import { useEffect, useState, Fragment, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { SecondaryButton } from "../../../components/buttons";
import { GetKMTankTrailerInspection } from "../../../actions/checklist";
import { Document, Page, pdfjs } from "react-pdf";
import "pdfjs-dist/web/pdf_viewer.css";
import dayjs from "dayjs";
import { OpenNewWindow } from "iconoir-react";
import { Helmet } from "react-helmet-async";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const OpenKMTankTrailerInspection = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState({});
  let [fileUrl, setFileUrl] = useState(null);
  const [numberOfPages, setNumberOfPages] = useState(1);

  let { id } = useParams();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetKMTankTrailerInspection(id)
        .then((res) => {
          setData(res.data);
          setFileUrl(res.data.fileURL);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(
            err.response.data ? err.response.data.message : "Something went wrong in retrieving this record. You will be redirected to the previous page.",
          );
          setTimeout(() => navigate("/checklists"), 3000);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const onLoadSuccess = ({ numPages }) => {
    setNumberOfPages(numPages);
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Open Kinder Morgan - Annual Tank Trailer Inspection Form | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Kinder-Morgan Annual Tank Trailer Inspection Form</p>
              <div className="flex items-center justify-end gap-2">
                <SecondaryButton label="Go back" callback={() => (location.key !== "default" ? navigate(-1) : navigate("/checklists", { replace: true }))} />
                {authState.user.functionCategory !== "technician" && (
                  <SecondaryButton label="Update" callback={() => navigate(`/checklists/annualTankTrailerInspection/${id}/edit`)} />
                )}
              </div>
            </div>
            <div className="w-full flex-grow flex-col justify-start items-center overflow-y-auto h-full px-0.5 gap-5">
              <div className="w-full pb-3 mx-auto border-b border-gray-500 md:w-3/4">
                <dl className="grid items-center grid-cols-1 gap-1 md:grid-cols-2">
                  <div className="flex flex-row items-center justify-between px-4">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Customer</dt>
                    <dd className="flex flex-row items-center justify-end gap-2 text-sm leading-6 text-right text-gray-700">
                      <p>{data.customerData}</p>
                      <button
                        className="pt-0.5 text-black hover:text-blue-600 duration-150"
                        onClick={() => window.open(`/customers/${data.customerId}`, "_blank").focus()}
                      >
                        <OpenNewWindow className="w-4 h-4" />
                      </button>
                    </dd>
                  </div>
                  <div className="flex flex-row items-center justify-between px-4">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Equipment</dt>
                    <dd className="flex flex-row items-center justify-end gap-2 text-sm leading-6 text-right text-gray-700">
                      <p>{data.equipmentData}</p>
                      <button
                        className="pt-0.5 text-black hover:text-blue-600 duration-150"
                        onClick={() => window.open(`/equipment/${data.equipmentId}`, "_blank").focus()}
                      >
                        <OpenNewWindow className="w-4 h-4" />
                      </button>
                    </dd>
                  </div>
                  <div className="flex flex-row items-center justify-between px-4">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Work Order / Relevant Job</dt>
                    <dd className="flex flex-row items-center justify-end gap-2 text-sm leading-6 text-right text-gray-700">
                      <p>{data.jobData}</p>
                      {data.workOrder.length > 0 && (
                        <button
                          className="pt-0.5 text-black hover:text-blue-600 duration-150"
                          onClick={() => window.open(`/jobs/${data.workOrder}`, "_blank").focus()}
                        >
                          <OpenNewWindow className="w-4 h-4" />
                        </button>
                      )}
                    </dd>
                  </div>
                  <div className="flex flex-row items-center justify-between px-4">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Technician</dt>
                    <dd className="text-sm leading-6 text-right text-gray-700">{data.employeeData}</dd>
                  </div>
                  <div className="flex flex-row items-center justify-between px-4">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Date</dt>
                    <dd className="text-sm leading-6 text-right text-gray-700">{dayjs(data.date).format("MMMM DD, YYYY")}</dd>
                  </div>
                </dl>
              </div>
              <Document
                // debug={true}
                file={{
                  url: fileUrl,
                }}
                onLoadSuccess={onLoadSuccess}
                pageMode="useOutlines"
                className="my-5"
              >
                <Page pageNumber={numberOfPages} renderTextLayer={false} />
              </Document>
              <div className="flex flex-row items-center justify-center w-full my-5">
                <SecondaryButton label="Download a copy" callback={() => window.open(fileUrl, "_blank")} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OpenKMTankTrailerInspection;
