import { useState } from "react";
import {
  ArrowRight,
  NavArrowDown,
  Home,
  Group,
  PasteClipboard,
  Book,
  Industry,
  DatabaseScript,
  Page,
  BoxIso,
  DeliveryTruck,
  Reports,
  ReceiveDollars,
  SendDollars,
  Clock,
  Settings,
  LogOut,
  Flash,
  Bell,
  SimpleCart,
  ClipboardCheck,
  HelpCircle,
  ClockRotateRight,
  Timer,
  Wrench,
} from "iconoir-react";
import { useLocation, useNavigate, useMatches } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Breadcrumbs from "./Breadcrumbs";
import { Dropdown, Popover } from "antd";

const NavigationWrapper = ({ authState, authDispatch }) => {
  const [open, setOpen] = useState(false);
  const [custMenu, setCustMenu] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  let initials = "HT";
  let fn;
  let ln;
  if (authState.user && authState.user.firstName && authState.user.firstName.length > 0) {
    fn = authState.user.firstName[0];
  }
  if (authState.user && authState.user.lastName && authState.user.lastName.length > 0) {
    ln = authState.user.lastName[0];
  }
  initials = fn + ln;

  const menuItems = [
    { title: "Dashboard", icon: <Home />, link: "/" },
    {
      title: "Customers",
      link: "customers",
      icon: <Group />,
    },
    { title: "Equipment", icon: <Industry />, link: "equipment" },
    { title: "Quotes", icon: <PasteClipboard />, link: "quotes" },
    { title: "Jobs", icon: <Book />, link: "/jobs" },
    { title: "Invoices", icon: <Page />, link: "invoices" },
    { title: "Parts", icon: <BoxIso />, link: "parts" },
    { title: "Vendors", icon: <DeliveryTruck />, link: "vendors" },
    { title: "PO's", icon: <SimpleCart />, link: "po" },
    { title: "AP's", icon: <SendDollars />, link: "ap" },
    { title: "Checklists", icon: <ClipboardCheck />, link: "checklists" },
    { title: "Reports", icon: <Reports />, link: "reports" },
    { title: "Settings", icon: <Settings />, link: "settings" },
    { title: "Admin", icon: <Wrench />, link: "admin" },
  ];

  let paths = [
    { title: "Dashboard", path: "/" },
    { title: "Customers", path: "/customers" },
    { title: "Create a customer", path: "/customers/new" },
    { title: "Open One Customer", path: "/customers/oneCustomer" },
    { title: "Equipment", path: "/equipment" },
    { title: "Quotes", path: "/quotes" },
    { title: "Jobs", path: "/jobs" },
    { title: "Invoices", path: "/invoices" },
    { title: "Parts", path: "/parts" },
    { title: "Vendors", path: "/vendors" },
    { title: "PO's", path: "/po" },
    { title: "AP's", path: "/ap" },
    { title: "Checklists", path: "/checklists" },
    { title: "Reports", path: "/reports" },
    { title: "Settings", path: "/settings" },
    { title: "Documents", path: "/docs" },
  ];

  let hiddenForTechs = ["Vendors", "AP's", "Reports", "Admin", "PO's"];

  const findLocation = (path) => {
    let one = paths.find((p) => p.path === path).title;
    if (one) {
      return one;
    } else {
      return "Unknown";
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <a onClick={() => navigate("/settings")} rel="noreferrer">
          Settings
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a onClick={() => navigate("/logout")} rel="noreferrer">
          Logout
        </a>
      ),
    },
  ];

  return (
    <div className="flex flex-row items-start justify-start w-full h-screen overflow-x-hidden overflow-y-hidden">
      <div className={`bg-blue-950 h-screen p-5 pt-8 ${open ? "w-72" : "w-20"} relative duration-300`}>
        <ArrowRight
          className={`bg-amber-300 text-blue-950 text-2xl p-2 rounded-full absolute -right-5 top-9 cursor-pointer ${open && "rotate-180"}`}
          onClick={() => setOpen(!open)}
        />
        <div className="inline-flex" onClick={() => navigate("/")}>
          <Flash
            className={`bg-amber-300 text-2xl rounded cursor-pointer px-2 py-1 block float-left mr-3 font-bold duration-500 ${open && "rotate-[360deg]"}`}
          />
          <h1 className={`text-white origin-left font-medium text-2xl duration-300 tracking-wider ${!open && "scale-0"}`}>HiTech</h1>
        </div>
        {open ? (
          <ul className="pt-1" key={uuidv4()}>
            {menuItems.map((item, index) => {
              if (authState.user.functionCategory === "technician") {
                if (!hiddenForTechs.includes(item.title)) {
                  return (
                    <li
                      key={uuidv4()}
                      className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-1 hover:bg-white/20 rounded-md ${
                        item.spacing ? "mt-9" : "mt-2"
                      }`}
                      onClick={() => navigate(item.link)}
                    >
                      <span className="block float-left">{item.icon}</span>
                      <span className={`text-base font-medium flex-1 duration-200 ${!open && "hidden"}`}>{item.title}</span>
                    </li>
                  );
                }
              } else {
                return (
                  <li
                    key={uuidv4()}
                    className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-1 hover:bg-white/20 rounded-md ${
                      item.spacing ? "mt-9" : "mt-2"
                    }`}
                    onClick={() => navigate(item.link)}
                  >
                    <span className="block float-left">{item.icon}</span>
                    <span className={`text-base font-medium flex-1 duration-200 ${!open && "hidden"}`}>{item.title}</span>
                  </li>
                );
              }
            })}
          </ul>
        ) : (
          <ul className="pt-1" key={uuidv4()}>
            {menuItems.map((item, index) => {
              if (authState.user.functionCategory === "technician") {
                if (!hiddenForTechs.includes(item.title)) {
                  return (
                    <Popover content={item.title} placement="right">
                      <li
                        key={uuidv4()}
                        className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-1 hover:bg-white/20 rounded-md ${
                          item.spacing ? "mt-9" : "mt-2"
                        }`}
                        onClick={() => navigate(item.link)}
                      >
                        <span className="block float-left">{item.icon}</span>
                        <span className={`text-base font-medium flex-1 duration-200 ${!open && "hidden"}`}>{item.title}</span>
                      </li>
                    </Popover>
                  );
                }
              } else {
                return (
                  <Popover content={item.title} placement="right">
                    <li
                      key={uuidv4()}
                      className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-1 hover:bg-white/20 rounded-md ${
                        item.spacing ? "mt-9" : "mt-2"
                      }`}
                      onClick={() => navigate(item.link)}
                    >
                      <span className="block float-left">{item.icon}</span>
                      <span className={`text-base font-medium flex-1 duration-200 ${!open && "hidden"}`}>{item.title}</span>
                    </li>
                  </Popover>
                );
              }
            })}
          </ul>
        )}
      </div>
      <div className="flex flex-col items-center justify-start flex-1 h-screen px-10 border bg-white-smoke">
        <div className="flex flex-row items-center justify-between w-full h-20 gap-3 max-w-content-wrapper bg-white-smoke">
          <h1 className="text-sm font-bold tracking-tight uppercase">{/* <Breadcrumbs /> */}</h1>
          <div className="flex flex-row items-center justify-end flex-grow gap-3">
            {authState.user.functionCategory !== "technician" && (
              <Popover content="Changelog" placement="bottom">
                <ClockRotateRight
                  className={`bg-slate-200/70 text-blue-950 text-2xl p-2 rounded-full hover:bg-slate-300 transition-200 cursor-pointer relative`}
                  onClick={() => navigate("/changelog")}
                />
              </Popover>
            )}
            <Popover content="Documentation" placement="bottom" onClick={() => navigate("/docs")}>
              <HelpCircle
                className={`bg-slate-200/70 text-blue-950 text-2xl p-2 rounded-full hover:bg-slate-300 transition-200 cursor-pointer relative`}
                onClick={() => navigate("/docs")}
              />
            </Popover>
            <Popover content="Timesheets" placement="bottom" onClick={() => navigate("/settings/timesheets")}>
              <Timer
                className={`bg-slate-200/70 text-blue-950 text-2xl p-2 rounded-full hover:bg-slate-300 transition-200 cursor-pointer relative`}
                onClick={() => navigate("/settings/timesheets")}
              />
            </Popover>
            {/* <Bell className={`bg-slate-200/70 text-blue-950 text-2xl p-2 rounded-full hover:bg-slate-300 transition-200 cursor-pointer relative`} /> */}
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              overlayClassName="pt-1 w-36 text-center"
            >
              <p className={`bg-slate-200/70 text-blue-950 text-sm font-semibold p-2 rounded-full hover:bg-slate-300 transition-200 cursor-pointer`}>
                {initials}
              </p>
            </Dropdown>
          </div>
        </div>
        <div className="w-full mb-3 border-b border-gray-400" />
        <div className="w-full h-full overflow-y-auto overflow-x-hidden max-w-[80vw] 2xl:max-w-content flex flex-col justify-start items-start">
          <Outlet className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default NavigationWrapper;
