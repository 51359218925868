import axiosInstance from "./axios";

// export const NewLocation = (data) => {
//   return axiosInstance.post("/admin/newLocation", data);
// };

// export const NewVendor = (data) => {
//   return axiosInstance.post("/admin/newVendor", data);
// };

// export const UpdateVendor = (data) => {
//   return axiosInstance.post("/admin/updateVendor", data);
// };

export const GetAccounts = () => {
  return axiosInstance.get("/admin/accounts");
};

export const AddAccount = (data) => {
  return axiosInstance.post("/admin/accounts", data);
};

export const UpdateAccount = (accountId, data) => {
  return axiosInstance.put(`/admin/accounts/${accountId}`, data);
};

export const GetOneAccount = (accountId) => {
  return axiosInstance.get(`/admin/accounts/${accountId}`);
};

export const GetAllEmployees = () => {
  return axiosInstance.get("/admin/employee");
};

export const CreateEmployee = (data) => {
  return axiosInstance.post("/admin/employee", data);
};

export const ResendInvite = (data) => {
  return axiosInstance.put("/admin/employee/invite", data);
};

export const GetOneEmployee = (employeeId) => {
  return axiosInstance.get(`/admin/employee/${employeeId}`);
};

export const SubmitTimeOff = (data) => {
  return axiosInstance.post("/admin/timeOff", data);
};

export const GetTimeOff = (id) => {
  return axiosInstance.get(`/admin/timeOff/${id}`);
};

export const AddTimeOffComment = (id, data) => {
  return axiosInstance.put(`/admin/timeOff/${id}/comment`, data);
};

export const WithdrawTimeOff = (id) => {
  return axiosInstance.put(`/admin/timeOff/${id}/withdraw`);
};

export const GetAllTimeOffRequests = () => {
  return axiosInstance.get("/admin/timeOff");
};

export const AddALaborRate = (data) => {
  return axiosInstance.post("/admin/laborRate", data);
};

export const UpdateLaborRate = (data) => {
  return axiosInstance.put(`/admin/laborRate/${data.rateId}`, data);
};

export const GetTimesheets = () => {
  return axiosInstance.get("/admin/timesheets");
};

export const GetOneTimesheet = (id) => {
  return axiosInstance.get(`/admin/timesheets/${id}`);
};

export const AddTimesheetNote = (id, data) => {
  return axiosInstance.put(`/admin/timesheets/${id}/note`, data);
};

export const EditTimesheetEntry = (id, data) => {
  return axiosInstance.put(`/admin/timesheets/${id}/entry`, data);
};

export const EditTimesheetRNM = (id, data) => {
  return axiosInstance.put(`/admin/timesheets/${id}/rnm`, data);
};

export const RemoveTimesheetRNM = (id, rnmId) => {
  return axiosInstance.delete(`/admin/timesheets/${id}/rnm/${rnmId}`);
};

export const ReviewTimesheet = (id, data) => {
  return axiosInstance.post(`/admin/timesheets/${id}/review`, data);
};

export const AddACannedFee = (data) => {
  return axiosInstance.post("/admin/cannedFee", data);
};

export const UpdateCannedFee = (data) => {
  return axiosInstance.put(`/admin/cannedFee/${data.feeId}`, data);
};

export const DeleteCannedFee = (feeId) => {
  return axiosInstance.delete(`/admin/cannedFee/${feeId}`);
};
