import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { OpenInWindow, Printer } from "iconoir-react";
import { GetInventoryCount, GetMonthlyServiceReport, GetOpenJobs } from "../../../actions/reports";
import dayjs from "dayjs";
import { formatCurrency } from "../../../components/tools";
import { GenerateAPOpenInvoicesReport, GenerateCountSheet, GenerateEquipmentServicesReport, GenerateOpenJobsReport } from "../../../data/pdf";
import { Helmet } from "react-helmet-async";

const UpcomingServiceReport = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;

    if (inView) {
      GetMonthlyServiceReport()
        .then((res) => {
          setServices(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "We encountered an error while loading Equipment Service data");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const printReport = (selected) => {
    let capitalize = selected[0].toUpperCase() + selected.slice(1);
    let data = services[selected];
    if (data.length > 0) {
      let doc = GenerateEquipmentServicesReport(data, capitalize);
      doc.setProperties({
        title: `Hi-Tech Power Systems | Equipment Service Report for ${capitalize}`,
        subject: `Hi-Tech Power Systems | Equipment Service Report for ${capitalize}`,
        author: "Hypertek Solutions LLC",
        keywords: "",
        creator: "contact@hypertek.dev",
      });
      window.open(doc.output("bloburl"), "_blank");
    } else {
      toast.error(`No scheduled services found for the month of ${capitalize}`);
    }
  };

  let months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Upcoming Service Report | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full h-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Upcoming Equipment Service Report</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-col items-start justify-center gap-1">
            <p className="text-sm uppercase text-gray-500/90">Upcoming Equipment Service Report</p>
            <p className="text-sm text-gray-600/80">Lists all equipment with upcoming scheduled maintenance due soon, broken down in month-to-month reports.</p>
          </div>
        </div>
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          {!loading && (
            <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4 sm:gap-6">
              {months.map((month) => (
                <div className="flex flex-col bg-white border shadow-sm rounded-xl w-60">
                  <div className="flex justify-between p-4 md:p-5 gap-x-3">
                    <div>
                      <p className="text-xs tracking-wide text-gray-500 uppercase">{month}</p>
                      <div className="flex items-center mt-1 gap-x-1">
                        <h3 className="text-xl font-medium text-gray-800 sm:text-2xl">{services[month].length}</h3>
                      </div>
                    </div>
                  </div>
                  <button
                    className="inline-flex items-center justify-between px-4 py-3 text-sm text-gray-600 border-t border-gray-200 md:px-5 hover:bg-gray-50 rounded-b-xl"
                    onClick={() => printReport(month)}
                  >
                    Print Report
                    <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpcomingServiceReport;
