import { useEffect, useState, Fragment, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Transition, Combobox } from "@headlessui/react";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { GetVendorsList, CreateAPInvoice, GetPartsList, GetPOsLean, GetAccounts, QueryVendors } from "../../actions/ims";
import { GetLeanJobs } from "../../actions/jobs";
import dayjs from "dayjs";
import { DatePicker, Divider, InputNumber, Select } from "antd";
import { ArrowSeparateVertical, Check, Page, Xmark } from "iconoir-react";
import { useDropzone } from "react-dropzone";
import { UploadAPInvoices } from "../../actions/cdn";
import { GenerateAPInvoiceControlReport } from "../../data/pdf";
import { Helmet } from "react-helmet-async";

const NewAP = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [vendors, setVendors] = useState([]);
  let [parts, setParts] = useState([]);
  let [step, setStep] = useState(0);
  let [query, setQuery] = useState("");
  let [selected, setSelected] = useState(null);
  let [poID, setPOID] = useState(null);
  let [stepOneData, setStepOneData] = useState({
    invoiceNumber: "",
    invoiceDate: "",
    dueDate: "",
  });
  let [stepTwoData, setStepTwoData] = useState({
    shipping: 0,
    tax: 0,
  });
  let [serializedItems, setSerializedItems] = useState([]);
  let [nonSerializedItems, setNonSerializedItems] = useState([]);
  let [addSerialModal, setAddSerialModal] = useState(false);
  let [serialToAdd, setSerialToAdd] = useState({
    partId: "",
    partNo: "",
    quantity: 0,
    price: 0,
  });
  let [selectedSerialPart, setSelectedSerialPart] = useState(null);
  let [serialQuery, setSerialQuery] = useState("");
  let [addNonSerialModal, setAddNonSerialModal] = useState(false);
  let [nonSerialToAdd, setNonSerialToAdd] = useState({
    partNo: "",
    quantity: 0,
    price: 0,
    description: "",
  });
  let [purchaseOrders, setPurchaseOrders] = useState([]);
  let [selectedFiles, setSelectedFiles] = useState([]);
  let [accounts, setAccounts] = useState([]);
  let [selectedAccount, setSelectedAccount] = useState(null);
  let [jobs, setJobs] = useState([]);
  let [selectedJob, setSelectedJob] = useState(null);

  let navigate = useNavigate();

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.ms-excel": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
      "text/csv": [],
    },
    maxFiles: 5,
    onDropAccepted: (acceptedFiles) => {
      let tmp = selectedFiles;
      tmp.push(...acceptedFiles);
      setSelectedFiles(tmp);
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((f) => {
        toast.error(`${f.file.name} was not accepted`);
      });
    },
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "100%",
    padding: "80px 0px",
    margin: "15px 0px",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetPartsList()
        .then((res) => {
          setParts(res.data);
          GetPOsLean()
            .then((res) => {
              setPurchaseOrders(res.data);
              GetAccounts()
                .then((res) => {
                  setAccounts(res.data);
                  setTimeout(() => setLoading(false), 700);
                  GetLeanJobs()
                    .then((res) => {
                      setJobs(res.data);
                      setTimeout(() => setLoading(false), 700);
                    })
                    .catch((err) => {
                      console.log(err);
                      setLoading(false);
                    });
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const filteredVendors =
    query === ""
      ? vendors
      : vendors.filter((vndr) => vndr.vendorName.toLowerCase().includes(query.toLowerCase()) || vndr.vendorCode.toLowerCase().includes(query.toLowerCase()));

  const updateInvoiceDate = (date, dateString) => {
    let tmp = stepOneData;
    if (dateString !== "") {
      tmp.invoiceDate = date.toJSON();
    } else {
      tmp.invoiceDate = "";
    }
    setStepOneData(tmp);
  };

  const updateInvoiceDueDate = (date, dateString) => {
    let tmp = stepOneData;
    if (dateString !== "") {
      tmp.dueDate = date.toJSON();
    } else {
      tmp.dueDate = "";
    }
    setStepOneData(tmp);
  };

  const updateInvoiceNumber = (val) => {
    let tmp = stepOneData;
    tmp.invoiceNumber = val;
    setStepOneData(tmp);
  };

  const updateInvoiceExpenses = (e) => {
    let { name, value } = e.target;
    if (value === "") {
      setDummyLoading(true);
      let tmp = stepTwoData;
      tmp[name] = parseFloat(0);
      setStepTwoData(tmp);
      setTimeout(() => setDummyLoading(false), 300);
    }
    if (value !== null && value !== "") {
      setDummyLoading(true);
      let tmp = stepTwoData;
      tmp[name] = parseFloat(value);
      setStepTwoData(tmp);
      setTimeout(() => setDummyLoading(false), 300);
    }
  };

  const changeSerialPartQuantity = (e) => {
    setDummyLoading(true);
    let tmp = serializedItems;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      let part = parts.find((pt) => pt.partId === id);
      let existing = _.find(tmp, (p) => p.partId === id);
      if (existing && existing !== undefined) {
        existing.quantity = value;
        existing.total = parseFloat((existing.price * value).toFixed(2));
        let index = _.findIndex(tmp, (p) => p.itemId === existing.itemId);
        tmp[index] = existing;
        setSerializedItems(tmp);
      } else {
        toast.error("Error updating part quantity");
      }
    }
    setTimeout(() => setDummyLoading(false), 300);
  };

  const changeSerialPartPrice = (e) => {
    setDummyLoading(true);
    let tmp = serializedItems;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      let part = parts.find((pt) => pt.partId === id);
      let existing = _.find(tmp, (p) => p.partId === id);
      if (existing && existing !== undefined) {
        existing.price = value;
        existing.total = parseFloat((existing.quantity * value).toFixed(2));
        let index = _.findIndex(tmp, (p) => p.itemId === existing.itemId);
        tmp[index] = existing;
        setSerializedItems(tmp);
      } else {
        toast.error("Error updating part price");
      }
    }
    setTimeout(() => setDummyLoading(false), 300);
  };

  const changeNonSerialPartQuantity = (e) => {
    setDummyLoading(true);
    let tmp = nonSerializedItems;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      let existing = _.find(tmp, (p) => p.itemId === id);
      if (existing && existing !== undefined) {
        existing.quantity = value;
        existing.total = parseFloat((existing.price * value).toFixed(2));
        let index = _.findIndex(tmp, (p) => p.itemId === existing.itemId);
        tmp[index] = existing;
        setNonSerializedItems(tmp);
      } else {
        toast.error("Error updating part quantity");
      }
    }
    setTimeout(() => setDummyLoading(false), 300);
  };

  const changeNonSerialPartPrice = (e) => {
    setDummyLoading(true);
    let tmp = nonSerializedItems;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      let existing = _.find(tmp, (p) => p.itemId === id);
      if (existing && existing !== undefined) {
        existing.price = value;
        existing.total = parseFloat((existing.quantity * value).toFixed(2));
        let index = _.findIndex(tmp, (p) => p.itemId === existing.itemId);
        tmp[index] = existing;
        setNonSerializedItems(tmp);
      } else {
        toast.error("Error updating part price");
      }
    }
    setTimeout(() => setDummyLoading(false), 300);
  };

  const removeSerialPart = (itemId) => {
    setDummyLoading(true);
    let tmp = serializedItems.filter((pt) => pt.itemId !== itemId);
    setSerializedItems(tmp);
    toast.success("Item removed, please save changes for it to take effect");
    setTimeout(() => setDummyLoading(false), 300);
  };

  const removeNonSerialPart = (itemId) => {
    setDummyLoading(true);
    let tmp = nonSerializedItems.filter((pt) => pt.itemId !== itemId);
    setNonSerializedItems(tmp);
    toast.success("Item removed, please save changes for it to take effect");
    setTimeout(() => setDummyLoading(false), 300);
  };

  const closeAddSerialPart = () => {
    setAddSerialModal(false);
    setSerialToAdd({
      partId: "",
      partNo: "",
      quantity: 0,
      price: 0,
    });
    setSelectedSerialPart(null);
    setSerialQuery("");
  };

  const filteredParts =
    serialQuery === ""
      ? parts
      : parts.filter(
          (part) => part.partNo.toLowerCase().includes(serialQuery.toLowerCase()) || part.description.toLowerCase().includes(serialQuery.toLowerCase()),
        );

  const editSerialPartChange = (e) => {
    let { name, value } = e.target;
    let tmp = serialToAdd;
    tmp[name] = parseFloat(value);
    setSerialToAdd(tmp);
  };

  const submitAddSerial = () => {
    let failed = false;
    if (!selectedSerialPart) {
      toast.error("Please select a part");
      failed = true;
    } else if (serialToAdd.quantity === 0) {
      toast.error("Please enter a part quantity");
      failed = true;
    }
    if (!failed) {
      setLoading(true);
      let tmp = serializedItems;
      let price = serialToAdd.price === 0 ? selectedSerialPart.chargeOut : serialToAdd.price;
      tmp.push({
        itemId: uuidv4(),
        partId: selectedSerialPart,
        partNo: parts.find((p) => p.partId === selectedSerialPart)?.partNo || "",
        description: parts.find((p) => p.partId === selectedSerialPart)?.description || "",
        quantity: serialToAdd.quantity,
        price: price,
        total: parseFloat((serialToAdd.quantity * price).toFixed(2)),
      });
      setSerializedItems(tmp);
      closeAddSerialPart();
      toast.success("Part added, please save changes for it to take effect");
      setTimeout(() => setLoading(false), 700);
    }
  };

  const renderAddSerial = () => {
    return (
      <Transition.Root show={addSerialModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddSerialPart}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl">
                  <div className="flex flex-col items-start justify-start w-full gap-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add A Serialized Part to AP Invoice
                    </Dialog.Title>
                    <div className="w-full mt-2">
                      <label htmlFor="part" className="block text-sm font-medium leading-6 text-gray-900">
                        Part
                      </label>
                      <Select
                        placeholder={"Select a part"}
                        onChange={setSelectedSerialPart}
                        options={parts.map((c) => ({
                          value: c.partId,
                          label: `${c.partNo} | ${c.description && c.description.length > 20 ? c.description.slice(0, 20) + "..." : c.description}`,
                        }))}
                        className="w-full font-sans"
                        controls={false}
                        showSearch
                        filterOption={filterOption}
                      />
                    </div>
                    <div className="w-full">
                      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        Price
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="number"
                          name="price"
                          id="price"
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          aria-describedby="price-per-part"
                          defaultValue={selectedSerialPart ? selectedSerialPart.chargeOut : null}
                          disabled={selectedSerialPart ? false : true}
                          step="0.01"
                          onChange={(e) => editSerialPartChange(e)}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <span className="text-gray-500 sm:text-sm" id="price-per-part">
                            USD
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-900">
                        Quantity
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="quantity"
                          id="quantity"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="Part Quantity"
                          onChange={(e) => editSerialPartChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeAddSerialPart()} />
                    <PrimaryButton label="Add Part" callback={() => submitAddSerial()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAddNonSerialPart = () => {
    setAddNonSerialModal(false);
    setNonSerialToAdd({
      partNo: "",
      description: "",
      quantity: 0,
      price: 0,
    });
  };
  const editNonSerialPartChange = (e) => {
    let { name, value } = e.target;
    let tmp = nonSerialToAdd;
    if (name === "quantity" || name === "price") {
      tmp[name] = parseFloat(value);
    } else {
      tmp[name] = value;
    }
    setNonSerialToAdd(tmp);
  };

  const submitAddNonSerial = () => {
    let failed = false;
    if (nonSerialToAdd.partNo === "") {
      toast.error("Please enter a part number");
      failed = true;
    } else if (nonSerialToAdd.description === "") {
      toast.error("Please enter a part description");
      failed = true;
    } else if (nonSerialToAdd.quantity === 0) {
      toast.error("Please enter a part quantity");
      failed = true;
    } else if (nonSerialToAdd.price === 0) {
      toast.error("Please enter a part price");
      failed = true;
    }
    if (!failed) {
      setLoading(true);
      let tmp = nonSerializedItems;
      tmp.push({
        itemId: uuidv4(),
        partId: "",
        partNo: nonSerialToAdd.partNo,
        description: nonSerialToAdd.description,
        quantity: nonSerialToAdd.quantity,
        price: nonSerialToAdd.price,
        total: parseFloat((nonSerialToAdd.quantity * nonSerialToAdd.price).toFixed(2)),
      });
      setNonSerializedItems(tmp);
      closeAddNonSerialPart();
      toast.success("Part added, please save changes for it to take effect");
      setTimeout(() => setLoading(false), 700);
    }
  };

  const renderAddNonSerial = () => {
    return (
      <Transition.Root show={addNonSerialModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddNonSerialPart}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl">
                  <div className="flex flex-col items-start justify-start w-full gap-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add A Non-Serialized Part to AP Invoice
                    </Dialog.Title>
                    <div key="partNo" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
                        Part No.
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                        type="text"
                        id="partNo"
                        name="partNo"
                        onChange={(e) => editNonSerialPartChange(e)}
                      />
                    </div>
                    <div key="description" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                        Description
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                        type="text"
                        id="description"
                        name="description"
                        onChange={(e) => editNonSerialPartChange(e)}
                      />
                    </div>
                    <div className="w-full">
                      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        Price
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="number"
                          name="price"
                          id="price"
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          aria-describedby="price-per-part"
                          step="0.01"
                          onChange={(e) => editNonSerialPartChange(e)}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <span className="text-gray-500 sm:text-sm" id="price-per-part">
                            USD
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-900">
                        Quantity
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="quantity"
                          id="quantity"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="Part Quantity"
                          onChange={(e) => editNonSerialPartChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeAddNonSerialPart()} />
                    <PrimaryButton label="Add Part" callback={() => submitAddNonSerial()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const nextStep = async () => {
    if (step === 0) {
      let failed = false;
      if (selected === null) {
        failed = true;
        toast.error("Please select a vendor.");
      } else if (stepOneData.invoiceNumber === "") {
        failed = true;
        toast.error("Please enter the invoice number.");
      } else if (stepOneData.invoiceDate === "") {
        failed = true;
        toast.error("Please select the invoice date.");
      } else if (stepOneData.dueDate === "") {
        failed = true;
        toast.error("Please select the due date.");
      }
      if (!failed) {
        setStep(1);
      }
    } else if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      let payload = {
        vendorId: selected,
        invoiceNumber: stepOneData.invoiceNumber,
        invoiceDate: stepOneData.invoiceDate,
        dueDate: stepOneData.dueDate,
        shipping: stepTwoData.shipping,
        tax: stepTwoData.tax,
        subtotal: getSubtotal(),
        total: getTotal(),
        items: [],
      };
      for (let i = 0; i < nonSerializedItems.length; i++) {
        payload.items.push({
          itemId: uuidv4(),
          price: nonSerializedItems[i].price,
          quantity: nonSerializedItems[i].quantity,
          partNo: nonSerializedItems[i].partNo,
          description: nonSerializedItems[i].description,
        });
      }
      for (let i = 0; i < serializedItems.length; i++) {
        payload.items.push({
          itemId: uuidv4(),
          price: serializedItems[i].price,
          quantity: serializedItems[i].quantity,
          partNo: serializedItems[i].partNo,
          description: serializedItems[i].description,
          partId: serializedItems[i].partId,
        });
      }
      payload.poReference = poID;
      payload.jobReference = selectedJob;
      payload.account = selectedAccount;
      setLoading(true);
      if (selectedFiles.length > 0) {
        toast("Uploading files...");
      }
      let uploadedFiles = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        let upload = await uploadFile(selectedFiles[i]);
        if (upload) {
          uploadedFiles.push(upload);
        }
      }
      if (selectedFiles.length > 0) {
        toast("Files Uploaded");
      }
      payload.files = uploadedFiles;
      CreateAPInvoice(payload)
        .then((res) => {
          toast.success("Invoice created successfully");
          generateControlReport(payload);
          navigate(`/ap/${res.data.invoiceId}`);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error creating invoice");
          setLoading(false);
        });
    }
  };

  const uploadFile = async (file) => {
    let formData = new FormData();
    formData.set("file", file, file.name);
    let upload = await UploadAPInvoices(formData);
    if (upload) {
      return upload.data[0].url;
    } else {
      toast.error("Error uploading file " + file.name);
      return null;
    }
  };

  const getSubtotal = () => {
    let st = 0;
    serializedItems.forEach((item) => {
      st += parseFloat(item.total);
    });
    nonSerializedItems.forEach((item) => {
      st += parseFloat(item.total);
    });
    if (poID) {
      let po = purchaseOrders.find((p) => p.poId === poID);
      if (po) {
        st += parseFloat(po.total);
      }
    }
    return st;
  };

  const getTotal = () => {
    let total = 0;
    let st = getSubtotal();
    let { shipping, tax } = stepTwoData;
    total = st + parseFloat(shipping) + parseFloat(tax);
    total = parseFloat(total.toFixed(2));
    return total || 0;
  };

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const selectPO = (val) => {
    let poData = purchaseOrders.find((p) => p.poId === val);
    if (poData) {
      setSelected(poData.vendorId);
      setPOID(val);
    }
  };

  const removeFile = (file, index) => {
    setDummyLoading(true);
    let tmp = selectedFiles;
    tmp.splice(index, 1);
    setSelectedFiles(tmp);
    setTimeout(() => setDummyLoading(false), 300);
  };

  const handleVendorSearch = (query) => {
    if (query?.length > 2) {
      QueryVendors(query)
        .then((res) => {
          setVendors(res.data.results);
        })
        .catch((err) => {
          toast.error("Vendor search failed, please try again");
          setVendors([]);
        });
    }
  };

  const renderStep = () => {
    if (step === 0) {
      return (
        <div className="w-full max-w-[800px] mx-auto flex flex-col justify-start items-start gap-2">
          <div className="w-full">
            <label className="block mb-1 text-sm font-medium leading-6 text-gray-900">Purchase Order</label>
            <Select
              placeholder={"Select a Purchase Order"}
              onChange={selectPO}
              options={purchaseOrders.map((c) => ({
                value: c.poId,
                label: `${c.poNumber} | ${formatCurrency(c.total)} | ${c.vendorCode.toUpperCase()}${c.vendorName.length > 0 ? " | " + c.vendorName : ""}`,
              }))}
              className="w-full font-sans"
              controls={false}
              showSearch
              filterOption={filterOption}
            />
          </div>
          <Divider style={{ fontSize: "0.75rem", lineHeight: "1rem" }}>OR</Divider>
          <div className="w-full">
            <label className="block mb-1 text-sm font-medium leading-6 text-gray-900">Vendor</label>
            {/* <Select
              placeholder={"Select a vendor"}
              onChange={setSelected}
              value={selected}
              options={vendors.map((c) => ({
                value: c.vendorId,
                label: `${c.vendorCode.toUpperCase()}${c.vendorName.length > 0 ? " | " + c.vendorName : ""}`,
              }))}
              className="w-full font-sans"
              controls={false}
              showSearch
              filterOption={filterOption}
            /> */}

            <Select
              placeholder="Search Vendors"
              onChange={setSelected}
              value={selected}
              options={(vendors || []).map((p) => ({
                label: `${p.vendorCode} | ${p.vendorName}`,
                value: p.vendorId,
              }))}
              className="w-full mb-2 font-sans"
              notFoundContent="No vendors found, start typing to search"
              controls={false}
              showSearch
              filterOption={false}
              defaultActiveFirstOption={false}
              onSearch={handleVendorSearch}
              allowClear
            />
          </div>
          <div className="w-full mt-2">
            <label htmlFor="invoiceNumber" className="block text-sm font-medium leading-6 text-gray-900">
              Invoice Number
            </label>
            <div className="w-full mt-2">
              <input
                type="invoiceNumber"
                name="invoiceNumber"
                id="invoiceNumber"
                className="block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                placeholder="Vendor's Invoice Number"
                onChange={(e) => updateInvoiceNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full mt-2">
            <label htmlFor="invoiceDate" className="block text-sm font-medium leading-6 text-gray-900">
              Invoice Date
            </label>
            <div className="w-full mt-2">
              <DatePicker style={{ width: "100%" }} placeholder="Select a date of the invoice" format={"MM/DD/YYYY"} onChange={updateInvoiceDate} />
            </div>
          </div>
          <div className="w-full mt-2">
            <label htmlFor="dueDate" className="block text-sm font-medium leading-6 text-gray-900">
              Due Date
            </label>
            <div className="w-full mt-2">
              <DatePicker style={{ width: "100%" }} placeholder="Select the invoice due date" format={"MM/DD/YYYY"} onChange={updateInvoiceDueDate} />
            </div>
          </div>
          <div className="flex flex-row items-center justify-end w-full gap-2 mt-2">
            <SecondaryButton label="Cancel" callback={() => navigate("/ap")} />
            <PrimaryButton label="Next" callback={() => nextStep()} />
          </div>
        </div>
      );
    } else if (step === 1) {
      return (
        <div className="flex flex-col items-start justify-start flex-grow w-full gap-2 mt-3">
          <div className="flex flex-row items-center justify-between w-full pb-3 border-b border-gray-300">
            <p className="font-semibold text-gray-600 uppercase">Serialized Parts</p>
            <SecondaryButton label="Add a Serialized Part" callback={() => setAddSerialModal(true)} />
          </div>
          {serializedItems.length === 0 ? (
            <div className="flex flex-row items-center justify-center w-full py-5">
              <p className="text-sm font-semibold text-gray-500 uppercase">0 Serialized Parts Added</p>
            </div>
          ) : (
            <table className="min-w-full overflow-y-auto divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-[250px]">
                    Part No
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Description
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                    Quantity
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                    Price
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]">
                    Total
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[80px]"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {serializedItems.map((pt) => (
                  <tr key={uuidv4()} className="h-12">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 w-[250px]">{pt.partNo}</td>
                    <td className="px-3 py-4 text-sm text-gray-500 capitalize truncate whitespace-nowrap">
                      {pt.description.slice(0, 50)}
                      {pt.description.length > 50 && "..."}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px]">
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                        type="number"
                        id={pt.partId}
                        defaultValue={pt.quantity}
                        onBlur={changeSerialPartQuantity}
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px]">
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                        type="number"
                        id={pt.partId}
                        defaultValue={pt.price}
                        onBlur={changeSerialPartPrice}
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-right">{formatCurrency(pt.price * pt.quantity)}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[80px] flex justify-end items-center">
                      <button
                        className="border border-gray-300 hover:border-red-300 duration-150 flex justify-center items-center rounded-md h-7 w-7 mt-0.5 group disabled:cursor-not-allowed"
                        onClick={() => removeSerialPart(pt.itemId)}
                      >
                        <Xmark className="w-5 h-5 text-gray-400 duration-150 group-hover:text-red-400" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className="flex flex-row items-center justify-between w-full py-3 border-gray-300 border-y">
            <p className="font-semibold text-gray-600 uppercase">Non-Serialized Parts</p>
            <SecondaryButton label="Add a  Non-Serialized Part" callback={() => setAddNonSerialModal(true)} />
          </div>
          {nonSerializedItems.length === 0 ? (
            <div className="flex flex-row items-center justify-center w-full py-5">
              <p className="text-sm font-semibold text-gray-500 uppercase">0 Non-Serialized Parts Added</p>
            </div>
          ) : (
            <table className="min-w-full overflow-y-auto divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-[250px]">
                    Part No
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Description
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                    Quantity
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[150px]">
                    Price
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]">
                    Total
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[80px]"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {nonSerializedItems.map((pt) => (
                  <tr key={uuidv4()} className="h-12">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 w-[250px]">{pt.partNo}</td>
                    <td className="px-3 py-4 text-sm text-gray-500 capitalize truncate whitespace-nowrap">
                      {pt.description.slice(0, 50)}
                      {pt.description.length > 50 && "..."}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px]">
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                        type="number"
                        id={pt.itemId}
                        defaultValue={pt.quantity}
                        onBlur={changeNonSerialPartQuantity}
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px]">
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                        type="number"
                        id={pt.itemId}
                        defaultValue={pt.price}
                        onBlur={changeNonSerialPartPrice}
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[150px] text-right">{formatCurrency(pt.price * pt.quantity)}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[80px] flex justify-end items-center">
                      <button
                        className="border border-gray-300 hover:border-red-300 duration-150 flex justify-center items-center rounded-md h-7 w-7 mt-0.5 group disabled:cursor-not-allowed"
                        onClick={() => removeNonSerialPart(pt.itemId)}
                      >
                        <Xmark className="w-5 h-5 text-gray-400 duration-150 group-hover:text-red-400" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className="flex flex-row items-center justify-end w-full gap-2 mt-2">
            <SecondaryButton label="Cancel" callback={() => setStep(step - 1)} />
            <PrimaryButton label="Next" callback={() => nextStep()} />
          </div>
          {renderAddSerial()}
          {renderAddNonSerial()}
        </div>
      );
    } else if (step === 2) {
      return (
        <div className="w-full max-w-[800px] mx-auto flex flex-col justify-start items-start gap-2">
          <div className="w-full mt-2">
            <label htmlFor="shipping" className="block mb-1 text-sm font-medium leading-6 text-gray-900">
              Subtotal
            </label>
            <InputNumber
              className="w-full font-sans py-0.5 px-1.5 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
              controls={false}
              placeholder="0.00"
              step={0.01}
              prefix={"$"}
              defaultValue={getSubtotal()}
            />
          </div>
          <div className="w-full mt-2">
            <label htmlFor="shipping" className="block text-sm font-medium leading-6 text-gray-900">
              Freight Cost
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                type="text"
                name="shipping"
                id="shipping"
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="shipping-currency"
                onBlur={updateInvoiceExpenses}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <span className="text-gray-500 sm:text-sm" id="shipping-currency">
                  USD
                </span>
              </div>
            </div>
          </div>
          <div className="w-full mt-2">
            <label htmlFor="tax" className="block text-sm font-medium leading-6 text-gray-900">
              Tax
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                type="text"
                name="tax"
                id="tax"
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="tax-currency"
                onBlur={updateInvoiceExpenses}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <span className="text-gray-500 sm:text-sm" id="tax-currency">
                  USD
                </span>
              </div>
            </div>
          </div>
          <div className="w-full">
            <label className="block mb-1 text-sm font-medium leading-6 text-gray-900">Job Reference</label>
            <Select
              placeholder={"Select a job"}
              onChange={setSelectedJob}
              options={jobs.map((c) => ({
                value: c.jobId,
                label: c.jobNo,
              }))}
              className="w-full font-sans"
              controls={false}
              showSearch
              filterOption={filterOption}
            />
          </div>
          <div className="w-full">
            <label className="block mb-1 text-sm font-medium leading-6 text-gray-900">Account</label>
            <Select
              placeholder={"Select an account"}
              onChange={setSelectedAccount}
              options={accounts.map((c) => ({
                value: c.accountId,
                label: c.accountNo + " | " + c.description,
              }))}
              className="w-full font-sans"
              controls={false}
              showSearch
              filterOption={filterOption}
            />
          </div>
          <div className="flex flex-col items-center justify-center w-full mt-2">
            <label className="block w-full text-sm font-medium leading-6 text-gray-900">Attach a copy of the AP Invoice</label>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p className="text-sm text-gray-600">Click to upload or drag and drop files here</p>
              <p className="text-xs text-gray-400">Accepts PDF, DOCX, CSV, and Excel Files - Maximum of 5</p>
            </div>
            <div className="flex flex-col items-center justify-start w-full gap-2 mt-5">
              {selectedFiles.length > 0 ? (
                selectedFiles.map((selectedFile, i) => {
                  return (
                    <div className="flex flex-row items-center justify-start w-full gap-2 p-2 text-xs font-medium text-gray-600 border border-gray-200 rounded-lg">
                      <Page className="w-5 h-5 text-gray-500" />
                      <p className="flex-grow">{selectedFile.name ? selectedFile.name : "Unknown File"}</p>
                      <Xmark
                        className="w-5 h-5 text-gray-500 transition-all duration-150 cursor-pointer hover:text-red-500"
                        onClick={() => removeFile(selectedFile, i)}
                      />
                    </div>
                  );
                })
              ) : (
                <p className="text-sm text-gray-500">No files added</p>
              )}
            </div>
          </div>
          <div className="flex flex-col items-end justify-start w-full gap-2 pt-2 mt-2 border-t border-gray-300">
            <p className="text-sm font-semibold text-gray-800 uppercase">SUBTOTAL: {formatCurrency(getSubtotal())}</p>
            <p className="text-sm font-semibold text-gray-800 uppercase">FREIGHT: {formatCurrency(stepTwoData.shipping || 0)}</p>
            <p className="text-sm font-semibold text-gray-800 uppercase">TAX: {formatCurrency(stepTwoData.tax || 0)}</p>
            <p className="text-sm font-semibold text-gray-800 uppercase">TOTAL: {formatCurrency(getTotal())}</p>
          </div>
          <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
            <SecondaryButton label="Back" callback={() => setStep(step - 1)} />
            <PrimaryButton label="Submit" callback={() => nextStep()} />
          </div>
        </div>
      );
    }
  };

  const generateControlReport = async (payload) => {
    let vendor = vendors.find((v) => v.vendorId === payload.vendorId);
    let po = purchaseOrders.find((p) => p.poId === payload.poReference);
    let account = accounts.find((a) => a.accountId === payload.account);
    let data = {
      vendor: vendor.vendorCode + " | " + vendor.vendorName,
      invoiceNo: payload.invoiceNumber,
      invoiceDate: dayjs(payload.invoiceDate).format("MM/DD/YYYY"),
      dueDate: dayjs(payload.dueDate).format("MM/DD/YYYY"),
      amount: payload.total,
      poNumber: po.poNumber,
      poAmount: po.total,
      accountNo: account.accountNo,
    };
    let doc = await GenerateAPInvoiceControlReport(data);
    doc.setProperties({
      title: `Hi-Tech Power Systems | AP Invoice Control Report`,
      subject: `Hi-Tech Power Systems | AP Invoice Control Report`,
      author: "Hypertek Solutions LLC",
      keywords: "",
      creator: "contact@hypertek.dev",
    });
    window.open(doc.output("bloburl"), "_blank");
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>New AP Invoice | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-col items-center justify-start w-full h-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-3 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">New AP Invoice</p>
            </div>
            {renderStep()}
          </div>
        </>
      )}
    </div>
  );
};

export default NewAP;
