import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const Logout = ({ authState, authDispatch }) => {
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      authState.authDispatch({ type: "logged-out" });
      navigate("/");
    }
    return () => {
      inView = false;
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Helmet>
        <title>Logging you out... | HTPS ERP</title>
      </Helmet>
      <p className="text-2xl font-bold">Logging you out...</p>
    </div>
  );
};

export default Logout;
