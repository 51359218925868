import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { PrimaryButton, SecondaryButton } from "../../../components/buttons";
import { GetAllEmployees, SubmitTimeOff } from "../../../actions/admin";
import { DatePicker, Input, Select, Switch } from "antd";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";

const NewTimeOffEntry = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [data, setData] = useState({
    employeeId: null,
    employeeName: "",
    startDate: null,
    endDate: null,
    allDay: true,
    status: null,
    reason: null,
    comment: null,
  });

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetAllEmployees()
        .then((res) => {
          setEmployees(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error loading employees");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const navigate = useNavigate();

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const selectEmployee = (val) => {
    let employee = employees.find((employee) => employee.userId === val);
    if (employee) {
      setData((prev) => ({
        ...prev,
        employeeId: employee.userId,
        employeeName: employee.firstName + " " + employee.lastName,
      }));
    }
  };

  const updateDateRange = (dates) => {
    let date1 = dayjs(dates[0]);
    let date2 = dayjs(dates[1]);
    if (date1.isAfter(date2)) {
      toast.error("Start date cannot be after end date");
    } else {
      setData((prev) => ({
        ...prev,
        startDate: date1.toJSON(),
        endDate: date2.toJSON(),
      }));
    }
  };

  const submitEntry = () => {
    let failed = false;
    if (!data.employeeId || data.employeeId === "" || data.employeeName === "") {
      toast.error("Please select an employee");
      failed = true;
    }
    if (!data.startDate || data.startDate === "") {
      toast.error("Please select a start date");
      failed = true;
    }
    if (!data.endDate || data.endDate === "") {
      toast.error("Please select an end date");
      failed = true;
    }
    if (dayjs(data.startDate).isAfter(dayjs(data.endDate))) {
      toast.error("Start date cannot be after end date");
      failed = true;
    }
    if (!data.status || data.status === "") {
      toast.error("Please select a status");
      failed = true;
    }
    if (!data.reason || data.reason === "") {
      toast.error("Please select a reason");
      failed = true;
    }
    if (!failed) {
      setLoading(true);
      let payload = data;
      if (payload.allDay) {
        payload.startDate = dayjs(payload.startDate).startOf("day").toJSON();
        payload.endDate = dayjs(payload.endDate).endOf("day").toJSON();
      }
      SubmitTimeOff(data)
        .then((res) => {
          toast.success("Time-Off entry created successfully");
          navigate(`/admin/timeOff/${res.data.timeOffId}`);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error creating time-off entry");
          setLoading(false);
        });
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <div className="flex flex-col items-center justify-start w-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">New Time-Off Entry</p>
          <SecondaryButton label="Cancel & Go Back" callback={() => navigate(-1)} />
        </div>
        {loading ? (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-start w-full max-w-2xl gap-4 mt-5">
            <Helmet>
              <title>New Time-Off Entry | HTPS ERP</title>
            </Helmet>
            <div className="flex flex-col items-start justify-start w-full">
              <label className="pb-1 text-xs text-gray-600 uppercase">Employee</label>
              <Select
                placeholder={"Select an employee"}
                name={"employeeId"}
                onChange={(val) => selectEmployee(val)}
                options={employees.map((employee) => ({
                  value: employee.userId,
                  label: `${employee.employeeCode} | ${employee.firstName} ${employee.lastName}`,
                }))}
                defaultValue={data.employeeId}
                className="w-full font-sans"
                controls={false}
                showSearch
                filterOption={filterOption}
              />
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-1">
              <label className="text-xs text-gray-600 uppercase">Taking full day/s off</label>
              <Switch defaultChecked={data.allDay} onChange={(v) => setData((prev) => ({ ...prev, allDay: v }))} />
            </div>
            <div className="flex flex-col items-start justify-start w-full">
              <label className="pb-1 text-xs text-gray-600 uppercase">Date / Time</label>
              <DatePicker.RangePicker
                showTime={data.allDay ? false : { minuteStep: 15, format: "HH:mm" }}
                format={data.allDay ? "MM-DD-YYYY" : "MM-DD-YYYY HH:mm"}
                style={{ width: "100%" }}
                changeOnBlur
                defaultValue={[data.startDate ? dayjs(data.startDate) : null, data.endDate ? dayjs(data.endDate) : null]}
                onChange={(dates) => updateDateRange(dates)}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full">
              <label className="pb-1 text-xs text-gray-600 uppercase">Status</label>
              <Select
                placeholder={"Select a status"}
                onChange={(val) => setData((prev) => ({ ...prev, status: val }))}
                options={[
                  {
                    label: "Approved",
                    value: "Approved",
                  },
                  {
                    label: "Withdrawn",
                    value: "Withdrawn",
                  },
                  {
                    label: "Rejected",
                    value: "Rejected",
                  },
                  {
                    label: "Tentative",
                    value: "Tentative",
                  },
                ]}
                defaultValue={data.status}
                className="w-full font-sans"
                controls={false}
                showSearch
                filterOption={filterOption}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full">
              <label className="pb-1 text-xs text-gray-600 uppercase">Time-Off Reason</label>
              <Select
                placeholder={"Select a reason"}
                onChange={(val) => setData((prev) => ({ ...prev, reason: val }))}
                options={[
                  {
                    label: "PTO",
                    value: "PTO",
                  },
                  {
                    label: "Bereavement",
                    value: "Bereavement",
                  },
                  {
                    label: "Jury Duty",
                    value: "Jury Duty",
                  },
                  {
                    label: "Election/Voting",
                    value: "Election/Voting",
                  },
                  {
                    label: "Holidays",
                    value: "Holidays",
                  },
                  {
                    label: "Military Leave",
                    value: "Military Leave",
                  },
                  {
                    label: "FMLA",
                    value: "FMLA",
                  },
                  {
                    label: "Personal",
                    value: "Personal",
                  },
                  {
                    label: "Vacation",
                    value: "Vacation",
                  },
                  {
                    label: "Sick",
                    value: "Sick",
                  },
                  {
                    label: "Extended Leave",
                    value: "Extended Leave",
                  },
                  {
                    label: "Casual Leave",
                    value: "Casual Leave",
                  },
                  {
                    label: "Compensatory Leave",
                    value: "Compensatory Leave",
                  },
                  {
                    label: "Sabbatical",
                    value: "Sabbatical",
                  },
                  {
                    label: "Unpaid Leave",
                    value: "Unpaid Leave",
                  },
                  {
                    label: "COVID Leave",
                    value: "COVID Leave",
                  },
                  {
                    label: "ADA Accommodations",
                    value: "ADA Accommodations",
                  },
                  {
                    label: "Other",
                    value: "Other",
                  },
                ]}
                defaultValue={data.reason}
                className="w-full font-sans"
                controls={false}
                showSearch
                filterOption={filterOption}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full">
              <label className="pb-1 text-xs text-gray-600 uppercase">Comments</label>
              <Input.TextArea
                placeholder={"Add any comments here"}
                onChange={(e) => setData((prev) => ({ ...prev, comment: e.target.value }))}
                rows={5}
                style={{ resize: "none" }}
                defaultValue={data.comment}
              />
            </div>
            <PrimaryButton label="Create Time-Off Entry" callback={() => submitEntry()} className="mt-5" />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewTimeOffEntry;
