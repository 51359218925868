import { SecondaryButton, PrimaryButton } from "../../components/buttons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { Input, Select, Table, Tooltip } from "antd";
import { QueryJobs } from "../../actions/jobs";
import { QueryCustomers } from "../../actions/customers";
import { AdvancedPartsQuery, GetDepartments, QueryEquipment } from "../../actions/ims";
import { formatCurrency } from "../../components/tools";

const _ = require("lodash");

const BLANK_FILTERS = {
  department: null,
  manufacturer: null,
  search: null,
};

const DEFAULT_SIZE = 12;

const AllParts = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [parts, setParts] = useState([]);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [filters, setFilters] = useState(BLANK_FILTERS);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetDepartments()
        .then((res) => {
          setDepartments(res.data);
          runPartsQuery(BLANK_FILTERS, 0, DEFAULT_SIZE);
        })
        .catch((err) => {
          toast.error("Error fetching department data");
          runPartsQuery(BLANK_FILTERS, 0, DEFAULT_SIZE);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const clearFilters = () => {
    setLoading(true);
    setFilters(BLANK_FILTERS);
    setParts([]);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runPartsQuery(BLANK_FILTERS, 0, DEFAULT_SIZE);
  };

  const updateFilter = ({ target: { name, value } }) => {
    let tmp = filters;
    tmp[name] = value;
    setFilters((prev) => ({ ...prev, [name]: value }));
    runPartsQuery(tmp, page, size);
  };

  const runPartsQuery = (data, offset, limit) => {
    AdvancedPartsQuery({
      search: data.search,
      department: data.department,
      manufacturer: data.manufacturer,
      page: offset,
      limit: limit,
    })
      .then((res) => {
        setParts(res.data.results);
        setTotal(res.data.total);
        setSize(res.data.size);
        setPage(res.data.page);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Failed to fetch parts");
        setParts([]);
        setTotal(0);
        setSize(DEFAULT_SIZE);
        setPage(0);
        setFilters(BLANK_FILTERS);
        setTimeout(() => setLoading(false), 700);
      });
  };

  const changePage = (pg, sz) => {
    setPage(pg);
    setSize(sz);
    setLoading(true);
    runPartsQuery(filters, pg, sz);
  };

  const applyFilters = () => {
    setLoading(true);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runPartsQuery(filters, 0, DEFAULT_SIZE);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };
  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const renderPartStock = (record) => {
    let NORTH_AUGUSTA = "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f";
    let AUGUSTA = "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf";
    let stock = record.stock;
    let augustaStock = 0;
    let northAugustaStock = 0;
    let na = record.stock.find((s) => s.location === NORTH_AUGUSTA);
    let ags = record.stock.find((s) => s.location === AUGUSTA);
    if (na) {
      northAugustaStock = na.quantityInStock;
    }
    if (ags) {
      augustaStock = ags.quantityInStock;
    }
    if (augustaStock === 0 && northAugustaStock === 0) {
      return <p>Out of Stock</p>;
    } else if (augustaStock > 0 && northAugustaStock === 0) {
      return <p>Augusta: {augustaStock}</p>;
    } else if (augustaStock === 0 && northAugustaStock > 0) {
      return <p>N. Augusta: {northAugustaStock}</p>;
    } else {
      return (
        <div className="flex flex-col items-start justify-start">
          <p>Augusta: {augustaStock}</p>
          <p>N. Augusta: {northAugustaStock}</p>
        </div>
      );
    }
  };

  const renderDepartment = (id) => {
    let dept = departments.find((d) => d.departmentId === id);
    if (dept) {
      return <Tooltip title={`${dept.name} | ${dept.label}`}>{dept.name}</Tooltip>;
    } else {
      return "Unknown";
    }
  };

  const updateDepartment = (v) => {
    let tmp = filters;
    tmp.department = v;
    setFilters((prev) => ({ ...prev, department: v }));
    runPartsQuery(tmp, page, size);
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Jobs | HTPS ERP</title>
      </Helmet>
      <div className="flex-grow flow-root w-full px-2 mt-3">
        <div className="-my-2">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-2">
              <div className="grid w-full h-full gap-6 mx-auto md:grid-cols-12">
                <div className="flex flex-col h-full col-span-3 gap-4">
                  <div className="flex flex-col items-start justify-start w-full gap-4 p-6 border border-gray-300 rounded-lg">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Filters</h3>
                    <div className="flex flex-col items-start justify-start w-full mt-4">
                      <label htmlFor="jobNo" className="pb-1 text-xs text-gray-600 uppercase">
                        Part No / Description
                      </label>
                      <Input
                        placeholder="Search by Part No / Description"
                        name="search"
                        onChange={(e) => {
                          updateFilter(e);
                        }}
                        value={filters.search}
                        autoComplete="off"
                        defaultValue={filters.search}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="manufacturer" className="pb-1 text-xs text-gray-600 uppercase">
                        Manufacturer
                      </label>
                      <Input
                        placeholder="Manufacturer"
                        autoComplete="off"
                        name="manufacturer"
                        onChange={(e) => {
                          updateFilter(e);
                        }}
                        value={filters.manufacturer}
                        defaultValue={filters.manufacturer}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="department" className="pb-1 text-xs text-gray-600 uppercase">
                        Department
                      </label>
                      <Select
                        placeholder="Search Departments"
                        onChange={(v) => updateDepartment(v)}
                        value={filters.department}
                        options={(departments || []).map((p) => ({
                          label: `${p.name} | ${p.label}`,
                          value: p.departmentId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No departments found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={filterOption}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        allowClear
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full gap-2">
                    <SecondaryButton
                      callback={() => clearFilters()}
                      label="Clear Filters"
                      className="block py-2.5 transition-all duration-300 normal-case w-full"
                    />
                    <PrimaryButton
                      callback={() => applyFilters()}
                      label="Apply Filters"
                      className="block py-2.5 transition-all duration-300 bg-black hover:bg-black/80 normal-case w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full col-span-9 gap-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Search Results</h3>
                    <button
                      className="inline-flex items-center justify-center h-8 px-4 py-2 leading-none text-sm font-medium transition-colors rounded-md whitespace-nowrap ring-offset-[#000064] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#18181b] focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#18181b] text-[#fafafa] hover:bg-[#18181b]/90"
                      onClick={() => navigate("/parts/new")}
                    >
                      New Part Profile
                    </button>
                  </div>
                  <Table
                    dataSource={parts}
                    loading={loading}
                    className="w-full"
                    sticky={true}
                    pagination={{
                      total: total,
                      responsive: true,
                      hideOnSinglePage: true,
                      onChange: (p, s) => changePage(p - 1, s),
                      pageSize: size,
                      current: page + 1,
                      showSizeChanger: false,
                    }}
                    rowKey="jobId"
                  >
                    <Table.Column title="Department" dataIndex="department" key="department" render={(d) => renderDepartment(d)} width={"120px"} />
                    <Table.Column title="Category" dataIndex="category" key="category" width={"100px"} align="center" render={(v) => v.toUpperCase()} />
                    <Table.Column title="Part No" dataIndex="partNo" key="partNo" width={"145px"} align="center" />
                    <Table.Column
                      title="Description"
                      dataIndex="description"
                      key="description"
                      render={(v) => (
                        <Tooltip title={v}>
                          <p className="truncate cursor-pointer">{v.length > 40 ? v.substring(0, 40) + "..." : v}</p>
                        </Tooltip>
                      )}
                    />
                    <Table.Column title="Stock" dataIndex="stock" key="stock" width={"120px"} render={(v, r) => renderPartStock(r)} />
                    <Table.Column title="Cost" dataIndex="cost" key="cost" render={(v) => formatCurrency(v)} align="right" width={"110px"} />
                    <Table.Column title="Charge Out" dataIndex="chargeOut" key="chargeOut" render={(v) => formatCurrency(v)} align="right" width={"110px"} />
                    <Table.Column
                      title=""
                      dataIndex="partId"
                      key="actions"
                      width={"80px"}
                      render={(v) => (
                        <SecondaryButton
                          callback={() => navigate(`/parts/${v}`)}
                          label="Open"
                          className="transition-all duration-300 border bg-white-smoke border-zinc-400 ring-0 hover:ring-0 hover:bg-zinc-100 hover:border-zinc-600 text-zinc-600 hover:text-zinc-900"
                        />
                      )}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllParts;
