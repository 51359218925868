import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { Calendar, Okrs, Packages, PasteClipboard, TaskList } from "iconoir-react";
import { Helmet } from "react-helmet-async";

const Documentation = ({ authState, authDispatch }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Documentation | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Documentation</p>
        </div>
        <p className="text-sm uppercase text-gray-500/90">Instructions on how to operate and get around the software</p>
        {authState.user.functionCategory !== "technician" ? (
          <div className="flex flex-col items-center justify-start w-full gap-2 px-1 mt-5 mb-10">
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Create_a_New_Customer__qgzrb_fnQ8iML0xcoU5wuQ", "_blank")}
            >
              <p className="font-medium text-gray-600">Creating new customers</p>
              <p className="text-sm text-gray-600/80">
                This guide provides step-by-step instructions on how to create a new customer in an ERP system. It includes detailed instructions on navigating
                to the necessary web page, filling in the required fields, and finalizing the customer creation process. By following this guide, users will be
                able to efficiently and accurately create a new customer in the system.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Editing_customer_information__Dp6ReYq-R_yN7AhOqNBKlA", "_blank")}
            >
              <p className="font-medium text-gray-600">Editing Customer's Information</p>
              <p className="text-sm text-gray-600/80">
                This guide provides step-by-step instructions on how to edit customer information in an ERP system. It covers navigating to the customers page,
                searching for a specific customer, and making changes to their contact information, AR information, as well as defaults. It is useful for anyone
                who needs to update customer information in an ERP system.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Adding_Equipment_to_ERP_System__sU1JvnitSrW4vVR4bcU-kA", "_blank")}
            >
              <p className="font-medium text-gray-600">Adding Equipment to ERP System</p>
              <p className="text-sm text-gray-600/80">
                This guide provides step-by-step instructions on how to add equipment to an ERP system. It is useful for individuals who need to input equipment
                data into the system and want a clear and concise guide to follow.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() =>
                window.open(
                  "https://scribehow.com/shared/Updating_Equipment_Details_Contact_Notes_Photos_and_Service_Schedule__zSVyFPRSSWGjqmnOv5zfLQ",
                  "_blank",
                )
              }
            >
              <p className="font-medium text-gray-600">Updating Equipment Details, Contact, Notes, Photos, and Service Schedule</p>
              <p className="text-sm text-gray-600/80">
                This guide provides step-by-step instructions on how to update equipment details, contact information, notes, photos, and service schedule on
                the ERP website. It is a comprehensive resource that will help users navigate the platform and make necessary changes to equipment information
                efficiently.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() =>
                window.open("https://scribehow.com/shared/Adding_a_New_Vendor_to_HiTech_Power_Systems_ERP_System__AlHYZHI9SFSnwqeCU9fwhQ", "_blank")
              }
            >
              <p className="font-medium text-gray-600">Adding a New Vendor to HiTech Power Systems ERP System</p>
              <p className="text-sm text-gray-600/80">
                This guide provides step-by-step instructions on how to add a new vendor to HiTech Power Systems ERP system. By following these steps, users can
                easily navigate through the system and enter all necessary information for the new vendor. This guide ensures a smooth and efficient process for
                adding vendors to the ERP system.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Step-by-Step_Guide_Updating_Vendors_Information__JMp0_uLxREKN__yEHDKPQw", "_blank")}
            >
              <p className="font-medium text-gray-600">Step-by-Step Guide: Updating Vendor's Information</p>
              <p className="text-sm text-gray-600/80">
                This step-by-step guide provides a clear and concise process for updating vendor information on a specific website. By following these steps,
                users can easily navigate to the vendor page, search for a specific vendor, update their information, and save the changes. This guide is
                essential for anyone who needs to update vendor information accurately and efficiently.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Creating_a_New_Part_Profile_in_ERP_System__BMGegmhdSZGVjyM39h2enw", "_blank")}
            >
              <p className="font-medium text-gray-600">Creating a New Part Profile in ERP System</p>
              <p className="text-sm text-gray-600/80">
                This guide provides step-by-step instructions on how to create a new part profile in an ERP system. Following these steps will help users easily
                navigate through the process of adding a new part, entering relevant information such as part number, cost, manufacturer, and quantity in stock.
                This guide is essential for anyone looking to efficiently manage parts in an ERP system.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/How_to_Create_a_Quote_and_Enter_Equipment_Details__u-_Pqn6UQR-PZLt2Ybjd1Q", "_blank")}
            >
              <p className="font-medium text-gray-600">How to Create a Quote and Enter Equipment Details</p>
              <p className="text-sm text-gray-600/80">
                This guide provides step-by-step instructions on how to create a quote and enter equipment details in an ERP system. By following these
                instructions, users will be able to easily navigate the system, select customers, fill in quote basics and details, attach equipment, review and
                create the quote. This guide is essential for anyone who needs to create professional quotes and manage equipment details efficiently.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Filling_out_a_quote_and_upgrading_it_to_a_job__hAyZkxGURL6unCl4FFfamg", "_blank")}
            >
              <p className="font-medium text-gray-600">Filling out a quote and upgrading it to a job</p>
              <p className="text-sm text-gray-600/80">
                This guide provides step-by-step instructions on how to fill out a quote and upgrade it to a job using a specific ERP system. It covers
                everything from adding labor and parts to miscellaneous charges and freight fees. Following this guide will help users efficiently navigate the
                system and successfully create a job based on a quote.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() =>
                window.open(
                  "https://scribehow.com/shared/Step-by-Step_Guide_Editing_Job_Details_and_progressing_it_into_an_Invoice__0U8l6p85SUeKyRtmzUu05w",
                  "_blank",
                )
              }
            >
              <p className="font-medium text-gray-600">Step-by-Step Guide: Editing Job Details and progressing it into an Invoice</p>
              <p className="text-sm text-gray-600/80">
                This step-by-step guide provides instructions on how to edit job details and progress them into an invoice. It covers various actions such as
                adding parts and labor, logging time, submitting for invoice approval, finalizing the invoice, collecting payment, and more. It is a
                comprehensive guide for anyone looking to efficiently manage and organize their job details and invoicing process.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() =>
                window.open(
                  "https://scribehow.com/shared/Updating_Equipment_Details_Contact_Notes_Photos_and_Service_Schedule__zSVyFPRSSWGjqmnOv5zfLQ",
                  "_blank",
                )
              }
            >
              <p className="font-medium text-gray-600">Updating Equipment Details, Contact, Notes, Photos, and Service Schedule</p>
              <p className="text-sm text-gray-600/80">
                This guide provides step-by-step instructions on how to update equipment details, contact information, notes, photos, and service schedule on
                the ERP website. It is a comprehensive resource that will help users navigate the platform and make necessary changes to equipment information
                efficiently.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/How_to_Create_a_Quote_and_Enter_Equipment_Details__u-_Pqn6UQR-PZLt2Ybjd1Q", "_blank")}
            >
              <p className="font-medium text-gray-600">How to Create a Quote and Enter Equipment Details</p>
              <p className="text-sm text-gray-600/80">
                This guide provides step-by-step instructions on how to create a quote and enter equipment details in an ERP system. By following these
                instructions, users will be able to easily navigate the system, select customers, fill in quote basics and details, attach equipment, review and
                create the quote. This guide is essential for anyone who needs to create professional quotes and manage equipment details efficiently.
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-start w-full gap-2 px-1 mt-5 mb-10">
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Accessing_the_Platform__e1LOScjuQBmJm8ifytUtQw", "_blank")}
            >
              <p className="font-medium text-gray-600">Accessing the Platform</p>
              <p className="text-sm text-gray-600/80">
                This guide provides step-by-step instructions on how to log into a website using an email and password.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Step-by-Step_Guide_Looking_up_customers__lqzFGUmaQB2brEs024mVRA", "_blank")}
            >
              <p className="font-medium text-gray-600">Looking up Customers</p>
              <p className="text-sm text-gray-600/80">
                This guide provides step-by-step instructions on how to look up customers in your dashboard. It explains how to search for customers using
                different criteria, view their profiles, and access additional information such as contact details, A/R information, equipment, quotes, jobs,
                and invoices. If you need to find specific customer details quickly and efficiently, this guide will help you navigate through the process.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Step-by-Step_Guide_Looking_up_Equipment__uiJDVnofSgChOBVBVCaevQ", "_blank")}
            >
              <p className="font-medium text-gray-600">Looking up Equipment</p>
              <p className="text-sm text-gray-600/80">
                This step-by-step guide provides instructions on how to look up equipment in a dashboard. By following these steps, you can easily search for
                specific equipment, view its profile, and access additional information such as history, quotes, jobs, invoices, and more.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Step-by-Step_Guide_Looking_up_and_previewing_quotes__TSF9xrSRSUWJNTilqFTawg", "_blank")}
            >
              <p className="font-medium text-gray-600">Looking up and previewing Quotes</p>
              <p className="text-sm text-gray-600/80">
                This step-by-step guide explains how to easily look up and preview quotes on your dashboard. It provides clear instructions on accessing the
                sidebar, searching for specific quotes, and previewing them. By following this guide, you can quickly retrieve and preview quotes, making it a
                valuable resource for anyone needing to access and review quote information efficiently.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Step-by-Step_Guide_Looking_up_Invoices__v0w_KwrSQ5mEVIjR66qlPQ", "_blank")}
            >
              <p className="font-medium text-gray-600">Looking up and previewing Invoices</p>
              <p className="text-sm text-gray-600/80">
                This step-by-step guide provides clear instructions on how to look up invoices. It covers accessing the dashboard, searching for specific
                invoices, and viewing and printing the invoices. This guide is beneficial for anyone who needs to quickly and efficiently retrieve and view
                invoices for reference or printing purposes.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Step-by-Step_Guide_Checking_part_stock__4kRz-DYiRJiz4oXJhX6rZw", "_blank")}
            >
              <p className="font-medium text-gray-600">Checking part stock</p>
              <p className="text-sm text-gray-600/80">
                This step-by-step guide provides a quick and easy way to check part stock. By following these steps, you can efficiently search for specific
                parts, view matching results, and access detailed information about each part, including stock availability divided by location. This guide is
                essential for anyone who needs to manage and track part inventory effectively.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() =>
                window.open("https://scribehow.com/shared/Step-by-Step_Guide_Retrieving_checklists_test_and_certifications__dxAQ_-DHTGq_cazK_J_jtQ", "_blank")
              }
            >
              <p className="font-medium text-gray-600">Retrieving checklists, test, and certifications</p>
              <p className="text-sm text-gray-600/80">
                This step-by-step guide provides clear instructions on how to retrieve checklists, tests, and certifications from your dashboard. It explains
                how to search for specific documents, access customer information, job details, and equipment profiles. Additionally, it outlines the option to
                download and print a copy of the document if needed. This guide is essential for anyone needing to efficiently locate and obtain important
                documents.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() =>
                window.open("https://scribehow.com/shared/Step-by-Step_Guide_Retrieving_Jobs_and_logging_labor_time__gOP2FxliTEGJPD02vTc-GA", "_blank")
              }
            >
              <p className="font-medium text-gray-600">Retrieving jobs and logging labor time</p>
              <p className="text-sm text-gray-600/80">
                This step-by-step guide provides instructions on how to retrieve jobs and log labor time. It covers various aspects such as searching for jobs,
                previewing quotes and customer profiles, adding internal notes, viewing parts and labor assigned to the job, logging labor time, and accessing
                other relevant information such as parking/storage fees, loadbank fees, freight fees, purchase orders, inventory transmittals, equipment
                details, and completed checklists/tests/certifications. This guide is useful for anyone who needs to efficiently navigate and manage job-related
                information in a comprehensive manner.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Step-by-Step_Guide_Filling_out_timesheets__MA7CFnK-S6SAY2mRE2_nQA", "_blank")}
            >
              <p className="font-medium text-gray-600">Filling out timesheets</p>
              <p className="text-sm text-gray-600/80">
                This step-by-step guide provides clear instructions on how to fill out timesheets. It covers everything from logging regular work hours to
                adding R&M time. By following this guide, users will be able to accurately and efficiently track their time and have a clear summary of their
                hours worked during a pay period.
              </p>
            </div>
            <div
              className="flex flex-col items-start justify-start w-full gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
              onClick={() => window.open("https://scribehow.com/shared/Step-by-Step_Guide_Updating_your_profile_information__20R8SFM6R7yQ-p33a7YSXA", "_blank")}
            >
              <p className="font-medium text-gray-600">Updating your profile information</p>
              <p className="text-sm text-gray-600/80">
                This step-by-step guide provides clear instructions on how to update your profile information. It covers accessing the settings, replacing old
                data with new information, and saving the changes. It also includes specific instructions for updating your emergency contact. It is a useful
                resource for anyone who needs to update their profile details quickly and easily.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Documentation;
