import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DeleteJobPayment, GetEmployees, GetOneJob, LogJobPayment, SubmitJobWarranty, UpdateJobPayment } from "../../actions/jobs";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { Input, Modal, Popconfirm, Select, InputNumber, Switch, DatePicker } from "antd";
import { formatCurrency } from "../../components/tools";
import { Printer } from "iconoir-react";
import { ReprintReceipt } from "../../actions/util";
import { MaskedInput } from "antd-mask-input";
import { EmailAReceipt, TextAReceipt } from "../../actions/util";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { GetTerminals, RetryCapture, AbortTransaction, SaleStatus, CheckCardSale, StartSale, CreateManualIntent } from "../../actions/payments";
import { BadgeCheck, Loader, Trash2 } from "lucide-react";
import { RefreshDouble } from "iconoir-react";
import validator from "validator";
import { Helmet } from "react-helmet-async";

var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

var stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const JobPayments = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [job, setJob] = useState({});
  let [employees, setEmployees] = useState([]);
  let [paymentModal, setPaymentModal] = useState(false);
  let [paymentAmount, setPaymentAmount] = useState(null);
  let [paymentStep, setPaymentStep] = useState(0);
  let [saleId, setSaleId] = useState(null);
  let [saleIds, setSaleIds] = useState([]);
  let [processing, setProcessing] = useState(false);
  let [intent, setIntent] = useState(null);
  let [terminal, setTerminal] = useState(null);
  let [terminals, setTerminals] = useState([]);
  let [terminalLoad, setTerminalLoad] = useState(false);
  let [retryTimeout, setRetryTimeout] = useState(true);
  let [paymentError, setPaymentError] = useState("");
  let [emailReceipt, setEmailReceipt] = useState(false);
  let [smsReceipt, setSmsReceipt] = useState(false);
  let [dummyLoading, setDummyLoading] = useState(true);
  let [receiptInfo, setReceiptInfo] = useState({
    email: null,
    phone: null,
  });
  let [paymentInfo, setPaymentInfo] = useState({
    date: null,
    amount: 0,
    method: null,
    notes: null,
    checkNo: null,
    nameOnTheCheck: null,
  });
  let [logPaymentModal, setLogPaymentModal] = useState(false);
  let [editPaymentModal, setEditPaymentModal] = useState(false);

  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      reloadData();
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const reloadData = () => {
    GetOneJob(jobId)
      .then((res) => {
        setJob(res.data.job);
        reloadTerminals();
        GetEmployees()
          .then((res) => {
            setEmployees(res.data);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading employees");
            setTimeout(() => setLoading(false), 700);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
        setTimeout(() => {
          navigate("/jobs");
        }, 3000);
      });
  };

  const reloadTerminals = () => {
    setTerminalLoad(true);
    GetTerminals()
      .then((res) => {
        setTerminals(res.data);
        if (terminal) {
          let newTerminal = res.data.find((pos) => pos.id === terminal.id);
          if (newTerminal) {
            setTerminal(newTerminal);
          } else {
            setTerminal(null);
          }
        }
        setTimeout(() => {
          setTerminalLoad(false);
        }, 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setTimeout(() => {
          setTerminalLoad(false);
        }, 700);
      });
  };

  const tabs = [
    { name: "Overview", href: "#", current: false },
    { name: "Parts", href: "parts", current: false },
    { name: "Labor", href: "labor", current: false },
    { name: "Parking/Storage", href: "parking", current: false },
    { name: "Loadbank", href: "loadbank", current: false },
    { name: "Freight", href: "freight", current: false },
    { name: "Misc", href: "misc", current: false },
    { name: "Purchase Orders", href: "purchaseOrders", current: false },
    {
      name: "Inventory Transmittals",
      href: "inventoryTransmittals",
      current: false,
    },
    { name: "Equipment", href: "equipment", current: false },
    { name: "Tests", href: "tests", current: false },
    { name: "Warranty", href: "warranty", current: false },
    { name: "Payments", href: "payments", current: true },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const findEmployee = (id) => {
    let employee = id;
    let found = employees.find((emp) => emp.userId === id);
    if (found) {
      employee = found.firstName + " " + found.lastName;
    }
    return employee;
  };

  const printReceipt = (saleId, location) => {
    ReprintReceipt({ saleId: saleId, terminal: location })
      .then((res) => {
        toast.success("Receipt printed successfully");
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error printing receipt");
      });
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
    setPaymentAmount(null);
    setPaymentStep(0);
    setSaleId(null);
    setProcessing(false);
    setIntent(false);
    setTerminal(null);
    setTerminalLoad(false);
    setRetryTimeout(false);
    setPaymentError("");
    setEmailReceipt(false);
    setSmsReceipt(false);
    setReceiptInfo({ email: null, phone: null });
    setDummyLoading(true);
    setTimeout(() => setDummyLoading(false), 700);
    reloadData();
  };

  const renderPaymentStep = () => {
    if (processing) {
      return (
        <div className="flex items-center flex-col justify-center w-full h-full min-h-[240px] gap-2">
          <Loader size={24} className="text-gray-300 animate-spin" />
          <p className="text-lg font-semibold text-gray-500 animate-pulse">PROCESSING</p>
        </div>
      );
    } else {
      if (paymentStep === 0) {
        // Payment Amount and Method
        return (
          <div className="flex flex-col items-start justify-start w-full gap-2 pt-4">
            <p>Amount to collect</p>
            <InputNumber
              placeholder="Dollar amount to collect"
              step={0.01}
              min={0.5}
              addonBefore="$"
              onChange={(v) => setPaymentAmount(v)}
              className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
              controls={false}
            />
            <div className="flex items-center justify-end w-full gap-2 mt-4 -mb-2">
              <SecondaryButton label="Cancel" callback={() => closePaymentModal()} />
              <PrimaryButton label="Enter Card" callback={() => startCardPayment()} />
              <PrimaryButton label="Terminal Sale" callback={() => startTerminalPayment()} />
            </div>
          </div>
        );
      } else if (paymentStep === 1) {
        // Card Payment - Enter card details
        const stripeOptions = {
          clientSecret: intent.client_secret,
          appearance: {
            theme: "stripe",
          },
        };
        return (
          <div className="flex flex-col items-start justify-start w-full gap-2 pt-4">
            <p>Enter Card Details</p>
            {!processing && (
              <Elements stripe={stripePromise} options={stripeOptions}>
                <StripeForm intent={intent} saleId={saleId} cancelSale={cancelCardSale} nextStep={cardNextStep} />
              </Elements>
            )}
          </div>
        );
      } else if (paymentStep === 2) {
        // Terminal Payment - Select a terminal
        return (
          <div className="flex flex-col items-start justify-start w-full gap-2 pt-4">
            <div className="flex flex-row items-center justify-between w-full px-1 mt-2 text-xs text-left text-gray-500">
              <p>Terminal to use:</p>
              <div
                className="flex flex-row items-center justify-end gap-1 uppercase duration-150 cursor-pointer hover:text-gray-800"
                onClick={() => reloadTerminals()}
              >
                <p>Reload Terminals</p>
                <RefreshDouble className="w-3 h-3" />
              </div>
            </div>
            {!terminalLoad && (
              <Select
                options={terminals.map((t) => ({
                  value: t.id,
                  label: t.label,
                }))}
                onChange={(e) => setTerminal(terminals.find((t) => t.id === e))}
                className="w-full font-sans"
                controls={false}
                placeholder="Select a terminal"
                optionRender={(opt, info) => {
                  return (
                    <div className="flex items-center justify-start gap-2">
                      <p>{opt.label}</p>
                      {terminal && terminal.id === opt.value && <BadgeCheck className="w-4 h-4 text-green-500" />}
                    </div>
                  );
                }}
                defaultValue={terminal ? terminal.id : null}
              />
            )}
            <div className="flex items-center justify-end w-full gap-2 mt-4 -mb-2">
              <SecondaryButton label="Cancel" callback={() => closePaymentModal()} />
              <PrimaryButton label="Next" callback={() => processTerminalPayment()} />
            </div>
          </div>
        );
      } else if (paymentStep === 3) {
        // Terminal Payment - Processing
        return (
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-4">
            <h3 className="text-base font-semibold leading-6 text-center text-gray-900">Terminal Transaction is Processing...</h3>
            <div className="mt-2">
              <p className="text-sm text-center text-gray-500">Please instruct the customer to complete the sale on the terminal.</p>
              <p className="text-sm text-center text-gray-500">Once the customer is done, capture the payment by clicking on the button below.</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-2 mt-5">
              <SecondaryButton callback={() => closePaymentModal()} disabled={processing} label="CANCEL" />
              <SecondaryButton callback={() => retryCapture()} disabled={retryTimeout} label="Retry" />
            </div>
          </div>
        );
      } else if (paymentStep === 4) {
        // Terminal Payment - Payment Success [ Print / Send Receipt ]
        return (
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-4">
            <h3 className="text-base font-semibold leading-6 text-center text-gray-900">SUCCESS!</h3>
            <div className="mt-2">
              <p className="text-sm text-center text-gray-500">Payment for {formatCurrency(paymentAmount)} has been captured successfully!</p>
              <p className="text-xs text-center text-gray-500">A copy of the receipt will be printed automatically!</p>
              <p className="text-sm font-medium text-center text-black">Would you like to send an e-receipt to the customer as well?</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-2 mt-5">
              <SecondaryButton callback={() => setPaymentStep(7)} label="Send E-Receipt" />
              <PrimaryButton callback={() => closePaymentModal()} label="Finish" />
            </div>
          </div>
        );
      } else if (paymentStep === 5) {
        // Terminal Payment - Payment Failed
        return (
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-4">
            <h3 className="text-base font-semibold leading-6 text-center text-gray-900">Payment Capture Failed!</h3>
            <div className="mt-2">
              <p className="text-sm text-center text-gray-500">
                There was an error capturing the payment. Press the button below to try again or cancel the sale.
              </p>
            </div>
            <div className="mt-2">
              <p className="text-sm text-center text-gray-500">{paymentError}</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-2 mt-5">
              <SecondaryButton callback={() => cancelTerminalSale()} disabled={processing} label="CANCEL SALE" />
              <PrimaryButton callback={() => retryCapture()} label="RETRY" />
            </div>
          </div>
        );
      } else if (paymentStep === 6) {
        // Card Payment - Payment Success [ Print / Send Receipt ]
        return (
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-4">
            <h3 className="text-base font-semibold leading-6 text-center text-gray-900">SUCCESS!</h3>
            <div className="mt-2">
              <p className="text-sm text-center text-gray-500">Payment for {formatCurrency(paymentAmount)} has been captured successfully!</p>
              <p className="text-xs text-center text-gray-500">A copy of the receipt will be printed automatically!</p>
              <p className="text-sm font-medium text-center text-black">Would you like to send an e-receipt to the customer as well?</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-2 mt-5">
              <SecondaryButton callback={() => setPaymentStep(7)} label="Send E-Receipt" />
              <PrimaryButton callback={() => closePaymentModal()} label="Finish" />
            </div>
          </div>
        );
      } else if (paymentStep === 7) {
        // UNIVERSAL - Send E-Receipt
        return (
          <div className="flex flex-col items-start justify-start w-full gap-2 pt-4">
            <h3 className="text-base font-semibold leading-6 text-center text-gray-900">Send a receipt</h3>
            <div className="flex flex-col items-start justify-center w-full gap-4 mt-2">
              <div className="flex flex-col items-start justify-between w-full gap-2">
                <div className="flex items-center justify-between w-full">
                  <p className="text-sm text-center text-gray-500">Send an Email copy of the receipt</p>
                  <Switch defaultChecked={emailReceipt} onChange={(chk) => setEmailReceipt(chk)} />
                </div>
                <Input
                  placeholder="Customer's Email Address"
                  onChange={(e) => updateReceiptVals("email", e.target.value)}
                  className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                  disabled={!emailReceipt}
                />
              </div>
              <div className="flex flex-col items-start justify-between w-full gap-2">
                <div className="flex items-center justify-between w-full">
                  <p className="text-sm text-center text-gray-500">Send an SMS copy of the receipt</p>
                  <Switch defaultChecked={smsReceipt} onChange={(chk) => setSmsReceipt(chk)} />
                </div>
                <MaskedInput
                  placeholder="Customer's Phone Number"
                  mask={"(000) 000-0000"}
                  className="py-1 text-gray-900 border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 phoneInput"
                  onChange={(e) => updateReceiptVals("phone", e.unmaskedValue)}
                  disabled={!smsReceipt}
                />
              </div>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-2 mt-5">
              <SecondaryButton callback={() => closePaymentModal()} label="Finish" />
              <PrimaryButton callback={() => sendEReceipt()} label="Send E-Receipt" />
            </div>
          </div>
        );
      }
    }
  };

  const updateReceiptVals = (field, value) => {
    let tmp = receiptInfo;
    tmp[field] = value;
    setReceiptInfo(tmp);
  };

  const sendEReceipt = async () => {
    let valid = true;
    if (!emailReceipt && !smsReceipt) {
      valid = false;
      toast.error("Please select at least one method to send the receipt.");
    } else if (emailReceipt && (!receiptInfo.email || !validator.isEmail(receiptInfo.email))) {
      valid = false;
      toast.error("Please enter a valid email address to send the receipt.");
    } else if (smsReceipt && (!receiptInfo.phone || !validator.isMobilePhone(receiptInfo.phone))) {
      valid = false;
      toast.error("Please enter a valid phone number to send the receipt.");
    }
    if (valid) {
      let failed = false;
      if (emailReceipt) {
        try {
          await EmailAReceipt(saleId, receiptInfo.email);
        } catch (err) {
          toast.error("Error sending email receipt");
          failed = true;
        }
      }
      if (smsReceipt) {
        try {
          await TextAReceipt(saleId, receiptInfo.phone);
        } catch (err) {
          toast.error("Error sending SMS receipt");
          failed = true;
        }
      }
      if (!failed) {
        toast.success("Receipt has been sent successfully.");
        closePaymentModal();
      }
    }
  };

  const processTerminalPayment = () => {
    if (!terminal || !terminal.id) {
      toast.error("Please select a terminal");
      reloadTerminals();
      return;
    }
    if (terminal.status !== "online") {
      toast.error("Terminal not online, please select another terminal or resolve the issue.");
      reloadTerminals();
      return;
    }
    setProcessing(true);
    StartSale({
      terminal: terminal.id,
      labor: 0,
      reference: jobId,
      category: "job",
      tax: 0,
      includeTax: false,
      parts: 0,
      gog: 0,
      misc: 0,
      shopFee: 0,
      freight: 0,
      services: paymentAmount,
      processingFees: calculateProcessingFee(paymentAmount),
      total: paymentAmount + calculateProcessingFee(paymentAmount),
      subtotal: paymentAmount,
      manualSale: true,
    })
      .then((res) => {
        setSaleId(res.data.sale);
        let tmp = saleIds;
        tmp.push({ id: res.data.sale, amount: paymentAmount });
        setSaleIds(tmp);
        setRetryTimeout(true);
        setTimeout(() => {
          setRetryTimeout(false);
        }, 15000);
        setPaymentStep(3);
        setTimeout(() => startStatusPing(res.data.sale), 10000);
        setProcessing(false);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error has occurred while creating a payment intent, please try again.");
        setProcessing(false);
      });
  };

  const cancelTerminalSale = () => {
    setProcessing(true);
    let tmp = saleIds;
    tmp = tmp.filter((s) => s.id !== saleId);
    setSaleIds(tmp);
    AbortTransaction({ terminal: terminal.id, saleId })
      .then((res) => {
        setProcessing(false);
        toast.success("Sale cancelled successfully");
        closePaymentModal();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setProcessing(false);
        closePaymentModal();
      });
  };

  const retryCapture = () => {
    setProcessing(true);
    RetryCapture({ saleId, terminal: terminal.id })
      .then((res) => {
        setSaleId(res.data.sale);
        let tmp = saleIds;
        tmp.push({ id: res.data.sale, amount: paymentAmount });
        setSaleIds(tmp);
        setPaymentStep(3);
        setRetryTimeout(true);
        setPaymentError("");
        setTimeout(() => {
          setRetryTimeout(false);
        }, 15000);
        setTimeout(() => startStatusPing(res.data.sale), 2000);
        setProcessing(false);
      })
      .catch((err) => {
        setPaymentStep(5);
        setProcessing(false);
        toast.error(err.response.data.message);
      });
  };

  const startStatusPing = (sale) => {
    SaleStatus({ saleId: sale })
      .then((res) => {
        if (res.data.status === "processing" || res.data.status === "pending" || res.data.status === "requires_capture") {
          setTimeout(() => {
            setPaymentError("");
            startStatusPing(sale);
          }, 2000);
        } else if (res.data.status === "succeeded") {
          setPaymentStep(4);
          setProcessing(false);
        } else if (res.data.status === "requires_payment_method") {
          if (res.data.paymentIntent.last_payment_error && paymentError === "") {
            toast.error(res.data.paymentIntent.last_payment_error.message);
            setPaymentError(res.data.paymentIntent.last_payment_error.message);
            setPaymentStep(5);
            setProcessing(false);
          } else {
            setTimeout(() => startStatusPing(sale), 2000);
          }
        } else {
          setPaymentStep(3);
          setProcessing(false);
        }
      })
      .catch((err) => {
        setTimeout(() => startStatusPing(sale), 2000);
      });
  };

  const cancelCardSale = () => {
    setProcessing(true);
    let tmp = saleIds;
    tmp = tmp.filter((s) => s.id !== saleId);
    setSaleIds(tmp);
    if (terminal) {
      AbortTransaction({ terminal: terminal.id, saleId: saleId })
        .then((res) => {
          setProcessing(false);
          toast.success("Sale has been cancelled successfully.");
          closePaymentModal();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error has occurred while cancelling the sale, please try again.");
          setProcessing(false);
          closePaymentModal();
        });
    } else {
      setProcessing(false);
      toast.success("Sale has been cancelled successfully.");
      closePaymentModal();
    }
  };

  const cardNextStep = () => {
    setPaymentStep(6);
  };

  const calculateProcessingFee = (amount) => {
    let processingFee = parseFloat((amount * 0.03).toFixed(2));
    return processingFee;
  };

  const startCardPayment = () => {
    if (!paymentAmount || paymentAmount < 0.5) {
      toast.error("Please enter a valid amount to collect.");
      return;
    }
    setProcessing(true);
    CreateManualIntent({
      labor: 0,
      reference: jobId,
      category: "job",
      tax: 0,
      includeTax: false,
      parts: 0,
      gog: 0,
      misc: 0,
      shopFee: 0,
      freight: 0,
      services: paymentAmount,
      processingFees: calculateProcessingFee(paymentAmount),
      total: paymentAmount + calculateProcessingFee(paymentAmount),
      subtotal: paymentAmount,
      manualSale: true,
    })
      .then((res) => {
        setSaleId(res.data.saleId);
        let tmp = saleIds;
        tmp.push({ id: res.data.saleId, amount: paymentAmount });
        setSaleIds(tmp);
        setIntent(res.data.intent);
        setPaymentStep(1);
        setTimeout(() => setProcessing(false), 500);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error has occurred while creating a payment intent, please try again.");
        setProcessing(false);
      });
  };

  const startTerminalPayment = () => {
    if (!paymentAmount || paymentAmount < 0.5) {
      toast.error("Please enter a valid amount to collect.");
      return;
    }
    setPaymentStep(2);
  };

  const closeLogPaymentModal = () => {
    setLogPaymentModal(false);
    setPaymentInfo({
      date: null,
      amount: 0,
      method: null,
      notes: null,
      checkNo: null,
      nameOnTheCheck: null,
    });
  };

  const submitLogPayment = () => {
    if (!paymentInfo.date || !dayjs(paymentInfo.date).isValid()) {
      toast.error("Please select a payment date");
      return;
    }
    if (paymentInfo.amount <= 0) {
      toast.error("Please enter a valid payment amount");
      return;
    }
    if (!paymentInfo.method || paymentInfo.method.length < 4) {
      toast.error("Please select a payment method");
      return;
    }
    if (paymentInfo.method === "check" && (!paymentInfo.checkNo || paymentInfo.checkNo.length < 1)) {
      toast.error("Please enter a valid check number");
      return;
    }
    setLoading(true);
    LogJobPayment(jobId, paymentInfo)
      .then((res) => {
        toast.success("Payment logged successfully");
        closeLogPaymentModal();
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error logging payment");
        setLoading(false);
      });
  };

  const startEditPayment = (payment) => {
    setPaymentInfo(payment);
    setTimeout(() => setEditPaymentModal(true), 700);
  };

  const closeEditPaymentModal = () => {
    setEditPaymentModal(false);
    setPaymentInfo({
      date: null,
      amount: 0,
      method: null,
      notes: null,
      checkNo: null,
      nameOnTheCheck: null,
    });
    reloadData();
  };

  const editPayment = () => {
    if (!paymentInfo.date || !dayjs(paymentInfo.date).isValid()) {
      toast.error("Please select a payment date");
      return;
    }
    if (paymentInfo.amount <= 0) {
      toast.error("Please enter a valid payment amount");
      return;
    }
    if (!paymentInfo.method || paymentInfo.method.length < 4) {
      toast.error("Please select a payment method");
      return;
    }
    if (paymentInfo.method === "check" && (!paymentInfo.checkNo || paymentInfo.checkNo.length < 1)) {
      toast.error("Please enter a valid check number");
      return;
    }
    setLoading(true);
    UpdateJobPayment(jobId, paymentInfo)
      .then((res) => {
        toast.success("Payment updated successfully");
        closeEditPaymentModal();
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating payment");
        setLoading(false);
      });
  };

  const deletePayment = (paymentId) => {
    setLoading(true);
    DeleteJobPayment(jobId, paymentId)
      .then((res) => {
        toast.success("Payment deleted successfully");
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error deleting payment");
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Payments - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      value={tab.name}
                      key={uuidv4()}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Job Payments</p>
              {authState.user.functionCategory !== "technician" && job.jobStatus === "open" && (
                <div className="flex items-center justify-end gap-2">
                  <SecondaryButton label="Log Payment" callback={() => setLogPaymentModal(true)} />
                  <PrimaryButton label="Collect Payment" callback={() => setPaymentModal(true)} />
                </div>
              )}
            </div>
            <div className="flex flex-col items-center justify-start w-full gap-2 mt-4 overflow-y-auto text-black">
              {job.payments.map(
                (payment) =>
                  payment.status === "succeeded" && (
                    <div className="flex flex-row items-center justify-between w-full px-8 py-4 text-green-700 bg-green-100 rounded-md shadow-sm">
                      <p>{dayjs(payment.date).format("MMMM Do[,] YYYY [at] h:mm A")}</p>
                      {payment.saleReference ? (
                        <p>Collected By: {findEmployee(payment.collectedBy) ?? payment.collectedBy}</p>
                      ) : (
                        <p className="text-sm text-green-800">
                          [{payment.method.toUpperCase()}]{" "}
                          {payment.method === "check"
                            ? `Check No: ${payment.checkNo} ${
                                payment.nameOnTheCheck && payment.nameOnTheCheck.length > 0 ? "| Name: " + payment.nameOnTheCheck : ""
                              } `
                            : ""}
                          {payment.notes && payment.notes.length > 0 ? "Note: " + payment.notes + " | " : ""} | Collected By: {payment.collectedBy}
                        </p>
                      )}
                      <p className="font-semibold">{formatCurrency(payment.amount)}</p>
                      {payment.saleReference ? (
                        <Popconfirm
                          placement="topRight"
                          title="Location"
                          description="Select a location where you'd like to print this receipt"
                          okText="Augusta, GA"
                          cancelText="North Augusta, SC"
                          icon={<Printer className="text-green-600" />}
                          onCancel={() => printReceipt(payment.saleReference, "tmr_FDp7QwhBsyuvKe")}
                          onConfirm={() => printReceipt(payment.saleReference, "tmr_FDp6LAZdjM5CBc")}
                        >
                          <button className="inline-flex items-center gap-x-1.5 rounded-md text-green-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm hover:bg-green-50 ring-1 rind-inset ring-green-300 disabled:hover:ring-green-400 hover:ring-green-900 transition-all duration-150 disabled:hover:cursor-not-allowed disabled:text-green-300 disabled:hover:text-green-400">
                            Print Receipt
                          </button>
                        </Popconfirm>
                      ) : (
                        job.jobStatus === "open" && (
                          <div className="flex items-center justify-end gap-2">
                            <button
                              className="inline-flex items-center gap-x-1.5 rounded-md text-green-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm hover:bg-green-50 ring-1 rind-inset ring-green-300 disabled:hover:ring-green-400 hover:ring-green-900 transition-all duration-150 disabled:hover:cursor-not-allowed disabled:text-green-300 disabled:hover:text-green-400"
                              onClick={() => startEditPayment(payment)}
                            >
                              Edit Payment
                            </button>
                            <Popconfirm
                              placement="topRight"
                              title="Delete Payment"
                              description="Are you sure you want to delete this payment? This action cannot be undone."
                              okText="I'm sure"
                              cancelText="Cancel"
                              icon={<Trash2 size={16} className="mr-1 text-red-600 mt-0.5" />}
                              onCancel={() => {}}
                              onConfirm={() => deletePayment(payment.paymentId)}
                            >
                              <button className="inline-flex items-center gap-x-1.5 rounded-md text-red-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm bg-red-50 hover:bg-red-200 ring-1 rind-inset ring-red-300 disabled:hover:ring-red-400 hover:ring-red-900 transition-all duration-150 disabled:hover:cursor-not-allowed disabled:text-red-300 disabled:hover:text-red-400">
                                Delete Payment
                              </button>
                            </Popconfirm>
                          </div>
                        )
                      )}
                    </div>
                  ),
              )}
              {job.payments.length === 0 && (
                <div className="flex items-center justify-center w-full py-5">
                  <p className="font-semibold text-gray-500">NO PAYMENTS RECORDED</p>
                </div>
              )}
            </div>
          </div>
          <Modal open={paymentModal} destroyOnClose onCancel={() => closePaymentModal()} title="Collect Payment" centered width={800} footer={[]}>
            {renderPaymentStep()}
          </Modal>
          <Modal
            open={logPaymentModal}
            title="Log Payment"
            destroyOnClose
            onCancel={() => closeLogPaymentModal()}
            onOk={() => submitLogPayment()}
            centered
            width={600}
          >
            <div className="flex flex-col items-start justify-start w-full gap-4 py-4">
              <div className="flex flex-col items-start justify-start w-full">
                <label className="pb-2 text-xs text-gray-600 uppercase">Payment Date</label>
                <DatePicker
                  placeholder="Payment Date"
                  name={"paymentDate"}
                  format={"MM/DD/YYYY"}
                  onChange={(date) => {
                    setPaymentInfo({
                      ...paymentInfo,
                      date: date ? date.toJSON() : null,
                    });
                  }}
                  className="w-full"
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <label className="pb-2 text-xs text-gray-600 uppercase">Payment Amount</label>
                <InputNumber
                  placeholder="Enter the payment amount"
                  name="paymentAmount"
                  onChange={(v) => setPaymentInfo({ ...paymentInfo, amount: v })}
                  className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 !focus:ring-0 !focus:shadow-none"
                  controls={false}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <label className="pb-2 text-xs text-gray-600 uppercase">Payment Method</label>
                <Select
                  placeholder={"Select a payment method"}
                  name="paymentMethod"
                  onChange={(v) => setPaymentInfo({ ...paymentInfo, method: v })}
                  options={[
                    { value: "cash", label: "Cash" },
                    { value: "check", label: "Check" },
                    { value: "card", label: "Card" },
                    { value: "other", label: "Other" },
                  ]}
                  className="w-full font-sans"
                  controls={false}
                  showSearch
                  filterOption={filterOption}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <label className="pb-2 text-xs text-gray-600 uppercase">Notes</label>
                <Input.TextArea
                  placeholder="Add notes about the payment"
                  name="paymentNotes"
                  onChange={(e) => setPaymentInfo({ ...paymentInfo, notes: e.target.value })}
                  rows={4}
                  autoSize={false}
                  className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                />
              </div>
              {paymentInfo.method === "check" && (
                <>
                  <div className="flex flex-col items-start justify-start w-full">
                    <label className="pb-2 text-xs text-gray-600 uppercase">Check No</label>
                    <Input
                      placeholder="Check No"
                      name="checkNo"
                      onChange={(e) =>
                        setPaymentInfo({
                          ...paymentInfo,
                          checkNo: e.target.value,
                        })
                      }
                      defaultValue={paymentInfo.checkNo ? paymentInfo.checkNo : null}
                      className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full">
                    <label className="pb-2 text-xs text-gray-600 uppercase">Name On The Check</label>
                    <Input
                      placeholder="Name on the check"
                      name="nameOnTheCheck"
                      onChange={(e) =>
                        setPaymentInfo({
                          ...paymentInfo,
                          nameOnTheCheck: e.target.value,
                        })
                      }
                      defaultValue={paymentInfo.nameOnTheCheck ? paymentInfo.nameOnTheCheck : null}
                      className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                    />
                  </div>
                </>
              )}
            </div>
          </Modal>
          <Modal
            open={editPaymentModal}
            title="Edit Payment"
            destroyOnClose
            onCancel={() => closeEditPaymentModal()}
            onOk={() => editPayment()}
            centered
            width={600}
          >
            <div className="flex flex-col items-start justify-start w-full gap-4 py-4">
              <div className="flex flex-col items-start justify-start w-full">
                <label className="pb-2 text-xs text-gray-600 uppercase">Payment Date</label>
                <DatePicker
                  placeholder="Payment Date"
                  name={"paymentDate"}
                  format={"MM/DD/YYYY"}
                  defaultValue={paymentInfo.date && dayjs(paymentInfo.date).isValid() ? dayjs(paymentInfo.date) : null}
                  onChange={(date) => {
                    setPaymentInfo({
                      ...paymentInfo,
                      date: date ? date.toJSON() : null,
                    });
                  }}
                  className="w-full"
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <label className="pb-2 text-xs text-gray-600 uppercase">Payment Amount</label>
                <InputNumber
                  placeholder="Enter the payment amount"
                  name="paymentAmount"
                  defaultValue={paymentInfo.amount}
                  onChange={(v) => setPaymentInfo({ ...paymentInfo, amount: v })}
                  className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 !focus:ring-0 !focus:shadow-none"
                  controls={false}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <label className="pb-2 text-xs text-gray-600 uppercase">Payment Method</label>
                <Select
                  placeholder={"Select a payment method"}
                  name="paymentMethod"
                  defaultValue={paymentInfo.method}
                  onChange={(v) => setPaymentInfo({ ...paymentInfo, method: v })}
                  options={[
                    { value: "cash", label: "Cash" },
                    { value: "check", label: "Check" },
                    { value: "card", label: "Card" },
                    { value: "other", label: "Other" },
                  ]}
                  className="w-full font-sans"
                  controls={false}
                  showSearch
                  filterOption={filterOption}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <label className="pb-2 text-xs text-gray-600 uppercase">Notes</label>
                <Input.TextArea
                  placeholder="Add notes about the payment"
                  name="paymentNotes"
                  defaultValue={paymentInfo.notes}
                  onChange={(e) => setPaymentInfo({ ...paymentInfo, notes: e.target.value })}
                  rows={4}
                  autoSize={false}
                  className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                />
              </div>
              {paymentInfo.method === "check" && (
                <>
                  <div className="flex flex-col items-start justify-start w-full">
                    <label className="pb-2 text-xs text-gray-600 uppercase">Check No</label>
                    <Input
                      placeholder="Check No"
                      name="checkNo"
                      onChange={(e) =>
                        setPaymentInfo({
                          ...paymentInfo,
                          checkNo: e.target.value,
                        })
                      }
                      defaultValue={paymentInfo.checkNo ? paymentInfo.checkNo : null}
                      className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full">
                    <label className="pb-2 text-xs text-gray-600 uppercase">Name On The Check</label>
                    <Input
                      placeholder="Name on the check"
                      name="nameOnTheCheck"
                      onChange={(e) =>
                        setPaymentInfo({
                          ...paymentInfo,
                          nameOnTheCheck: e.target.value,
                        })
                      }
                      defaultValue={paymentInfo.nameOnTheCheck ? paymentInfo.nameOnTheCheck : null}
                      className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                    />
                  </div>
                </>
              )}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

const StripeForm = ({ intent, saleId, cancelSale, nextStep }) => {
  const [processing, setProcessing] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const saleCancel = () => {
    cancelSale();
  };

  const submitCard = async () => {
    if (!stripe || !elements) {
      toast.error("Stripe not initialized");
      return;
    }
    if (nameOnCard === "") {
      toast.error("Please enter the name on the card");
      return;
    }
    let cardElement = elements.getElement(CardElement);
    setProcessing(true);
    stripe
      .confirmCardPayment(intent.client_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: nameOnCard,
          },
        },
      })
      .then(function (result) {
        if (result.error) {
          setProcessing(false);
          toast.error(result.error.message);
        } else {
          let piId = result.paymentIntent.id;
          CheckCardSale({ saleId: saleId, paymentIntent: piId })
            .then((res) => {
              toast.success("Payment Successful");
              nextStep(res.data.location);
            })
            .catch((err) => {
              toast.error(err.response.data.message);
              setProcessing(false);
            });
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setProcessing(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full">
      <div className="w-2/3 mx-auto my-2">
        <label htmlFor="nameOnCard" className="block w-full ml-1 text-xs text-gray-500">
          Name on Card
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="nameOnCard"
            id="nameOnCard"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
            placeholder="John Doe"
            aria-describedby="Name on Card"
            onChange={(e) => setNameOnCard(e.target.value)}
          />
        </div>
      </div>
      <div className="w-2/3 mx-auto mt-2">
        <label htmlFor="CardElement" className="block w-full ml-1 text-xs text-gray-500">
          Card Information
        </label>
      </div>
      <div className="w-2/3 p-2 mx-auto mt-1 border border-gray-300 rounded-md">
        <CardElement
          className="card"
          options={{
            showIcon: true,
            style: {
              base: {
                backgroundColor: "white",
              },
              paddingTop: "5px",
            },
            disableLink: true,
          }}
        />
      </div>
      <div className="flex flex-row items-center justify-between w-2/3 gap-2 mx-auto mt-5 sm:mt-6">
        <SecondaryButton callback={() => saleCancel()} disabled={processing} label="CANCEL SALE" />
        <SecondaryButton callback={() => submitCard()} disabled={processing} label={processing ? "PROCESSING" : "SUBMIT"} />
      </div>
    </div>
  );
};

export default JobPayments;
