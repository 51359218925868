import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import { GetOneCustomer } from "../../actions/customers";
import NoteField from "../../components/NoteField";
import toast from "react-hot-toast";
import { GetEmployees } from "../../actions/auth";
import { Minus, Refresh } from "iconoir-react";
import { Input } from "antd";
import { Helmet } from "react-helmet-async";

const CustomerEquipment = ({ authState, authDispatch }) => {
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noteField, setNoteField] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [query, setQuery] = useState("");

  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneCustomer(id)
        .then((res) => {
          setCustomer(res.data);
          setEquipment(res.data.equipment);
          GetEmployees()
            .then((res) => {
              setEmployees(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              console.log(err);
              setTimeout(() => setLoading(false), 700);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      inView = false;
    };
  }, [id]);

  const reloadData = () => {
    setLoading(true);
    GetOneCustomer(id)
      .then((res) => {
        setCustomer(res.data);
        setEquipment(res.data.equipment);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please refresh the page and try again");
        setTimeout(() => setLoading(false), 700);
      });
  };

  let tabs = [
    { name: "Overview", href: `/customers/${id}`, current: false },
    { name: "Contact Info", href: `/customers/${id}/contact`, current: false },
    { name: "A/R Info", href: `/customers/${id}/ar`, current: false },
    { name: "Defaults", href: `/customers/${id}/defaults`, current: false },
    { name: "Equipment", href: `/customers/${id}/equipment`, current: true },
    { name: "Quotes", href: `/customers/${id}/quotes`, current: false },
    { name: "Jobs", href: `/customers/${id}/jobs`, current: false },
    { name: "Invoices", href: `/customers/${id}/invoices`, current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const CustomerNavigation = () => {
    return (
      <div className="flex flex-row items-center justify-between w-full gap-3">
        <div className="w-full sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500"
            defaultValue={tabs.find((tab) => tab.current).href}
            onChange={(e) => navigate(e.target.value)}
          >
            {tabs.map((tab) => (
              <option value={tab.href}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden w-full sm:block">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current ? "border-blue-500 text-blue-600" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium",
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    );
  };

  const renderNote = (note) => {
    return (
      <div className="flex flex-col items-start justify-center w-full px-3 py-2 bg-white rounded-lg">
        <h3 className="w-full text-sm font-base text-slate-800">{note.note}</h3>
        <div className="flex flex-row items-center justify-between w-full mt-1">
          <p className="text-xs text-slate-400/75 font-medium pt-0.5">{employees.find((emp) => emp.employeeId === note.createdBy).name}</p>
          <p className="text-xs text-slate-400/75 font-medium pt-0.5">{dayjs(note.createdAt).format("MM/DD/YYYY")}</p>
        </div>
      </div>
    );
  };

  const renderEquip = (equip) => {
    return (
      <div className="w-full">
        <div className="rounded-lg shadow-sm bg-gray-50 ring-1 ring-gray-900/20">
          <dl className="flex flex-wrap">
            <div className="flex-auto pt-4 pl-6">
              <dd className="mt-1 text-xl font-bold leading-6 uppercase text-slate-600">
                {renderEquipmentType(equip.equipmentType)}
                {equip.equipmentType === "other" && equip.otherType.length > 0 && " | " + equip.otherType}
              </dd>
            </div>
            <div className="items-center self-end flex-none px-6 pt-4">
              <dd className="inline-flex items-center text-xs font-semibold rounded-md">
                {equip.customerEquipId && equip.customerEquipId.length > 0 && "Customer Equipment ID: " + equip.customerEquipId}
              </dd>
            </div>
            <div className="grid w-full grid-cols-2 gap-2 px-6 pt-4 mt-4 border-t gap-x-4 border-gray-900/10">
              <div className="flex flex-none w-full gap-x-4">
                <dt className="flex-none">
                  <Minus className="w-5 h-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd className="text-sm font-medium leading-6 text-gray-500">
                  Make:{" "}
                  <span className="font-semibold text-gray-900">
                    {equip.details.make && equip.details.make.length > 0 ? equip.details.make : "Not Provided"}
                  </span>
                </dd>
              </div>
              <div className="flex flex-none w-full gap-x-4">
                <dt className="flex-none">
                  <Minus className="w-5 h-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd className="text-sm leading-6 text-gray-500">
                  Model:{" "}
                  <span className="font-semibold text-gray-900">
                    {equip.details.model && equip.details.model.length > 0 ? equip.details.model : "Not Provided"}
                  </span>
                </dd>
              </div>
              {equip.equipmentType === "generator" && (
                <>
                  <div className="flex flex-none w-full gap-x-4">
                    <dt className="flex-none">
                      <Minus className="w-5 h-6 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                      Power:{" "}
                      <span className="font-semibold text-gray-900">
                        {equip.power} {equip.pwrUnit}
                      </span>
                    </dd>
                  </div>
                  <div className="flex flex-none w-full gap-x-4">
                    <dt className="flex-none">
                      <Minus className="w-5 h-6 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                      Fuel: <span className="font-semibold text-gray-900">{renderFuelType(equip.fuelType)}</span>
                    </dd>
                  </div>
                  <div className="flex flex-none w-full gap-x-4">
                    <dt className="flex-none">
                      <Minus className="w-5 h-6 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                      Location:{" "}
                      <span className="font-semibold text-gray-900">{equip.location && equip.location.length > 0 ? equip.location : "Not Provided"}</span>
                    </dd>
                  </div>
                </>
              )}
              <div className="flex flex-none w-full gap-x-4">
                <dt className="flex-none">
                  <Minus className="w-5 h-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd className="text-sm leading-6 text-gray-500">
                  Status:{" "}
                  <span className="font-semibold text-gray-900">
                    {equip.details.status && equip.details.status.length > 0 ? equipStatus[equip.details.status] : "Unknown"}
                  </span>
                </dd>
              </div>
            </div>
          </dl>
          <div className="flex flex-row items-center justify-end px-6 py-3 mt-4 border-t border-gray-900/10">
            <button
              onClick={() => window.open(`/equipment/${equip.equipmentId}`, "_blank")}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-xs font-medium text-gray-500 uppercase align-middle transition-all border border-gray-300 rounded-md hover:border-gray-600 hover:text-black focus:outline-none focus:ring-0"
            >
              Open
            </button>
          </div>
        </div>
      </div>
    );
  };

  const equipStatus = {
    availableForUse: "Available for Use",
    inoperable: "Inoperable",
    retired: "Retired",
  };

  const renderEquipmentType = (type) => {
    switch (type) {
      case "generator":
        return "Generator";
      case "pressureWasher":
        return "Pressure Washer";
      case "truck":
        return "Truck";
      case "trailer":
        return "Trailer";
      case "welder":
        return "Welder";
      case "airCompressor":
        return "Air Compressor";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const renderFuelType = (type) => {
    switch (type) {
      case "diesel":
        return "Diesel";
      case "gasoline":
        return "Gasoline";
      case "naturalGas":
        return "Natural Gas";
      case "propane":
        return "Propane";
      case "biFuel":
        return "Bi-Fuel";
      case "electricity":
        return "Electricity";
      case "other":
        return "Other";
      default:
        return "Not Provided";
    }
  };

  const filterEquipment =
    query === ""
      ? equipment
      : equipment.filter((pos) => {
          return (
            pos.equipmentType.toLowerCase().includes(query.toLowerCase().replace(/ /g, "")) ||
            (pos.details && pos.details.make && pos.details.make.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.details && pos.details.make && pos.details.model.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.details && pos.details.serialNo && pos.details.serialNo.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.engine && pos.engine.serialNo && pos.engine.serialNo.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.engine && pos.engine.specNo && pos.engine.specNo.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.engine && pos.engine.codeNo && pos.engine.codeNo.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.power && pos.power.toString().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.pwrUnit && pos.pwrUnit.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.fuelType && pos.fuelType.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.location && pos.location.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.customerEquipId && pos.customerEquipId.toLowerCase().includes(query.toLowerCase().replace(/ /g, "")))
          );
        });

  return (
    <div className="grid w-full h-full grid-cols-12 mb-4 overflow-y-auto md:px-4 gap-x-2">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full col-span-9">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="flex flex-col items-start justify-start w-full col-span-12 mb-10 md:col-span-9 md:overflow-y-auto md:mb-0">
          <Helmet>
            <title>
              Customer Equipment - {customer.customerCode}
              {customer.company.length > 0 ? ` - ${customer.company}` : ""} | HTPS ERP
            </title>
          </Helmet>
          <CustomerNavigation />
          <div className="mt-3 flex flex-col md:flex-row justify-between items-center w-full px-3 pb-3.5 border-b border-gray-300">
            <h1 className="w-full text-xl font-bold uppercase">Customer Equipment</h1>
            <div className="flex flex-col justify-end w-full gap-2 md:items-center md:flex-row">
              <Input
                placeholder="Search Equipment"
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                allowClear
                className="w-full font-sans py-2 px-4 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0 md:max-w-[300px]"
              />
              {authState.user.functionCategory !== "technician" && (
                <button
                  onClick={() => navigate(`/equipment/new?customerId=${id}`)}
                  className="rounded bg-slate-200/70 px-5 py-2.5 text-xs font-semibold text-slate-600 shadow-sm hover:bg-slate-600 hover:text-white uppercase transition-all duration-200"
                >
                  Add Equipment
                </button>
              )}
              <button
                onClick={() => reloadData()}
                className="rounded bg-slate-200/70 px-3 py-2.5 text-xs font-semibold text-slate-600 shadow-sm hover:bg-slate-600 hover:text-white uppercase transition-all duration-200 flex justify-center items-center gap-1"
              >
                <Refresh className="w-4 h-4" />
                <span className="md:hidden">REFRESH</span>
              </button>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full gap-3 px-1 pt-1 mt-3 md:px-5">
            {equipment.length > 0 ? (
              filterEquipment.map((eq) => renderEquip(eq))
            ) : (
              <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">No Equipment Found</p>
            )}
          </div>
        </div>
      )}
      <div className="flex flex-col items-start justify-start w-full col-span-12 gap-2 px-5 py-3 border rounded-lg shadow-md md:col-span-3 bg-slate-300/40 border-slate-300/60">
        <div className="flex flex-row items-center justify-between w-full pb-2 my-2 border-b border-gray-300">
          <h1 className="px-2 text-xl font-light leading-10 md:text-2xl">Notes</h1>
        </div>
        <div className="flex flex-col items-center justify-start w-full h-full gap-3">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : customer.notes.length > 0 ? (
            customer.notes.map((note) => renderNote(note))
          ) : (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">No notes found</p>
          )}
        </div>
        <div className="flex flex-col items-center justify-center w-full mt-2">
          {noteField ? (
            <NoteField
              closeField={(hasNew) => {
                setNoteField(false);
                if (hasNew) {
                  reloadData();
                }
              }}
              id={id}
            />
          ) : (
            <button
              onClick={() => setNoteField(true)}
              className="px-5 py-3 text-xs font-bold uppercase duration-150 rounded-md bg-slate-300 text-slate-800 hover:bg-gray-500/50"
            >
              Add a new note
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerEquipment;
