import { SecondaryButton, PrimaryButton } from "../../components/buttons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { DatePicker, Input, Select, Slider, Table, Tooltip } from "antd";
import { QueryJobs } from "../../actions/jobs";
import { QueryCustomers } from "../../actions/customers";
import { QueryEquipment } from "../../actions/ims";
import { QueryInvoices } from "../../actions/invoices";
import { formatCurrency } from "../../components/tools";
import dayjs from "dayjs";

const _ = require("lodash");

const BLANK_FILTERS = {
  invoiceNo: null,
  customer: {
    customerId: null,
    customerCode: null,
    company: null,
    contact: null,
  },
  status: null,
  equipment: {
    equipmentId: null,
    customerEquipId: null,
    equipmentType: null,
    customerId: null,
    details: null,
    engine: null,
  },
  locationName: null,
  total: [0, 100000],
  invoiceDate: [dayjs("01/01/2006", "MM/DD/YYYY"), dayjs()],
};

const DEFAULT_SIZE = 12;

const AllInvoices = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [filters, setFilters] = useState(BLANK_FILTERS);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      runJobQuery(filters, page, size);
    }
    return () => {
      inView = false;
    };
  }, []);

  const renderStatus = (status) => {
    switch (status) {
      case "draft":
        return (
          <div className="flex items-center justify-center">
            <span className="items-center justify-center block w-full px-2 py-1 text-xs font-medium text-center text-gray-900 bg-gray-500 rounded-md bg-opacity-10 ring-1 ring-inset ring-gray-800/10">
              Draft
            </span>
          </div>
        );
      case "ready":
        return (
          <div className="flex items-center justify-center">
            <span className="items-center justify-center block w-full px-2 py-1 text-xs font-medium text-center rounded-md text-sky-700 bg-sky-50 ring-1 ring-inset ring-sky-600/20">
              Ready
            </span>
          </div>
        );
      case "void":
        return (
          <div className="flex items-center justify-center">
            <span className="items-center justify-center block w-full px-2 py-1 text-xs font-medium text-center text-red-700 rounded-md bg-red-50 ring-1 ring-inset ring-red-600/10">
              VOID
            </span>
          </div>
        );
      case "overdue":
        return (
          <div className="flex items-center justify-center">
            <span className="items-center justify-center block w-full px-2 py-1 text-xs font-medium text-center rounded-md text-amber-700 bg-amber-50 ring-1 ring-inset ring-amber-600/10">
              OVERDUE
            </span>
          </div>
        );
      case "paid":
        return (
          <div className="flex items-center justify-center">
            <span className="items-center justify-center block w-full px-2 py-1 text-xs font-medium text-center text-green-700 rounded-md bg-green-50 ring-1 ring-inset ring-green-600/20">
              Paid
            </span>
          </div>
        );
      case "sent":
        return (
          <div className="flex items-center justify-center">
            <span className="items-center justify-center block w-full px-2 py-1 text-xs font-medium text-center text-blue-700 rounded-md bg-blue-50 ring-1 ring-inset ring-blue-600/20">
              Sent
            </span>
          </div>
        );
      default:
        return (
          <div className="flex items-center justify-center">
            <span className="items-center justify-center block w-full px-2 py-1 text-xs font-medium text-center text-gray-600 rounded-md bg-gray-50 ring-1 ring-inset ring-gray-500/10">
              Unknown
            </span>
          </div>
        );
    }
  };

  const clearFilters = () => {
    setLoading(true);
    setFilters(BLANK_FILTERS);
    setCustomers([]);
    setEquipment([]);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runJobQuery(BLANK_FILTERS, 0, DEFAULT_SIZE);
  };

  const updateFilter = ({ target: { name, value } }) => {
    let tmp = filters;
    tmp[name] = value;
    setFilters((prev) => ({ ...prev, [name]: value }));
    runJobQuery(tmp, page, size);
  };

  const handleCustomerSearch = (query) => {
    if (query?.length > 2) {
      QueryCustomers(query)
        .then((res) => {
          setCustomers(res.data);
        })
        .catch((err) => {
          toast.error("Customer search failed, please try again");
          setCustomers([]);
        });
    }
  };

  const updateCustomer = (v) => {
    let tmp = filters;
    if (v) {
      let qry = customers.find((c) => c.customerId === v);
      if (qry) {
        tmp.customer = { customerId: qry.customerId, customerCode: qry.customerCode, company: qry.company, contact: qry.contact };
      } else {
        tmp.customer = { customerId: null, customerCode: null, company: null, contact: null };
      }
    } else {
      tmp.customer = { customerId: null, customerCode: null, company: null, contact: null };
    }
    setFilters(tmp);
    setCustomers(customers.filter((c) => c.customerId === v));
    runJobQuery(tmp, page, size);
  };

  const handleEquipmentSearch = (query) => {
    if (query?.length > 2) {
      QueryEquipment(query, filters.customer.customerId)
        .then((res) => {
          setEquipment(res.data);
        })
        .catch((err) => {
          toast.error("Equipment search failed, please try again");
          setEquipment([]);
        });
    }
  };

  const updateEquipment = (v) => {
    let tmp = filters;
    if (v) {
      let qry = equipment.find((e) => e.equipmentId === v);
      if (qry) {
        tmp.equipment = {
          equipmentId: qry.equipmentId,
          customerEquipId: qry.customerEquipId,
          equipmentType: qry.equipmentType,
          customerId: qry.customerId,
          details: qry.details,
          engine: qry.details,
        };
      } else {
        tmp.equipment = {
          equipmentId: null,
          customerEquipId: null,
          equipmentType: null,
          customerId: null,
          details: null,
          engine: null,
        };
      }
    } else {
      tmp.equipment = {
        equipmentId: null,
        customerEquipId: null,
        equipmentType: null,
        customerId: null,
        details: null,
        engine: null,
      };
    }
    setFilters(tmp);
    setEquipment(equipment.filter((e) => e.equipmentId === v));
    runJobQuery(tmp, page, size);
  };

  let eqTypes = {
    generator: "Generator",
    pressureWasher: "Pressure Washer",
    truck: "Truck",
    trailer: "Trailer",
    welder: "Welder",
    airCompressor: "Air Compressor",
    other: "Other",
  };

  const runJobQuery = (data, offset, limit) => {
    QueryInvoices({
      invoiceNo: data.invoiceNo,
      customerId: data.customer.customerId,
      equipmentId: data.equipment.equipmentId,
      status: data.status,
      locationName: data.locationName,
      total: data.total,
      invoiceDate: data.invoiceDate,
      page: offset,
      limit: limit,
    })
      .then((res) => {
        setInvoices(res.data.results);
        setTotal(res.data.total);
        setSize(res.data.size);
        setPage(res.data.page);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Failed to fetch invoices");
        setInvoices([]);
        setTotal(0);
        setSize(DEFAULT_SIZE);
        setPage(0);
        setFilters(BLANK_FILTERS);
        setTimeout(() => setLoading(false), 700);
      });
  };

  const changePage = (pg, sz) => {
    setPage(pg);
    setSize(sz);
    setLoading(true);
    runJobQuery(filters, pg, sz);
  };

  const applyFilters = () => {
    setLoading(true);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runJobQuery(filters, 0, DEFAULT_SIZE);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  const updateLocation = (v) => {
    let tmp = filters;
    if (v) {
      tmp.locationName = v;
    } else {
      tmp.locationName = null;
    }
    setFilters(tmp);
    runJobQuery(tmp, page, size);
  };

  const updateStatus = (v) => {
    let tmp = filters;
    if (v) {
      tmp.status = v;
    } else {
      tmp.status = null;
    }
    setFilters(tmp);
    runJobQuery(tmp, page, size);
  };

  const disabledDate = (current) => {
    return current && current > dayjs();
  };

  const updateInvoiceDates = (dates) => {
    let tmp = filters;
    if (dates && dates.length === 2 && dates[0] && dates[1] && dayjs(dates[0]).isValid() && dayjs(dates[1]).isValid()) {
      tmp.invoiceDate = dates;
    } else {
      tmp.invoiceDate = [dayjs("01/01/2006", "MM/DD/YYYY"), dayjs()];
    }
    setFilters(tmp);
    runJobQuery(tmp, page, size);
  };

  const updateTotalSlider = (v) => {
    let tmp = filters;
    tmp.total = v;
    setFilters((prev) => ({ ...prev, total: v }));
    runJobQuery(tmp, page, size);
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Invoices | HTPS ERP</title>
      </Helmet>
      <div className="flex-grow flow-root w-full px-2 mt-3">
        <div className="-my-2">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-2">
              <div className="grid w-full h-full gap-6 mx-auto md:grid-cols-12">
                <div className="flex flex-col h-full col-span-3 gap-4">
                  <div className="flex flex-col items-start justify-start w-full gap-4 p-6 border border-gray-300 rounded-lg">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Filters</h3>
                    <div className="flex flex-col items-start justify-start w-full mt-4">
                      <label htmlFor="invoiceNo" className="pb-1 text-xs text-gray-600 uppercase">
                        Invoice No.
                      </label>
                      <Input
                        placeholder="Invoice No."
                        name="invoiceNo"
                        onChange={(e) => {
                          updateFilter(e);
                        }}
                        autoComplete="off"
                        value={filters.invoiceNo}
                        defaultValue={filters.invoiceNo}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="customer" className="pb-1 text-xs text-gray-600 uppercase">
                        Customer
                      </label>
                      <Select
                        placeholder="Search Customers"
                        onChange={(v) => updateCustomer(v)}
                        value={filters.customer.customerId}
                        options={(customers || []).map((p) => ({
                          label: `${p.customerCode} | ${p.company && p.company.length > 0 ? p.company : "No Company Provided"}`,
                          value: p.customerId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No customers found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onSearch={handleCustomerSearch}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="equipment" className="pb-1 text-xs text-gray-600 uppercase">
                        Equipment
                      </label>
                      <Select
                        placeholder="Search Equipment"
                        onChange={(v) => updateEquipment(v)}
                        value={filters.equipment.equipmentId}
                        options={(equipment || []).map((e) => ({
                          label: `${eqTypes[e.equipmentType]}${e?.details?.make?.length ? ` | ${e?.details?.make}` : ""}${e?.details?.model?.length ? ` | ${e?.details?.model}` : ""}${e?.customerEquipId?.length ? ` | ${e?.customerEquipId}` : ""}`,
                          value: e.equipmentId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No equipment found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onSearch={handleEquipmentSearch}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="locationName" className="pb-1 text-xs text-gray-600 uppercase">
                        Location
                      </label>
                      <Select
                        placeholder="Job Location"
                        onChange={(v) => updateLocation(v)}
                        value={filters.locationName}
                        options={[
                          {
                            label: "Augusta, GA",
                            value: "Augusta",
                          },
                          {
                            label: "North Augusta, SC",
                            value: "North Augusta",
                          },
                        ]}
                        className="w-full mb-2 font-sans"
                        controls={false}
                        filterOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        defaultActiveFirstOption={false}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="status" className="pb-1 text-xs text-gray-600 uppercase">
                        Invoice Status
                      </label>
                      <Select
                        placeholder="Invoice Status"
                        onChange={(v) => updateStatus(v)}
                        value={filters.status}
                        options={[
                          {
                            label: "Draft",
                            value: "draft",
                          },
                          {
                            label: "Ready",
                            value: "ready",
                          },
                          {
                            label: "Sent",
                            value: "sent",
                          },
                          {
                            label: "Paid",
                            value: "paid",
                          },
                          {
                            label: "Void",
                            value: "void",
                          },
                          {
                            label: "Overdue",
                            value: "overdue",
                          },
                        ]}
                        className="w-full mb-2 font-sans"
                        controls={false}
                        filterOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        defaultActiveFirstOption={false}
                        allowClear
                        mode="multiple"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="total" className="pb-1 text-xs text-gray-600 uppercase">
                        Invoice Amount
                      </label>
                      <Slider
                        range
                        min={0}
                        max={100000}
                        defaultValue={[0, 100000]}
                        value={filters.total}
                        className="w-full"
                        tooltip={{ formatter: (v) => formatCurrency(v) }}
                        step={100}
                        onChange={(v) => updateTotalSlider(v)}
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="invoiceDate" className="pb-1 text-xs text-gray-600 uppercase">
                        Invoice Date
                      </label>
                      <DatePicker.RangePicker
                        className="w-full"
                        disabledDate={disabledDate}
                        value={[filters.invoiceDate[0], filters.invoiceDate[1]]}
                        defaultValue={[filters.invoiceDate[0], filters.invoiceDate[1]]}
                        format="MM/DD/YYYY"
                        onChange={(dates) => updateInvoiceDates(dates)}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full gap-2">
                    <SecondaryButton
                      callback={() => clearFilters()}
                      label="Clear Filters"
                      className="block py-2.5 transition-all duration-300 normal-case w-full"
                    />
                    <PrimaryButton
                      callback={() => applyFilters()}
                      label="Apply Filters"
                      className="block py-2.5 transition-all duration-300 bg-black hover:bg-black/80 normal-case w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full col-span-9 gap-4">
                  <Table
                    dataSource={invoices}
                    loading={loading}
                    className="w-full"
                    sticky={true}
                    pagination={{
                      total: total,
                      responsive: true,
                      hideOnSinglePage: true,
                      onChange: (p, s) => changePage(p - 1, s),
                      pageSize: size,
                      current: page + 1,
                      showSizeChanger: false,
                    }}
                    rowKey="invoiceId"
                  >
                    <Table.Column title="Status" dataIndex="invoiceStatus" key="invoiceStatus" render={(d) => renderStatus(d)} width={"100px"} align="center" />
                    <Table.Column title="Invoice No." dataIndex="invoiceNo" key="invoiceNo" width={"180px"} align="left" />
                    <Table.Column
                      title="Customer"
                      dataIndex="customerName"
                      key="customerName"
                      render={(_v, r) => (
                        <Tooltip title={`${r?.customerCode}${r?.customerName?.length && ` | ${r.customerName}`}`}>
                          <p className="truncate cursor-pointer">
                            {r?.customerCode}
                            {r?.customerName?.length > 0 && ` | ${r.customerName}`}
                          </p>
                        </Tooltip>
                      )}
                    />
                    <Table.Column title="Location" dataIndex="locationName" key="locationName" width={"120px"} align="right" />
                    <Table.Column title="Total" dataIndex="total" key="total" render={(v) => formatCurrency(v)} align="right" width={"120px"} />
                    <Table.Column
                      title=""
                      dataIndex="invoiceId"
                      key="actions"
                      width={"80px"}
                      render={(v) => (
                        <SecondaryButton
                          callback={() => navigate(`/invoices/${v}`)}
                          label="Open"
                          className="transition-all duration-300 border bg-white-smoke border-zinc-400 ring-0 hover:ring-0 hover:bg-zinc-100 hover:border-zinc-600 text-zinc-600 hover:text-zinc-900"
                        />
                      )}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllInvoices;
