import validator from "validator";

const testZip = (val) => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
};

const testPhone = (val) => {
  return /^((\+1|1)?( |-)?)?(\(([2-9])(?:\d(?!\5)\d|(?!\5)\d\d)\)|([2-9])(?:\d(?!\6)\d|(?!\6)\d\d))( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(val);
};

export const newAccountForm = {
  accountNo: {
    fieldName: "accountNo",
    label: "Account Code",
    inputType: "number",
    placeholder: "Account Code",
    defaultValue: "",
    config: {
      required: "Account code is required",
      validate: (value) => (value.length > 4 && validator.isInt(value.toString())) || "Please enter a valid account code",
    },
  },
  description: {
    fieldName: "description",
    label: "Description",
    inputType: "text",
    placeholder: "Account Description",
    defaultValue: "",
    config: {
      required: "Account description is required",
      validate: (value) => value.length >= 2 || "Account description must be at least 2 characters long",
    },
  },
  split1: {
    fieldName: "split1",
    label: "Category & Type",
    inputType: "divider-label",
  },
  contra: {
    fieldName: "contra",
    label: "contra",
    inputType: "checkbox",
    placeholder: "contra",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  ap: {
    fieldName: "ap",
    label: "ap",
    inputType: "checkbox",
    placeholder: "ap",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  ltd: {
    fieldName: "ltd",
    label: "ltd",
    inputType: "checkbox",
    placeholder: "ltd",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  cc: {
    fieldName: "cc",
    label: "cc",
    inputType: "checkbox",
    placeholder: "cc",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  time: {
    fieldName: "time",
    label: "time",
    inputType: "checkbox",
    placeholder: "time",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  category: {
    fieldName: "category",
    label: "Account Type",
    inputType: "select",
    placeholder: "Account Type",
    options: [
      { value: "assets", label: "Assets" },
      { value: "income", label: "Income" },
      { value: "expenses", label: "Expenses" },
      { value: "liabilities", label: "Liabilities" },
      { value: "equity", label: "Equity" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: "Account type is required",
      validate: (value) => value.length >= 3 || "Please select an account type",
    },
  },
  split2: {
    fieldName: "split2",
    label: "Reporting Data",
    inputType: "divider-label",
  },
  balanceSheet: {
    fieldName: "balanceSheet",
    label: "Balance Sheet Statement",
    inputType: "checkbox",
    placeholder: "Balance Sheet Statement",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  income: {
    fieldName: "income",
    label: "Income Statement",
    inputType: "checkbox",
    placeholder: "Income Statement",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  cashFlow: {
    fieldName: "cashFlow",
    label: "Cash Flow Statement",
    inputType: "checkbox",
    placeholder: "Cash Flow Statement",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  changesInCapital: {
    fieldName: "changesInCapital",
    label: "Statement of Changes in Capital",
    inputType: "checkbox",
    placeholder: "Statement of Changes in Capital",
    defaultValue: false,
    config: {
      required: false,
    },
  },
};
export const updateAccountForm = {
  accountNo: {
    fieldName: "accountNo",
    label: "Account Code",
    inputType: "number",
    placeholder: "Account Code",
    config: {
      required: "Account code is required",
      validate: (value) => (value.length > 2 && validator.isInt(value.toString())) || "Account code must be at least 3 digits long",
    },
  },
  description: {
    fieldName: "description",
    label: "Description",
    inputType: "text",
    placeholder: "Account Description",
    config: {
      required: "Account description is required",
      validate: (value) => value.length >= 2 || "Account description must be at least 2 characters long",
    },
  },
  split1: {
    fieldName: "split1",
    label: "Category & Type",
    inputType: "divider-label",
  },
  contra: {
    fieldName: "contra",
    label: "contra",
    inputType: "checkbox",
    placeholder: "contra",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  ap: {
    fieldName: "ap",
    label: "ap",
    inputType: "checkbox",
    placeholder: "ap",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  ltd: {
    fieldName: "ltd",
    label: "ltd",
    inputType: "checkbox",
    placeholder: "ltd",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  cc: {
    fieldName: "cc",
    label: "cc",
    inputType: "checkbox",
    placeholder: "cc",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  time: {
    fieldName: "time",
    label: "time",
    inputType: "checkbox",
    placeholder: "time",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  category: {
    fieldName: "category",
    label: "Account Type",
    inputType: "select",
    placeholder: "Account Type",
    options: [
      { value: "assets", label: "Assets" },
      { value: "income", label: "Income" },
      { value: "expenses", label: "Expenses" },
      { value: "liabilities", label: "Liabilities" },
      { value: "equity", label: "Equity" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: "Account type is required",
      validate: (value) => value.length >= 3 || "Please select an account type",
    },
  },
  split2: {
    fieldName: "split2",
    label: "Reporting Data",
    inputType: "divider-label",
  },
  balanceSheet: {
    fieldName: "statements.balanceSheet",
    label: "Balance Sheet Statement",
    inputType: "checkbox",
    placeholder: "Balance Sheet Statement",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  income: {
    fieldName: "statements.income",
    label: "Income Statement",
    inputType: "checkbox",
    placeholder: "Income Statement",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  cashFlow: {
    fieldName: "statements.cashFlow",
    label: "Cash Flow Statement",
    inputType: "checkbox",
    placeholder: "Cash Flow Statement",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  changesInCapital: {
    fieldName: "statements.changesInCapital",
    label: "Statement of Changes in Capital",
    inputType: "checkbox",
    placeholder: "Statement of Changes in Capital",
    defaultValue: false,
    config: {
      required: false,
    },
  },
};
