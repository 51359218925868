import { useEffect, useState } from "react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import toast from "react-hot-toast";
import FormController from "../../components/FormController";
import { useNavigate, useSearchParams } from "react-router-dom";
import { newQuoteCustomer, newQuoteBasics, newQuoteDetails } from "../../data/quoteForms";
import { Check, Minus } from "iconoir-react";
import { GetFullCustomerList, GetOneCustomer } from "../../actions/customers";
import { CreateAQuote } from "../../actions/quotes";
import { Checkbox, Select } from "antd";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";
import { twMerge } from "tailwind-merge";

const locations = [
  { value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf", label: "Augusta, GA" },
  {
    value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
    label: "North Augusta, SC",
  },
];

const CheckboxGroup = Checkbox.Group;

const NewQuote = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    customerId: "",
    location: "",
    quoteDate: "",
    description: "",
    details: "",
    eoNotes: "",
  });
  const [quoteDetails, setQuoteDetails] = useState({
    poNumber: "",
    salesTaxZIP: "",
    laborDiscount: 0,
    partsMarkup: 0,
    terms: "",
    tax: "",
  });
  const [customer, setCustomer] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [step, setStep] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [equipment, setEquipment] = useState([]);

  const navigate = useNavigate();
  let [search, setSearch] = useSearchParams();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetFullCustomerList()
        .then((res) => {
          let tmp = [];
          let i = res.data.length;
          while (i--) {
            tmp.push({
              value: res.data[i].customerId,
              label: `${res.data[i].customerCode.toUpperCase()}${res.data[i].company ? " | " + res.data[i].company : ""}`,
            });
          }
          setCustomers(tmp);
          let customerId = search.get("customerId");
          if (customerId) {
            setCustomerId(customerId);
            let tmp = values;
            tmp.customerId = customerId;
            setValues(tmp);
            GetOneCustomer(customerId)
              .then((res) => {
                setCustomer(res.data);
                setStep(1);
                setTimeout(() => setLoading(false), 700);
              })
              .catch((err) => {
                toast.error(err.response.data ? err.response.data.message : "Something went wrong, please manually select the customer.");
                setCustomer(null);
                setCustomerId(null);
                setTimeout(() => setLoading(false), 700);
              });
          } else {
            setTimeout(() => setLoading(false), 700);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const selectCustomer = () => {
    setLoading(true);
    let tmp = values;
    tmp.customerId = customerId;
    setValues(tmp);
    GetOneCustomer(customerId)
      .then((res) => {
        setCustomer(res.data);
        setStep(1);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Something went wrong, try selecting a customer again.");
        setCustomer(null);
        setCustomerId(null);
        setTimeout(() => setLoading(false), 700);
      });
  };

  const submitBasics = (data) => {
    setLoading(true);
    setValues(data);
    let tmp = quoteDetails;
    tmp.salesTaxZIP = customer.defaults.salesTaxZip ?? "";
    tmp.laborDiscount = customer.defaults.laborDiscount ?? 0;
    tmp.partsMarkup = customer.defaults.partsMarkup ?? 45;
    tmp.terms = customer.arData.terms ?? "dueOnReceipt";
    tmp.tax = customer.arData.tax ?? "taxable";
    setQuoteDetails(tmp);
    setStep(2);
    setTimeout(() => setLoading(false), 700);
  };

  const submitDetails = (data) => {
    setLoading(true);
    let tmp = quoteDetails;
    tmp.poNumber = data.poNumber;
    tmp.laborDiscount = data.laborDiscount;
    tmp.partsMarkup = data.partsMarkup;
    tmp.terms = data.terms;
    tmp.tax = data.tax;
    tmp.salesTaxZIP = data.salesTaxZIP;
    setQuoteDetails(tmp);
    setStep(3);
    setTimeout(() => setLoading(false), 700);
  };

  const steps = [
    {
      id: 0,
      name: "Select a Customer",
      status: step === 0 ? "current" : "complete",
    },
    {
      id: 1,
      name: "Quote Basics",
      status: step === 1 ? "current" : step > 1 ? "complete" : "incomplete",
    },
    {
      id: 2,
      name: "Quote Details",
      status: step === 2 ? "current" : step > 2 ? "complete" : "incomplete",
    },
    {
      id: 3,
      name: "Quote Equipment",
      status: step === 3 ? "current" : step > 3 ? "complete" : "incomplete",
    },
    {
      id: 4,
      name: "Quote Overview",
      status: step === 4 ? "current" : step > 41 ? "complete" : "incomplete",
    },
  ];

  const renderEquipmentType = (type) => {
    switch (type) {
      case "generator":
        return "Generator";
      case "pressureWasher":
        return "Pressure Washer";
      case "truck":
        return "Truck";
      case "trailer":
        return "Trailer";
      case "welder":
        return "Welder";
      case "airCompressor":
        return "Air Compressor";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const renderFuelType = (type) => {
    switch (type) {
      case "diesel":
        return "Diesel";
      case "gasoline":
        return "Gasoline";
      case "naturalGas":
        return "Natural Gas";
      case "propane":
        return "Propane";
      case "biFuel":
        return "Bi-Fuel";
      case "electricity":
        return "Electricity";
      case "other":
        return "Other";
      default:
        return "Not Provided";
    }
  };

  const getEqOptions = () => {
    let toReturn = [];
    if (customer) {
      let eq = customer.equipment;
      for (let i = 0; i < eq.length; i++) {
        let el = eq[i];
        toReturn.push({
          label: `${renderEquipmentType(el.equipmentType)}${
            el.equipmentType === "other" && el.otherType && el.otherType.length > 0 ? " - " + el.otherType : ""
          } | ${el.details.make && el.details.make.length > 0 ? "Make: " + el.details.make : ""} ${
            el.details.model && el.details.model.length > 0 ? " Model: " + el.details.model : ""
          }${el.customerEquipId && el.customerEquipId.length > 0 ? " | " + el.customerEquipId : ""}`,
          value: el.equipmentId,
        });
      }
    }
    return toReturn;
  };

  const renderSelectedEquipment = (id) => {
    let equip = customer.equipment.find((e) => e.equipmentId === id);
    if (equip) {
      return (
        <div className="w-full mt-2">
          <div className="rounded-lg shadow-sm bg-gray-50 ring-1 ring-gray-900/20">
            <dl className="flex flex-wrap pb-3">
              <div className="flex-auto pt-3 pl-6">
                <dd className="mt-1 text-base font-bold leading-6 uppercase text-slate-600">
                  {renderEquipmentType(equip.equipmentType)}
                  {equip.equipmentType === "other" && equip.otherType.length > 0 && " | " + equip.otherType}
                </dd>
              </div>
              <div className="items-center self-end flex-none px-6 pt-3">
                <dd className="inline-flex items-center text-xs font-semibold rounded-md">
                  {equip.customerEquipId && equip.customerEquipId.length > 0 && "Customer Equipment ID: " + equip.customerEquipId}
                </dd>
              </div>
              <div className="grid w-full grid-cols-2 gap-2 px-6 pt-3 mt-4 border-t gap-x-4 border-gray-900/10">
                <div className="flex flex-none w-full gap-x-4">
                  <dt className="flex-none">
                    <Minus className="w-5 h-6 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd className="text-sm font-medium leading-6 text-gray-500">
                    Make:{" "}
                    <span className="font-semibold text-gray-900">
                      {equip.details.make && equip.details.make.length > 0 ? equip.details.make : "Not Provided"}
                    </span>
                  </dd>
                </div>
                <div className="flex flex-none w-full gap-x-4">
                  <dt className="flex-none">
                    <Minus className="w-5 h-6 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    Model:{" "}
                    <span className="font-semibold text-gray-900">
                      {equip.details.model && equip.details.model.length > 0 ? equip.details.model : "Not Provided"}
                    </span>
                  </dd>
                </div>
                {equip.equipmentType === "generator" && (
                  <>
                    <div className="flex flex-none w-full gap-x-4">
                      <dt className="flex-none">
                        <Minus className="w-5 h-6 text-gray-400" aria-hidden="true" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        Power:{" "}
                        <span className="font-semibold text-gray-900">
                          {equip.power} {equip.pwrUnit}
                        </span>
                      </dd>
                    </div>
                    <div className="flex flex-none w-full gap-x-4">
                      <dt className="flex-none">
                        <Minus className="w-5 h-6 text-gray-400" aria-hidden="true" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        Fuel: <span className="font-semibold text-gray-900">{renderFuelType(equip.fuelType)}</span>
                      </dd>
                    </div>
                    <div className="flex flex-none w-full gap-x-4">
                      <dt className="flex-none">
                        <Minus className="w-5 h-6 text-gray-400" aria-hidden="true" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        Location: <span className="font-semibold text-gray-900">{equip.location.length > 0 ? equip.location : "Not Provided"}</span>
                      </dd>
                    </div>
                  </>
                )}
              </div>
            </dl>
          </div>
        </div>
      );
    }
  };

  const equipmentSelect = (checked) => {
    setEquipment(checked);
  };

  const submitEquipment = () => {
    setStep(4);
  };

  const createQuote = () => {
    setLoading(true);
    let payload = {
      date: values.quoteDate,
      locationId: values.location,
      customerId: values.customerId,
      description: values.description,
      details: values.details,
      eoNotes: values.eoNotes,
      poNumber: quoteDetails.poNumber,
      equipment: equipment,
      taxZip: quoteDetails.salesTaxZIP,
      tax: quoteDetails.tax,
      laborDiscount: quoteDetails.laborDiscount,
      partsMarkup: quoteDetails.partsMarkup,
      paymentTerms: quoteDetails.terms,
    };
    CreateAQuote(payload)
      .then((res) => {
        toast.success("Quote created successfully! You will be redirected shortly.");
        navigate(`/quotes/${res.data.quoteId}`);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please try again.");
        setLoading(false);
      });
  };

  const renderTerms = () => {
    switch (quoteDetails.terms) {
      case "dueOnReceipt":
        return "Due on Receipt";
      case "net10":
        return "Net 10";
      case "net20":
        return "Net 20";
      case "net30":
        return "Net 30";
      case "collectOnDelivery":
        return "Collect on Delivery";
      case "prePay":
        return "Pre-Pay";
      default:
        return "Due on Receipt";
    }
  };

  const filterCustomers = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase()) || (option?.value ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const renderStep = () => {
    if (step === 0) {
      return (
        <div className="flex flex-col items-center justify-start w-full h-full gap-4">
          <div className="flex flex-col items-start justify-start w-full">
            <label className="block mb-2 text-sm font-medium">Customer</label>
            <Select
              className="w-full"
              showSearch
              placeholder="Pick a customer"
              optionFilterProp="children"
              onChange={(v) => setCustomerId(v)}
              filterOption={filterCustomers}
              defaultValue={customerId}
              allowClear
              onClear={() => setCustomerId(null)}
              options={customers}
            />
          </div>
          <div className="flex flex-row items-center justify-end w-full">
            <PrimaryButton label="Next" callback={() => selectCustomer()} disabled={!customerId} />
          </div>
        </div>
      );
    } else if (step === 1) {
      return (
        <FormController onSubmit={submitBasics} fields={newQuoteBasics} values={values} buttonText={"Save & Continue"} fullWidth={true} keepDefaults={true} />
      );
    } else if (step === 2) {
      return (
        <>
          <div className="flex flex-row items-center justify-between w-full mt-1 mb-4">
            <p className="text-sm font-semibold uppercase">Quote for {customer.company}</p>
            <button
              onClick={() => setStep(0)}
              className=" gap-x-1.5 rounded-md text-gray-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm hover:bg-gray-100 ring-1 rind-inset ring-gray-300"
            >
              Change Customer
            </button>
          </div>
          <FormController
            onSubmit={submitDetails}
            fields={newQuoteDetails}
            values={quoteDetails}
            keepDefaults={true}
            buttonText={"Save & Continue"}
            fullWidth={true}
          />
        </>
      );
    } else if (step === 3) {
      return (
        <div className="w-2/3 mx-auto">
          <div className="flex flex-col items-start justify-start w-full mt-1 mb-4">
            <p className="text-sm font-semibold uppercase">Quote for {customer.company}</p>
            <p className="font-semibold uppercase">Please select any equipment you want added to this quote</p>
          </div>
          <CheckboxGroup onChange={equipmentSelect} style={{ width: "100%" }} defaultValue={equipment}>
            {getEqOptions().map((eq) => (
              <Checkbox
                value={eq.value}
                className={twMerge(
                  "w-full px-4 py-2 mb-2 rounded-md border",
                  equipment.includes(eq.value) ? "bg-green-50 border-green-500" : "border-gray-300",
                )}
              >
                {eq.label}
              </Checkbox>
            ))}
          </CheckboxGroup>
          <div className="flex flex-row items-center justify-end w-full pt-3 mt-5 border-t border-gray-300">
            <SecondaryButton label="Save & Continue" callback={() => submitEquipment()} />
          </div>
        </div>
      );
    } else if (step === 4) {
      return (
        <div className="flex flex-col items-start justify-start w-full h-full gap-2">
          <p className="text-lg font-semibold">Quote Overview</p>
          <div className="grid w-full grid-cols-3 gap-2 mt-3">
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="text-xs font-bold uppercase">Customer:</p>
              <p className="text-lg font-light uppercase">{customer.company}</p>
            </div>
            <div className="flex flex-row items-center justify-center gap-1">
              <p className="text-xs font-bold uppercase">Location:</p>
              <p className="text-lg font-light uppercase">{locations.find((l) => (l.value = values.location)).label}</p>
            </div>
            <div className="flex flex-row items-center justify-end gap-1">
              <p className="text-xs font-bold uppercase">Quote Date:</p>
              <p className="text-lg font-light uppercase">{dayjs(values.quoteDate).format("MM/DD/YYYY")}</p>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full mt-3">
            <label htmlFor="description" className={`text-xs uppercase pb-2`}>
              Description
            </label>
            <input
              className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md bg-gray-50 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              type="text"
              id="description"
              value={values.description}
              disabled={true}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full mt-3">
            <label htmlFor="details" className={`text-xs uppercase pb-2`}>
              Details
            </label>
            <input
              className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md bg-gray-50 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              type="text"
              id="details"
              value={values.details}
              disabled={true}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full mt-3">
            <label htmlFor="eoNotes" className={`text-xs uppercase pb-2`}>
              EO Notes
            </label>
            <textarea
              rows={4}
              className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none"
              id="eoNotes"
              value={values.eoNotes}
              disabled={true}
            />
          </div>
          <div className="grid w-full grid-cols-3 gap-2 mt-3">
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="text-xs font-bold uppercase">Tax Status:</p>
              <p className="text-lg font-light uppercase">{quoteDetails.tax}</p>
            </div>
            <div className="flex flex-row items-center justify-center gap-1">
              <p className="text-xs font-bold uppercase">Sales Tax ZIP:</p>
              <p className="text-lg font-light uppercase">{quoteDetails.salesTaxZIP}</p>
            </div>
            <div className="flex flex-row items-center justify-end gap-1">
              <p className="text-xs font-bold uppercase">Customer's PO Number:</p>
              <p className="text-lg font-light uppercase">{quoteDetails.poNumber.length > 0 ? quoteDetails.poNumber : "N/A"}</p>
            </div>
          </div>
          <div className="grid w-full grid-cols-3 gap-2 mt-3">
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="text-xs font-bold uppercase">Labor Discount:</p>
              <p className="text-lg font-light uppercase">{quoteDetails.laborDiscount}</p>
            </div>
            <div className="flex flex-row items-center justify-center gap-1">
              <p className="text-xs font-bold uppercase">Parts Markup:</p>
              <p className="text-lg font-light uppercase">{quoteDetails.partsMarkup}</p>
            </div>
            <div className="flex flex-row items-center justify-end gap-1">
              <p className="text-xs font-bold uppercase">Payment Terms:</p>
              <p className="text-lg font-light uppercase">{renderTerms()}</p>
            </div>
          </div>
          <div className="w-full mt-3">
            <p>EQUIPMENT</p>
            {equipment.length > 0 ? (
              <div className="flex flex-col items-start justify-start w-full gap-2">{equipment.map((eq) => renderSelectedEquipment(eq))}</div>
            ) : (
              <p>No equipment selected</p>
            )}
          </div>
          <div className="flex flex-row items-center justify-end w-full pt-3 mt-5 border-t border-gray-300">
            <PrimaryButton label="Create Quote" callback={() => createQuote()} />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>New Quote | HTPS ERP</title>
      </Helmet>
      <nav aria-label="Progress" className="w-full mb-5">
        <ol role="list" className="border border-gray-300 divide-y divide-gray-300 rounded-md md:flex md:divide-y-0">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative md:flex md:flex-1">
              {step.status === "complete" ? (
                <div className="flex items-center w-full group">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-blue-600 rounded-full group-hover:bg-blue-800">
                      <Check className="w-6 h-6 text-white" aria-hidden="true" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                  </span>
                </div>
              ) : step.status === "current" ? (
                <div className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                  <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-blue-600 rounded-full">
                    <span className="text-blue-600">{step.id + 1}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-blue-600">{step.name}</span>
                </div>
              ) : (
                <div className="flex items-center cursor-pointer group">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900">{step.id + 1}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                  </span>
                </div>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  <div className="absolute top-0 right-0 hidden w-5 h-full md:block" aria-hidden="true">
                    <svg className="w-full h-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                      <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
      <div className="flex flex-col items-center justify-start flex-grow w-full px-10 pb-3 overflow-y-auto">{!loading ? renderStep() : <p>Loading...</p>}</div>
    </div>
  );
};

export default NewQuote;
