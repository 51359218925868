import axiosInstance from "./axios";

export const UploadEquipmentPhotos = (data) => {
  return axiosInstance.post("cdn/upload/eqPhotos", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("auth-token"),
    },
  });
};
export const UploadAPInvoices = (data) => {
  return axiosInstance.post("cdn/upload/apScans", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("auth-token"),
    },
  });
};
