import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import { GetOneCustomer, GetCustomerJobs } from "../../actions/customers";
import NoteField from "../../components/NoteField";
import toast from "react-hot-toast";
import { GetEmployees } from "../../actions/auth";
import { ReactComponent as JobIcon } from "../../assets/job.svg";
import { formatCurrency } from "../../components/tools";
import { Helmet } from "react-helmet-async";

const CustomerJobs = ({ authState, authDispatch }) => {
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noteField, setNoteField] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [jobs, setJobs] = useState([]);

  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneCustomer(id)
        .then((res) => {
          setCustomer(res.data);
          GetEmployees()
            .then((res) => {
              setEmployees(res.data);
              GetCustomerJobs(id)
                .then((res) => {
                  setJobs(res.data);
                  setTimeout(() => setLoading(false), 700);
                })
                .catch((err) => {
                  console.log(err);
                  setTimeout(() => setLoading(false), 700);
                });
            })
            .catch((err) => {
              console.log(err);
              setTimeout(() => setLoading(false), 700);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      inView = false;
    };
  }, [id]);

  const reloadData = () => {
    setLoading(true);
    GetOneCustomer(id)
      .then((res) => {
        setCustomer(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please refresh the page and try again");
        setTimeout(() => setLoading(false), 700);
      });
  };

  let tabs = [
    { name: "Overview", href: `/customers/${id}`, current: false },
    { name: "Contact Info", href: `/customers/${id}/contact`, current: false },
    { name: "A/R Info", href: `/customers/${id}/ar`, current: false },
    { name: "Defaults", href: `/customers/${id}/defaults`, current: false },
    { name: "Equipment", href: `/customers/${id}/equipment`, current: false },
    { name: "Quotes", href: `/customers/${id}/quotes`, current: false },
    { name: "Jobs", href: `/customers/${id}/jobs`, current: true },
    { name: "Invoices", href: `/customers/${id}/invoices`, current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const CustomerNavigation = () => {
    return (
      <div className="flex flex-row items-center justify-between w-full gap-3">
        <div className="w-full sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500"
            defaultValue={tabs.find((tab) => tab.current).href}
            onChange={(e) => navigate(e.target.value)}
          >
            {tabs.map((tab) => (
              <option value={tab.href}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden w-full sm:block">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current ? "border-blue-500 text-blue-600" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium",
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    );
  };

  const renderNote = (note) => {
    return (
      <div className="flex flex-col items-start justify-center w-full px-3 py-2 bg-white rounded-lg">
        <h3 className="w-full text-sm font-base text-slate-800">{note.note}</h3>
        <div className="flex flex-row items-center justify-between w-full mt-1">
          <p className="text-xs text-slate-400/75 font-medium pt-0.5">{employees.find((emp) => emp.employeeId === note.createdBy).name}</p>
          <p className="text-xs text-slate-400/75 font-medium pt-0.5">{dayjs(note.createdAt).format("MM/DD/YYYY")}</p>
        </div>
      </div>
    );
  };

  const renderJob = (qt) => {
    return (
      <div className="relative flex flex-col items-center justify-between w-full gap-4 px-4 py-2 border border-gray-100 shadow md:flex-row rounded-xl sm:py-3 sm:px-4 lg:py-4 lg:px-5">
        <div className="flex-col items-center justify-center hidden h-full p-1 bg-gray-200 rounded-full md:flex sm:p-2 lg:p-3">
          <JobIcon className="w-8 h-8 sm:h-10 sm:w-10" />
        </div>
        <div className="flex flex-col items-start justify-center w-full gap-1 text-gray-500">
          <h3 className="font-bold text-gray-900">
            <span className="capitalize">{qt.jobNo}</span>
          </h3>
          <p className="text-sm font-medium text-gray-700">
            Status: <span className="pr-3 mr-1 text-gray-400 capitalize border-r border-black">{qt.jobStatus}</span> Created By:{" "}
            <span className="pr-3 mr-1 text-gray-400 capitalize border-r border-black">{employees.find((emp) => emp.employeeId === qt.openedBy).name}</span>{" "}
            Total: <span className="text-gray-400 capitalize">{formatCurrency(qt.total)}</span>
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <button
            onClick={() => navigate(`/jobs/${qt.jobId}`)}
            className="px-4 py-2 text-sm font-semibold uppercase duration-150 bg-gray-200 rounded-md hover:bg-gray-300/90"
          >
            Open
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="grid w-full h-full grid-cols-12 mb-4 overflow-y-auto md:px-4 gap-x-2">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full col-span-9">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="flex flex-col items-start justify-start w-full col-span-12 md:col-span-9">
          <Helmet>
            <title>
              Customer Jobs - {customer.customerCode}
              {customer.company.length > 0 ? ` - ${customer.company}` : ""} | HTPS ERP
            </title>
          </Helmet>
          <CustomerNavigation />
          <div className="mt-3 flex flex-col md:flex-row md:justify-between md:items-center items-start justify-start w-full px-3 pb-3.5 border-b border-gray-300">
            <h1 className="text-2xl md:text-[28px] leading-[42px]">Customer Jobs</h1>
            <button
              onClick={() => navigate(`/jobs/new?customerId=${id}`)}
              className="px-5 py-3 text-xs font-bold uppercase duration-150 rounded-md bg-slate-300 text-slate-800 hover:bg-gray-500/50"
            >
              New Job
            </button>
          </div>
          <div className="flex flex-col items-center justify-start w-full gap-3 pt-1 mt-3 md:px-5">
            {jobs.length > 0 ? (
              jobs.map((eq) => renderJob(eq))
            ) : (
              <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">No Jobs Found</p>
            )}
          </div>
        </div>
      )}
      <div className="flex flex-col items-start justify-start w-full col-span-12 gap-2 px-5 py-3 border rounded-lg shadow-md md:col-span-3 bg-slate-300/40 border-slate-300/60">
        <div className="flex flex-row items-center justify-between w-full pb-2 my-2 border-b border-gray-300">
          <h1 className="px-2 text-xl font-light leading-10 md:text-2xl">Notes</h1>
        </div>
        <div className="flex flex-col items-center justify-start w-full h-full gap-3">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : customer.notes.length > 0 ? (
            customer.notes.map((note) => renderNote(note))
          ) : (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">No notes found</p>
          )}
        </div>
        <div className="flex flex-col items-center justify-center w-full mt-2">
          {noteField ? (
            <NoteField
              closeField={() => {
                setNoteField(false);
                reloadData();
              }}
              id={id}
            />
          ) : (
            <button
              onClick={() => setNoteField(true)}
              className="px-5 py-3 text-xs font-bold uppercase duration-150 rounded-md bg-slate-300 text-slate-800 hover:bg-gray-500/50"
            >
              Add a new note
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerJobs;
