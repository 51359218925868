import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { AddArAltContact, DeleteArAltContact, GetOneCustomer, UpdateArAltContact, UpdateCustomerAR } from "../../actions/customers";
import FormController from "../../components/FormController";
import NoteField from "../../components/NoteField";
import { customerArInfo } from "../../data/customerForms";
import toast from "react-hot-toast";
import { GetEmployees } from "../../actions/auth";
import { Button, Input, Modal, Select } from "antd";
import { EditPencil, Trash, WarningCircle, WarningCircleSolid } from "iconoir-react";
import { MaskedInput } from "antd-mask-input";
import validator from "validator";
import { formatPhoneNumber } from "../../components/tools";
import { Helmet } from "react-helmet-async";

const CustomerAR = ({ authState, authDispatch }) => {
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [newContact, setNewContact] = useState(false);
  const [contactData, setContactData] = useState({
    name: "",
    phoneNumber: "",
    phoneType: "",
    extension: "",
    contactId: "",
  });

  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneCustomer(id)
        .then((res) => {
          setCustomer(res.data);
          GetEmployees()
            .then((res) => {
              setEmployees(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              console.log(err);
              setTimeout(() => setLoading(false), 700);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      inView = false;
    };
  }, [id]);

  const reloadData = () => {
    setLoading(true);
    GetOneCustomer(id)
      .then((res) => {
        setCustomer(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please refresh the page and try again");
        setTimeout(() => setLoading(false), 700);
      });
  };

  let tabs = [
    { name: "Overview", href: `/customers/${id}`, current: false },
    { name: "Contact Info", href: `/customers/${id}/contact`, current: false },
    { name: "A/R Info", href: `/customers/${id}/ar`, current: true },
    { name: "Defaults", href: `/customers/${id}/defaults`, current: false },
    { name: "Equipment", href: `/customers/${id}/equipment`, current: false },
    { name: "Quotes", href: `/customers/${id}/quotes`, current: false },
    { name: "Jobs", href: `/customers/${id}/jobs`, current: false },
    { name: "Invoices", href: `/customers/${id}/invoices`, current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const CustomerNavigation = () => {
    return (
      <div className="flex flex-row items-center justify-between w-full gap-3">
        <div className="w-full sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500"
            defaultValue={tabs.find((tab) => tab.current).href}
            onChange={(e) => navigate(e.target.value)}
          >
            {tabs.map((tab) => (
              <option value={tab.href}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden w-full sm:block">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current ? "border-blue-500 text-blue-600" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium",
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    );
  };

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const renderNote = (data) => {
    return (
      <div className="flex flex-col items-start justify-center w-full px-3 py-2 bg-white rounded-lg">
        <div className="flex flex-row items-center justify-between w-full mt-1">
          <h3 className="w-full text-sm font-medium text-slate-800">{data.name}</h3>
          <p className="text-xs text-slate-600 font-base pt-0.5 capitalize">{data.phoneType}</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full mt-2">
          <p className="text-xs text-slate-500 font-medium pt-0.5">
            {formatPhoneNumber(data.phoneNumber)}
            {data.extension && data.extension.length > 0 ? ` x ${data.extension}` : ""}
          </p>
          <div className="flex items-center justify-end gap-1">
            <EditPencil
              width={"1.25em"}
              height="1.25em"
              className="transition-all duration-200 cursor-pointer hover:text-blue-600"
              onClick={() => updateAltContact(data)}
            />
            <Trash
              width={"1.25em"}
              height="1.25em"
              className="transition-all duration-200 cursor-pointer hover:text-red-600"
              onClick={() => deleteAltContact(data)}
            />
          </div>
        </div>
      </div>
    );
  };

  const updateDefaults = (data) => {
    console.log(data);
    if (!data.formModified) {
      toast.error("No fields were modified. There's nothing to save.");
    } else {
      setLoading(true);
      let toSend = {};
      for (let key in data.modifiedFields) {
        toSend[key] = data[key];
      }
      UpdateCustomerAR(toSend, id)
        .then((res) => {
          toast.success("Customer A/R information updated successfully");
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please try again");
          reloadData();
        });
    }
  };

  const closeAddContact = () => {
    setNewContact(false);
    setContactData({
      name: "",
      phoneNumber: "",
      phoneType: "",
      extension: "",
      contactId: "",
    });
  };

  const updateAltContact = (data) => {
    setContactData(data);
    setTimeout(() => setNewContact(true), 500);
  };

  const deleteAltContact = (data) => {
    Modal.confirm({
      title: "Are you sure you want to delete this contact?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      centered: true,
      onOk: () => {
        setLoading(true);
        DeleteArAltContact(data, id)
          .then((res) => {
            toast.success("Alt. contact deleted successfully");
            reloadData();
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please try again");
            reloadData();
          });
      },
      onCancel: () => {},
    });
  };

  const updateContactData = (val, field) => {
    let tmp = contactData;
    tmp[field] = val;
    setContactData(tmp);
  };

  const submitContact = () => {
    let data = contactData;
    if (!data.phoneNumber || data.phoneNumber.length !== 10 || !validator.isMobilePhone(data.phoneNumber)) {
      toast.error("Invalid phone number");
    } else if (!data.name || data.name.length < 2) {
      toast.error("Invalid name");
    } else if (!data.phoneType || data.phoneType.length < 2) {
      toast.error("Invalid phone type");
    } else {
      setLoading(true);
      if (data.contactId) {
        UpdateArAltContact(data, id)
          .then((res) => {
            toast.success("Alt. contact updated successfully");
            closeAddContact();
            reloadData();
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please try again");
            closeAddContact();
            reloadData();
          });
      } else {
        AddArAltContact(data, id)
          .then((res) => {
            toast.success("Alt. contact added successfully");
            closeAddContact();
            reloadData();
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please try again");
            closeAddContact();
            reloadData();
          });
      }
    }
  };

  return (
    <div className="grid w-full h-full grid-cols-12 mb-4 overflow-y-auto md:px-4 gap-x-2">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full col-span-9">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="flex flex-col items-start justify-start w-full col-span-12 mb-10 md:col-span-9 md:mb-0">
          <Helmet>
            <title>
              Customer A/R Info - {customer.customerCode}
              {customer.company.length > 0 ? ` - ${customer.company}` : ""} | HTPS ERP
            </title>
          </Helmet>
          <CustomerNavigation />
          <div className="mt-3 flex flex-col md:flex-row md:justify-between md:items-center items-start justify-start w-full px-3 pb-3.5 border-b border-gray-300">
            <h1 className="text-2xl md:text-[28px] leading-[42px]">A/R Information</h1>
            <div className="grid items-center grid-cols-2 gap-1 md:flex md:justify-end md:flex-row">
              <p className="text-xs font-bold uppercase">Company:</p>
              <p className="font-light md:text-lg md:border-r md:border-slate-400 md:pr-2 md:mr-1.5">
                {customer.company.length > 0 ? customer.company : "N/A"}
              </p>
              <p className="text-xs font-bold uppercase">Customer Code:</p>
              <p className="font-light md:text-lg">{customer.customerCode}</p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-5 pt-1 mt-3">
            <FormController
              onSubmit={updateDefaults}
              fields={customerArInfo}
              values={customer.arData}
              buttonText={"Save Changes"}
              fullWidth={true}
              hideSubmit={authState.user.functionCategory === "technician"}
            />
          </div>
        </div>
      )}
      <div className="flex flex-col items-start justify-start w-full col-span-12 gap-2 px-5 py-3 border rounded-lg shadow-md md:col-span-3 bg-slate-300/40 border-slate-300/60">
        <div className="flex flex-row items-center justify-between w-full pb-2 my-2 border-b border-gray-300">
          <h1 className="px-2 text-xl font-light leading-10 md:text-2xl">Alt. Contacts</h1>
        </div>
        <div className="flex flex-col items-center justify-start w-full h-full gap-3">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : customer.arData.altContacts && customer.arData.altContacts.length > 0 ? (
            customer.arData.altContacts.map((note) => renderNote(note))
          ) : (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">No alt. contacts found</p>
          )}
        </div>
        <div className="flex flex-col items-center justify-center w-full mt-2">
          <button
            onClick={() => setNewContact(true)}
            className="px-5 py-3 text-xs font-bold uppercase duration-150 rounded-md bg-slate-300 text-slate-800 hover:bg-gray-500/50"
          >
            Add a new contact
          </button>
        </div>
      </div>
      <Modal
        open={newContact}
        onCancel={closeAddContact}
        onOk={submitContact}
        centered={true}
        width={650}
        destroyOnClose
        title={contactData.contactId.length > 0 ? "Update Alt. Contact" : "New Alt. Contact"}
        okText={contactData.contactId.length > 0 ? "Update Contact" : "Add Contact"}
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 py-2">
          <div key="name" className="flex flex-col items-start justify-start w-full">
            <label htmlFor="name" className="pb-2 text-xs text-gray-600 uppercase">
              Contact's Name
            </label>
            <Input
              placeholder="Contact's name"
              name="name"
              onChange={(e) => {
                updateContactData(e.target.value, "name");
              }}
              defaultValue={contactData.name}
              className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2 mt-2">
            <div key="phoneNumber" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="phoneNumber" className="pb-2 text-xs text-gray-600 uppercase">
                Phone Number
              </label>
              <MaskedInput
                id="phoneNumber"
                defaultValue={contactData.phoneNumber}
                placeholder="Phone Number"
                mask={"(000) 000-0000"}
                className="py-1 text-gray-900 border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 phoneInput"
                onChange={(e) => updateContactData(e.unmaskedValue, "phoneNumber")}
              />
            </div>
            <div key="phoneType" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="phoneType" className="pb-2 text-xs text-gray-600 uppercase">
                Type
              </label>
              <Select
                placeholder="Phone Type"
                name="phoneType"
                onChange={(v) => updateContactData(v, "phoneType")}
                options={[
                  { value: "cell", label: "Cell" },
                  { value: "fax", label: "Fax" },
                  { value: "office", label: "Office" },
                  { value: "landline", label: "Landline" },
                  { value: "other", label: "Other" },
                ]}
                defaultValue={contactData.phoneType.length > 0 ? contactData.phoneType : null}
                className="w-full font-sans"
                controls={false}
                showSearch
                filterOption={filterOption}
              />
            </div>
            <div key="extension" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="extension" className="pb-2 text-xs text-gray-600 uppercase">
                Extension
              </label>
              <Input
                placeholder="Extension"
                name="extension"
                onChange={(e) => {
                  updateContactData(e.target.value, "extension");
                }}
                defaultValue={contactData.extension}
                className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CustomerAR;
