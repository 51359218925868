import { useState, useEffect } from "react";
import { ArrowRight, MoveRight } from "iconoir-react";
import { Player } from "@lottiefiles/react-lottie-player";
import boltLoading from "../assets/boltLoading.json";
import { GetDashboard } from "../actions/auth";
import toast from "react-hot-toast";
import { formatCurrencyCompact } from "../components/tools";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";

var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const Dashboard = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    dealsClosed: 0,
    pipeline: 0,
    openJobs: [],
    openQuotes: [],
    upcomingMaintenance: [],
    notifications: [],
    openInvoices: 0,
    openPOs: 0,
    openAPInvoices: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;

    if (inView) {
      GetDashboard()
        .then((res) => {
          setData(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Something went wrong. Unable to load dashboard data.");
          setLoading(false);
        });
    }

    return () => {
      inView = false;
    };
  }, []);

  let jobStatus = {
    open: "Open",
    void: "VOID",
    complete: "Complete",
    invoiced: "Invoiced",
  };

  let quoteStatus = {
    draft: "Draft",
    approval: "Awaiting Approval",
    approved: "Approved",
    returned: "Returned",
    sent: "Sent",
    accepted: "Accepted",
    declined: "Declined",
    void: "VOID",
    jobCreated: "Job Created",
  };

  return loading ? (
    <div className="flex flex-col items-center justify-center w-full h-full gap-4">
      <Player autoplay loop src={boltLoading} style={{ width: 60, height: 60 }} className="animate-bounce" />
      <p className="text-xs font-semibold text-gray-600 uppercase animate-pulse">Loading</p>
      <Helmet>
        <title>Dashboard | HTPS ERP</title>
      </Helmet>
    </div>
  ) : (
    <div className="w-full h-full grid grid-cols-1 md:grid-cols-4 mb-2.5 gap-4 overflow-y-auto">
      <Helmet>
        <title>Dashboard | HTPS ERP</title>
      </Helmet>
      <div className="flex-col items-center justify-between hidden row-span-2 gap-3 px-6 py-4 rounded-md sm:flex bg-slate-200/70">
        <p className="text-sm font-medium uppercase text-slate-500">Welcome back, {authState.user.firstName}</p>
        <div className="flex flex-col items-center justify-center w-full gap-3">
          <button
            className="inline-flex items-center justify-center w-full gap-2 px-4 py-3 text-sm font-medium text-gray-700 align-middle transition-all border rounded-md shadow-sm border-slate-300 hover:border-gray-700 focus:outline-none focus:ring-0"
            onClick={() => navigate("/customers/new")}
          >
            New Customer
          </button>
          <button
            className="inline-flex items-center justify-center w-full gap-2 px-4 py-3 text-sm font-medium text-gray-700 align-middle transition-all border rounded-md shadow-sm border-slate-300 hover:border-gray-700 focus:outline-none focus:ring-0"
            onClick={() => navigate("/quotes/new")}
          >
            New Quote
          </button>
          <button
            className="inline-flex items-center justify-center w-full gap-2 px-4 py-3 text-sm font-medium text-gray-700 align-middle transition-all border rounded-md shadow-sm border-slate-300 hover:border-gray-700 focus:outline-none focus:ring-0"
            onClick={() => navigate("/jobs/new")}
          >
            New Job
          </button>
          {authState.user.functionCategory !== "technician" && (
            <>
              <button
                className="inline-flex items-center justify-center w-full gap-2 px-4 py-3 text-sm font-medium text-gray-700 align-middle transition-all border rounded-md shadow-sm border-slate-300 hover:border-gray-700 focus:outline-none focus:ring-0"
                onClick={() => navigate("/po/new")}
              >
                New Purchase Order
              </button>
              <button
                className="inline-flex items-center justify-center w-full gap-2 px-4 py-3 text-sm font-medium text-gray-700 align-middle transition-all border rounded-md shadow-sm border-slate-300 hover:border-gray-700 focus:outline-none focus:ring-0"
                onClick={() => navigate("/ap/new")}
              >
                New AP Invoice
              </button>
              <button
                className="inline-flex items-center justify-center w-full gap-2 px-4 py-3 text-sm font-medium text-gray-700 align-middle transition-all border rounded-md shadow-sm border-slate-300 hover:border-gray-700 focus:outline-none focus:ring-0"
                onClick={() => navigate("/reports")}
              >
                View Reports
              </button>
            </>
          )}
        </div>
        <p className="text-xs uppercase text-slate-500">Today is, {dayjs().format("MMMM Do, YYYY")}</p>
      </div>
      <div className="flex-col items-start justify-start hidden col-span-1 row-span-2 gap-3 px-6 py-4 divide-y divide-gray-400 rounded-md md:flex bg-slate-200/70">
        <p className="text-lg font-semibold">Pipeline</p>
        <div className="flex flex-row items-center justify-between w-full py-2">
          <div className="flex flex-col items-start justify-start">
            <p className="font-semibold text-slate-950">Quotes</p>
            <p className="text-xs font-semibold text-slate-900">
              <span className="font-semibold text-slate-600/80">Currently Open: </span>
              {data.openQuotes.length}
            </p>
          </div>
          <div
            className="flex flex-row items-center justify-end gap-1 underline transition-all cursor-pointer decoration-transparent hover:decoration-slate-950 hover:text-slate-950 underline-offset-2 group"
            onClick={() => navigate("/quotes")}
          >
            <p className="text-xs font-medium uppercase">See all</p>
            <ArrowRight className="h-3 w-3 mb-0.5 group-hover:text-slate-950 text-slate-700" />
          </div>
        </div>
        <div className="flex flex-row items-center justify-between w-full py-2">
          <div className="flex flex-col items-start justify-start">
            <p className="font-semibold text-slate-950">Jobs</p>
            <p className="text-xs font-semibold text-slate-900">
              <span className="font-semibold text-slate-600/80">Currently Open: </span>
              {data.jobs}
            </p>
          </div>
          <div
            className="flex flex-row items-center justify-end gap-1 underline transition-all cursor-pointer decoration-transparent hover:decoration-slate-950 hover:text-slate-950 underline-offset-2 group"
            onClick={() => navigate("/jobs")}
          >
            <p className="text-xs font-medium uppercase">See all</p>
            <ArrowRight className="h-3 w-3 mb-0.5 group-hover:text-slate-950 text-slate-700" />
          </div>
        </div>
        {authState.user.functionCategory !== "technician" && (
          <div className="flex flex-row items-center justify-between w-full py-2">
            <div className="flex flex-col items-start justify-start">
              <p className="font-semibold text-slate-950">Invoices</p>
              <p className="text-xs font-semibold text-slate-900">
                <span className="font-semibold text-slate-600/80">Currently Open: </span>
                {data.openInvoices}
              </p>
            </div>
            <div
              className="flex flex-row items-center justify-end gap-1 underline transition-all cursor-pointer decoration-transparent hover:decoration-slate-950 hover:text-slate-950 underline-offset-2 group"
              onClick={() => navigate("/invoices")}
            >
              <p className="text-xs font-medium uppercase">See all</p>
              <ArrowRight className="h-3 w-3 mb-0.5 group-hover:text-slate-950 text-slate-700" />
            </div>
          </div>
        )}
        {authState.user.functionCategory !== "technician" && (
          <div className="flex flex-row items-center justify-between w-full py-2">
            <div className="flex flex-col items-start justify-start">
              <p className="font-semibold text-slate-950">PO's</p>
              <p className="text-xs font-semibold text-slate-900">
                <span className="font-semibold text-slate-600/80">Currently Open: </span>
                {data.openPOs}
              </p>
            </div>
            <div
              className="flex flex-row items-center justify-end gap-1 underline transition-all cursor-pointer decoration-transparent hover:decoration-slate-950 hover:text-slate-950 underline-offset-2 group"
              onClick={() => navigate("/po")}
            >
              <p className="text-xs font-medium uppercase">See all</p>
              <ArrowRight className="h-3 w-3 mb-0.5 group-hover:text-slate-950 text-slate-700" />
            </div>
          </div>
        )}
        {authState.user.functionCategory !== "technician" && (
          <div className="flex flex-row items-center justify-between w-full py-2">
            <div className="flex flex-col items-start justify-start">
              <p className="font-semibold text-slate-950">AP Invoices</p>
              <p className="text-xs font-semibold text-slate-900">
                <span className="font-semibold text-slate-600/80">Currently Open: </span>
                {data.openAPInvoices}
              </p>
            </div>
            <div
              className="flex flex-row items-center justify-end gap-1 underline transition-all cursor-pointer decoration-transparent hover:decoration-slate-950 hover:text-slate-950 underline-offset-2 group"
              onClick={() => navigate("/ap")}
            >
              <p className="text-xs font-medium uppercase">See all</p>
              <ArrowRight className="h-3 w-3 mb-0.5 group-hover:text-slate-950 text-slate-700" />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col items-start justify-start col-span-2 row-span-2 px-6 py-4 rounded-md bg-slate-200/70">
        <p className="text-lg font-semibold">Open Jobs</p>
        <div className="flex flex-col items-start justify-start flex-grow w-full overflow-y-auto">
          <table className="min-w-full divide-y divide-slate-400/80">
            <thead>
              <tr>
                <th scope="col" className="py-3 text-xs font-medium text-left text-gray-500 uppercase">
                  Job NO.
                </th>
                <th scope="col" className="py-3 text-xs font-medium text-left text-gray-500 uppercase">
                  Customer
                </th>
                <th scope="col" className="py-3 text-xs font-medium text-left text-gray-500 uppercase">
                  Status
                </th>
                <th scope="col" className="py-3 text-xs font-medium text-right text-gray-500 uppercase"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-slate-400/80">
              {data.openJobs.map((job, index) => {
                return (
                  index < 11 && (
                    <tr>
                      <td className="py-2 text-xs font-medium text-gray-800 whitespace-nowrap">{job.jobNo}</td>
                      <td className="py-2 text-xs text-gray-800 whitespace-nowrap">
                        {job.customerCode.toUpperCase() +
                          (job.customerName.length > 0 ? " | " : "") +
                          (job.customerName.length > 35 ? `${job.customerName.substring(0, 35)}...` : job.customerName)}
                      </td>
                      <td className="py-2 text-xs text-gray-800 whitespace-nowrap">{jobStatus[job.jobStatus]}</td>
                      <td className="py-2 text-xs font-medium text-right whitespace-nowrap">
                        <button
                          className="bg-slate-300 text-xs uppercase px-3 py-1.5 rounded-md text-slate-600 hover:text-blue-700 transition-all hover:bg-blue-200/60"
                          onClick={() => navigate(`/jobs/${job.jobId}`)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start col-span-2 px-6 py-4 rounded-md bg-slate-200/70">
        <p className="text-lg font-semibold">Recent Quotes</p>
        <div className="flex flex-col items-start justify-start flex-grow w-full overflow-y-auto">
          <table className="min-w-full divide-y divide-slate-400/80">
            <thead>
              <tr>
                <th scope="col" className="py-3 text-xs font-medium text-left text-gray-500 uppercase">
                  Quote NO.
                </th>
                <th scope="col" className="py-3 text-xs font-medium text-left text-gray-500 uppercase">
                  Customer
                </th>
                <th scope="col" className="py-3 text-xs font-medium text-left text-gray-500 uppercase">
                  Status
                </th>
                <th scope="col" className="py-3 text-xs font-medium text-right text-gray-500 uppercase"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-slate-400/80">
              {data.openQuotes.map((quote, index) => {
                return (
                  index < 11 && (
                    <tr>
                      <td className="py-2 text-xs font-medium text-gray-800 whitespace-nowrap">{quote.quoteNo}</td>
                      <td className="py-2 text-xs text-gray-800 whitespace-nowrap">{quote.customer}</td>
                      <td className="py-2 text-xs text-gray-800 whitespace-nowrap">{quoteStatus[quote.quoteStatus]}</td>
                      <td className="py-2 text-xs font-medium text-right whitespace-nowrap">
                        <button
                          className="bg-slate-300 text-xs uppercase px-3 py-1.5 rounded-md text-slate-600 hover:text-blue-700 transition-all hover:bg-blue-200/60"
                          onClick={() => navigate(`/quotes/${quote.quoteId}`)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start col-span-2 px-6 py-4 rounded-md md:col-span-1 bg-slate-200/70">
        <p className="text-lg font-semibold">Upcoming Maintenance</p>
        <div className="flex flex-col items-start justify-start flex-grow w-full overflow-y-auto">
          <ul className="w-full divide-y divide-slate-400/80">
            {data.upcomingMaintenance.map((maintenance, index) => {
              return (
                index < 11 && (
                  <li className="flex justify-between py-5 gap-x-6">
                    <div className="flex min-w-0 gap-x-4">
                      <div className="flex-auto min-w-0">
                        <p className="text-sm font-semibold text-gray-900">{maintenance.customerName}</p>
                        <p className="mt-1 text-xs text-gray-500 truncate">{maintenance.serviceType}</p>
                      </div>
                    </div>
                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                      <button
                        className="px-3 py-1 text-xs font-medium uppercase transition-all rounded-md bg-slate-300 text-slate-600 hover:text-blue-700 hover:bg-blue-200/60"
                        onClick={() => navigate(`/equipment/${maintenance.equipmentId}/serviceSchedule`)}
                      >
                        Open
                      </button>
                      <p className="mt-1 text-xs text-gray-500">
                        {dayjs()
                          .set("month", maintenance.schedule - 1)
                          .format("MMMM")}
                      </p>
                    </div>
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </div>
      <div className="flex-col items-start justify-start hidden px-6 py-4 rounded-md md:flex bg-slate-200/70">
        <p className="text-lg font-semibold">Notifications</p>
        <div className="flex flex-col items-start justify-start flex-grow w-full overflow-y-auto">
          <ul className="w-full divide-y divide-slate-400/80">
            {/* {data.notifications.map((notif) => (
              <li className="flex justify-between py-5 gap-x-6">
                <div className="flex min-w-0 gap-x-4">
                  <div className="flex-auto min-w-0">
                    <p className="text-sm font-semibold text-gray-900">Quote Approval</p>
                    <p className="mt-1 text-xs text-gray-500 truncate">by Dino Delkic</p>
                  </div>
                </div>
                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  <button className="px-3 py-1 text-xs font-medium uppercase transition-all rounded-md bg-slate-300 text-slate-600 hover:text-blue-700 hover:bg-blue-200/60">
                    Open
                  </button>
                  <p className="mt-1 text-xs text-gray-500">20 min ago</p>
                </div>
              </li>
            ))} */}
            <p className="text-sm font-semibold text-slate-600/60">Coming soon</p>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
