import { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Search, Xmark, Check } from "iconoir-react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { AddAccount, GetAccounts, UpdateAccount } from "../../actions/admin";
import { toast } from "react-hot-toast";
import FormController from "../../components/FormController";
import { newAccountForm, updateAccountForm } from "../../data/settingsForms";
import { Helmet } from "react-helmet-async";

const ChartOfAccounts = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [newAccount, setNewAccount] = useState(false);
  const [newAccountData, setNewAccountData] = useState({
    accountNo: "",
    description: "",
    contra: false,
    ap: false,
    ltd: false,
    cc: false,
    time: false,
    category: "",
    balanceSheet: false,
    income: false,
    cashFlow: false,
    changesInCapital: false,
  });
  const [accountDrawer, setAccountDrawer] = useState(false);
  const [account, setAccount] = useState({
    accountId: "",
    accountNo: "",
    ap: false,
    category: "",
    cc: false,
    contra: false,
    description: "",
    ltd: false,
    statements: {
      balanceSheet: false,
      income: false,
      cashFlow: false,
      changesInCapital: false,
    },
    time: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetAccounts()
        .then((res) => {
          setAccounts(res.data);
          setRawData(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleSearch = (val) => {
    if (val.length > 0) {
      setLoading(true);
      let filtered = _.filter(rawData, (o) => {
        return (
          o.accountNo.toLowerCase().includes(val.toLowerCase()) ||
          o.description.toLowerCase().includes(val.toLowerCase()) ||
          o.category.toLowerCase().includes(val.toLowerCase())
        );
      });
      setAccounts(filtered);
      setTimeout(() => {
        setLoading(false);
      }, 700);
    } else {
      setAccounts(rawData);
      setLoading(false);
    }
  };

  const renderBool = (value) => {
    if (value) {
      return <Check className="w-5 h-5 mx-auto text-green-500" aria-hidden="true" />;
    } else {
      return <Xmark className="w-5 h-5 mx-auto text-red-500" aria-hidden="true" />;
    }
  };

  const renderAddAccountModal = () => {
    return (
      <Transition.Root show={newAccount} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddAccount}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-2 sm:w-full sm:max-w-4xl sm:p-6">
                  <div>
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
                      Add a new account
                    </Dialog.Title>
                    <div className="grid grid-cols-1 gap-6 mt-2">
                      <FormController onSubmit={submitNewAccount} fields={newAccountForm} values={newAccountData} buttonText={"Add Account"} fullWidth={true} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAddAccount = () => {
    setNewAccount(false);
    setNewAccountData({
      accountNo: "",
      description: "",
      contra: false,
      ap: false,
      ltd: false,
      cc: false,
      time: false,
      category: "",
      balanceSheet: false,
      income: false,
      cashFlow: false,
      changesInCapital: false,
    });
  };

  const submitNewAccount = (data) => {
    setLoading(true);
    AddAccount(data)
      .then((res) => {
        toast.success("Account added successfully");
        closeAddAccount();
        GetAccounts()
          .then((res) => {
            setAccounts(res.data);
            setRawData(res.data);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
            setLoading(false);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
        setLoading(false);
      });
  };

  const openAccount = (acc) => {
    setAccount(acc);
    setTimeout(() => setAccountDrawer(true), 700);
  };

  const closeAccount = (acc) => {
    setAccountDrawer(false);
    setTimeout(
      () =>
        setAccount({
          accountId: "",
          accountNo: "",
          ap: false,
          category: "",
          cc: false,
          contra: false,
          description: "",
          ltd: false,
          statements: {
            balanceSheet: false,
            income: false,
            cashFlow: false,
            changesInCapital: false,
          },
          time: false,
        }),
      700,
    );
  };

  const saveAccountChanges = (data) => {
    if (data.formModified) {
      let toSend = {};
      for (let property in data.modifiedFields) {
        toSend[property] = data[property];
      }
      setLoading(true);
      UpdateAccount(account.accountId, toSend)
        .then((res) => {
          toast.success("Account updated successfully");
          closeAccount();
          reloadAccounts();
        })
        .catch((err) => {
          toast.err(err.response.data.message ? err.response.data.message : "Error updating account. Please try again");
          closeAccount();
          reloadAccounts();
        });
    } else {
      closeAccount();
    }
  };

  const reloadAccounts = () => {
    GetAccounts()
      .then((res) => {
        setAccounts(res.data);
        setRawData(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
        setLoading(false);
      });
  };

  const renderAccountDrawer = () => {
    return (
      <Transition.Root show={accountDrawer} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAccount}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                    <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Account Details</Dialog.Title>
                          <div className="flex items-center ml-3 h-7">
                            <button type="button" className="relative text-gray-400 bg-white rounded-md hover:text-gray-500" onClick={() => closeAccount()}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <Xmark className="w-6 h-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex-col items-start justify-start flex-1 gap-3 px-4 pt-4 mt-6 border-t border-gray-200 sm:px-6">
                        <FormController
                          onSubmit={saveAccountChanges}
                          fields={updateAccountForm}
                          values={account}
                          buttonText={"Save Changes"}
                          fullWidth={true}
                        />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <h1 className="mt-3 mb-3 text-3xl font-bold">Chart of Accounts</h1>
      <Helmet>
        <title>Chart of Accounts | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-row items-center justify-between w-full gap-2 px-1">
        <div className="relative rounded-md shadow-sm w-80">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <Search className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder="Search accounts"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <SecondaryButton callback={() => setNewAccount(true)} label={"Add a New Account"} />
      </div>
      <div className="flex-grow flow-root w-full px-4 mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {loading ? (
              <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
            ) : accounts.length > 0 ? (
              <table className="min-w-full border-separate border-spacing-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8 w-fit"
                    >
                      Account No.
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell w-1/4"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell w-fit"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell w-fit"
                    >
                      Contra
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell w-fit"
                    >
                      Accounts Payable
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell w-fit"
                    >
                      Long-Term Debt
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell w-fit"
                    >
                      Credit Card
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell w-fit"
                    >
                      Time
                    </th>
                    <th scope="col" className="sticky top-0 z-10 border-b border-gray-300 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                      <span className="sr-only">View / Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.map((acc, accIndex) => (
                    <tr key={acc.accountId}>
                      <td
                        className={classNames(
                          accIndex !== accounts.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8",
                        )}
                      >
                        {acc.accountNo}
                      </td>
                      <td
                        className={classNames(
                          accIndex !== accounts.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell",
                        )}
                      >
                        {acc.description}
                      </td>
                      <td
                        className={classNames(
                          accIndex !== accounts.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap capitalize hidden px-3 py-4 text-sm text-gray-500 lg:table-cell",
                        )}
                      >
                        {acc.category}
                      </td>
                      <td
                        className={classNames(
                          accIndex !== accounts.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell",
                        )}
                      >
                        {renderBool(acc.contra)}
                      </td>
                      <td
                        className={classNames(
                          accIndex !== accounts.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell",
                        )}
                      >
                        {renderBool(acc.ap)}
                      </td>
                      <td
                        className={classNames(
                          accIndex !== accounts.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell",
                        )}
                      >
                        {renderBool(acc.ltd)}
                      </td>
                      <td
                        className={classNames(
                          accIndex !== accounts.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell",
                        )}
                      >
                        {renderBool(acc.cc)}
                      </td>
                      <td
                        className={classNames(
                          accIndex !== accounts.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell",
                        )}
                      >
                        {renderBool(acc.time)}
                      </td>
                      <td
                        className={classNames(
                          accIndex !== accounts.length - 1 ? "border-b border-gray-200" : "",
                          "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8",
                        )}
                      >
                        <button
                          onClick={() => openAccount(acc)}
                          className="px-4 py-2 text-xs font-semibold text-blue-600 uppercase rounded-md hover:text-blue-900 bg-gray-200/75"
                        >
                          Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex flex-col items-center justify-start w-full h-full gap-5 pt-10 border-t border-gray-300">
                <h4 className="text-lg font-semibold text-gray-500">No accounts found</h4>
                <SecondaryButton callback={() => setNewAccount(true)} label={"Add a New Account"} />
              </div>
            )}
          </div>
        </div>
      </div>
      {renderAddAccountModal()}
      {renderAccountDrawer()}
    </div>
  );
};

export default ChartOfAccounts;
