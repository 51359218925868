import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { CheckCircle } from "iconoir-react";
import { Drawer, Popover } from "antd";
import dayjs from "dayjs";
import { SecondaryButton } from "../components/buttons";
import { Helmet } from "react-helmet-async";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Changelog = ({ authState, authDispatch }) => {
  const [drawer, setDrawer] = useState(false);

  const navigate = useNavigate();

  const activity = [
    {
      id: 30,
      dateTime: "2024-05-30T07:50:20.211Z",
      title: "Production R-1.1.06",
      updates: ["Added ability to see part photos added from the iPad app"],
    },
    {
      id: 29,
      dateTime: "2024-05-20T11:00:00.000Z",
      title: "Production R-1.1.05",
      updates: [
        "Job Equipment now shows Job No",
        "Added ability to log miles/hrs for equipment on jobs",
        "Added ability to log miles/hrs for equipment when creating a job",
        "Added a button to delete equipment from the job instead of using the sidebar",
        "Added ability to edit PO's in any state",
        "Removed modal when marking PO's as submitted if sufficient data exists",
        "Added ability to log other payments on jobs",
        "Fixed the bug where the shop fee would always recalculate to 3%",
        "Bug fixes and performance improvements",
      ],
    },
    {
      id: 28,
      dateTime: "2024-05-13T10:00:00.000Z",
      title: "Production R-1.1.04",
      updates: [
        "Added a AR Aging Report printout",
        "Updated default sorting on invoices page",
        "Fixed the daily time report and added automatic emails",
        "Tax calculation on existing invoices now uses the tax rate at the time of invoicing",
        "Items added to POs will now be added to jobs if they're not already there",
        "Added the ability to jump to job overview from PO",
        "Site titles now match content",
        "Foundational work for the upcoming inventory count",
        "Bug fixes and performance improvements",
      ],
    },
    {
      id: 27,
      dateTime: "2024-05-06T18:15:00.000Z",
      title: "Production R-1.1.03",
      updates: ["Added AR Aging Report", "Added sorting to invoiced page", "Added the ability to filter invoices by location on the invoice page"],
    },
    {
      id: 26,
      dateTime: "2024-05-06T10:10:00.000Z",
      title: "Production R-1.1.02",
      updates: [
        "Ability to add alternative contact info to customers",
        "Ability to replace customers on jobs",
        "Expanded equipment information on jobs",
        "Bug fixes and performance improvements",
      ],
    },
    {
      id: 25,
      dateTime: "2024-04-15T12:00:00.038Z",
      title: "Production R-1.1.01",
      updates: [
        "Allowed revision of invoices",
        "PO # on invoices can now be updated regardless of the invoice status",
        "Added check no., amount, date, name on the check and notes to invoice payment logs",
        "Credit card fee now shows only for payments made by card",
        "Added a payments tab to jobs with ability to reprint receipts",
        "Added ability to collect additional payments from the Job > Payments tab",
        "Added services to Purchase Orders - Added services will automagically be added to the job",
      ],
    },
    {
      id: 24,
      dateTime: "2024-04-01T06:00:00.617Z",
      title: "Production R-1.1",
      updates: [
        "Quote related printouts printouts now fallback to contact info on customer if AR info is missing",
        "Quote related printouts now include equipment information where applicable",
        "Quotes now include shop fee",
        "Quotes now include editable footer notes for terms and what's included/not included in the price",
        "Jobs now include shop fee",
        "Create a job from quote flow bugs fixed",
        "Canned Misc Fees for Jobs now can be managed from the admin section of the ERP",
        "Added the ability to collect payments during the job creation flow",
        "Added the ability to email / text receipts to customers (for job one time payments)",
        "Verified the alignment of the prints for the new check design",
      ],
    },
    {
      id: 23,
      dateTime: "2024-03-19T09:01:07.066Z",
      title: "Production R-1.0",
      updates: [
        "Added quantity to job > parking, allowing for multiple vehicles to charged on 1 line item",
        "Fixed the overlap on Open Job Report printout",
        "Verified the linking between jobs and newly created Purchase Orders",
        "Adding notes to Purchase Orders does not reset all progress anymore",
        "Added Cost, Markup, and Cost total to Purchase Orders",
        "Added customer info to Purchase Orders where applicable",
        "Added ability to open customer profile in new tab while creating a job",
        "Added a tentative preview of the job number that would be assigned to the job being created",
        "Fixed the bugs with invoices not calculating tax / showing $0.00 tax",
        "Added capitalized labor logs to invoice labor description and allowed for editing the same",
        "Completed inventory count update",
        "Other performance, security, and bug fixes",
      ],
    },
    {
      id: 23,
      dateTime: "2024-02-12T12:00:00.000Z",
      title: "Production RC-1.2",
      updates: [
        "Show PTO on timesheets (both for employees and management)",
        "Make timesheets printable",
        "Added checks and alerts for missed or incomplete timesheet entries",
        "Job number generation made specific to location",
        "Warranty workflow added",
        "North Augusta jobs will now be prefilled with the North Augusta ZIP code",
        "SPF, DKIM, and DMARC records added to the email server",
        "Updated job sorting to sort by creation date, instead of job number - due to location specific job numbers",
        "Added ability to edit labor line item description",
        "Updated timesheet logic to address bugs and performance issues",
        "Added ability to add equipment to jobs when opening them",
        "Updated logic for adding parts to the job",
        "Added predefined service fees",
        "Made minor adjustments to technician's dashboard UI",
      ],
    },
    {
      id: 22,
      dateTime: "2024-02-08T11:45:00.000Z",
      title: "Timesheet Patch - Time Off",
      updates: ["Dates with time off will now be shown on timesheets", "Jobs will be sorted by job number by default"],
    },
    {
      id: 21,
      dateTime: "2024-02-05T06:00:00.000Z",
      title: "Production RC-1.1",
      updates: [
        "Injected stock quantities for NA location per document provided",
        "Allowed technicians to open new jobs",
        "Allowed technicians to create new customers",
        "Allowed technicians to create new quotes",
        "Allowed technicians to enter checklists",
        "Allowed technicians to add parts and labor to jobs",
        "Wiped all previous customers, quotes, jobs, invoices, AP invoices, and PO's from the new system",
        "Migrated current job list from the old system",
        "Updated the default values for new customers",
        "Updated the default values for new jobs",
        "Minor optimizations and bug fixes",
      ],
    },
    {
      id: 20,
      dateTime: "2024-02-02T15:50:00.000Z",
      title: "Partial time logging",
      updates: ["Allowed partial time logging for timesheets", "Added the ability to skip lunch when logging time for timesheets"],
    },
    {
      id: 19,
      dateTime: "2024-01-29T19:00:00.000Z",
      title: "Production RC1",
      updates: [
        "Completely optimized Employee role UI for mobile devices",
        "Added job preview and job sheet printouts",
        "Added notes to PO's and PO printouts",
        "Updated Shop Fee math to calculate 3% on labor only",
        "Disabled customer facing communications for current production RC",
        "Updated the check design to match the new format",
        "Cancel & Go Back on Checklists fixed",
        "Job parts pricing fixed",
        "Fixed the bug when PO is received to reflect parts on the job",
        "Fixed the PO total preview on jobs",
        "Added timesheet reviewing logic",
        "Updated the employee invitation email to include a link",
        "Other security and performance improvements",
      ],
    },
    {
      id: 18,
      dateTime: "2024-01-21T18:20:00.000Z",
      title: "Pre-Production Polishing Batch #2",
      updates: [
        "Update default shop fee to 3%",
        "Redirect to the new customer after creation",
        "Show Sales Tax ZIP on customer overview",
        "Inventory Adjustment Printout",
        "Remove email/phone requirement on customer creation",
        "Hide ATS tab on truck and trailer equipment types",
        "Remove job details field requirement",
        "PO Printout fix",
        "Add PO to a job once created",
        "Fixed AR Terms on customer overview",
        "Show customer notes on job creation",
        "Prefill location on PO Creation",
        "PO Preview not showing part description fix",
        "Checklist/Test data prefill when created from a job (Customer & Job No.)",
        "Job Number generator fix",
        "PO Number generator fix",
        "Added ability to view, edit and add labor rates",
        "Updated invoice tax logic - fixed the bug with tax being calculated on labor",
        "Updated receive parts logic on PO's",
        "Updated invoice entering logic on PO's",
        "AP Invoices - Added the ability to add attachments after creating the invoice",
        "AP Invoices - Added the ability to attach/modify PO reference",
        "AP Invoices - Added the ability to attach/modify Account reference",
        "AP Invoices - Added the ability to remove items from the invoice after creation",
        "AP Invoices - Added the ability to void the invoice",
        "AP Invoices - Added an automation to send an email alert and automatically update invoice status to overdue when due date has passed",
      ],
    },
    {
      id: 17,
      dateTime: "2024-01-10T12:39:22.912Z",
      title: "Pre-Production Polishing Batch #1",
      updates: [
        "Added logic to trigger snowflakes effect automatically from 12/1 to 1/5",
        "Added Buckeye Forms",
        "Added Magellan Forms",
        "Added Time-Off Logic",
        "Started on optimization of the UI for mobile devices",
        "Created and added technician specific documentation",
      ],
    },
    {
      id: 16,
      dateTime: "2023-12-21T14:00:00.996Z",
      title: "Technician Role - Scope narrowed",
      updates: [
        "Pressing enter after filling in the credentials will now finally submit the login form",
        "Removed customer defaults view from customer overview page",
        "Removed customer defaults tab from customer view",
        "Revoked access to PO's for Technicians",
        "Revoked access to creating or editing checklists for technicians",
        "Added search to the equipment tab of customer view",
        "Added a fallback '404 - Not Found' page for invalid routes or routes that exceed users access permissions",
        "Remove the requirement for 'Company Name' on customer creation",
      ],
    },
    {
      id: 15,
      dateTime: "2023-12-18T13:00:00.000Z",
      title: "12/18 Batch 1",
      updates: ["Edit User Profile"],
    },
    {
      id: 14,
      dateTime: "2023-12-08T22:25:18.595Z",
      title: "12/8 Batch 2",
      updates: [
        "Allowed document attachments to AP Invoices",
        "Added PO # Reference to AP Invoices",
        "Added Job # Reference to AP Invoices",
        "Added Account # Reference to AP Invoices",
        "Added AP Invoice Control Report printout",
      ],
    },
    {
      id: 13,
      dateTime: "2023-12-08T19:25:29.076Z",
      title: "12/8 Batch 1",
      updates: ["Removed email & phone number fields as a requirement on customer profiles", "Started implementation of the administrative dashboard"],
    },
    {
      id: 12,
      dateTime: "2023-12-01T05:14:26.213Z",
      title: "Serialization",
      updates: [
        "Added logic to include 01 for Augusta and 02 for North Augusta in Quote numbers",
        "Added logic to include 01 for Augusta and 02 for North Augusta in Job numbers",
        "Added logic to include 01 for Augusta and 02 for North Augusta in Invoice numbers",
        "Added logic to include 01 for Augusta and 02 for North Augusta in AP Invoice numbers",
        "Added logic to include 01 for Augusta and 02 for North Augusta in Purchase Order numbers",
        "Added logic to include 01 for Augusta and 02 for North Augusta in Inventory Transmittal numbers",
      ],
    },
    {
      id: 11,
      dateTime: "2023-11-30T19:07:39.751Z",
      title: "Logout Button",
      updates: ["Added a dropdown menu to the top right of the navigation bar (user initials)"],
    },
    {
      id: 10,
      dateTime: "2023-11-30T07:05:25.472Z",
      title: "Timesheets Expansion",
      updates: [
        "Shifted pay periods by 1 week to align with the pay periods used by accounting",
        "Daily Timesheet Summary Added (Use November 20th, 2023 to see an example)",
        "Added an automation to send Daily Timesheet Summaries to Beverly every day at 5 PM [Disabled until production deployment]",
        "Weekly Timesheet Summary Added (Use week of November 20th, 2023 to see an example)",
        "Employee Timesheets added under reports (use pay period 11/20/2023 - 12/3/2023 and employee profile 'Dino Delkic' to see an example)",
        "Time must be entered for the previous day before the next entry can be made",
        "Added alerts and reminders for missed timesheet entries (applicable to role 'technician' only) [Disabled until production deployment]",
        "Time entering cutoff set to 5PM on Monday following the pay period end date",
        "Added Elastic Search to Quotes",
        "Added Elastic Search to Jobs",
        "Added Elastic Search to Invoices",
        "Added Elastic Search to Purchase Orders",
        "Added Elastic Search to AP Invoices",
        "Added check printing feature to AP Invoices",
        "Removed comment field requirement on AP Invoices payment logging",
        "Added the ability to update AP Invoices",
        "Added validation to prevent payment logging for AP Invoices after the invoice has been paid",
      ],
    },
    {
      id: 9,
      dateTime: "2023-11-22T12:27:27.592Z",
      title: "Timesheets",
      updates: [
        "Added the ability to log time for timesheets",
        "Added the ability to log R&M time for timesheets",
        "Included labor entered on jobs in timesheets",
        "Added the logic to calculate the total hours worked for timesheets",
      ],
    },
    {
      id: 8,
      dateTime: "2023-11-20T15:00:54.434Z",
      title: "Checklist Updates",
      updates: [
        "Added the ability to make edits to annual maintenance checklists",
        "Added the ability to make edits to semi-annual maintenance checklists",
        "Added the ability to make edits to quarterly maintenance checklists",
        "Added the ability to make edits to monthly maintenance checklists",
        "Added the ability to make edits to level-one service checklists",
        "Added the ability to make edits to loadbank load test logs",
        "Added the ability to make edits to gasoline delivery tank leakage test certification forms",
        "Added the ability to make edits to wet test certification forms",
        "Added the ability to make edits to internal visual inspection forms",
        "Added the ability to make edits to 5-year external visual inspection forms",
        "Added the ability to make edits to Kinder Morgan annual tank trailer inspection forms",
        "Added the ability to make edits to Citgo carrier equipment inspection forms",
        "Added Elastic Search to Checklists",
      ],
    },
    {
      id: 7,
      dateTime: "2023-11-18T15:14:46.162Z",
      title: "Revisiting Checklists & Addressing Bugs",
      updates: [
        "Added E-Signature to Citgo Inspection",
        "Added E-Signature to KM Tank Trailer Inspection",
        "Added E-Signature to Gasoline Delivery Tank Leakage Test",
        "Added E-Signature to Internal Visual Inspection Report",
        "Added E-Signature to Wet Test Certification",
        "Fixed a caching bug with vendors",
        "Fixed a linking issue on parts search dropdown",
        "Added names preview when hovering over the sidebar icons",
        "Updated equipment creation and basic info/details preview to reflect fields provided by the client",
        "Clicking on 'Add Equipment' from customer preview will now prefill customer information",
      ],
    },
    {
      id: 6,
      dateTime: "2023-11-15T03:12:52.633Z",
      title: "Equipment History Search & Bug Fixes",
      updates: [
        "Added a keyword based search for equipment history",
        "Added logic to update equipment history on quote creation/updates. This will add/remove records from equipment history",
        "Added logic to update equipment history on job creation/updates. This will add/remove records from equipment history",
        "Added logic to update equipment history on invoice creation/updates. This will add/remove records from equipment history",
        "Fixed a bug with the 'Invoices' flow resulting in a blank page when opening an invoice",
        "Fixed a bug with the 'Quotes' flow resulting in a blank page when opening a quote",
        "Fixed the link routing to the new 'Part Profile' creation flow",
        "Fixed the synchronization delay for newly created Vendors",
        "Minor performance improvements",
      ],
    },
    {
      id: 5,
      dateTime: "2023-11-13T17:35:52.633Z",
      title: "Bug Fix",
      updates: ["Fixed the bug on AP Invoice creation flow, resulting in a blank page", "Added a changelog page"],
    },
    {
      id: 4,
      dateTime: "2023-11-07T17:00:52.633Z",
      title: "Performance Updates and Bug Fixes",
      updates: [
        "Implemented real-time sync between elastic data and database",
        "Fixed customer name rendering on quotes and equipment",
        "Implemented a new 'Add Part Profile' flow, allowing for vendor creation from within the flow",
        "Fixed the dropzone on photo upload, images can now be dragged and dropped to be uploaded",
        "Fixed load-time performance on checklists",
        "Added notes on maintenance related checklists",
        "Fixed loading times and performance on the 'Equipment Log' report",
        "Fixed page break issue with the 'Inventory Count Sheet' report",
        "Improved loading performance of the 'Upcoming Equipment Services' report",
      ],
    },
    {
      id: 3,
      comment: "Called client, they reassured me the invoice would be paid by the 25th.",
      dateTime: "2023-11-06T17:00:52.633Z",
      title: "Performance Updates & Bug Fixes",
      updates: [
        "Reworked the equipment views under customers",
        "Optimized the Quote creation workflow performance",
        "Reworked the 'Add Parts' logic on quotes, jobs, and invoices",
        "Added equipment filtering by type on the equipment page",
        "Fixed the invoice PDF page break issue",
      ],
    },
    {
      id: 2,
      dateTime: "2023-11-03T17:00:52.633Z",
      title: "Flow Updates & Minor Bug Fixed",
      updates: ["Reworked the 'Add Equipment' flow per provided spec", "Fixed a bug with the photo upload by file select"],
    },
    {
      id: 1,
      dateTime: "2023-11-02T17:00:52.633Z",
      title: "Elastic Search Implementation",
      updates: ["Added ElasticSearch", "Optimized Get All Queries"],
    },
  ];

  const upcomingFeatures = [
    // "Receipt Printing",
    // "AP Invoices - Cron to mark invoices overdue if unpaid past due date",
    // "AP Invoices - Update invoice items (Add / Remove / Edit)",
    // "AP Invoices - Show PO Reference on AP Open",
    // "AP Invoices - Show Job Reference on AP Open",
    // "AP Invoices - Show Account Reference on AP Open",
    // "AP Invoices - Add more attachments post creation",
    // "HTPS-29 Inventory Quantity Adjustment printout",
    "Inventory Use Report",
    // "Time off Logic",
    // "Timesheet Reviewing Logic",
    // "Bulk AP Payment / Check Printing",
    "[DB] Concurrency Control",
    "Commission and Commission Report",
    "GA Sales Tax Report",
    "SC Sales Tax Report",
    "Sales Tax Deposit Report",
    "R&M Report",
    // "WIP Report",
    // "Revisit permissions upon role outline lock-in and trim backend response data accordingly",
    "Create a email notification on equipment create / update by technicians and send it to Vickie or Patty depending on the location outlining the changes",
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Changelog | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">ChangeLog</p>
          {/* <SecondaryButton label="Upcoming Features" callback={() => setDrawer(true)} /> */}
        </div>
        <ul className="w-full max-w-3xl mx-auto my-10 space-y-6">
          {activity.map((activityItem, activityItemIdx) => (
            <li key={activityItem.id} className="relative flex gap-x-4">
              <div className={classNames(activityItemIdx === activity.length - 1 ? "h-6" : "-bottom-6", "absolute left-0 top-0 flex w-6 justify-center")}>
                <div className="w-px bg-gray-200" />
              </div>
              <div className="relative flex items-center justify-center flex-none w-6 h-6">
                <CheckCircle className="w-6 h-6 text-green-600 bg-gray-50" aria-hidden="true" />
              </div>
              <div className="flex-auto p-3 rounded-md ring-1 ring-inset ring-gray-200">
                <div className="flex justify-between gap-x-4">
                  <div className="py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">Dino Delkic</span> deployed an update
                  </div>
                  <Popover content={<p>{dayjs(activityItem.dateTime).format("MM/DD/YYYY")}</p>}>
                    <time dateTime={activityItem.dateTime} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                      {dayjs(activityItem.dateTime).fromNow()}
                    </time>
                  </Popover>
                </div>
                <p className="mt-1 text-sm font-medium text-gray-700">{activityItem.title}</p>
                <ul className="px-5 my-2 list-disc">
                  {activityItem.updates.map((update, updateIdx) => (
                    <li key={updateIdx} className="text-sm text-gray-500">
                      {update}
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Drawer open={drawer} onClose={() => setDrawer(false)} placement="right" title="Upcoming Features">
        <p className="mt-1 text-sm font-medium text-gray-700">Features coming soon:</p>
        <ul className="px-5 my-2 overflow-y-auto list-disc">
          {upcomingFeatures.map((feature, featureIdx) => (
            <li key={featureIdx} className="pt-2 text-sm text-gray-500">
              {feature}
            </li>
          ))}
        </ul>
      </Drawer>
    </div>
  );
};

export default Changelog;
