import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { OpenInWindow, Printer } from "iconoir-react";
import { GetCountData } from "../../../actions/reports";
import { GetDepartments } from "../../../actions/auth";
import dayjs from "dayjs";
import { formatCurrency } from "../../../components/tools";
import { GenerateCountReport, GenerateOpenJobsReport } from "../../../data/pdf";
import { Helmet } from "react-helmet-async";

const InventoryCounts = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(false);
  const [parts, setParts] = useState([]);
  const [countData, setCountData] = useState(null);
  const [departments, setDepartments] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;

    if (inView) {
      GetCountData()
        .then((res) => {
          setParts(res.data.parts);
          setCountData(res.data.countData);
          GetDepartments()
            .then((res) => {
              setDepartments(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "We encountered an error while loading inventory count data");
              setLoading(false);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "We encountered an error while loading inventory count data");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const printReport = () => {
    let doc = GenerateCountReport(parts, countData, departments);
    doc.setProperties({
      title: `Hi-Tech Power Systems | Inventory Count Report`,
      subject: `Hi-Tech Power Systems | Inventory Count Report`,
      author: "Hypertek Solutions LLC",
      keywords: "",
      creator: "contact@hypertek.dev",
    });
    window.open(doc.output("bloburl"), "_blank");
  };

  const renderTableRow = (data) => {
    return (
      <tr className="even:bg-gray-50">
        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-3">{data.partNo}</td>
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
          CAT: {data.category && data.category.length > 0 ? data.category.toUpperCase() : "N/A"} / DPT:{" "}
          {data.department && data.department.length > 0 ? renderDepartment(data.department) : "N/A"} / MFG:{" "}
          {data.manufacturer && data.manufacturer.length > 0 ? data.manufacturer.toUpperCase() : "N/A"}
        </td>
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
          {data.description && data.description.length > 0 ? data.description.slice(0, 20) : "NA"}
          {data.description && data.description.length > 20 ? "..." : ""}
        </td>
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{formatCurrency(data.cost)}</td>
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{formatCurrency(data.chargeOut)}</td>
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{renderStock(data.stock)}</td>
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
          {data.photos && data.photos.length > 0 ? `Has ${data.photos.length} photo${data.photos.length > 1 ? "s" : ""}` : "No photos"}
        </td>
        <td className="relative py-4 pl-3 whitespace-nowrap pr-4sm:pr-3">
          <button
            onClick={() => window.open(`/parts/${data.partId}`, "_blank")}
            className="py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md border-2 border-gray-200 font-semibold text-gray-500 hover:text-white hover:bg-black hover:border-black focus:outline-none focus:ring-0 transition-all text-xs uppercase"
          >
            <OpenInWindow className="w-4 h-4" />
            Open
          </button>
        </td>
      </tr>
    );
  };

  const renderDepartment = (id) => {
    let department = "Unknown";
    if (departments.length > 0) {
      let dep = departments.find((d) => d.departmentId === id);
      if (dep) {
        department = dep.label;
      }
    }
    return department.toUpperCase();
  };

  const renderStock = (data) => {
    let stock = "No stock";
    if (data.length > 0) {
      let augusta = 0;
      let northAugusta = 0;
      let agsStock = data.find((s) => s.location === "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf");
      let naStock = data.find((s) => s.location === "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f");
      if (agsStock) {
        augusta = agsStock.quantityInStock;
      }
      if (naStock) {
        northAugusta = naStock.quantityInStock;
      }
      stock = `${augusta} in AGS, ${northAugusta} in NA`;
    }
    return stock;
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Inventory Counts Report | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full h-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Inventory Counts Report</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-col items-start justify-center gap-1">
            <p className="text-sm uppercase text-gray-500/90">Inventory Counts Report</p>
            <p className="text-sm text-gray-600/80">Generate a comprehensive report for all past inventory counts, and manage the parts with missing data.</p>
          </div>
          <button
            onClick={() => printReport()}
            className="py-1.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-gray-200 font-semibold text-gray-500 hover:text-white hover:bg-black hover:border-black focus:outline-none focus:ring-0 transition-all text-sm"
          >
            <Printer className="w-5 h-5" />
            Print Out
          </button>
        </div>
        <table className="min-w-full mt-3 divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                Part No
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                CAT / DPT / MFG
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Description
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Cost
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Charge Out
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Stock
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Photos
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3 text-right text-sm font-semibold text-gray-900">
                <span className="sr-only">View</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-100">{!loading && parts.map((part) => renderTableRow(part))}</tbody>
        </table>
      </div>
    </div>
  );
};

export default InventoryCounts;
