import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { GetOneVendor, UpdateVendor } from "../../actions/ims";
import { ErrorMessage } from "@hookform/error-message";
import { FormProvider, useForm, Controller } from "react-hook-form";
import validator from "validator";
import StateList from "../../components/stateList";
import { Combobox } from "@headlessui/react";
import { Check, ArrowSeparateVertical } from "iconoir-react";
import { Helmet } from "react-helmet-async";

const testZip = (val) => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
};

const testPhone = (val) => {
  return /^((\+1|1)?( |-)?)?(\(([2-9])(?:\d(?!\5)\d|(?!\5)\d\d)\)|([2-9])(?:\d(?!\6)\d|(?!\6)\d\d))( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(val);
};

const OpenVendor = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [vendor, setVendor] = useState({});
  let [accounts, setAccounts] = useState([]);
  const [query, setQuery] = useState("");
  const [vendorBasics, setVendorBasics] = useState({
    vendorCode: "",
    vendorName: "",
  });
  const [vendorContact, setVendorContact] = useState({
    name: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    email: "",
    website: "",
    communication: "",
  });
  const [vendorAP, setVendorAP] = useState({
    apName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    apEmail: "",
  });
  const [vendorPayment, setVendorPayment] = useState({
    apTerms: "",
    creditLimit: 0,
    defaultAPAccount: "",
  });

  const { vendorId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneVendor(vendorId)
        .then((res) => {
          setVendor(res.data.vendor);
          setAccounts(res.data.accounts);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error retrieving vendor information.");
          setTimeout(() => navigate("/vendors"), 2500);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const onSubmit = (data) => {
    setLoading(true);
    UpdateVendor(vendorId, data)
      .then((res) => {
        toast.success("Vendor information updated successfully.");
        GetOneVendor(vendorId)
          .then((res) => {
            setVendor(res.data.vendor);
            setAccounts(res.data.accounts);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Error retrieving vendor information.");
            setTimeout(() => navigate("/vendors"), 2500);
          });
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error updating vendor information.");
        setTimeout(() => navigate("/vendors"), 2500);
      });
  };

  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isDirty, dirtyFields, isValid },
    setValue,
    getValues,
    setError,
    control,
  } = formMethods;

  const filteredAccounts =
    query === ""
      ? accounts
      : accounts.filter((acc) => {
          return acc.description.toLowerCase().includes(query.toLowerCase()) || acc.accountNo.toLowerCase().includes(query.toLowerCase());
        });

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const saveChanges = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>
              Vendor - {vendor.vendorCode}
              {vendor.vendorName && vendor.vendorName.length > 0 ? ` - ${vendor.vendorName}` : ""} | HTPS ERP
            </title>
          </Helmet>
          <div className="flex flex-col items-start justify-start w-full h-full px-1 mt-1 overflow-y-auto">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-3 border-b border-gray-300">
              <p className="text-sm font-bold uppercase">
                <span className="text-lg font-semibold">{vendor.vendorCode} | </span>
                {vendor.vendorName}
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={`w-full flex flex-col justify-start items-start gap-2`} key="upperForm">
              <FormProvider {...formMethods}>
                <h3 className="mb-1 text-lg font-semibold uppercase">Vendor Information</h3>
                <div className="flex flex-row items-center justify-between w-full gap-4 mb-4">
                  <div key="vendorCode" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="vendorCode" className="pb-1 text-xs text-gray-600 uppercase">
                      Vendor Code
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="text"
                      id="vendorCode"
                      defaultValue={vendor.vendorCode}
                      placeholder="Vendor Code"
                      {...register("vendorCode", {
                        required: "Vendor code is required",
                        validate: (value) => value.length >= 2 || "Vendor code is required and must be at least 2 characters long",
                      })}
                    />
                    <ErrorMessage errors={errors} name="talkedTo" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="vendorName" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="vendorName" className="pb-1 text-xs text-gray-600 uppercase">
                      Vendor Name
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="text"
                      id="vendorName"
                      defaultValue={vendor.vendorName}
                      placeholder="Vendor Name"
                      {...register("vendorName", {
                        required: "Vendor name is required",
                        validate: (value) => value.length >= 2 || "Vendor name is required and must be at least 2 characters long",
                      })}
                    />
                    <ErrorMessage errors={errors} name="vendorName" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
                <h3 className="w-full pt-3 mb-1 text-lg font-semibold uppercase border-t border-gray-300">Contact Information</h3>
                <div key="contact.name" className="flex flex-col items-start justify-start w-full">
                  <label htmlFor="contact.name" className="pb-1 text-xs text-gray-600 uppercase">
                    Contact Person's Name
                  </label>
                  <input
                    className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                    type="text"
                    id="contact.name"
                    defaultValue={vendor.contact.name}
                    placeholder="Contact Person's Name"
                    {...register("contact.name", {
                      required: false,
                      validate: (value) => value === "" || value.length >= 2 || "Contact's name is required and must be at least 2 characters long",
                    })}
                  />
                  <ErrorMessage errors={errors} name="contact.name" as="p" className="px-1 pt-1 text-xs text-red-500" />
                </div>
                <div className="flex flex-row items-center justify-between w-full gap-4 mb-4">
                  <div key="contact.address" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="contact.address" className="pb-1 text-xs text-gray-600 uppercase">
                      Address
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="text"
                      id="contact.address"
                      defaultValue={vendor.contact.address}
                      placeholder="Address"
                      {...register("contact.address", {
                        required: false,
                        validate: (value) => value === "" || value.length >= 3 || "Address must be at least 2 characters long",
                      })}
                    />
                    <ErrorMessage errors={errors} name="contact.address" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="contact.address2" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="contact.address2" className="pb-1 text-xs text-gray-600 uppercase">
                      Apt/Suite/Unit
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="text"
                      id="contact.address2"
                      defaultValue={vendor.contact.address2}
                      placeholder="Apt/Suite/Unit"
                      {...register("contact.address2", {
                        required: false,
                      })}
                    />
                    <ErrorMessage errors={errors} name="contact.address2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between w-full gap-4 mb-4">
                  <div key="contact.city" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="contact.city" className="pb-1 text-xs text-gray-600 uppercase">
                      City
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="text"
                      id="contact.city"
                      defaultValue={vendor.contact.city}
                      placeholder="City"
                      {...register("contact.city", {
                        required: false,
                        validate: (value) =>
                          value === "" || (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "City must be at least 2 characters long",
                      })}
                    />
                    <ErrorMessage errors={errors} name="contact.city" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="contact.state" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="contact.state" className="pb-1 text-xs text-gray-600 uppercase">
                      State
                    </label>
                    <select
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      id="contact.state"
                      defaultValue={vendor.contact.state}
                      {...register("contact.state", {
                        required: false,
                        validate: (value) => value === "" || value.length === 2 || "Please select a valid state",
                      })}
                    >
                      <option hidden disabled value="">
                        Select One
                      </option>
                      {StateList.map((state) => (
                        <option key={state.code} value={state.code}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage errors={errors} name="contact.state" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="contact.zip" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="contact.zip" className="pb-1 text-xs text-gray-600 uppercase">
                      ZIP Code
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="text"
                      id="contact.zip"
                      defaultValue={vendor.contact.zip}
                      placeholder="ZIP Code"
                      {...register("contact.zip", {
                        required: false,
                        validate: (value) => value === "" || testZip(value) || "Please enter a valid ZIP code",
                      })}
                    />
                    <ErrorMessage errors={errors} name="contact.zip" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between w-full gap-4 mb-4">
                  <div key="contact.email" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="contact.email" className="pb-1 text-xs text-gray-600 uppercase">
                      Email Address
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="email"
                      id="contact.email"
                      defaultValue={vendor.contact.email}
                      placeholder="Email Address"
                      {...register("contact.email", {
                        required: false,
                        validate: (value) => value === "" || validator.isEmail(value) || "Please enter a valid email address",
                      })}
                    />
                    <ErrorMessage errors={errors} name="contact.email" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="contact.phoneNumber" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="contact.phoneNumber" className="pb-1 text-xs text-gray-600 uppercase">
                      Phone Number
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="tel"
                      id="contact.phoneNumber"
                      defaultValue={vendor.contact.phoneNumber}
                      placeholder="Phone Number"
                      {...register("contact.phoneNumber", {
                        required: false,
                        validate: (value) => value === "" || testPhone(value) || "Please enter a valid phone number",
                      })}
                    />
                    <ErrorMessage errors={errors} name="contact.phoneNumber" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between w-full gap-4 mb-4">
                  <div key="contact.website" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="contact.website" className="pb-1 text-xs text-gray-600 uppercase">
                      Website
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="email"
                      id="contact.website"
                      defaultValue={vendor.contact.website}
                      placeholder="Website"
                      {...register("contact.website", {
                        required: false,
                        validate: (value) => value === "" || validator.isURL(value) || "Please enter a valid website address",
                      })}
                    />
                    <ErrorMessage errors={errors} name="contact.website" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="contact.communication" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="contact.communication" className="pb-1 text-xs text-gray-600 uppercase">
                      Communication Preference
                    </label>
                    <select
                      className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      id="contact.communication"
                      defaultValue={vendor.contact.communication ? vendor.contact.communication : ""}
                      {...register("contact.communication", {
                        required: false,
                        validate: (value) => value === "" || value.length > 2 || "Please select payment terms for the customer",
                      })}
                    >
                      <option hidden disabled value="">
                        Select One
                      </option>
                      <option key={uuidv4()} value="phone">
                        Phone
                      </option>
                      <option key={uuidv4()} value="text">
                        Text
                      </option>
                      <option key={uuidv4()} value="email">
                        Email
                      </option>
                      <option key={uuidv4()} value="website">
                        Website
                      </option>
                      <option key={uuidv4()} value="fax">
                        Fax
                      </option>
                      <option key={uuidv4()} value="other">
                        Other
                      </option>
                    </select>
                    <ErrorMessage errors={errors} name="contact.communication" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
                <h3 className="w-full pt-3 mb-1 text-lg font-semibold uppercase border-t border-gray-300">A/P Information</h3>
                <div key="accountsPayable.apName" className="flex flex-col items-start justify-start w-full">
                  <label htmlFor="accountsPayable.apName" className="pb-1 text-xs text-gray-600 uppercase">
                    AP Contact Person's Name
                  </label>
                  <input
                    className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                    type="text"
                    id="accountsPayable.apName"
                    defaultValue={vendor.accountsPayable.apName}
                    placeholder="AP Contact Person's Name"
                    {...register("accountsPayable.apName", {
                      required: false,
                      validate: (value) => value === "" || value.length >= 2 || "Contact's name is required and must be at least 2 characters long",
                    })}
                  />
                  <ErrorMessage errors={errors} name="accountsPayable.apName" as="p" className="px-1 pt-1 text-xs text-red-500" />
                </div>
                <div className="flex flex-row items-center justify-between w-full gap-4 mb-4">
                  <div key="accountsPayable.address" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="accountsPayable.address" className="pb-1 text-xs text-gray-600 uppercase">
                      Address
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="text"
                      id="accountsPayable.address"
                      defaultValue={vendor.accountsPayable.address}
                      placeholder="Address"
                      {...register("accountsPayable.address", {
                        required: false,
                        validate: (value) => value === "" || value.length >= 3 || "Address must be at least 2 characters long",
                      })}
                    />
                    <ErrorMessage errors={errors} name="accountsPayable.address" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="accountsPayable.address2" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="accountsPayable.address2" className="pb-1 text-xs text-gray-600 uppercase">
                      Apt/Suite/Unit
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="text"
                      id="accountsPayable.address2"
                      defaultValue={vendor.accountsPayable.address2}
                      placeholder="Apt/Suite/Unit"
                      {...register("accountsPayable.address2", {
                        required: false,
                      })}
                    />
                    <ErrorMessage errors={errors} name="accountsPayable.address2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between w-full gap-4 mb-4">
                  <div key="accountsPayable.city" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="accountsPayable.city" className="pb-1 text-xs text-gray-600 uppercase">
                      City
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="text"
                      id="accountsPayable.city"
                      defaultValue={vendor.accountsPayable.city}
                      placeholder="City"
                      {...register("accountsPayable.city", {
                        required: false,
                        validate: (value) =>
                          value === "" || (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "City must be at least 2 characters long",
                      })}
                    />
                    <ErrorMessage errors={errors} name="accountsPayable.city" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="accountsPayable.state" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="accountsPayable.state" className="pb-1 text-xs text-gray-600 uppercase">
                      State
                    </label>
                    <select
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      id="accountsPayable.state"
                      defaultValue={vendor.accountsPayable.state}
                      {...register("accountsPayable.state", {
                        required: false,
                        validate: (value) => value === "" || value.length === 2 || "Please select a valid state",
                      })}
                    >
                      <option hidden disabled value="">
                        Select One
                      </option>
                      {StateList.map((state) => (
                        <option key={state.code} value={state.code}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage errors={errors} name="accountsPayable.state" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="accountsPayable.zip" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="accountsPayable.zip" className="pb-1 text-xs text-gray-600 uppercase">
                      ZIP Code
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="text"
                      id="accountsPayable.zip"
                      defaultValue={vendor.accountsPayable.zip}
                      placeholder="ZIP Code"
                      {...register("accountsPayable.zip", {
                        required: false,
                        validate: (value) => value === "" || testZip(value) || "Please enter a valid ZIP code",
                      })}
                    />
                    <ErrorMessage errors={errors} name="accountsPayable.zip" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between w-full gap-4 mb-4">
                  <div key="accountsPayable.apEmail" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="accountsPayable.apEmail" className="pb-1 text-xs text-gray-600 uppercase">
                      AP Email Address
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="email"
                      id="accountsPayable.apEmail"
                      defaultValue={vendor.accountsPayable.apEmail}
                      placeholder="AP Email Address"
                      {...register("accountsPayable.apEmail", {
                        required: false,
                        validate: (value) => value === "" || validator.isEmail(value) || "Please enter a valid email address",
                      })}
                    />
                    <ErrorMessage errors={errors} name="accountsPayable.apEmail" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="accountsPayable.phoneNumber" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="accountsPayable.phoneNumber" className="pb-1 text-xs text-gray-600 uppercase">
                      Phone Number
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="tel"
                      id="accountsPayable.phoneNumber"
                      defaultValue={vendor.accountsPayable.phoneNumber}
                      placeholder="Phone Number"
                      {...register("accountsPayable.phoneNumber", {
                        required: false,
                        validate: (value) => value === "" || testPhone(value) || "Please enter a valid phone number",
                      })}
                    />
                    <ErrorMessage errors={errors} name="accountsPayable.phoneNumber" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
                <h3 className="mb-1 text-lg font-semibold uppercase">Payment Information</h3>
                <div className="flex flex-row items-center justify-between w-full gap-4 mb-4">
                  <div className="flex flex-col items-start justify-start w-1/3">
                    <label htmlFor="paymentInfo.apTerms" className="pb-2 text-xs text-gray-600 uppercase">
                      Payment Terms
                    </label>
                    <select
                      className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      id="paymentInfo.apTerms"
                      defaultValue={vendor.paymentInfo.apTerms}
                      {...register("paymentInfo.apTerms", {
                        required: "Payment terms are required",
                        validate: (value) => value.length >= 3 || "Please select default payment terms",
                      })}
                    >
                      <option hidden disabled value="">
                        Select One
                      </option>
                      <option key="net10" value="net10">
                        NET10
                      </option>
                      <option key="net20" value="net20">
                        NET20
                      </option>
                      <option key="net30" value="net30">
                        NET30
                      </option>
                      <option key="collectOnDelivery" value="collectOnDelivery">
                        Collect on Delivery
                      </option>
                      <option key="dueUponReceipt" value="dueUponReceipt">
                        Due Upon Receipt
                      </option>
                      <option key="prePay" value="prePay">
                        Pre-Pay
                      </option>
                    </select>
                    <ErrorMessage errors={errors} name="paymentInfo.apTerms" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="paymentInfo.creditLimit" className="flex flex-col items-start justify-start w-1/3">
                    <label htmlFor="paymentInfo.creditLimit" className="pb-1 text-xs text-gray-600 uppercase">
                      Credit Limit ($)
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      type="number"
                      id="paymentInfo.creditLimit"
                      defaultValue={vendor.paymentInfo.creditLimit}
                      placeholder="Credit Limit"
                      {...register("creditLimitpaymentInfo.", {
                        required: "Credit Limit is required",
                        validate: (value) => validator.isInt(value.toString()) || "Please enter a credit limit",
                      })}
                    />
                    <ErrorMessage errors={errors} name="paymentInfo.creditLimit" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div className="flex flex-col items-start justify-start w-1/3">
                    <Controller
                      control={control}
                      name="paymentInfo.defaultAPAccount"
                      rules={{
                        required: "Default A/P Account is required",
                        validate: (value) => value.length >= 3 || "Please select default A/P Account",
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Combobox
                          as="div"
                          onChange={(e) => onChange(e.accountId)}
                          className="w-full"
                          defaultValue={accounts.find((a) => a.accountId === vendor.paymentInfo.defaultAPAccount)}
                        >
                          <Combobox.Label className="block pb-2 text-xs text-gray-600 uppercase">Default A/P Account</Combobox.Label>
                          <div className="relative w-full">
                            <Combobox.Input
                              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              onChange={(event) => setQuery(event.target.value)}
                              displayValue={(acc) => acc?.accountNo + " | " + acc?.description}
                              placeholder="Select One"
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                              <ArrowSeparateVertical className="w-5 h-5 text-gray-600" aria-hidden="true" />
                            </Combobox.Button>

                            {filteredAccounts.length > 0 && (
                              <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredAccounts.map((acc) => (
                                  <Combobox.Option
                                    key={acc.accountId}
                                    value={acc}
                                    className={({ active }) =>
                                      classNames("relative cursor-default select-none py-2 pl-3 pr-9", active ? "bg-blue-600 text-white" : "text-gray-900")
                                    }
                                  >
                                    {({ active, selected }) => (
                                      <>
                                        <span className={classNames("block truncate", selected && "font-semibold")}>
                                          {acc.accountNo} | {acc.description}
                                        </span>

                                        {selected && (
                                          <span
                                            className={classNames("absolute inset-y-0 right-0 flex items-center pr-4", active ? "text-white" : "text-blue-600")}
                                          >
                                            <Check className="w-5 h-5" aria-hidden="true" />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))}
                              </Combobox.Options>
                            )}
                          </div>
                        </Combobox>
                      )}
                    />
                    <ErrorMessage errors={errors} name="paymentInfo.defaultAPAccount" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
              </FormProvider>
            </form>
            <div className="flex flex-row items-center justify-end w-full gap-2 pt-4 mt-4 border-t border-gray-300">
              <SecondaryButton label="Go Back" callback={() => navigate("/vendors")} />
              <PrimaryButton label="Save Changes" callback={() => saveChanges()} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OpenVendor;
