import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { DynamicField } from "./DynamicField";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { Switch } from "@headlessui/react";
import { MaskedInput } from "antd-mask-input";

const FormController = ({ onSubmit, fields, buttonText, key, defaultValues, values, fullWidth, keepDefaults, row, hideSubmit, backFunction, back }) => {
  const formMethods = useForm({ values: values });
  const {
    handleSubmit,
    formState: { isSubmitting, errors, isDirty, dirtyFields },
    control,
  } = formMethods;

  function submitForm(data, error) {
    data.formModified = isDirty;
    data.modifiedFields = dirtyFields;
    onSubmit(data);
    formMethods.reset({ keepDefaults: keepDefaults ? values : null });
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  let labelHidden = ["checkbox", "divider", "divider-label", "label"];

  const renderForm = Object.keys(fields).map((i) => {
    if (fields[i].inputType === "date-picker") {
      return (
        <div key={i + `.${fields[i].fieldName}`} className="flex flex-col items-start justify-start w-full">
          <label htmlFor={fields[i].fieldName} className={`text-xs uppercase pb-2 text-gray-600 ${labelHidden.includes(fields[i].inputType) && "hidden"}`}>
            {fields[i].label}
          </label>
          <Controller
            control={formMethods.control}
            name={fields[i].fieldName}
            rules={{
              required: "Date is required",
              validate: (v) => dayjs(v).isValid() || "Please provide a date",
            }}
            defaultValue={fields[i].defaultValue ? dayjs(fields[i].defaultValue) : null}
            render={(props) => (
              <DatePicker
                placeholder={fields[i].placeholder}
                status={props.fieldState.error ? "error" : undefined}
                ref={props.field.ref}
                name={fields[i].fieldName}
                onBlur={props.field.onBlur}
                format={"MM/DD/YYYY"}
                onChange={(date) => {
                  props.field.onChange(date ? date.toJSON() : null);
                }}
                defaultValue={
                  defaultValues && defaultValues[fields[i].fieldName] && dayjs(defaultValues[fields[i].fieldName]).isValid()
                    ? dayjs(defaultValues[fields[i].fieldName])
                    : null
                }
                {...props}
                className="w-full"
              />
            )}
          />
          <ErrorMessage errors={errors} name={fields[i].fieldName} as="p" className="px-1 pt-1 text-xs text-red-500" />
        </div>
      );
    } else if (fields[i].inputType === "switch") {
      let currField = fields[i];
      return (
        <div key={currField.fieldName} className="flex items-center justify-between w-full gap-4 my-3">
          <div className="text-sm leading-6">
            <label className="text-gray-900">{currField.label}</label>
          </div>
          <Controller
            control={control}
            defaultValue={null}
            name={currField.fieldName}
            rules={{
              required: false,
            }}
            render={({ field: { value, onChange, ref } }) => (
              <Switch
                checked={value}
                onChange={(e) => onChange(e)}
                className={`${value ? "bg-green-600" : "bg-gray-200"}
                            relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">{currField.label}</span>
                <span
                  className={classNames(
                    value ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                  )}
                >
                  <span
                    className={classNames(
                      value ? "opacity-0 duration-100 ease-out" : "opacity-100 duration-200 ease-in",
                      "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",
                    )}
                    aria-hidden="true"
                  >
                    <svg className="w-3 h-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                  <span
                    className={classNames(
                      value ? "opacity-100 duration-200 ease-in" : "opacity-0 duration-100 ease-out",
                      "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",
                    )}
                    aria-hidden="true"
                  >
                    <svg className="w-3 h-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
            )}
          />
          <ErrorMessage errors={errors} name={currField.fieldName} as="p" className="px-1 pt-1 text-xs text-red-500" />
        </div>
      );
    } else if (fields[i].inputType === "phoneNumber") {
      let currField = fields[i];
      return (
        <div key={`${currField.fieldName}`} className="flex flex-col items-start justify-start w-full">
          <div className="pb-1 text-xs leading-6 uppercase">
            <label className="text-gray-600">{currField.label}</label>
          </div>
          <Controller
            control={control}
            defaultValue={null}
            name={currField.fieldName}
            rules={currField.config}
            render={({ field: { value, onChange, ref } }) => (
              <MaskedInput
                id={currField.fieldName}
                defaultValue={values && values[currField.fieldName] ? values[currField.fieldName] : null}
                placeholder={currField.placeholder}
                mask={"(000) 000-0000"}
                className="py-1 text-gray-900 border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 phoneInput"
                onChange={(e) => onChange(e.unmaskedValue)}
              />
            )}
          />
          <ErrorMessage errors={errors} name={currField.fieldName} as="p" className="px-1 pt-1 text-xs text-red-500" />
        </div>
      );
    } else {
      let field = fields[i];
      return (
        <div key={i + `.${field.fieldName}`} className="flex flex-col items-start justify-start w-full">
          <label htmlFor={field.fieldName} className={`text-xs uppercase pb-2 text-gray-600 ${labelHidden.includes(field.inputType) && "hidden"}`}>
            {field.label}
          </label>
          <DynamicField {...field} value={values[i]} control={control} />
          <ErrorMessage errors={errors} name={field.fieldName} as="p" className="px-1 pt-1 text-xs text-red-500" />
        </div>
      );
    }
  });

  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className={`w-full ${!fullWidth ? "max-w-lg" : null} flex ${row ? "flex-row justify-between items-center" : "flex-col justify-start items-start"} gap-2`}
      key={key ?? "form"}
    >
      <FormProvider {...formMethods}>{renderForm}</FormProvider>
      {!hideSubmit && (
        <div className={`w-full flex flex-row ${!back ? "justify-end" : "justify-between"} items-center mt-3 mb-1`}>
          {back && (
            <button
              onClick={() => backFunction()}
              className="inline-flex items-center gap-x-1.5 rounded-md text-gray-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm hover:bg-gray-50 ring-1 rind-inset ring-gray-300 hover:ring-gray-900 transition-all duration-150 disabled:hover:cursor-not-allowed disabled:text-gray-300"
            >
              {isSubmitting ? "Processing..." : "Back"}
            </button>
          )}
          <button
            type="submit"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-5 py-2 text-xs font-semibold uppercase text-white shadow-sm hover:bg-blue-500"
          >
            {isSubmitting ? "Processing..." : buttonText}
          </button>
        </div>
      )}
    </form>
  );
};

export default FormController;
