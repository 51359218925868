import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { GetOneCustomer, UpdateCustomerDefaults } from "../../actions/customers";
import FormController from "../../components/FormController";
import NoteField from "../../components/NoteField";
import { customerDefaults } from "../../data/customerForms";
import toast from "react-hot-toast";
import { GetEmployees } from "../../actions/auth";
import { Helmet } from "react-helmet-async";

const CustomerDefaults = ({ authState, authDispatch }) => {
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noteField, setNoteField] = useState(false);
  const [employees, setEmployees] = useState([]);

  let { id } = useParams();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneCustomer(id)
        .then((res) => {
          setCustomer(res.data);
          GetEmployees()
            .then((res) => {
              setEmployees(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              console.log(err);
              setTimeout(() => setLoading(false), 700);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      inView = false;
    };
  }, [id]);

  const reloadData = () => {
    setLoading(true);
    GetOneCustomer(id)
      .then((res) => {
        setCustomer(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please refresh the page and try again");
        setTimeout(() => setLoading(false), 700);
      });
  };

  const tabs = [
    { name: "Overview", href: `/customers/${id}`, current: false },
    { name: "Contact Info", href: `/customers/${id}/contact`, current: false },
    { name: "A/R Info", href: `/customers/${id}/ar`, current: false },
    { name: "Defaults", href: `/customers/${id}/defaults`, current: true },
    { name: "Equipment", href: `/customers/${id}/equipment`, current: false },
    { name: "Quotes", href: `/customers/${id}/quotes`, current: false },
    { name: "Jobs", href: `/customers/${id}/jobs`, current: false },
    { name: "Invoices", href: `/customers/${id}/invoices`, current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const CustomerNavigation = () => {
    return (
      <div className="flex flex-row items-center justify-between w-full gap-3">
        <div className="w-full sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden w-full sm:block">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current ? "border-blue-500 text-blue-600" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium",
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    );
  };

  const renderNote = (note) => {
    return (
      <div className="flex flex-col items-start justify-center w-full px-3 py-2 bg-white rounded-lg">
        <h3 className="w-full text-sm font-base text-slate-800">{note.note}</h3>
        <div className="flex flex-row items-center justify-between w-full mt-1">
          <p className="text-xs text-slate-400/75 font-medium pt-0.5">{employees.find((emp) => emp.employeeId === note.createdBy).name}</p>
          <p className="text-xs text-slate-400/75 font-medium pt-0.5">{dayjs(note.createdAt).format("MM/DD/YYYY")}</p>
        </div>
      </div>
    );
  };

  const updateDefaults = (data) => {
    if (!data.formModified) {
      toast.error("No fields were modified. There's nothing to save.");
    } else {
      setLoading(true);
      let toSend = {};
      for (let key in data.modifiedFields) {
        if (key === "commission") {
          if (!toSend.commission) {
            toSend.commission = {};
          }
          if (data.modifiedFields.commission.percentage) {
            toSend.commission.percentage = data.commission.percentage;
          }
          if (data.modifiedFields.commission.employee) {
            toSend.commission.employee = data.commission.employee;
          }
        } else {
          toSend[key] = data[key];
        }
      }
      UpdateCustomerDefaults(toSend, id)
        .then((res) => {
          toast.success("Customer defaults updated successfully");
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please try again");
          reloadData();
        });
    }
  };

  return (
    <div className="grid w-full h-full grid-cols-12 px-4 mb-4 overflow-y-hidden gap-x-2">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full col-span-9">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="flex flex-col items-start justify-start w-full col-span-9 py-2 overflow-y-auto">
          <Helmet>
            <title>
              Customer Defaults - {customer.customerCode}
              {customer.company.length > 0 ? ` - ${customer.company}` : ""} | HTPS ERP
            </title>
          </Helmet>
          <CustomerNavigation />
          <div className="mt-3 flex flex-row justify-between items-center w-full px-3 pb-3.5 border-b border-gray-300">
            <h1 className="text-[28px] leading-[42px]">Customer Defaults</h1>
            <div className="flex flex-row items-center justify-end gap-1">
              <p className="text-xs font-bold uppercase">Company:</p>
              <p className="font-light text-lg border-r border-slate-400 pr-2 mr-1.5">{customer.company}</p>
              <p className="text-xs font-bold uppercase">Customer Code:</p>
              <p className="text-lg font-light">{customer.customerCode}</p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-5 pt-1 mt-3">
            <FormController
              onSubmit={updateDefaults}
              fields={customerDefaults}
              values={customer.defaults}
              buttonText={"Save Changes"}
              fullWidth={true}
              hideSubmit={authState.user.functionCategory === "technician"}
            />
          </div>
        </div>
      )}
      <div className="flex flex-col items-start justify-start w-full col-span-3 gap-2 px-5 py-1 pb-4 border rounded-lg shadow-md bg-slate-300/40 border-slate-300/60">
        <div className="flex flex-row items-center justify-between w-full pb-2 mb-2 border-b border-gray-300">
          <h1 className="w-full px-2 text-2xl font-light leading-10 text-center">Notes</h1>
        </div>
        <div className="flex flex-col items-center justify-start w-full h-full gap-3">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : customer.notes.length > 0 ? (
            customer.notes.map((note) => renderNote(note))
          ) : (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">No notes found</p>
          )}
        </div>
        <div className="flex flex-col items-center justify-center w-full mt-2">
          {noteField ? (
            <NoteField
              closeField={(hasNew) => {
                setNoteField(false);
                if (hasNew) {
                  reloadData();
                }
              }}
              id={id}
            />
          ) : (
            <button
              onClick={() => setNoteField(true)}
              className="px-5 py-3 text-xs font-bold uppercase duration-150 rounded-md bg-slate-300 text-slate-800 hover:bg-gray-500/50"
            >
              Add a new note
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerDefaults;
