import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { DeletePart, GetOnePart, UpdatePart, UpdatePartStock } from "../../actions/ims";
import { toast } from "react-hot-toast";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import validator from "validator";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { Image, InputNumber, Modal, Select } from "antd";
import { GenerateInventoryAdjustmentPrintout } from "../../data/pdf";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";
import { WarningTriangle } from "iconoir-react";

const PartID = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [part, setPart] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [locations, setLocations] = useState([]);
  const [photosModal, setPhotosModal] = useState(false);

  const navigate = useNavigate();
  const { partId } = useParams();
  const [stockAdjustment, setStockAdjustment] = useState(false);
  const [stockData, setStockData] = useState({
    location: "",
    quantity: 0,
  });

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOnePart(partId)
        .then((res) => {
          setPart(res.data.part);
          setDepartments(res.data.departments);
          setVendors(res.data.vendors);
          setLocations(res.data.locations);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error retrieving part data");
          setTimeout(() => navigate("/parts"), 2500);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const onSubmit = (data) => {
    //
  };

  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isDirty, dirtyFields, isValid },
    getValues,
    control,
  } = formMethods;

  const saveChanges = () => {
    let data = getValues();
    setLoading(true);
    UpdatePart(partId, data)
      .then((res) => {
        toast.success("Part data updated successfully");
        GetOnePart(partId)
          .then((res) => {
            setPart(res.data.part);
            setDepartments(res.data.departments);
            setVendors(res.data.vendors);
            setLocations(res.data.locations);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Error retrieving part data");
            setTimeout(() => navigate("/parts"), 2500);
          });
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error updating part data");
        setLoading(false);
      });
  };

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const closeStockAdjustment = () => {
    setStockAdjustment(false);
    setStockData({ location: "", quantity: 0 });
  };

  const submitStockAdjustment = () => {
    let current = part.stock;
    if (!current) current = [];
    let index = current.findIndex((s) => s.location === stockData.location);
    if (index !== -1) {
      let tmp = {
        quantityInStock: current[index].quantityInStock + stockData.quantity,
        location: stockData.location,
      };
      current.splice(index, 1);
      current.push(tmp);
      // closeStockAdjustment();
    } else {
      let tmp = {
        quantityInStock: stockData.quantity,
        location: stockData.location,
      };
      current.push(tmp);
    }
    let toSend = {
      partId: part.partId,
      stock: current,
    };
    setLoading(true);
    UpdatePartStock(partId, toSend)
      .then((res) => {
        toast.success("Part stock adjusted successfully");
        setPart(res.data.part);
        closeStockAdjustment();
        let doc = GenerateInventoryAdjustmentPrintout(res.data.adjustmentData);
        doc.setProperties({
          title: `Inventory Adjustment - ${part.partNo} - ${dayjs().format("MM/DD/YYYY")}}`,
          subject: `Inventory Adjustment - ${part.partNo} - ${dayjs().format("MM/DD/YYYY")}`,
          author: "Hypertek Solutions LLC",
          keywords: "",
          creator: "contact@hypertek.dev",
        });
        window.open(doc.output("bloburl"), "_blank");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error updating part stock");
        setLoading(false);
      });
    // setPart({ ...part, stock: current });
    // closeStockAdjustment();
  };

  const generateAdjustmentPrintout = () => {
    let toSend = part;
    part.adjustedBy = authState.user.firstName + " " + authState.user.lastName;
  };

  const deletePart = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this part?",
      icon: <WarningTriangle className="mr-2 text-red-600" />,
      content: "This action cannot be undone",
      centered: true,
      onOk() {
        confirmPartDelete();
      },
      onCancel() {},
    });
  };

  const confirmPartDelete = () => {
    setLoading(true);
    DeletePart(partId)
      .then((res) => {
        toast.success("Part deleted successfully");
        navigate("/parts");
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error deleting part");
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Part - {part.partNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-col items-start justify-start w-full h-full px-1 mt-1 overflow-y-auto">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-3 border-b border-gray-300">
              <p className="text-lg font-bold uppercase">
                <span className="pr-2 text-sm font-medium">PART:</span>
                {part.partNo}
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={`w-full flex flex-col justify-start items-start gap-2`} key="upperForm">
              <FormProvider {...formMethods}>
                <h3 className="mb-1 text-lg font-semibold uppercase">Part Information</h3>
                <div className="flex flex-col items-center justify-between w-full gap-2 md:flex-row">
                  <div key="department" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="department" className="pb-1 text-xs text-gray-600 uppercase">
                      Department
                    </label>
                    <Controller
                      control={control}
                      name={"department"}
                      rules={{ required: false }}
                      defaultValue={part.department}
                      render={(props) => (
                        <Select
                          placeholder={"Select a department"}
                          ref={props.field.ref}
                          name={"department"}
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={departments.map((dept) => ({
                            value: dept.departmentId,
                            label: dept.name,
                          }))}
                          defaultValue={part.department}
                          {...props}
                          className="w-full mt-1 font-sans"
                          controls={false}
                          showSearch
                          filterOption={filterOption}
                          disabled={authState.user.functionCategory === "technician"}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="department" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="category" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="category" className="pb-1 text-xs text-gray-600 uppercase">
                      Category
                    </label>
                    <Controller
                      control={control}
                      name={"category"}
                      rules={{ required: false }}
                      defaultValue={part.category}
                      render={(props) => (
                        <Select
                          placeholder={"Select a category"}
                          ref={props.field.ref}
                          name={"category"}
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={[
                            { label: "Parts", value: "parts" },
                            { label: "Gas, Oil & Grease", value: "gog" },
                            { label: "Fluids", value: "fluids" },
                          ]}
                          defaultValue={part.category}
                          {...props}
                          className="w-full mt-1 font-sans"
                          controls={false}
                          showSearch
                          filterOption={filterOption}
                          disabled={authState.user.functionCategory === "technician"}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="category" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-between w-full gap-2 md:flex-row">
                  <div key="partNo" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
                      Part No
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                      type="text"
                      id="partNo"
                      defaultValue={part.partNo}
                      placeholder="Part Number"
                      disabled={authState.user.functionCategory === "technician"}
                      {...register("partNo", {
                        required: false,
                        validate: (value) => value && (value === "" || value.length >= 2 || "Part Number is required and must be at least 2 characters long"),
                      })}
                    />
                    <ErrorMessage errors={errors} name="partNo" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="description" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                      Part Description
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                      type="text"
                      id="description"
                      defaultValue={part.description}
                      placeholder="Part Description"
                      disabled={authState.user.functionCategory === "technician"}
                      {...register("description", {
                        required: false,
                        validate: (value) => value === "" || value.length >= 2 || "Part Description is required and must be at least 2 characters long",
                      })}
                    />
                    <ErrorMessage errors={errors} name="description" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-between w-full gap-2 md:flex-row">
                  <div key="unit" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="unit" className="pb-1 text-xs text-gray-600 uppercase">
                      Unit
                    </label>
                    <Controller
                      control={control}
                      name={"unit"}
                      rules={{ required: false }}
                      defaultValue={part.unit}
                      render={(props) => (
                        <Select
                          placeholder={"Select a unit"}
                          ref={props.field.ref}
                          name={"unit"}
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          disabled={authState.user.functionCategory === "technician"}
                          options={[
                            { label: "Each", value: "each" },
                            { label: "Quart", value: "quart" },
                            { label: "Gallon", value: "gallon" },
                            { label: "Kit", value: "kit" },
                            { label: "Pound", value: "pound" },
                            { label: "Pair", value: "pair" },
                            { label: "Set", value: "set" },
                            { label: "Tube", value: "tube" },
                            { label: "Can", value: "can" },
                            { label: "Foot", value: "foot" },
                            { label: "Meter", value: "meter" },
                          ]}
                          defaultValue={part.unit}
                          {...props}
                          className="w-full font-sans"
                          controls={false}
                          showSearch
                          filterOption={filterOption}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="unit" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="manufacturer" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="manufacturer" className="pb-1 text-xs text-gray-600 uppercase">
                      Part Manufacturer
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                      type="text"
                      id="manufacturer"
                      defaultValue={part.manufacturer}
                      disabled={authState.user.functionCategory === "technician"}
                      placeholder="Part Manufacturer"
                      {...register("manufacturer", {
                        required: false,
                        validate: (value) => value === "" || value.length >= 2 || "Part Manufacturer is required and must be at least 2 characters long",
                      })}
                    />
                    <ErrorMessage errors={errors} name="manufacturer" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
                <div key="defaultVendor" className="flex flex-col items-start justify-start w-full">
                  <label htmlFor="defaultVendor" className="pb-1 text-xs text-gray-600 uppercase">
                    Default Vendor
                  </label>
                  <Controller
                    control={control}
                    name={"defaultVendor"}
                    rules={{ required: false }}
                    defaultValue={part.defaultVendor}
                    render={(props) => (
                      <Select
                        placeholder={"Select a Default Vendor"}
                        ref={props.field.ref}
                        name={"defaultVendor"}
                        onBlur={props.field.onBlur}
                        disabled={authState.user.functionCategory === "technician"}
                        onChange={props.field.onChange}
                        options={vendors.map((vndr) => ({
                          value: vndr.vendorId,
                          label: `${vndr.vendorCode}${vndr.vendorName.length > 0 ? " | " + vndr.vendorName : ""}`,
                        }))}
                        defaultValue={part.defaultVendor}
                        {...props}
                        className="w-full font-sans"
                        controls={false}
                        showSearch
                        filterOption={filterOption}
                      />
                    )}
                  />
                  <ErrorMessage errors={errors} name="defaultVendor" as="p" className="px-1 pt-1 text-xs text-red-500" />
                </div>
                <div className="flex flex-row items-center justify-between w-full gap-2">
                  {authState.user.functionCategory !== "technician" && (
                    <div key="cost" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="cost" className="pb-1 text-xs text-gray-600 uppercase">
                        Cost ($)
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                        type="number"
                        id="cost"
                        defaultValue={part.cost}
                        placeholder="Cost"
                        {...register("cost", {
                          required: false,
                          validate: (value) => value === "" || value.length >= 1 || "Cost is required",
                        })}
                      />
                      <ErrorMessage errors={errors} name="cost" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                  )}
                  {authState.user.functionCategory !== "technician" && (
                    <div key="markup" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="markup" className="pb-1 text-xs text-gray-600 uppercase">
                        Markup (%)
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                        type="number"
                        id="markup"
                        defaultValue={part.markup}
                        placeholder="Markup"
                        {...register("markup", {
                          required: false,
                          validate: (value) => value === "" || value.length >= 1 || "Markup is required",
                        })}
                      />
                      <ErrorMessage errors={errors} name="markup" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                  )}
                  <div key="chargeOut" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="chargeOut" className="pb-1 text-xs text-gray-600 uppercase">
                      Charge Out ($)
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                      type="number"
                      id="chargeOut"
                      defaultValue={part.chargeOut}
                      disabled={authState.user.functionCategory === "technician"}
                      placeholder="Charge Out"
                      {...register("chargeOut", {
                        required: false,
                        validate: (value) => value === "" || value.length >= 1 || "Charge Out is required",
                      })}
                    />
                    <ErrorMessage errors={errors} name="chargeOut" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
              </FormProvider>
            </form>
            <div className="flex flex-row items-center justify-between w-full py-3 mt-3 border-gray-300 border-y">
              <h3 className="text-lg font-semibold uppercase">Stock</h3>
              {authState.user.functionCategory !== "technician" && <SecondaryButton label="Adjust" callback={() => setStockAdjustment(true)} />}
            </div>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Location
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Quantity in Stock
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {part.stock.map((pt) => (
                  <tr key={uuidv4()}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                      {locations.find((l) => l.locationId === pt.location)?.locationName || "Unknown Location " + pt.location}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pt.quantityInStock}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {authState.user.functionCategory !== "technician" && (
              <div className="flex flex-row items-center justify-end w-full gap-2 pt-3 mt-4 border-t border-gray-300">
                <SecondaryButton label="Delete Part" callback={() => deletePart()} />
                {part.photos && part.photos.length > 0 && <SecondaryButton label="Photos" callback={() => setPhotosModal(true)} />}
                <PrimaryButton label="Save Changes" callback={() => saveChanges()} />
              </div>
            )}
          </div>
          <Modal
            open={stockAdjustment}
            onClose={() => closeStockAdjustment()}
            onCancel={() => closeStockAdjustment()}
            onOk={() => submitStockAdjustment()}
            centered
            width={680}
            destroyOnClose
            title="Manual Stock Adjustment"
          >
            <div className="flex flex-col items-start justify-start w-full gap-2 my-5">
              <div className="flex flex-col items-start justify-start w-full">
                <label className="block mb-1.5 pl-1 text-xs font-medium text-gray-700 uppercase">Location</label>
                <Select
                  placeholder="Location"
                  showSearch
                  filterOption={filterOption}
                  options={[
                    {
                      label: "Augusta, GA",
                      value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf",
                    },
                    {
                      label: "North Augusta, SC",
                      value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
                    },
                  ]}
                  className="w-full"
                  onSelect={(v) => setStockData({ ...stockData, location: v })}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <label className="block mb-1.5 pl-1 text-xs font-medium text-gray-700 uppercase">
                  Quantity Adjustment (+/- amount to increase or decrease the quantity by)
                </label>
                <InputNumber
                  className="block w-full py-1 text-sm text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                  placeholder="Quantity Adjustment"
                  controls={false}
                  onChange={(v) => setStockData({ ...stockData, quantity: v })}
                />
                <label className="block pl-1 mt-0.5 text-xs font-normal text-gray-400">
                  i.e. -3 to decrease the quantity by 3 or "4" to add 4 to the current quantity.
                </label>
              </div>
            </div>
          </Modal>
          <Modal
            open={photosModal}
            onClose={() => setPhotosModal(false)}
            onCancel={() => setPhotosModal(false)}
            centered
            width={680}
            destroyOnClose
            title="Manual Stock Adjustment"
            footer={[<SecondaryButton label="Close" callback={() => setPhotosModal(false)} />]}
          >
            <div className="flex items-center justify-center w-full h-full max-h-[960px] overflow-y-auto py-2">
              <Image.PreviewGroup
                preview={{
                  onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
              >
                {part.photos.map((photo) => (
                  <Image width={340} src={photo.url} />
                ))}
              </Image.PreviewGroup>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default PartID;
