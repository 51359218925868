import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import * as _ from "lodash";
import { CreateAPO, GetNewPOData } from "../../actions/ims";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { DatePicker, Select } from "antd";
import validator from "validator";
import { Helmet } from "react-helmet-async";

const CreateANewPO = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [po, setPo] = useState({
    vendorId: "",
    poAccount: "",
    partsFor: "",
    jobReference: "",
    shipping: 0,
    dateSent: "",
    expectedDeliveryDate: "",
    dateReceived: "",
    talkedTo: "",
    locationId: "",
  });
  let [locations, setLocations] = useState([]);
  let [vendors, setVendors] = useState([]);
  let [parts, setParts] = useState([]);
  let [jobs, setJobs] = useState([]);
  let [accounts, setAccounts] = useState([]);

  const navigate = useNavigate();
  let [search, setSearch] = useSearchParams();

  useEffect(() => {
    let inView = true;
    if (inView) {
      let jobId = search.get("jobId");
      let locationId = search.get("locationId");
      let tmp = po;
      if (jobId) {
        tmp.jobReference = jobId;
        tmp.partsFor = "job";
        setValue("partsFor", "job");
        setValue("jobReference", jobId);
      }
      if (locationId) {
        tmp.locationId = locationId;
        setValue("locationId", locationId);
      }
      setPo(tmp);
      GetNewPOData()
        .then((res) => {
          setJobs(res.data.jobs);
          setVendors(res.data.vendors);
          setAccounts(res.data.accounts);
          setLocations(res.data.locations);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading data");
          setTimeout(() => {
            navigate("/po");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isDirty, dirtyFields, isValid },
    setValue,
    trigger,
    getValues,
    setError,
    control,
  } = formMethods;

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const saveChanges = () => {
    let tmp = {
      items: [],
    };
    if (isDirty) {
      let dirtyOnes = dirtyFields;
      for (let property in dirtyOnes) {
        if (property === "talkedTo") {
          let val = getValues(property);
          if (val.length < 3) {
            setError(property, {
              type: "custom",
              message: "Who did you talk to / How did you contact the vendor?",
            });
          } else {
            tmp[property] = val;
          }
        } else {
          tmp[property] = getValues(property);
        }
      }
    }
    trigger();
    if (!isDirty) {
      toast.error("Please fill out the form before submitting");
    } else if (!isValid) {
      toast.error("Please fill out the form correctly before submitting");
    } else {
      let data = getValues();
      setLoading(true);
      CreateAPO(data)
        .then((res) => {
          toast.success("Purchase Order created successfully! You will be redirected shortly!");
          navigate(`/po/${res.data.poId}`);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error creating Purchase Order");
          setLoading(false);
        });
    }
  };

  const onSubmit = (data) => {
    saveChanges();
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>New Purchase Order | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-col items-center justify-start w-full h-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="font-semibold uppercase">Create a new Purchase Order</p>
              <SecondaryButton label="Cancel" callback={() => navigate(-1)} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={`w-full flex flex-row justify-between items-center gap-5`} key="upperForm">
              <FormProvider {...formMethods}>
                <div className="grid w-full grid-cols-2 grid-rows-5 gap-4 py-3 border-b border-gray-300">
                  <div key="vendorId" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="vendorId" className="pb-1 text-xs text-gray-600 uppercase">
                      Vendor
                    </label>
                    <Controller
                      control={control}
                      name="vendorId"
                      rules={{
                        required: "Vendor is required",
                        validate: (value) => {
                          return value.length >= 3 || "Please select a vendor";
                        },
                      }}
                      render={(props) => (
                        <Select
                          placeholder="Select a vendor"
                          ref={props.field.ref}
                          name={"vendorId"}
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={vendors.map((vendor) => ({
                            label: `${vendor.vendorCode} | ${vendor.vendorName}`,
                            value: vendor.vendorId,
                          }))}
                          className="w-full font-sans"
                          controls={false}
                          defaultValue={po.vendorId && po.vendorId.length > 10 ? po.vendorId : null}
                          showSearch
                          filterOption={filterOption}
                          {...props.field}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="vendorId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="poAccount" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="poAccount" className="pb-1 text-xs text-gray-600 uppercase">
                      Purchase Order Account
                    </label>
                    <Controller
                      control={control}
                      name="poAccount"
                      rules={{
                        required: "PO Account  is required",
                        validate: (value) => {
                          return value.length >= 3 || "Please select a PO account";
                        },
                      }}
                      render={(props) => (
                        <Select
                          placeholder="Select an account"
                          ref={props.field.ref}
                          name={"poAccount"}
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={accounts.map((account) => ({
                            label: `${account.accountNo} | ${account.description}`,
                            value: account.accountId,
                          }))}
                          className="w-full font-sans"
                          controls={false}
                          defaultValue={po.poAccount && po.poAccount.length > 10 ? po.poAccount : null}
                          showSearch
                          filterOption={filterOption}
                          {...props.field}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="poAccount" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="partsFor" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="partsFor" className="pb-1 text-xs text-gray-600 uppercase">
                      Parts For{" "}
                    </label>
                    <Controller
                      control={control}
                      name="partsFor"
                      rules={{
                        required: "Please select what the parts are for",
                        validate: (value) => value.length >= 3 || "Please select a use case for the PO",
                      }}
                      render={(props) => (
                        <Select
                          placeholder="Select the use case for the parts"
                          ref={props.field.ref}
                          name={"partsFor"}
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={[
                            { label: "Repairs & Maintenance", value: "rnm" },
                            { label: "Inventory", value: "inventory" },
                            { label: "Job", value: "job" },
                            { label: "General Use", value: "general" },
                          ]}
                          className="w-full font-sans"
                          defaultValue={po.partsFor && po.partsFor.length > 0 ? po.partsFor : null}
                          controls={false}
                          showSearch
                          filterOption={filterOption}
                          {...props.field}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="partsFor" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="jobReference" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="jobReference" className="pb-1 text-xs text-gray-600 uppercase">
                      Job Reference{" "}
                    </label>
                    <Controller
                      control={control}
                      name="jobReference"
                      rules={{ required: false }}
                      render={(props) => (
                        <Select
                          placeholder="Select a job"
                          ref={props.field.ref}
                          name={"jobReference"}
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={jobs.map((job) => ({
                            label: job.jobNo,
                            value: job.jobId,
                          }))}
                          className="w-full font-sans"
                          controls={false}
                          defaultValue={po.jobReference && po.jobReference.length > 10 ? po.jobReference : null}
                          showSearch
                          filterOption={filterOption}
                          {...props.field}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="jobReference" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="shipping" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="shipping" className="pb-1 text-xs text-gray-600 uppercase">
                      Shipping Cost
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="number"
                      id="shipping"
                      defaultValue={po.shipping || 0}
                      placeholder="Shipping Cost"
                      {...register("shipping", {
                        required: "Shipping cost is required",
                        validate: (value) => validator.isFloat(value.toString()) || "Please enter a shipping cost",
                      })}
                    />
                    <ErrorMessage errors={errors} name="shipping" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="dateSent" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="dateSent" className="pb-1 text-xs text-gray-600 uppercase">
                      Date Ordered
                    </label>
                    <Controller
                      control={formMethods.control}
                      name="dateSent"
                      rules={{
                        required: false,
                        validate: (v) => dayjs(v).isValid() || "Date when PO was ordered is required",
                      }}
                      defaultValue={po.dateSent ? dayjs(po.dateSent) : null}
                      render={({ field, fieldState }) => (
                        <DatePicker
                          placeholder="Date when PO was ordered from the vendor"
                          status={fieldState.error ? "error" : undefined}
                          ref={field.ref}
                          format={"MM/DD/YYYY"}
                          name={field.name}
                          onBlur={field.onBlur}
                          value={field.value ? dayjs(field.value) : null}
                          onChange={(date) => {
                            field.onChange(date ? date.toJSON() : null);
                          }}
                          className="w-full"
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="dateSent" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="expectedDeliveryDate" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="expectedDeliveryDate" className="pb-1 text-xs text-gray-600 uppercase">
                      Expected Delivery Date
                    </label>
                    <Controller
                      control={formMethods.control}
                      name="expectedDeliveryDate"
                      rules={{
                        required: false,
                      }}
                      defaultValue={po.expectedDeliveryDate ? dayjs(po.expectedDeliveryDate) : null}
                      render={({ field, fieldState }) => (
                        <DatePicker
                          placeholder="Expected delivery date"
                          status={fieldState.error ? "error" : undefined}
                          ref={field.ref}
                          format={"MM/DD/YYYY"}
                          name={field.name}
                          onBlur={field.onBlur}
                          value={field.value ? dayjs(field.value) : null}
                          onChange={(date) => {
                            field.onChange(date ? date.toJSON() : null);
                          }}
                          className="w-full"
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="expectedDeliveryDate" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="dateReceived" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="dateReceived" className="pb-1 text-xs text-gray-600 uppercase">
                      Date PO Was Received
                    </label>
                    <Controller
                      control={formMethods.control}
                      name="dateReceived"
                      rules={{
                        required: false,
                        validate: (v) => dayjs(v).isValid() || "Date PO was received is required",
                      }}
                      value={po.dateReceived ? dayjs(po.dateReceived) : null}
                      render={({ field, fieldState }) => (
                        <DatePicker
                          placeholder="Date PO was received"
                          status={fieldState.error ? "error" : undefined}
                          ref={field.ref}
                          name={field.name}
                          format={"MM/DD/YYYY"}
                          onBlur={field.onBlur}
                          value={field.value ? dayjs(field.value) : null}
                          onChange={(date) => {
                            field.onChange(date ? date.toJSON() : null);
                          }}
                          className="w-full"
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="dateReceived" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="locationId" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="locationId" className="pb-1 text-xs text-gray-600 uppercase">
                      Location
                    </label>
                    <Controller
                      control={control}
                      name="locationId"
                      defaultValue={authState.user.locationId}
                      rules={{
                        required: "Location is required",
                        validate: (value) => {
                          return value.length >= 3 || "Please select a location";
                        },
                      }}
                      render={(props) => (
                        <Select
                          placeholder="Select a location"
                          ref={props.field.ref}
                          name={"locationId"}
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={[
                            {
                              value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf",
                              label: "Augusta, GA",
                            },
                            {
                              value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
                              label: "North Augusta, SC",
                            },
                          ]}
                          className="w-full font-sans"
                          controls={false}
                          defaultValue={authState.user.locationId}
                          showSearch
                          filterOption={filterOption}
                          {...props.field}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="locationId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="talkedTo" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="talkedTo" className="pb-1 text-xs text-gray-600 uppercase">
                      Talked To / Vendor Point-of-Contact
                    </label>
                    <input
                      className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                      type="text"
                      id="talkedTo"
                      defaultValue={po.talkedTo}
                      placeholder="Talked To / Vendor Point-of-Contact"
                      {...register("talkedTo", {
                        required: "Who did you talk to / How did you contact the vendor?",
                        validate: (value) => value.length >= 2 || "Please enter a name of the person you talked to",
                      })}
                    />
                    <ErrorMessage errors={errors} name="talkedTo" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                </div>
              </FormProvider>
            </form>
            <div className="w-full flex flex-row justify-end items-center gap-2 py-5 border-t border-gray-300 px-0.5">
              <PrimaryButton label="Create PO" callback={() => saveChanges()} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateANewPO;
