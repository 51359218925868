import { useEffect, useState, Fragment, useRef } from "react";
import {
  GetOneQuote,
  GetRates,
  UpdateQuote,
  SubmitQuoteForReview,
  ReviseQuote,
  SendQuoteToClient,
  AcceptQuote,
  DeclineQuote,
  ReQuote,
  UpdateQuoteShopFee,
  UpdateQuoteFooter,
  GetQuotePOData,
  UpdateQuotePOData,
  UpdateQuoteJob,
} from "../../actions/quotes";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import FormController from "../../components/FormController";
import { DatePicker, Drawer, Input, InputNumber, Modal, Select, Switch, Table, Tooltip } from "antd";
import validator from "validator";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { Dialog, Transition, Menu } from "@headlessui/react";
import { Trash, EditPencil, NavArrowDown, DeliveryTruck, SendMail, DoubleCheck, Search, Xmark, Eye, OpenNewWindow, WarningCircle } from "iconoir-react";
import { v4 as uuidv4, validate } from "uuid";
import { formatCurrency } from "../../components/tools";
import { generateEstimate, generateEstimatePreview } from "../../data/pdf";
import { newEquipmentATS, newEquipmentBasics, newEquipmentContact, newEquipmentDetails, newEquipmentEngine } from "../../data/equipmentForms";
import { acceptQuoteForm, rejectQuoteForm } from "../../data/quoteForms";
import { AddEquipmentToCustomer } from "../../actions/customers";
import { AdvancedPartsQuery, QueryVendors } from "../../actions/ims";
import { QueryJobs } from "../../actions/jobs";
import { Helmet } from "react-helmet-async";
import { GetDepartments } from "../../actions/auth";
import { filterOption } from "../../components/DynamicField";

const _ = require("lodash");

const testZip = (val) => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
};
const testPhone = (val) => {
  return /^((\+1|1)?( |-)?)?(\(([2-9])(?:\d(?!\5)\d|(?!\5)\d\d)\)|([2-9])(?:\d(?!\6)\d|(?!\6)\d\d))( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(val);
};

const OpenQuote = ({ authState }) => {
  let [loading, setLoading] = useState(true);
  let [quote, setQuote] = useState({});
  let [customer, setCustomer] = useState({});
  let [location, setLocation] = useState({});
  let [equipment, setEquipment] = useState([]);
  let [equipmentDrawer, setEquipmentDrawer] = useState(false);
  let [rates, setRates] = useState([]);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [addLaborModal, setAddLaborModal] = useState(false);
  let [laborData, setLaborData] = useState({
    laborId: "",
    rate: "",
    description: "",
    hours: 0,
    perHour: "",
    extPrice: 0,
  });
  let [addPartsModal, setAddPartsModal] = useState(false);
  let [rawPartsData, setRawPartsData] = useState([]);
  let [partsData, setPartsData] = useState([]);
  let [departments, setDepartments] = useState([]);
  let [partsToAdd, setPartsToAdd] = useState([]);
  let [addParkingModal, setAddParkingModal] = useState(false);
  let [parkingData, setParkingData] = useState({
    parkingType: "",
    increment: "",
    startDate: "",
    endDate: "",
    unitCharge: 0,
    quantity: 0,
    totalCharge: 0,
    parkingId: "",
  });
  let [addLoadbankModal, setAddLoadbankModal] = useState(false);
  let [loadbankData, setLoadbankData] = useState({
    description: "",
    unitCharge: "",
    loadbankId: "",
  });
  let [addFreightModal, setAddFreightModal] = useState(false);
  let [freightData, setFreightData] = useState({
    poNumber: "",
    freightCost: 0,
    freightMarkup: 0,
    freightChargeOut: 0,
    vendor: "",
    freightId: "",
  });
  let [addMiscModal, setAddMiscModal] = useState(false);
  let [miscData, setMiscData] = useState({
    miscType: "",
    partNo: "",
    description: "",
    quantity: 0,
    chargeOutPrice: 0,
    miscId: "",
  });
  let [editLaborModal, setEditLaborModal] = useState(false);
  let [editPartModal, setEditPartModal] = useState(false);
  let [editPartData, setEditPartData] = useState({
    quantity: 0,
    partNo: "",
    partId: "",
    category: "",
    description: "",
    cost: 0,
    pricePerPart: 0,
    extPrice: 0,
    uniqueId: "",
  });
  let [editParkingModal, setEditParkingModal] = useState(false);
  let [editFreightModal, setEditFreightModal] = useState(false);
  let [editLoadbankModal, setEditLoadbankModal] = useState(false);
  let [editMiscModal, setEditMiscModal] = useState(false);
  const [addEquipmentModal, setAddEquipmentModal] = useState(false);
  const [step, setStep] = useState(0);
  const [eqBasics, setEqBasics] = useState({
    customerEquipId: "",
    equipmentType: "",
    power: "",
    miles: "",
    pwrUnit: "",
    milesUnit: "",
    fuelType: "",
    location: "",
    taxZip: "",
  });
  const [eqDetails, setEqDetails] = useState({
    make: "",
    model: "",
    specNo: "",
    serialNo: "",
    arrangement: "",
    status: "",
  });
  const [eqEngine, setEqEngine] = useState({
    make: "",
    model: "",
    specNo: "",
    serialNo: "",
    codeNo: "",
  });
  const [eqATS, setEqATS] = useState({
    make: "",
    model: "",
    specNo: "",
    serialNo: "",
    codeNo: "",
  });
  const [eqContact, setEqContact] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [submitForReviewModal, setSubmitForReviewModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [acceptQuoteModal, setAcceptQuoteModal] = useState(false);
  const [declineQuoteModal, setDeclineQuoteModal] = useState(false);
  const [sendToClientModal, setSendToClientModal] = useState(false);
  const [sendEmail, setSendEmail] = useState(null);
  const [sendText, setSendText] = useState(null);
  const [sendEmailCheck, setSendEmailCheck] = useState(false);
  const [sendTextCheck, setSendTextCheck] = useState(false);
  const [acceptData, setAcceptData] = useState({
    approvedBy: "",
    title: "",
    approvalMethod: "",
    poNumber: "",
    acceptanceNotes: "",
  });
  const [rejectData, setRejectData] = useState({
    rejectedBy: "",
    approvalMethod: "",
    reasonForRejection: "",
  });
  const [search, setSearch] = useState("");
  const [shopFeeModal, setShopFeeModal] = useState(false);
  const [footerVals, setFooterVals] = useState([]);
  const [footerModal, setFooterModal] = useState(false);
  let [total, setTotal] = useState(0);
  let [page, setPage] = useState(0);
  let [size, setSize] = useState(10);
  const [shopFeeVal, setShopFeeVal] = useState(0);
  const [usePercentage, setUsePercentage] = useState(false);
  const [poPrefillData, setPoPrefillData] = useState(null);
  const [poCheckModal, setPoCheckModal] = useState(false);
  const [poEditDrawer, setPoEditDrawer] = useState(false);
  const [poEditData, setPoEditData] = useState({
    poId: "",
    vendorId: "",
    vendorName: "",
    vendorCode: "",
    poAccount: "",
  });
  const [vendors, setVendors] = useState([]);
  const [quoteJobModal, setQuoteJobModal] = useState(false);
  const [jobs, setJobs] = useState([]);

  const { quoteId } = useParams();
  const navigate = useNavigate();

  const freightCostRef = useRef();
  const freightMarkupRef = useRef();
  const freightChargeOutRef = useRef();

  const sendEmailRef = useRef();
  const sendTextRef = useRef();

  useEffect(() => {
    let inView = true;
    if (inView) {
      reloadData();
    }
    return () => {
      inView = false;
    };
  }, [quoteId]);

  const reloadData = () => {
    GetOneQuote(quoteId)
      .then((res) => {
        let quoteData = res.data.quote;
        setQuote(quoteData);
        setShopFeeVal(quoteData.shopFees || 0);
        setCustomer(res.data.customer);
        setLocation(res.data.location);
        setEquipment(res.data.equipment);
        GetRates()
          .then((res) => {
            setRates(res.data);
            AdvancedPartsQuery({ search, limit: 10, page: 0 })
              .then((res) => {
                setRawPartsData(res.data.results);
                setPartsData(res.data.results);
                setTotal(res.data.total);
                setPage(res.data.page);
                setSize(res.data.size);
                GetDepartments()
                  .then((res) => {
                    setDepartments(res.data);
                    setTimeout(() => setLoading(false), 700);
                  })
                  .catch((err) => {
                    toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                    setTimeout(() => setLoading(false), 700);
                  });
              })
              .catch((err) => {
                toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                setTimeout(() => setLoading(false), 700);
              });
          })
          .catch((err) => {
            toast.error("Error loading labor rates");
            setLoading(false);
          });
      })
      .catch((err) => {
        toast.error("Error loading quote");
        setTimeout(() => {
          navigate("/quotes");
        }, 3000);
      });
  };

  const renderQuoteStatus = () => {
    if (quote.quoteStatus === "draft") {
      return <span className="font-semibold text-amber-400">Draft</span>;
    } else if (quote.quoteStatus === "approval") {
      return <span className="font-semibold text-orange-400">Awaiting Manager Approval</span>;
    } else if (quote.quoteStatus === "returned") {
      return <span className="font-semibold text-red-500">Returned by Manager</span>;
    } else if (quote.quoteStatus === "approved") {
      return <span className="font-semibold text-green-500">Approved by Manager</span>;
    } else if (quote.quoteStatus === "sent") {
      return <span className="font-semibold text-sky-400">Sent to Customer</span>;
    } else if (quote.quoteStatus === "accepted") {
      return <span className="font-semibold text-green-500">Accepted by Customer</span>;
    } else if (quote.quoteStatus === "declined") {
      return <span className="font-semibold text-red-500">Rejected by Customer</span>;
    } else if (quote.quoteStatus === "void") {
      return <span className="font-semibold text-rose-800">VOID</span>;
    } else if (quote.quoteStatus === "jobCreated") {
      return <span className="font-semibold text-green-500">Job Created</span>;
    }
  };

  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isDirty, dirtyFields },
  } = formMethods;

  const onSubmit = (data) => {
    data.date = dayjs(data.date).toJSON();
    let tmp = quote;
    for (let key in data) {
      tmp[key] = data[key];
    }
    setLoading(true);
    UpdateQuote(quoteId, tmp)
      .then((res) => {
        toast.success("Quote updated successfully");
        GetOneQuote(quoteId)
          .then((res) => {
            let quoteData = res.data.quote;
            setQuote(quoteData);
            setShopFeeVal(quoteData.shopFees || 0);
            setCustomer(res.data.customer);
            setLocation(res.data.location);
            setEquipment(res.data.equipment);
            GetRates()
              .then((res) => {
                setRates(res.data);
                AdvancedPartsQuery({ search, limit: 10, page: 0 })
                  .then((res) => {
                    setRawPartsData(res.data.results);
                    setPartsData(res.data.results);
                    setTotal(res.data.total);
                    setPage(res.data.page);
                    setSize(res.data.size);
                    GetDepartments()
                      .then((res) => {
                        setDepartments(res.data);
                        setTimeout(() => setLoading(false), 700);
                      })
                      .catch((err) => {
                        toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                        setTimeout(() => setLoading(false), 700);
                      });
                  })
                  .catch((err) => {
                    toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                    setTimeout(() => setLoading(false), 700);
                  });
              })
              .catch((err) => {
                toast.error("Error loading labor rates");
                setLoading(false);
              });
          })
          .catch((err) => {
            toast.error("Error loading quote");
            setTimeout(() => {
              navigate("/quotes");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating quote");
        GetOneQuote(quoteId)
          .then((res) => {
            let quoteData = res.data.quote;
            setQuote(quoteData);
            setShopFeeVal(quoteData.shopFees || 0);
            setCustomer(res.data.customer);
            setLocation(res.data.location);
            setEquipment(res.data.equipment);
            GetRates()
              .then((res) => {
                setRates(res.data);
                AdvancedPartsQuery({ search, limit: 10, page: 0 })
                  .then((res) => {
                    setRawPartsData(res.data.results);
                    setPartsData(res.data.results);
                    setTotal(res.data.total);
                    setPage(res.data.page);
                    setSize(res.data.size);
                    GetDepartments()
                      .then((res) => {
                        setDepartments(res.data);
                        setTimeout(() => setLoading(false), 700);
                      })
                      .catch((err) => {
                        toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                        setTimeout(() => setLoading(false), 700);
                      });
                  })
                  .catch((err) => {
                    toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                    setTimeout(() => setLoading(false), 700);
                  });
              })
              .catch((err) => {
                toast.error("Error loading labor rates");
                setLoading(false);
              });
          })
          .catch((err) => {
            toast.error("Error loading quote");
            setTimeout(() => {
              navigate("/quotes");
            }, 3000);
          });
      });
  };

  const onSubmitForReview = (data) => {
    data.date = dayjs(data.date).toJSON();
    let tmp = quote;
    for (let key in data) {
      tmp[key] = data[key];
    }
    setLoading(true);
    UpdateQuote(quoteId, tmp)
      .then((res) => {
        toast.success("Quote updated successfully");
        setTimeout(() => setLoading(false), 700);
        setSubmitForReviewModal(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating quote");
      });
  };

  const calculateLabor = () => {
    let total = 0;
    quote.labor.forEach((labor) => {
      total += parseFloat(labor.extPrice);
    });
    return total;
  };

  const calculateParts = () => {
    let total = 0;
    quote.parts.forEach((part) => {
      if (part.category === "parts") {
        total += parseFloat(part.extPrice);
      }
    });
    return total;
  };

  const calculateGOG = () => {
    let total = 0;
    quote.parts.forEach((part) => {
      if (part.category !== "parts") {
        total += parseFloat(part.extPrice);
      }
    });
    return total;
  };

  const calculateParking = () => {
    let total = 0;
    quote.parking.forEach((parking) => {
      total += parseFloat(parking.totalCharge);
    });
    return total;
  };

  const calculateLoadbank = () => {
    let total = 0;
    quote.loadbank.forEach((loadbank) => {
      total += parseFloat(loadbank.unitCharge);
    });
    return total;
  };

  const calculateFreight = () => {
    let total = 0;
    quote.freight.forEach((freight) => {
      total += parseFloat(freight.freightChargeOut);
    });
    return total;
  };

  const calculateMisc = () => {
    let total = 0;
    quote.misc.forEach((misc) => {
      total += parseFloat(misc.chargeOutPrice);
    });
    return total;
  };

  const calculateTotal = () => {
    let total = 0;
    total += calculateLabor();
    total += calculateParts();
    total += calculateGOG();
    total += calculateParking();
    total += calculateLoadbank();
    total += calculateFreight();
    total += calculateMisc();
    total += quote.shopFees || 0;
    return total;
  };

  const calculatePercentage = (percent) => {
    let subtotal = 0;
    subtotal += calculateLabor();
    subtotal += calculateParts();
    subtotal += calculateGOG();
    subtotal += calculateFreight();
    subtotal += calculateMisc();
    setShopFeeVal(parseFloat(((subtotal * percent) / 100).toFixed(2)));
  };

  const renderUpperForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className={`w-full flex flex-row justify-between items-center gap-5`} key="upperForm">
        <FormProvider {...formMethods}>
          <div className="flex flex-col items-start justify-start w-full gap-2">
            <div key="locationId" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="locationId" className="pb-1 text-xs text-gray-600 uppercase">
                Location
              </label>
              <select
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                disabled={quote.quoteStatus !== "draft"}
                id="locationId"
                defaultValue={quote.locationId}
                {...register("locationId", {
                  required: "Location is required",
                  validate: (value) => value.length >= 3 || "Please select a location",
                })}
              >
                <option hidden disabled value="">
                  Select One
                </option>
                <option key="a6fe18dd-b809-4a78-85ab-e767e2b8ebcf" value="a6fe18dd-b809-4a78-85ab-e767e2b8ebcf">
                  Augusta | GA
                </option>
                <option key="1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f" value="1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f">
                  North Augusta | SC
                </option>
              </select>
              <ErrorMessage errors={errors} name="locationId" as="p" className="px-1 pt-1 text-xs text-red-500" />
            </div>

            <div key="date" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="date" className="pb-1 text-xs text-gray-600 uppercase">
                Quote Date
              </label>
              <Controller
                control={formMethods.control}
                name="date"
                rules={{
                  required: "Quote date is required",
                  validate: (v) => dayjs(v).isValid() || "Please set a date for the quote",
                }}
                defaultValue={dayjs(quote.date)}
                render={(props) => (
                  <DatePicker
                    placeholder="Select a date for the quote"
                    {...props}
                    format={"MM/DD/YYYY"}
                    onChange={(date, dateString) => {
                      props.field.onChange(dateString);
                    }}
                    onBlur={(date, dateString) => {
                      props.field.onBlur(dateString);
                    }}
                    defaultValue={quote.date !== "" ? dayjs(quote.date) : null}
                    value={quote.date !== "" ? dayjs(quote.date) : null}
                    ref={props.field.ref}
                    className="w-full disabled:cursor-not-allowed disabled:bg-gray-100/80"
                    disabled={quote.quoteStatus !== "draft"}
                  />
                )}
              />
              <ErrorMessage errors={errors} name="date" as="p" className="px-1 pt-1 text-xs text-red-500" />
            </div>

            <div key="description" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                Description
              </label>
              <input
                className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                disabled={quote.quoteStatus !== "draft"}
                type="text"
                id="description"
                defaultValue={quote.description}
                placeholder="Quote Description"
                {...register("description", {
                  required: "Quote description is required",
                  validate: (value) => value.length >= 3 || "Please enter a description the quote",
                })}
              />
              <ErrorMessage errors={errors} name="description" as="p" className="px-1 pt-1 text-xs text-red-500" />
            </div>
            <div key="eoNotes" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="eoNotes" className="pb-1 text-xs text-gray-600 uppercase">
                EO Notes
              </label>
              <input
                className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                disabled={quote.quoteStatus !== "draft"}
                type="text"
                id="eoNotes"
                defaultValue={quote.eoNotes}
                placeholder="EO Notes"
                {...register("eoNotes", {
                  required: false,
                })}
              />
              <ErrorMessage errors={errors} name="eoNotes" as="p" className="px-1 pt-1 text-xs text-red-500" />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-2">
            <div className="flex flex-row items-center justify-between w-full gap-4">
              <div key="poNumber" className="flex flex-col items-start justify-start w-full">
                <label htmlFor="poNumber" className="pb-1 text-xs text-gray-600 uppercase">
                  Client's PO Number
                </label>
                <input
                  className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                  disabled={quote.quoteStatus !== "draft"}
                  type="text"
                  id="poNumber"
                  defaultValue={quote.poNumber}
                  placeholder="Client's PO Number"
                  {...register("poNumber", {
                    required: false,
                  })}
                />
                <ErrorMessage errors={errors} name="poNumber" as="p" className="px-1 pt-1 text-xs text-red-500" />
              </div>
              <div key="tax" className="flex flex-col items-start justify-start w-full">
                <label htmlFor="tax" className="pb-1 text-xs text-gray-600 uppercase">
                  Tax Status
                </label>
                <select
                  className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                  disabled={quote.quoteStatus !== "draft"}
                  id="tax"
                  defaultValue={quote.tax}
                  {...register("tax", {
                    required: "Tax Status is Required",
                    validate: (value) => value.length >= 3 || "Please select a tax status",
                  })}
                >
                  <option hidden disabled value="">
                    Select One
                  </option>
                  <option key="taxable" value="taxable">
                    Taxable
                  </option>
                  <option key="gov" value="gov">
                    Government Entity
                  </option>
                  <option key="st5biz" value="st5biz">
                    Business w/ ST5
                  </option>
                  <option key="outOfState" value="outOfState">
                    Out of State
                  </option>
                </select>
                <ErrorMessage errors={errors} name="tax" as="p" className="px-1 pt-1 text-xs text-red-500" />
              </div>
              <div key="taxZip" className="flex flex-col items-start justify-start w-full">
                <label htmlFor="taxZip" className="pb-1 text-xs text-gray-600 uppercase">
                  Sales Tax ZIP
                </label>
                <input
                  className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                  disabled={quote.quoteStatus !== "draft"}
                  type="text"
                  id="taxZip"
                  defaultValue={quote.taxZip}
                  placeholder="Sales Tax ZIP"
                  {...register("taxZip", {
                    required: "Customer's sales tax ZIP code is required",
                    validate: (value) => testZip(value) || "Please enter a valid ZIP code",
                  })}
                />
                <ErrorMessage errors={errors} name="taxZip" as="p" className="px-1 pt-1 text-xs text-red-500" />
              </div>
            </div>
            {authState.user.functionCategory !== "technician" && (
              <div className="flex flex-row items-center justify-between w-full gap-4">
                <div key="laborDiscount" className="flex flex-col items-start justify-start w-full">
                  <label htmlFor="laborDiscount" className="pb-1 text-xs text-gray-600 uppercase">
                    Labor Discount
                  </label>
                  <input
                    className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                    disabled={quote.quoteStatus !== "draft"}
                    type="number"
                    id="laborDiscount"
                    defaultValue={quote.laborDiscount}
                    placeholder="Client's PO Number"
                    {...register("laborDiscount", {
                      required: "Labor discount is required",
                      validate: (value) => validator.isInt(value.toString()) || "Please enter a labor discount",
                    })}
                  />
                  <ErrorMessage errors={errors} name="laborDiscount" as="p" className="px-1 pt-1 text-xs text-red-500" />
                </div>
                <div key="partsMarkup" className="flex flex-col items-start justify-start w-full">
                  <label htmlFor="partsMarkup" className="pb-1 text-xs text-gray-600 uppercase">
                    Parts Markup %
                  </label>
                  <input
                    className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                    disabled={quote.quoteStatus !== "draft"}
                    type="number"
                    id="partsMarkup"
                    defaultValue={quote.partsMarkup}
                    placeholder="Parts Markup"
                    {...register("partsMarkup", {
                      required: "Parts Markup % is required",
                      validate: (value) => validator.isInt(value.toString()) || "Please enter a Parts Markup %",
                    })}
                  />
                  <ErrorMessage errors={errors} name="partsMarkup" as="p" className="px-1 pt-1 text-xs text-red-500" />
                </div>
                <div key="paymentTerms" className="flex flex-col items-start justify-start w-full">
                  <label htmlFor="paymentTerms" className="pb-1 text-xs text-gray-600 uppercase">
                    Payment Terms
                  </label>
                  <select
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                    disabled={quote.quoteStatus !== "draft"}
                    id="paymentTerms"
                    defaultValue={quote.paymentTerms}
                    {...register("paymentTerms", {
                      required: "Payment Terms are Required",
                      validate: (value) => value.length >= 3 || "Please select payment terms",
                    })}
                  >
                    <option hidden disabled value="">
                      Select One
                    </option>
                    <option key="net10" value="net10">
                      NET 10
                    </option>
                    <option key="net20" value="net20">
                      NET 20
                    </option>
                    <option key="net30" value="net30">
                      NET 30
                    </option>
                    <option key="collectOnDelivery" value="collectOnDelivery">
                      Collect on Delivery
                    </option>
                    <option key="dueOnReceipt" value="dueOnReceipt">
                      Due on Receipt
                    </option>
                    <option key="prePay" value="prePay">
                      Pre-Pay
                    </option>
                  </select>
                  <ErrorMessage errors={errors} name="paymentTerms" as="p" className="px-1 pt-1 text-xs text-red-500" />
                </div>
              </div>
            )}
            <div key="details" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="details" className="pb-1 text-xs text-gray-600 uppercase">
                Quote Details
              </label>
              <textarea
                rows={3}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none disabled:cursor-not-allowed disabled:bg-gray-100/80"
                disabled={quote.quoteStatus !== "draft"}
                id="details"
                defaultValue={quote.details}
                placeholder="Quote Details"
                {...register("details", {
                  required: "Quote details are required",
                  validate: (value) => value.length >= 3 || "Please enter the quote details",
                })}
              />
              <ErrorMessage errors={errors} name="details" as="p" className="px-1 pt-1 text-xs text-red-500" />
            </div>
          </div>
        </FormProvider>
      </form>
    );
  };

  const renderEquipStatus = (status) => {
    if (status === "availableForUse") {
      return "Available for Use";
    } else if (status === "inoperable") {
      return "Inoperable";
    } else if (status === "retired") {
      return "Retired";
    } else {
      return "Unknown";
    }
  };

  const renderEquipType = (eqType) => {
    switch (eqType) {
      case "generator":
        return "Generator";
      case "pressureWasher":
        return "Pressure Washer";
      case "truck":
        return "Truck";
      case "trailer":
        return "Trailer";
      case "welder":
        return "Welder";
      case "airCompressor":
        return "Air Compressor";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const updateQuoteEq = (eqId) => {
    setDummyLoading(true);
    if (quote.equipment.includes(eqId)) {
      let tmp = quote;
      let newEq = tmp.equipment.filter((eq) => eq !== eqId);
      tmp.equipment = newEq;
      setQuote(tmp);
    } else {
      let tmp = quote;
      tmp.equipment.push(eqId);
      setQuote(tmp);
    }
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderEquipmentDrawer = () => {
    return (
      <Transition.Root show={equipmentDrawer} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setEquipmentDrawer}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                    <div className="flex flex-col h-full pt-6 overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Add / Remove Equipment</Dialog.Title>
                          <div className="flex items-center ml-3 h-7">
                            <button
                              type="button"
                              className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                              onClick={() => setEquipmentDrawer(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <Xmark className="w-6 h-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex flex-col items-center justify-start flex-1 gap-2 px-4 mt-6 overflow-y-auto sm:px-6">
                        {equipment.map((eq) => (
                          <div
                            className={`w-full flex flex-col justify-start items-start border rounded-lg ${
                              quote.equipment.includes(eq.equipmentId) ? "border-gray-500" : "border-gray-300"
                            } px-4 py-3 gap-1`}
                            key={eq.equipmentId}
                          >
                            <p className="text-sm">
                              <span className="text-xs font-semibold uppercase">Type:</span> {renderEquipType(eq.equipmentType)}
                            </p>
                            <p className="text-sm">
                              <span className="text-xs font-semibold uppercase">Customer's Equipment ID:</span>{" "}
                              {eq.customerEquipId && eq.customerEquipId.length > 0 ? eq.customerEquipId : "Not Provided"}
                            </p>
                            <p className="text-sm">
                              <span className="text-xs font-semibold uppercase">Make:</span> {eq.details.make}
                            </p>
                            <p className="text-sm">
                              <span className="text-xs font-semibold uppercase">Model:</span> {eq.details.model}
                            </p>
                            <div className="flex flex-row items-center justify-end w-full">
                              <SecondaryButton
                                label={quote.equipment.includes(eq.equipmentId) ? "Remove from Quote" : "Add to Quote"}
                                callback={() => {
                                  updateQuoteEq(eq.equipmentId);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-row items-center justify-end w-full gap-3 px-5 py-5 border-t border-gray-300">
                        <SecondaryButton label="Add New Equipment" callback={() => window.open("/equipment/new", "_blank")} />
                        <SecondaryButton label="Close" callback={() => setEquipmentDrawer(false)} />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAddLaborModal = () => {
    setAddLaborModal(false);
    setLaborData({
      laborId: "",
      rate: "",
      description: "",
      hours: 0,
      perHour: "",
      extPrice: 0,
    });
  };

  const changeLaborData = (e) => {
    let { id, value } = e.target;
    let tmp = laborData;
    tmp[id] = value;
    setLaborData(tmp);
  };

  const addNewLabor = () => {
    let failed = false;
    if (laborData.rate === "") {
      toast.error("Please select a labor rate");
      failed = true;
    } else if (laborData.hours === 0) {
      toast.error("Please enter the number of hours to be worked");
      failed = true;
    } else if (laborData.description.length < 5) {
      toast.error("Please enter a description for the labor being quoted");
      failed = true;
    }

    if (!failed) {
      setDummyLoading(true);
      let tmp = quote;
      let toPush = {
        laborId: uuidv4(),
        rate: laborData.rate,
        description: laborData.description,
        hours: parseFloat(laborData.hours),
        perHour: rates.find((rt) => rt.rateId === laborData.rate).rate,
      };
      if (parseFloat(quote.laborDiscount) > 0) {
        toPush.perHour = parseFloat((toPush.perHour * ((100 - parseFloat(quote.laborDiscount)) / 100)).toFixed(2));
      }
      toPush.extPrice = parseFloat((toPush.hours * toPush.perHour).toFixed(2));
      tmp.labor.push(toPush);
      setQuote(tmp);
      closeAddLaborModal();
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const renderAddLaborModal = () => {
    return (
      <Transition.Root show={addLaborModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddLaborModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-md sm:px-6">
                  <div>
                    <div className="text-center">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Labor to Quote
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="rate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="rate" className="pb-1 text-xs text-gray-600 uppercase">
                            Labor Rate
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="rate"
                            defaultValue=""
                            onChange={changeLaborData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            {rates.map((rt) => (
                              <option key={rt.rateId} value={rt.rateId}>
                                {rt.laborCode} - {rt.description} | {formatCurrency(rt.rate)}/hr
                              </option>
                            ))}
                          </select>
                        </div>
                        <div key="hours" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="hours" className="pb-1 text-xs text-gray-600 uppercase">
                            Hours
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="hours"
                            placeholder="How many hours of labor is being quoted?"
                            onChange={changeLaborData}
                          />
                        </div>
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Labor Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="description"
                            placeholder="Describe the labor being quoted"
                            onChange={changeLaborData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeAddLaborModal()} />
                    <PrimaryButton label="Add Labor" callback={() => addNewLabor()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closePartsModal = () => {
    setPartsData([]);
    setPartsToAdd([]);
    setAddPartsModal(false);
    setSearch("");
    setPage(0);
    setTotal(0);
    setSize(10);
    resetPartSearch(0, 10);
  };

  const changePartPrice = (e) => {
    let tmp = partsToAdd;
    let { id, value } = e.target;
    if (value !== "0" || value !== "" || value !== "0") {
      if (parseFloat(value) > 0) {
        setDummyLoading(true);
        let part = partsData.find((pt) => pt.partId === id);
        let existing = _.find(tmp, (p) => p.partId === id);
        if (existing && existing !== undefined) {
          existing.pricePerPart = value;
          existing.extPrice = parseFloat((existing.quantity * value).toFixed(2));
          let index = _.findIndex(tmp, (p) => p.uniqueId === existing.uniqueId);
          tmp[index] = existing;
          setPartsToAdd(tmp);
        } else {
          tmp.push({
            quantity: 0,
            partNo: part.partNo,
            partId: id,
            category: part.category,
            description: part.description,
            cost: part.cost,
            pricePerPart: value,
            extPrice: 0,
            uniqueId: uuidv4(),
          });
          setPartsToAdd(tmp);
        }
        setTimeout(() => setDummyLoading(false), 700);
      } else {
        toast.error("Please enter a valid value");
      }
    } else {
      toast.error("Please enter a valid value");
    }
  };

  const changePartQuantity = (e) => {
    let tmp = partsToAdd;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      if (parseFloat(value) > 0) {
        setDummyLoading(true);
        let part = partsData.find((pt) => pt.partId === id);
        let existing = _.find(tmp, (p) => p.partId === id);
        if (existing && existing !== undefined) {
          existing.quantity = value;
          existing.extPrice = parseFloat((existing.pricePerPart * value).toFixed(2));
          let index = _.findIndex(tmp, (p) => p.uniqueId === existing.uniqueId);
          tmp[index] = existing;
          setPartsToAdd(tmp);
        } else {
          tmp.push({
            quantity: value,
            partNo: part.partNo,
            partId: id,
            category: part.category,
            description: part.description,
            cost: part.cost,
            pricePerPart: parseFloat((part.cost + part.cost * (quote.partsMarkup / 100)).toFixed(2)),
            extPrice: parseFloat(((part.cost + part.cost * (quote.partsMarkup / 100)) * value).toFixed(2)),
            uniqueId: uuidv4(),
          });
          setPartsToAdd(tmp);
        }
        setTimeout(() => setDummyLoading(false), 700);
      } else {
        toast.error("Please enter a valid value");
      }
    }
  };

  const addNewParts = () => {
    let tmp = partsToAdd;
    tmp.filter((p) => p.quantity !== "0" || p.quantity !== "" || p.quantity !== 0);
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].toCommit = 0;
      tmp[i].quantity = parseFloat(tmp[i].quantity);
      tmp[i].uniqueId = uuidv4();
    }
    setQuote((prev) => ({ ...prev, parts: [...prev.parts, ...tmp] }));
    closePartsModal();
  };

  const onPartSearch = (pg, lmt) => {
    setDummyLoading(true);
    AdvancedPartsQuery({ search, limit: lmt, page: pg })
      .then((res) => {
        setRawPartsData(res.data.results);
        setPartsData(res.data.results);
        setTotal(res.data.total);
        setPage(res.data.page);
        setSize(res.data.size);
        setTimeout(() => setDummyLoading(false), 750);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
        setTimeout(() => setDummyLoading(false), 750);
      });
  };

  const resetPartSearch = (pg, lmt) => {
    setSearch("");
    setDummyLoading(true);
    AdvancedPartsQuery({ search: "", limit: lmt, page: pg })
      .then((res) => {
        setRawPartsData(res.data.results);
        setPartsData(res.data.results);
        setTotal(res.data.total);
        setPage(res.data.page);
        setSize(res.data.size);
        setTimeout(() => setDummyLoading(false), 750);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
        setTimeout(() => setDummyLoading(false), 750);
      });
  };

  const changePage = (pg, sz) => {
    setPage(pg);
    setSize(sz);
    onPartSearch(pg, sz);
  };

  const renderAddPartQuantityChange = (partId) => {
    let pt = partsData.find((pt) => pt.partId === partId);
    let defVal = 0;
    let find = partsToAdd.find((p) => p.partId === partId);
    if (find) {
      defVal = find.quantity;
    }
    if (pt && !dummyLoading) {
      return (
        <InputNumber
          className="w-[140px]"
          id={pt.partId}
          defaultValue={parseFloat(defVal)}
          onBlur={changePartQuantity}
          min={0}
          controls={false}
          addonBefore="QTY"
        />
      );
    } else {
      return (
        <input
          className="w-[140px] rounded-md py-1 px-3 text-gray-900 border border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          type="text"
          id="LOADING"
          defaultValue={"LOADING"}
          disabled={true}
        />
      );
    }
  };

  const renderAddPartPriceChange = (partId) => {
    let pt = partsData.find((pt) => pt.partId === partId);
    if (pt && !dummyLoading) {
      return (
        <InputNumber
          className="w-[140px]"
          defaultValue={parseFloat(pt.chargeOut)}
          onBlur={changePartPrice}
          min={0}
          step={0.01}
          id={pt.partId}
          controls={false}
          addonBefore="$"
        />
      );
    } else {
      return (
        <input
          className="w-[140px] rounded-md py-1 px-3 text-gray-900 border border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          type="text"
          id="LOADING"
          defaultValue={"LOADING"}
          disabled={true}
        />
      );
    }
  };

  const renderAddPartsModal = () => {
    return (
      <Modal open={addPartsModal} onCancel={() => closePartsModal()} footer={[]} title="Add Parts to Job" centered destroyOnClose width={900}>
        <div className="flex flex-col items-start justify-between w-full h-full gap-1">
          <div className="flex flex-row items-center justify-end w-full gap-2">
            <Input
              placeholder="Start typing to search parts"
              onChange={(e) => setSearch(e.target.value)}
              defaultValue={search}
              className="w-[280px] px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
              allowClear
              onPressEnter={() => onPartSearch(page, size)}
              onClear={() => resetPartSearch(page, size)}
            />
            <SecondaryButton label="Search" callback={() => onPartSearch(page, size)} />
          </div>
          <Table
            dataSource={partsData}
            size="small"
            pagination={{
              total: total,
              responsive: true,
              hideOnSinglePage: true,
              onChange: (p, s) => changePage(p - 1, s),
              pageSize: size,
              current: page + 1,
              showSizeChanger: false,
            }}
            className="flex-grow w-full h-full py-2"
            loading={dummyLoading}
          >
            <Table.Column title="Department" dataIndex="department" key="department" render={(r) => departments.find((d) => d.departmentId === r)?.name} />
            <Table.Column title="Category" dataIndex="category" key="category" render={(r) => r.toUpperCase()} />
            <Table.Column
              title="Part No."
              dataIndex="partNo"
              key="partNo"
              render={(v) => <Tooltip title={v}>{v.length > 20 ? v.substring(0, 20) + "..." : v}</Tooltip>}
            />
            <Table.Column
              title="Description"
              dataIndex="description"
              key="description"
              render={(v) => <Tooltip title={v}>{v.length > 20 ? v.substring(0, 20) + "..." : v}</Tooltip>}
            />
            <Table.Column title="Stock" dataIndex="stock" key="stock" render={(r) => renderQtyInStock(r)} />
            <Table.Column title="Ext. Price" dataIndex="partId" key="partId" render={(r) => renderAddPartPriceChange(r)} />
            <Table.Column title="Quantity" dataIndex="partId" key="partId" render={(r) => renderAddPartQuantityChange(r)} />
          </Table>
          <div className="flex flex-row items-center justify-end w-full gap-4 pt-5 border-t border-gray-300">
            <SecondaryButton label="Cancel" callback={() => closePartsModal()} />
            <PrimaryButton label="Add Parts to Job" callback={() => addNewParts()} />
          </div>
        </div>
      </Modal>
    );
  };

  const renderQtyInStock = (pt) => {
    if (pt && pt.length > 0) {
      let total = 0;
      for (let i = 0; i < pt.length; i++) {
        const element = pt[i];
        total += element.quantityInStock;
      }
      return total;
    } else {
      return "0";
    }
  };

  const closeParkingModal = () => {
    setAddParkingModal(false);
    setParkingData({
      parkingType: "",
      increment: "",
      startDate: "",
      endDate: "",
      unitCharge: 0,
      quantity: 0,
      totalCharge: 0,
      parkingId: "",
    });
  };

  const addNewParking = () => {
    let failed = false;
    if (parkingData.parkingType === "") {
      toast.error("Please select a fee type");
      failed = true;
    } else if (parkingData.increment === "") {
      toast.error("Please select a charge increment");
      failed = true;
    } else if (parkingData.startDate === "") {
      toast.error("Please select a start date");
      failed = true;
    } else if (parkingData.endDate === "") {
      toast.error("Please select an end date");
      failed = true;
    } else if (parkingData.unitCharge === 0) {
      toast.error("Please enter a unit charge");
      failed = true;
    } else if (parkingData.quantity === 0 || parkingData.totalCharge === 0) {
      toast.error("There is not enough quantity for the charge");
      failed = true;
    }
    if (!failed) {
      setDummyLoading(true);
      let tmp = quote;
      let toAdd = parkingData;
      toAdd.parkingId = uuidv4();
      tmp.parking.push(toAdd);
      setQuote(tmp);
      closeParkingModal();
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const changeParkingData = (e) => {
    let { id, value } = e.target;
    setDummyLoading(true);
    let tmp = parkingData;
    tmp[id] = value;
    if (tmp.startDate !== "" && tmp.endDate !== "" && tmp.increment !== "") {
      let start = dayjs(tmp.startDate);
      let end = dayjs(tmp.endDate);
      let diff = end.diff(start, "days");
      if (tmp.increment === "daily") {
        tmp.quantity = Math.ceil(diff);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "weekly") {
        tmp.quantity = Math.ceil(diff / 7);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "monthly") {
        tmp.quantity = Math.ceil(diff / 30);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "annually") {
        tmp.quantity = Math.ceil(diff / 365);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      }
    }
    setParkingData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const changeParkingStart = (date) => {
    let tmp = parkingData;
    setDummyLoading(true);
    tmp.startDate = date;
    if (tmp.startDate !== "" && tmp.endDate !== "" && tmp.increment !== "") {
      let start = dayjs(tmp.startDate);
      let end = dayjs(tmp.endDate);
      let diff = end.diff(start, "days");
      if (tmp.increment === "daily") {
        tmp.quantity = Math.ceil(diff);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "weekly") {
        tmp.quantity = Math.ceil(diff / 7);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "monthly") {
        tmp.quantity = Math.ceil(diff / 30);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "annually") {
        tmp.quantity = Math.ceil(diff / 365);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      }
    }
    setParkingData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const changeParkingEnd = (date) => {
    let tmp = parkingData;
    setDummyLoading(true);
    tmp.endDate = date;
    if (tmp.startDate !== "" && tmp.endDate !== "" && tmp.increment !== "") {
      let start = dayjs(tmp.startDate);
      let end = dayjs(tmp.endDate);
      let diff = end.diff(start, "days");
      if (tmp.increment === "daily") {
        tmp.quantity = Math.ceil(diff);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "weekly") {
        tmp.quantity = Math.ceil(diff / 7);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "monthly") {
        tmp.quantity = Math.ceil(diff / 30);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "annually") {
        tmp.quantity = Math.ceil(diff / 365);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      }
    }
    setParkingData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderAddParkingModal = () => {
    return (
      <Transition.Root show={addParkingModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeParkingModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Parking/Storage Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="parkingType" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="parkingType" className="pb-1 text-xs text-gray-600 uppercase">
                            Fee Type
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="parkingType"
                            defaultValue=""
                            onChange={changeParkingData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="parking" value="parking">
                              Parking
                            </option>
                            <option key="storage" value="storage">
                              Storage
                            </option>
                          </select>
                        </div>
                        <div key="startDate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="startDate" className="pb-1 text-xs text-gray-600 uppercase">
                            Start Date
                          </label>
                          <DatePicker format={"MM/DD/YYYY"} onChange={(date, dateString) => changeParkingStart(dateString)} style={{ width: "100%" }} />
                        </div>
                        <div key="endDate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="endDate" className="pb-1 text-xs text-gray-600 uppercase">
                            End Date
                          </label>
                          <DatePicker format={"MM/DD/YYYY"} onChange={(date, dateString) => changeParkingEnd(dateString)} style={{ width: "100%" }} />
                        </div>
                        <div key="increment" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="increment" className="pb-1 text-xs text-gray-600 uppercase">
                            Charge increment
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="increment"
                            defaultValue=""
                            onChange={changeParkingData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="daily" value="daily">
                              Daily
                            </option>
                            <option key="weekly" value="weekly">
                              Weekly
                            </option>
                            <option key="monthly" value="monthly">
                              Monthly
                            </option>
                            <option key="annually" value="annually">
                              Annually
                            </option>
                          </select>
                        </div>
                        <div key="unitCharge" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="unitCharge" className="pb-1 text-xs text-gray-600 uppercase">
                            Unit Charge
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="unitCharge"
                            onChange={changeParkingData}
                          />
                        </div>
                        <div className="flex flex-col items-end justify-center w-full gap-1 px-1 py-2 border-gray-300 border-y">
                          <p className="text-sm uppercase">
                            Quantity: <span className="font-semibold">{parkingData.quantity}</span>
                          </p>
                          <p className="text-sm uppercase">
                            Total: <span className="font-semibold">{formatCurrency(parkingData.totalCharge)}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeParkingModal()} />
                    <PrimaryButton label="Add Parking/Storage Fee" callback={() => addNewParking()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAddLoadbank = () => {
    setAddLoadbankModal(false);
    setLoadbankData({
      description: "",
      unitCharge: 0,
      loadbankId: "",
    });
  };

  const changeLoadbankData = (e) => {
    let tmp = loadbankData;
    let { id, value } = e.target;
    tmp[id] = value;
    setLoadbankData(tmp);
  };

  const addNewLoadbank = () => {
    let failed = false;
    if (loadbankData.description === "") {
      toast.error("Please enter a loadbank fee description");
      failed = true;
    } else if (loadbankData.unitCharge === 0) {
      toast.error("Please enter a unit charge");
      failed = true;
    }

    if (!failed) {
      setDummyLoading(true);
      let tmp = quote;
      let toAdd = loadbankData;
      toAdd.loadbankId = uuidv4();
      tmp.loadbank.push(toAdd);
      setQuote(tmp);
      closeAddLoadbank();
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const renderAddLoadbankModal = () => {
    return (
      <Transition.Root show={addLoadbankModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddLoadbank}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Loadbank Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="description"
                            onChange={changeLoadbankData}
                          />
                        </div>
                        <div key="unitCharge" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="unitCharge" className="pb-1 text-xs text-gray-600 uppercase">
                            Loadbank Fee
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="unitCharge"
                            onChange={changeLoadbankData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeAddLoadbank()} />
                    <PrimaryButton label="Add Loadbank Fee" callback={() => addNewLoadbank()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAddFreightModal = () => {
    setAddFreightModal(false);
    setFreightData({
      poNumber: "",
      freightCost: 0,
      freightMarkup: 0,
      freightChargeOut: 0,
      vendor: "",
      freightId: "",
    });
  };

  const addNewFreight = () => {
    let failed = false;
    if (freightData.vendor === "") {
      toast.error("Please enter a vendor");
      failed = true;
    } else if (freightData.freightCost === 0) {
      toast.error("Please enter a freight cost");
      failed = true;
    } else if (freightData.freightChargeOut === 0) {
      toast.error("Please enter a freight charge out");
      failed = true;
    }
    if (!failed) {
      setDummyLoading(true);
      let tmp = quote;
      let toAdd = freightData;
      toAdd.freightId = uuidv4();
      tmp.freight.push(toAdd);
      setQuote(tmp);
      closeAddFreightModal();
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const changeFreightData = (e) => {
    setDummyLoading(true);
    let tmp = freightData;
    let { id, value } = e.target;
    if (id === "freightCost" || id === "freightMarkup" || id === "freightChargeOut") {
      value = parseFloat(value);
    }
    tmp[id] = value;
    if (id === "freightCost") {
      if (tmp.freightMarkup === 0) {
        tmp.freightChargeOut = tmp.freightCost;
        freightChargeOutRef.current.value = tmp.freightChargeOut;
      } else {
        tmp.freightChargeOut = parseFloat((tmp.freightCost + tmp.freightCost * (tmp.freightMarkup / 100)).toFixed(2));
        freightChargeOutRef.current.value = tmp.freightChargeOut;
      }
    }
    if (id === "freightMarkup") {
      if (tmp.freightCost !== 0) {
        tmp.freightChargeOut = parseFloat((tmp.freightCost + tmp.freightCost * (tmp.freightMarkup / 100)).toFixed(2));
        freightChargeOutRef.current.value = tmp.freightChargeOut;
      }
    }
    if (id === "freightChargeOut") {
      if (tmp.freightCost !== 0) {
        tmp.freightMarkup = parseFloat((((tmp.freightChargeOut - tmp.freightCost) / tmp.freightCost) * 100).toFixed(2));
        freightMarkupRef.current.value = tmp.freightMarkup;
      }
    }
    setFreightData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderAddFreightModal = () => {
    return (
      <Transition.Root show={addFreightModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddFreightModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Freight Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 py-2 mt-2">
                        <div key="vendor" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="vendor" className="pb-1 text-xs text-gray-600 uppercase">
                            Vendor
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="vendor"
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightCost" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightCost" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Cost
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightCost"
                            ref={freightCostRef}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightMarkup" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightMarkup" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Markup
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightMarkup"
                            ref={freightMarkupRef}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightChargeOut" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightChargeOut" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Charge Out
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightChargeOut"
                            ref={freightChargeOutRef}
                            onChange={changeFreightData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeAddFreightModal()} />
                    <PrimaryButton label="Add Freight Fee" callback={() => addNewFreight()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAddMiscModal = () => {
    setAddMiscModal(false);
    setMiscData({
      miscType: "",
      partNo: "",
      description: "",
      quantity: 0,
      chargeOutPrice: 0,
      miscId: "",
    });
  };

  const addNewMisc = () => {
    let failed = false;

    if (miscData.miscType === "") {
      toast.error("Please select a fee type");
      failed = true;
    } else if (miscData.description === "") {
      toast.error("Please enter a description");
      failed = true;
    } else if (miscData.quantity === 0) {
      toast.error("Please enter a quantity");
      failed = true;
    } else if (miscData.chargeOutPrice === 0) {
      toast.error("Please enter a charge out price");
      failed = true;
    }

    if (!failed) {
      setDummyLoading(true);
      let tmp = quote;
      let toAdd = miscData;
      toAdd.miscId = uuidv4();
      tmp.misc.push(toAdd);
      setQuote(tmp);
      closeAddMiscModal();
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const changeMiscData = (e) => {
    setDummyLoading(true);
    let { id, value } = e.target;
    let tmp = miscData;
    if (id === "quantity" || id === "chargeOutPrice") {
      value = parseFloat(value);
    }
    tmp[id] = value;
    setMiscData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderAddMiscModal = () => {
    return (
      <Transition.Root show={addMiscModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddMiscModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Misc Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 py-2 mt-2">
                        <div key="parkingType" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="parkingType" className="pb-1 text-xs text-gray-600 uppercase">
                            Fee Type
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="miscType"
                            defaultValue=""
                            onChange={changeMiscData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="service" value="service">
                              Service
                            </option>
                            <option key="part" value="part">
                              Part
                            </option>
                          </select>
                        </div>
                        <div key="partNo" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
                            Part No.
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="partNo"
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="description"
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="quantity" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="quantity" className="pb-1 text-xs text-gray-600 uppercase">
                            Quantity
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="quantity"
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="chargeOutPrice" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="chargeOutPrice" className="pb-1 text-xs text-gray-600 uppercase">
                            Charge Out Price
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="chargeOutPrice"
                            onChange={changeMiscData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeAddMiscModal()} />
                    <PrimaryButton label="Add Misc Fee" callback={() => addNewMisc()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const removeLabor = (id) => {
    setDummyLoading(true);
    let tmp = quote;
    tmp.labor = tmp.labor.filter((l) => l.laborId !== id);
    setQuote(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const removePart = (id) => {
    setDummyLoading(true);
    let tmp = quote;
    tmp.parts = tmp.parts.filter((p) => p.uniqueId !== id);
    setQuote(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const removeParking = (id) => {
    setDummyLoading(true);
    let tmp = quote;
    tmp.parking = tmp.parking.filter((p) => p.parkingId !== id);
    setQuote(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const removeLoadbank = (id) => {
    setDummyLoading(true);
    let tmp = quote;
    tmp.loadbank = tmp.loadbank.filter((l) => l.loadbankId !== id);
    setQuote(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const removeFreight = (id) => {
    setDummyLoading(true);
    let tmp = quote;
    tmp.freight = tmp.freight.filter((f) => f.freightId !== id);
    setQuote(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const removeMisc = (id) => {
    setDummyLoading(true);
    let tmp = quote;
    tmp.misc = tmp.misc.filter((m) => m.miscId !== id);
    setQuote(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const closeLaborEdit = () => {
    setEditLaborModal(false);
    setLaborData({
      laborId: "",
      rate: "",
      description: "",
      hours: 0,
      perHour: "",
      extPrice: 0,
    });
  };

  const openLaborEdit = (id) => {
    let lbr = _.find(quote.labor, { laborId: id });
    if (lbr) {
      setLaborData(lbr);
      setTimeout(() => setEditLaborModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const submitLaborEdit = () => {
    setDummyLoading(true);
    let tmp = quote;
    let lbrIndex = _.findIndex(tmp.labor, { laborId: laborData.laborId });
    let toAdd = laborData;
    toAdd.perHour = rates.find((rt) => rt.rateId === laborData.rate).rate;
    if (parseFloat(quote.laborDiscount) > 0) {
      toAdd.perHour = parseFloat((toAdd.perHour * ((100 - parseFloat(quote.laborDiscount)) / 100)).toFixed(2));
    }
    toAdd.extPrice = toAdd.perHour * toAdd.hours;
    tmp.labor[lbrIndex] = toAdd;
    setQuote(tmp);
    closeLaborEdit();
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderEditLaborModal = () => {
    return (
      <Transition.Root show={editLaborModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeLaborEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-md sm:px-6">
                  <div>
                    <div className="text-center">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit labor entry
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="rate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="rate" className="pb-1 text-xs text-gray-600 uppercase">
                            Labor Rate
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            id="rate"
                            defaultValue={laborData.rate}
                            onChange={changeLaborData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            {rates.map((rt) => (
                              <option key={rt.rateId} value={rt.rateId}>
                                {rt.laborCode} - {rt.description} | {formatCurrency(rt.rate)}/hr
                              </option>
                            ))}
                          </select>
                        </div>
                        <div key="hours" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="hours" className="pb-1 text-xs text-gray-600 uppercase">
                            Hours
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="number"
                            id="hours"
                            defaultValue={laborData.hours}
                            placeholder="How many hours of labor is being quoted?"
                            onChange={changeLaborData}
                          />
                        </div>
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Labor Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="text"
                            id="description"
                            defaultValue={laborData.description}
                            placeholder="Describe the labor being quoted"
                            onChange={changeLaborData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    {quote.quoteStatus === "draft" ? (
                      <>
                        <SecondaryButton label="Cancel" callback={() => closeLaborEdit()} />
                        <PrimaryButton label="Save" callback={() => submitLaborEdit()} />
                      </>
                    ) : (
                      <SecondaryButton label="Close" callback={() => closeLaborEdit()} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const openPartEdit = (id) => {
    let prt = _.find(quote.parts, { uniqueId: id });
    if (prt) {
      setEditPartData(prt);
      setTimeout(() => setEditPartModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const closePartEdit = () => {
    setEditPartModal(false);
    setEditPartData({
      quantity: 0,
      partNo: "",
      partId: "",
      category: "",
      description: "",
      cost: 0,
      pricePerPart: 0,
      extPrice: 0,
      uniqueId: "",
    });
  };

  const submitPartEdit = () => {
    setDummyLoading(true);
    let tmp = quote;
    let lbrIndex = _.findIndex(tmp.parts, { uniqueId: editPartData.uniqueId });
    let toAdd = editPartData;
    tmp.parts[lbrIndex] = toAdd;
    setQuote(tmp);
    closePartEdit();
    setTimeout(() => setDummyLoading(false), 700);
  };

  const changeEditPartData = (e) => {
    setDummyLoading(true);
    let { id, value } = e.target;
    value = parseFloat(value);
    if (value !== null && value > 0) {
      let tmp = editPartData;
      tmp[id] = value;
      tmp.extPrice = parseFloat((tmp.quantity * tmp.pricePerPart).toFixed(2));
      setEditPartData(tmp);
      setTimeout(() => setDummyLoading(false), 700);
    } else {
      toast.error("Please enter a valid value");
    }
  };

  const renderPartEditModal = () => {
    return (
      <Transition.Root show={editPartModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closePartEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Part
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div className="flex flex-row items-center justify-between w-full pt-3 mt-3 mb-1 border-t border-gray-300">
                          <p className="text-sm font-semibold uppercase">Part No.</p>
                          <p className="uppercase">{editPartData.partNo}</p>
                        </div>
                        <div className="flex flex-row items-center justify-between w-full mb-1">
                          <p className="text-sm font-semibold uppercase">Category</p>
                          <p className="uppercase">{editPartData.category}</p>
                        </div>
                        <div className="flex flex-row items-center justify-between w-full mb-1">
                          <p className="text-sm font-semibold uppercase">Description</p>
                          <p className="uppercase">{editPartData.description}</p>
                        </div>
                        <div className="flex flex-row items-center justify-between w-full mb-1">
                          <p className="text-sm font-semibold uppercase">Cost</p>
                          <p className="uppercase">{formatCurrency(editPartData.cost)}</p>
                        </div>
                        <div key="pricePerPart" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="pricePerPart" className="pb-1 text-xs text-gray-600 uppercase">
                            Price Per Part
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="number"
                            id="pricePerPart"
                            defaultValue={editPartData.pricePerPart}
                            onChange={changeEditPartData}
                            min={0}
                          />
                        </div>
                        <div key="quantity" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="quantity" className="pb-1 text-xs text-gray-600 uppercase">
                            Quantity
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="number"
                            id="quantity"
                            defaultValue={editPartData.quantity}
                            onChange={changeEditPartData}
                            min={0}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    {quote.quoteStatus === "draft" ? (
                      <>
                        <SecondaryButton label="Cancel" callback={() => closePartEdit()} />
                        <PrimaryButton label="Save" callback={() => submitPartEdit()} />
                      </>
                    ) : (
                      <SecondaryButton label="Close" callback={() => closePartEdit()} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const openParkingEdit = (id) => {
    let prk = _.find(quote.parking, { parkingId: id });
    if (prk) {
      setParkingData(prk);
      setTimeout(() => setEditParkingModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const closeParkingEdit = () => {
    setEditParkingModal(false);
    setParkingData({
      parkingType: "",
      increment: "",
      startDate: "",
      endDate: "",
      unitCharge: 0,
      quantity: 0,
      totalCharge: 0,
      parkingId: "",
    });
  };

  const submitParkingEdit = () => {
    setDummyLoading(true);
    let tmp = quote;
    let lbrIndex = _.findIndex(tmp.parking, {
      parkingId: parkingData.parkingId,
    });
    let toAdd = parkingData;
    tmp.parking[lbrIndex] = toAdd;
    setQuote(tmp);
    closeParkingEdit();
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderParkingEditModal = () => {
    return (
      <Transition.Root show={editParkingModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeParkingEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Parking/Storage Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="parkingType" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="parkingType" className="pb-1 text-xs text-gray-600 uppercase">
                            Fee Type
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            id="parkingType"
                            defaultValue={parkingData.parkingType}
                            onChange={changeParkingData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="parking" value="parking">
                              Parking
                            </option>
                            <option key="storage" value="storage">
                              Storage
                            </option>
                          </select>
                        </div>
                        <div key="startDate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="startDate" className="pb-1 text-xs text-gray-600 uppercase">
                            Start Date
                          </label>
                          <DatePicker
                            onChange={(date, dateString) => changeParkingStart(dateString)}
                            defaultValue={dayjs(parkingData.startDate)}
                            style={{ width: "100%" }}
                            className="disabled:bg-gray-100/80"
                            format={"MM/DD/YYYY"}
                            disabled={quote.quoteStatus !== "draft"}
                          />
                        </div>
                        <div key="endDate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="endDate" className="pb-1 text-xs text-gray-600 uppercase">
                            End Date
                          </label>
                          <DatePicker
                            onChange={(date, dateString) => changeParkingEnd(dateString)}
                            defaultValue={dayjs(parkingData.endDate)}
                            style={{ width: "100%" }}
                            format={"MM/DD/YYYY"}
                            className="disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                          />
                        </div>
                        <div key="increment" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="increment" className="pb-1 text-xs text-gray-600 uppercase">
                            Charge increment
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            id="increment"
                            defaultValue={parkingData.increment}
                            onChange={changeParkingData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="daily" value="daily">
                              Daily
                            </option>
                            <option key="weekly" value="weekly">
                              Weekly
                            </option>
                            <option key="monthly" value="monthly">
                              Monthly
                            </option>
                            <option key="annually" value="annually">
                              Annually
                            </option>
                          </select>
                        </div>
                        <div key="unitCharge" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="unitCharge" className="pb-1 text-xs text-gray-600 uppercase">
                            Unit Charge
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="number"
                            id="unitCharge"
                            defaultValue={parkingData.unitCharge}
                            onChange={changeParkingData}
                          />
                        </div>
                        <div className="flex flex-col items-end justify-center w-full gap-1 px-1 py-2 border-gray-300 border-y">
                          <p className="text-sm uppercase">
                            Quantity: <span className="font-semibold">{parkingData.quantity}</span>
                          </p>
                          <p className="text-sm uppercase">
                            Total: <span className="font-semibold">{formatCurrency(parkingData.totalCharge)}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    {quote.quoteStatus === "draft" ? (
                      <>
                        <SecondaryButton label="Cancel" callback={() => closeParkingEdit()} />
                        <PrimaryButton label="Save" callback={() => submitParkingEdit()} />
                      </>
                    ) : (
                      <SecondaryButton label="Close" callback={() => closeParkingEdit()} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const openFreightEdit = (id) => {
    let frt = _.find(quote.freight, { freightId: id });
    if (frt) {
      setFreightData(frt);
      setTimeout(() => setEditFreightModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const closeFreightEdit = () => {
    setEditFreightModal(false);
    setFreightData({
      poNumber: "",
      freightCost: 0,
      freightMarkup: 0,
      freightChargeOut: 0,
      vendor: "",
      freightId: "",
    });
  };

  const submitFreightEdit = () => {
    setDummyLoading(true);
    let tmp = quote;
    let lbrIndex = _.findIndex(tmp.freight, {
      freightId: freightData.freightId,
    });
    let toAdd = freightData;
    tmp.freight[lbrIndex] = toAdd;
    setQuote(tmp);
    closeFreightEdit();
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderFreightEditModal = () => {
    return (
      <Transition.Root show={editFreightModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeFreightEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Freight Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 py-2 mt-2">
                        <div key="vendor" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="vendor" className="pb-1 text-xs text-gray-600 uppercase">
                            Vendor
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="text"
                            id="vendor"
                            defaultValue={freightData.vendor}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightCost" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightCost" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Cost
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="number"
                            id="freightCost"
                            ref={freightCostRef}
                            defaultValue={freightData.freightCost}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightMarkup" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightMarkup" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Markup
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="number"
                            id="freightMarkup"
                            ref={freightMarkupRef}
                            defaultValue={freightData.freightMarkup}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightChargeOut" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightChargeOut" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Charge Out
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="number"
                            id="freightChargeOut"
                            ref={freightChargeOutRef}
                            defaultValue={freightData.freightChargeOut}
                            onChange={changeFreightData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    {quote.quoteStatus === "draft" ? (
                      <>
                        <SecondaryButton label="Cancel" callback={() => closeFreightEdit()} />
                        <PrimaryButton label="Save" callback={() => submitFreightEdit()} />
                      </>
                    ) : (
                      <SecondaryButton label="Close" callback={() => closeFreightEdit()} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const openLoadbankEdit = (id) => {
    let frt = _.find(quote.loadbank, { loadbankId: id });
    if (frt) {
      setLoadbankData(frt);
      setTimeout(() => setEditLoadbankModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const closeLoadbankEdit = () => {
    setEditLoadbankModal(false);
    setLoadbankData({
      description: "",
      unitCharge: 0,
      loadbankId: "",
    });
  };

  const submitLoadbankEdit = () => {
    setDummyLoading(true);
    let tmp = quote;
    let lbrIndex = _.findIndex(tmp.loadbank, {
      loadbankId: loadbankData.loadbankId,
    });
    let toAdd = loadbankData;
    tmp.loadbank[lbrIndex] = toAdd;
    setQuote(tmp);
    closeLoadbankEdit();
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderLoadbankEditModal = () => {
    return (
      <Transition.Root show={editLoadbankModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeLoadbankEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Loadbank Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="text"
                            id="description"
                            defaultValue={loadbankData.description}
                            onChange={changeLoadbankData}
                          />
                        </div>
                        <div key="unitCharge" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="unitCharge" className="pb-1 text-xs text-gray-600 uppercase">
                            Loadbank Fee
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="number"
                            id="unitCharge"
                            defaultValue={loadbankData.unitCharge}
                            onChange={changeLoadbankData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    {quote.quoteStatus === "draft" ? (
                      <>
                        <SecondaryButton label="Cancel" callback={() => closeLoadbankEdit()} />
                        <PrimaryButton label="Save" callback={() => submitLoadbankEdit()} />
                      </>
                    ) : (
                      <SecondaryButton label="Close" callback={() => closeLoadbankEdit()} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const openMiscEdit = (id) => {
    let frt = _.find(quote.misc, { miscId: id });
    if (frt) {
      setMiscData(frt);
      setTimeout(() => setEditMiscModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const closeMiscEdit = () => {
    setEditMiscModal(false);
    setMiscData({
      miscType: "",
      partNo: "",
      description: "",
      quantity: 0,
      chargeOutPrice: 0,
      miscId: "",
    });
  };

  const submitMiscEdit = () => {
    setDummyLoading(true);
    let tmp = quote;
    let lbrIndex = _.findIndex(tmp.misc, {
      miscId: miscData.miscId,
    });
    let toAdd = miscData;
    tmp.misc[lbrIndex] = toAdd;
    setQuote(tmp);
    closeMiscEdit();
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderMiscEditModal = () => {
    return (
      <Transition.Root show={editMiscModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeMiscEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Misc Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 py-2 mt-2">
                        <div key="parkingType" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="parkingType" className="pb-1 text-xs text-gray-600 uppercase">
                            Fee Type
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            id="miscType"
                            defaultValue={miscData.miscType}
                            onChange={changeMiscData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="service" value="service">
                              Service
                            </option>
                            <option key="part" value="part">
                              Part
                            </option>
                          </select>
                        </div>
                        <div key="partNo" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
                            Part No.
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="text"
                            id="partNo"
                            defaultValue={miscData.partNo}
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="text"
                            id="description"
                            defaultValue={miscData.description}
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="quantity" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="quantity" className="pb-1 text-xs text-gray-600 uppercase">
                            Quantity
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="text"
                            id="quantity"
                            defaultValue={miscData.quantity}
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="chargeOutPrice" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="chargeOutPrice" className="pb-1 text-xs text-gray-600 uppercase">
                            Charge Out Price
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100/80"
                            disabled={quote.quoteStatus !== "draft"}
                            type="number"
                            id="chargeOutPrice"
                            defaultValue={miscData.chargeOutPrice}
                            onChange={changeMiscData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    {quote.quoteStatus === "draft" ? (
                      <>
                        <SecondaryButton label="Cancel" callback={() => closeMiscEdit()} />
                        <PrimaryButton label="Save" callback={() => submitMiscEdit()} />
                      </>
                    ) : (
                      <SecondaryButton label="Close" callback={() => closeMiscEdit()} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const saveQuote = () => {
    handleSubmit(onSubmit)();
  };

  const saveAndSubmitForReview = () => {
    handleSubmit(onSubmitForReview)();
  };

  const printPreview = async (detail) => {
    try {
      if (quote.quoteStatus === "draft" || quote.quoteStatus === "approval" || quote.quoteStatus === "returned" || quote.quoteStatus === "void") {
        let doc = generateEstimatePreview({ quote, customer, rates, equipment }, detail);
        doc.setProperties({
          title: `Estimate Preview - ${quote.quoteNo}`,
          subject: `Estimate Preview - ${quote.quoteNo}`,
          author: "Hypertek Solutions LLC",
          keywords: "",
          creator: "contact@hypertek.dev",
        });
        window.open(doc.output("bloburl"), "_blank");
      } else {
        let doc = await generateEstimate({ quote, customer, rates, equipment }, detail);
        doc.setProperties({
          title: `Estimate - ${quote.quoteNo}`,
          subject: `Estimate - ${quote.quoteNo}`,
          author: "Hypertek Solutions LLC",
          keywords: "",
          creator: "contact@hypertek.dev",
        });
        window.open(doc.output("bloburl"), "_blank");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderEquipmentType = (type) => {
    switch (type) {
      case "generator":
        return "Generator";
      case "pressureWasher":
        return "Pressure Washer";
      case "truck":
        return "Truck";
      case "trailer":
        return "Trailer";
      case "welder":
        return "Welder";
      case "airCompressor":
        return "Air Compressor";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const renderFuelType = (type) => {
    switch (type) {
      case "diesel":
        return "Diesel";
      case "gasoline":
        return "Gasoline";
      case "naturalGas":
        return "Natural Gas";
      case "propane":
        return "Propane";
      case "biFuel":
        return "Bi-Fuel";
      case "electricity":
        return "Electricity";
      default:
        return "Other";
    }
  };

  const renderEquipmentStatus = (status) => {
    switch (status) {
      case "availableForUse":
        return "Available For Use";
      case "inoperable":
        return "Inoperable";
      case "retired":
        return "Retired";
      default:
        return "Unknown";
    }
  };

  const renderPhoneNumber = (number) => {
    let toRet = "(";
    toRet += number.slice(0, 3);
    toRet += ") ";
    toRet += number.slice(3, 6);
    toRet += "-";
    toRet += number.slice(6, 10);
    return toRet;
  };

  const eqSteps = [
    { name: "Basic Info | Step 1", href: "#", status: "current" },
    { name: "Details | Step 2", href: "#", status: "upcoming" },
    { name: "Engine | Step 3", href: "#", status: "upcoming" },
    { name: "ATS | Step 4", href: "#", status: "upcoming" },
    { name: "Contact Info | Step 5", href: "#", status: "upcoming" },
    { name: "Notes | Step 6", href: "#", status: "upcoming" },
  ];

  const renderNewEquipmentModal = () => {
    return (
      <Transition.Root show={addEquipmentModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddEquipment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-0 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl">
                  <div>
                    <div>
                      <div className="flex flex-row items-center justify-between w-full px-4 pb-3 border-b border-gray-300">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Add New Equipment
                        </Dialog.Title>
                        <nav className="flex items-center justify-center" aria-label="Progress">
                          <p className="text-sm font-medium">
                            Step {step + 1} of {eqSteps.length}
                          </p>
                          <ol role="list" className="flex items-center ml-5 space-x-5">
                            {eqSteps.map((currentStep, index) => (
                              <li key={currentStep.name}>
                                {index < step ? (
                                  <p className="block h-2.5 w-2.5 rounded-full bg-blue-600 hover:bg-blue-900">
                                    <span className="sr-only">{currentStep.name}</span>
                                  </p>
                                ) : index === step ? (
                                  <p className="relative flex items-center justify-center" aria-current="step">
                                    <span className="absolute flex w-5 h-5 p-px" aria-hidden="true">
                                      <span className="w-full h-full bg-blue-200 rounded-full" />
                                    </span>
                                    <span className="relative block h-2.5 w-2.5 rounded-full bg-blue-600" aria-hidden="true" />
                                    <span className="sr-only">{currentStep.name}</span>
                                  </p>
                                ) : (
                                  <p className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">
                                    <span className="sr-only">{currentStep.name}</span>
                                  </p>
                                )}
                              </li>
                            ))}
                          </ol>
                        </nav>
                        <button
                          type="button"
                          className="text-gray-400 bg-white rounded-md hover:text-gray-500"
                          // onClick={() => closeAddEquipment()}
                        >
                          <span className="sr-only">Close</span>
                          <Xmark className="w-6 h-6" aria-hidden="true" />
                        </button>
                      </div>
                      {renderNewEqStep()}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderNewEqStep = () => {
    if (step === 0) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
            <FormController onSubmit={submitStep} fields={newEquipmentBasics} values={eqBasics} buttonText={"Next"} fullWidth={true} />
          </div>
        </div>
      );
    } else if (step === 1) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
            <FormController onSubmit={submitStep} fields={newEquipmentDetails} values={eqDetails} buttonText={"Next"} fullWidth={true} />
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
            <FormController onSubmit={submitStep} fields={newEquipmentEngine} values={eqEngine} buttonText={"Next"} fullWidth={true} />
          </div>
        </div>
      );
    } else if (step === 3) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
            <FormController onSubmit={submitStep} fields={newEquipmentATS} values={eqATS} buttonText={"Next"} fullWidth={true} />
          </div>
        </div>
      );
    } else if (step === 4) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
            <FormController onSubmit={submitStep} fields={newEquipmentContact} values={eqContact} buttonText={"Next"} fullWidth={true} />
          </div>
        </div>
      );
    } else if (step === 5) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
            <p className="w-full text-lg font-bold text-center text-gray-500 uppercase">New Equipment Overview</p>
            <div className="grid w-full grid-cols-2 gap-4 px-4 gap-y-4">
              <div className="flex flex-col items-start justify-start gap-2">
                <div className="w-full py-2 border-b border-gray-300">
                  <p className="text-sm font-bold text-gray-400 uppercase">Basic Information</p>
                </div>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Customer's Equipment ID: </span>
                  {eqBasics.customerEquipId}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Equipment Type: </span>
                  {renderEquipmentType(eqBasics.equipmentType)}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Power: </span>
                  {eqBasics.power} {eqBasics.pwrUnit}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal capitalize">{eqBasics.milesUnit}: </span>
                  {eqBasics.miles} {eqBasics.milesUnit}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Fuel: </span>
                  {renderFuelType(eqBasics.fuelType)}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Location: </span>
                  {eqBasics.location}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Sales Tax ZIP: </span>
                  {eqBasics.taxZip}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start gap-2">
                <div className="w-full py-2 border-b border-gray-300">
                  <p className="text-sm font-bold text-gray-400 uppercase">Detailed Information</p>
                </div>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Make: </span>
                  {eqDetails.make}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Model: </span>
                  {eqDetails.model}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Spec No: </span>
                  {eqDetails.specNo}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Serial No: </span>
                  {eqDetails.serialNo}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Arrangement: </span>
                  {eqDetails.arrangement}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Equipment Status: </span>
                  {renderEquipmentStatus(eqDetails.status)}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start gap-2">
                <div className="w-full py-2 border-b border-gray-300">
                  <p className="text-sm font-bold text-gray-400 uppercase">Engine Information</p>
                </div>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Make: </span>
                  {eqEngine.make}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Model: </span>
                  {eqEngine.model}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Spec No: </span>
                  {eqEngine.specNo}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Serial No: </span>
                  {eqEngine.serialNo}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Code No: </span>
                  {eqEngine.codeNo}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start gap-2">
                <div className="w-full py-2 border-b border-gray-300">
                  <p className="text-sm font-bold text-gray-400 uppercase">ATS Information</p>
                </div>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Make: </span>
                  {eqATS.make}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Model: </span>
                  {eqATS.model}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Spec No: </span>
                  {eqATS.specNo}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Serial No: </span>
                  {eqATS.serialNo}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Code No: </span>
                  {eqATS.codeNo}
                </p>
              </div>
            </div>
            <div className="grid w-full grid-cols-3 gap-4 px-4 gap-y-4">
              <div className="w-full col-span-3 py-2 border-b border-gray-300">
                <p className="text-sm font-bold text-gray-400 uppercase">Contact Information</p>
              </div>
              <p className="text-sm font-semibold text-gray-900">
                <span className="font-normal">First Name: </span>
                {eqContact.firstName}
              </p>
              <p className="text-sm font-semibold text-gray-900">
                <span className="font-normal">Last Name: </span>
                {eqContact.lastName}
              </p>
              <p className="text-sm font-semibold text-gray-900">
                <span className="font-normal">Phone Number: </span>
                {renderPhoneNumber(eqContact.phoneNumber)}
              </p>
            </div>
            <div className="flex flex-row items-center justify-end w-full gap-2 px-4 pt-3 mt-4 border-t border-gray-300">
              <SecondaryButton label="Cancel" callback={() => closeAddEquipment()} />
              {step === 5 && <PrimaryButton label="Add Equipment" callback={() => submitAddEquipment()} />}
            </div>
          </div>
        </div>
      );
    }
  };

  const submitStep = (data) => {
    if (step === 0) {
      setEqBasics(data);
      setStep(1);
    } else if (step === 1) {
      setEqDetails(data);
      setStep(2);
    } else if (step === 2) {
      setEqEngine(data);
      setStep(3);
    } else if (step === 3) {
      setEqATS(data);
      setStep(4);
    } else if (step === 4) {
      setEqContact(data);
      setStep(5);
    }
  };

  const closeAddEquipment = () => {
    setAddEquipmentModal(false);
    setStep(0);
    setEqBasics({
      customerEquipId: "",
      equipmentType: "",
      power: "",
      miles: "",
      pwrUnit: "",
      milesUnit: "",
      fuelType: "",
      location: "",
      taxZip: "",
    });
    setEqDetails({
      make: "",
      model: "",
      specNo: "",
      serialNo: "",
      arrangement: "",
      status: "",
    });
    setEqEngine({
      make: "",
      model: "",
      specNo: "",
      serialNo: "",
      codeNo: "",
    });
    setEqATS({
      make: "",
      model: "",
      specNo: "",
      serialNo: "",
      codeNo: "",
    });
    setEqContact({
      firstName: "",
      lastName: "",
      phoneNumber: "",
    });
  };

  const submitAddEquipment = () => {
    let toSend = eqBasics;
    toSend.details = eqDetails;
    toSend.engine = eqEngine;
    toSend.ats = eqATS;
    toSend.contact = eqContact;
    delete toSend.modifiedFields;
    delete toSend.formModified;
    delete toSend.details.modifiedFields;
    delete toSend.details.formModified;
    delete toSend.engine.formModified;
    delete toSend.engine.modifiedFields;
    delete toSend.ats.formModified;
    delete toSend.ats.modifiedFields;
    delete toSend.contact.formModified;
    delete toSend.contact.modifiedFields;
    setLoading(true);
    AddEquipmentToCustomer(toSend, customer.customerId)
      .then((res) => {
        toast.success("Equipment added successfully");
        closeAddEquipment();
        let tmp = equipment;
        tmp.push(res.data.equipment);
        setEquipment(tmp);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please try again.");
        setLoading(false);
      });
  };

  const attachToExistingJob = () => {
    setQuoteJobModal(true);
    //
  };

  const updateQuoteJob = () => {
    setLoading(true);
    UpdateQuoteJob(quoteId, { jobId: quote.jobId, jobNo: quote.jobNo })
      .then((res) => {
        toast.success("Quote job updated successfully");
        closeQuoteJob();
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please try again.");
        setLoading(false);
      });
  };

  const closeQuoteJob = () => {
    setQuoteJobModal(false);
    setJobs([]);
  };

  const handleJobSearch = (data) => {
    if (data?.length > 2) {
      setDummyLoading(true);
      QueryJobs({
        jobNo: data,
        page: 0,
        limit: 12,
      })
        .then((res) => {
          setJobs(res.data.results);
          setTimeout(() => setDummyLoading(false), 500);
        })
        .catch((err) => {
          toast.error("Failed to fetch jobs");
          setJobs([]);
          setTimeout(() => setDummyLoading(false), 500);
        });
    }
  };

  const selectQuoteJob = (jobId) => {
    if (jobId) {
      let job = jobs.find((j) => j.jobId === jobId);
      if (job) {
        setDummyLoading(true);
        let tmp = quote;
        quote.jobId = jobId;
        quote.jobNo = job.jobNo;
        setQuote(tmp);
        setJobs([job]);
        setTimeout(() => setDummyLoading(false), 500);
      }
    } else {
      setDummyLoading(true);
      setJobs([]);
      setQuote({ ...quote, jobId: null, jobNo: null });
      setTimeout(() => setDummyLoading(false), 500);
    }
  };

  const renderQuoteJobModal = () => {
    return (
      <Modal
        open={quoteJobModal}
        centered
        destroyOnClose
        width={600}
        title={validate(quote.jobId) ? "Change Quote Job" : "Pick Quote Job"}
        onCancel={() => closeQuoteJob()}
        onOk={() => updateQuoteJob()}
      >
        <div className="flex flex-col items-start justify-start w-full mt-4">
          <label htmlFor="jobNo" className="pb-1 text-xs text-gray-600 uppercase">
            Job Number
          </label>
          <Select
            placeholder="Search Jobs"
            onChange={(v) => selectQuoteJob(v)}
            value={quote.jobId || null}
            options={(jobs || []).map((p) => ({
              label: p.jobNo,
              value: p.jobId,
            }))}
            className="w-full mb-2 font-sans"
            notFoundContent="No jobs found, start typing to search"
            controls={false}
            showSearch
            filterOption={false}
            defaultActiveFirstOption={false}
            onSearch={handleJobSearch}
            allowClear
          />
        </div>
      </Modal>
    );
  };

  const openPOCheck = () => {
    setLoading(true);
    GetQuotePOData(quoteId)
      .then((res) => {
        setPoPrefillData(res.data);
        setPoCheckModal(true);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error loading PO data");
        setPoPrefillData(null);
        setPoCheckModal(false);
      });
  };

  const renderQuoteButtons = () => {
    if (quote.quoteStatus === "draft") {
      return (
        <>
          <SecondaryButton label={validate(quote.jobId) ? "Change Quote Job" : "Pick Quote Job"} callback={() => attachToExistingJob()} />
          <SecondaryButton label="Check/Prefill PO Data" callback={() => openPOCheck()} />
          <SecondaryButton
            label="Edit footer notes"
            callback={() => {
              setFooterVals(quote.footer);
              setFooterModal(true);
            }}
          />
          <SecondaryButton label="Save Changes" callback={() => saveQuote()} />
          <PrimaryButton label="Save and Submit for Review" callback={() => saveAndSubmitForReview()} />
        </>
      );
    } else if (quote.quoteStatus === "approval") {
      return (
        <>
          <SecondaryButton label={validate(quote.jobId) ? "Change Quote Job" : "Pick Quote Job"} callback={() => attachToExistingJob()} />
          <SecondaryButton label="Check/Prefill PO Data" callback={() => openPOCheck()} />
          <SecondaryButton label="Revise Quote" callback={() => reviseQuote()} />
          <PrimaryButton label="Approve or Reject" callback={() => navigate(`/quotes/${quoteId}/review`)} />
        </>
      );
    } else if (quote.quoteStatus === "returned") {
      return (
        <>
          {quote.review.comment.length > 0 ? (
            <PrimaryButton label="View Comments and Revise" callback={() => setCommentModal(true)} />
          ) : (
            <PrimaryButton label="Revise Quote" callback={() => reviseQuote()} />
          )}
        </>
      );
    } else if (quote.quoteStatus === "approved" || quote.quoteStatus === "sent") {
      return (
        <>
          <SecondaryButton label="Check/Prefill PO Data" callback={() => openPOCheck()} />
          <SecondaryButton label={validate(quote.jobId) ? "Change Quote Job" : "Pick Quote Job"} callback={() => attachToExistingJob()} />
          {quote.review.comment.length > 0 && <SecondaryButton label="View Approval Comments" callback={() => setCommentModal(true)} />}
          <SecondaryButton label="Send Quote to Client" callback={() => setSendToClientModal(true)} />
          <button
            onClick={() => setDeclineQuoteModal(true)}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-5 py-2 text-xs font-semibold uppercase text-white shadow-sm hover:bg-red-500"
          >
            Reject Quote
          </button>
          <PrimaryButton label="Accept Quote" callback={() => setAcceptQuoteModal(true)} />
        </>
      );
    } else if (quote.quoteStatus === "declined") {
      return <SecondaryButton label="ReQuote" callback={() => reQuote()} />;
    } else if (quote.quoteStatus === "accepted") {
      return (
        <>
          <SecondaryButton label="Check/Prefill PO Data" callback={() => openPOCheck()} />
          <SecondaryButton label="ReQuote" callback={() => reQuote()} />
          <PrimaryButton label="Create a Job for the Quote" callback={() => createAJob()} />
        </>
      );
    } else if (quote.quoteStatus === "void") {
      //
    } else if (quote.quoteStatus === "jobCreated") {
      return <SecondaryButton label="ReQuote" callback={() => reQuote()} />;
    }
  };

  const reQuote = () => {
    setLoading(true);
    ReQuote(quoteId)
      .then((res) => {
        toast.success("New quote created successfully");
        setTimeout(() => {
          navigate(`/quotes/${res.data.quoteId}`);
        }, 2500);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while creating a new quote, please try again");
        setLoading(false);
      });
  };

  const createAJob = () => {
    navigate(`/quotes/${quoteId}/jobFromQuote`);
  };

  const reviseQuote = () => {
    setLoading(true);
    ReviseQuote(quoteId)
      .then((res) => {
        setSubmitForReviewModal(false);
        setCommentModal(false);
        toast.success("Quote revised successfully");
        GetOneQuote(quoteId)
          .then((res) => {
            let quoteData = res.data.quote;
            setQuote(quoteData);
            setShopFeeVal(quoteData.shopFees || 0);
            setCustomer(res.data.customer);
            setLocation(res.data.location);
            setEquipment(res.data.equipment);
            GetRates()
              .then((res) => {
                setRates(res.data);
                AdvancedPartsQuery({ search, limit: 10, page: 0 })
                  .then((res) => {
                    setRawPartsData(res.data.results);
                    setPartsData(res.data.results);
                    setTotal(res.data.total);
                    setPage(res.data.page);
                    setSize(res.data.size);
                    GetDepartments()
                      .then((res) => {
                        setDepartments(res.data);
                        setTimeout(() => setLoading(false), 700);
                      })
                      .catch((err) => {
                        toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                        setTimeout(() => setLoading(false), 700);
                      });
                  })
                  .catch((err) => {
                    toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                    setTimeout(() => setLoading(false), 700);
                  });
              })
              .catch((err) => {
                toast.error("Error loading labor rates");
                setLoading(false);
              });
          })
          .catch((err) => {
            toast.error("Error loading quote");
            setTimeout(() => {
              navigate("/quotes");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "An error occurred while revising this quote, please try again");
        setLoading(false);
      });
  };

  const renderSubmitForReviewModal = () => {
    return (
      <Transition.Root show={submitForReviewModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setSubmitForReviewModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                  <div>
                    <div className="flex items-center justify-center w-12 h-12 mx-auto bg-blue-100 rounded-full">
                      <DeliveryTruck className="w-6 h-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Submit Quote for Review
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="pb-2 mb-2 text-sm text-gray-500 border-b border-gray-300">Please select the urgency level of the quote review</p>
                        <div className="flex flex-col items-center justify-start w-full gap-2 my-3">
                          <button
                            onClick={() => submitForReview("low")}
                            className="w-full px-5 py-2 text-xs font-semibold text-gray-900 uppercase rounded-md shadow-sm hover:bg-gray-50 ring-1 rind-inset ring-gray-300"
                          >
                            Low Importance
                          </button>
                          <p className="pb-2 mb-2 text-sm text-gray-500 border-b border-gray-300">
                            Management will receive an in-app notification indicating that this quote requires review
                          </p>
                          <button
                            onClick={() => submitForReview("medium")}
                            className="w-full px-5 py-2 text-xs font-semibold text-gray-900 uppercase rounded-md shadow-sm hover:bg-gray-50 ring-1 rind-inset ring-gray-300"
                          >
                            Medium Importance
                          </button>
                          <p className="pb-2 mb-2 text-sm text-gray-500 border-b border-gray-300">
                            Management will receive an in-app notification and an email indicating that this quote requires review
                          </p>
                          <button
                            onClick={() => submitForReview("high")}
                            className="w-full px-5 py-2 text-xs font-semibold text-gray-900 uppercase rounded-md shadow-sm hover:bg-gray-50 ring-1 rind-inset ring-gray-300"
                          >
                            High Importance
                          </button>
                          <p className="pb-2 mb-2 text-sm text-gray-500 border-b border-gray-300">
                            Management will receive an in-app notification, an email, and a text message indicating that this quote requires review
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-center mt-5 sm:mt-6">
                    <SecondaryButton label="Cancel" callback={() => setSubmitForReviewModal(false)} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const submitForReview = (importance) => {
    setLoading(true);
    SubmitQuoteForReview(quoteId, { importance: importance })
      .then((res) => {
        setSubmitForReviewModal(false);
        toast.success("Quote submitted for review successfully");
        GetOneQuote(quoteId)
          .then((res) => {
            let quoteData = res.data.quote;
            setQuote(quoteData);
            setShopFeeVal(quoteData.shopFees || 0);
            setCustomer(res.data.customer);
            setLocation(res.data.location);
            setEquipment(res.data.equipment);
            GetRates()
              .then((res) => {
                setRates(res.data);
                AdvancedPartsQuery({ search, limit: 10, page: 0 })
                  .then((res) => {
                    setRawPartsData(res.data.results);
                    setPartsData(res.data.results);
                    setTotal(res.data.total);
                    setPage(res.data.page);
                    setSize(res.data.size);
                    GetDepartments()
                      .then((res) => {
                        setDepartments(res.data);
                        setTimeout(() => setLoading(false), 700);
                      })
                      .catch((err) => {
                        toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                        setTimeout(() => setLoading(false), 700);
                      });
                  })
                  .catch((err) => {
                    toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                    setTimeout(() => setLoading(false), 700);
                  });
              })
              .catch((err) => {
                toast.error("Error loading labor rates");
                setLoading(false);
              });
          })
          .catch((err) => {
            toast.error("Error loading quote");
            setTimeout(() => {
              navigate("/quotes");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error("Error submitting quote for review");
        setLoading(false);
      });
  };

  const renderReviewCommentModal = () => {
    return (
      <Transition.Root show={commentModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setCommentModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    {quote.quoteStatus === "returned" ? (
                      <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                        <Xmark className="w-6 h-6 text-red-600" aria-hidden="true" />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-blue-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                        <DeliveryTruck className="w-6 h-6 text-blue-600" aria-hidden="true" />
                      </div>
                    )}
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {quote.quoteStatus === "returned"
                          ? "Your quote has been rejected by a manager"
                          : quote.quoteStatus === "approved"
                            ? "Your quote has been approved by a manager"
                            : "Review Comments"}
                      </Dialog.Title>
                      <div className="mt-2">
                        {quote.review.comment.length > 0 ? (
                          <p className="text-sm text-gray-500">
                            <span className="mb-1 font-semibold text-gray-900">Manager's Comment:</span>
                            <br />
                            {quote.review.comment}
                          </p>
                        ) : (
                          <p className="text-sm text-gray-500">No comments were left by the manager</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end gap-2 mt-5">
                    <SecondaryButton label="Close" callback={() => setCommentModal(false)} />
                    {quote.quoteStatus === "returned" && <PrimaryButton label="Revise Quote" callback={() => reviseQuote()} />}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderSendQuoteToClientModal = () => {
    return (
      <Transition.Root show={sendToClientModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeSendToClient()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-blue-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                      <SendMail className="w-6 h-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="w-full mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Send Quote to the Client
                      </Dialog.Title>
                      <div className="flex flex-col items-start justify-start w-full gap-2 my-2">
                        <p>We will send a unique link to access the client portal. How should we send it?</p>
                        <div className="flex flex-row items-center justify-between w-full my-3">
                          <div className="relative flex items-start w-1/2">
                            <div className="flex items-center h-6">
                              <input
                                id="sendViaEmail"
                                aria-describedby="send-via-email-checkbox"
                                name="sendViaEmail"
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-600"
                                onChange={(e) => setSendEmailCheck(e.target.checked)}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label htmlFor="sendViaEmail" className="font-medium text-gray-900">
                                Send via Email
                              </label>
                            </div>
                          </div>
                          <div className="w-full">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:bg-gray-100/80 disabled:cursor-not-allowed"
                              placeholder="client@mail.com"
                              disabled={!sendEmailCheck}
                              ref={sendEmailRef}
                              onChange={(e) => setSendEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between w-full my-3">
                          <div className="relative flex items-start w-1/2">
                            <div className="flex items-center h-6">
                              <input
                                id="sendViaText"
                                aria-describedby="send-via-text-checkbox"
                                name="sendViaText"
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-600"
                                onChange={(e) => setSendTextCheck(e.target.checked)}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label htmlFor="sendViaText" className="font-medium text-gray-900">
                                Send via Text
                              </label>
                            </div>
                          </div>
                          <div className="w-full">
                            <input
                              type="tel"
                              name="phoneNumber"
                              id="phoneNumber"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:bg-gray-100/80 disabled:cursor-not-allowed"
                              placeholder="(888) 888-8888"
                              disabled={!sendTextCheck}
                              ref={sendTextRef}
                              onChange={(e) => updateSendText(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeSendToClient()} />
                    <PrimaryButton label="Send" callback={() => submitSendToClient()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeSendToClient = () => {
    setSendToClientModal(false);
    setSendEmail(null);
    setSendEmailCheck(false);
    setSendText(null);
    setSendTextCheck(false);
  };

  const submitSendToClient = () => {
    let failed = false;
    let emailValid = false;
    let phoneValid = false;
    if (!sendEmailCheck && !sendTextCheck) {
      toast.error("Please select at least one method of sending the quote");
      failed = true;
    }
    if (sendEmailCheck) {
      if (validator.isEmail(sendEmail)) {
        emailValid = true;
      } else {
        toast.error("Please enter a valid email");
        failed = true;
      }
    }
    if (sendTextCheck) {
      if (testPhone(sendText)) {
        phoneValid = true;
      } else {
        toast.error("Please enter a valid phone number");
        failed = true;
      }
    }
    if (!failed) {
      let toSend = {
        sendEmail: sendEmailCheck,
        sendText: sendTextCheck,
        email: sendEmail,
        phone: sendText,
      };
      SendQuoteToClient(quoteId, toSend)
        .then((res) => {
          toast.success("Quote sent successfully");
          closeSendToClient();
          setLoading(true);
          GetOneQuote(quoteId)
            .then((res) => {
              let quoteData = res.data.quote;
              setQuote(quoteData);
              setShopFeeVal(quoteData.shopFees || 0);
              setCustomer(res.data.customer);
              setLocation(res.data.location);
              setEquipment(res.data.equipment);
              GetRates()
                .then((res) => {
                  setRates(res.data);
                  AdvancedPartsQuery({ search, limit: 10, page: 0 })
                    .then((res) => {
                      setRawPartsData(res.data.results);
                      setPartsData(res.data.results);
                      setTotal(res.data.total);
                      setPage(res.data.page);
                      setSize(res.data.size);
                      GetDepartments()
                        .then((res) => {
                          setDepartments(res.data);
                          setTimeout(() => setLoading(false), 700);
                        })
                        .catch((err) => {
                          toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                          setTimeout(() => setLoading(false), 700);
                        });
                    })
                    .catch((err) => {
                      toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                      setTimeout(() => setLoading(false), 700);
                    });
                })
                .catch((err) => {
                  toast.error("Error loading labor rates");
                  setLoading(false);
                });
            })
            .catch((err) => {
              toast.error("Error loading quote");
              setTimeout(() => {
                navigate("/quotes");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error sending quote to the customer, please try again.");
        });
    }
  };

  const updateSendText = (value) => {
    let previousValue = sendText;
    if (value.length < 10) {
      if (isNaN(value)) {
        toast.error("Please enter a valid number");
        setSendText(previousValue);
        sendTextRef.current.value = previousValue;
      } else {
        setSendText(value);
      }
    } else if (value.length === 10) {
      if (testPhone(value)) {
        setSendText(value);
      } else {
        toast.error("Please enter a valid number");
        setSendText(previousValue);
        sendTextRef.current.value = previousValue;
      }
    } else if (value.length > 10) {
      toast.error("Please enter a valid number");
      setSendText(previousValue);
      sendTextRef.current.value = previousValue;
    }
  };

  const renderDeclineQuoteModal = () => {
    return (
      <Transition.Root show={declineQuoteModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeDeclineQuote()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div className="flex-col items-center w-full gap-3 sm:flex">
                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                      <Xmark className="w-6 h-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="w-full mt-3 text-center sm:ml-4 sm:mt-0">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Reject Quote
                      </Dialog.Title>
                      <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
                        <FormController onSubmit={submitDeclineQuote} fields={rejectQuoteForm} values={rejectData} buttonText={"Submit"} fullWidth={true} />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const submitDeclineQuote = (data) => {
    setLoading(true);
    DeclineQuote(quoteId, data)
      .then((res) => {
        toast.success("Quote rejected successfully");
        closeDeclineQuote();
        GetOneQuote(quoteId)
          .then((res) => {
            let quoteData = res.data.quote;
            setQuote(quoteData);
            setShopFeeVal(quoteData.shopFees || 0);
            setCustomer(res.data.customer);
            setLocation(res.data.location);
            setEquipment(res.data.equipment);
            GetRates()
              .then((res) => {
                setRates(res.data);
                AdvancedPartsQuery({ search, limit: 10, page: 0 })
                  .then((res) => {
                    setRawPartsData(res.data.results);
                    setPartsData(res.data.results);
                    setTotal(res.data.total);
                    setPage(res.data.page);
                    setSize(res.data.size);
                    GetDepartments()
                      .then((res) => {
                        setDepartments(res.data);
                        setTimeout(() => setLoading(false), 700);
                      })
                      .catch((err) => {
                        toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                        setTimeout(() => setLoading(false), 700);
                      });
                  })
                  .catch((err) => {
                    toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                    setTimeout(() => setLoading(false), 700);
                  });
              })
              .catch((err) => {
                toast.error("Error loading labor rates");
                setLoading(false);
              });
          })
          .catch((err) => {
            toast.error("Error loading quote");
            setTimeout(() => {
              navigate("/quotes");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error rejecting the quote, please try again.");
      });
  };

  const closeDeclineQuote = () => {
    setDeclineQuoteModal(false);
    setRejectData({
      rejectedBy: "",
      approvalMethod: "",
      reasonForRejection: "",
    });
  };

  const renderAcceptQuoteModal = () => {
    return (
      <Transition.Root show={acceptQuoteModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeAcceptQuote()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div className="flex-col items-center w-full gap-3 sm:flex">
                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-blue-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                      <DoubleCheck className="w-6 h-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="w-full mt-3 text-center sm:ml-4 sm:mt-0">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Accept Quote
                      </Dialog.Title>
                      <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
                        <FormController onSubmit={submitAcceptQuote} fields={acceptQuoteForm} values={acceptData} buttonText={"Submit"} fullWidth={true} />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const submitAcceptQuote = (data) => {
    setLoading(true);
    AcceptQuote(quoteId, data)
      .then((res) => {
        toast.success("Quote accepted successfully");
        closeAcceptQuote();
        GetOneQuote(quoteId)
          .then((res) => {
            let quoteData = res.data.quote;
            setQuote(quoteData);
            setShopFeeVal(quoteData.shopFees || 0);
            setCustomer(res.data.customer);
            setLocation(res.data.location);
            setEquipment(res.data.equipment);
            GetRates()
              .then((res) => {
                setRates(res.data);
                AdvancedPartsQuery({ search, limit: 10, page: 0 })
                  .then((res) => {
                    setRawPartsData(res.data.results);
                    setPartsData(res.data.results);
                    setTotal(res.data.total);
                    setPage(res.data.page);
                    setSize(res.data.size);
                    GetDepartments()
                      .then((res) => {
                        setDepartments(res.data);
                        setTimeout(() => setLoading(false), 700);
                      })
                      .catch((err) => {
                        toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                        setTimeout(() => setLoading(false), 700);
                      });
                  })
                  .catch((err) => {
                    toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                    setTimeout(() => setLoading(false), 700);
                  });
              })
              .catch((err) => {
                toast.error("Error loading labor rates");
                setLoading(false);
              });
          })
          .catch((err) => {
            toast.error("Error loading quote");
            setTimeout(() => {
              navigate("/quotes");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error accepting the quote, please try again.");
      });
  };

  const closeAcceptQuote = () => {
    setAcceptQuoteModal(false);
    setAcceptData({
      approvedBy: "",
      title: "",
      approvalMethod: "",
      poNumber: "",
      acceptanceNotes: "",
    });
  };

  const updateShopFee = () => {
    setLoading(true);
    UpdateQuoteShopFee(quoteId, {
      shopFee: shopFeeVal,
    })
      .then((res) => {
        closeShopFeeModal();
        setQuote(res.data);
        setShopFeeVal(res.data.shopFees || 0);
        toast.success("Shop Fee updated successfully!");
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating shop fee, please try again.");
        setTimeout(() => setLoading(false), 700);
      });
  };

  const renderShopFeeModal = () => {
    return (
      <Modal open={shopFeeModal} onCancel={closeShopFeeModal} title="Update Shop Fee" destroyOnClose centered width={750} onOk={() => updateShopFee()}>
        <div className="w-full py-4">
          <div className="w-full">
            <label htmlFor="shopFee" className="block text-sm font-medium leading-6 text-gray-900">
              Shop Fee - Fixed Amount
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <InputNumber
                placeholder="Enter the shop fee amount"
                name="shopFee"
                onChange={(v) => setShopFeeVal(v)}
                value={shopFeeVal}
                className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                disabled={usePercentage}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <span className="text-gray-500 sm:text-sm" id="shopFee-currency">
                  USD
                </span>
              </div>
            </div>
            <div className="flex items-center justify-end w-full mt-2">
              <SecondaryButton label="Set to 3%" callback={() => setShopFeeVal(calculateShopFee())} />
            </div>
          </div>
          <div className="flex items-center justify-between w-full py-4 mt-2 border-gray-200 border-y">
            <p className="block text-sm font-medium leading-6 text-gray-900">Use Percentage</p>
            <Switch onChange={(v) => setUsePercentage(v)} />
          </div>
          <div className="w-full mt-2">
            <label htmlFor="shopFee" className="block text-sm font-medium leading-6 text-gray-900">
              Shop Fee - % of Subtotal
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <InputNumber
                placeholder="Enter the desired percentage"
                name="shopFee"
                onChange={(v) => calculatePercentage(v)}
                className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                disabled={!usePercentage}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <span className="text-gray-500 sm:text-sm" id="shopFee-currency">
                  %
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const closeShopFeeModal = () => {
    setShopFeeModal(false);
    setUsePercentage(false);
  };

  const calculateShopFee = () => {
    let lbr = calculateLabor();
    let shopFee = parseFloat((lbr * (3 / 100)).toFixed(2));
    return shopFee || 0;
  };

  const renderFooterModal = () => {
    return (
      <Modal
        open={footerModal}
        onCancel={() => closeFooterModal()}
        onOk={() => submitFooter()}
        title="Footer Notes"
        centered
        destroyOnClose
        okText={"Update Footer Notes"}
        width={600}
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 py-4">
          <p className="font-semibold">Enter Your Footer Notes (terms will be added automatically)</p>
          <Input
            placeholder="Enter your note"
            onBlur={(e) => updateFooterVals(0, e.target.value)}
            defaultValue={footerVals[0]}
            className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
          />
          <Input
            placeholder="Enter your note"
            onBlur={(e) => updateFooterVals(1, e.target.value)}
            defaultValue={footerVals[1]}
            className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
          />
          <Input
            placeholder="Enter your note"
            onBlur={(e) => updateFooterVals(2, e.target.value)}
            defaultValue={footerVals[2]}
            className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
          />
        </div>
      </Modal>
    );
  };

  const updateFooterVals = (index, value) => {
    setDummyLoading(true);
    let tmp = footerVals;
    tmp[index] = value;
    setFooterVals(tmp);
    setTimeout(() => setDummyLoading(false), 500);
  };

  const closeFooterModal = () => {
    setFooterModal(false);
    setFooterVals([]);
  };

  const submitFooter = () => {
    let payload = footerVals.filter((val) => val.length > 0);
    setLoading(true);
    UpdateQuoteFooter(quoteId, { footer: payload })
      .then((res) => {
        closeFooterModal();
        setQuote(res.data);
        toast.success("Footer notes updated successfully!");
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating footer noter, please try again.");
        setTimeout(() => setLoading(false), 700);
      });
  };

  const closePOCheckModal = () => {
    setPoCheckModal(false);
    setPoPrefillData(null);
  };

  const renderAccount = (accountId) => {
    let acc = poPrefillData.accounts.find((acc) => acc.accountId === accountId);
    if (acc) {
      return acc.accountNo + " | " + acc.description;
    }
    return "SELECTED, BUT CAN'T BE RETRIEVED";
  };

  const openEditPO = (po) => {
    setPoEditData({
      poId: po.poId,
      vendorId: po.vendorId,
      vendorName: po.vendorName,
      vendorCode: po.vendorCode,
      poAccount: po.poAccount,
      poNumber: po.poNumber,
    });
    setPoEditDrawer(true);
  };

  const renderPOLine = (po) => {
    return (
      <div key={po.poId} className="flex flex-col items-start justify-start w-full gap-2 px-4 py-2 border rounded-md border-slate-200 bg-slate-50">
        <div className="flex flex-row items-start justify-between w-full">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex flex-row items-center justify-start w-full gap-2">
              <p>
                <span className="font-semibold">Vendor:</span> {validate(po.vendorId) ? po.vendorCode + " | " + po.vendorName : "MISSING"}
              </p>
              {po.vendorId === "" && <WarningCircle className="w-5 h-5 text-red-500" />}
            </div>
            <div className="flex flex-row items-center justify-start w-full gap-2">
              <p>
                <span className="font-semibold">PO Account:</span> {validate(po.poAccount) ? renderAccount(po.poAccount) : "MISSING"}
              </p>
              {po.poAccount === "" && <WarningCircle className="w-5 h-5 text-red-500" />}
            </div>
          </div>
          <div className="flex flex-row items-center justify-end w-fit">
            <SecondaryButton label="Edit info" callback={() => openEditPO(po)} className="flex justify-center text-center w-28" />
          </div>
        </div>
        <p className="font-semibold">Items</p>
        <div className="flex flex-col items-start justify-start w-full gap-2 px-4 py-2 border border-gray-100">
          <div className="grid items-center justify-between w-full grid-cols-5 gap-2">
            <p className="font-semibold text-left">Part No</p>
            <p className="font-semibold text-left">Description</p>
            <p className="font-semibold text-right">Quantity</p>
            <p className="font-semibold text-right">Price</p>
            <p className="font-semibold text-right">Total</p>
          </div>
          {po.items.map((item) => (
            <div key={item.itemId} className="grid items-center justify-between w-full grid-cols-5 gap-2">
              <p className="text-left">{item.partNo}</p>
              <p className="text-left truncate">{item.description}</p>
              <p className="text-right">{item.quantity}</p>
              <p className="text-right">{formatCurrency(item.price)}</p>
              <p className="text-right">{formatCurrency(item.total)}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPOCheckModal = () => {
    return (
      <Modal
        open={poCheckModal}
        onCancel={() => closePOCheckModal()}
        onOk={() => updatePOPrefill()}
        title={"Prefill PO Data"}
        centered
        width={750}
        destroyOnClose
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 py-4">
          <p>Pre Purchase Orders for this quote</p>
          <div className="flex flex-col items-start justify-start w-full gap-2 py-4 mt-3 border-t border-gray-300">
            {(poPrefillData?.purchaseOrders || []).map((po) => renderPOLine(po))}
          </div>
        </div>
      </Modal>
    );
  };

  const updatePOPrefill = () => {
    setLoading(true);
    UpdateQuotePOData(quoteId, poPrefillData.purchaseOrders)
      .then((res) => {
        closePOCheckModal();
        setTimeout(() => setLoading(false), 700);
        toast.success("PO Prefill data updated successfully!");
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating prefill data, please try again.");
        setLoading(false);
      });
  };

  const closePOEditDrawer = () => {
    setPoEditDrawer(false);
    setPoEditData({
      poId: "",
      vendorId: "",
      vendorName: "",
      vendorCode: "",
      poAccount: "",
    });
  };

  const updatePoVendor = (v) => {
    setDummyLoading(true);
    let tmp = poEditData;
    if (v) {
      let qry = vendors.find((c) => c.vendorId === v);
      if (qry) {
        tmp.vendorId = v;
        tmp.vendorName = qry.vendorName;
        tmp.vendorCode = qry.vendorCode;
      } else {
        tmp.vendorId = "";
        tmp.vendorName = "";
        tmp.vendorCode = "";
      }
    } else {
      tmp.vendorId = "";
      tmp.vendorName = "";
      tmp.vendorCode = "";
    }
    setPoEditData(tmp);
    setTimeout(() => setDummyLoading(false), 500);
  };

  const handleVendorSearch = (query) => {
    if (query?.length > 2) {
      QueryVendors(query)
        .then((res) => {
          setVendors(res.data.results);
        })
        .catch((err) => {
          toast.error("Vendor search failed, please try again");
          setVendors([]);
        });
    }
  };

  const renderPOEditDrawer = () => {
    return (
      <Drawer open={poEditDrawer} onCancel={() => closePOEditDrawer()} title={"Edit PRE-PO Data"} centered width={500} destroyOnClose>
        <div className="flex flex-col items-start justify-start w-full h-full gap-2">
          <p className="text-lg font-semibold">Edit info for PRE-PO {poEditData.poNumber}</p>
          <div className="flex flex-col items-start justify-start w-full pt-4 border-t border-gray-200">
            <label htmlFor="vendor" className="pb-1 text-xs text-gray-600 uppercase">
              Vendor
            </label>
            <Select
              placeholder="Search Vendors"
              onChange={(v) => updatePoVendor(v)}
              value={poEditData?.vendorId || null}
              options={(vendors || []).map((p) => ({
                label: `${p.vendorCode} | ${p.vendorName && p.vendorName.length > 0 ? p.vendorName : ""}`,
                value: p.vendorId,
              }))}
              className="w-full mb-2 font-sans"
              notFoundContent="No vendors found, start typing to search"
              controls={false}
              showSearch
              filterOption={false}
              defaultActiveFirstOption={false}
              onSearch={handleVendorSearch}
              allowClear
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full">
            <label htmlFor="AP Account" className="pb-1 text-xs text-gray-600 uppercase">
              AP Account
            </label>
            <Select
              placeholder="Search Accounts"
              onChange={(v) => setPoEditData((prev) => ({ ...prev, poAccount: v }))}
              value={poEditData?.poAccount || null}
              options={(poPrefillData?.accounts || []).map((p) => ({
                label: `${p.accountNo} | ${p.description}`,
                value: p.accountId,
              }))}
              className="w-full mb-2 font-sans"
              notFoundContent="No AP accounts found, start typing to search"
              controls={false}
              showSearch
              filterOption={filterOption}
              defaultActiveFirstOption={false}
              allowClear
            />
          </div>
          <div className="flex items-center justify-end w-full gap-2 mt-auto">
            <SecondaryButton label="Cancel" callback={() => closePOEditDrawer()} />
            <PrimaryButton label="Update PO" callback={() => updatePO()} />
          </div>
        </div>
      </Drawer>
    );
  };

  const updatePO = () => {
    let tmp = poPrefillData;
    let index = tmp.purchaseOrders.findIndex((po) => po.poId === poEditData.poId);
    if (index > -1) {
      tmp.purchaseOrders[index].vendorId = poEditData.vendorId;
      tmp.purchaseOrders[index].poAccount = poEditData.poAccount;
      tmp.purchaseOrders[index].vendorName = poEditData.vendorName;
      tmp.purchaseOrders[index].vendorCode = poEditData.vendorCode;
    }
    setPoPrefillData(tmp);
    closePOEditDrawer();
    toast.success("PO updated successfully! Make sure to save your changes once you're done!");
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full px-1">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Quote {quote.quoteNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-between w-full pb-3 text-sm border-b border-gray-300">
            <div className="flex flex-col items-start justify-center">
              <p className="text-base">
                Quote #<span className="font-semibold">{quote.quoteNo}</span>
              </p>
              <p className="text-base">
                Prepared for{" "}
                <span className="font-semibold">
                  {customer.customerCode}
                  {customer.company.length > 0 ? " | " + customer.company : ""}
                </span>
              </p>
            </div>
            <div className="flex flex-col items-end justify-center">
              <p>
                Quote started on <span className="font-semibold">{dayjs(quote.createdAt || new Date()).format("MM/DD/YYYY")}</span>
              </p>
              <p>
                Prepared by <span className="font-semibold">{quote.createdBy}</span>
              </p>
              <div className="flex flex-row items-center justify-end gap-2">
                <p>
                  Status: <span className="font-semibold">{renderQuoteStatus()}</span>
                </p>
                {validate(quote.jobId) && (
                  <button
                    className="px-2 py-1 duration-150 border border-gray-300 rounded-md hover:bg-gray-300 hover:border-black"
                    onClick={() => window.open(`/jobs/${quote.jobId}`, "_blank")}
                  >
                    <div className="flex flex-row items-center justify-center gap-2">
                      <p className="text-xs font-medium uppercase">View Job</p>
                      <OpenNewWindow className="w-3 h-3" />
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between w-full gap-5 mt-5">{renderUpperForm()}</div>
          <div className="flex flex-col items-start justify-start w-full gap-5 mt-5">
            <div className="flex flex-row items-center justify-between w-full pb-3 border-b border-gray-300">
              <p className="text-lg font-semibold">Equipment</p>
              {quote.quoteStatus === "draft" && (
                <button
                  onClick={() => setEquipmentDrawer(true)}
                  className="px-4 py-2 text-xs font-medium text-gray-800 uppercase duration-150 rounded-md bg-gray-300/75 hover:text-white hover:bg-gray-400"
                >
                  Manage Equipment
                </button>
              )}
            </div>
            {quote.equipment.length === 0 ? (
              <div className="flex flex-row items-center justify-center w-full pb-3">
                <p className="text-sm font-semibold text-gray-700 uppercase">No Equipment Was Added to This Quote</p>
              </div>
            ) : (
              <table className="w-full max-w-[95%] mx-auto divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      Type
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Equipment ID
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Equipment Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Equipment Make
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {quote.equipment.map((eqId) => {
                    let eq = equipment.find((e) => e.equipmentId === eqId);
                    return (
                      <tr key={eq.equipmentId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-3">{renderEquipType(eq.equipmentType)}</td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {eq.customerEquipId && eq.customerEquipId.length > 0 ? eq.customerEquipId : "Not Provided"}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{renderEquipStatus(eq.details.status)}</td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{eq.details.make}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-5 mt-5">
            <div className="flex flex-row items-center justify-between w-full pb-3 border-b border-gray-300">
              <p className="text-lg font-semibold">Labor</p>
              {quote.quoteStatus === "draft" && (
                <button
                  onClick={() => setAddLaborModal(true)}
                  className="px-4 py-2 text-xs font-medium text-gray-800 uppercase duration-150 rounded-md bg-gray-300/75 hover:text-white hover:bg-gray-400"
                >
                  Add Labor
                </button>
              )}
            </div>
            {quote.labor.length === 0 ? (
              <div className="flex flex-row items-center justify-center w-full pb-3">
                <p className="text-sm font-semibold text-gray-700 uppercase">No Labor Was Added to This Quote</p>
              </div>
            ) : (
              <table className="w-full max-w-[95%] mx-auto divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      Rate
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Description
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Hours
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Per Hour
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Ext. Price
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <span className="sr-only">Edit/Remove</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {quote.labor.map((lbr) => (
                    <tr key={lbr.laborId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-3">
                        {rates.find((rt) => rt.rateId === lbr.rate).laborCode}
                      </td>
                      <td className="w-1/2 px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{lbr.description}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{lbr.hours}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{formatCurrency(lbr.perHour)}/hr</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{formatCurrency(lbr.extPrice)}</td>
                      {quote.quoteStatus === "draft" ? (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <EditPencil
                            onClick={() => openLaborEdit(lbr.laborId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-blue-500"
                          />
                          <Trash onClick={() => removeLabor(lbr.laborId)} className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-red-500" />
                        </td>
                      ) : (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <Eye onClick={() => openLaborEdit(lbr.laborId)} className="w-6 h-6 text-gray-400 duration-150 cursor-pointer hover:text-blue-500" />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-5 mt-5">
            <div className="flex flex-row items-center justify-between w-full pb-3 border-b border-gray-300">
              <p className="text-lg font-semibold">Parts</p>
              {quote.quoteStatus === "draft" && (
                <button
                  onClick={() => setAddPartsModal(true)}
                  className="px-4 py-2 text-xs font-medium text-gray-800 uppercase duration-150 rounded-md bg-gray-300/75 hover:text-white hover:bg-gray-400"
                >
                  Add Parts
                </button>
              )}
            </div>
            {quote.parts.length === 0 ? (
              <div className="flex flex-row items-center justify-center w-full pb-3">
                <p className="text-sm font-semibold text-gray-700 uppercase">No Parts Were Added to This Quote</p>
              </div>
            ) : (
              <table className="w-full max-w-[95%] mx-auto divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      Description
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Part No.
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Cost
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Quantity
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Ext. Price
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Ext. Total
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <span className="sr-only">Edit/Remove</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {quote.parts.map((pt) => (
                    <tr key={pt.uniqueId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                      <td className="w-1/2 px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pt.description}</td>
                      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-3">{pt.partNo}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{formatCurrency(pt.cost)}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pt.quantity}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{formatCurrency(pt.pricePerPart)}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{formatCurrency(pt.extPrice)}</td>
                      {quote.quoteStatus === "draft" ? (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <EditPencil
                            onClick={() => openPartEdit(pt.uniqueId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-blue-500"
                          />
                          <Trash onClick={() => removePart(pt.uniqueId)} className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-red-500" />
                        </td>
                      ) : (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <Eye onClick={() => openPartEdit(pt.uniqueId)} className="w-6 h-6 text-gray-400 duration-150 cursor-pointer hover:text-blue-500" />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-5 mt-5">
            <div className="flex flex-row items-center justify-between w-full pb-3 border-b border-gray-300">
              <p className="text-lg font-semibold">Misc</p>
              {quote.quoteStatus === "draft" && (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex flex-row items-center justify-between px-4 py-2 text-xs font-medium text-gray-800 uppercase duration-150 rounded-md bg-gray-300/75 group-hover:text-white group-hover:bg-gray-400">
                      Add Misc Charges
                      <NavArrowDown className="w-5 h-5 ml-2 group" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-[1px] mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-1 py-1 ">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => setAddParkingModal(true)}
                              className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              Add Parking Charges
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => setAddLoadbankModal(true)}
                              className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              Add Loadbank Charges
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => setAddFreightModal(true)}
                              className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              Add Freight Charges
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => setAddMiscModal(true)}
                              className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              Add Misc Charges
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
            </div>
            {quote.parking.length === 0 && quote.loadbank.length === 0 && quote.freight.length === 0 && quote.misc.length === 0 ? (
              <div className="flex flex-row items-center justify-center w-full pb-3">
                <p className="text-sm font-semibold text-gray-700 uppercase">No Misc. Charges Added to This Quote</p>
              </div>
            ) : (
              <table className="w-full max-w-[95%] mx-auto divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      Description
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Price
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Quantity
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Ext. Price
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <span className="sr-only">Edit/Remove</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {quote.parking.map((pt) => (
                    <tr key={pt.parkingId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                      <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">
                        {pt.parkingType} | {pt.increment}
                      </td>
                      <td className="py-4 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-3">{formatCurrency(pt.unitCharge)}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pt.quantity}</td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{formatCurrency(pt.totalCharge)}</td>
                      {quote.quoteStatus === "draft" ? (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <EditPencil
                            onClick={() => openParkingEdit(pt.parkingId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-blue-500"
                          />
                          <Trash onClick={() => removeParking(pt.parkingId)} className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-red-500" />
                        </td>
                      ) : (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <Eye
                            onClick={() => openParkingEdit(pt.parkingId)}
                            className="w-6 h-6 text-gray-400 duration-150 cursor-pointer hover:text-blue-500"
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                  {quote.loadbank.map((pt) => (
                    <tr key={pt.loadbankId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                      <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">Loadbank Fee</td>
                      <td className="py-4 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-3">{formatCurrency(pt.unitCharge)}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">1</td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{formatCurrency(pt.unitCharge)}</td>
                      {quote.quoteStatus === "draft" ? (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <EditPencil
                            onClick={() => openLoadbankEdit(pt.loadbankId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-blue-500"
                          />
                          <Trash
                            onClick={() => removeLoadbank(pt.loadbankId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-red-500"
                          />
                        </td>
                      ) : (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <Eye
                            onClick={() => openLoadbankEdit(pt.loadbankId)}
                            className="w-6 h-6 text-gray-400 duration-150 cursor-pointer hover:text-blue-500"
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                  {quote.freight.map((pt) => (
                    <tr key={pt.freightId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                      <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">Freight | {pt.poNumber}</td>
                      <td className="py-4 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-3">{formatCurrency(pt.freightCost)}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">1</td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{formatCurrency(pt.freightChargeOut)}</td>
                      {quote.quoteStatus === "draft" ? (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <EditPencil
                            onClick={() => openFreightEdit(pt.freightId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-blue-500"
                          />
                          <Trash onClick={() => removeFreight(pt.freightId)} className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-red-500" />
                        </td>
                      ) : (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <Eye
                            onClick={() => openFreightEdit(pt.freightId)}
                            className="w-6 h-6 text-gray-400 duration-150 cursor-pointer hover:text-blue-500"
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                  {quote.misc.map((pt) => (
                    <tr key={pt.miscId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                      <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">Misc | {pt.miscType}</td>
                      <td className="py-4 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-3">{formatCurrency(pt.chargeOutPrice / pt.quantity)}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pt.quantity}</td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{formatCurrency(pt.chargeOutPrice)}</td>
                      {quote.quoteStatus === "draft" ? (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <EditPencil
                            onClick={() => openMiscEdit(pt.miscId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-blue-500"
                          />
                          <Trash onClick={() => removeMisc(pt.miscId)} className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-red-500" />
                        </td>
                      ) : (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <Eye onClick={() => openMiscEdit(pt.miscId)} className="w-6 h-6 text-gray-400 duration-150 cursor-pointer hover:text-blue-500" />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="flex items-start justify-between w-full gap-5 pt-3 mt-5 border-t border-gray-300">
            <div className="flex flex-col items-start justify-start w-1/3 gap-1">
              <p className="w-full pb-3 text-lg font-semibold border-b border-gray-200">Footer Notes</p>
              {quote.footer.map((note, index) => {
                return (
                  <p key={index} className="w-full text-sm font-medium uppercase">
                    {note}
                  </p>
                );
              })}
            </div>
            <div className="flex flex-col items-end justify-start w-1/3 max-w-[300px] gap-2">
              <div className="flex flex-row items-center justify-between w-full">
                <p className="font-bold">Labor Total:</p>
                <p className="font-semibold">{formatCurrency(calculateLabor())}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-full">
                <p className="font-bold">Parts Total:</p>
                <p className="font-semibold">{formatCurrency(calculateParts())}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-full">
                <p className="font-bold">Gas, Oil & Grease Total:</p>
                <p className="font-semibold">{formatCurrency(calculateGOG())}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-full">
                <p className="font-bold">Parking/Storage Total:</p>
                <p className="font-semibold">{formatCurrency(calculateParking())}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-full">
                <p className="font-bold">Loadbank Total:</p>
                <p className="font-semibold">{formatCurrency(calculateLoadbank())}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-full">
                <p className="font-bold">Freight Total:</p>
                <p className="font-semibold">{formatCurrency(calculateFreight())}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-full">
                <p className="font-bold">Misc Total:</p>
                <p className="font-semibold">{formatCurrency(calculateMisc())}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center justify-start gap-2">
                  <p className="font-bold">Shop Fees:</p>
                  {quote.quoteStatus === "draft" && (
                    <p
                      className="text-xs font-bold text-blue-500 transition-all duration-200 cursor-pointer hover:text-blue-700"
                      onClick={() => setShopFeeModal(true)}
                    >
                      EDIT
                    </p>
                  )}
                </div>
                <p className="font-semibold">{formatCurrency(quote.shopFees || 0)}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-full pt-2 mt-1 border-t border-gray-300">
                <p className="font-bold">TOTAL:</p>
                <p className="font-semibold">{formatCurrency(calculateTotal())}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-end w-full gap-5 px-2 pt-5 pb-5 my-5 border-t border-gray-300">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="flex flex-row items-center justify-between px-4 py-2 text-xs font-medium text-gray-900 uppercase duration-150 rounded-md ring-1 rind-inset ring-gray-300 group-hover:text-white group-hover:bg-gray-400">
                  Print Preview
                  <NavArrowDown className="w-5 h-5 ml-2 group" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-[1px] bottom-full mb-1 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => printPreview("none")}
                          className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Estimate with no details
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => printPreview("group")}
                          className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Estimate with group details
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => printPreview("all")}
                          className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Estimate with all details
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            {renderQuoteButtons()}
          </div>
          {renderEquipmentDrawer()}
          {renderAddLaborModal()}
          {renderAddPartsModal()}
          {renderAddParkingModal()}
          {renderAddLoadbankModal()}
          {renderAddFreightModal()}
          {renderAddMiscModal()}
          {renderEditLaborModal()}
          {renderPartEditModal()}
          {renderParkingEditModal()}
          {renderFreightEditModal()}
          {renderLoadbankEditModal()}
          {renderMiscEditModal()}
          {renderNewEquipmentModal()}
          {renderSubmitForReviewModal()}
          {renderReviewCommentModal()}
          {renderSendQuoteToClientModal()}
          {renderDeclineQuoteModal()}
          {renderAcceptQuoteModal()}
          {renderShopFeeModal()}
          {renderFooterModal()}
          {renderPOCheckModal()}
          {renderPOEditDrawer()}
          {renderQuoteJobModal()}
        </>
      )}
    </div>
  );
};
export default OpenQuote;
