import validator from "validator";

export const formatCurrency = (val) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(val);
};

export const formatCurrencyCompact = (val) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: "compact",
  }).format(val);
};

export const formatPhoneNumber = (number) => {
  if (validator.isMobilePhone(number)) {
    return number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  } else {
    return number;
  }
};

export const NORTH_AUGUSTA = "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f";

export const AUGUSTA = "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf";
