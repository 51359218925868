import validator from "validator";

const testZip = (val) => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
};

const testPhone = (val) => {
  return /^((\+1|1)?( |-)?)?(\(([2-9])(?:\d(?!\5)\d|(?!\5)\d\d)\)|([2-9])(?:\d(?!\6)\d|(?!\6)\d\d))( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(val);
};

export const employeeAccountForm = {
  employeeCode: {
    fieldName: "employeeCode",
    label: "Employee Code",
    inputType: "text",
    placeholder: "Employee Code",
    defaultValue: "",
    config: {
      required: "Employee code is required",
      validate: (value) => value.length >= 3 || "Employee code must be at least 3 characters long",
    },
  },
  firstName: {
    fieldName: "firstName",
    label: "First Name",
    inputType: "text",
    placeholder: "First Name",
    defaultValue: "",
    config: {
      required: "First name is required",
      validate: (value) => (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "First name must be at least 2 characters long",
    },
  },
  lastName: {
    fieldName: "lastName",
    label: "Last Name",
    inputType: "text",
    placeholder: "Last Name",
    defaultValue: "",
    config: {
      required: "Last name is required",
      validate: (value) => (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "Last name must be at least 2 characters long",
    },
  },
  address: {
    fieldName: "address",
    label: "Address",
    inputType: "text",
    placeholder: "Address",
    defaultValue: "",
    config: {
      required: "Employee's address is required",
      validate: (value) => value.length >= 3 || "Address must be at least 2 characters long",
    },
  },
  address2: {
    fieldName: "address2",
    label: "Apt/Suite/Unit",
    inputType: "text",
    placeholder: "Apt/Suite/Unit",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  city: {
    fieldName: "city",
    label: "City",
    inputType: "text",
    placeholder: "City",
    defaultValue: "",
    config: {
      required: "Employee's city is required",
      validate: (value) => (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "City must be at least 2 characters long",
    },
  },
  state: {
    fieldName: "state",
    label: "State",
    inputType: "state-picker",
    placeholder: "",
    defaultValue: "",
    config: {
      required: "Employee's state is required",
      validate: (value) => value.length === 2 || "Please select a valid state",
    },
  },
  zip: {
    fieldName: "zip",
    label: "ZIP Code",
    inputType: "number",
    placeholder: "ZIP Code",
    defaultValue: "",
    config: {
      required: "Employee's ZIP code is required",
      validate: (value) => testZip(value) || "Please enter a valid ZIP code",
    },
  },
  phoneNumber: {
    fieldName: "phoneNumber",
    label: "Phone Number",
    inputType: "phoneNumber",
    // inputType: "phone",
    placeholder: "Phone Number",
    defaultValue: "",
    config: {
      required: "Employee's phone number is required",
      validate: (value) => testPhone(value) || "Please enter a valid phone number",
    },
  },
  email: {
    fieldName: "email",
    label: "Email",
    inputType: "email",
    placeholder: "Email",
    defaultValue: "",
    config: {
      required: "Employee's email address is required",
      validate: (value) => validator.isEmail(value) || "Please enter a valid email address",
    },
  },
  emergencyContactName: {
    fieldName: "emergencyContact.name",
    label: "Emergency Contact's Name",
    inputType: "text",
    placeholder: "Name",
    defaultValue: "",
    // config: {
    //   required: "Employee's emergency contact information is required",
    //   validate: (value) =>
    //     value.length >= 3 || "Name must be at least 2 characters long",
    // },
    config: {
      required: "Employee's emergency contact information is required",
      validate: (value) => value.length >= 3 || "Contact's name must be at least 2 characters long",
    },
  },
  emergencyContactRelationship: {
    fieldName: "emergencyContact.relationship",
    label: "Relationship with the Emergency Contact",
    inputType: "text",
    placeholder: "Spouse, Parent, etc.",
    defaultValue: "",
    config: {
      required: "Employee's emergency contact information is required",
      validate: (value) => value.length >= 3 || "Relationship must be at least 2 characters long",
    },
  },
  emergencyContactPhone: {
    fieldName: "emergencyContact.phoneNumber",
    label: "Emergency Contact's Phone Number",
    inputType: "phone",
    placeholder: "Emergency Contact's Phone Number",
    defaultValue: "",
    config: {
      required: "Emergency Contact's phone number is required",
      validate: (value) => testPhone(value) || "Please enter a valid phone number",
    },
  },
  hiringDate: {
    fieldName: "hiringDate",
    label: "Hiring Date",
    inputType: "date-picker",
    placeholder: "Select a date",
    defaultValue: "",
    config: {
      required: "Hiring date is required",
      validate: (value) => value.length >= 3 || "Please select a date when this employee was hired",
    },
  },
  locationId: {
    fieldName: "locationId",
    label: "Location",
    inputType: "select",
    placeholder: "Select One",
    defaultValue: "",
    options: [
      { value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf", label: "Augusta, GA" },
      {
        value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
        label: "North Augusta, SC",
      },
    ],
    config: {
      required: "Location is required",
      validate: (value) => value.length >= 3 || "Please select a location",
    },
  },
  title: {
    fieldName: "title",
    label: "Title",
    inputType: "text",
    placeholder: "Title",
    defaultValue: "",
    config: {
      required: "Employee's title is required",
      validate: (value) => (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "Title must be at least 2 characters long",
    },
  },
  functionCategory: {
    fieldName: "functionCategory",
    label: "Function Category",
    inputType: "select",
    placeholder: "Select One",
    defaultValue: "",
    options: [
      { value: "administrative", label: "Administrative" },
      {
        value: "management",
        label: "Management",
      },
      { value: "technician", label: "Technician" },
    ],
    config: {
      required: "Function category is required",
      validate: (value) => value.length >= 3 || "Please select a function category",
    },
  },
  payRate: {
    fieldName: "payRate",
    label: "Pay rate ($)",
    inputType: "number",
    placeholder: "Pay rate",
    defaultValue: "0",
    config: {
      required: "Pay rate is required",
      validate: (value) => validator.isInt(value.toString()) || "Please enter a valid pay rate",
    },
  },
  payPeriod: {
    fieldName: "payPeriod",
    label: "Pay Period",
    inputType: "select",
    placeholder: "Select One",
    defaultValue: "",
    options: [
      { value: "hourly", label: "Hourly" },
      { value: "daily", label: "Daily" },
      { value: "weekly", label: "Weekly" },
      { value: "biWeekly", label: "Bi-Weekly" },
      { value: "monthly", label: "Monthly" },
      { value: "annually", label: "Annually" },
    ],
    config: {
      required: "Pay period is required",
      validate: (value) => value.length >= 3 || "Please select a pay period",
    },
  },
  commissioned: {
    fieldName: "commissioned",
    label: "Receives commission on sales?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  commissionRate: {
    fieldName: "commissionRate",
    label: "Commission rate (%)",
    inputType: "number",
    placeholder: "Commission rate",
    defaultValue: "",
    config: {
      required: "Commission rate is required",
      validate: (value) => validator.isInt(value.toString()) || "Please enter a valid commission rate",
    },
  },
};
