import validator from "validator";

const testZip = (val) => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
};

const testPhone = (val) => {
  return /^((\+1|1)?( |-)?)?(\(([2-9])(?:\d(?!\5)\d|(?!\5)\d\d)\)|([2-9])(?:\d(?!\6)\d|(?!\6)\d\d))( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(val);
};

export const newQuoteCustomer = {
  customerId: {
    fieldName: "customerId",
    label: "Customer",
    inputType: "customer-picker",
    placeholder: "",
    defaultValue: "",
    config: {
      required: "Customer is required",
      validate: (value) => value.length >= 3 || "Please select a customer",
    },
  },
};

export const newQuoteBasics = {
  customerId: {
    fieldName: "customerId",
    label: "Customer",
    inputType: "customer-picker",
    placeholder: "",
    defaultValue: "",
    config: {
      required: "Customer is required",
      validate: (value) => value.length >= 3 || "Please select a customer",
    },
  },
  location: {
    fieldName: "location",
    label: "Location",
    inputType: "select",
    placeholder: "Select One",
    defaultValue: "",
    options: [
      { value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf", label: "Augusta, GA" },
      {
        value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
        label: "North Augusta, SC",
      },
    ],
    config: {
      required: "Location is required",
      validate: (value) => value.length >= 3 || "Please select a location",
    },
  },
  quoteDate: {
    fieldName: "quoteDate",
    label: "Quote Date",
    inputType: "date-picker",
    placeholder: "Pick a date",
    defaultValue: "",
    config: {
      required: "Quote date is required",
      validate: (value) => value.length >= 3 || "Please select a date for the quote",
    },
  },
  description: {
    fieldName: "description",
    label: "Description",
    inputType: "text",
    placeholder: "Briefly describe the quote",
    defaultValue: "",
    config: {
      required: "Quote description is required",
      validate: (value) => value.length >= 3 || "Please enter a description the quote",
    },
  },
  details: {
    fieldName: "details",
    label: "Quote Details",
    inputType: "textarea",
    placeholder: "Enter the details of the quote",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  eoNotes: {
    fieldName: "eoNotes",
    label: "EO Notes",
    inputType: "text",
    placeholder: "Enter EO Notes",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const newQuoteDetails = {
  clientPO: {
    fieldName: "poNumber",
    label: "Client's PO Number",
    inputType: "text",
    placeholder: "Client's PO Number",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  laborDiscount: {
    fieldName: "laborDiscount",
    label: "Labor discount ($)",
    inputType: "number",
    placeholder: "Labor discount",
    defaultValue: "0",
    config: {
      required: "Labor discount is required",
      validate: (value) => validator.isInt(value.toString()) || "Please enter a labor discount",
    },
  },
  partsMarkup: {
    fieldName: "partsMarkup",
    label: "Parts Markup",
    inputType: "number",
    placeholder: "Parts Markup",
    defaultValue: "45",
    config: {
      required: "Parts markup is required",
      validate: (value) => validator.isInt(value.toString()) || "Please enter a valid parts markup",
    },
  },
  paymentTerms: {
    fieldName: "terms",
    label: "Payment Terms",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "net10", label: "NET10" },
      { value: "net20", label: "NET20" },
      { value: "net30", label: "NET30" },
      { value: "collectOnDelivery", label: "Collect on Delivery" },
      { value: "dueOnReceipt", label: "Due on Receipt" },
      { value: "prePay", label: "Pre-Pay" },
    ],
    defaultValue: "",
    config: {
      required: "Customer's payment terms are required",
      validate: (value) => value.length > 2 || "Please select payment terms for the customer",
    },
  },
  tax: {
    fieldName: "tax",
    label: "What's the customer's tax status?",
    inputType: "select",
    placeholder: "What's the customer's tax status?",
    options: [
      { value: "taxable", label: "Taxable" },
      { value: "gov", label: "Government Entity" },
      { value: "st5biz", label: "Business w/ ST5" },
      { value: "outOfState", label: "Out of State" },
    ],
    defaultValue: "",
    config: {
      required: "Customer's tax status is required",
      validate: (value) => value.length >= 3 || "Please select a tax status",
    },
  },
  taxZip: {
    fieldName: "salesTaxZIP",
    label: "Sales Tax ZIP Code",
    inputType: "number",
    placeholder: "Sales Tax ZIP Code",
    defaultValue: "",
    config: {
      required: "Customer's sales tax ZIP code is required",
      validate: (value) => testZip(value) || "Please enter a valid ZIP code",
    },
  },
};

export const rejectQuoteForm = {
  rejectedBy: {
    fieldName: "rejectedBy",
    label: "Rejected By",
    inputType: "text",
    placeholder: "Who rejected the quote?",
    defaultValue: "",
    config: {
      required: "Please enter the name of the person who rejected the quote",
      validate: (value) => value.length >= 3 || "Please enter the name of the person who rejected the quote",
    },
  },
  approvalMethod: {
    fieldName: "approvalMethod",
    label: "How did you receive the rejection?",
    inputType: "select",
    placeholder: "How did you receive the rejection?",
    options: [
      { value: "email", label: "Via Email" },
      { value: "text", label: "Via Text" },
      { value: "phone", label: "Via Phone Call" },
      { value: "inPerson", label: "In Person" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: "",
      validate: (value) => value.length >= 3 || "Please select an answer",
    },
  },
  reasonForRejection: {
    fieldName: "reasonForRejection",
    label: "Reason for Rejection",
    inputType: "textarea",
    placeholder: "Did they explain why they rejected the quote?",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const acceptQuoteForm = {
  approvedBy: {
    fieldName: "approvedBy",
    label: "Approved By",
    inputType: "text",
    placeholder: "Who approved the quote?",
    defaultValue: "",
    config: {
      required: "Please enter the name of the person who approved the quote",
      validate: (value) => value.length >= 3 || "Please enter the name of the person who approved the quote",
    },
  },
  title: {
    fieldName: "title",
    label: "Title",
    inputType: "text",
    placeholder: "What's their title?",
    defaultValue: "",
    config: {
      required: "Please enter the title of the person who approved the quote",
      validate: (value) => value.length >= 3 || "Please enter the title of the person who approved the quote",
    },
  },
  approvalMethod: {
    fieldName: "approvalMethod",
    label: "How did you receive the approval?",
    inputType: "select",
    placeholder: "How did you receive the approval?",
    options: [
      { value: "email", label: "Via Email" },
      { value: "text", label: "Via Text" },
      { value: "phone", label: "Via Phone Call" },
      { value: "inPerson", label: "In Person" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: "",
      validate: (value) => value.length >= 3 || "Please select an answer",
    },
  },
  poNumber: {
    fieldName: "poNumber",
    label: "PO Number",
    inputType: "text",
    placeholder: "Please enter a PO if it was given to you by the customer",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  acceptanceNotes: {
    fieldName: "acceptanceNotes",
    label: "Acceptance notes",
    inputType: "textarea",
    placeholder: "If the client mentioned anything relevant to the quote, please mention it here",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};
