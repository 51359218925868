import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../../components/buttons";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { GetAllEmployees } from "../../../actions/admin";
import { Helmet } from "react-helmet-async";

const Employees = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetAllEmployees()
        .then((res) => {
          setEmployees(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error retrieving Employee records");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const renderEmployeeLine = (line) => {
    return (
      <div className="grid items-center w-full grid-cols-7 gap-4 px-1 py-3 border-b border-gray-300" key={uuidv4()}>
        <p className="text-sm font-semibold uppercase">{line.employeeCode}</p>
        <p>
          {line.firstName} {line.lastName}
        </p>
        <p className="col-span-2">{line.email}</p>
        <p className="capitalize">{line.functionCategory}</p>
        <p className="capitalize">{line.accountStatus}</p>
        <div className="flex flex-row items-center justify-center gap-2">
          <SecondaryButton label="Open" callback={() => navigate(`/admin/employees/${line.userId}`)} />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <Helmet>
              <title>Employees | HTPS ERP</title>
            </Helmet>
            <div className="flex flex-row items-center justify-between w-full pb-5 my-5 border-b border-gray-300">
              <h1 className="text-3xl font-bold">Employees</h1>
              <SecondaryButton label="Create a Employee Account" callback={() => navigate("/admin/employees/new")} />
            </div>
          </div>
          <div className="grid w-full grid-cols-7 gap-4 px-1 py-3 border-b border-gray-300">
            <p className="text-xs font-semibold uppercase">Employee Code</p>
            <p className="text-xs font-semibold uppercase">Name</p>
            <p className="col-span-2 text-xs font-semibold uppercase">Email</p>
            <p className="text-xs font-semibold uppercase">Category</p>
            <p className="text-xs font-semibold uppercase">Account status</p>
            <div className="flex flex-row items-center justify-center"></div>
          </div>
          {employees.map((line, index) => renderEmployeeLine(line, index))}
        </>
      )}
    </div>
  );
};

export default Employees;
