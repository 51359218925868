import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { GetUser } from "./actions/auth";
import Login from "./pages/auth/login";
import { Toaster } from "react-hot-toast";
import Loader from "./components/loader";
import CoreLayout from "./layouts/coreLayout";
import Invite from "./pages/auth/invite";
import ForgotPassword from "./pages/auth/forgotPassword";
import ResetPasswordView from "./pages/auth/resetPassword";
import markerSDK from "@marker.io/browser";
import { Helmet } from "react-helmet-async";

const App = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);

  let widget;

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (token) {
      console.log("%c Powered by Hypertek Solutions LLC ", "background: #3d4044; color: #f20c2e; font-size:16px; font-weight:bold;");
      console.log("%c        liveuptothehype.com        ", "background: #3d4044; color: #f20c2e; font-size:16px; font-weight:bold;");
      // loadMarker();
      GetUser()
        .then((response) => {
          authDispatch({
            type: "valid-login",
            payload: {
              token: localStorage.getItem("auth-token"),
              role: response.data.role,
              user: response.data,
            },
          });
          setLoading(false);
        })
        .catch((err) => {
          authDispatch({ type: "logged-out" });
          localStorage.removeItem("auth-token");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  // const loadMarker = async () => {
  //   if (process.env.REACT_APP_ENVIRONMENT !== "dev" && !widget) {
  //     const widget = await markerSDK.loadWidget({
  //       project: "652952d24de0ad00cdf91454",
  //       customData: { host: process.env.REACT_APP_HOST },
  //     });
  //     widget.show();
  //   }
  // };

  return !loading ? (
    <div className="w-full h-screen overflow-x-hidden">
      <Helmet>
        <title>HiTech Power Systems ERP</title>
      </Helmet>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 5000,
          style: {
            background: "#172554",
            color: "#fff",
            borderRadius: "5px",
          },
          className: "maxZIndex",
          success: {
            style: {
              paddingLeft: "15px",
            },
          },
          error: {
            style: {
              paddingLeft: "15px",
            },
          },
        }}
      />
      <Routes>
        <Route path="login" element={<Login authDispatch={authDispatch} authState={authState} />} />
        <Route path="forgotPassword" element={<ForgotPassword authDispatch={authDispatch} authState={authState} />} />
        <Route path="resetPassword" element={<ResetPasswordView authDispatch={authDispatch} authState={authState} />} />
        <Route path="invite/:inviteId" element={<Invite authDispatch={authDispatch} authState={authState} />} />
        <Route path="/*" element={authState.isAuth ? <CoreLayout authState={authState} authDispatch={authDispatch} /> : <Navigate to="/login" />} />
      </Routes>
    </div>
  ) : (
    <Loader />
  );
};

export default App;
