import { useEffect, useState, Fragment, useMemo } from "react";
import { Dialog, Transition } from "@headlessui/react";
import dayjs from "dayjs";
import { formatCurrency } from "../../components/tools";
import { useParams, useNavigate } from "react-router-dom";
import { AddEquipmentPhoto, GetOneEquipment } from "../../actions/ims";
import toast from "react-hot-toast";
import { CloudUpload } from "iconoir-react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { UploadEquipmentPhotos } from "../../actions/cdn";
import { Image } from "antd";
import { useDropzone } from "react-dropzone";
import { Helmet } from "react-helmet-async";

const EquipmentPhotos = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [equipment, setEquipment] = useState({});
  let [customer, setCustomer] = useState({});
  let [uploadModal, setUploadModal] = useState(false);
  let [selectedFile, setSelectedFile] = useState(null);
  let [fileUrl, setFileUrl] = useState(null);
  let [addFileModal, setAddFileModal] = useState(false);
  let [addFileData, setAddFileData] = useState({
    notes: "",
    jobNo: "",
  });
  let [imagePreview, setImagePreview] = useState(false);
  let [imagePreviewData, setImagePreviewData] = useState({
    dateTaken: "",
    photoId: "",
    photoUrl: "",
    photoNotes: "",
    jobNo: "",
    photoTakenBy: "",
  });

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDropAccepted: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((f) => {
        toast.error(`${f.file.name} was not accepted`);
      });
    },
  });

  let { equipmentId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneEquipment(equipmentId)
        .then((res) => {
          setEquipment(res.data.equipment);
          setCustomer(res.data.customer);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error loading equipment");
          setTimeout(() => navigate("/equipment"), 1800);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const tabs = [
    { name: "Basic Information", href: "#", current: false },
    { name: "Details", href: "details", current: false },
    { name: "Engine", href: "engine", current: false },
    { name: "ATS", href: "ats", current: false },
    { name: "Contact", href: "contact", current: false },
    { name: "Notes", href: "notes", current: false },
    { name: "Photos", href: "photos", current: true },
    { name: "Service Schedule", href: "serviceSchedule", current: false },
    { name: "History", href: "history", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "100%",
    padding: "80px 0px",
    margin: "15px 0px",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );
  const DropzoneComponent = () => {
    return (
      <div className="flex flex-col items-center justify-center w-full">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p className="text-sm text-gray-600">Click to upload or drag and drop the file here</p>
        </div>
        <div className="flex flex-col items-center justify-start w-full gap-2 mt-5">
          {selectedFile ? (
            <div className="flex flex-col items-center justify-start w-full gap-1 text-xs">
              <p>File Name: {selectedFile.name ? selectedFile.name : "Unknown File"}</p>
            </div>
          ) : (
            <p className="text-sm text-gray-500">No file selected</p>
          )}
        </div>
      </div>
    );
  };

  const fileUploadModal = () => {
    return (
      <Transition.Root show={uploadModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeUploadModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="w-full px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                        <CloudUpload className="w-6 h-6 text-blue-600" aria-hidden="true" />
                      </div>
                      <div className="w-full mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Add Photos
                        </Dialog.Title>
                        <div className="w-full mt-2">{DropzoneComponent()}</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 px-6 py-3">
                    <SecondaryButton label="Cancel" callback={() => closeUploadModal()} />
                    <PrimaryButton
                      label="Upload"
                      disabled={!selectedFile}
                      callback={() => (selectedFile ? uploadFile() : toast.error("Please select a file first"))}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderAddFileModal = () => {
    return (
      <Transition.Root show={addFileModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddFileModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="flex flex-col items-start justify-start w-full gap-2 px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add Photo
                    </Dialog.Title>
                    <div className="w-full h-full mt-2 max-h-64">
                      <img src={fileUrl} className="object-cover w-full h-64" />
                    </div>
                    <div className="w-full mt-2">
                      <label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">
                        Notes
                      </label>
                      <div className="mt-2">
                        <input
                          type="notes"
                          name="notes"
                          id="notes"
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="Notes about this photo"
                          onChange={(e) => changeData(e)}
                        />
                      </div>
                    </div>
                    <div className="w-full mt-2">
                      <label htmlFor="jobNo" className="block text-sm font-medium leading-6 text-gray-900">
                        Job No (optional)
                      </label>
                      <div className="mt-2">
                        <input
                          type="jobNo"
                          name="jobNo"
                          id="jobNo"
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="Job this photo was taken for"
                          onChange={(e) => changeData(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 px-6 py-3">
                    <SecondaryButton label="Cancel" callback={() => closeAddFileModal()} />
                    <PrimaryButton label="Add Photo" callback={() => submitNewPhoto()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const submitNewPhoto = () => {
    setLoading(true);
    AddEquipmentPhoto(equipmentId, {
      url: fileUrl,
      notes: addFileData.notes,
      jobNo: addFileData.jobNo,
    })
      .then((res) => {
        toast.success("Photo added successfully");
        closeAddFileModal();
        GetOneEquipment(equipmentId)
          .then((res) => {
            setEquipment(res.data.equipment);
            setCustomer(res.data.customer);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Error loading equipment");
            setTimeout(() => navigate("/equipment"), 1800);
          });
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error adding photo");
        setLoading(false);
      });
  };

  const changeData = (e) => {
    let { name, value } = e.target;
    let tmp = addFileData;
    tmp[name] = value;
    setAddFileData(tmp);
  };

  const closeAddFileModal = () => {
    setAddFileModal(false);
    setAddFileData({
      notes: "",
      jobNo: "",
    });
    setFileUrl(null);
  };

  const closeUploadModal = () => {
    setUploadModal(false);
    setSelectedFile(null);
  };

  const uploadFile = () => {
    let formData = new FormData();
    formData.set("file", selectedFile, selectedFile.name);
    setLoading(true);
    UploadEquipmentPhotos(formData)
      .then((res) => {
        setFileUrl(res.data[0].url);
        setAddFileModal(true);
        closeUploadModal();
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error uploading file");
        setLoading(false);
      });
  };

  const renderImagePreview = () => {
    return (
      <Transition.Root show={imagePreview} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeImagePreview}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-6xl sm:p-6">
                  <div className="flex flex-col items-start justify-start w-full gap-4 mt-3">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Payment successful
                    </Dialog.Title>
                    <div className="flex flex-row items-start justify-between w-full gap-3 mt-2">
                      <Image src={imagePreviewData.photoUrl} width="75%" style={{ maxHeight: "60vh", objectFit: "cover" }} />
                      <div className="flex flex-col items-end justify-center w-1/4 h-full gap-1">
                        <p className="text-sm font-semibold text-gray-500 uppercase">
                          Added On: <span className="text-gray-900">{dayjs(imagePreviewData.dateTaken).format("MM/DD/YYYY")}</span>
                        </p>
                        <p className="text-sm font-semibold text-gray-500 uppercase">
                          Notes: <span className="w-full text-sm font-semibold text-gray-900">{imagePreviewData.photoNotes}</span>
                        </p>
                        <p className="text-sm font-semibold text-gray-500 uppercase">
                          Job Reference:{" "}
                          <span className="w-full text-sm font-semibold text-gray-900">
                            {imagePreviewData.jobNo.length > 0 ? imagePreviewData.jobNo : "N/A"}
                          </span>
                        </p>
                        <p className="text-sm font-semibold text-gray-500 uppercase">
                          Added By:{" "}
                          <span className="w-full text-sm font-semibold text-gray-900">
                            {imagePreviewData.photoTakenBy.length > 0 ? imagePreviewData.photoTakenBy : "N/A"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end gap-2 mt-5 sm:mt-6">
                    <SecondaryButton label="Close" callback={() => closeImagePreview()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeImagePreview = () => {
    setImagePreview(false);
    setTimeout(() => {
      setImagePreviewData({
        dateTaken: "",
        photoId: "",
        photoUrl: "",
        photoNotes: "",
        jobNo: "",
        photoTakenBy: "",
      });
    }, 700);
  };

  const openImagePreview = (data) => {
    setImagePreviewData({
      dateTaken: data.dateTaken,
      photoId: data.photoId,
      photoUrl: data.photoUrl,
      photoNotes: data.photoNotes,
      jobNo: data.jobNo,
      photoTakenBy: data.photoTakenBy || "",
    });
    setImagePreview(true);
  };

  const renderEqType = () => {
    return equipment.equipmentType === "airCompressor"
      ? "Air Compressor"
      : equipment.equipmentType === "pressureWasher"
        ? "Pressure Washer"
        : equipment.equipmentType[0].toUpperCase() + equipment.equipmentType.slice(1);
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>
              {renderEqType()} Photos - {customer.customerCode} | HTPS ERP
            </title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/equipment/${equipmentId}/${v.target.value}`)}
              >
                {tabs.map((tab) => {
                  if ((tab.name === "ATS" && equipment.equipmentType === "truck") || (tab.name === "ATS" && equipment.equipmentType === "trailer")) {
                    return;
                  }
                  return <option value={tab.href}>{tab.name}</option>;
                })}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => {
                    if ((tab.name === "ATS" && equipment.equipmentType === "truck") || (tab.name === "ATS" && equipment.equipmentType === "trailer")) {
                      return;
                    }
                    return (
                      <p
                        key={tab.name}
                        onClick={() => navigate(`/equipment/${equipmentId}/${tab.href}`)}
                        className={classNames(
                          tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        {tab.name}
                      </p>
                    );
                  })}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full h-full gap-4 px-1 mb-5">
            <div className="flex flex-row items-center justify-between w-full pb-3 mb-5 border-b border-gray-300">
              <p className="font-semibold uppercase">Equipment Photos</p>
              <SecondaryButton label="Add Photos" callback={() => setUploadModal(true)} />
            </div>
            <div className="flex flex-col items-start justify-start flex-grow w-full overflow-y-auto">
              <div className="grid w-full grid-cols-5 gap-4">
                {equipment.photos.length > 0
                  ? equipment.photos.map((photo, index) => (
                      <div className="relative cursor-pointer" onClick={() => openImagePreview(photo)}>
                        <div className="absolute inset-0 z-10 flex flex-col items-center justify-center font-semibold text-center text-white duration-300 opacity-0 bg-slate-900 hover:opacity-100 bg-opacity-80">
                          <h1 className="tracking-wider">Image #{index + 1}</h1>
                          <p className="mx-auto font-mono text-xs font-normal truncate">Added on {dayjs(photo.dateTaken).format("MM/DD/YYYY")}</p>
                        </div>
                        <div className="relative">
                          <div className="flex flex-wrap content-center h-48">
                            <img src={photo.photoUrl} className="object-cover w-full h-48 mx-auto" alt="" />
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              {equipment.photos.length === 0 && <p className="w-full text-sm font-semibold text-center uppercase text-gray-500/80">No Photos Added</p>}
            </div>
          </div>
          {fileUploadModal()}
          {renderAddFileModal()}
          {renderImagePreview()}
        </>
      )}
    </div>
  );
};

export default EquipmentPhotos;
