import { SecondaryButton, PrimaryButton } from "../../components/buttons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { Input, Select, Table, Tooltip } from "antd";
import { QueryJobs } from "../../actions/jobs";
import { QueryCustomers } from "../../actions/customers";
import { AdvancedEquipmentQuery, QueryEquipment, QueryPurchaseOrders, QueryVendors } from "../../actions/ims";

const _ = require("lodash");

const BLANK_FILTERS = {
  customer: {
    customerId: null,
    customerCode: null,
    company: null,
  },
  equipmentType: null,
  make: null,
  model: null,
  serialNo: null,
  status: null,
};

const DEFAULT_SIZE = 12;

const AllEquipment = () => {
  const [loading, setLoading] = useState(true);
  const [equipment, setEquipment] = useState([]);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [vendors, setVendors] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [filters, setFilters] = useState(BLANK_FILTERS);
  const [customers, setCustomers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      runPOQuery(filters, page, size);
    }
    return () => {
      inView = false;
    };
  }, []);

  const renderStatus = (status) => {
    switch (status) {
      case "retired":
        return (
          <span className="inline-flex items-center rounded-md bg-gray-50 px-3.5 py-1.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Retired
          </span>
        );
      case "availableForUse":
        return (
          <span className="inline-flex items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Available For Use
          </span>
        );
      case "inoperable":
        return (
          <span className="inline-flex items-center rounded-md bg-red-50 px-3.5 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            Inoperable
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center rounded-md bg-gray-50 px-3.5 py-1.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Unknown
          </span>
        );
    }
  };

  const clearFilters = () => {
    setLoading(true);
    setFilters(BLANK_FILTERS);
    setVendors([]);
    setJobs([]);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runPOQuery(BLANK_FILTERS, 0, DEFAULT_SIZE);
  };

  const updateFilter = ({ target: { name, value } }) => {
    let tmp = filters;
    tmp[name] = value;
    setFilters((prev) => ({ ...prev, [name]: value }));
    runPOQuery(tmp, page, size);
  };

  const runPOQuery = (data, offset, limit) => {
    AdvancedEquipmentQuery({
      customerId: data.customer.customerId,
      status: data.status,
      make: data.make,
      model: data.model,
      serialNo: data.serialNo,
      equipmentType: data.equipmentType,
      page: offset,
      limit: limit,
    })
      .then((res) => {
        setEquipment(res.data.results);
        setTotal(res.data.total);
        setSize(res.data.size);
        setPage(res.data.page);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Failed to fetch POs");
        setEquipment([]);
        setTotal(0);
        setSize(DEFAULT_SIZE);
        setPage(0);
        setFilters(BLANK_FILTERS);
        setTimeout(() => setLoading(false), 700);
      });
  };

  const changePage = (pg, sz) => {
    setPage(pg);
    setSize(sz);
    setLoading(true);
    runPOQuery(filters, pg, sz);
  };

  const applyFilters = () => {
    setLoading(true);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runPOQuery(filters, 0, DEFAULT_SIZE);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  const updateLocation = (v) => {
    let tmp = filters;
    if (v) {
      tmp.locationId = v;
    } else {
      tmp.locationId = null;
    }
    setFilters(tmp);
    runPOQuery(tmp, page, size);
  };

  const updateStatus = (v) => {
    let tmp = filters;
    if (v) {
      tmp.status = v;
    } else {
      tmp.status = null;
    }
    setFilters(tmp);
    runPOQuery(tmp, page, size);
  };

  const updateType = (v) => {
    let tmp = filters;
    if (v) {
      tmp.equipmentType = v;
    } else {
      tmp.equipmentType = null;
    }
    setFilters(tmp);
    runPOQuery(tmp, page, size);
  };

  const handleCustomerSearch = (query) => {
    if (query?.length > 2) {
      QueryCustomers(query)
        .then((res) => {
          setCustomers(res.data);
        })
        .catch((err) => {
          toast.error("Customer search failed, please try again");
          setCustomers([]);
        });
    }
  };

  const updateCustomer = (v) => {
    let tmp = filters;
    if (v) {
      let qry = customers.find((c) => c.customerId === v);
      if (qry) {
        tmp.customer = { customerId: qry.customerId, customerCode: qry.customerCode, company: qry.company };
      } else {
        tmp.customer = { customerId: null, customerCode: null, company: null };
      }
    } else {
      tmp.customer = { customerId: null, customerCode: null, company: null };
    }
    setFilters(tmp);
    setCustomers(customers.filter((c) => c.customerId === v));
    runPOQuery(tmp, page, size);
  };

  let eqTypes = {
    generator: "Generator",
    pressureWasher: "Pressure Washer",
    truck: "Truck",
    trailer: "Trailer",
    welder: "Welder",
    airCompressor: "Air Compressor",
    other: "Other",
  };

  const getMakeModel = (r) => {
    let value = `${r?.details?.make?.length > 0 ? r?.details?.make : r?.engine?.make?.length > 0 ? r?.engine?.make : ""} ${r?.details?.model?.length > 0 ? r?.details?.model : r?.engine?.model?.length > 0 ? r?.engine?.model : ""}`;
    if (value.replace(" ", "").length === 0) {
      return "Not Provided";
    } else {
      return value;
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Equipment | HTPS ERP</title>
      </Helmet>
      <div className="flex-grow flow-root w-full px-2 mt-3">
        <div className="-my-2">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-2">
              <div className="grid w-full h-full gap-6 mx-auto md:grid-cols-12">
                <div className="flex flex-col h-full col-span-3 gap-4">
                  <div className="flex flex-col items-start justify-start w-full gap-4 p-6 border border-gray-300 rounded-lg">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Filters</h3>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="equipmentType" className="pb-1 text-xs text-gray-600 uppercase">
                        Equipment Type
                      </label>
                      <Select
                        placeholder="Equipment Type"
                        onChange={(v) => updateType(v)}
                        value={filters.equipmentType}
                        options={[
                          { value: "generator", label: "Generator" },
                          { value: "pressureWasher", label: "Pressure Washer" },
                          { value: "truck", label: "Truck" },
                          { value: "trailer", label: "Trailer" },
                          { value: "welder", label: "Welder" },
                          { value: "airCompressor", label: "Air Compressor" },
                          { value: "other", label: "Other" },
                        ]}
                        className="w-full mb-2 font-sans"
                        controls={false}
                        filterOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        defaultActiveFirstOption={false}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="status" className="pb-1 text-xs text-gray-600 uppercase">
                        Equipment Status
                      </label>
                      <Select
                        placeholder="Equipment Status"
                        onChange={(v) => updateStatus(v)}
                        value={filters.status}
                        options={[
                          {
                            label: "All Statuses",
                            value: null,
                          },
                          {
                            label: "Available for Use",
                            value: "availableForUse",
                          },
                          {
                            label: "Inoperable",
                            value: "inoperable",
                          },
                          {
                            label: "Retired",
                            value: "retired",
                          },
                        ]}
                        className="w-full mb-2 font-sans"
                        controls={false}
                        filterOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        defaultActiveFirstOption={false}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="customer" className="pb-1 text-xs text-gray-600 uppercase">
                        Customer
                      </label>
                      <Select
                        placeholder="Search Customers"
                        onChange={(v) => updateCustomer(v)}
                        value={filters.customer.customerId}
                        options={(customers || []).map((p) => ({
                          label: `${p.customerCode} | ${p.company && p.company.length > 0 ? p.company : "No Company Provided"}`,
                          value: p.customerId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No customers found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onSearch={handleCustomerSearch}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="serialNo" className="pb-1 text-xs text-gray-600 uppercase">
                        Serial/Code/Engine No
                      </label>
                      <Input
                        placeholder="Enter Serial/Code/Engine No"
                        name="serialNo"
                        autoComplete="off"
                        onChange={(e) => {
                          updateFilter(e);
                        }}
                        value={filters.serialNo}
                        defaultValue={filters.serialNo}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                        allowClear
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full gap-2">
                    <SecondaryButton
                      callback={() => clearFilters()}
                      label="Clear Filters"
                      className="block py-2.5 transition-all duration-300 normal-case w-full"
                    />
                    <PrimaryButton
                      callback={() => applyFilters()}
                      label="Apply Filters"
                      className="block py-2.5 transition-all duration-300 bg-black hover:bg-black/80 normal-case w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full col-span-9 gap-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Search Results</h3>
                    <button
                      className="inline-flex items-center justify-center h-8 px-4 py-2 leading-none text-sm font-medium transition-colors rounded-md whitespace-nowrap ring-offset-[#000064] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#18181b] focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#18181b] text-[#fafafa] hover:bg-[#18181b]/90"
                      onClick={() => navigate("/equipment/new")}
                    >
                      Add Equipment
                    </button>
                  </div>
                  <Table
                    dataSource={equipment}
                    loading={loading}
                    className="w-full"
                    sticky={true}
                    pagination={{
                      total: total,
                      responsive: true,
                      hideOnSinglePage: true,
                      onChange: (p, s) => changePage(p - 1, s),
                      pageSize: size,
                      current: page + 1,
                      showSizeChanger: false,
                    }}
                    rowKey="equipmentId"
                  >
                    <Table.Column title="Type" dataIndex="equipmentType" key="equipmentType" align="left" width={180} render={(v) => eqTypes[v]} />
                    <Table.Column
                      title="Make / Model"
                      dataIndex="details.make"
                      key="details.make"
                      render={(_v, r) => (
                        <Tooltip title={getMakeModel(r)}>
                          <p className="truncate cursor-pointer">{getMakeModel(r)}</p>
                        </Tooltip>
                      )}
                    />
                    <Table.Column
                      title="Customer"
                      dataIndex="customerId"
                      key="customerId"
                      render={(v, r) => (
                        <Tooltip title={`${r.customerCode} ${r.company}`}>
                          <p className="truncate">
                            {r.customerCode} {r.company}
                          </p>
                        </Tooltip>
                      )}
                    />
                    <Table.Column title="Status" dataIndex="status" key="status" render={(d) => renderStatus(d)} width={"180px"} />
                    <Table.Column
                      title=""
                      dataIndex="equipmentId"
                      key="actions"
                      width={"80px"}
                      render={(v) => (
                        <SecondaryButton
                          callback={() => navigate(`/equipment/${v}`)}
                          label="Open"
                          className="transition-all duration-300 border bg-white-smoke border-zinc-400 ring-0 hover:ring-0 hover:bg-zinc-100 hover:border-zinc-600 text-zinc-600 hover:text-zinc-900"
                        />
                      )}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllEquipment;
