import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GetOneJob, AddANote, UpdateJob, SubmitJobForInvoice, VoidAJob, UpdateJobShopFee, ReplaceJobCustomer } from "../../actions/jobs";
import toast from "react-hot-toast";
import { OpenNewWindow } from "iconoir-react";
import dayjs from "dayjs";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import validator from "validator";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import NoteField from "../../components/NoteField";
import { v4 as uuidv4 } from "uuid";
import { formatCurrency } from "../../components/tools";
import { Button, Drawer, InputNumber, Modal, Select, Table } from "antd";
import { GenerateJobSheet } from "../../data/pdf";
import { QueryCustomers } from "../../actions/customers";
import { Helmet } from "react-helmet-async";

const OpenJob = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [job, setJob] = useState({});
  let [rates, setRates] = useState([]);
  let [customer, setCustomer] = useState({});
  let [location, setLocation] = useState({});
  let [equipment, setEquipment] = useState([]);
  let [employees, setEmployees] = useState([]);
  let [noteField, setNoteField] = useState(false);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [note, setNote] = useState("");
  let [previewJob, setPreviewJob] = useState(false);
  let [invoiceModal, setInvoiceModal] = useState(false);
  let [shopFeeModal, setShopFeeModal] = useState(false);
  let [shopFeePercentage, setShopFeePercentage] = useState(3);
  let [replaceCustomerModal, setReplaceCustomerModal] = useState(false);
  let [newCustomer, setNewCustomer] = useState(null);
  let [customers, setCustomers] = useState([]);
  let [otherQuotesDrawer, setOtherQuotesDrawer] = useState(false);

  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneJob(jobId)
        .then((res) => {
          setJob(res.data.job);
          setRates(res.data.rates);
          setCustomer(res.data.customer);
          setLocation(res.data.location);
          setEquipment(res.data.equipment);
          setEmployees(res.data.employees);
          setShopFeePercentage(res.data.job.shopFeePercentage);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
          setTimeout(() => {
            navigate("/jobs");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const tabs = [
    { name: "Overview", href: "#", current: true },
    { name: "Parts", href: "parts", current: false },
    { name: "Labor", href: "labor", current: false },
    { name: "Parking/Storage", href: "parking", current: false },
    { name: "Loadbank", href: "loadbank", current: false },
    { name: "Freight", href: "freight", current: false },
    { name: "Misc", href: "misc", current: false },
    { name: "Purchase Orders", href: "purchaseOrders", current: false },
    {
      name: "Inventory Transmittals",
      href: "inventoryTransmittals",
      current: false,
    },
    { name: "Equipment", href: "equipment", current: false },
    { name: "Tests", href: "tests", current: false },
    { name: "Warranty", href: "warranty", current: false },
    { name: "Payments", href: "payments", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const testZip = (val) => {
    return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
  };

  const renderJobStatus = (status) => {
    switch (status) {
      case "open":
        return (
          <span className="inline-flex items-center rounded-md bg-gray-50 px-3.5 py-1.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/30">
            Open
          </span>
        );
      case "invoiced":
        return (
          <span className="inline-flex items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Invoiced
          </span>
        );
      case "void":
        return (
          <span className="inline-flex items-center rounded-md bg-red-50 px-3.5 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            VOID
          </span>
        );
      case "complete":
        return (
          <span className="inline-flex items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Completed
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center rounded-md bg-gray-50 px-3.5 py-1.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/30">
            Unknown
          </span>
        );
    }
  };

  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isDirty, dirtyFields },
    setValue,
  } = formMethods;

  const onSubmit = (data) => {
    console.log(data);
  };

  const submitNote = () => {
    let tmp = job;
    let tmpNotes = job.jobNotes;
    let noteData = {
      note: note,
      noteBy: authState.user.userId,
      noteByName: authState.user.firstName + " " + authState.user.lastName,
      noteDate: new Date(),
      noteId: uuidv4(),
    };
    tmpNotes.push(noteData);
    AddANote(jobId, { note: note })
      .then((res) => {
        tmp.jobNotes = tmpNotes;
        setJob(tmp);
        setNoteField(false);
        setNote("");
        toast.success("Note added successfully!");
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error adding note, please try again later");
        setNoteField(false);
        setNote("");
      });
  };

  const renderNote = (note) => {
    return (
      <div className="flex flex-col items-start justify-center w-full px-3 py-2 rounded-lg shadow bg-gray-200/40">
        <h3 className="w-full text-sm font-base text-slate-800">{note.note}</h3>
        <div className="flex flex-row items-center justify-between w-full mt-1">
          <p className="text-xs text-slate-500/60 font-medium pt-0.5">{note.noteByName}</p>
          <p className="text-xs text-slate-500/60 font-medium pt-0.5">{dayjs(note.noteDate).format("MM/DD/YYYY")}</p>
        </div>
      </div>
    );
  };

  const getLaborStatus = () => {
    let quoted = 0;
    let completed = 0;
    for (let i = 0; i < job.labor.length; i++) {
      let el = job.labor[i];
      if (el.quoted) {
        quoted += el.quoted;
      }
      completed += el.billableTime;
    }
    if (quoted === 0 && completed === 0) {
      return "No labor was quoted or completed.";
    } else if (quoted > completed) {
      return `${completed} out of ${quoted} quoted hours completed.`;
    } else if (quoted === completed) {
      return `All ${quoted} quoted hours have been completed.`;
    } else if (quoted < completed) {
      let diff = completed - quoted;
      return `Labor complete with additional ${diff} hours of work.`;
    }
  };

  const getPartsStatus = () => {
    let expected = 0;
    let added = 0;
    for (let i = 0; i < job.parts.length; i++) {
      let el = job.parts[i];
      expected += el.quantity;
      added += el.commissioned ?? 0;
    }
    if (expected === 0 && added === 0) {
      return "No parts were quoted or added to this job.";
    } else if (expected > added) {
      return `${added} out of ${expected} quoted parts added.`;
    } else if (expected === added) {
      return `All ${expected} quoted parts have been added.`;
    } else if (expected < added) {
      let extra = added - expected;
      return `Parts complete with additional ${extra} parts.`;
    } else {
      return `Unknown parts status. Expected: ${expected} | Added: ${added}`;
    }
  };

  const getLoadbankStatus = () => {
    let expected = job.loadbank.length;
    let added = job.loadbankTests.length;
    if (expected === 0) {
      return "No loadbank tests were quoted or added to this job.";
    } else {
      if (expected === added) {
        return `All ${expected} quoted loadbank tests have been completed.`;
      } else if (expected > added) {
        return `${added} out of ${expected} quoted loadbank tests completed.`;
      } else {
        return `Loadbank tests complete with additional ${added - expected} tests.`;
      }
    }
  };

  const getLaborTotal = () => {
    let total = 0;
    if (job.labor) {
      for (let i = 0; i < job.labor.length; i++) {
        let el = job.labor[i];
        if (el.status !== "void") {
          total += el.quotedPrice;
        }
      }
    }
    return total;
  };

  const getPartsTotal = () => {
    let total = 0;
    for (let i = 0; i < job.parts.length; i++) {
      let el = job.parts[i];
      if (el.category === "parts") {
        if (el.partStatus !== "returnedToInventory" && el.partStatus !== "returnedToVendor") {
          total += el.extPrice;
        }
      }
    }
    for (let i = 0; i < job.misc.length; i++) {
      let el = job.misc[i];
      if (el.miscType === "part") {
        if (el.partStatus !== "returnedToInventory" && el.partStatus !== "returnedToVendor") {
          total += el.chargeOutPrice;
        }
      }
    }
    return total;
  };

  const getGOGTotal = () => {
    let total = 0;
    for (let i = 0; i < job.parts.length; i++) {
      let el = job.parts[i];
      if (el.category === "gog") {
        total += el.extPrice;
      }
    }
    return total;
  };

  const getMiscTotal = () => {
    let total = 0;
    for (let i = 0; i < job.misc.length; i++) {
      let el = job.misc[i];
      if (el.miscType === "service") {
        total += el.chargeOutPrice;
      }
    }
    for (let i = 0; i < job.parking.length; i++) {
      let el = job.parking[i];
      total += el.totalCharge;
    }
    for (let i = 0; i < job.loadbank.length; i++) {
      let el = job.loadbank[i];
      total += el.unitCharge;
    }
    for (let i = 0; i < job.freight.length; i++) {
      let el = job.freight[i];
      total += el.freightChargeOut;
    }
    return total;
  };

  const getShopFeeTotal = () => {
    let total = 0;
    if (job.shopFeePercentage === undefined || job.shopFeePercentage === null) {
      total = (getLaborTotal() / 100) * 3;
    } else {
      total = (getLaborTotal() / 100) * job.shopFeePercentage;
    }
    return total;
  };

  const calculateShopFee = () => {
    return (getLaborTotal() / 100) * shopFeePercentage;
  };

  const getSubtotal = () => {
    let total = 0;
    total += getLaborTotal();
    total += getPartsTotal();
    total += getGOGTotal();
    total += getMiscTotal();
    total += getShopFeeTotal();
    return total;
  };

  const getPayments = () => {
    let total = 0;
    if (job.payments) {
      for (let i = 0; i < job.payments.length; i++) {
        let el = job.payments[i];
        total += el.amount;
      }
    }
    return total;
  };

  const getWarrantyProviderSubtotal = () => {
    let total = 0;
    total += getLaborTotal();
    total += getShopFeeTotal();
    for (let i = 0; i < job.parts.length; i++) {
      let el = job.parts[i];
      if (!el.chargeToCustomer) {
        total += el.extPrice;
      }
    }
    for (let i = 0; i < job.misc.length; i++) {
      let el = job.misc[i];
      if (!el.chargeToCustomer) {
        total += el.chargeOutPrice;
      }
    }
    for (let i = 0; i < job.parking.length; i++) {
      let el = job.parking[i];
      total += el.totalCharge;
    }
    for (let i = 0; i < job.loadbank.length; i++) {
      let el = job.loadbank[i];
      total += el.unitCharge;
    }
    for (let i = 0; i < job.freight.length; i++) {
      let el = job.freight[i];
      total += el.freightChargeOut;
    }
    return total;
  };

  const getCustomerSubtotal = () => {
    let total = 0;
    for (let i = 0; i < job.parts.length; i++) {
      let el = job.parts[i];
      if (el.chargeToCustomer) {
        total += el.extPrice;
      }
    }
    for (let i = 0; i < job.misc.length; i++) {
      let el = job.misc[i];
      if (el.chargeToCustomer) {
        total += el.chargeOutPrice;
      }
    }
    return total;
  };

  const saveJobChanges = () => {
    handleSubmit(saveJob)();
  };

  const voidJob = () => {
    setLoading(true);
    VoidAJob(jobId)
      .then((res) => {
        toast.success("Job voided successfully!");
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setRates(res.data.rates);
            setCustomer(res.data.customer);
            setLocation(res.data.location);
            setEquipment(res.data.equipment);
            setEmployees(res.data.employees);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error occurred while voiding job.");
        setTimeout(() => {
          navigate("/jobs");
        }, 3000);
      });
  };

  const saveJob = (data) => {
    setLoading(true);
    data.laborTotal = parseFloat(getLaborTotal().toFixed(2));
    data.partsTotal = parseFloat(getPartsTotal().toFixed(2));
    data.gogTotal = parseFloat(getGOGTotal().toFixed(2));
    data.miscTotal = parseFloat(getMiscTotal().toFixed(2));
    data.shopFeeTotal = parseFloat(getShopFeeTotal().toFixed(2));
    data.subtotal = parseFloat(getSubtotal().toFixed(2));
    UpdateJob(jobId, data)
      .then((res) => {
        toast.success("Job updated successfully!");
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setRates(res.data.rates);
            setCustomer(res.data.customer);
            setLocation(res.data.location);
            setEquipment(res.data.equipment);
            setEmployees(res.data.employees);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating job information");
        setLoading(false);
      });
  };
  const submitJob = (data) => {
    setLoading(true);
    UpdateJob(jobId, data)
      .then((res) => {
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setRates(res.data.rates);
            setCustomer(res.data.customer);
            setLocation(res.data.location);
            setEquipment(res.data.equipment);
            setEmployees(res.data.employees);
            SubmitJobForInvoice(jobId)
              .then((res) => {
                toast.success("Job Invoice submitted successfully!");
                if (job.warranty) {
                  navigate(`/invoices/${res.data.providerInvoiceId}`);
                } else {
                  navigate(`/invoices/${res.data.invoiceId}`);
                }
              })
              .catch((err) => {
                toast.error(err.response.data.message ? err.response.data.message : "Error submitting job for an invoice");
                setLoading(false);
              });
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating job information");
        setLoading(false);
      });
  };

  const submitForInvoice = () => {
    handleSubmit(submitJob)();
  };

  const changeLaborDiscount = (e) => {
    let { value } = e.target;
    let discount = parseInt(value);
    if (!isNaN(discount)) {
      setValue("laborDiscount", value);
      setDummyLoading(true);
      let tmp = job;
      let lbr = tmp.labor;
      for (let i = 0; i < lbr.length; i++) {
        const element = lbr[i];
        let rate = rates.find((r) => r.rateId === element.rate);
        lbr[i].perHour = rate.rate - discount;
      }
      tmp.labor = lbr;
      setJob(tmp);
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const renderLaborStatus = (status) => {
    switch (status) {
      case "quoted":
        return <p className="px-2 py-1 text-xs font-semibold text-center border rounded-md text-amber-500 border-amber-500 bg-amber-50">Quoted</p>;
      case "completed":
        return <p className="px-2 py-1 text-xs font-semibold text-center text-green-700 border border-green-700 rounded-md bg-green-50">Completed</p>;
      case "skipped":
        return <p className="px-2 py-1 text-xs font-semibold text-center text-red-700 border border-red-700 rounded-md bg-red-50">Skipped</p>;
      case "incomplete":
        return <p className="px-2 py-1 text-xs font-semibold text-center text-red-700 border border-red-700 rounded-md bg-red-50">Incomplete</p>;
      case "void":
        return <p className="px-2 py-1 text-xs font-semibold text-center text-red-700 border border-red-700 rounded-md bg-red-50">VOID</p>;
      case "additionalLabor":
        return <p className="px-2 py-1 text-xs font-semibold text-center border rounded-md text-sky-500 border-sky-500 bg-sky-50">Additional Labor</p>;
      case "inProgress":
        return <p className="px-2 py-1 text-xs font-semibold text-center border rounded-md text-sky-500 border-sky-500 bg-sky-50">In-Progress</p>;
      default:
        return <p className="px-2 py-1 text-xs font-semibold text-center text-gray-500 border border-gray-500 rounded-md bg-gray-50">Unknown</p>;
    }
  };

  const partStatus = {
    entered: "Entered",
    inventory: "Inventory",
    onOrder: "On Order",
    returnedToInventory: "Returned to Inventory",
    returnedToVendor: "Returned to Vendor",
    inTransit: "In Transit",
    partiallyAvailable: "Partially Available",
    committed: "Committed",
    backOrdered: "Back Ordered",
  };

  const otherCharges = () => {
    let other = [];
    for (let i = 0; i < job.misc.length; i++) {
      let el = job.misc[i];
      other.push({
        key: el.miscId,
        quantity: el.quantity,
        description: `${el.miscType === "part" ? "MISC PART" : "MISC SERVICE"} | ${el.description}${el.partNo && el.partNo.length > 0 && " | " + el.partNo}`,
        price: el.chargeOutPrice,
        total: parseFloat((el.chargeOutPrice / el.quantity).toFixed(2)),
      });
    }
    for (let i = 0; i < job.freight.length; i++) {
      let el = job.freight[i];
      other.push({
        key: el.freightId,
        quantity: 1,
        description: `FREIGHT FEE | ${el.poNumber}`,
        price: el.freightChargeOut,
        total: el.freightChargeOut,
      });
    }
    for (let i = 0; i < job.loadbank.length; i++) {
      let el = job.loadbank[i];
      other.push({
        key: el.loadbankId,
        quantity: 1,
        description: `LOADBANK FEE${el.description.length > 0 ? " | " + el.description : ""}`,
        price: el.unitCharge,
        total: el.unitCharge,
      });
    }
    for (let i = 0; i < job.parking.length; i++) {
      let el = job.parking[i];
      other.push({
        key: el.parkingId,
        quantity: el.quantity,
        description: `${el.increment.toUpperCase()} ${el.parkingType === "parking" ? "PARKING" : "STORAGE"} FEE | From ${dayjs(el.startDate).format(
          "MM/DD/YYYY",
        )} to ${dayjs(el.endDate).format("MM/DD/YYYY")}`,
        price: el.unitCharge,
        total: el.totalCharge,
      });
    }
    return other;
  };

  const equipmentRender = () => {
    let toReturn = [];
    for (let i = 0; i < job.equipment.length; i++) {
      let el = job.equipment[i];
      let eq = equipment.find((e) => e.equipmentId === el.equipmentId);
      if (eq) {
        let eqType = {
          generator: "Generator",
          pressureWasher: "Pressure Washer",
          truck: "Truck",
          trailer: "Trailer",
          welder: "Welder",
          airCompressor: "Air Compressor",
          other: "Other",
        };
        toReturn.push({
          key: eq.equipmentId,
          type: eqType[eq.equipmentType],
          customerEquipId: eq.customerEquipId && eq.customerEquipId.length > 0 ? eq.customerEquipId : "N/A",
          make: eq.details.make,
          model: eq.details.model,
          rawData: eq,
        });
      }
    }
    return toReturn;
  };

  const printJobSheet = () => {
    let jobParts = [];
    let jobLabor = [];
    let laborStatus = {
      quoted: "Quoted",
      completed: "Completed",
      skipped: "Skipped",
      incomplete: "Incomplete",
      void: "VOID",
      additionalLabor: "Additional Labor",
      inProgress: "In-Progress",
    };
    for (let i = 0; i < job.parts.length; i++) {
      let el = job.parts[i];
      jobParts.push({
        partNo: el.partNo,
        status: partStatus[el.partStatus],
        description: el.description,
        price: el.pricePerPart,
        quantity: el.quantity,
        total: el.extPrice,
      });
    }
    for (let i = 0; i < job.labor.length; i++) {
      let el = job.labor[i];
      jobLabor.push({
        description: el.laborDescription,
        status: laborStatus[el.status],
        rate: rates.find((rt) => rt.rateId === el.rate).laborCode,
        quotedCost: el.quotedPrice,
      });
    }
    let toPrint = {
      jobNo: job.jobNo,
      customer: customer.customerCode + " | " + customer.company,
      preparedBy: employees.find((e) => e.userId === job.openedBy).firstName + " " + employees.find((e) => e.userId === job.openedBy).lastName,
      openDate: dayjs(job.openDate).format("MMMM Do, YYYY"),
      description: job.description,
      labor: jobLabor,
      parts: jobParts,
      otherCharges: otherCharges(),
      equipment: equipmentRender(),
      subtotal: getSubtotal(),
    };
    let doc = GenerateJobSheet(toPrint);
    doc.setProperties({
      title: `Job Sheet - ${job.jobNo}`,
      subject: `Job Sheet - ${job.jobNo}`,
      author: "Hypertek Solutions LLC",
      keywords: "",
      creator: "contact@hypertek.dev",
    });
    window.open(doc.output("bloburl"), "_blank");
  };

  const renderShopFeeModal = () => {
    return (
      <Modal
        open={shopFeeModal}
        onCancel={() => closeShopFeeModal()}
        onOk={() => submitShopFee()}
        title="Shop Fee"
        centered
        destroyOnClose
        okText={"Update Shop Fee"}
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 py-4">
          <p className="font-semibold">Shop Fee Percentage:</p>
          <InputNumber onChange={(v) => setShopFeePercentage(v)} controls={false} addonAfter="%" min={0} value={shopFeePercentage} className="w-full" />
          <div className="flex items-center justify-between w-full">
            <p className="font-semibold">Calculated Shop Fee:</p>
            <p className="font-bold">{formatCurrency(calculateShopFee())}</p>
          </div>
        </div>
      </Modal>
    );
  };

  const closeShopFeeModal = () => {
    setShopFeeModal(false);
    setShopFeePercentage(3);
  };

  const submitShopFee = () => {
    let lbr = getLaborTotal();
    let shopFee = parseFloat((lbr * (shopFeePercentage / 100)).toFixed(2));
    setLoading(true);
    UpdateJobShopFee(jobId, {
      shopFeeTotal: shopFee,
      shopFeePercentage: shopFeePercentage,
    })
      .then((res) => {
        closeShopFeeModal();
        setJob(res.data);
        toast.success("Shop Fee updated successfully!");
        setTimeout(() => handleSubmit(saveJob)(), 500);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating shop fee, please try again.");
        setTimeout(() => setLoading(false), 700);
      });
  };

  const replaceCustomer = () => {
    Modal.confirm({
      title: "Are You Sure You Want To Replace Customer",
      content:
        "This will replace the current customer with the new customer. This action will remove all equipment attached to this job and replace the customer on all Purchase Orders.",
      okText: "Replace Customer",
      cancelText: "Cancel",
      onOk: () => {
        setReplaceCustomerModal(true);
      },
      onCancel: () => {},
      centered: true,
      width: 550,
    });
  };

  const renderReplaceCustomerModal = () => {
    return (
      <Modal
        open={replaceCustomerModal}
        onCancel={() => cancelCustomerReplace()}
        title="Replace Job Customer"
        centered
        destroyOnClose
        width={650}
        onOk={() => submitCustomerChange()}
      >
        <div className="flex flex-col items-start justify-start w-full mt-1">
          <label className="pb-2 text-xs text-gray-600 uppercase">New Customer</label>
          <Select
            placeholder="Search Customers"
            onChange={(v) => setNewCustomer(v)}
            value={newCustomer && newCustomer.length > 0 ? newCustomer : null}
            options={(customers || []).map((p) => ({
              label: `${p.customerCode} | ${p.company && p.company.length > 0 ? p.company : "No Company Provided"} | ${p.contact.firstName}`,
              value: p.customerId,
            }))}
            className="w-full mb-2 font-sans"
            notFoundContent="No customers found, start typing to search"
            controls={false}
            showSearch
            filterOption={false}
            defaultActiveFirstOption={false}
            onSearch={handleCustomerSearch}
          />
        </div>
      </Modal>
    );
  };

  const cancelCustomerReplace = () => {
    setReplaceCustomerModal(false);
    setNewCustomer(null);
    setLoading(true);
    setCustomers([]);
    GetOneJob(jobId)
      .then((res) => {
        setJob(res.data.job);
        setRates(res.data.rates);
        setCustomer(res.data.customer);
        setLocation(res.data.location);
        setEquipment(res.data.equipment);
        setEmployees(res.data.employees);
        setShopFeePercentage(res.data.job.shopFeePercentage);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
        setTimeout(() => {
          navigate("/jobs");
        }, 3000);
      });
  };

  const handleCustomerSearch = (query) => {
    QueryCustomers(query)
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((err) => {
        toast.error("Customer search failed, please try again");
        setCustomers([]);
      });
  };

  const submitCustomerChange = () => {
    ReplaceJobCustomer(jobId, { customerId: newCustomer })
      .then((res) => {
        toast.success("Customer replaced successfully!");
        cancelCustomerReplace();
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error replacing customer, please try again.");
        cancelCustomerReplace();
      });
  };

  const renderOtherQuotesDrawer = () => {
    return (
      <Drawer open={otherQuotesDrawer} onCancel={() => setOtherQuotesDrawer(false)} onClose={() => setOtherQuotesDrawer(false)} destroyOnClose title="Job Quotes" width={500}>
        <div className="flex flex-col items-start justify-start w-full h-full gap-2">
          <div className="flex flex-row items-center justify-between w-full px-4 py-2 border border-gray-300 rounded-md">
            <div className="flex flex-col items-start justify-center">
              <p>
                <span className="font-semibold">Quote No:</span> {job.quoteNo}
              </p>
              <p>
                <span className="font-semibold">Added On:</span> {dayjs(job.openDate).format("MM/DD/YYYY")}
              </p>
              <p>
                <span className="font-semibold">Created By:</span> {employees.find((e) => e.userId === job.openedBy)?.firstName}{" "}
                {employees.find((e) => e.userId === job.openedBy)?.lastName}
              </p>
            </div>
            <SecondaryButton label="Open" callback={() => window.open(`/quotes/${job.quoteId}`, "_blank")} />
          </div>
          {(job?.otherQuotes || []).map((q) => (
            <div className="flex flex-row items-center justify-between w-full px-4 py-2 border border-gray-300 rounded-md">
              <div className="flex flex-col items-start justify-center">
                <p>
                  <span className="font-semibold">Quote No:</span> {q.quoteNo}
                </p>
                <p>
                  <span className="font-semibold">Added On:</span> {dayjs(q.addedOn).format("MM/DD/YYYY")}
                </p>
                <p>
                  <span className="font-semibold">Created By:</span> {q.addedBy}
                </p>
              </div>
              <SecondaryButton label="Open" callback={() => window.open(`/quotes/${q.quoteId}`, "_blank")} />
            </div>
          ))}
          <div className="flex flex-row items-center justify-end w-full mt-auto">
            <PrimaryButton label="Close" callback={() => setOtherQuotesDrawer(false)} />
          </div>
        </div>
      </Drawer>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Overview - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).name}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      key={tab.name}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-2 pb-3 text-sm border-b border-gray-300 md:items-center md:justify-between md:flex-row">
            <div className="flex flex-col items-start justify-center gap-2">
              <div className="flex flex-row items-center justify-start gap-2">
                <p className="text-sm">
                  Job # <span className="font-semibold">{job.jobNo}</span>
                </p>
                {job.quoteId && job.quoteId !== "" && (
                  <button
                    className="px-4 py-1 duration-150 border border-gray-300 rounded-md hover:bg-gray-300 hover:border-black"
                    onClick={() => window.open(`/quotes/${job.quoteId}`, "_blank")}
                  >
                    <div className="flex flex-row items-center justify-center gap-2">
                      <p className="text-xs font-medium uppercase">View Quote</p>
                      <OpenNewWindow className="w-3 h-3" />
                    </div>
                  </button>
                )}
                {job.otherQuotes?.length > 0 && (
                  <button
                    className="px-4 py-1 duration-150 border border-gray-300 rounded-md hover:bg-gray-300 hover:border-black"
                    onClick={() => setOtherQuotesDrawer(true)}
                  >
                    <div className="flex flex-row items-center justify-center gap-2">
                      <p className="text-xs font-medium uppercase">All Job Quotes</p>
                    </div>
                  </button>
                )}
              </div>
              <div className="flex flex-row items-center justify-start gap-2">
                <p className="text-sm">
                  Customer:{" "}
                  <span className="font-semibold">
                    {customer.customerCode} | {job.customerName}
                  </span>
                </p>
                <button
                  className="px-4 py-1 duration-150 border border-gray-300 rounded-md hover:bg-gray-300 hover:border-black"
                  onClick={() => window.open(`/customers/${job.customerId}`, "_blank")}
                >
                  <div className="flex flex-row items-center justify-center gap-2">
                    <p className="text-xs font-medium uppercase">View</p>
                    <OpenNewWindow className="w-3 h-3" />
                  </div>
                </button>
              </div>
              <div className="flex flex-row items-center justify-start gap-2">
                <p className="text-sm">Status</p>
                {renderJobStatus(job.jobStatus)}
                {job.jobStatus === "invoiced" && !job.warranty ? (
                  <button
                    className="px-4 py-1 duration-150 border border-gray-300 rounded-md hover:bg-gray-300 hover:border-black"
                    onClick={() => window.open(`/invoices/${job.invoiceId}`, "_blank")}
                  >
                    <div className="flex flex-row items-center justify-center gap-2">
                      <p className="text-xs font-medium uppercase">View</p>
                      <OpenNewWindow className="w-3 h-3" />
                    </div>
                  </button>
                ) : (
                  job.jobStatus === "invoiced" &&
                  job.warranty && (
                    <button
                      className="px-4 py-1 duration-150 border border-gray-300 rounded-md hover:bg-gray-300 hover:border-black"
                      onClick={() => setInvoiceModal(true)}
                    >
                      <div className="flex flex-row items-center justify-center gap-2">
                        <p className="text-xs font-medium uppercase">View</p>
                        <OpenNewWindow className="w-3 h-3" />
                      </div>
                    </button>
                  )
                )}
              </div>
            </div>
            <div className="flex flex-col items-start justify-center gap-2 md:items-end">
              <p className="text-sm">
                Started on: <span className="font-semibold">{dayjs(job.openDate).format("MM/DD/YYYY")}</span>
              </p>
              <p className="text-sm">
                Started by:{" "}
                <span className="font-semibold">
                  {employees.find((e) => e.userId === job.openedBy).firstName} {employees.find((e) => e.userId === job.openedBy).lastName}
                </span>
              </p>
              <p className="text-sm">
                Location: <span className="font-semibold">{job.locationName}</span>
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full h-full gap-3 mt-3">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={`w-full flex flex-row justify-between items-center gap-5 border-b border-gray-300 pb-5 mb-5`}
              key="upperForm"
            >
              <FormProvider {...formMethods}>
                <div className="flex flex-col items-center justify-between w-full gap-5 px-1 md:flex-row">
                  <div className="flex flex-col items-start justify-start w-full gap-2 md:w-1/2">
                    <div key="description" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                        Description
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                        disabled={job.jobStatus !== "open"}
                        type="text"
                        id="description"
                        defaultValue={job.description}
                        placeholder="Job Description"
                        {...register("description", {
                          required: "Job description is required",
                          validate: (value) => value.length >= 3 || "Please enter a description for the job",
                        })}
                      />
                      <ErrorMessage errors={errors} name="description" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="details" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="details" className="pb-1 text-xs text-gray-600 uppercase">
                        Job Details
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                        disabled={job.jobStatus !== "open"}
                        type="text"
                        id="details"
                        defaultValue={job.details}
                        placeholder="Job details"
                        {...register("details", {
                          required: false,
                        })}
                      />
                      <ErrorMessage errors={errors} name="details" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="clientPo" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="clientPo" className="pb-1 text-xs text-gray-600 uppercase">
                        Client's PO Number
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                        disabled={job.jobStatus !== "open"}
                        type="text"
                        id="clientPo"
                        defaultValue={job.clientPo}
                        placeholder="Client's PO Number"
                        {...register("clientPo", {
                          required: false,
                        })}
                      />
                      <ErrorMessage errors={errors} name="clientPo" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="paymentTerms" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="paymentTerms" className="pb-1 text-xs text-gray-600 uppercase">
                        Payment Terms
                      </label>
                      <select
                        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                        disabled={job.jobStatus !== "open"}
                        id="paymentTerms"
                        defaultValue={job.paymentTerms}
                        {...register("paymentTerms", {
                          required: "Payment Terms are Required",
                          validate: (value) => value.length >= 3 || "Please select payment terms",
                        })}
                      >
                        <option hidden disabled value="">
                          Select One
                        </option>
                        <option key="net10" value="net10">
                          NET 10
                        </option>
                        <option key="net20" value="net20">
                          NET 20
                        </option>
                        <option key="net30" value="net30">
                          NET 30
                        </option>
                        <option key="collectOnDelivery" value="collectOnDelivery">
                          Collect on Delivery
                        </option>
                        <option key="dueOnReceipt" value="dueOnReceipt">
                          Due on Receipt
                        </option>
                        <option key="prePay" value="prePay">
                          Pre-Pay
                        </option>
                      </select>
                      <ErrorMessage errors={errors} name="paymentTerms" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start w-full gap-2 md:w-1/2">
                    {authState.user.functionCategory !== "technician" && (
                      <div key="laborDiscount" className="flex flex-col items-start justify-start w-full">
                        <label htmlFor="laborDiscount" className="pb-1 text-xs text-gray-600 uppercase">
                          Labor Discount
                        </label>
                        <input
                          className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                          disabled={job.jobStatus !== "open"}
                          type="number"
                          id="laborDiscount"
                          defaultValue={job.laborDiscount}
                          placeholder="Client's PO Number"
                          {...register("laborDiscount", {
                            required: "Labor discount is required",
                            validate: (value) => validator.isInt(value.toString()) || "Please enter a labor discount",
                          })}
                          onBlur={(e) => changeLaborDiscount(e)}
                        />
                        <ErrorMessage errors={errors} name="laborDiscount" as="p" className="px-1 pt-1 text-xs text-red-500" />
                      </div>
                    )}
                    {authState.user.functionCategory !== "technician" && (
                      <div key="partsMarkup" className="flex flex-col items-start justify-start w-full">
                        <label htmlFor="partsMarkup" className="pb-1 text-xs text-gray-600 uppercase">
                          Parts Markup %
                        </label>
                        <input
                          className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                          disabled={job.jobStatus !== "open"}
                          type="number"
                          id="partsMarkup"
                          defaultValue={job.partsMarkup}
                          placeholder="Parts Markup"
                          {...register("partsMarkup", {
                            required: "Parts Markup % is required",
                            validate: (value) => validator.isInt(value.toString()) || "Please enter a Parts Markup %",
                          })}
                        />
                        <ErrorMessage errors={errors} name="partsMarkup" as="p" className="px-1 pt-1 text-xs text-red-500" />
                      </div>
                    )}
                    <div key="clientNotes" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="clientNotes" className="pb-1 text-xs text-gray-600 uppercase">
                        Client's Notes
                      </label>
                      <input
                        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                        disabled={job.jobStatus !== "open"}
                        type="text"
                        id="clientNotes"
                        defaultValue={job.clientNotes}
                        placeholder=""
                        {...register("clientNotes", {
                          required: false,
                        })}
                      />
                      <ErrorMessage errors={errors} name="clientNotes" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div className="flex flex-col items-center justify-between w-full gap-3 md:flex-row">
                      <div key="tax" className="flex flex-col items-start justify-start w-full">
                        <label htmlFor="tax" className="pb-1 text-xs text-gray-600 uppercase">
                          Tax Status
                        </label>
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                          disabled={job.jobStatus !== "open"}
                          id="tax"
                          defaultValue={job.taxStatus}
                          {...register("tax", {
                            required: "Tax Status is Required",
                            validate: (value) => value.length >= 3 || "Please select a tax status",
                          })}
                        >
                          <option hidden disabled value="">
                            Select One
                          </option>
                          <option key="taxable" value="taxable">
                            Taxable
                          </option>
                          <option key="gov" value="gov">
                            Government Entity
                          </option>
                          <option key="st5biz" value="st5biz">
                            Business w/ ST5
                          </option>
                          <option key="outOfState" value="outOfState">
                            Out of State
                          </option>
                        </select>
                        <ErrorMessage errors={errors} name="tax" as="p" className="px-1 pt-1 text-xs text-red-500" />
                      </div>
                      <div key="taxZip" className="flex flex-col items-start justify-start w-full">
                        <label htmlFor="taxZip" className="pb-1 text-xs text-gray-600 uppercase">
                          Sales Tax ZIP
                        </label>
                        <input
                          className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100/80"
                          disabled={job.jobStatus !== "open"}
                          type="text"
                          id="taxZip"
                          defaultValue={job.taxZip}
                          placeholder="Sales Tax ZIP"
                          {...register("taxZip", {
                            required: "Customer's sales tax ZIP code is required",
                            validate: (value) => testZip(value) || "Please enter a valid ZIP code",
                          })}
                        />
                        <ErrorMessage errors={errors} name="taxZip" as="p" className="px-1 pt-1 text-xs text-red-500" />
                      </div>
                    </div>
                  </div>
                </div>
              </FormProvider>
            </form>
            <div className="flex flex-col items-center justify-between flex-grow w-full h-full gap-5 md:flex-row">
              <div className="flex flex-col items-start justify-start w-full h-full gap-3 md:w-1/2">
                <div className="flex flex-row items-center justify-start w-full gap-2">
                  <p className="text-sm font-semibold">Labor Status:</p>
                  <p className="text-sm">{getLaborStatus()}</p>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-2">
                  <p className="text-sm font-semibold">Parts Status:</p>
                  <p className="text-sm">{getPartsStatus()}</p>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-2 pb-5 mb-5 border-b border-gray-300">
                  <p className="text-sm font-semibold">Loadbank Tests:</p>
                  <p className="text-sm">{getLoadbankStatus()}</p>
                </div>
                <div className="flex flex-row items-center justify-end w-full gap-2">
                  <p className="text-sm font-semibold">Labor Total:</p>
                  <p className="w-24 text-sm text-right">{formatCurrency(getLaborTotal())}</p>
                </div>
                <div className="flex flex-row items-center justify-end w-full gap-2">
                  <p className="text-sm font-semibold">Parts Total:</p>
                  <p className="w-24 text-sm text-right">{formatCurrency(getPartsTotal())}</p>
                </div>
                <div className="flex flex-row items-center justify-end w-full gap-2">
                  <p className="text-sm font-semibold">Gas, Oil & Grease Total:</p>
                  <p className="w-24 text-sm text-right">{formatCurrency(getGOGTotal())}</p>
                </div>
                <div className="flex flex-row items-center justify-end w-full gap-2">
                  <p className="text-sm font-semibold">Misc Fees Total:</p>
                  <p className="w-24 text-sm text-right">{formatCurrency(getMiscTotal())}</p>
                </div>
                <div className="flex flex-row items-center justify-end w-full gap-2">
                  <p className="text-sm font-semibold">
                    Shop Fee ({job.shopFeePercentage}%):{" "}
                    {job.jobStatus === "open" && (
                      <span
                        className="text-blue-500 transition-all duration-200 cursor-pointer hover:text-blue-700"
                        onClick={() => {
                          setShopFeePercentage(job.shopFeePercentage);
                          setShopFeeModal(true);
                        }}
                      >
                        EDIT
                      </span>
                    )}
                  </p>
                  <p className="w-24 text-sm text-right">{formatCurrency(getShopFeeTotal())}</p>
                </div>
                <div className="flex flex-row items-center justify-end w-full gap-2 pt-3 border-t border-gray-400">
                  <p className="font-semibold">Subtotal:</p>
                  <p className="w-24 font-semibold text-right">{formatCurrency(getSubtotal())}</p>
                </div>
                {job.payments && job.payments.length > 0 && (
                  <>
                    <div className="flex flex-row items-center justify-end w-full gap-2 pt-3 text-sm border-t border-gray-400">
                      <p className="font-semibold">Payments Made:</p>
                      <p className="w-24 font-semibold text-right">{formatCurrency(getPayments())}</p>
                    </div>
                    <div className="flex flex-row items-center justify-end w-full gap-2 text-sm">
                      <p className="font-semibold">Amount Due:</p>
                      <p className="w-24 font-semibold text-right">{formatCurrency(getSubtotal() - getPayments())}</p>
                    </div>
                  </>
                )}
                {job.warranty && (
                  <>
                    <div className="flex flex-row items-center justify-end w-full gap-2 pt-3 text-sm border-t border-gray-600">
                      <p className="text-gray-800">Warranty Provider Subtotal:</p>
                      <p className="w-24 text-right text-gray-800">{formatCurrency(getWarrantyProviderSubtotal())}</p>
                    </div>
                    <div className="flex flex-row items-center justify-end w-full gap-2 -mt-2 text-sm">
                      <p className="text-gray-800">Customer Subtotal:</p>
                      <p className="w-24 text-right text-gray-800">{formatCurrency(getCustomerSubtotal())}</p>
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-col items-start justify-start w-full h-full gap-3 pt-4 border-t border-gray-300 md:border-t-0 md:pt-0 md:pl-4 md:border-l md:w-1/2">
                <div className="flex flex-col items-center justify-start flex-grow w-full gap-2">
                  {job.jobNotes.length > 0 ? (
                    job.jobNotes.map((note) => renderNote(note))
                  ) : (
                    <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">No notes found</p>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center w-full py-3 overflow-y-auto">
                  {noteField ? (
                    <>
                      <textarea
                        rows={4}
                        name="note"
                        id="note"
                        className="block mb-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        placeholder="Enter your note here..."
                        defaultValue={""}
                        onChange={(e) => setNote(e.target.value)}
                      />
                      <div className="flex flex-row items-center justify-around w-full gap-3">
                        <button
                          onClick={() => setNoteField(false)}
                          className="w-1/2 py-3 text-xs font-bold uppercase duration-150 rounded-md bg-slate-300 text-slate-800 hover:bg-gray-500/50"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => submitNote()}
                          className="w-1/2 py-3 text-xs font-bold uppercase duration-150 rounded-md bg-slate-300 text-slate-800 hover:bg-gray-500/50"
                        >
                          Add a new note
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-row items-center justify-end w-full px-1">
                      {job.jobStatus === "open" && <SecondaryButton label="Add Note" callback={() => setNoteField(true)} />}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end justify-start w-full gap-2 px-1 pt-5 pb-4 my-5 border-t border-gray-300 md:gap-4 md:items-center md:justify-end md:flex-row">
              <SecondaryButton label={"Preview Job"} callback={() => setPreviewJob(true)} />
              {job.jobStatus === "open" && (
                <>
                  {authState.user.functionCategory !== "technician" && (
                    <>
                      <SecondaryButton label={"Replace Customer"} callback={() => replaceCustomer()} />
                      <SecondaryButton label={"VOID Job"} callback={() => voidJob()} />
                      <PrimaryButton label={"Submit for Invoice Approval"} callback={() => submitForInvoice()} />
                    </>
                  )}
                  <SecondaryButton label={"Save Changes"} callback={() => saveJobChanges()} />
                </>
              )}
            </div>
            <Modal
              open={previewJob}
              centered
              title="Preview Job"
              onCancel={() => setPreviewJob(false)}
              destroyOnClose
              width={750}
              className="h-full max-h-[90vh] overflow-y-auto"
              footer={[<Button onClick={() => printJobSheet()}>Print Job Sheet</Button>, <Button onClick={() => setPreviewJob(false)}>Close</Button>]}
            >
              <div className="grid w-full grid-cols-2 gap-3 py-4">
                <p className="font-medium text-slate-600">{job.jobNo}</p>
                <p className="font-medium text-slate-600 place-self-end">
                  Customer: {customer.customerCode} {customer.company}
                </p>
                <p className="font-medium text-slate-600">
                  Prepared by {employees.find((e) => e.userId === job.openedBy).firstName} {employees.find((e) => e.userId === job.openedBy).lastName}
                </p>
                <p className="font-medium text-slate-600 place-self-end">Started On {dayjs(job.openDate).format("MMMM Do, YYYY")}</p>
                <p className="col-span-2 mt-3 text-sm text-slate-600">Job Description:</p>
                <p className="col-span-2 mb-3 font-medium text-slate-600">{job.description}</p>
                <p className="col-span-2 mt-3 text-sm font-semibold text-slate-800">Labor</p>
                {job.labor.length > 0 ? (
                  <Table className="w-full col-span-2" dataSource={job.labor} bordered pagination={false}>
                    <Table.Column title="Description" dataIndex="laborDescription" key="laborDescription" />
                    <Table.Column title="Status" dataIndex="status" key="status" render={(s) => renderLaborStatus(s)} align="center" />
                    <Table.Column title="Rate" dataIndex="rate" key="rate" render={(r) => rates.find((rt) => rt.rateId === r).laborCode} align="center" />
                    <Table.Column title="Quoted Cost" dataIndex="quotedPrice" key="quotedPrice" render={(p) => formatCurrency(p)} align="right" />
                  </Table>
                ) : (
                  <div className="flex items-center justify-center w-full col-span-2">
                    <p className="font-semibold uppercase text-slate-500">No Labor Added To This Job</p>
                  </div>
                )}
                <p className="col-span-2 mt-3 text-sm font-semibold text-slate-800">Parts</p>
                {job.parts.length > 0 ? (
                  <Table className="w-full col-span-2" dataSource={job.parts} bordered pagination={false}>
                    <Table.Column title="Part No." dataIndex="partNo" key="partNo" />
                    <Table.Column title="Description" dataIndex="description" key="description" />
                    <Table.Column title="Status" dataIndex="partStatus" key="partStatus" render={(s) => partStatus[s]} align="center" />
                    <Table.Column title="Price" dataIndex="pricePerPart" key="pricePerPart" render={(r) => formatCurrency(r)} align="center" />
                    <Table.Column title="Quantity" dataIndex="quantity" key="quantity" align="center" />
                    <Table.Column title="Total" dataIndex="extPrice" key="extPrice" render={(p) => formatCurrency(p)} align="right" />
                  </Table>
                ) : (
                  <div className="flex items-center justify-center w-full col-span-2">
                    <p className="font-semibold uppercase text-slate-500">No Parts Added To This Job</p>
                  </div>
                )}
                <p className="col-span-2 mt-3 text-sm font-semibold text-slate-800">Other Charges</p>
                {otherCharges().length > 0 ? (
                  <Table className="w-full col-span-2" dataSource={otherCharges()} bordered pagination={false}>
                    <Table.Column title="Description" dataIndex="description" key="description" />
                    <Table.Column title="Quantity" dataIndex="quantity" key="quantity" align="center" />
                    <Table.Column title="Price" dataIndex="price" key="price" render={(r) => formatCurrency(r)} align="center" />
                    <Table.Column title="Total" dataIndex="total" key="total" render={(p) => formatCurrency(p)} align="right" />
                  </Table>
                ) : (
                  <div className="flex items-center justify-center w-full col-span-2">
                    <p className="font-semibold uppercase text-slate-500">No Other Charges Added To This Job</p>
                  </div>
                )}
                <p className="col-span-2 mt-3 text-sm font-semibold text-slate-800">Equipment</p>
                {job.equipment.length > 0 ? (
                  <Table className="w-full col-span-2" dataSource={equipmentRender()} bordered pagination={false}>
                    <Table.Column title="Type" dataIndex="type" key="type" />
                    <Table.Column title="Cust. Eq. ID" dataIndex="customerEquipId" key="customerEquipId" />
                    <Table.Column title="Make" dataIndex="make" key="make" />
                    <Table.Column title="Model" dataIndex="model" key="model" />
                  </Table>
                ) : (
                  <div className="flex items-center justify-center w-full col-span-2">
                    <p className="font-semibold uppercase text-slate-500">No Equipment Added To This Job</p>
                  </div>
                )}
                <p className="col-start-2 mt-3 text-sm font-semibold text-right text-slate-800">Total Before Taxes: {formatCurrency(getSubtotal())}</p>
              </div>
            </Modal>
            <Modal
              open={invoiceModal}
              title="Which invoice would you like to open?"
              centered
              destroyOnClose
              onCancel={() => setInvoiceModal(false)}
              footer={[]}
            >
              <div className="flex flex-col items-center justify-center w-full gap-4 pt-10">
                <SecondaryButton label="Warranty Provider Invoice" callback={() => navigate(`/invoices/${job.warrantyInvoiceId}`)} />
                <SecondaryButton label="Customer Invoice" callback={() => navigate(`/invoices/${job.invoiceId}`)} />
              </div>
            </Modal>
          </div>
        </>
      )}
      {renderShopFeeModal()}
      {renderReplaceCustomerModal()}
      {renderOtherQuotesDrawer()}
    </div>
  );
};

export default OpenJob;
