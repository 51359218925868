import { SecondaryButton, PrimaryButton } from "../../components/buttons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { Input, Select, Table, Tooltip } from "antd";
import { QueryJobs } from "../../actions/jobs";
import { AdvancedQueryCustomers, QueryByEquipment, QueryCustomers } from "../../actions/customers";
import { QueryEquipment, QueryVendors } from "../../actions/ims";

const _ = require("lodash");

const BLANK_FILTERS = {
  vendor: {
    vendorId: null,
    vendorCode: null,
    vendorName: null,
  },
  search: "",
};

const DEFAULT_SIZE = 12;

const AllVendors = () => {
  const [loading, setLoading] = useState(true);
  const [vendors, setVendors] = useState([]);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [equipment, setEquipment] = useState([]);
  const [filters, setFilters] = useState(BLANK_FILTERS);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      runJobQuery(filters, page, size);
    }
    return () => {
      inView = false;
    };
  }, []);

  const clearFilters = () => {
    setLoading(true);
    setFilters(BLANK_FILTERS);
    setVendors([]);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runJobQuery(BLANK_FILTERS, 0, DEFAULT_SIZE);
  };

  const handleVendorSearch = (query) => {
    if (query?.length > 2) {
      setFilters({ ...filters, search: query });
      QueryCustomers(query)
        .then((res) => {
          setVendors(res.data);
        })
        .catch((err) => {
          toast.error("Customer search failed, please try again");
          setVendors([]);
        });
    }
  };

  const updateCustomer = (v) => {
    let tmp = filters;
    if (v) {
      let qry = vendors.find((c) => c.vendors === v);
      if (qry) {
        tmp.vendor = { vendors: qry.vendors, vendorCode: qry.vendorCode, vendorName: qry.vendorName };
      } else {
        tmp.vendor = { vendors: null, vendorCode: null, vendorName: null };
      }
    } else {
      tmp.vendor = { vendors: null, vendorCode: null, vendorName: null };
    }
    setFilters(tmp);
    setVendors(vendors.filter((c) => c.vendors === v));
    runJobQuery(tmp, page, size);
  };

  const runJobQuery = (data, offset, limit) => {
    let query = "";
    if (data.search.length > 2) {
      query = data.search;
    }
    QueryVendors(query, offset, limit)
      .then((res) => {
        setVendors(res.data.results);
        setTotal(res.data.total);
        setSize(res.data.size);
        setPage(res.data.page);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Failed to fetch vendors");
        setVendors([]);
        setTotal(0);
        setSize(DEFAULT_SIZE);
        setPage(0);
        setFilters(BLANK_FILTERS);
        setTimeout(() => setLoading(false), 700);
      });
  };

  const changePage = (pg, sz) => {
    setPage(pg);
    setSize(sz);
    setLoading(true);
    runJobQuery(filters, pg, sz);
  };

  const applyFilters = () => {
    setLoading(true);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runJobQuery(filters, 0, DEFAULT_SIZE);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Vendors | HTPS ERP</title>
      </Helmet>
      <div className="flex-grow flow-root w-full px-2 mt-3">
        <div className="-my-2">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-2">
              <div className="grid w-full h-full gap-6 mx-auto md:grid-cols-12">
                <div className="flex flex-col h-full col-span-3 gap-4">
                  <div className="flex flex-col items-start justify-start w-full gap-4 p-6 border border-gray-300 rounded-lg">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Filters</h3>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="vendor" className="pb-1 text-xs text-gray-600 uppercase">
                        Vendor
                      </label>
                      <Select
                        placeholder="Search Vendors"
                        onChange={(v) => updateCustomer(v)}
                        value={filters.vendor.vendorId}
                        options={(vendors || []).map((p) => ({
                          label: `${p.vendorCode} | ${p.vendorName && p.vendorName.length > 0 ? p.vendorName : ""}`,
                          value: p.vendorId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No vendors found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onSearch={handleVendorSearch}
                        allowClear
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full gap-2">
                    <SecondaryButton
                      callback={() => clearFilters()}
                      label="Clear Filters"
                      className="block py-2.5 transition-all duration-300 normal-case w-full"
                    />
                    <PrimaryButton
                      callback={() => applyFilters()}
                      label="Apply Filters"
                      className="block py-2.5 transition-all duration-300 bg-black hover:bg-black/80 normal-case w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full col-span-9 gap-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Search Results</h3>
                    <button
                      className="inline-flex items-center justify-center h-8 px-4 py-2 leading-none text-sm font-medium transition-colors rounded-md whitespace-nowrap ring-offset-[#000064] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#18181b] focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#18181b] text-[#fafafa] hover:bg-[#18181b]/90"
                      onClick={() => navigate("/vendors/new")}
                    >
                      New Vendor
                    </button>
                  </div>
                  <Table
                    dataSource={vendors}
                    loading={loading}
                    className="w-full"
                    sticky={true}
                    pagination={{
                      total: total,
                      responsive: true,
                      hideOnSinglePage: true,
                      onChange: (p, s) => changePage(p - 1, s),
                      pageSize: size,
                      current: page + 1,
                      showSizeChanger: false,
                    }}
                    rowKey="vendorId"
                  >
                    <Table.Column title="Vendor Code" dataIndex="vendorCode" key="vendorCode" width={"70px"} align="left" />
                    <Table.Column
                      title="Vendor Name"
                      dataIndex="vendorName"
                      key="vendorName"
                      width={"180px"}
                      align="left"
                      render={(v) => (
                        <Tooltip title={v}>
                          <p className="truncate">{v}</p>
                        </Tooltip>
                      )}
                    />
                    <Table.Column
                      title="Contact Person"
                      dataIndex="contact"
                      key="contact"
                      width={"260px"}
                      align="left"
                      render={(v, r) => (r?.contact?.name?.length > 10 ? r.contact.name : "Not Provided")}
                    />
                    <Table.Column
                      title=""
                      dataIndex="vendorId"
                      key="actions"
                      width={"80px"}
                      render={(v) => (
                        <SecondaryButton
                          callback={() => navigate(`/vendors/${v}`)}
                          label="Open"
                          className="transition-all duration-300 border bg-white-smoke border-zinc-400 ring-0 hover:ring-0 hover:bg-zinc-100 hover:border-zinc-600 text-zinc-600 hover:text-zinc-900"
                        />
                      )}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllVendors;
