import { SecondaryButton, PrimaryButton } from "../../components/buttons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { Input, Select, Table, Tooltip } from "antd";
import { QueryJobs } from "../../actions/jobs";
import { QueryCustomers } from "../../actions/customers";
import { QueryEquipment, QueryPurchaseOrders, QueryVendors } from "../../actions/ims";

const _ = require("lodash");

const BLANK_FILTERS = {
  poNo: null,
  vendor: {
    vendorId: null,
    vendorCode: null,
    vendorName: null,
  },
  job: {
    jobId: null,
    jobNo: null,
  },
  locationId: null,
  status: null,
};

const DEFAULT_SIZE = 12;

const AllPurchaseOrders = () => {
  const [loading, setLoading] = useState(true);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [vendors, setVendors] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [filters, setFilters] = useState(BLANK_FILTERS);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      runPOQuery(filters, page, size);
    }
    return () => {
      inView = false;
    };
  }, []);

  const renderStatus = (status) => {
    switch (status) {
      case "draft":
        return (
          <span className="inline-flex items-center rounded-md bg-gray-50 px-3.5 py-1.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Draft
          </span>
        );
      case "approved":
        return (
          <span className="inline-flex items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Approved
          </span>
        );
      case "denied":
        return (
          <span className="inline-flex items-center rounded-md bg-red-50 px-3.5 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            Denied
          </span>
        );
      case "received":
        return (
          <span className="inline-flex items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Received
          </span>
        );
      case "void":
        return (
          <span className="inline-flex items-center rounded-md bg-red-50 px-3.5 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            VOID
          </span>
        );
      case "returned":
        return (
          <span className="inline-flex items-center rounded-md bg-red-50 px-3.5 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            Returned
          </span>
        );
      case "backOrdered":
        return (
          <span className="inline-flex items-center rounded-md bg-red-50 px-3.5 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            Back-Ordered
          </span>
        );
      case "submitted":
        return (
          <span className="inline-flex items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Submitted
          </span>
        );
      case "invoiced":
        return (
          <span className="inline-flex items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Invoiced
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center rounded-md bg-gray-50 px-3.5 py-1.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Unknown
          </span>
        );
    }
  };

  const clearFilters = () => {
    setLoading(true);
    setFilters(BLANK_FILTERS);
    setVendors([]);
    setJobs([]);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runPOQuery(BLANK_FILTERS, 0, DEFAULT_SIZE);
  };

  const updateFilter = ({ target: { name, value } }) => {
    let tmp = filters;
    tmp[name] = value;
    setFilters((prev) => ({ ...prev, [name]: value }));
    runPOQuery(tmp, page, size);
  };

  const handleVendorSearch = (query) => {
    if (query?.length > 2) {
      QueryVendors(query)
        .then((res) => {
          setVendors(res.data.results);
        })
        .catch((err) => {
          toast.error("Vendor search failed, please try again");
          setVendors([]);
        });
    }
  };

  const updateVendor = (v) => {
    let tmp = filters;
    if (v) {
      let qry = vendors.find((c) => c.vendorId === v);
      if (qry) {
        tmp.vendor = { vendorId: qry.vendorId, vendorCode: qry.vendorCode, vendorName: qry.vendorName };
      } else {
        tmp.vendor = { vendorId: null, vendorCode: null, vendorName: null };
      }
    } else {
      tmp.vendor = { vendorId: null, vendorCode: null, vendorName: null };
    }
    setFilters(tmp);
    setVendors(vendors.filter((c) => c.vendorId === v));
    runPOQuery(tmp, page, size);
  };

  const handleJobSearch = (query) => {
    if (query?.length > 2) {
      QueryJobs({ jobNo: query })
        .then((res) => {
          setJobs(res.data.results);
        })
        .catch((err) => {
          toast.error("Job search failed, please try again");
          setJobs([]);
        });
    }
  };

  const updateJob = (v) => {
    let tmp = filters;
    if (v) {
      let qry = jobs.find((e) => e.jobId === v);
      if (qry) {
        tmp.job = {
          jobId: qry.jobId,
          jobNo: qry.jobNo,
        };
      } else {
        tmp.job = {
          jobId: null,
          jobNo: qry.jobNo,
        };
      }
    } else {
      tmp.job = {
        jobId: null,
        jobNo: null,
      };
    }
    setFilters(tmp);
    setJobs(jobs.filter((e) => e.jobId === v));
    runPOQuery(tmp, page, size);
  };

  const runPOQuery = (data, offset, limit) => {
    QueryPurchaseOrders({
      poNo: data.poNo,
      vendorId: data.vendor.vendorId,
      jobId: data.job.jobId,
      status: data.status,
      locationId: data.locationId,
      page: offset,
      limit: limit,
    })
      .then((res) => {
        setPurchaseOrders(res.data.results);
        setTotal(res.data.total);
        setSize(res.data.size);
        setPage(res.data.page);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Failed to fetch POs");
        setPurchaseOrders([]);
        setTotal(0);
        setSize(DEFAULT_SIZE);
        setPage(0);
        setFilters(BLANK_FILTERS);
        setTimeout(() => setLoading(false), 700);
      });
  };

  const changePage = (pg, sz) => {
    setPage(pg);
    setSize(sz);
    setLoading(true);
    runPOQuery(filters, pg, sz);
  };

  const applyFilters = () => {
    setLoading(true);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runPOQuery(filters, 0, DEFAULT_SIZE);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  const updateLocation = (v) => {
    let tmp = filters;
    if (v) {
      tmp.locationId = v;
    } else {
      tmp.locationId = null;
    }
    setFilters(tmp);
    runPOQuery(tmp, page, size);
  };

  const locations = [
    {
      label: "Augusta, GA",
      value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf",
    },
    {
      label: "North Augusta, SC",
      value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
    },
  ];

  const updateStatus = (v) => {
    let tmp = filters;
    if (v) {
      tmp.status = v;
    } else {
      tmp.status = null;
    }
    setFilters(tmp);
    runPOQuery(tmp, page, size);
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Purchase Orders | HTPS ERP</title>
      </Helmet>
      <div className="flex-grow flow-root w-full px-2 mt-3">
        <div className="-my-2">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-2">
              <div className="grid w-full h-full gap-6 mx-auto md:grid-cols-12">
                <div className="flex flex-col h-full col-span-3 gap-4">
                  <div className="flex flex-col items-start justify-start w-full gap-4 p-6 border border-gray-300 rounded-lg">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Filters</h3>
                    <div className="flex flex-col items-start justify-start w-full mt-4">
                      <label htmlFor="poNo" className="pb-1 text-xs text-gray-600 uppercase">
                        PO Number
                      </label>
                      <Input
                        placeholder="PO Number"
                        name="poNo"
                        autoComplete="off"
                        onChange={(e) => {
                          updateFilter(e);
                        }}
                        value={filters.poNo}
                        defaultValue={filters.poNo}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="jobId" className="pb-1 text-xs text-gray-600 uppercase">
                        Job
                      </label>
                      <Select
                        placeholder="Search Jobs"
                        onChange={(v) => updateJob(v)}
                        value={filters.job.jobId}
                        options={(jobs || []).map((p) => ({
                          label: p.jobNo,
                          value: p.jobId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No jobs found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onSearch={handleJobSearch}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="vendorId" className="pb-1 text-xs text-gray-600 uppercase">
                        Vendor
                      </label>
                      <Select
                        placeholder="Search Vendors"
                        onChange={(v) => updateVendor(v)}
                        value={filters.vendor.vendorId}
                        options={(vendors || []).map((e) => ({
                          label: `${e.vendorCode} | ${e.vendorName}`,
                          value: e.vendorId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No vendors found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onSearch={handleVendorSearch}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="locationId" className="pb-1 text-xs text-gray-600 uppercase">
                        Location
                      </label>
                      <Select
                        placeholder="PO Location"
                        onChange={(v) => updateLocation(v)}
                        value={filters.locationId}
                        options={locations}
                        className="w-full mb-2 font-sans"
                        controls={false}
                        filterOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        defaultActiveFirstOption={false}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="status" className="pb-1 text-xs text-gray-600 uppercase">
                        Invoice Status
                      </label>
                      <Select
                        placeholder="Invoice Status"
                        onChange={(v) => updateStatus(v)}
                        value={filters.status}
                        options={[
                          {
                            label: "All Statuses",
                            value: null,
                          },
                          {
                            label: "Draft",
                            value: "draft",
                          },
                          {
                            label: "Approved",
                            value: "approved",
                          },
                          {
                            label: "Denied",
                            value: "denied",
                          },
                          {
                            label: "Submitted",
                            value: "submitted",
                          },
                          {
                            label: "Invoiced",
                            value: "invoiced",
                          },
                          {
                            label: "Received",
                            value: "received",
                          },
                          {
                            label: "VOID",
                            value: "void",
                          },
                          {
                            label: "Returned",
                            value: "returned",
                          },
                          {
                            label: "Back Ordered",
                            value: "backOrdered",
                          },
                        ]}
                        className="w-full mb-2 font-sans"
                        controls={false}
                        filterOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        defaultActiveFirstOption={false}
                        allowClear
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full gap-2">
                    <SecondaryButton
                      callback={() => clearFilters()}
                      label="Clear Filters"
                      className="block py-2.5 transition-all duration-300 normal-case w-full"
                    />
                    <PrimaryButton
                      callback={() => applyFilters()}
                      label="Apply Filters"
                      className="block py-2.5 transition-all duration-300 bg-black hover:bg-black/80 normal-case w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full col-span-9 gap-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Search Results</h3>
                    <button
                      className="inline-flex items-center justify-center h-8 px-4 py-2 leading-none text-sm font-medium transition-colors rounded-md whitespace-nowrap ring-offset-[#000064] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#18181b] focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#18181b] text-[#fafafa] hover:bg-[#18181b]/90"
                      onClick={() => navigate("/po/new")}
                    >
                      New Purchase Order
                    </button>
                  </div>
                  <Table
                    dataSource={purchaseOrders}
                    loading={loading}
                    className="w-full"
                    sticky={true}
                    pagination={{
                      total: total,
                      responsive: true,
                      hideOnSinglePage: true,
                      onChange: (p, s) => changePage(p - 1, s),
                      pageSize: size,
                      current: page + 1,
                      showSizeChanger: false,
                    }}
                    rowKey="jobId"
                  >
                    <Table.Column title="Status" dataIndex="poStatus" key="poStatus" render={(d) => renderStatus(d)} width={"100px"} />
                    <Table.Column title="PO No." dataIndex="poNumber" key="poNumber" align="left" width={180} />
                    <Table.Column
                      title="Vendor"
                      dataIndex="vendorName"
                      key="vendorName"
                      render={(_v, r) => (
                        <Tooltip title={`${r?.vendorCode}${r?.vendorName?.length && ` | ${r.vendorName}`}`}>
                          <p className="truncate cursor-pointer">
                            {r?.vendorCode}
                            {r?.vendorName?.length > 0 && ` | ${r.vendorName}`}
                          </p>
                        </Tooltip>
                      )}
                    />
                    <Table.Column
                      title="Location"
                      dataIndex="locationId"
                      key="locationId"
                      width={"160px"}
                      render={(v) => locations.find((l) => l.value === v).label}
                    />
                    <Table.Column
                      title=""
                      dataIndex="poId"
                      key="actions"
                      width={"80px"}
                      render={(v) => (
                        <SecondaryButton
                          callback={() => navigate(`/po/${v}`)}
                          label="Open"
                          className="transition-all duration-300 border bg-white-smoke border-zinc-400 ring-0 hover:ring-0 hover:bg-zinc-100 hover:border-zinc-600 text-zinc-600 hover:text-zinc-900"
                        />
                      )}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPurchaseOrders;
