import axiosInstance from "./axios";

export const GetAccounts = () => {
  return axiosInstance.get("/util/accounts");
};

export const EmailAReceipt = (saleId, email) => {
  return axiosInstance.put(`/util/receipt/${saleId}/email`, { email });
};

export const TextAReceipt = (saleId, phone) => {
  return axiosInstance.put(`/util/receipt/${saleId}/sms`, { phone });
};

export const ReprintReceipt = (data) => {
  return axiosInstance.post(`/util/reprintReceipt`, data);
};
