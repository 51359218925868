import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../../components/buttons";
import * as _ from "lodash";
import dayjs from "dayjs";
import FormController from "../../../components/FormController";
import { employeeAccountForm } from "../../../data/employeeForms";
import { CreateEmployee, GetOneEmployee, ResendInvite } from "../../../actions/admin";
import { Helmet } from "react-helmet-async";

const locations = [
  { value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf", label: "Augusta, GA" },
  {
    value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
    label: "North Augusta, SC",
  },
];

const OpenEmployee = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState({
    employeeCode: "",
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    email: "",
    emergencyContact: {
      name: "",
      phoneNumber: "",
      relationship: "",
    },
    hiringDate: "",
    locationId: "",
    payRate: "",
    payPeriod: "",
    title: "",
    functionCategory: "",
    commissioned: false,
    commissionRate: "",
  });

  const navigate = useNavigate();
  let { employeeId } = useParams();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneEmployee(employeeId)
        .then((res) => {
          setData(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "An error occurred");
          setTimeout(() => navigate(-1), 2500);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const submitEmployee = (values) => {
    setLoading(true);
    CreateEmployee(values)
      .then((res) => {
        toast.success("Employee created successfully. An invite has been sent to their email address");
        setTimeout(() => navigate("/admin/employees"), 2500);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred");
        setLoading(false);
      });
  };

  const resendInvite = () => {
    setLoading(true);
    ResendInvite({ email: data.email })
      .then((res) => {
        toast.success("Invite resent successfully");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred");
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <Helmet>
              <title>
                Employee - {data.firstName} {data.lastName} | HTPS ERP
              </title>
            </Helmet>
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Employee Account</p>
              <div className="flex flex-row items-center justify-end gap-2">
                <p className="text-sm font-semibold uppercase">{data.accountStatus}</p>
                {data.accountStatus === "invited" && <SecondaryButton label="Resend Invite" callback={() => resendInvite()} />}
              </div>
            </div>
            <div className="w-2/3 mx-auto flex-grow flex-col justify-start items-center overflow-y-auto h-full px-0.5 gap-5 mb-10">
              <FormController
                onSubmit={submitEmployee}
                fields={employeeAccountForm}
                values={data}
                defaultValues={data}
                buttonText={"Update Employee"}
                hideSubmit={true}
                fullWidth={true}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OpenEmployee;
