import { useState } from "react";
import { AddNoteToCustomer } from "../actions/customers";
import toast from "react-hot-toast";

const NoteField = ({ closeField, id }) => {
  const [note, setNote] = useState("");

  const submitNote = () => {
    AddNoteToCustomer({ note }, id)
      .then((res) => {
        toast.success("Note added successfully");
        closeField(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please try again");
      });
  };

  return (
    <>
      <textarea
        rows={4}
        name="note"
        id="note"
        className="block mb-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
        placeholder="Enter your note here..."
        defaultValue={""}
        onChange={(e) => setNote(e.target.value)}
      />
      <div className="w-full flex flex-row justify-around items-center gap-3">
        <button
          onClick={() => closeField(false)}
          className="w-1/2 py-3 text-xs font-bold bg-slate-300 text-slate-800 uppercase rounded-md hover:bg-gray-500/50 duration-150"
        >
          Cancel
        </button>
        <button
          onClick={() => submitNote()}
          className="w-1/2 py-3 text-xs font-bold bg-slate-300 text-slate-800 uppercase rounded-md hover:bg-gray-500/50 duration-150"
        >
          Add a new note
        </button>
      </div>
    </>
  );
};

export default NoteField;
