import jsPDF from "jspdf";
import "jspdf-autotable";
import { v4 as uuidv4 } from "uuid";
import hiTechLogo from "../assets/hitechLogo2.png";
import dayjs from "dayjs";
import paidStamp from "../assets/paidStamp.png";
import { AUGUSTA, formatCurrency, formatPhoneNumber, NORTH_AUGUSTA } from "../components/tools";
import { ToWords } from "to-words";

export const generateQuoteApproval = (data) => {
  try {
    let bodyData = [];
    for (let i = 0; i < data.quote.labor.length; i++) {
      bodyData.push([
        data.quote.labor[i].hours,
        data.quote.labor[i].rate,
        data.quote.labor[i].description,
        "$" + data.quote.labor[i].perHour.toFixed(2),
        "$" + data.quote.labor[i].extPrice.toFixed(2),
      ]);
    }
    let partsData = [];
    for (let i = 0; i < data.quote.parts.length; i++) {
      partsData.push([
        data.quote.parts[i].quantity,
        data.quote.parts[i].partNo,
        data.quote.parts[i].description.slice(0, 50) + data.quote.parts[i].description.length > 50 ? "..." : "",
        "$" + data.quote.parts[i].cost.toFixed(2),
        "$" + (data.quote.parts[i].cost * data.quote.parts[i].quantity).toFixed(2),
        "$" + data.quote.parts[i].pricePerPart.toFixed(2),
        "$" + data.quote.parts[i].extPrice.toFixed(2),
      ]);
    }
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgXPos = pageWidth / 2 - 100;
    doc.addImage("hitechLogo.png", "PNG", imgXPos, 30, 200, 54, "hitechLogo", "NONE", 0);
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#000000");
    doc.setDrawColor("#000000");
    doc.setFontSize(12);
    doc.text("QUOTE APPROVAL", pageWidth / 2, 85, { align: "center" });
    doc.setLineWidth(0.8);
    doc.line(50, 95, pageWidth - 50, 95);
    doc.setFontSize(10);
    doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 110, {
      align: "center",
    });
    doc.setFont("Helvetica", "bold");
    doc.text(data.customer.customerCode + (data.customer.company.length > 0 ? ", " + data.customer.company.toUpperCase() : ""), 50, 170, {
      align: "left",
    });
    doc.setFont("Helvetica", "normal");
    doc.text(
      data.customer.arData.name && data.customer.arData.name.length > 0
        ? data.customer.arData.name.toUpperCase()
        : data.customer.contact.firstName + " " + data.customer.contact.lastName,
      50,
      181,
      {
        align: "left",
      },
    );
    let custAddr = data.customer.contact.address.toUpperCase();
    if (data.customer.contact.address2 && data.customer.contact.address2.length > 0) {
      custAddr += ", " + data.customer.contact.address2.toUpperCase();
    }
    doc.text(custAddr, 50, 192, {
      align: "left",
    });
    let custAddr2 = `${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`;
    if (custAddr2.length < 5) {
      custAddr2 = `${data.customer.contact.city.toUpperCase()}, ${data.customer.contact.state.toUpperCase()} ${data.customer.contact.zip}`;
    }
    doc.text(custAddr2, 50, 203, {
      align: "left",
    });
    let custEmail = data.customer.arData.email.toUpperCase();
    if (custEmail.length < 2) {
      custEmail = data.customer.contact.email.toUpperCase();
    }
    if (custEmail.length > 2) {
      custEmail += " ";
    }
    if (data.customer.arData.phoneNumber.length === 10) {
      custEmail += formatPhoneNumber(data.customer.arData.phoneNumber);
    } else if (data.customer.contact.phoneNumber.length === 10) {
      custEmail += formatPhoneNumber(data.customer.contact.phoneNumber);
    }
    doc.text(custEmail, 50, 214, {
      align: "left",
    });
    doc.text(`Date of Estimate: ${dayjs(data.quote.date).format("MM/DD/YYYY")}`, 562, 130, {
      align: "right",
    });
    doc.text(`Estimate Expires: ${dayjs(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 144, {
      align: "right",
    });
    doc.line(50, 210, pageWidth - 50, 210);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(8);
    doc.text(data.quote.description, pageWidth / 2, 224, {
      align: "center",
    });
    doc.setFont("Helvetica", "normal");
    let quoteDetails = doc.splitTextToSize(data.quote.details, 420);
    let x = 224;
    for (let i = 0; i < quoteDetails.length; i++) {
      x += 10;
      doc.text(quoteDetails[i], pageWidth / 2, x, {
        align: "center",
      });
    }
    x += 10;
    doc.setTextColor("#3d4044");
    doc.text(data.quote.eoNotes, pageWidth / 2, x, { align: "center" });
    doc.setTextColor("#000000");
    x += 10;
    doc.line(50, x, pageWidth - 50, x);
    x += 5;
    doc.line(50, x, pageWidth - 50, x);
    x += 15;
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.text("Labor", 60, x, { align: "left" });
    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal");
    x += 5;
    doc.autoTable({
      headStyles: {
        fillColor: "#ffffff",
        textColor: "#000000",
      },
      columnStyles: {
        0: { halign: "left", columnWidth: 45 },
        1: { halign: "left", columnWidth: 60 },
        2: { cellWidth: "auto" },
        3: { halign: "right", columnWidth: 60 },
        4: { halign: "right", columnWidth: 60 },
      },
      styles: { fontSize: 8, rowHeight: 2, cellPadding: 2 },
      theme: "plain",
      head: [
        {
          hours: "Hours",
          laborRate: "Labor Rate",
          description: "Description of Labor",
          perHour: "Per Hour",
          extPrice: "Ext. Price",
        },
      ],
      body: bodyData,
      margin: { top: x, left: 60, right: 60, bottom: 15 },
      didDrawPage: (d) => {
        x = d.cursor.y + 20;
      },
      didParseCell: (hookData) => {
        if (hookData.section === "head") {
          let acceptable = ["perHour", "extPrice"];
          if (acceptable.includes(hookData.column.dataKey)) {
            hookData.cell.styles.halign = "right";
          }
        }
      },
    });
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.text("Parts", 60, x - 5, { align: "left" });
    x += 5;
    doc.autoTable({
      headStyles: {
        fillColor: "#ffffff",
        textColor: "#000000",
        halign: "center",
      },
      columnStyles: {
        0: { cellWidth: "auto", halign: "right" },
        1: { cellWidth: "auto", halign: "right" },
        2: { cellWidth: "auto", halign: "left" },
        3: { cellWidth: "auto", halign: "center" },
        4: { cellWidth: "auto", halign: "right" },
        5: { cellWidth: "auto", halign: "right" },
        6: { cellWidth: "auto", halign: "right" },
      },
      styles: {
        fontSize: 8,
        rowHeight: 2,
        cellPadding: 2,
      },
      theme: "plain",
      head: [
        {
          qnty: "Qnty",
          partNo: "Part #",
          description: "Description",
          cost: "Cost", // Price Purchased At per part
          extCost: "Ext. Cost", // Price purchased at total
          each: "Each", // Extended Price
          extPrice: "Ext. Price", //Extended Total
        },
      ],
      body: partsData,
      margin: { top: x, left: 60, right: 60, bottom: 15 },
      didParseCell: (hookData) => {
        if (hookData.section === "head") {
          let acceptable = ["extCost", "each", "extPrice", "qnty", "partNo"];
          if (acceptable.includes(hookData.column.dataKey)) {
            hookData.cell.styles.halign = "right";
          } else if (hookData.column.dataKey === "cost") {
            hookData.cell.styles.halign = "center";
          } else {
            hookData.cell.styles.halign = "left";
          }
        }
      },
      didDrawPage: (d) => {
        x = d.cursor.y + 20;
      },
    });
    x += 20;
    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");
    doc.text("Labor Total:", 500, x, { align: "right" });
    doc.text("Parts Total:", 500, x + 18, { align: "right" });
    doc.text("Gas, Oil & Grease Total:", 500, x + 36, { align: "right" });
    doc.text("Shop Fee:", 500, x + 54, { align: "right" });
    doc.text("Estimate Total:", 500, x + 72, { align: "right" });
    doc.setFont("Helvetica", "normal");

    //Labor Total
    let laborTotal = 0;
    for (let i = 0; i < data.quote.labor.length; i++) {
      laborTotal += data.quote.labor[i].extPrice;
    }
    doc.text(`$${laborTotal.toFixed(2)}`, 550, x, { align: "right" });
    //Parts Total
    let partsTotal = 0;
    for (let i = 0; i < data.quote.parts.length; i++) {
      partsTotal += data.quote.parts[i].extPrice;
    }
    doc.text(`$${partsTotal.toFixed(2)}`, 550, x + 18, { align: "right" });
    //Gas, Oil & Grease Total
    let gasOilGreaseTotal = 0;
    for (let i = 0; i < data.quote.gasOilGrease.length; i++) {
      gasOilGreaseTotal += data.quote.gasOilGrease[i].extPrice;
    }
    doc.text(`$${gasOilGreaseTotal.toFixed(2)}`, 550, x + 36, {
      align: "right",
    });
    //Shop Fee
    let shopFeeTotal = data.quote.shopFees;
    doc.text(`${formatCurrency(shopFeeTotal)}`, 550, x + 54, {
      align: "right",
    });
    doc.setFont("Helvetica", "bold");
    //Estimate Total
    let estimateTotal = 0;
    estimateTotal = laborTotal + partsTotal + gasOilGreaseTotal + shopFeeTotal;
    doc.text(`$${estimateTotal.toFixed(2)}`, 550, x + 72, { align: "right" });
    x += 100;
    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");
    x += 10;
    for (let i = 0; i < data.quote.footer.length; i++) {
      doc.text(data.quote.footer[i], pageWidth / 2, x, { align: "center" });
      x += 12;
    }
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    if (pageCount > 1) {
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        pageWidth = doc.internal.pageSize.getWidth();
        pageHeight = doc.internal.pageSize.getHeight();
        let txWid = doc.getTextWidth(`Page ${i} of ${pageCount}`);
        doc.text(`Page ${i} of ${pageCount}`, pageWidth / 2 - txWid / 2, pageHeight - 18, {
          align: "center",
        });
      }
    }
    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const generateEstimate = async (data, detail) => {
  try {
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgWidth = 315.12;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
    doc.setDrawColor("#000000");
    doc.setLineWidth(0.8);
    doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
    doc.setLineWidth(2);
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.setTextColor("#000000");
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
      align: "center",
    });
    doc.text("Augusta, GA 30906", 254, 104, {
      align: "center",
    });
    doc.text("Tel: (706) 790-8111", 254, 115, {
      align: "center",
    });
    doc.text("Fax: (706) 790-4368", 254, 126, {
      align: "center",
    });
    doc.text("1182 Edgefield Road", 360, 93, {
      align: "center",
    });
    doc.text("North Augusta, SC 29841", 360, 104, {
      align: "center",
    });
    doc.text("Tel: (803) 613-0101", 360, 115, {
      align: "center",
    });
    doc.text("Fax: (803) 613-0323", 360, 126, {
      align: "center",
    });
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.line(50, 135, pageWidth - 50, 135);
    doc.text("ESTIMATE FOR SERVICES", pageWidth / 2, 149, {
      align: "center",
    });
    doc.line(50, 155, pageWidth - 50, 155);
    doc.setFontSize(9);
    doc.text(data.customer.customerCode + (data.customer.company.length > 0 ? ", " + data.customer.company.toUpperCase() : ""), 50, 170, {
      align: "left",
    });
    doc.setFont("Helvetica", "normal");
    doc.text(
      data.customer.arData.name && data.customer.arData.name.length > 0
        ? data.customer.arData.name.toUpperCase()
        : data.customer.contact.firstName + " " + data.customer.contact.lastName,
      50,
      181,
      {
        align: "left",
      },
    );
    let custAddr = data.customer.contact.address.toUpperCase();
    if (data.customer.contact.address2 && data.customer.contact.address2.length > 0) {
      custAddr += ", " + data.customer.contact.address2.toUpperCase();
    }
    doc.text(custAddr, 50, 192, {
      align: "left",
    });
    let custAddr2 = `${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`;
    if (custAddr2.length < 5) {
      custAddr2 = `${data.customer.contact.city.toUpperCase()}, ${data.customer.contact.state.toUpperCase()} ${data.customer.contact.zip}`;
    }
    doc.text(custAddr2, 50, 203, {
      align: "left",
    });
    let custEmail = data.customer.arData.email.toUpperCase();
    if (custEmail.length < 2) {
      custEmail = data.customer.contact.email.toUpperCase();
    }
    if (custEmail.length > 2) {
      custEmail += " ";
    }
    if (data.customer.arData.phoneNumber.length === 10) {
      custEmail += formatPhoneNumber(data.customer.arData.phoneNumber);
    } else if (data.customer.contact.phoneNumber.length === 10) {
      custEmail += formatPhoneNumber(data.customer.contact.phoneNumber);
    }
    doc.text(custEmail, 50, 214, {
      align: "left",
    });
    doc.text(`Date of Estimate: ${dayjs(data.quote.date).format("MM/DD/YYYY")}`, 562, 170, {
      align: "right",
    });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      doc.text(`Estimate Approved On: ${dayjs(data.quote.acceptance.approvedDate).format("MM/DD/YYYY")}`, 562, 181, {
        align: "right",
      });
    } else {
      doc.text(`Estimate Expires: ${dayjs(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 181, {
        align: "right",
      });
    }
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 230, {
      align: "center",
    });
    doc.line(50, 237, pageWidth - 50, 237);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(9);
    doc.text(data.quote.description.toUpperCase(), pageWidth / 2, 250, {
      align: "center",
    });
    let quoteDetails = doc.splitTextToSize(data.quote.details, 420);
    let x = 261;
    doc.setFont("Helvetica", "normal");
    for (let i = 0; i < quoteDetails.length; i++) {
      doc.text(quoteDetails[i].toUpperCase(), pageWidth / 2, x, {
        align: "center",
      });
      x += 11;
    }
    x -= 4;
    doc.line(50, x, pageWidth - 50, x);
    x += 6;
    doc.line(50, x, pageWidth - 50, x);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    if (detail === "none") {
      x += 50;
      doc.text("TOTAL ESTIMATE", pageWidth / 2, x, {
        align: "center",
      });
      x += 14;
      let estimateTotal = 0;
      for (let i = 0; i < data.quote.labor.length; i++) {
        estimateTotal += data.quote.labor[i].extPrice;
      }
      for (let i = 0; i < data.quote.parts.length; i++) {
        estimateTotal += data.quote.parts[i].extPrice;
      }
      for (let i = 0; i < data.quote.misc.length; i++) {
        estimateTotal += data.quote.misc[i].chargeOutPrice;
      }
      for (let i = 0; i < data.quote.freight.length; i++) {
        estimateTotal += data.quote.freight[i].freightChargeOut;
      }
      for (let i = 0; i < data.quote.loadbank.length; i++) {
        estimateTotal += data.quote.loadbank[i].unitCharge;
      }
      for (let i = 0; i < data.quote.parking.length; i++) {
        estimateTotal += data.quote.parking[i].totalCharge;
      }
      doc.text(`$${parseFloat(estimateTotal.toFixed(2)).toLocaleString()}`, pageWidth / 2, x, {
        align: "center",
      });
      doc.setLineWidth(0.8);
      let txWid = doc.getTextWidth(`$${parseFloat(estimateTotal.toFixed(2)).toLocaleString()}`);
      x += 2;
      doc.line(pageWidth / 2 - txWid / 2 - 3, x, pageWidth / 2 + txWid / 2 + 3, x);
      doc.setLineWidth(1);
      x += 48;
    } else if (detail === "group") {
      x += 20;
      let laborTotal = 0;
      let partsTotal = 0;
      let gogTotal = 0;
      let miscTotal = 0;
      let estimateTotal = 0;
      for (let i = 0; i < data.quote.labor.length; i++) {
        laborTotal += data.quote.labor[i].extPrice;
      }
      for (let i = 0; i < data.quote.parts.length; i++) {
        if (data.quote.parts[i].category !== "gog") {
          partsTotal += data.quote.parts[i].extPrice;
        } else {
          gogTotal += data.quote.parts[i].extPrice;
        }
      }
      for (let i = 0; i < data.quote.misc.length; i++) {
        let el = data.quote.misc[i];
        if (el.miscType === "service") {
          laborTotal += el.chargeOutPrice;
        } else if (el.miscType === "part") {
          partsTotal += el.chargeOutPrice;
        }
      }
      for (let i = 0; i < data.quote.freight.length; i++) {
        miscTotal += data.quote.freight[i].freightChargeOut;
      }
      for (let i = 0; i < data.quote.loadbank.length; i++) {
        miscTotal += data.quote.loadbank[i].unitCharge;
      }
      for (let i = 0; i < data.quote.parking.length; i++) {
        miscTotal += data.quote.parking[i].totalCharge;
      }
      estimateTotal = laborTotal + partsTotal + gogTotal + miscTotal;
      laborTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(laborTotal);
      partsTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(partsTotal);
      gogTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(gogTotal);
      miscTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(miscTotal);
      estimateTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(estimateTotal);

      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("Labor Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${laborTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Parts Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${partsTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Gas, Oil & Grease Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${gogTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Misc. Fees Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${miscTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Shop Fee:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(formatCurrency(data.quote.shopFees || 0), pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Estimate Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.text(`${estimateTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });

      x += 20;
    } else if (detail === "all") {
      x += 20;
      let laborTotal = 0;
      let partsTotal = 0;
      let gogTotal = 0;
      let miscTotal = 0;
      let shopFeeTotal = 0;
      let estimateTotal = 0;

      let laborItems = [];
      let partsItems = [];
      let miscItems = [];

      for (let i = 0; i < data.quote.labor.length; i++) {
        laborTotal += data.quote.labor[i].extPrice;
        let el = data.quote.labor[i];
        laborItems.push([
          el.hours,
          data.rates.find((r) => r.rateId === el.rate).laborCode ?? "Labor",
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.perHour),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.extPrice),
        ]);
      }
      for (let i = 0; i < data.quote.parts.length; i++) {
        if (data.quote.parts[i].category !== "gog") {
          partsTotal += data.quote.parts[i].extPrice;
        } else {
          gogTotal += data.quote.parts[i].extPrice;
        }
        let el = data.quote.parts[i];
        partsItems.push([
          el.quantity,
          el.partNo,
          el.description.slice(0, 50) + el.description.length > 50 ? "..." : "",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.pricePerPart),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.extPrice),
        ]);
      }
      for (let i = 0; i < data.quote.misc.length; i++) {
        let el = data.quote.misc[i];
        if (el.miscType === "service") {
          laborTotal += el.chargeOutPrice;
          laborItems.push([
            el.quantity,
            "SPEC-SERV",
            el.description,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(el.chargeOutPrice / el.quantity),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(el.chargeOutPrice),
          ]);
        } else if (el.miscType === "part") {
          partsTotal += el.chargeOutPrice;
          partsItems.push([
            el.quantity,
            "SPEC-PART",
            el.description,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(el.chargeOutPrice / el.quantity),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(el.chargeOutPrice),
          ]);
        }
      }
      for (let i = 0; i < data.quote.freight.length; i++) {
        miscTotal += data.quote.freight[i].freightChargeOut;
        let el = data.quote.freight[i];
        miscItems.push([
          "1",
          "FREIGHT FEE",
          "Freight Charge",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.freightChargeOut),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.freightChargeOut),
        ]);
      }
      for (let i = 0; i < data.quote.loadbank.length; i++) {
        miscTotal += data.quote.loadbank[i].unitCharge;
        let el = data.quote.loadbank[i];
        miscItems.push([
          el.quantity,
          "LOADBANK FEE",
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.unitCharge),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.unitCharge),
        ]);
      }
      for (let i = 0; i < data.quote.parking.length; i++) {
        miscTotal += data.quote.parking[i].totalCharge;
        let el = data.quote.parking[i];
        miscItems.push([
          el.quantity,
          el.parkingType.toUpperCase() + " FEE",
          el.increment.toUpperCase() + " " + el.parkingType.toUpperCase() + " FEE",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.unitCharge),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.totalCharge),
        ]);
      }
      shopFeeTotal = data.quote.shopFees || 0;
      estimateTotal = laborTotal + partsTotal + gogTotal + miscTotal + shopFeeTotal;
      laborTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(laborTotal);
      partsTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(partsTotal);
      gogTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(gogTotal);
      miscTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(miscTotal);
      shopFeeTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(shopFeeTotal);
      estimateTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(estimateTotal);
      if (laborItems.length > 0) {
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("Labor ", 60, x, {
          align: "left",
        });

        doc.autoTable({
          headStyles: {
            fillColor: "#ffffff",
            textColor: "#000000",
          },
          columnStyles: {
            0: { halign: "center", cellWidth: "auto" },
            1: { halign: "left", cellWidth: "auto" },
            2: { cellWidth: "auto" },
            3: { halign: "right", cellWidth: "auto" },
            4: { halign: "right", cellWidth: "auto" },
          },
          styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
          theme: "plain",
          head: [
            [
              { content: "Hours", styles: { halign: "left" } },
              { content: "Labor Rate", styles: { halign: "left" } },
              { content: "Description", styles: { halign: "left" } },
              { content: "Per Hour", styles: { halign: "right" } },
              { content: "Ext. Price", styles: { halign: "right" } },
            ],
          ],
          body: laborItems,
          margin: { top: x + 10, left: 70, right: 70, bottom: 15 },
        });
        x = doc.lastAutoTable.finalY + 15;
      }
      if (partsItems.length > 0) {
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("Parts ", 60, x, {
          align: "left",
        });
        x += 10;

        doc.autoTable({
          startY: x,
          headStyles: {
            fillColor: "#ffffff",
            textColor: "#000000",
          },
          columnStyles: {
            0: { halign: "center", cellWidth: "auto" },
            1: { halign: "left", cellWidth: "auto" },
            2: { cellWidth: "auto" },
            3: { halign: "right", cellWidth: "auto" },
            4: { halign: "right", cellWidth: "auto" },
          },
          styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
          theme: "plain",
          head: [
            [
              { content: "Quantity", styles: { halign: "left" } },
              { content: "Part No", styles: { halign: "left" } },
              { content: "Description", styles: { halign: "left" } },
              { content: "Unit Price", styles: { halign: "right" } },
              { content: "Ext. Price", styles: { halign: "right" } },
            ],
          ],
          body: partsItems,
          margin: { top: 0, left: 70, right: 70, bottom: 15 },
        });
        x = doc.lastAutoTable.finalY + 20;
      }
      if (miscItems.length > 0) {
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("Misc. Fees ", 60, x, {
          align: "left",
        });
        x += 10;

        doc.autoTable({
          startY: x,
          headStyles: {
            fillColor: "#ffffff",
            textColor: "#000000",
          },
          columnStyles: {
            0: { halign: "center", cellWidth: "auto" },
            1: { halign: "left", cellWidth: "auto" },
            2: { cellWidth: "auto" },
            3: { halign: "right", cellWidth: "auto" },
            4: { halign: "right", cellWidth: "auto" },
          },
          styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
          theme: "plain",
          head: [
            [
              { content: "Quantity", styles: { halign: "left" } },
              { content: "Fee Code", styles: { halign: "left" } },
              { content: "Description", styles: { halign: "left" } },
              { content: "Unit Price", styles: { halign: "right" } },
              { content: "Ext. Price", styles: { halign: "right" } },
            ],
          ],
          body: miscItems,
          margin: { top: 0, left: 70, right: 70, bottom: 15 },
        });
        x = doc.lastAutoTable.finalY + 20;
      }
      if (x > 690) {
        doc.addPage();
        let imgWidth = 315.12;
        let imgXPos = pageWidth / 2 - imgWidth / 2;
        doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
        doc.setDrawColor("#000000");
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.setLineWidth(0.8);
        doc.setTextColor("#000000");
        doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
        doc.setLineWidth(2);
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(8);
        doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
          align: "center",
        });
        doc.text("Augusta, GA 30906", 254, 104, {
          align: "center",
        });
        doc.text("Tel: (706) 790-8111", 254, 115, {
          align: "center",
        });
        doc.text("Fax: (706) 790-4368", 254, 126, {
          align: "center",
        });
        doc.text("1182 Edgefield Road", 360, 93, {
          align: "center",
        });
        doc.text("North Augusta, SC 29841", 360, 104, {
          align: "center",
        });
        doc.text("Tel: (803) 613-0101", 360, 115, {
          align: "center",
        });
        doc.text("Fax: (803) 613-0323", 360, 126, {
          align: "center",
        });
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.line(50, 135, pageWidth - 50, 135);
        doc.text("ESTIMATE FOR SERVICES", pageWidth / 2, 149, {
          align: "center",
        });
        doc.line(50, 155, pageWidth - 50, 155);
        doc.setFontSize(9);
        doc.text(data.customer.customerCode + (data.customer.company.length > 0 ? ", " + data.customer.company.toUpperCase() : ""), 50, 170, {
          align: "left",
        });
        doc.setFont("Helvetica", "normal");
        doc.text(
          data.customer.arData.name && data.customer.arData.name.length > 0
            ? data.customer.arData.name.toUpperCase()
            : data.customer.contact.firstName + " " + data.customer.contact.lastName,
          50,
          181,
          {
            align: "left",
          },
        );
        let custAddr = data.customer.contact.address.toUpperCase();
        if (data.customer.contact.address2 && data.customer.contact.address2.length > 0) {
          custAddr += ", " + data.customer.contact.address2.toUpperCase();
        }
        doc.text(custAddr, 50, 192, {
          align: "left",
        });
        let custAddr2 = `${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`;
        if (custAddr2.length < 5) {
          custAddr2 = `${data.customer.contact.city.toUpperCase()}, ${data.customer.contact.state.toUpperCase()} ${data.customer.contact.zip}`;
        }
        doc.text(custAddr2, 50, 203, {
          align: "left",
        });
        let custEmail = data.customer.arData.email.toUpperCase();
        if (custEmail.length < 2) {
          custEmail = data.customer.contact.email.toUpperCase();
        }
        if (custEmail.length > 2) {
          custEmail += " ";
        }
        if (data.customer.arData.phoneNumber.length === 10) {
          custEmail += formatPhoneNumber(data.customer.arData.phoneNumber);
        } else if (data.customer.contact.phoneNumber.length === 10) {
          custEmail += formatPhoneNumber(data.customer.contact.phoneNumber);
        }
        doc.text(custEmail, 50, 214, {
          align: "left",
        });
        doc.text(`Date of Estimate: ${dayjs(data.quote.date).format("MM/DD/YYYY")}`, 562, 170, {
          align: "right",
        });
        doc.text(`Estimate Expires: ${dayjs(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 181, {
          align: "right",
        });
        doc.setFont("Helvetica", "bold");
        doc.setFontSize(12);
        doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 230, {
          align: "center",
        });
        doc.line(50, 237, pageWidth - 50, 237);
        x = 250;
      }
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("Labor Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${laborTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Parts Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${partsTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Gas, Oil & Grease Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${gogTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Misc. Fees Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${miscTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Shop Fee Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${shopFeeTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Estimate Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.text(`${estimateTotal}`, pageWidth - 70, x, {
        align: "right",
      });

      x += 20;
    }

    if (data.quote.equipment.length > 0) {
      data.quote.equipment.forEach((el) => {
        let oneEq = data.equipment.find((e) => e.equipmentId === el);
        if (oneEq) {
          doc.setFontSize(12);
          doc.setFont("Helvetica", "bold");
          doc.text("Equipment", 60, x, {
            align: "left",
          });
          doc.setFont("Helvetica", "normal");
          x += 14;
          let equipmentItems = [];
          let types = {
            generator: "Generator",
            pressureWasher: "Pressure Washer",
            truck: "Truck",
            trailer: "Trailer",
            welder: "Welder",
            airCompressor: "Air Compressor",
            other: "Other",
          };
          equipmentItems.push([["Type:"], [types[oneEq.equipmentType]]]);
          equipmentItems.push([
            ["Customer Equip. ID:"],
            [oneEq.customerEquipmentId && oneEq.customerEquipmentId.length > 0 ? oneEq.customerEquipmentId : "N/A"],
          ]);
          equipmentItems.push([["Location:"], [oneEq.location && oneEq.location.length > 0 ? oneEq.location : "N/A"]]);
          equipmentItems.push([["Make:"], [oneEq.details.make && oneEq.details.make.length > 0 ? oneEq.details.make : "N/A"]]);
          equipmentItems.push([["Model:"], [oneEq.details.model && oneEq.details.model.length > 0 ? oneEq.details.model : "N/A"]]);
          equipmentItems.push([["Spec No:"], [oneEq.details.specNo && oneEq.details.specNo.length > 0 ? oneEq.details.specNo : "N/A"]]);
          equipmentItems.push([["Serial No:"], [oneEq.details.serialNo && oneEq.details.serialNo.length > 0 ? oneEq.details.serialNo : "N/A"]]);
          equipmentItems.push([
            ["Miles / Hours:"],
            [oneEq.details.miles && oneEq.details.miles > 0 ? `${oneEq.details.miles} ${oneEq.milesUnit.toUpperCase()}` : "N/A"],
          ]);
          doc.autoTable({
            startY: x,
            headStyles: {
              fillColor: "#ffffff",
              textColor: "#000000",
            },
            columnStyles: {
              0: { halign: "left", cellWidth: "auto" },
              1: { halign: "right", cellWidth: "auto" },
            },
            styles: { fontSize: 10, minCellHeight: 12, cellPadding: 5 },
            theme: "grid",
            head: [],
            body: equipmentItems,
            margin: { top: 0, left: 65, right: 65, bottom: 25 },
          });
          x = doc.lastAutoTable.finalY + 20;
        }
      });
    }

    doc.line(50, x, pageWidth - 50, x);
    if (x > 690) {
      doc.addPage();
      let imgWidth = 315.12;
      let imgXPos = pageWidth / 2 - imgWidth / 2;
      doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.setLineWidth(0.8);
      doc.setTextColor("#000000");
      doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
      doc.setLineWidth(2);
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
        align: "center",
      });
      doc.text("Augusta, GA 30906", 254, 104, {
        align: "center",
      });
      doc.text("Tel: (706) 790-8111", 254, 115, {
        align: "center",
      });
      doc.text("Fax: (706) 790-4368", 254, 126, {
        align: "center",
      });
      doc.text("1182 Edgefield Road", 360, 93, {
        align: "center",
      });
      doc.text("North Augusta, SC 29841", 360, 104, {
        align: "center",
      });
      doc.text("Tel: (803) 613-0101", 360, 115, {
        align: "center",
      });
      doc.text("Fax: (803) 613-0323", 360, 126, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.line(50, 135, pageWidth - 50, 135);
      doc.text("ESTIMATE FOR SERVICES", pageWidth / 2, 149, {
        align: "center",
      });
      doc.line(50, 155, pageWidth - 50, 155);
      doc.setFontSize(9);
      doc.text(data.customer.customerCode + (data.customer.company.length > 0 ? ", " + data.customer.company.toUpperCase() : ""), 50, 170, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(
        data.customer.arData.name && data.customer.arData.name.length > 0
          ? data.customer.arData.name.toUpperCase()
          : data.customer.contact.firstName + " " + data.customer.contact.lastName,
        50,
        181,
        {
          align: "left",
        },
      );
      let custAddr = data.customer.contact.address.toUpperCase();
      if (data.customer.contact.address2 && data.customer.contact.address2.length > 0) {
        custAddr += ", " + data.customer.contact.address2.toUpperCase();
      }
      doc.text(custAddr, 50, 192, {
        align: "left",
      });
      let custAddr2 = `${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`;
      if (custAddr2.length < 5) {
        custAddr2 = `${data.customer.contact.city.toUpperCase()}, ${data.customer.contact.state.toUpperCase()} ${data.customer.contact.zip}`;
      }
      doc.text(custAddr2, 50, 203, {
        align: "left",
      });
      let custEmail = data.customer.arData.email.toUpperCase();
      if (custEmail.length < 2) {
        custEmail = data.customer.contact.email.toUpperCase();
      }
      if (custEmail.length > 2) {
        custEmail += " ";
      }
      if (data.customer.arData.phoneNumber.length === 10) {
        custEmail += formatPhoneNumber(data.customer.arData.phoneNumber);
      } else if (data.customer.contact.phoneNumber.length === 10) {
        custEmail += formatPhoneNumber(data.customer.contact.phoneNumber);
      }
      doc.text(custEmail, 50, 214, {
        align: "left",
      });
      doc.text(`Date of Estimate: ${dayjs(data.quote.date).format("MM/DD/YYYY")}`, 562, 170, {
        align: "right",
      });
      doc.text(`Estimate Expires: ${dayjs(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 181, {
        align: "right",
      });
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 230, {
        align: "center",
      });
      doc.line(50, 237, pageWidth - 50, 237);
      x = 250;
    }
    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");
    x += 12;
    for (let i = 0; i < data.quote.footer.length; i++) {
      doc.text("*** " + data.quote.footer[i].toUpperCase() + " ***", pageWidth / 2, x, {
        align: "center",
      });
      x += 12;
    }
    let terms = "";
    if (data.quote.paymentTerms === "net10") {
      terms = "NET 10";
    } else if (data.quote.paymentTerms === "net20") {
      terms = "NET 20";
    } else if (data.quote.paymentTerms === "net30") {
      terms = "NET 30";
    } else if (data.quote.paymentTerms === "collectOnDelivery") {
      terms = "COLLECT ON DELIVERY";
    } else if (data.quote.paymentTerms === "dueOnReceipt") {
      terms = "DUE ON RECEIPT";
    } else if (data.quote.paymentTerms === "prePay") {
      terms = "PAY UPFRONT";
    }
    doc.setFont("Helvetica", "bold");
    doc.text(`*** TERMS: ${terms} ***`, pageWidth / 2, x, {
      align: "center",
    });
    x += 6;
    doc.setLineWidth(2);
    doc.line(50, x, pageWidth - 50, x);
    if (x > 520) {
      doc.addPage();
      let imgWidth = 315.12;
      let imgXPos = pageWidth / 2 - imgWidth / 2;
      doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor("#ff0000");
      doc.setLineWidth(0.8);
      doc.setTextColor("#000000");
      doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
      doc.setLineWidth(2);
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
        align: "center",
      });
      doc.text("Augusta, GA 30906", 254, 104, {
        align: "center",
      });
      doc.text("Tel: (706) 790-8111", 254, 115, {
        align: "center",
      });
      doc.text("Fax: (706) 790-4368", 254, 126, {
        align: "center",
      });
      doc.text("1182 Edgefield Road", 360, 93, {
        align: "center",
      });
      doc.text("North Augusta, SC 29841", 360, 104, {
        align: "center",
      });
      doc.text("Tel: (803) 613-0101", 360, 115, {
        align: "center",
      });
      doc.text("Fax: (803) 613-0323", 360, 126, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.line(50, 135, pageWidth - 50, 135);
      doc.text("ESTIMATE FOR SERVICES", pageWidth / 2, 149, {
        align: "center",
      });
      doc.line(50, 155, pageWidth - 50, 155);
      doc.setFontSize(9);
      doc.text(data.customer.customerCode + (data.customer.company.length > 0 ? ", " + data.customer.company.toUpperCase() : ""), 50, 170, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(
        data.customer.arData.name && data.customer.arData.name.length > 0
          ? data.customer.arData.name.toUpperCase()
          : data.customer.contact.firstName + " " + data.customer.contact.lastName,
        50,
        181,
        {
          align: "left",
        },
      );
      let custAddr = data.customer.contact.address.toUpperCase();
      if (data.customer.contact.address2 && data.customer.contact.address2.length > 0) {
        custAddr += ", " + data.customer.contact.address2.toUpperCase();
      }
      doc.text(custAddr, 50, 192, {
        align: "left",
      });
      let custAddr2 = `${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`;
      if (custAddr2.length < 5) {
        custAddr2 = `${data.customer.contact.city.toUpperCase()}, ${data.customer.contact.state.toUpperCase()} ${data.customer.contact.zip}`;
      }
      doc.text(custAddr2, 50, 203, {
        align: "left",
      });
      let custEmail = data.customer.arData.email.toUpperCase();
      if (custEmail.length < 2) {
        custEmail = data.customer.contact.email.toUpperCase();
      }
      if (custEmail.length > 2) {
        custEmail += " ";
      }
      if (data.customer.arData.phoneNumber.length === 10) {
        custEmail += formatPhoneNumber(data.customer.arData.phoneNumber);
      } else if (data.customer.contact.phoneNumber.length === 10) {
        custEmail += formatPhoneNumber(data.customer.contact.phoneNumber);
      }
      doc.text(custEmail, 50, 214, {
        align: "left",
      });
      doc.text(`Date of Estimate: ${dayjs(data.quote.date).format("MM/DD/YYYY")}`, 562, 170, {
        align: "right",
      });
      doc.text(`Estimate Expires: ${dayjs(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 181, {
        align: "right",
      });
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 230, {
        align: "center",
      });
      doc.line(50, 237, pageWidth - 50, 237);
      x = 250;
    }
    x += 12;
    doc.setFontSize(8);
    doc.text(`Customer Acceptance:`, 70, x, {
      align: "left",
    });
    let xPos = 70 + doc.getTextWidth("Customer Acceptance:") + 10;
    doc.setFont("Helvetica", "normal");
    let acceptanceTerms = `By approval below, we accept the above bill of material, price, and terms, as described in Hi-Tech Power Systems Estimate # ${data.quote.quoteNo}, and request that the order be released for production.`;
    var splitTitle = doc.splitTextToSize(acceptanceTerms, pageWidth - 220);
    for (let i = 0; i < splitTitle.length; i++) {
      doc.text(splitTitle[i], xPos, x, {
        align: "left",
      });
      x += 10;
    }
    x += 40;
    doc.setLineWidth(1);
    let sigWid = pageWidth / 3;
    doc.line(60, x, sigWid + 60, x);
    doc.line(pageWidth - sigWid - 60, x, pageWidth - 60, x);
    doc.setFontSize(7);
    doc.text("Company Name", 60, x + 8, { align: "left" });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      doc.setFontSize(10);
      doc.text(data.quote.acceptance.companyName, 60, x - 10, {
        align: "left",
      });
      doc.setFontSize(7);
    }
    doc.text("Date", pageWidth - sigWid - 60, x + 8, { align: "left" });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      doc.setFontSize(10);
      doc.text(dayjs(data.quote.acceptance.approvedDate).format("MM/DD/YYYY"), pageWidth - sigWid - 60, x - 10, {
        align: "left",
      });
      doc.setFontSize(7);
    }
    x += 40;
    doc.line(60, x, sigWid + 60, x);
    doc.line(pageWidth - sigWid - 60, x, pageWidth - 60, x);
    doc.text("Approved By (Signature)", 60, x + 8, { align: "left" });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      if (data.quote.acceptance.signatureImage.length > 0) {
        let signatureURL = data.quote.acceptance.signatureImage;
        let dataUrl = await getBase64FromUrl(signatureURL);
        let imgWid = 111;
        let left = 60 + (sigWid - imgWid) / 2;
        doc.addImage(dataUrl, "PNG", left, x - 50, imgWid, 50, "Signature", "NONE", 0);
      }
    }
    doc.text("Title", pageWidth - sigWid - 60, x + 8, { align: "left" });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      doc.setFontSize(10);
      doc.text(data.quote.acceptance.title, pageWidth - sigWid - 60, x - 10, {
        align: "left",
      });
      doc.setFontSize(7);
    }
    x += 40;
    doc.line(60, x, sigWid + 60, x);
    doc.line(pageWidth - sigWid - 60, x, pageWidth - 60, x);
    doc.text("Approved By (Print Name)", 60, x + 8, { align: "left" });
    doc.text("Approved By (Print Name)", 60, x + 8, { align: "left" });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      doc.setFontSize(10);
      doc.text(data.quote.acceptance.signedBy, 60, x - 10, {
        align: "left",
      });
      doc.setFontSize(7);
    }
    if (data.quote.poNumber && data.quote.poNumber.length > 0) {
      doc.setFontSize(9);
      doc.text(data.quote.poNumber, pageWidth - sigWid - 60, x - 5, {
        align: "left",
      });
      doc.setFontSize(7);
    }
    doc.text("Purchase Order #", pageWidth - sigWid - 60, x + 8, {
      align: "left",
    });
    if (data.quote.quoteStatus === "accepted" || data.quote.quoteStatus === "jobCreated") {
      doc.setFontSize(10);
      doc.text(data.quote.acceptance.poNumber, pageWidth - sigWid - 60, x - 10, {
        align: "left",
      });
      doc.setFontSize(7);
    }
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    if (pageCount > 1) {
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        pageWidth = doc.internal.pageSize.getWidth();
        pageHeight = doc.internal.pageSize.getHeight();
        let txWid = doc.getTextWidth(`Page ${i} of ${pageCount}`);
        doc.text(`Page ${i} of ${pageCount}`, pageWidth / 2 - txWid / 2, pageHeight - 18, {
          align: "center",
        });
      }
    }
    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const generateEstimatePreview = (data, detail) => {
  try {
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgWidth = 315.12;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
    doc.setDrawColor("#000000");
    doc.setLineWidth(0.8);
    doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
    doc.setLineWidth(2);
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.setTextColor("#ff0000");
    doc.text("INTERNAL DOCUMENT - NOT AN OFFICIAL ESTIMATE", pageWidth / 2, 22, {
      align: "center",
    });
    doc.text("INTERNAL DOCUMENT - NOT AN OFFICIAL ESTIMATE", pageWidth / 2, 760, {
      align: "center",
    });
    doc.setTextColor("#000000");
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
      align: "center",
    });
    doc.text("Augusta, GA 30906", 254, 104, {
      align: "center",
    });
    doc.text("Tel: (706) 790-8111", 254, 115, {
      align: "center",
    });
    doc.text("Fax: (706) 790-4368", 254, 126, {
      align: "center",
    });
    doc.text("1182 Edgefield Road", 360, 93, {
      align: "center",
    });
    doc.text("North Augusta, SC 29841", 360, 104, {
      align: "center",
    });
    doc.text("Tel: (803) 613-0101", 360, 115, {
      align: "center",
    });
    doc.text("Fax: (803) 613-0323", 360, 126, {
      align: "center",
    });
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.line(50, 135, pageWidth - 50, 135);
    doc.text("ESTIMATE FOR SERVICES", pageWidth / 2, 149, {
      align: "center",
    });
    doc.line(50, 155, pageWidth - 50, 155);
    doc.setFontSize(9);
    doc.text(data.customer.customerCode + (data.customer.company.length > 0 ? ", " + data.customer.company.toUpperCase() : ""), 50, 170, {
      align: "left",
    });
    doc.setFont("Helvetica", "normal");
    doc.text(
      data.customer.arData.name && data.customer.arData.name.length > 0
        ? data.customer.arData.name.toUpperCase()
        : data.customer.contact.firstName + " " + data.customer.contact.lastName,
      50,
      181,
      {
        align: "left",
      },
    );
    let custAddr = data.customer.contact.address.toUpperCase();
    if (data.customer.contact.address2 && data.customer.contact.address2.length > 0) {
      custAddr += ", " + data.customer.contact.address2.toUpperCase();
    }
    doc.text(custAddr, 50, 192, {
      align: "left",
    });
    let custAddr2 = `${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`;
    if (custAddr2.length < 5) {
      custAddr2 = `${data.customer.contact.city.toUpperCase()}, ${data.customer.contact.state.toUpperCase()} ${data.customer.contact.zip}`;
    }
    doc.text(custAddr2, 50, 203, {
      align: "left",
    });
    let custEmail = data.customer.arData.email.toUpperCase();
    if (custEmail.length < 2) {
      custEmail = data.customer.contact.email.toUpperCase();
    }
    if (custEmail.length > 2) {
      custEmail += " ";
    }
    if (data.customer.arData.phoneNumber.length === 10) {
      custEmail += formatPhoneNumber(data.customer.arData.phoneNumber);
    } else if (data.customer.contact.phoneNumber.length === 10) {
      custEmail += formatPhoneNumber(data.customer.contact.phoneNumber);
    }
    doc.text(custEmail, 50, 214, {
      align: "left",
    });
    doc.text(`Date of Estimate: ${dayjs(data.quote.date).format("MM/DD/YYYY")}`, 562, 170, {
      align: "right",
    });
    doc.text(`Estimate Expires: ${dayjs(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 181, {
      align: "right",
    });
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 230, {
      align: "center",
    });
    doc.line(50, 237, pageWidth - 50, 237);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(9);
    doc.text(data.quote.description.toUpperCase(), pageWidth / 2, 250, {
      align: "center",
    });
    let quoteDetails = doc.splitTextToSize(data.quote.details, 420);
    let x = 261;
    doc.setFont("Helvetica", "normal");
    for (let i = 0; i < quoteDetails.length; i++) {
      doc.text(quoteDetails[i].toUpperCase(), pageWidth / 2, x, {
        align: "center",
      });
      x += 11;
    }
    x -= 4;
    doc.line(50, x, pageWidth - 50, x);
    x += 6;
    doc.line(50, x, pageWidth - 50, x);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    if (detail === "none") {
      x += 50;
      doc.text("TOTAL ESTIMATE", pageWidth / 2, x, {
        align: "center",
      });
      x += 14;
      let estimateTotal = 0;
      for (let i = 0; i < data.quote.labor.length; i++) {
        estimateTotal += data.quote.labor[i].extPrice;
      }
      for (let i = 0; i < data.quote.parts.length; i++) {
        estimateTotal += data.quote.parts[i].extPrice;
      }
      for (let i = 0; i < data.quote.misc.length; i++) {
        estimateTotal += data.quote.misc[i].chargeOutPrice;
      }
      for (let i = 0; i < data.quote.parking.length; i++) {
        estimateTotal += data.quote.parking[i].totalCharge;
      }
      for (let i = 0; i < data.quote.freight.length; i++) {
        estimateTotal += data.quote.freight[i].freightChargeOut;
      }
      for (let i = 0; i < data.quote.loadbank.length; i++) {
        estimateTotal += data.quote.loadbank[i].unitCharge;
      }
      doc.text(`$${parseFloat(estimateTotal.toFixed(2)).toLocaleString()}`, pageWidth / 2, x, {
        align: "center",
      });
      doc.setLineWidth(0.8);
      let txWid = doc.getTextWidth(`$${parseFloat(estimateTotal.toFixed(2)).toLocaleString()}`);
      x += 2;
      doc.line(pageWidth / 2 - txWid / 2 - 3, x, pageWidth / 2 + txWid / 2 + 3, x);
      doc.setLineWidth(1);
      x += 48;
    } else if (detail === "group") {
      x += 20;
      let laborTotal = 0;
      let partsTotal = 0;
      let gogTotal = 0;
      let miscTotal = 0;
      let estimateTotal = 0;
      for (let i = 0; i < data.quote.labor.length; i++) {
        laborTotal += data.quote.labor[i].extPrice;
      }
      for (let i = 0; i < data.quote.parts.length; i++) {
        if (data.quote.parts[i].category !== "gog") {
          partsTotal += data.quote.parts[i].extPrice;
        } else {
          gogTotal += data.quote.parts[i].extPrice;
        }
      }
      for (let i = 0; i < data.quote.misc.length; i++) {
        let el = data.quote.misc[i];
        if (el.miscType === "service") {
          laborTotal += el.chargeOutPrice;
        } else if (el.miscType === "part") {
          partsTotal += el.chargeOutPrice;
        }
      }
      for (let i = 0; i < data.quote.freight.length; i++) {
        miscTotal += data.quote.freight[i].freightChargeOut;
      }
      for (let i = 0; i < data.quote.loadbank.length; i++) {
        miscTotal += data.quote.loadbank[i].unitCharge;
      }
      for (let i = 0; i < data.quote.parking.length; i++) {
        miscTotal += data.quote.parking[i].totalCharge;
      }
      estimateTotal = laborTotal + partsTotal + gogTotal + miscTotal;
      laborTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(laborTotal);
      partsTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(partsTotal);
      gogTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(gogTotal);
      miscTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(miscTotal);
      estimateTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(estimateTotal);

      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("Labor Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${laborTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Parts Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${partsTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Gas, Oil & Grease Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${gogTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Misc. Fees Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${miscTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Shop Fees:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(formatCurrency(data.quote.shopFees || 0), pageWidth / 2 + 10, x, {
        align: "left",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Estimate Total:", pageWidth / 2 - 10, x, {
        align: "right",
      });
      doc.text(`${estimateTotal}`, pageWidth / 2 + 10, x, {
        align: "left",
      });

      x += 20;
    } else if (detail === "all") {
      x += 20;
      let laborTotal = 0;
      let partsTotal = 0;
      let gogTotal = 0;
      let miscTotal = 0;
      let shopFeeTotal = 0;
      let estimateTotal = 0;

      let laborItems = [];
      let partsItems = [];
      let miscItems = [];

      for (let i = 0; i < data.quote.labor.length; i++) {
        laborTotal += data.quote.labor[i].extPrice;
        let el = data.quote.labor[i];
        laborItems.push([
          el.hours,
          data.rates.find((r) => r.rateId === el.rate).laborCode ?? "Labor",
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.perHour),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.extPrice),
        ]);
      }
      for (let i = 0; i < data.quote.parts.length; i++) {
        if (data.quote.parts[i].category !== "gog") {
          partsTotal += data.quote.parts[i].extPrice;
        } else {
          gogTotal += data.quote.parts[i].extPrice;
        }
        let el = data.quote.parts[i];
        partsItems.push([
          el.quantity,
          el.partNo,
          el.description.slice(0, 50) + el.description.length > 50 ? "..." : "",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.pricePerPart),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.extPrice),
        ]);
      }
      for (let i = 0; i < data.quote.misc.length; i++) {
        let el = data.quote.misc[i];
        if (el.miscType === "service") {
          laborTotal += el.chargeOutPrice;
          laborItems.push([
            el.quantity,
            "SPEC-SERV",
            el.description,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(el.chargeOutPrice / el.quantity),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(el.chargeOutPrice),
          ]);
        } else if (el.miscType === "part") {
          partsTotal += el.chargeOutPrice;
          partsItems.push([
            el.quantity,
            "SPEC-PART",
            el.description,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(el.chargeOutPrice / el.quantity),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(el.chargeOutPrice),
          ]);
        }
      }
      for (let i = 0; i < data.quote.freight.length; i++) {
        miscTotal += data.quote.freight[i].freightChargeOut;
        let el = data.quote.freight[i];
        miscItems.push([
          "1",
          "FREIGHT FEE",
          "Freight Charge",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.freightChargeOut),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.freightChargeOut),
        ]);
      }
      for (let i = 0; i < data.quote.loadbank.length; i++) {
        miscTotal += data.quote.loadbank[i].unitCharge;
        let el = data.quote.loadbank[i];
        miscItems.push([
          "1",
          "LOADBANK FEE",
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.unitCharge),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.unitCharge),
        ]);
      }
      for (let i = 0; i < data.quote.parking.length; i++) {
        miscTotal += data.quote.parking[i].totalCharge;
        let el = data.quote.parking[i];
        miscItems.push([
          el.quantity,
          el.parkingType.toUpperCase() + " FEE",
          el.increment.toUpperCase() + " " + el.parkingType.toUpperCase() + " FEE",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.unitCharge),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.totalCharge),
        ]);
      }
      shopFeeTotal = data.quote.shopFees || 0;
      estimateTotal = laborTotal + partsTotal + gogTotal + miscTotal + shopFeeTotal;
      laborTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(laborTotal);
      partsTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(partsTotal);
      gogTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(gogTotal);
      miscTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(miscTotal);
      shopFeeTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(shopFeeTotal);
      estimateTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(estimateTotal);
      if (laborItems.length > 0) {
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("Labor ", 60, x, {
          align: "left",
        });

        doc.autoTable({
          headStyles: {
            fillColor: "#ffffff",
            textColor: "#000000",
          },
          columnStyles: {
            0: { halign: "center", cellWidth: "auto" },
            1: { halign: "left", cellWidth: "auto" },
            2: { cellWidth: "auto" },
            3: { halign: "right", cellWidth: "auto" },
            4: { halign: "right", cellWidth: "auto" },
          },
          styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
          theme: "plain",
          head: [
            [
              { content: "Hours", styles: { halign: "left" } },
              { content: "Labor Rate", styles: { halign: "left" } },
              { content: "Description", styles: { halign: "left" } },
              { content: "Per Hour", styles: { halign: "right" } },
              { content: "Ext. Price", styles: { halign: "right" } },
            ],
          ],
          body: laborItems,
          margin: { top: x + 10, left: 70, right: 70, bottom: 15 },
        });
        x = doc.lastAutoTable.finalY + 15;
      }
      if (partsItems.length > 0) {
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("Parts ", 60, x, {
          align: "left",
        });
        x += 10;

        doc.autoTable({
          startY: x,
          headStyles: {
            fillColor: "#ffffff",
            textColor: "#000000",
          },
          columnStyles: {
            0: { halign: "center", cellWidth: "auto" },
            1: { halign: "left", cellWidth: "auto" },
            2: { cellWidth: "auto" },
            3: { halign: "right", cellWidth: "auto" },
            4: { halign: "right", cellWidth: "auto" },
          },
          styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
          theme: "plain",
          head: [
            [
              { content: "Quantity", styles: { halign: "left" } },
              { content: "Part No", styles: { halign: "left" } },
              { content: "Description", styles: { halign: "left" } },
              { content: "Unit Price", styles: { halign: "right" } },
              { content: "Ext. Price", styles: { halign: "right" } },
            ],
          ],
          body: partsItems,
          margin: { top: 0, left: 70, right: 70, bottom: 15 },
        });
        x = doc.lastAutoTable.finalY + 20;
      }
      if (miscItems.length > 0) {
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("Misc. Fees ", 60, x, {
          align: "left",
        });
        x += 10;

        doc.autoTable({
          startY: x,
          headStyles: {
            fillColor: "#ffffff",
            textColor: "#000000",
          },
          columnStyles: {
            0: { halign: "center", cellWidth: "auto" },
            1: { halign: "left", cellWidth: "auto" },
            2: { cellWidth: "auto" },
            3: { halign: "right", cellWidth: "auto" },
            4: { halign: "right", cellWidth: "auto" },
          },
          styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
          theme: "plain",
          head: [
            [
              { content: "Quantity", styles: { halign: "left" } },
              { content: "Fee Code", styles: { halign: "left" } },
              { content: "Description", styles: { halign: "left" } },
              { content: "Unit Price", styles: { halign: "right" } },
              { content: "Ext. Price", styles: { halign: "right" } },
            ],
          ],
          body: miscItems,
          margin: { top: 0, left: 70, right: 70, bottom: 15 },
        });
        x = doc.lastAutoTable.finalY + 20;
      }
      if (x > 690) {
        doc.addPage();
        let imgWidth = 315.12;
        let imgXPos = pageWidth / 2 - imgWidth / 2;
        doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
        doc.setDrawColor("#000000");
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.setTextColor("#ff0000");
        doc.text("INTERNAL DOCUMENT - NOT AN OFFICIAL ESTIMATE", pageWidth / 2, 22, {
          align: "center",
        });
        doc.text("INTERNAL DOCUMENT - NOT AN OFFICIAL ESTIMATE", pageWidth / 2, 760, {
          align: "center",
        });
        doc.setLineWidth(0.8);
        doc.setTextColor("#000000");
        doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
        doc.setLineWidth(2);
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(8);
        doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
          align: "center",
        });
        doc.text("Augusta, GA 30906", 254, 104, {
          align: "center",
        });
        doc.text("Tel: (706) 790-8111", 254, 115, {
          align: "center",
        });
        doc.text("Fax: (706) 790-4368", 254, 126, {
          align: "center",
        });
        doc.text("1182 Edgefield Road", 360, 93, {
          align: "center",
        });
        doc.text("North Augusta, SC 29841", 360, 104, {
          align: "center",
        });
        doc.text("Tel: (803) 613-0101", 360, 115, {
          align: "center",
        });
        doc.text("Fax: (803) 613-0323", 360, 126, {
          align: "center",
        });
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.line(50, 135, pageWidth - 50, 135);
        doc.text("ESTIMATE FOR SERVICES", pageWidth / 2, 149, {
          align: "center",
        });
        doc.line(50, 155, pageWidth - 50, 155);
        doc.setFontSize(9);
        doc.text(data.customer.customerCode + (data.customer.company.length > 0 ? ", " + data.customer.company.toUpperCase() : ""), 50, 170, {
          align: "left",
        });
        doc.setFont("Helvetica", "normal");
        doc.text(
          data.customer.arData.name && data.customer.arData.name.length > 0
            ? data.customer.arData.name.toUpperCase()
            : data.customer.contact.firstName + " " + data.customer.contact.lastName,
          50,
          181,
          {
            align: "left",
          },
        );
        let custAddr = data.customer.contact.address.toUpperCase();
        if (data.customer.contact.address2 && data.customer.contact.address2.length > 0) {
          custAddr += ", " + data.customer.contact.address2.toUpperCase();
        }
        doc.text(custAddr, 50, 192, {
          align: "left",
        });
        let custAddr2 = `${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`;
        if (custAddr2.length < 5) {
          custAddr2 = `${data.customer.contact.city.toUpperCase()}, ${data.customer.contact.state.toUpperCase()} ${data.customer.contact.zip}`;
        }
        doc.text(custAddr2, 50, 203, {
          align: "left",
        });
        let custEmail = data.customer.arData.email.toUpperCase();
        if (custEmail.length < 2) {
          custEmail = data.customer.contact.email.toUpperCase();
        }
        if (custEmail.length > 2) {
          custEmail += " ";
        }
        if (data.customer.arData.phoneNumber.length === 10) {
          custEmail += formatPhoneNumber(data.customer.arData.phoneNumber);
        } else if (data.customer.contact.phoneNumber.length === 10) {
          custEmail += formatPhoneNumber(data.customer.contact.phoneNumber);
        }
        doc.text(custEmail, 50, 214, {
          align: "left",
        });
        doc.text(`Date of Estimate: ${dayjs(data.quote.date).format("MM/DD/YYYY")}`, 562, 170, {
          align: "right",
        });
        doc.text(`Estimate Expires: ${dayjs(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 181, {
          align: "right",
        });
        doc.setFont("Helvetica", "bold");
        doc.setFontSize(12);
        doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 230, {
          align: "center",
        });
        doc.line(50, 237, pageWidth - 50, 237);
        x = 250;
      }
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("Labor Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${laborTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Parts Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${partsTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Gas, Oil & Grease Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${gogTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Misc. Fees Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${miscTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Shop Fee Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(`${shopFeeTotal}`, pageWidth - 70, x, {
        align: "right",
      });
      x += 14;
      doc.setFont("Helvetica", "bold");
      doc.text("Estimate Total:", pageWidth - 160, x, {
        align: "right",
      });
      doc.text(`${estimateTotal}`, pageWidth - 70, x, {
        align: "right",
      });

      x += 20;
    }

    if (data.quote.equipment.length > 0) {
      data.quote.equipment.forEach((el) => {
        let oneEq = data.equipment.find((e) => e.equipmentId === el);
        if (oneEq) {
          doc.setFontSize(12);
          doc.setFont("Helvetica", "bold");
          doc.text("Equipment", 60, x, {
            align: "left",
          });
          doc.setFont("Helvetica", "normal");
          x += 14;
          let equipmentItems = [];
          let types = {
            generator: "Generator",
            pressureWasher: "Pressure Washer",
            truck: "Truck",
            trailer: "Trailer",
            welder: "Welder",
            airCompressor: "Air Compressor",
            other: "Other",
          };
          equipmentItems.push([["Type:"], [types[oneEq.equipmentType]]]);
          equipmentItems.push([
            ["Customer Equip. ID:"],
            [oneEq.customerEquipmentId && oneEq.customerEquipmentId.length > 0 ? oneEq.customerEquipmentId : "N/A"],
          ]);
          equipmentItems.push([["Location:"], [oneEq.location && oneEq.location.length > 0 ? oneEq.location : "N/A"]]);
          equipmentItems.push([["Make:"], [oneEq.details.make && oneEq.details.make.length > 0 ? oneEq.details.make : "N/A"]]);
          equipmentItems.push([["Model:"], [oneEq.details.model && oneEq.details.model.length > 0 ? oneEq.details.model : "N/A"]]);
          equipmentItems.push([["Spec No:"], [oneEq.details.specNo && oneEq.details.specNo.length > 0 ? oneEq.details.specNo : "N/A"]]);
          equipmentItems.push([["Serial No:"], [oneEq.details.serialNo && oneEq.details.serialNo.length > 0 ? oneEq.details.serialNo : "N/A"]]);
          equipmentItems.push([
            ["Miles / Hours:"],
            [oneEq.details.miles && oneEq.details.miles > 0 ? `${oneEq.details.miles} ${oneEq.milesUnit.toUpperCase()}` : "N/A"],
          ]);
          doc.autoTable({
            startY: x,
            headStyles: {
              fillColor: "#ffffff",
              textColor: "#000000",
            },
            columnStyles: {
              0: { halign: "left", cellWidth: "auto" },
              1: { halign: "right", cellWidth: "auto" },
            },
            styles: { fontSize: 10, minCellHeight: 12, cellPadding: 5 },
            theme: "grid",
            head: [],
            body: equipmentItems,
            margin: { top: 0, left: 65, right: 65, bottom: 25 },
          });
          x = doc.lastAutoTable.finalY + 20;
        }
      });
    }

    doc.line(50, x, pageWidth - 50, x);
    if (x > 690) {
      doc.addPage();
      let imgWidth = 315.12;
      let imgXPos = pageWidth / 2 - imgWidth / 2;
      doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor("#ff0000");
      doc.text("INTERNAL DOCUMENT - NOT AN OFFICIAL ESTIMATE", pageWidth / 2, 22, {
        align: "center",
      });
      doc.text("INTERNAL DOCUMENT - NOT AN OFFICIAL ESTIMATE", pageWidth / 2, 760, {
        align: "center",
      });
      doc.setLineWidth(0.8);
      doc.setTextColor("#000000");
      doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
      doc.setLineWidth(2);
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
        align: "center",
      });
      doc.text("Augusta, GA 30906", 254, 104, {
        align: "center",
      });
      doc.text("Tel: (706) 790-8111", 254, 115, {
        align: "center",
      });
      doc.text("Fax: (706) 790-4368", 254, 126, {
        align: "center",
      });
      doc.text("1182 Edgefield Road", 360, 93, {
        align: "center",
      });
      doc.text("North Augusta, SC 29841", 360, 104, {
        align: "center",
      });
      doc.text("Tel: (803) 613-0101", 360, 115, {
        align: "center",
      });
      doc.text("Fax: (803) 613-0323", 360, 126, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.line(50, 135, pageWidth - 50, 135);
      doc.text("ESTIMATE FOR SERVICES", pageWidth / 2, 149, {
        align: "center",
      });
      doc.line(50, 155, pageWidth - 50, 155);
      doc.setFontSize(9);
      doc.text(data.customer.company.toUpperCase(), 50, 170, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(data.customer.customerCode + (data.customer.company.length > 0 ? ", " + data.customer.company.toUpperCase() : ""), 50, 170, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(
        data.customer.arData.name && data.customer.arData.name.length > 0
          ? data.customer.arData.name.toUpperCase()
          : data.customer.contact.firstName + " " + data.customer.contact.lastName,
        50,
        181,
        {
          align: "left",
        },
      );
      let custAddr = data.customer.contact.address.toUpperCase();
      if (data.customer.contact.address2 && data.customer.contact.address2.length > 0) {
        custAddr += ", " + data.customer.contact.address2.toUpperCase();
      }
      doc.text(custAddr, 50, 192, {
        align: "left",
      });
      let custAddr2 = `${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`;
      if (custAddr2.length < 5) {
        custAddr2 = `${data.customer.contact.city.toUpperCase()}, ${data.customer.contact.state.toUpperCase()} ${data.customer.contact.zip}`;
      }
      doc.text(custAddr2, 50, 203, {
        align: "left",
      });
      let custEmail = data.customer.arData.email.toUpperCase();
      if (custEmail.length < 2) {
        custEmail = data.customer.contact.email.toUpperCase();
      }
      if (custEmail.length > 2) {
        custEmail += " ";
      }
      if (data.customer.arData.phoneNumber.length === 10) {
        custEmail += formatPhoneNumber(data.customer.arData.phoneNumber);
      } else if (data.customer.contact.phoneNumber.length === 10) {
        custEmail += formatPhoneNumber(data.customer.contact.phoneNumber);
      }
      doc.text(custEmail, 50, 214, {
        align: "left",
      });
      doc.text(`Date of Estimate: ${dayjs(data.quote.date).format("MM/DD/YYYY")}`, 562, 170, {
        align: "right",
      });
      doc.text(`Estimate Expires: ${dayjs(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 181, {
        align: "right",
      });
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 230, {
        align: "center",
      });
      doc.line(50, 237, pageWidth - 50, 237);
      x = 250;
    }
    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");
    x += 12;
    for (let i = 0; i < data.quote.footer.length; i++) {
      doc.text("*** " + data.quote.footer[i].toUpperCase() + " ***", pageWidth / 2, x, {
        align: "center",
      });
      x += 12;
    }
    let terms = "";
    if (data.quote.paymentTerms === "net10") {
      terms = "NET 10";
    } else if (data.quote.paymentTerms === "net20") {
      terms = "NET 20";
    } else if (data.quote.paymentTerms === "net30") {
      terms = "NET 30";
    } else if (data.quote.paymentTerms === "collectOnDelivery") {
      terms = "COLLECT ON DELIVERY";
    } else if (data.quote.paymentTerms === "dueOnReceipt") {
      terms = "DUE ON RECEIPT";
    } else if (data.quote.paymentTerms === "prePay") {
      terms = "PAY UPFRONT";
    }
    doc.setFont("Helvetica", "bold");
    doc.text(`*** TERMS: ${terms} ***`, pageWidth / 2, x, {
      align: "center",
    });
    x += 6;
    doc.setLineWidth(2);
    doc.line(50, x, pageWidth - 50, x);
    if (x > 520) {
      doc.addPage();
      let imgWidth = 315.12;
      let imgXPos = pageWidth / 2 - imgWidth / 2;
      doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor("#ff0000");
      doc.text("INTERNAL DOCUMENT - NOT AN OFFICIAL ESTIMATE", pageWidth / 2, 22, {
        align: "center",
      });
      doc.text("INTERNAL DOCUMENT - NOT AN OFFICIAL ESTIMATE", pageWidth / 2, 760, {
        align: "center",
      });
      doc.setLineWidth(0.8);
      doc.setTextColor("#000000");
      doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
      doc.setLineWidth(2);
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
        align: "center",
      });
      doc.text("Augusta, GA 30906", 254, 104, {
        align: "center",
      });
      doc.text("Tel: (706) 790-8111", 254, 115, {
        align: "center",
      });
      doc.text("Fax: (706) 790-4368", 254, 126, {
        align: "center",
      });
      doc.text("1182 Edgefield Road", 360, 93, {
        align: "center",
      });
      doc.text("North Augusta, SC 29841", 360, 104, {
        align: "center",
      });
      doc.text("Tel: (803) 613-0101", 360, 115, {
        align: "center",
      });
      doc.text("Fax: (803) 613-0323", 360, 126, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.line(50, 135, pageWidth - 50, 135);
      doc.text("ESTIMATE FOR SERVICES", pageWidth / 2, 149, {
        align: "center",
      });
      doc.line(50, 155, pageWidth - 50, 155);
      doc.setFontSize(9);
      doc.text(data.customer.customerCode + (data.customer.company.length > 0 ? ", " + data.customer.company.toUpperCase() : ""), 50, 170, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.text(
        data.customer.arData.name && data.customer.arData.name.length > 0
          ? data.customer.arData.name.toUpperCase()
          : data.customer.contact.firstName + " " + data.customer.contact.lastName,
        50,
        181,
        {
          align: "left",
        },
      );
      let custAddr = data.customer.contact.address.toUpperCase();
      if (data.customer.contact.address2 && data.customer.contact.address2.length > 0) {
        custAddr += ", " + data.customer.contact.address2.toUpperCase();
      }
      doc.text(custAddr, 50, 192, {
        align: "left",
      });
      let custAddr2 = `${data.customer.arData.city.toUpperCase()}, ${data.customer.arData.state.toUpperCase()} ${data.customer.arData.zip}`;
      if (custAddr2.length < 5) {
        custAddr2 = `${data.customer.contact.city.toUpperCase()}, ${data.customer.contact.state.toUpperCase()} ${data.customer.contact.zip}`;
      }
      doc.text(custAddr2, 50, 203, {
        align: "left",
      });
      let custEmail = data.customer.arData.email.toUpperCase();
      if (custEmail.length < 2) {
        custEmail = data.customer.contact.email.toUpperCase();
      }
      if (custEmail.length > 2) {
        custEmail += " ";
      }
      if (data.customer.arData.phoneNumber.length === 10) {
        custEmail += formatPhoneNumber(data.customer.arData.phoneNumber);
      } else if (data.customer.contact.phoneNumber.length === 10) {
        custEmail += formatPhoneNumber(data.customer.contact.phoneNumber);
      }
      doc.text(custEmail, 50, 214, {
        align: "left",
      });
      doc.text(`Date of Estimate: ${dayjs(data.quote.date).format("MM/DD/YYYY")}`, 562, 170, {
        align: "right",
      });
      doc.text(`Estimate Expires: ${dayjs(data.quote.date).add(30, "days").format("MM/DD/YYYY")}`, 562, 181, {
        align: "right",
      });
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Estimate #: ${data.quote.quoteNo}`, pageWidth / 2, 230, {
        align: "center",
      });
      doc.line(50, 237, pageWidth - 50, 237);
      x = 250;
    }
    x += 12;
    doc.setFontSize(8);
    doc.text(`Customer Acceptance:`, 70, x, {
      align: "left",
    });
    let xPos = 70 + doc.getTextWidth("Customer Acceptance:") + 10;
    doc.setFont("Helvetica", "normal");
    let acceptanceTerms = `By approval below, we accept the above bill of material, price, and terms, as described in Hi-Tech Power Systems Estimate # ${data.quote.quoteNo}, and request that the order be released for production.`;
    var splitTitle = doc.splitTextToSize(acceptanceTerms, pageWidth - 220);
    for (let i = 0; i < splitTitle.length; i++) {
      doc.text(splitTitle[i], xPos, x, {
        align: "left",
      });
      x += 10;
    }
    x += 40;
    doc.setLineWidth(1);
    let sigWid = pageWidth / 3;
    doc.line(60, x, sigWid + 60, x);
    doc.line(pageWidth - sigWid - 60, x, pageWidth - 60, x);
    doc.setFontSize(7);
    doc.text("Company Name", 60, x + 8, { align: "left" });
    doc.text("Date", pageWidth - sigWid - 60, x + 8, { align: "left" });
    x += 40;
    doc.line(60, x, sigWid + 60, x);
    doc.line(pageWidth - sigWid - 60, x, pageWidth - 60, x);
    doc.text("Approved By (Signature)", 60, x + 8, { align: "left" });
    doc.text("Title", pageWidth - sigWid - 60, x + 8, { align: "left" });
    x += 40;
    doc.line(60, x, sigWid + 60, x);
    doc.line(pageWidth - sigWid - 60, x, pageWidth - 60, x);
    doc.text("Approved By (Print Name)", 60, x + 8, { align: "left" });
    if (data.quote.poNumber && data.quote.poNumber.length > 0) {
      doc.setFontSize(9);
      doc.text(data.quote.poNumber, pageWidth - sigWid - 60, x - 5, {
        align: "left",
      });
      doc.setFontSize(7);
    }
    doc.text("Purchase Order #", pageWidth - sigWid - 60, x + 8, {
      align: "left",
    });
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    if (pageCount > 1) {
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        pageWidth = doc.internal.pageSize.getWidth();
        pageHeight = doc.internal.pageSize.getHeight();
        let txWid = doc.getTextWidth(`Page ${i} of ${pageCount}`);
        doc.text(`Page ${i} of ${pageCount}`, pageWidth / 2 - txWid / 2, pageHeight - 18, {
          align: "center",
        });
      }
    }
    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const generatePoPreview = (data) => {
  try {
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgWidth = 180;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 46.86, "hitechLogo", "NONE", 0);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    let internalStatuses = ["draft", "approved", "denied", "void"];
    if (internalStatuses.includes(data.poStatus)) {
      doc.setTextColor("#ff0000");
      doc.text("INTERNAL DOCUMENT - NOT AN OFFICIAL ESTIMATE", pageWidth / 2, 22, {
        align: "center",
      });
      doc.text("INTERNAL DOCUMENT - NOT AN OFFICIAL ESTIMATE", pageWidth / 2, 760, {
        align: "center",
      });
    }
    doc.setTextColor("#000000");
    doc.setDrawColor("#000000");
    doc.text("PURCHASE ORDER", pageWidth / 2, 85, { align: "center" });
    doc.setLineWidth(0.8);
    doc.setFontSize(10);
    doc.text(`PO #: ${data.poNumber}`, pageWidth / 2, 110, {
      align: "center",
    });
    const poTextWidth = doc.getTextWidth(`PO #: ${data.poNumber}`);
    doc.line(pageWidth / 2 - poTextWidth / 2 - 5, 114, pageWidth / 2 + poTextWidth / 2 + 5, 114);
    doc.line(50, 95, pageWidth - 50, 95);
    doc.text("Vendor:", 50, 135, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    const vendorTextWidth = doc.getTextWidth("Vendor:");
    doc.text(data.vendorCode + data.vendorName, 59 + vendorTextWidth, 135, {
      align: "left",
    });
    doc.setFont("helvetica", "bold");
    doc.text("Parts for:", 50, 146, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    const poPartsForWidth = doc.getTextWidth("Parts for:");
    doc.text(data.partsFor, 59 + poPartsForWidth, 146, {
      align: "left",
    });
    doc.setFont("helvetica", "bold");
    doc.text("Job Reference:", 50, 157, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    const jobReferenceWidth = doc.getTextWidth("Job Reference:");
    doc.text(data.jobReference, 59 + jobReferenceWidth, 157, {
      align: "left",
    });
    doc.setFont("helvetica", "bold");
    doc.text("Payment Account:", 50, 168, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    const paymentAccountWidth = doc.getTextWidth("Payment Account:");
    doc.text(data.poAccount, 59 + paymentAccountWidth, 168, {
      align: "left",
    });
    doc.setFont("helvetica", "bold");
    doc.text("Location:", 50, 179, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    const locationWidth = doc.getTextWidth("Location:");
    doc.text(data.location || "", 59 + locationWidth, 179, {
      align: "left",
    });
    doc.setFont("helvetica", "bold");

    //Right Side
    doc.text("Date Created:", 507, 135, {
      align: "right",
    });
    doc.setFont("helvetica", "normal");
    let dateCreated = "N/A";
    if (data.poDate && dayjs(data.poDate).isValid()) {
      dateCreated = dayjs(data.poDate).format("MM/DD/YYYY");
    }
    doc.text(dateCreated, 562, 135, {
      align: "right",
    });
    doc.setFont("helvetica", "bold");
    doc.text("Date Ordered:", 507, 146, {
      align: "right",
    });
    doc.setFont("helvetica", "normal");
    let dateOrdered = "N/A";
    if (data.dateSent && dayjs(data.dateSent).isValid()) {
      dateOrdered = dayjs(data.dateSent).format("MM/DD/YYYY");
    }
    doc.text(dateOrdered, 562, 146, {
      align: "right",
    });
    doc.setFont("helvetica", "bold");
    doc.text("Expected Delivery:", 507, 157, {
      align: "right",
    });
    doc.setFont("helvetica", "normal");
    let dateExpected = "N/A";
    if (data.expectedDeliveryDate && dayjs(data.expectedDeliveryDate).isValid()) {
      dateExpected = dayjs(data.expectedDeliveryDate).format("MM/DD/YYYY");
    }
    doc.text(dateExpected, 562, 157, {
      align: "right",
    });
    doc.setFont("helvetica", "bold");
    doc.text("Date Received:", 507, 168, {
      align: "right",
    });
    doc.setFont("helvetica", "normal");
    let dateReceived = "N/A";
    if (data.dateReceived && dayjs(data.dateReceived).isValid()) {
      dateReceived = dayjs(data.dateReceived).format("MM/DD/YYYY");
    }
    doc.text(dateReceived, 562, 168, {
      align: "right",
    });
    doc.setFont("helvetica", "bold");
    doc.text("PO Status:", 507, 179, {
      align: "right",
    });
    doc.setFont("helvetica", "normal");
    let status = data.poStatus[0].toUpperCase() + data.poStatus.slice(1);
    doc.text(status, 562, 179, {
      align: "right",
    });
    doc.setFont("helvetica", "bold");
    let y = 200;
    if (data.customerInfo) {
      let customer = data.customerInfo;
      y = 190;
      doc.line(50, y, pageWidth - 50, y);
      y += 15;
      doc.setFont("helvetica", "bold");
      doc.text("Client Code:", 50, y, {
        align: "left",
      });
      const codeWidth = doc.getTextWidth("Client Code:");
      doc.setFont("helvetica", "normal");
      doc.text(customer.customerCode && customer.customerCode.length > 0 ? customer.customerCode : "Unknown", 59 + codeWidth, y, {
        align: "left",
      });
      doc.setFont("helvetica", "bold");
      y += 13;
      doc.setFont("helvetica", "bold");
      doc.text("Client Company / Contact:", 50, y, {
        align: "left",
      });
      const companyWidth = doc.getTextWidth("Client Company / Contact:");
      doc.setFont("helvetica", "normal");
      doc.text(
        customer.company && customer.company.length > 0
          ? customer.company
          : customer.contact.firstName.length > 0
            ? customer.contact.firstName + " " + customer.contact.lastName
            : "Unknown",
        59 + companyWidth,
        y,
        {
          align: "left",
        },
      );
      doc.setFont("helvetica", "bold");
      y += 11;
    }
    doc.line(50, y, pageWidth - 50, y);

    let items = [];
    let subTotal = 0;
    let costTotal = 0;

    for (let i = 0; i < data.items.length; i++) {
      const element = data.items[i];
      if (!element.service) {
        items.push([
          element.partNo,
          element.description.slice(0, 50) + (element.description.length > 50 ? "..." : ""),
          element.quantity,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(element.costPrice),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(element.costTotal),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(element.price),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(element.total),
        ]);
        subTotal += element.total;
        costTotal += element.costTotal;
      }
    }

    const services = [];

    for (let i = 0; i < data.items.length; i++) {
      const element = data.items[i];
      if (element.service) {
        let val = element.description.slice(0, 50) + (element.description.length > 50 ? "..." : "");
        services.push([
          val.toUpperCase(),
          element.quantity,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(element.price),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(element.total),
        ]);
        subTotal += element.total;
        costTotal += element.costTotal;
      }
    }
    if (items.length > 0) {
      doc.autoTable({
        headStyles: {
          fillColor: "#ffffff",
          textColor: "#000000",
        },
        columnStyles: {
          0: { halign: "left", cellWidth: "auto" },
          1: { halign: "left", cellWidth: "auto" },
          2: { halign: "right", cellWidth: "auto" },
          3: { halign: "right", cellWidth: "auto" },
          4: { halign: "right", cellWidth: "auto" },
          5: { halign: "right", cellWidth: "auto" },
          6: { halign: "right", cellWidth: "auto" },
        },
        styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
        theme: "plain",
        head: [
          [
            { content: "Part No", styles: { halign: "left" } },
            { content: "Description", styles: { halign: "left" } },
            { content: "Quantity", styles: { halign: "right" } },
            { content: "Cost", styles: { halign: "right" } },
            { content: "Cost Total", styles: { halign: "right" } },
            { content: "Ext. Price", styles: { halign: "right" } },
            { content: "Ext. Total", styles: { halign: "right" } },
          ],
        ],
        body: items,
        margin: { top: y + 15, left: 50, right: 50, bottom: 15 },
      });
      y = doc.lastAutoTable.finalY + 15;
      doc.line(50, y, pageWidth - 50, y);
      y += 15;
    }

    if (services.length > 0) {
      doc.autoTable({
        headStyles: {
          fillColor: "#ffffff",
          textColor: "#000000",
        },
        columnStyles: {
          0: { halign: "left", cellWidth: "auto" },
          1: { halign: "right", cellWidth: "auto" },
          2: { halign: "right", cellWidth: "auto" },
          3: { halign: "right", cellWidth: "auto" },
        },
        styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
        theme: "plain",
        head: [
          [
            { content: "Charge Description", styles: { halign: "left" } },
            { content: "Quantity", styles: { halign: "right" } },
            { content: "Price", styles: { halign: "right" } },
            { content: "Total", styles: { halign: "right" } },
          ],
        ],
        body: services,
        margin: { top: y + 15, left: 50, right: 50, bottom: 15 },
      });
      y = doc.lastAutoTable.finalY + 15;
      doc.line(50, y, pageWidth - 50, y);
      y += 15;
    }

    doc.text("Subtotal:", 507, y, {
      align: "right",
    });
    doc.setFont("helvetica", "normal");
    let subtotalTxt = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(subTotal);
    doc.text(subtotalTxt, 562, y, {
      align: "right",
    });
    doc.setFont("helvetica", "bold");

    y += 13;

    doc.text("Shipping:", 507, y, {
      align: "right",
    });
    doc.setFont("helvetica", "normal");
    let shippingTxt = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(data.shipping);
    doc.text(shippingTxt, 562, y, {
      align: "right",
    });
    doc.setFont("helvetica", "bold");

    y += 13;

    doc.text("Total Cost:", 507, y, {
      align: "right",
    });
    doc.setFont("helvetica", "normal");
    let costTtlText = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(costTotal);
    doc.text(costTtlText, 562, y, {
      align: "right",
    });
    doc.setFont("helvetica", "bold");

    y += 18;

    doc.text("TOTAL:", 507, y, {
      align: "right",
    });
    let totalTxt = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(subTotal + data.shipping);
    doc.text(totalTxt, 562, y, {
      align: "right",
    });
    if (data.notes.length > 0) {
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      y += 18;
      doc.line(50, y, pageWidth - 50, y);
      y += 18;
      doc.text("Purchase Order Notes", 50, y, {
        align: "left",
      });
      y += 18;
      doc.setFontSize(8);
      doc.setFont("helvetica", "normal");
      for (let i = 0; i < data.notes.length; i++) {
        const element = data.notes[i];
        doc.text(element.note, 50, y, {
          align: "left",
        });
        doc.text(
          `${element.createdByName ? "Created by " + element.createdByName + ", " : ""}${dayjs(element.createdAt).format("MMMM Do, YYYY h:mm A")}`,
          pageWidth - 50,
          y,
          {
            align: "right",
          },
        );
        y += 13;
      }
    }
    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateInvoicePDF = (data) => {
  try {
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFont("Helvetica", "normal");
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgWidth = 290;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 78.25, "hitechLogo", "NONE", 0);
    doc.setDrawColor("#000000");
    doc.setLineWidth(2);
    doc.setTextColor("#000000");
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
      align: "center",
    });
    doc.text("Augusta, GA 30906", 254, 104, {
      align: "center",
    });
    doc.text("Tel: (706) 790-8111", 254, 115, {
      align: "center",
    });
    doc.text("Fax: (706) 790-4368", 254, 126, {
      align: "center",
    });
    doc.text("1182 Edgefield Road", 360, 93, {
      align: "center",
    });
    doc.text("North Augusta, SC 29841", 360, 104, {
      align: "center",
    });
    doc.text("Tel: (803) 613-0101", 360, 115, {
      align: "center",
    });
    doc.text("Fax: (803) 613-0323", 360, 126, {
      align: "center",
    });
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.line(50, 135, pageWidth - 50, 135);
    doc.text(data.invoice.invoiceNo, pageWidth / 2, 149, {
      align: "center",
    });
    doc.line(50, 155, pageWidth - 50, 155);
    doc.setFontSize(9);
    doc.text(
      "Customer: " + data.customer.customerCode.toUpperCase() + (data.customer.company.length > 0 ? " - " + data.customer.company.toUpperCase() : ""),
      50,
      170,
      {
        align: "left",
      },
    );
    doc.setFont("Helvetica", "normal");
    doc.text(
      "Contact Person: " +
        (data.customer.arData.name.length > 0
          ? data.customer.arData.name.toUpperCase()
          : data.customer.contact.firstName.length === 0
            ? "Not Provided"
            : data.customer.contact.firstName.toUpperCase() + " " + data.customer.contact.lastName.toUpperCase()),
      50,
      181,
      {
        align: "left",
      },
    );
    let custAddr = data.customer.arData.address.toUpperCase();
    if (data.customer.arData.address2 && data.customer.arData.address2.length > 0) {
      custAddr += ", " + data.customer.arData.address2.toUpperCase();
    }
    if (custAddr.replace(" ", "").length === 0) {
      custAddr = data.customer.contact.address.toUpperCase();
      if (data.customer.contact.address2 && data.customer.contact.address2.length > 0) {
        custAddr += ", " + data.customer.contact.address2.toUpperCase();
      }
    }
    if (custAddr.replace(" ", "").length === 0) {
      custAddr = "Not Provided";
    }
    doc.text(custAddr, 50, 192, {
      align: "left",
    });
    let city =
      data.customer.arData.city.length > 0
        ? data.customer.arData.city.toUpperCase()
        : data.customer.contact.city.length > 0
          ? data.customer.contact.city.toUpperCase()
          : "";
    let state =
      data.customer.arData.state.length > 0
        ? data.customer.arData.state.toUpperCase()
        : data.customer.contact.state.length > 0
          ? data.customer.contact.state.toUpperCase()
          : "";
    let zip =
      data.customer.arData.zip.length > 0
        ? data.customer.arData.zip.toUpperCase()
        : data.customer.contact.zip.length > 0
          ? data.customer.contact.zip.toUpperCase()
          : "";
    let csz = city + (city.length > 0 && state.length > 0 ? ", " : "") + state + (state.length > 0 && zip.length > 0 ? " " : "") + zip;
    doc.text(csz, 50, 203, {
      align: "left",
    });
    doc.text(
      "Email: " + data.customer.arData.email.length > 0
        ? data.customer.arData.email.toUpperCase()
        : data.customer.contact.email.length > 0
          ? data.customer.contact.email.toUpperCase()
          : "",
      50,
      214,
      {
        align: "left",
      },
    );
    doc.text(`Invoice Date: ${dayjs(data.invoice.invoiceDate).format("MM/DD/YYYY")}`, 562, 170, {
      align: "right",
    });
    doc.text(`Due Date: ${dayjs(data.invoice.dueDate).format("MM/DD/YYYY")}`, 562, 181, {
      align: "right",
    });
    doc.text(`PO Number: ${data.invoice.customerPo && data.invoice.customerPo.length > 0 ? data.invoice.customerPo : "N/A"}`, 562, 192, {
      align: "right",
    });
    let terms = {
      net10: "NET 10",
      net20: "NET 20",
      net30: "NET 30",
      collectOnDelivery: "COLLECT ON DELIVERY",
      dueOnReceipt: "DUE ON RECEIPT",
      prePay: "PAY UPFRONT",
    };
    doc.text(`Payment Terms: ${terms[data.invoice.paymentTerms]}`, 562, 203, {
      align: "right",
    });
    let status = {
      sent: "UNPAID",
      ready: "UNPAID",
      paid: "PAID",
      overdue: "OVERDUE",
      void: "VOID",
      draft: "DRAFT",
    };
    doc.text(`Invoice Status: ${status[data.invoice.invoiceStatus]}`, 562, 214, {
      align: "right",
    });
    doc.line(50, 225, pageWidth - 50, 225);
    let y = 241;

    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");
    doc.text("JOB DESCRIPTION", 50, y, {
      align: "left",
    });
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);
    y += 11;
    doc.text(data.invoice.jobDescription.toUpperCase(), 50, y, {
      align: "left",
      maxWidth: pageWidth - 100,
    });
    var dim = doc.splitTextToSize(data.invoice.jobDescription, pageWidth - 100, {
      fontSize: 9,
    });
    y += dim.length * 9;
    y += 11;

    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");
    doc.text("DETAILS", 50, y, {
      align: "left",
    });
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);
    y += 11;
    doc.text(data.invoice.jobDetails.toUpperCase(), 50, y, {
      align: "left",
      maxWidth: pageWidth - 100,
    });
    var dim2 = doc.splitTextToSize(data.invoice.jobDetails, pageWidth - 100, {
      fontSize: 9,
    });
    y += dim2.length * 9;
    y += 11;
    doc.line(50, y, pageWidth - 50, y);
    y += 11;

    let laborItems = [];
    let partsItems = [];
    let gogItems = [];
    let servicesItems = [];
    let subtotal = 0;

    for (let i = 0; i < data.invoice.parts.length; i++) {
      if (data.invoice.parts[i].category !== "gog") {
        let el = data.invoice.parts[i];
        partsItems.push([
          el.quantity,
          el.partNo,
          el.description.slice(0, 50) + el.description.length > 50 ? "..." : el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.pricePerPart),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.extPrice),
        ]);
        subtotal += el.extPrice;
      } else {
        let el = data.invoice.parts[i];
        gogItems.push([
          el.quantity,
          el.partNo,
          el.description.slice(0, 50) + el.description.length > 50 ? "..." : el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.pricePerPart),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.extPrice),
        ]);
        subtotal += el.extPrice;
      }
    }
    for (let i = 0; i < data.invoice.misc.length; i++) {
      let el = data.invoice.misc[i];
      if (el.miscType === "part") {
        partsItems.push([
          el.quantity,
          "MISC-PART",
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.chargeOutPrice / el.quantity),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.chargeOutPrice),
        ]);
        subtotal += el.chargeOutPrice;
      } else {
        servicesItems.push([
          el.description,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(el.chargeOutPrice),
        ]);
        subtotal += el.chargeOutPrice;
      }
    }
    for (let i = 0; i < data.invoice.labor.length; i++) {
      let el = data.invoice.labor[i];
      if (el.quotedPrice > 0) {
        if (el.hideOnPrint) {
          laborItems.push(["Labor Performed", formatCurrency(el.quotedPrice)]);
        } else {
          laborItems.push([el.laborDescription, formatCurrency(el.quotedPrice)]);
        }
        subtotal += el.quotedPrice;
      }
    }
    if (data.invoice.freightTotal > 0) {
      servicesItems.push([
        "FREIGHT",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.invoice.freightTotal),
      ]);
      subtotal += data.invoice.freightTotal;
    }
    for (let i = 0; i < data.invoice.loadbank.length; i++) {
      let el = data.invoice.loadbank[i];
      servicesItems.push([
        "LOADBANK",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(el.unitCharge),
      ]);
      subtotal += el.unitCharge;
    }
    for (let i = 0; i < data.invoice.parking.length; i++) {
      let el = data.invoice.parking[i];
      servicesItems.push([
        `PARKING/STORAGE [${el.increment.toUpperCase()}] ${el.quantity} x ${formatCurrency(el.unitCharge)}`,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(el.totalCharge),
      ]);
      subtotal += el.totalCharge;
    }

    if (partsItems.length > 0) {
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("PARTS", 50, y, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(9);
      y += 11;
      doc.autoTable({
        startY: y,
        headStyles: {
          fillColor: "#3d4044",
          textColor: "#ffffff",
        },
        columnStyles: {
          0: { halign: "left", cellWidth: "auto" },
          1: { halign: "left", cellWidth: "auto" },
          2: { cellWidth: "auto" },
          3: { halign: "right", cellWidth: "auto" },
          4: { halign: "right", cellWidth: "auto" },
        },
        styles: {
          fontSize: 9,
          font: "Helvetica",
          minCellHeight: 12,
          cellPadding: 4,
        },
        head: [
          [
            { content: "Quantity", styles: { halign: "left" } },
            { content: "Part No", styles: { halign: "left" } },
            { content: "Description", styles: { halign: "left" } },
            { content: "Unit Price", styles: { halign: "right" } },
            { content: "Ext. Price", styles: { halign: "right" } },
          ],
        ],
        body: partsItems,
        margin: { top: 0, left: 50, right: 50, bottom: 15 },
        theme: "grid",
        didDrawPage: function () {
          // generateHead();
          y = 241;
        },
      });
      y = doc.lastAutoTable.finalY + 20;
    }
    if (laborItems.length > 0) {
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("LABOR", 50, y, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(9);
      y += 11;
      doc.autoTable({
        startY: y,
        headStyles: {
          fillColor: "#3d4044",
          textColor: "#ffffff",
        },
        columnStyles: {
          0: { halign: "left", cellWidth: "auto" },
          1: { halign: "right", cellWidth: "auto" },
        },
        styles: {
          fontSize: 9,
          font: "Helvetica",
          minCellHeight: 12,
          cellPadding: 4,
        },
        head: [
          [
            { content: "Description", styles: { halign: "left" } },
            { content: "Ext. Price", styles: { halign: "right" } },
          ],
        ],
        body: laborItems,
        margin: { top: 0, left: 50, right: 50, bottom: 15 },
        theme: "grid",
        didDrawPage: function () {
          // generateHead();
          y = 241;
        },
      });
      y = doc.lastAutoTable.finalY + 20;
    }

    if (gogItems.length > 0) {
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("GAS, OIL & GREASE", 50, y, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(9);
      y += 11;
      doc.autoTable({
        startY: y,
        headStyles: {
          fillColor: "#3d4044",
          textColor: "#ffffff",
        },
        columnStyles: {
          0: { halign: "left", cellWidth: "auto" },
          1: { halign: "left", cellWidth: "auto" },
          2: { cellWidth: "auto" },
          3: { halign: "right", cellWidth: "auto" },
          4: { halign: "right", cellWidth: "auto" },
        },
        styles: {
          fontSize: 9,
          font: "Helvetica",
          minCellHeight: 12,
          cellPadding: 4,
        },
        head: [
          [
            { content: "Quantity", styles: { halign: "left" } },
            { content: "Part No", styles: { halign: "left" } },
            { content: "Description", styles: { halign: "left" } },
            { content: "Unit Price", styles: { halign: "right" } },
            { content: "Ext. Price", styles: { halign: "right" } },
          ],
        ],
        body: gogItems,
        margin: { top: 0, left: 50, right: 50, bottom: 15 },
        theme: "grid",
        didDrawPage: function () {
          // generateHead();
          y = 241;
        },
      });
      y = doc.lastAutoTable.finalY + 20;
    }

    if (servicesItems.length > 0) {
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("SERVICES & MISC", 50, y, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(9);
      y += 11;
      doc.autoTable({
        startY: y,
        headStyles: {
          fillColor: "#3d4044",
          textColor: "#ffffff",
        },
        columnStyles: {
          0: { halign: "left", cellWidth: "auto" },
          1: { halign: "right", cellWidth: "auto" },
        },
        styles: {
          fontSize: 9,
          font: "Helvetica",
          minCellHeight: 12,
          cellPadding: 4,
        },
        head: [
          [
            { content: "Description", styles: { halign: "left" } },
            { content: "Ext. Price", styles: { halign: "right" } },
          ],
        ],
        body: servicesItems,
        margin: { top: 0, left: 50, right: 50, bottom: 15 },
        theme: "grid",
        didDrawPage: function () {
          // generateHead();
          y = 241;
        },
      });
      y = doc.lastAutoTable.finalY + 20;
    }
    if (data.invoice.equipment.length > 0) {
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("EQUIPMENT", 50, y, {
        align: "left",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(9);
      y += 11;
      //EQUIPMENT
      let raw = [];
      for (let i = 0; i < data.invoice.equipment.length; i++) {
        let el = data.invoice.equipment[i];
        let types = {
          generator: "Generator",
          pressureWasher: "Pressure Washer",
          truck: "Truck",
          trailer: "Trailer",
          welder: "Welder",
          airCompressor: "Air Compressor",
          other: "Other",
        };
        let fuels = {
          diesel: "Diesel",
          gasoline: "Gasoline",
          naturalGas: "Natural Gas",
          propane: "Propane",
          biFuel: "Bi-Fuel",
          electricity: "Electricity",
          other: "Other",
        };
        raw.push({
          "Equip. Type": types[el.equipmentType],
          "Equip. ID": el.customerEquipId && el.customerEquipId.length > 0 ? el.customerEquipId : "N/A",
          Location: el.location && el.location.length > 0 ? el.location : "N/A",
        });
        raw.push({
          "Fuel Type": el.fuelType && el.fuelType.length > 0 ? fuels[el.fuelType] : "N/A",
          Make: el.details.make && el.details.make.length > 0 ? el.details.make : "N/A",
          Model: el.details.model && el.details.model.length > 0 ? el.details.model : "N/A",
        });
        raw.push({
          "Spec No": el.details.specNo && el.details.specNo.length > 0 ? el.details.specNo : "N/A",
          "Serial No": el.details.serialNo && el.details.serialNo.length > 0 ? el.details.serialNo : "N/A",
          "ATS Make": el.ats.make && el.ats.make.length > 0 ? el.ats.make : "N/A",
        });
        raw.push({
          "ATS Model": el.ats.model && el.ats.model.length > 0 ? el.ats.model : "N/A",
          "ATS Serial": el.ats.serialNo && el.ats.serialNo.length > 0 ? el.ats.serialNo : "N/A",
          "ATS Size": el.ats.size && el.ats.size.length > 0 ? el.ats.size : "N/A",
        });
        raw.push({
          "Engine Make": el.engine.make && el.engine.make.length > 0 ? el.engine.make : "N/A",
          "Engine Model": el.engine.model && el.engine.model.length > 0 ? el.engine.model : "N/A",
          "Engine Serial": el.engine.serialNo && el.engine.serialNo.length > 0 ? el.engine.serialNo : "N/A",
        });
        raw.push({
          "Engine Code": el.engine.codeNo && el.engine.codeNo.length > 0 ? el.engine.codeNo : "N/A",
          "Miles/Hours": el.miles ? el.miles : "N/A",
          Power: el.power && el.pwrUnit && el.pwrUnit.length > 0 ? el.power + " " + el.pwrUnit : "N/A",
        });
      }
      let body = [];

      for (var i = 0; i < raw.length; i++) {
        var row = [];
        for (var key in raw[i]) {
          row.push({ content: `${key}: ` + raw[i][key] });
        }
        if (i % 6 === 0 && i > 0) {
          body.push([
            {
              content: "",
              // rowSpan: 1,
              colSpan: 3,
              styles: { halign: "center", valign: "top" },
            },
          ]);
        }
        body.push(row);
      }

      doc.autoTable({
        startY: y,
        head: [
          [
            {
              content: "Equipment",
              colSpan: 3,
              styles: { halign: "center", fillColor: "#3d4044" },
            },
          ],
        ],
        body: body,
        rowPageBreak: "auto",
        theme: "grid",
        bodyStyles: { valign: "top" },
        styles: {
          overflow: "linebreak",
          // columnWidth: "100",
          font: "Helvetica",
          fontSize: 9,
          cellPadding: 4,
          overflowColumns: "linebreak",
        },
        margin: { left: 50, right: 50 },
        didDrawPage: function () {
          // generateHead();
          y = 241;
        },
      });
      y = doc.lastAutoTable.finalY + 20;
    }
    if (y > 480) {
      doc.addPage();
      // generateHead();
      // y = 241;
      y = 50;
    }
    doc.line(50, y, pageWidth - 50, y);
    y += 16;
    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");
    let startY = y;
    doc.text("PLEASE REMIT PAYMENT TO:", 50, y, {
      align: "left",
    });
    doc.setFont("Helvetica", "normal");
    y += 11;
    doc.text("Hi-Tech Power Systems", 50, y, {
      align: "left",
    });
    doc.setFontSize(9);
    y += 11;
    doc.text("2479 Doug Barnard Pkwy", 50, y, {
      align: "left",
    });
    y += 11;
    doc.text("Augusta, GA 30906", 50, y, {
      align: "left",
    });
    y += 11;
    doc.text("(706) 790-8111", 50, y, {
      align: "left",
    });
    y += 11;
    if (data.invoice.invoiceStatus === "paid") {
      doc.addImage(paidStamp, "PNG", pageWidth / 4 - 60, y, 120, 120, "paidStamp", "NONE", 0);
    }
    subtotal += data.invoice.shopFeeTotal;
    let totals = [
      [
        "LABOR",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.invoice.laborTotal),
      ],
      [
        "PARTS",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.invoice.partsTotal),
      ],
      [
        "GAS, OIL & GREASE",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.invoice.gogTotal),
      ],
      [
        "PARKING/STORAGE",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.invoice.parkingTotal),
      ],
      [
        "LOADBANK",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.invoice.loadbankTotal),
      ],
      [
        "SERVICES",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.invoice.servicesTotal),
      ],
      [
        "FREIGHT",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.invoice.freightTotal),
      ],
      [
        "MISC. SHOP",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.invoice.shopFeeTotal),
      ],
      [
        "SUBTOTAL",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(subtotal),
      ],
      [
        "TAX",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(data.invoice.tax),
      ],
    ];
    if (data.invoice.invoiceStatus === "paid") {
      const calculateCardFees = () => {
        let paidWithCard = data.invoice.payments.filter((p) => p.paymentMethod === "card");
        let total = 0;
        for (let i = 0; i < paidWithCard.length; i++) {
          total += parseFloat(((paidWithCard[i].paymentAmount / 1.03) * 0.03).toFixed(2));
        }
        return total;
      };
      if (calculateCardFees() > 0) {
        totals.push([
          "PROCESSING FEES",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(calculateCardFees()),
        ]);
        totals.push([
          "TOTAL",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(subtotal + data.invoice.tax + calculateCardFees()),
        ]);
      } else {
        totals.push([
          "TOTAL",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(subtotal + data.invoice.tax),
        ]);
      }
    } else {
      if (data.invoice.payments.length > 0) {
        let paid = 0;
        for (let i = 0; i < data.invoice.payments.length; i++) {
          const element = data.invoice.payments[i];
          paid += element.paymentAmount;
        }
        totals.push([
          "PAYMENT",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(paid),
        ]);
        totals.push([
          "BALANCE DUE",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(subtotal + data.invoice.tax - paid),
        ]);
      } else {
        totals.push([
          "TOTAL",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(subtotal + data.invoice.tax),
        ]);
      }
    }
    doc.autoTable({
      startY: startY,
      headStyles: {
        fillColor: "#3d4044",
        textColor: "#ffffff",
      },
      columnStyles: {
        0: { halign: "left", cellWidth: "auto" },
        1: { halign: "right", cellWidth: "auto" },
      },
      styles: {
        fontSize: 9,
        font: "Helvetica",
        minCellHeight: 12,
        cellPadding: 4,
      },
      head: [],
      body: totals,
      margin: { top: 0, left: pageWidth / 2, right: 50, bottom: 15 },
      theme: "grid",
      tableWidth: (pageWidth - 100) / 2,
    });
    y = doc.lastAutoTable.finalY + 20;

    if (data.invoice.invoiceStatus === "paid") {
      doc.setFontSize(8);
      doc.setTextColor("#000000");
      let paymentText = `PAID IN FULL`;
      if (data.invoice.payments && data.invoice.payments.length > 0) {
        paymentText += ` WITH ${data.invoice.payments[0].paymentMethod.toUpperCase()}`;
        if (data.invoice.payments[0].paymentMethod === "card") {
          if (data.invoice.payments[0].saleId && data.invoice.payments[0].saleId.length > 10) {
            paymentText = `Paid in Full - ` + data.invoice.payments[0].note;
          } else if (data.invoice.payments[0].checkNo && data.invoice.payments[0].checkNo.length > 3) {
            paymentText += ` ENDING IN ${data.invoice.payments[0].checkNo}`;
          }
        } else if (data.invoice.payments[0].paymentMethod === "check") {
          paymentText += ` NO. ${data.invoice.payments[0].checkNo}`;
        }
      }
      doc.text(paymentText.toUpperCase(), pageWidth / 4, y - 34, {
        align: "center",
      });
    }
    doc.setDrawColor("#3d4044");
    doc.setTextColor("#3d4044");
    doc.line(50, y, pageWidth - 50, y);
    y += 16;
    doc.setFontSize(8);
    let noWarranty =
      "UNLESS OTHERWISE PROVIDED BY LAW, THE SELLER (ABOVE NAMED DEALERSHIP) HEREBY EXPRESSLY DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING ANY IMPLIED WARRANTY OF MERCHANT-ABILITY OR FITNESS FOR A PARTICULAR PURPOSE AND NEITHER ASSUMES NOR AUTHORIZES ONY OTHER PERSON TO ASSUME FOR IT ANY LIABILITY IN CONNECTION WITH THE SALE OF SAID PRODUCTS.";
    doc.text(noWarranty, pageWidth / 2, y, {
      align: "center",
      maxWidth: pageWidth - 100,
    });
    var dim3 = doc.splitTextToSize(noWarranty, pageWidth - 100, {
      fontSize: 8,
    });
    y += dim3.length * 8;
    y += 11;
    doc.line(50, y, pageWidth - 50, y);

    const pageCount = doc.internal.getNumberOfPages();

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    if (pageCount > 1) {
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(8);
        doc.setTextColor("#6c757d");
        pageWidth = doc.internal.pageSize.getWidth();
        pageHeight = doc.internal.pageSize.getHeight();
        doc.text(`Page ${i} of ${pageCount}`, pageWidth / 2, pageHeight - 18, {
          align: "center",
        });
      }
    }
    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateAPOpenInvoicesReport = (data) => {
  try {
    const doc = new jsPDF("l", "pt", "letter");
    doc.setFont("Helvetica", "normal");
    let pageWidth = doc.internal.pageSize.getWidth();

    let y = 108;

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);
    y += 11;
    let totals = {
      outstanding: 0,
      paymentsMade: 0,
    };
    for (let i = 0; i < data.length; i++) {
      let el = data[i];
      let formattedData = [];
      let totalPaid = 0;
      let totalOutstanding = 0;
      formattedData.push([el.vendorName, "", "", "", "", "", "", ""]);
      for (const invoice of el.invoices) {
        let invoicePaid = 0;
        if (invoice.payments && Array.isArray(invoice.payments) && invoice.payments.length > 0) {
          for (let i = 0; i < invoice.payments.length; i++) {
            totalPaid += invoice.payments[i].amount;
            invoicePaid += invoice.payments[i].amount;
          }
        }
        let paid = false;
        let total = 0;
        if (invoice.items.length > 0) {
          total = invoice.items.reduce(
            (acc, item) => acc + parseFloat((parseFloat(item.price.toFixed(2)) * parseFloat(item.quantity.toFixed(2))).toFixed(2)),
            0,
          );
        } else {
          total = invoice.total;
        }
        totalOutstanding += total;
        let net = parseFloat((parseFloat(total.toFixed(2)) - parseFloat(invoicePaid.toFixed(2))).toFixed(2));
        if (invoicePaid >= total) {
          paid = true;
        }
        formattedData.push([
          invoice.invoiceNumber,
          dayjs(el.createdAt).format("MM/DD/YYYY"),
          dayjs(el.invoiceDate).format("MM/DD/YYYY"),
          dayjs(el.dueDate).format("MM/DD/YYYY"),
          formatCurrency(total),
          formatCurrency(invoicePaid),
          formatCurrency(net),
          {
            content: paid ? "Paid" : "Not Paid",
            styles: { textColor: paid ? "#008000" : "#ff0000" },
          },
        ]);
      }
      const vendorNet = parseFloat((parseFloat(totalOutstanding.toFixed(2)) - parseFloat(totalPaid.toFixed(2))).toFixed(2));
      formattedData.push(["", "", "", "", formatCurrency(totalOutstanding), formatCurrency(totalPaid), formatCurrency(vendorNet), ""]);
      totals.outstanding += totalOutstanding;
      totals.paymentsMade += totalPaid;
      doc.autoTable({
        startY: y,
        headStyles: {
          fillColor: "#d1d5db",
          textColor: "#1e293b",
        },
        columnStyles: {
          0: { halign: "left", cellWidth: "auto" },
          1: { halign: "left", cellWidth: "auto" },
          2: { halign: "left", cellWidth: "auto" },
          3: { halign: "left", cellWidth: "auto" },
          4: { halign: "left", cellWidth: "auto" },
          5: { halign: "left", cellWidth: "auto" },
          6: { halign: "left", cellWidth: "auto" },
          7: { halign: "right", cellWidth: "auto" },
        },
        styles: {
          fontSize: 9,
          font: "Helvetica",
          minCellHeight: 12,
          cellPadding: 4,
        },
        head: [
          [
            { content: "Vendor/Invoice No", styles: { halign: "left" } },
            { content: "Entry Date", styles: { halign: "left" } },
            { content: "Invoice Date", styles: { halign: "left" } },
            { content: "Due Date", styles: { halign: "left" } },
            { content: "Amount", styles: { halign: "left" } },
            { content: "Amount Paid", styles: { halign: "left" } },
            { content: "Net", styles: { halign: "left" } },
            { content: "Status", styles: { halign: "right" } },
          ],
        ],
        body: formattedData,
        margin: { top: 119, left: 50, right: 50, bottom: 45 },
        theme: "striped",
        didDrawPage: function (data) {
          doc.setFont("Helvetica", "normal");
          let pageWidth = doc.internal.pageSize.getWidth();
          let pageHeight = doc.internal.pageSize.getHeight();
          let imgXPos = 50;
          doc.addImage(hiTechLogo, "PNG", imgXPos, 30, 185.9, 50, "hitechLogo", "NONE", 0);
          doc.setDrawColor("#000000");
          doc.setLineWidth(2);
          doc.setTextColor("#000000");
          doc.setFont("Helvetica", "normal");
          doc.setFontSize(8);
          doc.text("2479 Doug Barnard Pkwy.", 270, 38, {
            align: "left",
          });
          doc.text("Augusta, GA 30906", 270, 49, {
            align: "left",
          });
          doc.text("Tel: (706) 790-8111", 270, 60, {
            align: "left",
          });
          doc.text("Fax: (706) 790-4368", 270, 71, {
            align: "left",
          });
          doc.text("1182 Edgefield Road", 376, 38, {
            align: "left",
          });
          doc.text("North Augusta, SC 29841", 376, 49, {
            align: "left",
          });
          doc.text("Tel: (803) 613-0101", 376, 60, {
            align: "left",
          });
          doc.text("Fax: (803) 613-0323", 376, 71, {
            align: "left",
          });
          doc.text(`Run Date: ${dayjs().format("MM/DD/YYYY")}`, pageWidth - 50, 38, {
            align: "right",
          });
          doc.text(`Run Time: ${dayjs().format("h:m A")}`, pageWidth - 50, 49, {
            align: "right",
          });
          doc.setFontSize(12);
          doc.setFont("Helvetica", "normal");
          doc.line(50, 90, pageWidth - 50, 90);
          doc.text("Accounts Payable | Open Invoice Report", pageWidth / 2, 108, {
            align: "center",
          });
        },
      });
      y = doc.lastAutoTable.finalY + 20;
      if (y > 480) {
        doc.addPage();
        doc.setFont("Helvetica", "normal");
        let pageWidth = doc.internal.pageSize.getWidth();
        let pageHeight = doc.internal.pageSize.getHeight();
        let imgXPos = 50;
        doc.addImage(hiTechLogo, "PNG", imgXPos, 30, 185.9, 50, "hitechLogo", "NONE", 0);
        doc.setDrawColor("#000000");
        doc.setLineWidth(2);
        doc.setTextColor("#000000");
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(8);
        doc.text("2479 Doug Barnard Pkwy.", 270, 38, {
          align: "left",
        });
        doc.text("Augusta, GA 30906", 270, 49, {
          align: "left",
        });
        doc.text("Tel: (706) 790-8111", 270, 60, {
          align: "left",
        });
        doc.text("Fax: (706) 790-4368", 270, 71, {
          align: "left",
        });
        doc.text("1182 Edgefield Road", 376, 38, {
          align: "left",
        });
        doc.text("North Augusta, SC 29841", 376, 49, {
          align: "left",
        });
        doc.text("Tel: (803) 613-0101", 376, 60, {
          align: "left",
        });
        doc.text("Fax: (803) 613-0323", 376, 71, {
          align: "left",
        });
        doc.text(`Run Date: ${dayjs().format("MM/DD/YYYY")}`, pageWidth - 50, 38, {
          align: "right",
        });
        doc.text(`Run Time: ${dayjs().format("h:m A")}`, pageWidth - 50, 49, {
          align: "right",
        });
        doc.setFontSize(12);
        doc.setFont("Helvetica", "normal");
        doc.line(50, 90, pageWidth - 50, 90);
        doc.text("Accounts Payable | Open Invoice Report", pageWidth / 2, 108, {
          align: "center",
        });
        y = 119;
      }
    }
    let openInvoices = data.reduce((acc, el) => acc + el.invoices.length, 0);
    let overdue = data.reduce((acc, el) => {
      let overdue = el.invoices.filter((i) => dayjs().isAfter(dayjs(i.dueDate)));
      return acc + overdue.length;
    }, 0);

    doc.autoTable({
      startY: y,
      headStyles: {
        fillColor: "#d1d5db",
        textColor: "#1e293b",
      },
      columnStyles: {
        0: { halign: "left", cellWidth: "auto" },
        1: { halign: "left", cellWidth: "auto" },
        2: { halign: "left", cellWidth: "auto" },
        3: { halign: "right", cellWidth: "auto" },
        4: { halign: "right", cellWidth: "auto" },
        5: { halign: "right", cellWidth: "auto" },
        6: { halign: "right", cellWidth: "auto" },
        7: { halign: "right", cellWidth: "auto" },
      },
      styles: {
        fontSize: 9,
        font: "Helvetica",
        minCellHeight: 12,
        cellPadding: 4,
      },
      head: [
        [
          { content: "Open Invoices", styles: { halign: "left" } },
          { content: "", styles: { halign: "left" } },
          { content: "Overdue Invoices", styles: { halign: "left" } },
          { content: "", styles: { halign: "right" } },
          { content: "", styles: { halign: "right" } },
          { content: "Total", styles: { halign: "right" } },
          { content: "Payments Made", styles: { halign: "right" } },
          { content: "Total Outstanding", styles: { halign: "right" } },
        ],
      ],
      body: [
        [
          openInvoices,
          "",
          overdue,
          "",
          "",
          formatCurrency(totals.outstanding),
          formatCurrency(totals.paymentsMade),
          formatCurrency(totals.outstanding - totals.paymentsMade),
        ],
      ],
      margin: { top: 0, left: 50, right: 50, bottom: 15 },
      theme: "striped",
    });

    const pageCount = doc.internal.getNumberOfPages();

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      pageWidth = doc.internal.pageSize.getWidth();
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);

      let txWid = doc.getTextWidth(`Page ${i} of ${pageCount}`);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 50, 60, {
        align: "right",
      });
    }

    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateCustomerEquipmentLog = (data, customerData) => {
  try {
    const doc = new jsPDF("l", "pt", "letter");
    doc.setFont("Helvetica", "normal");
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    function generateHead() {
      let imgXPos = 50;
      doc.addImage(hiTechLogo, "PNG", imgXPos, 30, 185.9, 50, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setLineWidth(2);
      doc.setTextColor("#000000");
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 270, 38, {
        align: "left",
      });
      doc.text("Augusta, GA 30906", 270, 49, {
        align: "left",
      });
      doc.text("Tel: (706) 790-8111", 270, 60, {
        align: "left",
      });
      doc.text("Fax: (706) 790-4368", 270, 71, {
        align: "left",
      });
      doc.text("1182 Edgefield Road", 376, 38, {
        align: "left",
      });
      doc.text("North Augusta, SC 29841", 376, 49, {
        align: "left",
      });
      doc.text("Tel: (803) 613-0101", 376, 60, {
        align: "left",
      });
      doc.text("Fax: (803) 613-0323", 376, 71, {
        align: "left",
      });
      doc.text(`Report Date: ${dayjs().format("MM/DD/YYYY")}`, pageWidth - 50, 38, {
        align: "right",
      });
      doc.text(`Report Time: ${dayjs().format("h:m A")}`, pageWidth - 50, 49, {
        align: "right",
      });
      doc.text(`Customer: ${customerData.customerCode} | ${customerData.company}`, pageWidth - 50, 60, {
        align: "right",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.line(50, 90, pageWidth - 50, 90);
      doc.text("Customer Equipment Log", pageWidth / 2, 108, {
        align: "center",
      });

      y = 130;
    }
    let y = 130;
    generateHead();

    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal");

    const equipTypes = {
      generator: "Generator",
      pressureWasher: "Pressure Washer",
      truck: "Truck",
      trailer: "Trailer",
      welder: "Welder",
      airCompressor: "Air Compressor",
      other: "Other",
    };

    const fuelTypes = {
      diesel: "Diesel",
      gasoline: "Gasoline",
      naturalGas: "Natural Gas",
      propane: "Propane",
      biFuel: "Bi-Fuel",
      electricity: "Electricity",
      other: "Other",
    };
    data.forEach((el) => {
      if (y > 430) {
        doc.addPage();
        generateHead();
      }
      doc.setFontSize(8);
      doc.setFont("Helvetica", "normal");
      doc.setLineWidth(0.5);
      doc.setDrawColor("#9ca3af");
      doc.line(50, y, pageWidth - 50, y);
      y += 16;
      doc.text(`Customer Code: ${el.customerCode && el.customerCode.length > 0 ? el.customerCode : "Unknown"}`, 50, y, {
        align: "left",
      });
      doc.text(`Location: ${el.location && el.location.length > 0 ? el.location : "Unknown"}`, 250, y, {
        align: "left",
      });
      doc.text(`Equipment Model: ${el.model && el.model.length > 0 ? el.model : "Unknown"}`, 450, y, {
        align: "left",
      });
      y += 11;
      doc.text(`Customer Company: ${el.company && el.company.length > 0 ? el.company : "Unknown"}`, 50, y, {
        align: "left",
      });
      doc.text(`Customer Equip. ID: ${el.customerEquipId && el.customerEquipId.length > 0 ? el.customerEquipId : "Unknown"}`, 250, y, {
        align: "left",
      });
      doc.text(`Equipment Serial No: ${el.serialNo && el.serialNo.length > 0 ? el.serialNo : "Unknown"}`, 450, y, {
        align: "left",
      });
      y += 11;
      doc.text(`Customer Contact: ${el.contact && el.contact.length > 0 ? el.contact : "Unknown"}`, 50, y, {
        align: "left",
      });
      doc.text(
        `Equipment Type: ${equipTypes[el.equipmentType]}${el.equipmentType === "other" ? (el.otherType.length > 0 ? " - " + el.otherType : " - Unknown") : ""}`,
        250,
        y,
        {
          align: "left",
        },
      );
      doc.text(`Equipment Arrangement: ${el.arrangement && el.arrangement.length > 0 ? el.arrangement : "Unknown"}`, 450, y, {
        align: "left",
      });
      y += 11;
      doc.text(`Customer Email: ${el.email && el.email.length > 2 ? el.email : "Unknown"}`, 50, y, {
        align: "left",
      });
      doc.text(`Fuel Type: ${el.fuelType && el.fuelType.length > 0 ? fuelTypes[el.fuelType] : "Unknown"}`, 250, y, {
        align: "left",
      });
      doc.text(`Engine Make: ${el.engineMake && el.engineMake.length > 0 ? el.engineMake : "Unknown"}`, 450, y, {
        align: "left",
      });
      y += 11;
      doc.text(
        `Customer Phone: ${
          el.phoneNumber && el.phoneNumber.length === 10 ? formatPhoneNumber(el.phoneNumber) : el.phoneNumber.length > 0 ? el.phoneNumber : "Unknown"
        }`,
        50,
        y,
        {
          align: "left",
        },
      );
      doc.text(`Equipment Make: ${el.make && el.make.length > 0 ? el.make : "Unknown"}`, 250, y, {
        align: "left",
      });
      doc.text(`Engine Spec: ${el.engineSpecNo && el.engineSpecNo.length > 0 ? el.engineSpecNo : "Unknown"}`, 450, y, {
        align: "left",
      });
      if (el.events.length > 0) {
        let elBody = [];
        for (let i = 0; i < el.events.length; i++) {
          elBody.push([el.events[i].type, el.events[i].readableId, dayjs(el.events[i].date).format("MM/DD/YYYY"), el.events[i].description]);
        }
        doc.autoTable({
          startY: y + 20,
          pageBreak: "auto",
          headStyles: {
            fillColor: "#d1d5db",
            textColor: "#1e293b",
          },
          columnStyles: {
            0: { halign: "left", cellWidth: "auto" },
            1: { halign: "left", cellWidth: "auto" },
            2: { halign: "left", cellWidth: "auto" },
            3: { halign: "left", cellWidth: "auto" },
          },
          styles: {
            fontSize: 9,
            font: "Helvetica",
            minCellHeight: 12,
            cellPadding: 4,
          },
          head: [
            [
              { content: "Type", styles: { halign: "left" } },
              { content: "ID", styles: { halign: "left" } },
              { content: "Date", styles: { halign: "left" } },
              { content: "Description", styles: { halign: "left" } },
            ],
          ],
          body: elBody,
          margin: { top: 20, left: 50, right: 50, bottom: 15 },
          theme: "striped",
        });
        y = doc.lastAutoTable.finalY + 20;
      } else {
        y = y + 20;
      }
    });
    const pageCount = doc.internal.getNumberOfPages();

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      pageWidth = doc.internal.pageSize.getWidth();
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 50, 71, {
        align: "right",
      });
    }

    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateCountSheet = (data, location) => {
  try {
    const doc = new jsPDF("l", "pt", "letter");
    doc.setFont("Helvetica", "normal");
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let locationName = "Augusta, GA";
    if (location === "northAugusta") {
      locationName = "North Augusta, SC";
    }
    function generateHead() {
      let imgXPos = 50;
      doc.addImage(hiTechLogo, "PNG", imgXPos, 30, 185.9, 50, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setLineWidth(2);
      doc.setTextColor("#000000");
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 270, 38, {
        align: "left",
      });
      doc.text("Augusta, GA 30906", 270, 49, {
        align: "left",
      });
      doc.text("Tel: (706) 790-8111", 270, 60, {
        align: "left",
      });
      doc.text("Fax: (706) 790-4368", 270, 71, {
        align: "left",
      });
      doc.text("1182 Edgefield Road", 376, 38, {
        align: "left",
      });
      doc.text("North Augusta, SC 29841", 376, 49, {
        align: "left",
      });
      doc.text("Tel: (803) 613-0101", 376, 60, {
        align: "left",
      });
      doc.text("Fax: (803) 613-0323", 376, 71, {
        align: "left",
      });
      doc.text(`Report Date: ${dayjs().format("MM/DD/YYYY")}`, pageWidth - 50, 38, {
        align: "right",
      });
      doc.text(`Report Time: ${dayjs().format("h:m A")}`, pageWidth - 50, 49, {
        align: "right",
      });
      doc.text(`Location: ${locationName}`, pageWidth - 50, 60, {
        align: "right",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.line(50, 90, pageWidth - 50, 90);
      doc.text("Inventory Count Sheet", pageWidth / 2, 108, {
        align: "center",
      });

      y = 110;
    }
    let y = 110;

    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal");

    let body = [];

    for (let i = 0; i < data.length; i++) {
      let el = data[i];
      let count = el.augustaStock;
      if (location === "northAugusta") {
        count = el.northAugustaStock;
      }
      let description = el.description || "";
      if (description.length > 30) {
        description = description.slice(0, 30) + "...";
      }
      body.push([el.partNo, el.department, description, count, "", ""]);
    }

    doc.autoTable({
      startY: y + 10,
      pageBreak: "auto",
      headStyles: {
        fillColor: "#d1d5db",
        textColor: "#1e293b",
      },
      columnStyles: {
        0: { halign: "left", cellWidth: 120 },
        1: { halign: "left", cellWidth: 80 },
        2: { halign: "left", cellWidth: 150 },
        3: { halign: "left", cellWidth: 60 },
        4: { halign: "left", cellWidth: 100 },
        5: { halign: "left", cellWidth: "auto" },
      },
      styles: {
        fontSize: 9,
        font: "Helvetica",
        minCellHeight: 12,
        cellPadding: 4,
      },
      head: [
        [
          {
            content: "Part No",
            styles: { halign: "left", rowSpan: 2, colSpan: 2 },
          },
          {
            content: "Department",
            styles: { halign: "left", rowSpan: 2, colSpan: 2 },
          },
          {
            content: "Description",
            styles: { halign: "left", rowSpan: 2, colSpan: 2 },
          },
          {
            content: "Quantity",
            styles: { halign: "left", rowSpan: 2, colSpan: 2 },
          },
          {
            content: "Count",
            styles: { halign: "left", rowSpan: 2, colSpan: 2 },
          },
          {
            content: "Comments",
            styles: { halign: "left", rowSpan: 2, colSpan: 4 },
          },
        ],
      ],
      body: body,
      margin: { top: 120, left: 50, right: 50, bottom: 15 },
      theme: "grid",
      didDrawPage: function (data) {
        generateHead();
      },
    });
    y = doc.lastAutoTable.finalY + 20;
    const pageCount = doc.internal.getNumberOfPages();

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      pageWidth = doc.internal.pageSize.getWidth();
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      let txWid = doc.getTextWidth(`Page ${i} of ${pageCount}`);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 50, 71, {
        align: "right",
      });
    }

    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateOpenJobsReport = (data, location) => {
  try {
    const doc = new jsPDF("l", "pt", "letter");
    doc.setFont("Helvetica", "normal");
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let locationName = "Augusta, GA";
    if (location === "northAugusta") {
      locationName = "North Augusta, SC";
    }
    function generateHead() {
      let imgXPos = 50;
      doc.addImage(hiTechLogo, "PNG", imgXPos, 30, 185.9, 50, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setLineWidth(2);
      doc.setTextColor("#000000");
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 270, 38, {
        align: "left",
      });
      doc.text("Augusta, GA 30906", 270, 49, {
        align: "left",
      });
      doc.text("Tel: (706) 790-8111", 270, 60, {
        align: "left",
      });
      doc.text("Fax: (706) 790-4368", 270, 71, {
        align: "left",
      });
      doc.text("1182 Edgefield Road", 376, 38, {
        align: "left",
      });
      doc.text("North Augusta, SC 29841", 376, 49, {
        align: "left",
      });
      doc.text("Tel: (803) 613-0101", 376, 60, {
        align: "left",
      });
      doc.text("Fax: (803) 613-0323", 376, 71, {
        align: "left",
      });
      doc.text(`Report Date: ${dayjs().format("MM/DD/YYYY")}`, pageWidth - 50, 38, {
        align: "right",
      });
      doc.text(`Report Time: ${dayjs().format("h:m A")}`, pageWidth - 50, 49, {
        align: "right",
      });
      doc.text(`Location: ${locationName}`, pageWidth - 50, 60, {
        align: "right",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.line(50, 90, pageWidth - 50, 90);
      doc.text("Open Jobs Report", pageWidth / 2, 108, {
        align: "center",
      });

      y = 110;
    }
    let y = 110;

    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal");

    let body = [];

    for (let i = 0; i < data.length; i++) {
      let el = data[i];
      body.push([el.jobNo, el.customerCode + " | " + el.customerName, dayjs(el.openDate).format("MM/DD/YYYY"), el.description]);
    }

    doc.autoTable({
      startY: y + 10,
      headStyles: {
        fillColor: "#d1d5db",
        textColor: "#1e293b",
      },
      columnStyles: {
        0: { halign: "left" },
        1: { halign: "left" },
        2: { halign: "left" },
        3: { halign: "left" },
      },
      styles: {
        fontSize: 9,
        font: "Helvetica",
        minCellHeight: 12,
        cellPadding: 4,
      },
      head: [
        [
          { content: "Job No", styles: { halign: "left" } },
          { content: "Customer", styles: { halign: "left" } },
          { content: "Open Date", styles: { halign: "left" } },
          { content: "Description", styles: { halign: "left" } },
        ],
      ],
      body: body,
      margin: { top: 120, left: 50, right: 50, bottom: 25 },
      theme: "grid",
      didDrawPage: function (data) {
        generateHead();
      },
    });
    y = doc.lastAutoTable.finalY + 20;
    const pageCount = doc.internal.getNumberOfPages();

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      pageWidth = doc.internal.pageSize.getWidth();
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      let txWid = doc.getTextWidth(`Page ${i} of ${pageCount}`);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 50, 71, {
        align: "right",
      });
    }

    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateEquipmentServicesReport = (data, month) => {
  try {
    const doc = new jsPDF("l", "pt", "letter");
    doc.setFont("Helvetica", "normal");
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    function generateHead() {
      let imgXPos = 50;
      doc.addImage(hiTechLogo, "PNG", imgXPos, 30, 185.9, 50, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setLineWidth(2);
      doc.setTextColor("#000000");
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 270, 38, {
        align: "left",
      });
      doc.text("Augusta, GA 30906", 270, 49, {
        align: "left",
      });
      doc.text("Tel: (706) 790-8111", 270, 60, {
        align: "left",
      });
      doc.text("Fax: (706) 790-4368", 270, 71, {
        align: "left",
      });
      doc.text("1182 Edgefield Road", 376, 38, {
        align: "left",
      });
      doc.text("North Augusta, SC 29841", 376, 49, {
        align: "left",
      });
      doc.text("Tel: (803) 613-0101", 376, 60, {
        align: "left",
      });
      doc.text("Fax: (803) 613-0323", 376, 71, {
        align: "left",
      });
      doc.text(`Report Date: ${dayjs().format("MM/DD/YYYY")}`, pageWidth - 50, 38, {
        align: "right",
      });
      doc.text(`Report Time: ${dayjs().format("h:m A")}`, pageWidth - 50, 49, {
        align: "right",
      });
      doc.text(`Month: ${month}`, pageWidth - 50, 60, {
        align: "right",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.line(50, 90, pageWidth - 50, 90);
      doc.text(`Equipment Services for ${month}`, pageWidth / 2, 108, {
        align: "center",
      });

      y = 110;
    }
    let y = 110;
    generateHead();

    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal");
    const pageCount = doc.internal.getNumberOfPages();

    const equipTypes = {
      generator: "Generator",
      pressureWasher: "Pressure Washer",
      truck: "Truck",
      trailer: "Trailer",
      welder: "Welder",
      airCompressor: "Air Compressor",
      other: "Other",
    };

    data.forEach((el) => {
      y += 15;
      if (y > 450) {
        doc.addPage();
        generateHead();
      }
      doc.setFontSize(8);
      doc.setFont("Helvetica", "normal");
      doc.setLineWidth(0.5);
      doc.setDrawColor("#9ca3af");
      doc.line(50, y, pageWidth - 50, y);
      y += 16;
      doc.text(`Service Type: ${el.type}`, 50, y, {
        align: "left",
      });
      doc.text(`Customer Email: ${el.customer.email}`, 250, y, {
        align: "left",
      });
      doc.text(`Equipment Make: ${el.equipment.make}`, 450, y, {
        align: "left",
      });
      y += 11;
      doc.text(`Customer Company: ${el.customer.company}`, 50, y, {
        align: "left",
      });
      doc.text(`Customer Phone: ${formatPhoneNumber(el.customer.phoneNumber)}`, 250, y, {
        align: "left",
      });
      doc.text(`Equipment Model: ${el.equipment.model}`, 450, y, {
        align: "left",
      });
      y += 11;
      doc.text(`Customer Contact: ${el.customer.contact}`, 50, y, {
        align: "left",
      });
      doc.text(`Customer Equip. ID: ${el.equipment.customerEquipId}`, 250, y, {
        align: "left",
      });
      doc.text(`Equipment Serial No: ${el.equipment.serialNo}`, 450, y, {
        align: "left",
      });
      y += 11;
      doc.text(`Location: ${el.equipment.location}`, 50, y, {
        align: "left",
      });
      doc.text(`Equipment Type: ${equipTypes[el.equipment.equipmentType]}`, 250, y, {
        align: "left",
      });
      doc.text(`Equipment Spec No: ${el.equipment.specNo}`, 450, y, {
        align: "left",
      });
    });

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      pageWidth = doc.internal.pageSize.getWidth();
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      let txWid = doc.getTextWidth(`Page ${i} of ${pageCount}`);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 50, 71, {
        align: "right",
      });
    }

    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateDailyTimeReport = (data) => {
  try {
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgWidth = 315.12;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    function generateHead() {
      doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setLineWidth(0.8);
      doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
      doc.setLineWidth(2);
      doc.setTextColor("#000000");
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
        align: "center",
      });
      doc.text("Augusta, GA 30906", 254, 104, {
        align: "center",
      });
      doc.text("Tel: (706) 790-8111", 254, 115, {
        align: "center",
      });
      doc.text("Fax: (706) 790-4368", 254, 126, {
        align: "center",
      });
      doc.text("1182 Edgefield Road", 360, 93, {
        align: "center",
      });
      doc.text("North Augusta, SC 29841", 360, 104, {
        align: "center",
      });
      doc.text("Tel: (803) 613-0101", 360, 115, {
        align: "center",
      });
      doc.text("Fax: (803) 613-0323", 360, 126, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.line(50, 135, pageWidth - 50, 135);
      doc.text("Daily Report - " + data.date, pageWidth / 2, 149, {
        align: "center",
      });
      doc.line(50, 155, pageWidth - 50, 155);
      doc.setFontSize(9);
      doc.setFont("Helvetica", "normal");
      y = 180;
    }
    let y = 180;
    // generateHead();
    for (let i = 0; i < data.timeCards.length; i++) {
      let current = data.timeCards[i];
      let bodyRows = [];
      let hours = 0;
      let billable = 0;
      current.entries.forEach((e) => {
        bodyRows.push([e.job, e.client, e.start, e.end, e.hours.toFixed(2) + " hrs", e.billable.toFixed(2) + " hrs"]);
        hours = hours + e.hours;
        billable = billable + e.billable;
      });
      bodyRows.push(["", "", "", "TOTAL:", hours.toFixed(2) + " hrs", billable.toFixed(2) + " hrs"]);
      doc.autoTable({
        headStyles: {
          fillColor: "#ffffff",
          textColor: "#000000",
        },
        head: [
          [
            { content: "Job", styles: { halign: "left" } },
            { content: "Client", styles: { halign: "left" } },
            { content: "Start", styles: { halign: "left" } },
            { content: "End", styles: { halign: "left" } },
            { content: "Hours", styles: { halign: "right" } },
            { content: "Billable", styles: { halign: "right" } },
          ],
        ],
        columnStyles: {
          0: { halign: "left", cellWidth: "auto" },
          1: { halign: "left", cellWidth: "auto" },
          2: { halign: "left", cellWidth: "auto" },
          3: { halign: "left", cellWidth: "auto" },
          4: { halign: "right", cellWidth: "auto" },
          5: { halign: "right", cellWidth: "auto" },
        },
        body: bodyRows,
        startY: y + 10,
        styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
        theme: "grid",
        // willDrawPage: function (data) {
        //   // Header
        //   doc.setLineWidth(1);
        //   doc.line(50, y - 14, pageWidth - 50, y - 14);
        //   doc.line(50, y + 7, pageWidth - 50, y + 7);
        //   doc.setFontSize(12);
        //   doc.text(current.employee, 65, y);
        //   doc.setFontSize(9);
        //   doc.text(
        //     "Time in: " + current.timeIn + " | Lunch Out: " + current.lunchOut + " | Lunch In: " + current.lunchIn + " | Time Out: " + current.timeOut,
        //     pageWidth - 65,
        //     y - 1,
        //     { align: "right" }
        //   );
        //   doc.setFontSize(9);
        // },
        margin: { top: 190, left: 60, right: 60, bottom: 30 },
        pageBreak: "auto",
        rowPageBreak: "avoid",
        showHead: "everyPage",
        didDrawPage: function (data) {
          doc.setLineWidth(1);
          doc.line(50, y - 14, pageWidth - 50, y - 14);
          doc.line(50, y + 7, pageWidth - 50, y + 7);
          doc.setFontSize(12);
          doc.text(current.employee, 65, y);
          doc.setFontSize(9);
          doc.text(
            "Time in: " + current.timeIn + " | Lunch Out: " + current.lunchOut + " | Lunch In: " + current.lunchIn + " | Time Out: " + current.timeOut,
            pageWidth - 65,
            y - 1,
            { align: "right" },
          );
          doc.setFontSize(9);
          y = 180;
        },
      });
      y = doc.lastAutoTable.finalY + 50;
    }
    //
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      pageWidth = doc.internal.pageSize.getWidth();
      pageHeight = doc.internal.pageSize.getHeight();
      generateHead();
      if (pageCount > 1) {
        doc.setFontSize(8);
        doc.setTextColor("#3d4044");
        doc.text(`Page ${i} of ${pageCount}`, pageWidth - 50, pageHeight - 18, {
          align: "right",
        });
      }
    }
    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateTimesheet = (data) => {
  try {
    const doc = new jsPDF("l", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgWidth = 315.12;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    // doc.addImage(hiTechLogo, "PNG", 50, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
    doc.addImage(hiTechLogo, "PNG", 40, 30, 250, 67.46, "hitechLogo", "NONE", 0);
    // doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
    doc.setDrawColor("#000000");
    doc.setTextColor("#000000");
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text("EMPLOYEE TIMESHEET", 163, 90, {
      align: "center",
    });
    doc.text("EMPLOYEE NAME: " + data.employeeName.toUpperCase(), pageWidth - 40, 60, {
      align: "right",
    });
    doc.text("PAY PERIOD: " + data.periodName, pageWidth - 40, 75, {
      align: "right",
    });
    doc.setFont("Helvetica", "normal");

    doc.text("APPROVED BY: ", 40, pageHeight - 75, {
      align: "left",
    });
    let apprtimew = doc.getTextWidth(`APPROVAL DATE: `);
    doc.line(40 + apprtimew + 5, pageHeight - 75, 40 + apprtimew + 5 + 200, pageHeight - 75);
    doc.text("APPROVAL DATE: ", 40, pageHeight - 45, {
      align: "left",
    });
    doc.line(40 + apprtimew + 5, pageHeight - 45, 40 + apprtimew + 5 + 200, pageHeight - 45);

    doc.setFontSize(8);
    doc.text("TIMESHEET ID: " + data.timesheetId, pageWidth - 40, pageHeight - 35, {
      align: "right",
    });
    let y = 130;
    let bodyRows = [];
    let hours = 0;
    let overtimeHours = 0;
    let periodStart = dayjs(data.startDate);
    while (periodStart.isBefore(data.endDate)) {
      let existing = data.entries.find((e) => e.date === periodStart.format("YYYY-MM-DD"));
      if (existing) {
        let timeIn = dayjs(existing.timeIn);
        let timeOut = dayjs(existing.timeOut);
        let lunchOut = dayjs(existing.lunchOut);
        let lunchIn = dayjs(existing.lunchIn);

        let preLunch = lunchOut.diff(timeIn, "minutes");
        let postLunch = timeOut.diff(lunchIn, "minutes");
        let minutes = preLunch + postLunch;

        let hrs = Math.floor(minutes / 60);
        let mins = minutes - hrs * 60;
        let minRender = "00";
        if (mins >= 53) {
          hrs += 1;
          minRender = "00";
        } else if (mins <= 7) {
          minRender = "00";
        } else if (mins >= 8 && mins <= 22) {
          minRender = "25";
        } else if (mins >= 23 && mins <= 37) {
          minRender = "50";
        } else if (mins >= 38 && mins <= 52) {
          minRender = "75";
        }
        let reg = "";
        let ot = "";
        let ttl = "";
        let tmp = parseFloat(hrs + "." + minRender);
        if (hours < 40 && hours + tmp <= 40) {
          reg = hrs + "." + minRender + " hrs";
          ttl = hrs + "." + minRender + " hrs";
          ot = "0.00 hrs";
        } else if (hours >= 40) {
          reg = "0.00 hrs";
          ttl = hrs + "." + minRender + " hrs";
          ot = hrs + "." + minRender + " hrs";
        } else if (hours < 40 && hours + tmp > 40) {
          let diff = 40 - hours;
          reg = diff + ".00 hrs";
          ot = hrs - diff + "." + minRender + " hrs";
          ttl = hrs + "." + minRender + " hrs";
        }
        hours = hours + tmp;
        bodyRows.push([
          dayjs(existing.timeIn).format("MM-DD-YYYY"),
          dayjs(existing.timeIn).format("hh:MM A"),
          dayjs(existing.lunchOut).format("hh:MM A"),
          dayjs(existing.lunchIn).format("hh:MM A"),
          dayjs(existing.timeOut).format("hh:MM A"),
          reg,
          ot,
          ttl,
        ]);
      } else {
        bodyRows.push([dayjs(periodStart).format("MM-DD-YYYY"), "", "", "", "", "0.00 hrs", "0.00 hrs", "0.00 hrs"]);
      }
      periodStart = periodStart.add(1, "day");
    }
    bodyRows.push([
      {
        content: "",
        styles: {
          fontStyle: "bold",
          fillColor: "#ffffff",
          textColor: "#000000",
          lineWidth: 0,
          lineColor: "#c9cacb",
          fontSize: 11,
          minCellHeight: 22,
          cellPadding: { top: 8, right: 4, bottom: 6, left: 4 },
        },
      },
      {
        content: "",
        styles: {
          fontStyle: "bold",
          fillColor: "#ffffff",
          textColor: "#000000",
          lineWidth: 0,
          lineColor: "#c9cacb",
          fontSize: 11,
          minCellHeight: 22,
          cellPadding: { top: 8, right: 4, bottom: 6, left: 4 },
        },
      },
      {
        content: "",
        styles: {
          fontStyle: "bold",
          fillColor: "#ffffff",
          textColor: "#000000",
          lineWidth: 0,
          lineColor: "#c9cacb",
          fontSize: 11,
          minCellHeight: 22,
          cellPadding: { top: 8, right: 4, bottom: 6, left: 4 },
        },
      },
      {
        content: "",
        styles: {
          fontStyle: "bold",
          fillColor: "#ffffff",
          textColor: "#000000",
          lineWidth: 0,
          lineColor: "#c9cacb",
          fontSize: 11,
          minCellHeight: 22,
          cellPadding: { top: 8, right: 4, bottom: 6, left: 4 },
        },
      },
      {
        content: "TOTAL TIME",
        styles: {
          fontStyle: "bold",
          fillColor: "#ffffff",
          textColor: "#000000",
          fontSize: 11,
          minCellHeight: 22,
          cellPadding: { top: 8, right: 4, bottom: 6, left: 4 },
        },
      },
      {
        content: hours.toFixed(2) + " hrs",
        styles: {
          fontStyle: "bold",
          fillColor: "#ffffff",
          textColor: "#000000",
          fontSize: 11,
          minCellHeight: 22,
          cellPadding: { top: 8, right: 4, bottom: 6, left: 4 },
        },
      },
      {
        content: overtimeHours.toFixed(2) + " hrs",
        styles: {
          fontStyle: "bold",
          fillColor: "#ffffff",
          textColor: "#000000",
          fontSize: 11,
          minCellHeight: 22,
          cellPadding: { top: 8, right: 4, bottom: 6, left: 4 },
        },
      },
      {
        content: (hours + overtimeHours).toFixed(2) + " hrs",
        styles: {
          fontStyle: "bold",
          fillColor: "#ffffff",
          textColor: "#000000",
          fontSize: 11,
          minCellHeight: 22,
          cellPadding: { top: 8, right: 4, bottom: 6, left: 4 },
        },
      },
    ]);
    doc.autoTable({
      headStyles: {
        fillColor: "#ffffff",
        textColor: "#000000",
        fontSize: 11,
        fontStyle: "bold",
        minCellHeight: 22,
        lineWidth: 0.5,
        lineColor: "#c9cacb",
      },
      head: [
        [
          { content: "Date", styles: { halign: "center" } },
          { content: "Start TIme", styles: { halign: "center" } },
          { content: "Lunch Out", styles: { halign: "center" } },
          { content: "Lunch In", styles: { halign: "center" } },
          { content: "End Time", styles: { halign: "center" } },
          { content: "Regular Hours", styles: { halign: "center" } },
          { content: "Overtime Hours", styles: { halign: "center" } },
          { content: "Total Hours", styles: { halign: "center" } },
        ],
      ],
      columnStyles: {
        0: { halign: "center", cellWidth: "auto" },
        1: { halign: "center", cellWidth: "auto" },
        2: { halign: "center", cellWidth: "auto" },
        3: { halign: "center", cellWidth: "auto" },
        4: { halign: "center", cellWidth: "auto" },
        5: { halign: "center", cellWidth: "auto" },
        6: { halign: "center", cellWidth: "auto" },
        7: { halign: "center", cellWidth: "auto" },
      },
      body: bodyRows,
      startY: y,
      styles: {
        fontSize: 11,
        minCellHeight: 20,
        cellPadding: { top: 6, right: 4, bottom: 6, left: 4 },
        fillColor: "#f1f4ff",
        lineWidth: 0.5,
        lineColor: "#c9cacb",
      },
      alternateRowStyles: {
        fontSize: 11,
        minCellHeight: 20,
        cellPadding: { top: 6, right: 4, bottom: 6, left: 4 },
        fillColor: "#e2e5f1",
        lineWidth: 0.5,
        lineColor: "#c9cacb",
      },
      theme: "striped",
      margin: { top: 0, left: 35, right: 35, bottom: 40 },
      pageBreak: "auto",
      rowPageBreak: "avoid",
      showHead: "everyPage",
    });
    //
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      pageWidth = doc.internal.pageSize.getWidth();
      pageHeight = doc.internal.pageSize.getHeight();
      if (pageCount > 1) {
        doc.setFontSize(8);
        doc.setTextColor("#3d4044");
        doc.text(`Page ${i} of ${pageCount}`, pageWidth - 50, pageHeight - 18, {
          align: "right",
        });
      }
    }
    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateWeeklyTimeReport = (data) => {
  try {
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgWidth = 315.12;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    function generateHead() {
      doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 85.92, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setLineWidth(0.8);
      doc.line(pageWidth / 2 - 100, 65, pageWidth / 2 + 100, 65);
      doc.setLineWidth(2);
      doc.setTextColor("#000000");
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 254, 93, {
        align: "center",
      });
      doc.text("Augusta, GA 30906", 254, 104, {
        align: "center",
      });
      doc.text("Tel: (706) 790-8111", 254, 115, {
        align: "center",
      });
      doc.text("Fax: (706) 790-4368", 254, 126, {
        align: "center",
      });
      doc.text("1182 Edgefield Road", 360, 93, {
        align: "center",
      });
      doc.text("North Augusta, SC 29841", 360, 104, {
        align: "center",
      });
      doc.text("Tel: (803) 613-0101", 360, 115, {
        align: "center",
      });
      doc.text("Fax: (803) 613-0323", 360, 126, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "bold");
      doc.line(50, 135, pageWidth - 50, 135);
      // doc.text("Weekly Time Report - " + data.date, pageWidth / 2, 149, {
      doc.text("Weekly Time Report", pageWidth / 2, 149, {
        align: "center",
      });
      doc.line(50, 155, pageWidth - 50, 155);
      doc.setFontSize(9);
      doc.setFont("Helvetica", "normal");
      y = 180;
    }
    let y = 180;
    // generateHead();
    for (let i = 0; i < data.length; i++) {
      let current = data[i];
      let bodyRows = [];
      let hours = 0;
      let billable = 0;
      current.entries.forEach((e) => {
        bodyRows.push([e.job, e.client, e.start, e.end, e.hours.toFixed(2) + " hrs", e.billable.toFixed(2) + " hrs"]);
        hours = hours + e.hours;
        billable = billable + e.billable;
      });
      if (current.entries.length > 0) {
        bodyRows.push([
          { content: "", lineWidth: 0 },
          { content: "", lineWidth: 0 },
          { content: "", lineWidth: 0 },
          "TOTAL:",
          hours.toFixed(2) + " hrs",
          billable.toFixed(2) + " hrs",
        ]);
      }
      if (bodyRows.length > 0) {
        doc.autoTable({
          headStyles: {
            fillColor: "#ffffff",
            textColor: "#000000",
          },
          head: [
            [
              { content: "Job", styles: { halign: "left" } },
              { content: "Client", styles: { halign: "left" } },
              { content: "Start", styles: { halign: "left" } },
              { content: "End", styles: { halign: "left" } },
              { content: "Hours", styles: { halign: "right" } },
              { content: "Billable", styles: { halign: "right" } },
            ],
          ],
          columnStyles: {
            0: { halign: "left", cellWidth: "auto" },
            1: { halign: "left", cellWidth: "auto" },
            2: { halign: "left", cellWidth: "auto" },
            3: { halign: "left", cellWidth: "auto" },
            4: { halign: "right", cellWidth: "auto" },
            5: { halign: "right", cellWidth: "auto" },
          },
          body: bodyRows,
          startY: y + 10,
          styles: { fontSize: 10, minCellHeight: 12, cellPadding: 2 },
          theme: "grid",
          margin: { top: 190, left: 60, right: 60, bottom: 30 },
          pageBreak: "auto",
          rowPageBreak: "avoid",
          showHead: "everyPage",
          didDrawPage: function (data) {
            doc.setLineWidth(1);
            doc.line(50, y - 14, pageWidth - 50, y - 14);
            doc.line(50, y + 7, pageWidth - 50, y + 7);
            doc.setFontSize(12);
            doc.setFontSize(10);
            doc.setFont("Helvetica", "bold");
            doc.text(current.employee + " | " + current.date, 55, y - 0.5, {
              align: "left",
            });
            doc.setFont("Helvetica", "normal");
            doc.setFontSize(9);
            doc.text(
              "Hours Worked: " + current.timeIn + " to " + current.timeOut + " | Break: " + current.lunchOut + " to " + current.lunchIn,
              pageWidth - 55,
              y - 1,
              { align: "right" },
            );
            doc.setFontSize(9);
            y = 180;
          },
        });
        y = doc.lastAutoTable.finalY + 50;
      } else {
        doc.setLineWidth(1);
        doc.line(50, y - 14, pageWidth - 50, y - 14);
        doc.line(50, y + 7, pageWidth - 50, y + 7);
        doc.setFontSize(12);
        doc.setFontSize(10);
        doc.setFont("Helvetica", "bold");
        doc.text(current.employee + " | " + current.date, 55, y - 0.5, {
          align: "left",
        });
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(9);
        doc.text(
          "Hours Worked: " + current.timeIn + " to " + current.timeOut + " | Break: " + current.lunchOut + " to " + current.lunchIn,
          pageWidth - 55,
          y - 1,
          { align: "right" },
        );
        y = y + 25;
        doc.setFontSize(9);
        doc.setFont("Helvetica", "bold");
        doc.setTextColor("#7a7a7a");
        doc.text(
          `${current.employee.toUpperCase()} DID NOT LOG ANY LABOR OR R&M FOR ${dayjs(current.dateJSON).format("MMMM Do, YYYY").toUpperCase()}`,
          pageWidth / 2,
          y,
          { align: "center" },
        );
        doc.setTextColor("#000000");
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(9);
        y = y + 35;
      }
    }
    //
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      pageWidth = doc.internal.pageSize.getWidth();
      pageHeight = doc.internal.pageSize.getHeight();
      generateHead();
      doc.setFontSize(8);
      doc.setTextColor("#3d4044");
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 50, pageHeight - 18, {
        align: "right",
      });
    }
    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateCheck = (data) => {
  try {
    const doc = new jsPDF("p", "pt", [617, 797]);

    doc.setTextColor("#000000");
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(10);
    const toWords = new ToWords({
      localeCode: "en-US",
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: true,
      },
    });
    let stringAmount = toWords.convert(data.total);
    let txWid = doc.getTextWidth(stringAmount);
    doc.text(stringAmount, 64.8, 347.99, {
      align: "left",
    });
    doc.setDrawColor("#000000");
    doc.text(data.date, 416.51, 375.03, {
      align: "center",
    });
    doc.text(data.totalString, 544.44, 375.03, {
      align: "center",
    });
    doc.text(data.payTo + "\n" + data.vendorAddress + "\n" + data.vendorCityState, 64.8, 394.83, {
      align: "left",
      lineHeightFactor: 1.4,
    });

    // Top fold

    doc.text("PAY TO: \n" + data.payTo + "\n" + data.vendorAddress + "\n" + data.vendorCityState, 40, 45.73, {
      align: "left",
      lineHeightFactor: 1.4,
    });
    doc.autoTable({
      headStyles: {
        fillColor: null,
        textColor: "#000000",
      },
      columnStyles: {
        0: { halign: "left", valign: "middle" },
        1: { halign: "left", valign: "middle" },
        2: { halign: "right", valign: "middle" },
        3: { halign: "right", valign: "middle" },
        4: { halign: "right", valign: "middle" },
      },
      styles: {
        fontSize: 10,
        minCellHeight: 16,
        cellPadding: { top: 7, right: 5, bottom: 6, left: 5 },
        lineWidth: 1,
        lineColor: "#000000",
      },
      theme: "grid",
      head: [
        [
          { content: "Invoice Number", styles: { halign: "left" } },
          { content: "Invoice Date", styles: { halign: "left" } },
          { content: "Invoice Total", styles: { halign: "left" } },
          { content: "Payments Made", styles: { halign: "left" } },
          { content: "Balance", styles: { halign: "left" } },
        ],
      ],
      body: [[data.invoiceNumber, data.invoiceDate, data.invoiceTotal, data.paymentsMade, data.amountDue]],
      margin: { top: 0, left: 50, right: 50, bottom: 0 },
      startY: 110,
    });
    doc.text("AMOUNT ENCLOSED: " + data.totalString, 567, doc.lastAutoTable.finalY + 20, {
      align: "right",
    });

    //Bottom fold

    doc.text("PAY TO: \n" + data.payTo + "\n" + data.vendorAddress + "\n" + data.vendorCityState, 40, 560, {
      align: "left",
      lineHeightFactor: 1.4,
    });
    doc.autoTable({
      headStyles: {
        fillColor: null,
        textColor: "#000000",
      },
      columnStyles: {
        0: { halign: "left", valign: "middle" },
        1: { halign: "left", valign: "middle" },
        2: { halign: "right", valign: "middle" },
        3: { halign: "right", valign: "middle" },
        4: { halign: "right", valign: "middle" },
      },
      styles: {
        fontSize: 10,
        minCellHeight: 16,
        cellPadding: { top: 7, right: 5, bottom: 6, left: 5 },
        lineWidth: 1,
        lineColor: "#000000",
      },
      theme: "grid",
      head: [
        [
          { content: "Invoice Number", styles: { halign: "left" } },
          { content: "Invoice Date", styles: { halign: "left" } },
          { content: "Invoice Total", styles: { halign: "left" } },
          { content: "Payments Made", styles: { halign: "left" } },
          { content: "Balance", styles: { halign: "left" } },
        ],
      ],
      body: [[data.invoiceNumber, data.invoiceDate, data.invoiceTotal, data.paymentsMade, data.amountDue]],
      margin: { top: 0, left: 50, right: 50, bottom: 0 },
      startY: 605,
    });
    doc.text("AMOUNT ENCLOSED: " + data.totalString, 567, doc.lastAutoTable.finalY + 20, {
      align: "right",
    });

    // Check Numbers
    // doc.setFont("Helvetica", "bold");
    // doc.setFontSize(12);
    // doc.text(data.checkNumber, 538.46, 314.73, {
    //   align: "center",
    // });
    // doc.text(data.checkNumber, 538.46, 25, {
    //   align: "center",
    // });
    // doc.text(data.checkNumber, 538.46, 521.77, {
    //   align: "center",
    // });

    // Amount Line

    doc.setDrawColor("#999999");
    doc.setLineWidth(1);
    doc.setLineDashPattern([4, 3], 0);
    doc.line(64.8 + txWid + 7.5, 345, 570, 345);

    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};
export const GenerateCheckOLD = (data) => {
  try {
    const doc = new jsPDF("p", "pt", "letter");
    let pageWidth = doc.internal.pageSize.getWidth(); //612
    let pageHeight = doc.internal.pageSize.getHeight(); //792
    doc.setTextColor("#000000");
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(10);
    doc.text(data.date, 573.38, 75, {
      align: "right",
    });
    doc.text(data.payTo, 80.5, 91.7, {
      align: "left",
    });
    doc.text(parseFloat(data.total).toFixed(2), 486.87, 91.7, {
      align: "left",
    });
    const toWords = new ToWords({
      localeCode: "en-US",
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: true,
      },
    });
    let stringAmount = toWords.convert(data.total);
    let txWid = doc.getTextWidth(stringAmount);
    doc.text(stringAmount, 30.1, 116.51, {
      align: "left",
    });
    doc.setDrawColor("#000000");
    doc.setLineWidth(1);
    doc.line(30.1 + txWid + 5, 114, 531, 114);
    doc.text("VOID AFTER 30 DAYS", 58.9, 198.33, {
      align: "left",
    });

    //Second Fold
    doc.text(data.payTo, 56.94, 288, {
      align: "left",
    });
    doc.text(data.date, 426.76, 288, {
      align: "center",
    });
    doc.text(data.totalString, 562.9, 296.52, {
      align: "right",
    });

    doc.text("Invoice Number: " + data.invoiceNumber, 56.94, 322.04, {
      align: "left",
    });
    doc.text("Invoice Date: " + data.invoiceDate, 56.94, 336.04, {
      align: "left",
    });
    doc.text("Due Date: " + data.dueDate, 56.94, 350.04, {
      align: "left",
    });

    doc.text("SUBTOTAL: " + data.subtotal, 562.9, 375.61, {
      align: "right",
    });
    doc.text("FREIGHT: " + data.shipping, 562.9, 389.61, {
      align: "right",
    });
    doc.text("TAX: " + data.tax, 562.9, 403.61, {
      align: "right",
    });
    doc.text("TOTAL: " + data.totalString, 562.9, 417.61, {
      align: "right",
    });
    doc.text("PAYMENTS: " + data.paymentsMade, 562.9, 431.61, {
      align: "right",
    });
    doc.setFont("Helvetica", "bold");
    doc.text("BALANCE: " + data.amountDue, 562.9, 445.61, {
      align: "right",
    });
    doc.setFont("Helvetica", "normal");

    //Third Fold
    doc.text(data.payTo, 56.94, 540.63, { align: "left" });
    doc.text(data.date, 426.76, 540.63, {
      align: "center",
    });
    doc.text(data.totalString, 562.9, 547.21, {
      align: "right",
    });

    doc.text("Invoice Number: " + data.invoiceNumber, 56.94, 574.67, {
      align: "left",
    });
    doc.text("Invoice Date: " + data.invoiceDate, 56.94, 588.67, {
      align: "left",
    });
    doc.text("Due Date: " + data.dueDate, 56.94, 602.67, {
      align: "left",
    });

    doc.text("SUBTOTAL: " + data.subtotal, 562.9, 662.96, {
      align: "right",
    });
    doc.text("FREIGHT: " + data.shipping, 562.9, 676.96, {
      align: "right",
    });
    doc.text("TAX: " + data.tax, 562.9, 690.96, {
      align: "right",
    });
    doc.text("TOTAL: " + data.totalString, 562.9, 704.96, {
      align: "right",
    });
    doc.text("PAYMENTS: " + data.paymentsMade, 562.9, 718.96, {
      align: "right",
    });
    doc.setFont("Helvetica", "bold");
    doc.text("BALANCE: " + data.amountDue, 562.9, 732.96, {
      align: "right",
    });
    doc.setFont("Helvetica", "normal");

    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateAPInvoiceControlReport = (data) => {
  try {
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgWidth = 180;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 46.86, "hitechLogo", "NONE", 0);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#000000");
    doc.setDrawColor("#000000");
    doc.text("AP Invoice Control Report", pageWidth / 2, 99, {
      align: "center",
    });
    doc.setLineWidth(0.8);
    doc.setFontSize(10);
    doc.line(50, 85, pageWidth - 50, 85);
    doc.line(50, 105, pageWidth - 50, 105);

    doc.setFont("helvetica", "normal");
    let y = 105;

    let items = [];

    items.push(["AP Vendor", data.vendor]);
    items.push(["Invoice No.", data.invoiceNo]);
    items.push(["AP Invoice Date", data.invoiceDate]);
    items.push(["AP Invoice Due Date", data.dueDate]);
    items.push([
      "AP Amount Invoiced",
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data.amount),
    ]);
    items.push(["Purchase Order No.", data.poNumber]);
    items.push([
      "PO Amount",
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data.poAmount),
    ]);
    items.push(["Account No.", data.accountNo]);

    doc.autoTable({
      headStyles: {
        fillColor: "#ffffff",
        textColor: "#000000",
      },
      columnStyles: {
        0: { halign: "left", cellWidth: 146 },
        1: { halign: "right", cellWidth: 366 },
      },
      styles: { fontSize: 10, minCellHeight: 12, cellPadding: 5 },
      theme: "grid",
      head: [
        [
          { content: "", styles: { halign: "left" } },
          { content: "", styles: { halign: "left" } },
        ],
      ],
      body: items,
      margin: { top: y, left: 50, right: 50, bottom: 15 },
    });
    y = doc.lastAutoTable.finalY + 15;
    doc.line(50, y, pageWidth - 50, y);
    y += 15;
    doc.setFont("helvetica", "bold");
    doc.text(
      `GRAND TOTAL: ${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data.amount)}`,
      pageWidth - 50,
      y,
      { align: "right" },
    );
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    doc.text(`SUBMITTED ON: ${dayjs().format("MM/DD/YYYY")}`, 50, y, {
      align: "left",
    });

    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateInventoryAdjustmentPrintout = (data) => {
  try {
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgWidth = 180;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 46.86, "hitechLogo", "NONE", 0);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#000000");
    doc.setDrawColor("#000000");
    doc.text("Inventory Stock Adjustment", pageWidth / 2, 99, {
      align: "center",
    });
    doc.setLineWidth(0.8);
    doc.setFontSize(10);
    doc.line(50, 85, pageWidth - 50, 85);
    doc.line(50, 105, pageWidth - 50, 105);

    doc.setFont("helvetica", "normal");
    let y = 120;

    doc.text("Part Number: " + data.partNo, 50, y, { align: "left" });
    doc.text("Adjustment Date: " + dayjs(data.changedAt).format("MM/DD/YYYY [at] h:mm A"), pageWidth - 50, y, {
      align: "right",
    });
    y += 15;
    doc.text("Category: " + (data.category === "parts" ? "Parts" : data.category === "gog" ? "Gas, Oil & Grease" : "Fluids"), 50, y, {
      align: "left",
    });
    doc.text("Adjusted by: " + data.changeMadeByName, pageWidth - 50, y, {
      align: "right",
    });
    y += 15;
    doc.line(50, y, pageWidth - 50, y);

    let items = [];
    for (let i = 0; i < data.quantity.length; i++) {
      const el = data.quantity[i];
      items.push([el.locationName, el.previousQuantity, el.quantityInStock]);
    }

    doc.autoTable({
      headStyles: {
        fillColor: "#ffffff",
        textColor: "#000000",
      },
      columnStyles: {
        0: { halign: "left", cellWidth: 200 },
        1: { halign: "right", cellWidth: 156 },
        2: { halign: "right", cellWidth: 156 },
      },
      styles: { fontSize: 10, minCellHeight: 12, cellPadding: 5 },
      theme: "grid",
      head: [
        [
          { content: "Location", styles: { halign: "left" } },
          { content: "Previous Quantity", styles: { halign: "right" } },
          { content: "Adjusted Quantity", styles: { halign: "right" } },
        ],
      ],
      body: items,
      margin: { top: y, left: 50, right: 50, bottom: 15 },
    });
    y = doc.lastAutoTable.finalY + 15;
    doc.line(50, y, pageWidth - 50, y);
    y = y + 15;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(7);
    doc.setTextColor("#7a7a7a");
    doc.text(`CHANGE ID: ${data.changeId.toUpperCase()}`, pageWidth / 2, y, {
      align: "center",
    });

    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateJobSheet = (data) => {
  try {
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgWidth = 180;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 46.86, "hitechLogo", "NONE", 0);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#000000");
    doc.setDrawColor("#000000");
    doc.text(`Job Sheet`, pageWidth / 2, 99, { align: "center" });
    doc.setLineWidth(0.8);
    doc.setFontSize(10);
    doc.line(50, 85, pageWidth - 50, 85);
    doc.line(50, 105, pageWidth - 50, 105);

    doc.setFont("helvetica", "normal");
    let y = 120;

    doc.text("Job No: " + data.jobNo, 50, y, { align: "left" });
    doc.text("Customer: " + data.customer, pageWidth - 50, y, {
      align: "right",
    });
    y += 14;
    doc.text("Prepared By: " + data.preparedBy, 50, y, { align: "left" });
    doc.text("Started On: " + data.openDate, pageWidth - 50, y, {
      align: "right",
    });
    y += 18;
    doc.setFont("helvetica", "bold");
    doc.text("Description:", 50, y, { align: "left" });
    doc.setFont("helvetica", "normal");
    y += 18;
    doc.text(data.description, 50, y, {
      align: "left",
      maxWidth: pageWidth - 100,
    });
    y += 14;
    doc.line(50, y, pageWidth - 50, y);
    y += 20;
    if (data.labor.length > 0) {
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Labor", 50, y, { align: "left" });
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      y += 8;
      let items = [];
      for (let i = 0; i < data.labor.length; i++) {
        const el = data.labor[i];
        items.push([el.description, el.status, el.rate, el.quotedCost]);
      }
      doc.autoTable({
        headStyles: {
          fillColor: "#ffffff",
          textColor: "#000000",
          lineWidth: 0.5,
        },
        columnStyles: {
          0: { halign: "left" },
          1: { halign: "left" },
          2: { halign: "left" },
          3: { halign: "left" },
        },
        styles: { fontSize: 10, minCellHeight: 10, cellPadding: 3 },
        theme: "grid",
        head: [
          [
            { content: "Description", styles: { halign: "left" } },
            { content: "Status", styles: { halign: "left" } },
            { content: "Rate", styles: { halign: "left" } },
            { content: "Total", styles: { halign: "left" } },
          ],
        ],
        body: items,
        margin: { top: y, left: 50, right: 50, bottom: 15 },
      });
      y = doc.lastAutoTable.finalY + 15;
    }
    if (data.parts.length > 0) {
      y += 5;
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Parts", 50, y, { align: "left" });
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      y = y + 10;
      let items = [];
      for (let i = 0; i < data.parts.length; i++) {
        const el = data.parts[i];
        items.push([el.partNo, el.status, el.description, formatCurrency(el.price), el.quantity, formatCurrency(el.total)]);
      }
      doc.autoTable({
        headStyles: {
          fillColor: "#ffffff",
          textColor: "#000000",
          lineWidth: 0.5,
        },
        columnStyles: {
          0: { halign: "left" },
          1: { halign: "left" },
          2: { halign: "left" },
          3: { halign: "left" },
          4: { halign: "left" },
          5: { halign: "left" },
        },
        styles: { fontSize: 10, minCellHeight: 10, cellPadding: 3 },
        theme: "grid",
        head: [
          [
            { content: "Part No", styles: { halign: "left" } },
            { content: "Status", styles: { halign: "left" } },
            { content: "Description", styles: { halign: "left" } },
            { content: "Price", styles: { halign: "left" } },
            { content: "Quantity", styles: { halign: "left" } },
            { content: "Total", styles: { halign: "left" } },
          ],
        ],
        body: items,
        margin: { top: y, left: 50, right: 50, bottom: 15 },
        startY: y,
      });
      y = doc.lastAutoTable.finalY + 15;
    }
    if (data.otherCharges.length > 0) {
      y += 5;
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Other Charges", 50, y, { align: "left" });
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      y += 10;
      let items = [];
      for (let i = 0; i < data.otherCharges.length; i++) {
        const el = data.otherCharges[i];
        items.push([el.description, formatCurrency(el.price), el.quantity, formatCurrency(el.total)]);
      }
      doc.autoTable({
        headStyles: {
          fillColor: "#ffffff",
          textColor: "#000000",
          lineWidth: 0.5,
        },
        columnStyles: {
          0: { halign: "left" },
          1: { halign: "left" },
          2: { halign: "left" },
          3: { halign: "left" },
        },
        styles: { fontSize: 10, minCellHeight: 10, cellPadding: 3 },
        theme: "grid",
        head: [
          [
            { content: "Description", styles: { halign: "left" } },
            { content: "Price", styles: { halign: "left" } },
            { content: "Quantity", styles: { halign: "left" } },
            { content: "Total", styles: { halign: "left" } },
          ],
        ],
        body: items,
        margin: { top: y, left: 50, right: 50, bottom: 15 },
        startY: y,
      });
      y = doc.lastAutoTable.finalY + 15;
    }
    if (data.equipment.length > 0) {
      y += 5;
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Equipment", 50, y, { align: "left" });
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      y += 10;
      for (let i = 0; i < data.equipment.length; i++) {
        let items = [];
        const el = data.equipment[i];
        items.push([
          { content: "GENERATOR", styles: { halign: "center" } },
          { content: "ATS", styles: { halign: "center" } },
          { content: "ENGINE", styles: { halign: "center" } },
        ]);
        items.push([
          "Make: " + (el.rawData.details.make && el.rawData.details.make.length > 0 ? el.rawData.details.make : "N/A"),
          "Make: " + (el.rawData.ats.length > 0 && el.rawData.ats[0].make && el.rawData.ats[0].make.length > 0 ? el.rawData.ats[0].make : "N/A"),
          "Make: " + (el.rawData.engine && el.rawData.engine.make && el.rawData.engine.make.length > 0 ? el.rawData.engine.make : "N/A"),
        ]);
        items.push([
          "Model: " + (el.rawData.details.model && el.rawData.details.model.length > 0 ? el.rawData.details.model : "N/A"),
          "Model: " + (el.rawData.ats.length > 0 && el.rawData.ats[0].model && el.rawData.ats[0].model.length > 0 ? el.rawData.ats[0].model : "N/A"),
          "Model: " + (el.rawData.engine && el.rawData.engine.model && el.rawData.engine.model.length > 0 ? el.rawData.engine.model : "N/A"),
        ]);
        items.push([
          "Spec#: " + (el.rawData.details.specNo && el.rawData.details.specNo.length > 0 ? el.rawData.details.specNo : "N/A"),
          "Size: " + (el.rawData.ats.length > 0 && el.rawData.ats[0].size && el.rawData.ats[0].size.length > 0 ? el.rawData.ats[0].size : "N/A"),
          "Spec#: " + (el.rawData.engine && el.rawData.engine.specNo && el.rawData.engine.specNo.length > 0 ? el.rawData.engine.specNo : "N/A"),
        ]);
        items.push([
          "SR#: " + (el.rawData.details.serialNo && el.rawData.details.serialNo.length > 0 ? el.rawData.details.serialNo : "N/A"),
          "Spec#: " + (el.rawData.ats.length > 0 && el.rawData.ats[0].specNo && el.rawData.ats[0].specNo.length > 0 ? el.rawData.ats[0].specNo : "N/A"),
          "SR#: " + (el.rawData.engine && el.rawData.engine.serialNo && el.rawData.engine.serialNo.length > 0 ? el.rawData.engine.serialNo : "N/A"),
        ]);
        items.push([
          "Arrgmnt#: " + (el.rawData.details.arrangement && el.rawData.details.arrangement.length > 0 ? el.rawData.details.arrangement : "N/A"),
          "SR#: " + (el.rawData.ats.length > 0 && el.rawData.ats[0].serialNo && el.rawData.ats[0].serialNo.length > 0 ? el.rawData.ats[0].serialNo : "N/A"),
          "Code#: " + (el.rawData.engine && el.rawData.engine.codeNo && el.rawData.engine.codeNo.length > 0 ? el.rawData.engine.codeNo : "N/A"),
        ]);
        doc.autoTable({
          headStyles: {
            fillColor: "#ffffff",
            textColor: "#000000",
            lineWidth: 0.5,
          },
          columnStyles: {
            0: { halign: "left" },
            1: { halign: "left" },
            2: { halign: "left" },
          },
          styles: { fontSize: 10, minCellHeight: 10, cellPadding: 3 },
          theme: "grid",
          head: [
            [
              {
                content: "Equipment Type: " + el.type,
                styles: { halign: "center", cellWidth: 170, valign: "middle" },
              },
              {
                content: "Cust. Eq. ID: " + el.customerEquipId,
                styles: { halign: "center", cellWidth: 170, valign: "middle" },
              },
              {
                content: "Location: " + (el.rawData.location.length > 0 ? el.rawData.location : "N/A"),
                styles: { halign: "center", cellWidth: 170, valign: "middle" },
              },
            ],
          ],
          body: items,
          margin: { top: y, left: 50, right: 50, bottom: 15 },
          startY: y,
        });
        y = doc.lastAutoTable.finalY + 15;
      }
    }
    doc.line(50, y, pageWidth - 50, y);
    y += 14;
    y += 5;
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("TOTAL BEFORE TAX: " + formatCurrency(data.subtotal), pageWidth - 50, y, { align: "right" });
    y += 14;
    doc.autoTable({
      headStyles: {
        fillColor: "#ffffff",
        textColor: "#000000",
        lineWidth: 0.5,
      },
      columnStyles: {
        0: { halign: "left" },
      },
      styles: { fontSize: 10, minCellHeight: 10, cellPadding: 3 },
      theme: "grid",
      head: [[{ content: "NOTES:", styles: { halign: "left" } }]],
      body: [[], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
      margin: { top: 15, left: 50, right: 50, bottom: 25 },
      startY: y,
    });
    y = doc.lastAutoTable.finalY + 15;
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(8);
    if (pageCount > 1) {
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        pageWidth = doc.internal.pageSize.getWidth();
        pageHeight = doc.internal.pageSize.getHeight();
        let txWid = doc.getTextWidth(`Page ${i} of ${pageCount}`);
        doc.text(`Page ${i} of ${pageCount}`, pageWidth / 2 - txWid / 2, pageHeight - 18, {
          align: "center",
        });
      }
    }
    return doc;
  } catch (e) {
    return false;
  }
};

export const PrintTimesheet = (data) => {
  try {
    const doc = new jsPDF("l", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let imgWidth = 180;
    let imgXPos = pageWidth / 2 - imgWidth / 2;
    doc.addImage(hiTechLogo, "PNG", imgXPos, 30, imgWidth, 46.86, "hitechLogo", "NONE", 0);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#000000");
    doc.setDrawColor("#000000");
    doc.text(`Employee Timesheet`, pageWidth / 2, 99, { align: "center" });
    doc.setLineWidth(0.8);
    doc.setFontSize(10);
    doc.line(50, 85, pageWidth - 50, 85);
    doc.line(50, 105, pageWidth - 50, 105);

    doc.setFont("helvetica", "normal");
    let y = 127;

    doc.text("Employee's Name: " + data.employeeName, 54, y, { align: "left" });
    doc.text("Pay Period: " + data.payPeriod, pageWidth - 54, y, {
      align: "right",
    });
    y += 14;
    doc.line(50, y, pageWidth - 50, y);
    y += 20;
    let totalHours = 0;
    if (data.entries.length > 0) {
      const calculateHours = (entry) => {
        let minutes = 0;
        let timeIn = dayjs(entry.timeIn);
        let timeOut = dayjs(entry.timeOut);
        let lunchIn = dayjs(entry.lunchIn);
        let lunchOut = dayjs(entry.lunchOut);
        if (entry.skipLunch === false) {
          let preLunch = lunchOut.diff(timeIn, "minutes", true);
          let postLunch = timeOut.diff(lunchIn, "minutes", true);
          minutes = minutes + preLunch + postLunch;
        } else {
          let total = timeOut.diff(timeIn, "minutes", true);
          minutes = minutes + total;
        }
        let hrs = Math.floor(minutes / 60);
        let mins = minutes - hrs * 60;
        if (mins >= 8 && mins <= 22) {
          return `${hrs}.25`;
        } else if (mins >= 23 && mins <= 37) {
          return `${hrs}.50`;
        } else if (mins >= 38 && mins <= 52) {
          return `${hrs}.75`;
        } else if (mins >= 53) {
          hrs++;
          return `${hrs}.00`;
        } else {
          return `${hrs}.00`;
        }
      };
      y += 8;
      let items = [];
      for (let i = 0; i < data.entries.length; i++) {
        const el = data.entries[i];
        if (!el.partialEntry) {
          totalHours += parseFloat(calculateHours(el));
          if (el.skipLunch) {
            items.push([
              dayjs(el.date, "MM/DD/YYYY").format("MM/DD/YYYY - dddd"),
              dayjs(el.timeIn).format("HH:mm"),
              "N/A",
              "N/A",
              dayjs(el.timeOut).format("HH:mm"),
              calculateHours(el) + " hrs",
            ]);
          } else {
            items.push([
              dayjs(el.date, "MM/DD/YYYY").format("MM/DD/YYYY - dddd"),
              dayjs(el.timeIn).format("HH:mm"),
              dayjs(el.lunchOut).format("HH:mm"),
              dayjs(el.lunchIn).format("HH:mm"),
              dayjs(el.timeOut).format("HH:mm"),
              calculateHours(el) + " hrs",
            ]);
          }
        }
      }
      doc.autoTable({
        headStyles: {
          fillColor: "#ffffff",
          textColor: "#000000",
          lineWidth: 0.5,
        },
        columnStyles: {
          0: { halign: "left" },
          1: { halign: "center" },
          2: { halign: "center" },
          3: { halign: "center" },
          4: { halign: "center" },
          5: { halign: "right" },
        },
        styles: { fontSize: 10, minCellHeight: 15, cellPadding: 5 },
        theme: "grid",
        head: [
          [
            { content: "Date", styles: { halign: "left" } },
            { content: "Start Time", styles: { halign: "center" } },
            { content: "Lunch Out", styles: { halign: "center" } },
            { content: "Lunch In", styles: { halign: "center" } },
            { content: "End Time", styles: { halign: "center" } },
            { content: "Total Hours", styles: { halign: "right" } },
          ],
        ],
        body: items,
        margin: { top: y, left: 50, right: 50, bottom: 15 },
      });
      y = doc.lastAutoTable.finalY + 15;
    }
    doc.line(50, y, pageWidth - 50, y);
    y += 14;
    y += 5;
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("HOURS ACCOUNTED FOR: " + data.hoursWorked, pageWidth - 50, y, {
      align: "right",
    });
    y += 14;
    doc.text("OVERTIME: " + data.overtime, pageWidth - 50, y, {
      align: "right",
    });
    y += 14;
    doc.text("TOTAL LOGGED HOURS: " + totalHours + " hrs", pageWidth - 50, y, {
      align: "right",
    });
    return doc;
  } catch (e) {
    return false;
  }
};

export const GenerateARAgingReport = (data, user) => {
  try {
    const doc = new jsPDF("l", "pt", "letter");
    doc.setFont("Helvetica", "normal");
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    function generateHead() {
      let imgXPos = 50;
      doc.addImage(hiTechLogo, "PNG", imgXPos, 30, 185.9, 50, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setLineWidth(2);
      doc.setTextColor("#000000");
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 270, 38, {
        align: "left",
      });
      doc.text("Augusta, GA 30906", 270, 49, {
        align: "left",
      });
      doc.text("Tel: (706) 790-8111", 270, 60, {
        align: "left",
      });
      doc.text("Fax: (706) 790-4368", 270, 71, {
        align: "left",
      });
      doc.text("1182 Edgefield Road", 376, 38, {
        align: "left",
      });
      doc.text("North Augusta, SC 29841", 376, 49, {
        align: "left",
      });
      doc.text("Tel: (803) 613-0101", 376, 60, {
        align: "left",
      });
      doc.text("Fax: (803) 613-0323", 376, 71, {
        align: "left",
      });
      doc.text(`Report Date: ${dayjs().format("MM/DD/YYYY")}`, pageWidth - 50, 38, {
        align: "right",
      });
      doc.text(`Report Time: ${dayjs().format("h:m A")}`, pageWidth - 50, 49, {
        align: "right",
      });
      doc.text(`Generated By: ${user.firstName} ${user.lastName}`, pageWidth - 50, 60, {
        align: "right",
      });
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.line(50, 90, pageWidth - 50, 90);
      doc.text("AR Aging Report", pageWidth / 2, 108, {
        align: "center",
      });

      y = 130;
    }
    let y = 130;
    generateHead();

    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal");

    data.forEach((el) => {
      if (y > 430) {
        doc.addPage();
        generateHead();
      }
      doc.setFontSize(8);
      doc.setFont("Helvetica", "normal");
      doc.setLineWidth(0.5);
      doc.setDrawColor("#9ca3af");
      doc.line(50, y, pageWidth - 50, y);
      y += 16;
      doc.text(`Customer Code: ${el.customerCode && el.customerCode.length > 0 ? el.customerCode : "Unknown"}`, 50, y, {
        align: "left",
      });
      doc.text(`Customer Company / Name: ${el.customerName && el.customerName.length > 0 ? el.customerName : "Unknown"}`, 250, y, {
        align: "left",
      });
      if (el.invoices.length > 0) {
        let elBody = [];
        for (let i = 0; i < el.invoices.length; i++) {
          let itm = el.invoices[i];
          if (itm.key !== "TOTALS") {
            elBody.push([
              itm.invoiceNo,
              dayjs(itm.invoiceDate).format("MM/DD/YYYY"),
              dayjs(itm.invoiceDate).add(30, "day").format("MM/DD/YYYY"),
              itm.current,
              itm.zeroToThirty,
              itm.thirtyOneToSixty,
              itm.overSixty,
              itm.credit,
              itm.total,
            ]);
          }
        }
        let totals = el.invoices.find((x) => x.key === "TOTALS");
        elBody.push([
          {
            content: "TOTALS:",
            colSpan: 3,
            styles: {
              halign: "right",
              font: "helvetica",
              fontStyle: "bold",
              lineColor: "#9FA3A8",
              textColor: "#000000",
              lineWidth: 1,
            },
          },
          {
            content: totals.current,
            styles: {
              halign: "right",
              font: "helvetica",
              fontStyle: "bold",
              lineColor: "#9FA3A8",
              textColor: "#000000",
              lineWidth: 1,
            },
          },
          {
            content: totals.zeroToThirty,
            styles: {
              halign: "right",
              font: "helvetica",
              fontStyle: "bold",
              lineColor: "#9FA3A8",
              textColor: "#000000",
              lineWidth: 1,
            },
          },
          {
            content: totals.thirtyOneToSixty,
            styles: {
              halign: "right",
              font: "helvetica",
              fontStyle: "bold",
              lineColor: "#9FA3A8",
              textColor: "#000000",
              lineWidth: 1,
            },
          },
          {
            content: totals.overSixty,
            styles: {
              halign: "right",
              font: "helvetica",
              fontStyle: "bold",
              lineColor: "#9FA3A8",
              textColor: "#000000",
              lineWidth: 1,
            },
          },
          {
            content: totals.credit,
            styles: {
              halign: "right",
              font: "helvetica",
              fontStyle: "bold",
              lineColor: "#9FA3A8",
              textColor: "#000000",
              lineWidth: 1,
            },
          },
          {
            content: totals.total,
            styles: {
              halign: "right",
              font: "helvetica",
              fontStyle: "bold",
              lineColor: "#9FA3A8",
              textColor: "#000000",
              lineWidth: 1,
            },
          },
        ]);
        doc.autoTable({
          startY: y + 20,
          pageBreak: "auto",
          headStyles: {
            fillColor: "#d1d5db",
            textColor: "#1e293b",
          },
          columnStyles: {
            0: { halign: "left", cellWidth: "auto" },
            1: { halign: "left", cellWidth: "auto" },
            2: { halign: "left", cellWidth: "auto" },
            3: { halign: "right", cellWidth: "auto" },
            4: { halign: "right", cellWidth: "auto" },
            5: { halign: "right", cellWidth: "auto" },
            6: { halign: "right", cellWidth: "auto" },
            7: { halign: "right", cellWidth: "auto" },
            8: { halign: "right", cellWidth: "auto" },
          },
          styles: {
            fontSize: 9,
            font: "Helvetica",
            minCellHeight: 12,
            cellPadding: 4,
            lineWidth: 1,
            lineColor: "#dfdfe2",
            textColor: "#3d4044",
          },
          head: [
            [
              { content: "Invoice #", styles: { halign: "left" } },
              { content: "Invoice date", styles: { halign: "left" } },
              { content: "Due date", styles: { halign: "left" } },
              { content: "Current", styles: { halign: "right" } },
              { content: "0-30", styles: { halign: "right" } },
              { content: "31-60", styles: { halign: "right" } },
              { content: "Over 60", styles: { halign: "right" } },
              { content: "Credit", styles: { halign: "right" } },
              { content: "Total", styles: { halign: "right" } },
            ],
          ],
          body: elBody,
          margin: { top: 20, left: 50, right: 50, bottom: 15 },
          theme: "striped",
        });
        y = doc.lastAutoTable.finalY + 20;
      } else {
        y = y + 20;
      }
    });
    const pageCount = doc.internal.getNumberOfPages();

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      pageWidth = doc.internal.pageSize.getWidth();
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 50, 71, {
        align: "right",
      });
    }

    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const GenerateCountReport = (data, countInfo, departments) => {
  try {
    const doc = new jsPDF("l", "pt", "letter");
    doc.setFont("Helvetica", "normal");
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    function generateHead() {
      let imgXPos = 50;
      doc.addImage(hiTechLogo, "PNG", imgXPos, 30, 185.9, 50, "hitechLogo", "NONE", 0);
      doc.setDrawColor("#000000");
      doc.setLineWidth(2);
      doc.setTextColor("#000000");
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      doc.text("2479 Doug Barnard Pkwy.", 270, 38, {
        align: "left",
      });
      doc.text("Augusta, GA 30906", 270, 49, {
        align: "left",
      });
      doc.text("Tel: (706) 790-8111", 270, 60, {
        align: "left",
      });
      doc.text("Fax: (706) 790-4368", 270, 71, {
        align: "left",
      });
      doc.text("1182 Edgefield Road", 376, 38, {
        align: "left",
      });
      doc.text("North Augusta, SC 29841", 376, 49, {
        align: "left",
      });
      doc.text("Tel: (803) 613-0101", 376, 60, {
        align: "left",
      });
      doc.text("Fax: (803) 613-0323", 376, 71, {
        align: "left",
      });
      doc.text(`Count ID: ${countInfo._id.replace("count_", "").toUpperCase()}`, pageWidth - 50, 38, {
        align: "right",
      });
      doc.text(`Count Start: ${dayjs(countInfo.startedAt).format("MM/DD/YYYY")}`, pageWidth - 50, 49, {
        align: "right",
      });
      doc.text(
        `Count End: ${countInfo.stoppedAt && dayjs(countInfo.stoppedAt).isValid() ? dayjs(countInfo.stoppedAt).format("MM/DD/YYYY") : "Ongoing"}`,
        pageWidth - 50,
        60,
        {
          align: "right",
        },
      );
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.line(50, 90, pageWidth - 50, 90);
      doc.text("Inventory Count Sheet", pageWidth / 2, 108, {
        align: "center",
      });

      y = 110;
    }
    let y = 110;

    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal");

    let body = [];

    let countId = countInfo._id;
    for (let i = 0; i < data.length; i++) {
      let el = data[i];
      let augustaCount = 0;
      let northAugustaCount = 0;
      let counted = false;
      let dept = "Unknown";
      if (departments.find((d) => d.departmentId === el.department)) {
        dept = `[${departments.find((d) => d.departmentId === el.department).name}] ${departments.find((d) => d.departmentId === el.department).label}`;
      }
      if (el.stock) {
        el.stock.forEach((s) => {
          if (s.lastCount && s.lastCount === countId) {
            counted = true;
            if (s.location === NORTH_AUGUSTA) {
              northAugustaCount = s.quantityInStock;
            }
            if (s.location === AUGUSTA) {
              augustaCount = s.quantityInStock;
            }
          }
        });
      }
      if (counted) {
        body.push([
          el.partNo,
          dept,
          el.description,
          formatCurrency(el.cost),
          formatCurrency(el.chargeOut),
          `AGS: ${augustaCount}\nNA: ${northAugustaCount}`,
          el.photos && el.photos.length > 0 ? el.photos.length : "0",
        ]);
      }
    }

    doc.autoTable({
      startY: y + 10,
      pageBreak: "auto",
      rowPageBreak: "avoid",
      headStyles: {
        fillColor: "#d1d5db",
        textColor: "#1e293b",
      },
      columnStyles: {
        0: { halign: "left", valign: "middle", cellWidth: 100 },
        1: { halign: "left", valign: "middle", cellWidth: 120 },
        2: { halign: "left", valign: "middle", cellWidth: "auto" },
        3: { halign: "right", valign: "middle", cellWidth: 60 },
        4: { halign: "right", valign: "middle", cellWidth: 60 },
        5: { halign: "right", valign: "middle", cellWidth: 60 },
        6: { halign: "right", valign: "middle", cellWidth: 55 },
      },
      styles: {
        fontSize: 9,
        font: "Helvetica",
        minCellHeight: 10,
        cellPadding: 2,
      },
      head: [
        [
          {
            content: "Part No",
            styles: { halign: "left", valign: "middle" },
          },
          {
            content: "Department",
            styles: { halign: "left", valign: "middle" },
          },
          {
            content: "Description",
            styles: { halign: "left", valign: "middle" },
          },
          {
            content: "Cost",
            styles: { halign: "right", valign: "middle" },
          },
          {
            content: "Charge Out",
            styles: { halign: "right", valign: "middle" },
          },
          {
            content: "Count",
            styles: { halign: "right", valign: "middle" },
          },
          {
            content: "Photos Added",
            styles: { halign: "right", valign: "middle" },
          },
        ],
      ],
      body: body,
      margin: { top: 120, left: 50, right: 50, bottom: 15 },
      theme: "grid",
      didDrawPage: function (data) {
        generateHead();
      },
    });
    y = doc.lastAutoTable.finalY + 20;
    const pageCount = doc.internal.getNumberOfPages();

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      pageWidth = doc.internal.pageSize.getWidth();
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(8);
      let txWid = doc.getTextWidth(`Page ${i} of ${pageCount}`);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 50, 71, {
        align: "right",
      });
    }

    return doc;
  } catch (e) {
    console.log(e);
    return false;
  }
};
