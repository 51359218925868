import { validate } from "uuid";
import axiosInstance from "./axios";
import qs from "qs";

export const GetQuotes = () => {
  return axiosInstance.get("/quotes");
};

export const GetRates = () => {
  return axiosInstance.get("/quotes/rates");
};

export const CreateAQuote = (data) => {
  return axiosInstance.post("/quotes", data);
};

export const GetOneQuote = (quoteId) => {
  return axiosInstance.get(`/quotes/${quoteId}`);
};

export const UpdateQuote = (quoteId, data) => {
  return axiosInstance.put(`/quotes/${quoteId}`, data);
};

export const SubmitQuoteForReview = (quoteId, data) => {
  return axiosInstance.post(`/quotes/${quoteId}/review`, data);
};

export const ReviseQuote = (quoteId) => {
  return axiosInstance.post(`/quotes/${quoteId}/revise`);
};

export const ApproveQuote = (quoteId, data) => {
  return axiosInstance.post(`/quotes/${quoteId}/approve`, data);
};

export const RejectQuote = (quoteId, data) => {
  return axiosInstance.post(`/quotes/${quoteId}/reject`, data);
};

export const SendQuoteToClient = (quoteId, data) => {
  return axiosInstance.post(`/quotes/${quoteId}/send`, data);
};

export const AcceptQuote = (quoteId, data) => {
  return axiosInstance.post(`/quotes/${quoteId}/accept`, data);
};

export const DeclineQuote = (quoteId, data) => {
  return axiosInstance.post(`/quotes/${quoteId}/deny`, data);
};

export const PreJobCheck = (quoteId) => {
  return axiosInstance.get(`/quotes/${quoteId}/preJob`);
};

export const CreateAJob = (quoteId, data) => {
  return axiosInstance.post(`/quotes/${quoteId}/job`, data);
};

export const ReQuote = (quoteId, data) => {
  return axiosInstance.post(`/quotes/${quoteId}/reQuote`, data);
};

export const UpdateQuoteShopFee = (quoteId, data) => {
  return axiosInstance.put(`/quotes/${quoteId}/shopFee`, data);
};

export const UpdateQuoteFooter = (quoteId, data) => {
  return axiosInstance.put(`/quotes/${quoteId}/footer`, data);
};

export const QueryQuotes = (data) => {
  let queryParams = {};
  if (data.customerId && validate(data.customerId)) {
    queryParams.customerId = data.customerId;
  }
  if (data.quoteNo && data.quoteNo.length > 2) {
    queryParams.quoteNo = data.quoteNo;
  }
  if (data.description && data.description.length > 2) {
    queryParams.description = data.description;
  }
  if (data.equipmentId && data.equipmentId.length > 2) {
    queryParams.equipmentId = data.equipmentId;
  }
  if (data.locationId && data.locationId.length > 2) {
    queryParams.locationId = data.locationId;
  }
  if (data.customerPo && data.customerPo.length > 2) {
    queryParams.customerPo = data.customerPo;
  }
  if (data.page) {
    queryParams.page = data.page;
  }
  if (data.limit) {
    queryParams.limit = data.limit;
  }
  let queryString = qs.stringify(queryParams);
  return axiosInstance.get(`/quotes/query?${queryString}`);
};

export const GetQuotePOData = (quoteId) => {
  return axiosInstance.get(`/quotes/${quoteId}/poCheck`);
};

export const UpdateQuotePOData = (quoteId, data) => {
  return axiosInstance.put(`/quotes/${quoteId}/poPrefill`, data);
};

export const UpdateQuoteJob = (quoteId, data) => {
  return axiosInstance.put(`/quotes/${quoteId}/job`, data);
};
