import { twMerge } from "tailwind-merge";

export const PrimaryButton = ({ label, callback, disabled, className }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={() => callback()}
      className={twMerge(
        "inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-5 py-2 text-xs font-semibold uppercase text-white shadow-sm hover:bg-blue-500 disabled:hover:cursor-not-allowed disabled:text-blue-400 disabled:bg-blue-200",
        className,
      )}
    >
      {label}
    </button>
  );
};

export const SecondaryButton = ({ label, callback, disabled, className }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={() => callback()}
      className={twMerge(
        "inline-flex items-center gap-x-1.5 rounded-md text-gray-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm hover:bg-gray-50 ring-1 rind-inset ring-gray-300 disabled:hover:ring-gray-400 hover:ring-gray-900 transition-all duration-150 disabled:hover:cursor-not-allowed disabled:text-gray-300 disabled:hover:text-gray-400",
        className,
      )}
    >
      {label}
    </button>
  );
};
export const IconButton = ({ icon, callback, disabled, className }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={() => callback()}
      className={twMerge(
        "flex items-center px-5 py-2 text-xs font-semibold text-gray-900 uppercase transition-all duration-150 rounded-md shadow-sm hover:bg-gray-50 ring-1 rind-inset ring-gray-300 disabled:hover:ring-gray-400 hover:ring-gray-900 disabled:hover:cursor-not-allowed disabled:text-gray-300 disabled:hover:text-gray-400",
        className,
      )}
    >
      {icon}
    </button>
  );
};
