import axiosInstance from "./axios";

export const GetTerminals = () => {
  return axiosInstance.get("/payment/getTerminals");
};

export const StartPaymentProcessing = (data) => {
  return axiosInstance.post("/payment/startPaymentProcessing", data);
};

export const CollectPayment = (data) => {
  return axiosInstance.post("/payment/collectPayment", data);
};

export const CompleteTransaction = (data) => {
  return axiosInstance.post("/payment/completeTransaction", data);
};

export const AbortTransaction = (data) => {
  return axiosInstance.post("/payment/abortAction", data);
};

export const StartSale = (data) => {
  return axiosInstance.post("/payment/startSale", data);
};

export const CaptureSale = (data) => {
  return axiosInstance.post("/payment/captureSale", data);
};

export const RetryCapture = (data) => {
  return axiosInstance.post("/payment/retryCapture", data);
};

export const GetSales = () => {
  return axiosInstance.get("/payment/sales");
};

export const SaleStatus = (data) => {
  return axiosInstance.post("/payment/saleStatus", data);
};

export const PrintReceipt = (data) => {
  return axiosInstance.post("/payment/printReceipt", data);
};

export const CreateManualIntent = (data) => {
  return axiosInstance.post("/payment/createManualIntent", data);
};

export const CheckCardSale = (data) => {
  return axiosInstance.post("/payment/checkCardSale", data);
};

export const SetCardLocation = (data) => {
  return axiosInstance.post("/payment/setCardLocation", data);
};
