import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GetOneEquipment, UpdateEquipmentBasics } from "../../actions/ims";
import toast from "react-hot-toast";
import { Input, InputNumber, Select } from "antd";
import { OpenNewWindow } from "iconoir-react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { Helmet } from "react-helmet-async";

const OpenEquipment = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [equipment, setEquipment] = useState({});
  let [raw, setRaw] = useState({});
  let [customer, setCustomer] = useState({});

  let { equipmentId } = useParams();
  let navigate = useNavigate();

  let tabs = [
    { name: "Basic Information", href: "#", current: true },
    { name: "Details", href: "details", current: false },
    { name: "Engine", href: "engine", current: false },
    { name: "ATS", href: "ats", current: false },
    { name: "Contact", href: "contact", current: false },
    { name: "Notes", href: "notes", current: false },
    { name: "Photos", href: "photos", current: false },
    { name: "Service Schedule", href: "serviceSchedule", current: false },
    { name: "History", href: "history", current: false },
  ];

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneEquipment(equipmentId)
        .then((res) => {
          setEquipment(res.data.equipment);
          setRaw(res.data.equipment);
          setCustomer(res.data.customer);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error loading equipment");
          setTimeout(() => navigate("/equipment"), 1800);
        });
    }
    return () => {
      inView = false;
    };
  }, []);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const submitStep = () => {
    let payload = {};
    let fields = [
      "customerEquipId",
      "otherType",
      "description",
      "power",
      "miles",
      "pwrUnit",
      "milesUnit",
      "fuelType",
      "year",
      "location",
      "taxZip",
      "pumpInfo",
      "other",
    ];
    fields.forEach((property) => {
      if (property === "otherType" && equipment.equipmentType !== "other") {
        payload.otherType = null;
      } else {
        payload[property] = equipment[property];
      }
    });
    setLoading(true);
    UpdateEquipmentBasics(equipmentId, payload)
      .then((res) => {
        toast.success("Equipment updated successfully");
        GetOneEquipment(equipmentId)
          .then((res) => {
            setEquipment(res.data.equipment);
            setCustomer(res.data.customer);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Error loading equipment");
            setTimeout(() => navigate("/equipment"), 1800);
          });
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error updating equipment");
        setLoading(false);
      });
  };
  const powerUnitSelect = (
    <Select defaultValue={equipment.pwrUnit} onChange={(v) => updateEquipment("pwrUnit", v)}>
      <Select.Option value="hp">hp</Select.Option>
      <Select.Option value="kw">kW</Select.Option>
      <Select.Option value="PSI">PSI</Select.Option>
      <Select.Option value="">Other</Select.Option>
    </Select>
  );
  const milesHoursUnit = (
    <Select defaultValue={equipment.milesUnit} onChange={(v) => updateEquipment("milesUnit", v)}>
      <Select.Option value="hours">Hours</Select.Option>
      <Select.Option value="miles">Miles</Select.Option>
      <Select.Option value="">Other</Select.Option>
    </Select>
  );

  const updateEquipment = (field, value) => {
    let tmp = equipment;
    tmp[field] = value;
    setEquipment(tmp);
  };

  const renderEqType = () => {
    return equipment.equipmentType === "airCompressor"
      ? "Air Compressor"
      : equipment.equipmentType === "pressureWasher"
        ? "Pressure Washer"
        : equipment.equipmentType[0].toUpperCase() + equipment.equipmentType.slice(1);
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>
              {renderEqType()} Basic Info - {customer.customerCode} | HTPS ERP
            </title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/equipment/${equipmentId}/${v.target.value}`)}
              >
                {tabs.map((tab) => {
                  if ((tab.name === "ATS" && equipment.equipmentType === "truck") || (tab.name === "ATS" && equipment.equipmentType === "trailer")) {
                    return;
                  }
                  return <option value={tab.href}>{tab.name}</option>;
                })}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => {
                    if ((tab.name === "ATS" && equipment.equipmentType === "truck") || (tab.name === "ATS" && equipment.equipmentType === "trailer")) {
                      return;
                    }
                    return (
                      <p
                        key={tab.name}
                        onClick={() => navigate(`/equipment/${equipmentId}/${tab.href}`)}
                        className={classNames(
                          tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        {tab.name}
                      </p>
                    );
                  })}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
            <div className="grid items-center justify-start w-full grid-cols-2 gap-5 px-4">
              {(equipment.equipmentType !== "pressureWasher" || equipment.equipmentType !== "airCompressor") && (
                <div className="flex flex-col items-start justify-start w-full col-span-2 md:col-span-1">
                  <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Customer Equipment ID</label>
                  <Input
                    className="block w-full p-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                    placeholder="Customer Equipment ID"
                    defaultValue={equipment.customerEquipId}
                    onChange={(v) => updateEquipment("customerEquipId", v.target.value)}
                  />
                </div>
              )}
              <div className="flex flex-col items-start justify-start w-full col-span-2 md:col-span-1">
                <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Customer</label>
                <div className="flex flex-row items-center justify-between w-full gap-2">
                  <Input
                    className="block w-full p-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                    placeholder="Customer"
                    value={`${customer.customerCode.toUpperCase()}${customer.company ? " | " + customer.company : ""}`}
                    disabled
                  />
                  <button
                    className="px-4 py-2.5 rounded-lg border text-gray-500 border-gray-300 hover:border-black hover:text-black transition-all duration-150"
                    onClick={() => window.open(`/customers/${customer.customerId}`, "_blank")}
                  >
                    <div className="flex flex-row items-center justify-center gap-2">
                      <p className="text-xs font-medium uppercase">Open</p>
                      <OpenNewWindow className="w-3 h-3" />
                    </div>
                  </button>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start w-full col-span-2 md:col-span-1">
                <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Equipment Type</label>
                <Input
                  className="block w-full p-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                  placeholder="Equipment Type"
                  defaultValue={
                    equipment.equipmentType === "airCompressor"
                      ? "Air Compressor"
                      : equipment.equipmentType === "pressureWasher"
                        ? "Pressure Washer"
                        : equipment.equipmentType[0].toUpperCase() + equipment.equipmentType.slice(1)
                  }
                  disabled
                />
              </div>
              {equipment.equipmentType === "other" && (
                <>
                  <div className="flex flex-col items-start justify-start w-full col-span-2 md:col-span-1">
                    <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Other Type</label>
                    <Input
                      className="block w-full p-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                      placeholder="Other Type"
                      defaultValue={equipment.otherType}
                      disabled={equipment.equipmentType !== "other"}
                      onChange={(v) => updateEquipment("otherType", v.target.value)}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-2">
                    <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Description</label>
                    <Input.TextArea
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 px-3 disabled:bg-white disabled:text-gray-600 !resize-none"
                      rows={2}
                      placeholder="Other Type"
                      defaultValue={equipment.details.description}
                      onChange={(v) => updateEquipment("details.description", v.target.value)}
                    />
                  </div>
                </>
              )}
              {(equipment.equipmentType === "pressureWasher" || equipment.equipmentType === "airCompressor" || equipment.equipmentType === "generator") && (
                <div className="flex flex-col items-start justify-start w-full col-span-2 md:col-span-1">
                  <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Power</label>
                  <InputNumber
                    className="block w-full py-1 text-sm text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                    placeholder="Power"
                    defaultValue={equipment.power}
                    addonAfter={powerUnitSelect}
                    onChange={(v) => updateEquipment("power", v)}
                  />
                </div>
              )}
              {(equipment.equipmentType === "generator" ||
                equipment.equipmentType === "welder" ||
                equipment.equipmentType === "truck" ||
                equipment.equipmentType === "other") && (
                <div className="flex flex-col items-start justify-start w-full col-span-2 md:col-span-1">
                  <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Miles / Hours</label>
                  <InputNumber
                    className="block w-full py-1 text-sm text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                    placeholder="Miles / Hours"
                    defaultValue={equipment.miles}
                    addonAfter={milesHoursUnit}
                    onChange={(v) => updateEquipment("miles", v)}
                  />
                </div>
              )}
              {(equipment.equipmentType !== "trailer" || equipment.equipmentType !== "welder") && (
                <div className="flex flex-col items-start justify-start w-full col-span-2 md:col-span-1">
                  <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Fuel Type</label>
                  <Select
                    className="w-full"
                    placeholder="Select Fuel Type"
                    optionFilterProp="children"
                    onChange={(v) => updateEquipment("fuelType", v)}
                    defaultValue={equipment.fuelType}
                    onClear={() => setEquipment({ ...equipment, fuelType: "" })}
                    options={[
                      {
                        value: "diesel",
                        label: "Diesel",
                      },
                      {
                        value: "gasoline",
                        label: "Gasoline",
                      },
                      {
                        value: "naturalGas",
                        label: "Natural Gas",
                      },
                      {
                        value: "propane",
                        label: "Propane",
                      },
                      {
                        value: "biFuel",
                        label: "Bi-Fuel",
                      },
                      {
                        value: "electricity",
                        label: "Electricity",
                      },
                      {
                        value: "other",
                        label: "Other",
                      },
                      {
                        value: "",
                        label: "None / Unknown",
                      },
                    ]}
                  />
                </div>
              )}
              {(equipment.equipmentType === "truck" || equipment.equipmentType === "trailer" || equipment.equipmentType === "other") && (
                <div className="flex flex-col items-start justify-start w-full col-span-2 md:col-span-1">
                  <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Year</label>
                  <Input
                    className="block w-full p-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                    placeholder="Year"
                    defaultValue={equipment.year}
                    onChange={(v) => updateEquipment("year", v.target.value)}
                  />
                </div>
              )}
              {(equipment.equipmentType === "generator" || equipment.equipmentType === "welder" || equipment.equipmentType === "airCompressor") && (
                <div className="flex flex-col items-start justify-start w-full col-span-2 md:col-span-1">
                  <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Location</label>
                  <Input
                    className="block w-full p-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                    placeholder="Location"
                    defaultValue={equipment.location}
                    onChange={(v) => updateEquipment("location", v.target.value)}
                  />
                </div>
              )}
              <div className="flex flex-col items-start justify-start w-full col-span-2 md:col-span-1">
                <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Sales Tax ZIP Code</label>
                <InputNumber
                  className="block w-full py-1 text-sm text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                  placeholder="Sales Tax ZIP Code"
                  defaultValue={equipment.taxZip}
                  maxLength={5}
                  onChange={(v) => updateEquipment("taxZip", v)}
                />
              </div>
              {equipment.equipmentType === "pressureWasher" && (
                <div className="flex flex-col items-start justify-start w-full col-span-2 md:col-span-1">
                  <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Pump Info</label>
                  <Input
                    className="block w-full p-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                    placeholder="Pump Info"
                    defaultValue={equipment.pumpInfo}
                    onChange={(v) => updateEquipment("pumpInfo", v.target.value)}
                  />
                </div>
              )}
              {(equipment.equipmentType !== "pressureWasher" || equipment.equipmentType !== "welder") && (
                <div className="flex flex-col items-start justify-start w-full col-span-2">
                  <label className="block mb-1.5 text-xs font-medium text-gray-700 uppercase">Other Information</label>
                  <Input.TextArea
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 px-3 disabled:bg-white disabled:text-gray-600 !resize-none"
                    placeholder="Other information not covered above"
                    defaultValue={equipment.other}
                    rows={4}
                    onChange={(v) => updateEquipment("other", v.target.value)}
                  />
                </div>
              )}
              <div className="flex flex-row items-center justify-end w-full col-span-2">
                <PrimaryButton label="Save Changes" callback={() => submitStep()} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OpenEquipment;
