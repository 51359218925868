import { useEffect, useState, Fragment, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Transition, Switch } from "@headlessui/react";
import toast from "react-hot-toast";
import { IconButton, PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import * as _ from "lodash";
import { v4 as uuidv4, validate } from "uuid";
import {
  AddAPAttachments,
  AddNonSerialPartToAPInvoice,
  AddSerialPartToAPInvoice,
  ApplyCreditToInvoice,
  GetOneAP,
  GetPartsList,
  LogAPPayment,
  RemoveAPInvoiceItem,
  SubmitAPCheck,
  UpdateAPCredit,
  UpdateAPInvoice,
  UpdateAPLineItem,
  VoidInvoice,
} from "../../actions/ims";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { DatePicker, Drawer, Input, InputNumber, Modal, Select, Table } from "antd";
import validator from "validator";
import { Plus, Page, Xmark, OpenNewWindow } from "iconoir-react";
import { GenerateAPInvoiceControlReport, GenerateCheck } from "../../data/pdf";
import { useDropzone } from "react-dropzone";
import { UploadAPInvoices } from "../../actions/cdn";
import { Helmet } from "react-helmet-async";
import { Edit, Receipt, Trash2 } from "lucide-react";

const OpenAP = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [ap, setAP] = useState({});
  let [accounts, setAccounts] = useState([]);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [vendors, setVendors] = useState([]);
  let [logPaymentModal, setLogPaymentModal] = useState(false);
  let [generateCheckModal, setGenerateCheckModal] = useState(false);
  let [creditPayment, setCreditPayment] = useState(false);
  let [check, setCheck] = useState(false);
  let [paymentData, setPaymentData] = useState({
    paidAt: "",
    amount: 0,
    paymentAccount: "",
    comment: "",
    checkNumber: "",
  });
  const [addAttachments, setAddAttachments] = useState(false);
  let [selectedFiles, setSelectedFiles] = useState([]);
  let [purchaseOrders, setPurchaseOrders] = useState([]);
  let [jobs, setJobs] = useState([]);
  let [addSerial, setAddSerial] = useState(false);
  let [addNonSerial, setAddNonSerial] = useState(false);
  let [parts, setParts] = useState([]);
  let [serialToAdd, setSerialToAdd] = useState({
    partId: null,
    partNo: null,
    quantity: null,
    description: null,
    price: null,
    itemId: null,
  });
  let [nonSerialToAdd, setNonSerialToAdd] = useState({
    partNo: null,
    quantity: null,
    price: null,
    description: null,
    itemId: null,
  });
  let [selectedSerialPart, setSelectedSerialPart] = useState(null);
  let [creditModal, setCreditModal] = useState(false);
  let [creditData, setCreditData] = useState({
    creditId: null,
    creditDate: null,
    creditAmount: null,
    reason: "",
    note: "",
    itemId: null,
  });
  let [editLineItemModal, setEditLineItemModal] = useState(false);
  let [lineItemData, setLineItemData] = useState({
    description: null,
    itemId: null,
    partId: null,
    partNo: null,
    price: null,
    quantity: null,
    total: null,
  });
  let [credits, setCredits] = useState([]);
  let [creditDrawer, setCreditDrawer] = useState(false);

  const { apId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      loadData();
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  let apStatuses = {
    received: "Received",
    paid: "Paid",
    overdue: "Overdue",
    void: "VOID",
    partiallyPaid: "Partially Paid",
  };

  const updatePaymentDate = (date, dateString) => {
    let tmp = paymentData;
    if (dateString === "") {
      tmp.paidAt = "";
    } else {
      tmp.paidAt = date.toJSON();
    }
    setPaymentData(tmp);
  };

  const updateValue = (e) => {
    let tmp = paymentData;
    let { name, value } = e.target;
    tmp[name] = value;
    setPaymentData(tmp);
  };

  const closeLogPaymentModal = () => {
    setLogPaymentModal(false);
    setCreditPayment(false);
    setCheck(false);
    setPaymentData({
      paidAt: "",
      amount: 0,
      paymentAccount: "",
      comment: "",
      checkNumber: "",
    });
  };

  const renderLogPaymentModal = () => {
    return (
      <Transition.Root show={logPaymentModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeLogPaymentModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="flex flex-col items-start justify-start gap-2 mt-3">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Record an Invoice Payment
                    </Dialog.Title>
                    <div className="w-full mt-2">
                      <div className="w-full">
                        <label htmlFor="datePaymentSubmitted" className="block text-sm font-medium leading-6 text-gray-900">
                          Payment Date
                        </label>
                        <div className="w-full mt-2">
                          <DatePicker placeholder="Select a date" style={{ width: "100%" }} onChange={updatePaymentDate} format={"MM/DD/YYYY"} />
                        </div>
                      </div>
                      <div className="w-full">
                        <label htmlFor="amount" className="block text-sm font-medium leading-6 text-gray-900">
                          Payment Amount
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="text"
                            name="amount"
                            id="amount"
                            className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="0.00"
                            aria-describedby="amount-currency"
                            onChange={updateValue}
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <span className="text-gray-500 sm:text-sm" id="amount-currency">
                              USD
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="w-full">
                        <label htmlFor="paymentAccount" className="block text-sm font-medium leading-6 text-gray-900">
                          Payment Account
                        </label>
                        <select
                          id="paymentAccount"
                          name="paymentAccount"
                          onChange={updateValue}
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        >
                          <option value="" disabled hidden>
                            Select Account
                          </option>
                          {accounts.map((acc) => (
                            <option key={uuidv4()} value={acc.accountId}>
                              {acc.accountNo} | {acc.description}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="w-full mt-2">
                        <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                          Add your comment
                        </label>
                        <div className="mt-2">
                          <textarea
                            rows={4}
                            name="comment"
                            id="comment"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none"
                            defaultValue={""}
                            onChange={updateValue}
                          />
                        </div>
                      </div>
                      <Switch.Group as="div" className="flex items-center justify-between mt-2">
                        <span className="flex flex-col flex-grow">
                          <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                            Credit Applied
                          </Switch.Label>
                          <Switch.Description as="span" className="text-xs text-gray-500">
                            Is this credit that's being applied to this invoice?
                          </Switch.Description>
                        </span>
                        <Switch
                          checked={creditPayment}
                          onChange={setCreditPayment}
                          className={classNames(
                            creditPayment ? "bg-blue-600" : "bg-gray-200",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none",
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              creditPayment ? "translate-x-5" : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                            )}
                          />
                        </Switch>
                      </Switch.Group>
                      <Switch.Group as="div" className="flex items-center justify-between mt-2">
                        <span className="flex flex-col flex-grow">
                          <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                            Generate a check
                          </Switch.Label>
                          <Switch.Description as="span" className="text-xs text-gray-500">
                            Do you want to generate a printable check for this payment?
                          </Switch.Description>
                        </span>
                        <Switch
                          checked={check}
                          onChange={setCheck}
                          className={classNames(
                            check ? "bg-blue-600" : "bg-gray-200",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none",
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              check ? "translate-x-5" : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                            )}
                          />
                        </Switch>
                      </Switch.Group>
                      {check && (
                        <div className="w-full mt-2">
                          <label htmlFor="checkNumber" className="block text-sm font-medium leading-6 text-gray-900">
                            Check Number
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="checkNumber"
                              id="checkNumber"
                              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              placeholder="012345"
                              aria-describedby="check-number"
                              onChange={updateValue}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeLogPaymentModal()} />
                    <PrimaryButton label="Log Payment" callback={() => logPayment()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderGenerateCheckModal = () => {
    return (
      <Transition.Root show={generateCheckModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeGenerateCheck}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="flex flex-col items-start justify-start gap-2 mt-3">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Generate a Check
                    </Dialog.Title>
                    <div className="w-full mt-2">
                      <div className="w-full mt-2">
                        <label htmlFor="checkNumber" className="block text-sm font-medium leading-6 text-gray-900">
                          Check Number
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="checkNumber"
                            id="checkNumber"
                            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="012345"
                            aria-describedby="check-number"
                            onChange={updateValue}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeGenerateCheck()} />
                    <PrimaryButton label="Generate Check" callback={() => generateCheck()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeGenerateCheck = () => {
    setGenerateCheckModal(false);
    setCreditPayment(false);
    setCheck(false);
    setPaymentData({
      paidAt: "",
      amount: 0,
      paymentAccount: "",
      comment: "",
      checkNumber: "",
    });
  };

  const generateCheck = () => {
    let tmp = paymentData;
    if (tmp.checkNumber === "") {
      toast.error("Check number is required");
    } else {
      SubmitAPCheck(apId, tmp.paymentId, { checkNumber: tmp.checkNumber })
        .then((res) => {
          toast.success("Check generated successfully!");
          closeGenerateCheck();
          GetOneAP(apId)
            .then((res) => {
              setAP(res.data.ap);
              setAccounts(res.data.accounts);
              setVendors(res.data.vendors);
              let paymentsMade = 0;
              res.data.ap.payments.forEach((p) => {
                paymentsMade += p.amount;
              });
              let vendor = vendors.find((v) => v.vendorId === res.data.ap.vendorId);
              let vendorAddress =
                vendor.accountsPayable.address && vendor.accountsPayable.address.length > 0
                  ? vendor.accountsPayable.address
                  : vendor.contact.address && vendor.contact.address.length > 0
                    ? vendor.contact.address
                    : "";
              if (vendorAddress.length > 0) {
                vendorAddress +=
                  vendor.accountsPayable.address2 && vendor.accountsPayable.address2.length > 0
                    ? " " + vendor.accountsPayable.address2
                    : vendor.contact.address2 && vendor.contact.address2.length > 0
                      ? " " + vendor.contact.address2
                      : "";
              }
              let vendorCityState =
                vendor.accountsPayable.city && vendor.accountsPayable.city.length > 0
                  ? vendor.accountsPayable.city
                  : vendor.contact.city && vendor.contact.city.length > 0
                    ? vendor.contact.city
                    : "";
              if (vendorCityState.length > 0) {
                vendorCityState +=
                  vendor.accountsPayable.state && vendor.accountsPayable.state.length > 0
                    ? ", " + vendor.accountsPayable.state
                    : vendor.contact.state && vendor.contact.state.length > 0
                      ? ", " + vendor.contact.state
                      : "";
              }
              if (vendorCityState.length > 0) {
                vendorCityState +=
                  vendor.accountsPayable.zip && vendor.accountsPayable.zip.length > 0
                    ? " " + vendor.accountsPayable.zip
                    : vendor.contact.zip && vendor.contact.zip.length > 0
                      ? " " + vendor.contact.zip
                      : "";
              }
              let data = {
                payTo: res.data.ap.vendorName,
                vendorAddress: vendorAddress,
                vendorCityState: vendorCityState,
                date: dayjs(tmp.paidAt).format("MM/DD/YYYY"),
                invoiceNumber: res.data.ap.invoiceNumber,
                invoiceDate: dayjs(res.data.ap.invoiceDate).isValid() ? dayjs(res.data.ap.invoiceDate).format("MM/DD/YYYY") : "",
                dueDate: dayjs(res.data.ap.dueDate).isValid() ? dayjs(res.data.ap.dueDate).format("MM/DD/YYYY") : "",
                subtotal: formatCurrency(res.data.ap.subtotal),
                shipping: formatCurrency(res.data.ap.shipping),
                tax: formatCurrency(res.data.ap.tax),
                invoiceTotal: formatCurrency(res.data.ap.total),
                total: tmp.amount,
                totalString: formatCurrency(tmp.amount),
                paymentsMade: formatCurrency(paymentsMade),
                amountDue: formatCurrency(res.data.ap.total - paymentsMade),
                payments: res.data.ap.payments,
                checkNumber: tmp.checkNumber,
              };
              let doc = GenerateCheck(data);
              doc.setProperties({
                title: `Hi-Tech Power Systems | Check ${tmp.checkNumber}`,
                subject: `Hi-Tech Power Systems | Check ${tmp.checkNumber}`,
                author: "Hypertek Solutions LLC",
                keywords: "",
                creator: "contact@hypertek.dev",
              });
              window.open(doc.output("bloburl"), "_blank");
              loadData();
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Error retrieving AP Invoice.");
              setTimeout(() => navigate("/ap"), 2500);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error generating check.");
          setLoading(false);
        });
    }
  };

  const logPayment = () => {
    let tmp = paymentData;
    tmp.credit = creditPayment;
    tmp.check = check;
    let failed = false;
    if (tmp.amount === 0 || tmp.amount === "") {
      toast.error("Payment amount is required");
      failed = true;
    } else if (tmp.paidAt === "") {
      toast.error("Payment date is required");
      failed = true;
    } else if (!creditPayment && tmp.paymentAccount === "") {
      toast.error("Payment account is required");
      failed = true;
    } else if (check && tmp.checkNumber === "") {
      toast.error("Check number is required");
      failed = true;
    }
    if (!failed) {
      setLoading(true);
      LogAPPayment(apId, tmp)
        .then((res) => {
          toast.success("Payment logged successfully!");
          closeLogPaymentModal();
          GetOneAP(apId)
            .then((res) => {
              setAP(res.data.ap);
              setAccounts(res.data.accounts);
              setVendors(res.data.vendors);
              if (check) {
                let paymentsMade = 0;
                res.data.ap.payments.forEach((p) => {
                  paymentsMade += p.amount;
                });
                let vendor = vendors.find((v) => v.vendorId === res.data.ap.vendorId);
                let vendorAddress =
                  vendor.accountsPayable.address && vendor.accountsPayable.address.length > 0
                    ? vendor.accountsPayable.address
                    : vendor.contact.address && vendor.contact.address.length > 0
                      ? vendor.contact.address
                      : "";
                if (vendorAddress.length > 0) {
                  vendorAddress +=
                    vendor.accountsPayable.address2 && vendor.accountsPayable.address2.length > 0
                      ? " " + vendor.accountsPayable.address2
                      : vendor.contact.address2 && vendor.contact.address2.length > 0
                        ? " " + vendor.contact.address2
                        : "";
                }
                let vendorCityState =
                  vendor.accountsPayable.city && vendor.accountsPayable.city.length > 0
                    ? vendor.accountsPayable.city
                    : vendor.contact.city && vendor.contact.city.length > 0
                      ? vendor.contact.city
                      : "";
                if (vendorCityState.length > 0) {
                  vendorCityState +=
                    vendor.accountsPayable.state && vendor.accountsPayable.state.length > 0
                      ? ", " + vendor.accountsPayable.state
                      : vendor.contact.state && vendor.contact.state.length > 0
                        ? ", " + vendor.contact.state
                        : "";
                }
                if (vendorCityState.length > 0) {
                  vendorCityState +=
                    vendor.accountsPayable.zip && vendor.accountsPayable.zip.length > 0
                      ? " " + vendor.accountsPayable.zip
                      : vendor.contact.zip && vendor.contact.zip.length > 0
                        ? " " + vendor.contact.zip
                        : "";
                }
                let data = {
                  payTo: res.data.ap.vendorName,
                  vendorAddress: vendorAddress,
                  vendorCityState: vendorCityState,
                  date: dayjs(tmp.paidAt).format("MM/DD/YYYY"),
                  invoiceNumber: res.data.ap.invoiceNumber,
                  invoiceDate: dayjs(res.data.ap.invoiceDate).isValid() ? dayjs(res.data.ap.invoiceDate).format("MM/DD/YYYY") : "",
                  dueDate: dayjs(res.data.ap.dueDate).isValid() ? dayjs(res.data.ap.dueDate).format("MM/DD/YYYY") : "",
                  subtotal: formatCurrency(res.data.ap.subtotal),
                  shipping: formatCurrency(res.data.ap.shipping),
                  tax: formatCurrency(res.data.ap.tax),
                  invoiceTotal: formatCurrency(res.data.ap.total),
                  total: tmp.amount,
                  totalString: formatCurrency(tmp.amount),
                  paymentsMade: formatCurrency(paymentsMade),
                  amountDue: formatCurrency(res.data.ap.total - paymentsMade),
                  payments: res.data.ap.payments,
                  checkNumber: tmp.checkNumber,
                };
                let doc = GenerateCheck(data);
                doc.setProperties({
                  title: `Hi-Tech Power Systems | Check ${tmp.checkNumber}`,
                  subject: `Hi-Tech Power Systems | Check ${tmp.checkNumber}`,
                  author: "Hypertek Solutions LLC",
                  keywords: "",
                  creator: "contact@hypertek.dev",
                });
                window.open(doc.output("bloburl"), "_blank");
              }
              loadData();
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Error retrieving AP Invoice.");
              setTimeout(() => navigate("/ap"), 2500);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error logging payment.");
          setLoading(false);
        });
    }
  };

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const renderPayment = (data) => {
    return (
      <div className="flex flex-col items-start justify-start w-full gap-2 px-3 py-2 rounded-md bg-gray-200/50">
        <div className="flex justify-between w-full gap-x-2">
          <div className="flex gap-x-4">
            <div className="flex-auto min-w-0">
              <p className="text-sm font-semibold leading-6 text-gray-900">{data.paidBy}</p>
              <p className="text-xs leading-5 text-gray-500 truncate">{dayjs(data.paidAt).format("MM/DD/YYYY")}</p>
            </div>
          </div>
          <div className="hidden sm:flex sm:flex-col sm:items-end">
            <p className="text-sm leading-6 text-gray-900">{formatCurrency(data.amount)}</p>
            {data.credit && <p className="mt-1 text-xs leading-5 text-gray-500">CREDIT</p>}
          </div>
        </div>
        <p className="text-sm leading-6 text-gray-900">{data.comment}</p>
        <div className="flex items-center justify-between w-full gap-x-2">
          <p className="text-xs font-semibold leading-6 text-gray-500 uppercase">{data.check ? `Check #${data.checkNumber}` : "No checks generated"}</p>
          <button
            className="border border-gray-300 hover:border-blue-300 duration-150 flex flex-row justify-center items-center rounded-md text-xs px-2 py-1 font-medium uppercase mt-0.5 group disabled:hover:border-gray-300 disabled:cursor-not-allowed text-gray-400 hover:text-blue-400 disabled:hover:text-gray-400"
            onClick={() => checkFromPayment(data)}
          >
            {data.check ? "Reprint" : "Generate"}
          </button>
        </div>
      </div>
    );
  };

  const checkFromPayment = (payment) => {
    if (payment.check) {
      let paymentsMade = 0;
      ap.payments.forEach((p) => {
        paymentsMade += p.amount;
      });
      let vendor = vendors.find((v) => v.vendorId === ap.vendorId);
      let vendorAddress =
        vendor.accountsPayable.address && vendor.accountsPayable.address.length > 0
          ? vendor.accountsPayable.address
          : vendor.contact.address && vendor.contact.address.length > 0
            ? vendor.contact.address
            : "";
      if (vendorAddress.length > 0) {
        vendorAddress +=
          vendor.accountsPayable.address2 && vendor.accountsPayable.address2.length > 0
            ? " " + vendor.accountsPayable.address2
            : vendor.contact.address2 && vendor.contact.address2.length > 0
              ? " " + vendor.contact.address2
              : "";
      }
      let vendorCityState =
        vendor.accountsPayable.city && vendor.accountsPayable.city.length > 0
          ? vendor.accountsPayable.city
          : vendor.contact.city && vendor.contact.city.length > 0
            ? vendor.contact.city
            : "";
      if (vendorCityState.length > 0) {
        vendorCityState +=
          vendor.accountsPayable.state && vendor.accountsPayable.state.length > 0
            ? ", " + vendor.accountsPayable.state
            : vendor.contact.state && vendor.contact.state.length > 0
              ? ", " + vendor.contact.state
              : "";
      }
      if (vendorCityState.length > 0) {
        vendorCityState +=
          vendor.accountsPayable.zip && vendor.accountsPayable.zip.length > 0
            ? " " + vendor.accountsPayable.zip
            : vendor.contact.zip && vendor.contact.zip.length > 0
              ? " " + vendor.contact.zip
              : "";
      }
      let data = {
        payTo: ap.vendorName,
        vendorAddress: vendorAddress,
        vendorCityState: vendorCityState,
        date: dayjs(payment.paidAt).format("MM/DD/YYYY"),
        invoiceNumber: ap.invoiceNumber,
        invoiceDate: dayjs(ap.invoiceDate).isValid() ? dayjs(ap.invoiceDate).format("MM/DD/YYYY") : "",
        dueDate: dayjs(ap.dueDate).isValid() ? dayjs(ap.dueDate).format("MM/DD/YYYY") : "",
        subtotal: formatCurrency(ap.subtotal),
        shipping: formatCurrency(ap.shipping),
        tax: formatCurrency(ap.tax),
        invoiceTotal: formatCurrency(ap.total),
        total: payment.amount,
        totalString: formatCurrency(payment.amount),
        paymentsMade: formatCurrency(paymentsMade),
        amountDue: formatCurrency(ap.total - paymentsMade),
        payments: ap.payments,
        checkNumber: payment.checkNumber,
      };
      data.vendorAddress = "4015 Enterprise Ct.";
      data.vendorCityState = "Augusta, GA 30809";
      let doc = GenerateCheck(data);
      doc.setProperties({
        title: `Hi-Tech Power Systems | Check ${payment.checkNumber}`,
        subject: `Hi-Tech Power Systems | Check ${payment.checkNumber}`,
        author: "Hypertek Solutions LLC",
        keywords: "",
        creator: "contact@hypertek.dev",
      });
      window.open(doc.output("bloburl"), "_blank");
    } else {
      let tmp = paymentData;
      tmp.amount = payment.amount;
      tmp.paidAt = payment.paidAt;
      tmp.paymentId = payment.paymentId;
      setPaymentData(tmp);
      setGenerateCheckModal(true);
    }
  };

  const valueChange = (e) => {
    let tmp = ap;
    let { name, value } = e.target;
    tmp[name] = value;
    setAP(tmp);
  };

  const vendorChange = (e) => {
    let tmp = ap;
    tmp.vendorId = e;
    let vendor = vendors.find((v) => v.vendorId === e);
    tmp.vendorName = vendor.vendorName;
    tmp.vendorCode = vendor.vendorCode;
    setAP(tmp);
  };

  const jobChange = (e) => {
    let tmp = ap;
    tmp.jobReference = e;
    setAP(tmp);
  };

  const poChange = (e) => {
    let tmp = ap;
    tmp.poReference = e;
    setAP(tmp);
  };

  const accountChange = (e) => {
    let tmp = ap;
    tmp.account = e;
    setAP(tmp);
  };

  const changeDate = (type, date) => {
    let tmp = ap;
    if (type === "due") {
      tmp.dueDate = date;
    } else {
      tmp.invoiceDate = date;
    }
    setAP(tmp);
  };

  const saveChanges = () => {
    let data = {
      invoiceNumber: ap.invoiceNumber,
      invoiceDate: ap.invoiceDate,
      dueDate: ap.dueDate,
      vendorId: ap.vendorId,
      vendorName: ap.vendorName,
      vendorCode: ap.vendorCode,
      jobReference: ap.jobReference,
      poReference: ap.poReference,
      account: ap.account,
      items: ap.items,
      tax: ap.tax,
      shipping: ap.shipping,
    };
    setLoading(true);
    UpdateAPInvoice(apId, data)
      .then((res) => {
        toast.success("AP Invoice updated successfully!");
        loadData();
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error updating AP Invoice.");
        setLoading(false);
      });
  };

  const getAttachments = () => {
    for (let i = 0; i < ap.attachments.length; i++) {
      window.open(ap.attachments[i], "_blank");
    }
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.ms-excel": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
      "text/csv": [],
    },
    maxFiles: 5,
    onDropAccepted: (acceptedFiles) => {
      let tmp = selectedFiles;
      tmp.push(...acceptedFiles);
      setSelectedFiles(tmp);
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((f) => {
        toast.error(`${f.file.name} was not accepted`);
      });
    },
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "100%",
    padding: "80px 0px",
    margin: "15px 0px",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const removeFile = (file, index) => {
    setLoading(true);
    let tmp = selectedFiles;
    tmp.splice(index, 1);
    setSelectedFiles(tmp);
    setTimeout(() => setLoading(false), 300);
  };

  const submitFiles = async () => {
    if (selectedFiles.length === 0) {
      toast.error("No files selected");
    } else {
      setLoading(true);
      toast("Uploading files...");
      let uploadedFiles = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        let upload = await uploadFile(selectedFiles[i]);
        if (upload) {
          uploadedFiles.push(upload);
        }
      }
      if (selectedFiles.length > 0) {
        toast("Files Uploaded");
      }
      setLoading(false);
      AddAPAttachments(apId, { files: uploadedFiles })
        .then((res) => {
          toast.success("Attachments added successfully!");
          cancelUpload();
          loadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error adding attachments.");
          setLoading(false);
        });
    }
  };

  const uploadFile = async (file) => {
    let formData = new FormData();
    formData.set("file", file, file.name);
    let upload = await UploadAPInvoices(formData);
    if (upload) {
      return upload.data[0].url;
    } else {
      toast.error("Error uploading file " + file.name);
      return null;
    }
  };

  const cancelUpload = () => {
    setAddAttachments(false);
    setTimeout(() => {
      setSelectedFiles([]);
    }, 700);
  };

  const voidInvoice = () => {
    setLoading(true);
    VoidInvoice(apId)
      .then((res) => {
        toast.success("AP Invoice voided successfully!");
        loadData();
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error has occurred while voiding the invoice.");
        setLoading(false);
      });
  };

  const loadData = () => {
    GetOneAP(apId)
      .then((res) => {
        setAP(res.data.ap);
        setAccounts(res.data.accounts);
        setVendors(res.data.vendors);
        setPurchaseOrders(res.data.purchaseOrders);
        setJobs(res.data.jobs);
        GetPartsList()
          .then((res) => {
            setParts(res.data);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Error retrieving AP Invoice.");
            setTimeout(() => navigate("/ap"), 2500);
          });
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error retrieving AP Invoice.");
        setTimeout(() => navigate("/ap"), 2500);
      });
  };

  const editSerialPartChange = (e) => {
    let { name, value } = e.target;
    let tmp = serialToAdd;
    tmp[name] = parseFloat(value);
    setSerialToAdd(tmp);
  };

  const pickPart = (e) => {
    setDummyLoading(true);
    let part = parts.find((p) => p.partId === e);
    setSelectedSerialPart(part);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const submitSerial = () => {
    if (serialToAdd.quantity === null || serialToAdd.quantity === 0) {
      toast.error("Quantity is required");
    } else if (!selectedSerialPart) {
      toast.error("Part is required");
    } else {
      let toSend = {
        price: selectedSerialPart.chargeOut,
        quantity: serialToAdd.quantity,
        total: parseFloat((serialToAdd.quantity * selectedSerialPart.chargeOut).toFixed(2)),
        partNo: selectedSerialPart.partNo,
        partId: selectedSerialPart.partId,
        description: selectedSerialPart.description,
        itemId: uuidv4(),
      };
      setLoading(true);
      AddSerialPartToAPInvoice(apId, toSend)
        .then((res) => {
          toast.success("Serialized Part added successfully!");
          cancelAddSerial();
          loadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error adding part.");
          setLoading(false);
        });
    }
  };

  const cancelAddSerial = () => {
    setAddSerial(false);
    setTimeout(() => {
      setSelectedSerialPart(null);
      setSerialToAdd({
        partId: null,
        partNo: null,
        description: null,
        quantity: null,
        price: null,
        itemId: null,
      });
    }, 700);
  };

  const submitNonSerial = () => {
    if (nonSerialToAdd.quantity === null || nonSerialToAdd.quantity === 0) {
      toast.error("Quantity is required");
    } else if (nonSerialToAdd.partNo === null || nonSerialToAdd.partNo === "" || nonSerialToAdd.description === null || nonSerialToAdd.description === "") {
      toast.error("Part No and Part Description are required");
    } else if (nonSerialToAdd.price === null || nonSerialToAdd.price === 0) {
      toast.error("Price is required");
    } else {
      let toSend = {
        price: nonSerialToAdd.price,
        quantity: nonSerialToAdd.quantity,
        total: parseFloat((nonSerialToAdd.quantity * nonSerialToAdd.price).toFixed(2)),
        partNo: nonSerialToAdd.partNo,
        description: nonSerialToAdd.description,
        itemId: uuidv4(),
      };
      setLoading(true);
      AddNonSerialPartToAPInvoice(apId, toSend)
        .then((res) => {
          toast.success("Non-Serialized Part added successfully!");
          cancelAddNonSerial();
          loadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error adding part.");
          setLoading(false);
        });
    }
  };

  const cancelAddNonSerial = () => {
    setAddNonSerial(false);
    setTimeout(() => {
      setNonSerialToAdd({
        partId: null,
        partNo: null,
        quantity: null,
        price: null,
        itemId: null,
      });
    }, 700);
  };

  const editNonSerialPartChange = (e) => {
    let { name, value } = e.target;
    let tmp = nonSerialToAdd;
    if (name === "quantity" || name === "price") {
      tmp[name] = parseFloat(value);
    } else {
      tmp[name] = value;
    }
    setNonSerialToAdd(tmp);
  };

  const deleteItem = (itemId) => {
    setLoading(true);
    RemoveAPInvoiceItem(apId, itemId)
      .then((res) => {
        toast.success("Item removed successfully!");
        loadData();
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error removing item.");
        setLoading(false);
      });
  };

  const closeCreditModal = () => {
    setCreditModal(false);
    setLoading(true);
    setCreditData({
      creditId: null,
      creditDate: null,
      creditAmount: null,
      reason: "",
      note: "",
      itemId: null,
    });
    setCredits([]);
    setCreditDrawer(false);
    loadData();
  };

  const disabledDate = (current) => {
    return current && current > dayjs();
  };

  const creditModalDisabledFields = () => {
    if (!creditData.itemId) {
      return true;
    } else {
      return false;
    }
  };

  const updateCreditAmount = (v) => {
    let item = ap.items.find((i) => i.itemId === creditData.itemId);
    if (!item) {
      toast.error("Something went wrong, please try again.");
    } else {
      if (v > item.total) {
        toast.error("Credit amount cannot exceed item total.");
      } else {
        toast.remove();
        setCreditData((prev) => ({ ...prev, creditAmount: v }));
      }
    }
  };

  const submitCreditModal = () => {
    if (!creditData.itemId) {
      toast.error("Item for which you wish to issue credit is required");
    } else if (!creditData.creditAmount || creditData.creditAmount === 0) {
      toast.error("Credit amount is required");
    } else if (!creditData.creditDate || !dayjs(creditData.creditDate).isValid()) {
      toast.error("Credit date is required");
    } else {
      if (!creditData.creditId && !validate(creditData.creditId)) {
        ApplyCreditToInvoice(apId, creditData)
          .then((res) => {
            toast.success("Credit applied successfully!");
            closeCreditModal();
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Error applying credit.");
          });
      } else {
        UpdateAPCredit(apId, creditData.creditId, creditData)
          .then((res) => {
            toast.success(res.data.message);
            closeCreditModal();
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Error updating credit.");
          });
      }
    }
  };

  const calculateItemTotal = (record) => {
    let itemId = record.itemId;
    let item = ap.items.find((i) => i.itemId === itemId);
    if (item) {
      let total = item.total || 0;
      let credits = ap.credits.reduce((acc, c) => (c.itemId === itemId ? acc + c.creditAmount : acc), 0);
      return parseFloat((total - credits).toFixed(2));
    } else {
      return 0;
    }
  };

  const editLineItem = (item) => {
    setLineItemData(item);
    if (item.partId && validate(item.partId)) {
      let part = parts.find((p) => p.partId === item.partId);
      setSelectedSerialPart(part);
    }
    setEditLineItemModal(true);
  };

  const closeEditLineItem = () => {
    setEditLineItemModal(false);
    setLoading(true);
    setSelectedSerialPart(null);
    setLineItemData({
      itemId: null,
      partNo: null,
      description: null,
      quantity: null,
      price: null,
      total: null,
    });
    loadData();
  };

  const submitEditLineItem = () => {
    let failed = false;
    if (lineItemData.partId && validate(lineItemData.partId)) {
      if (lineItemData.quantity === null || lineItemData.quantity === 0) {
        toast.error("Quantity is required");
        failed = true;
      } else if (!selectedSerialPart) {
        toast.error("Part is required");
        failed = true;
      }
    } else {
      if (lineItemData.quantity === null || lineItemData.quantity === 0) {
        toast.error("Quantity is required");
        failed = true;
      } else if (lineItemData.partNo === null || lineItemData.partNo === "" || lineItemData.description === null || lineItemData.description === "") {
        toast.error("Part No and Part Description are required");
        failed = true;
      } else if (lineItemData.price === null || lineItemData.price === 0) {
        toast.error("Price is required");
        failed = true;
      }
    }
    if (!failed) {
      UpdateAPLineItem(apId, lineItemData.itemId, lineItemData)
        .then((res) => {
          toast.success(res.data.message);
          closeEditLineItem();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error updating line item.");
        });
    }
  };

  const calculateTotal = () => {
    let total = 0;
    total += ap.items.reduce((acc, item) => acc + item.total, 0);
    total += ap.shipping;
    total += ap.tax;
    total -= ap.credits.reduce((acc, credit) => acc + credit.creditAmount, 0);
    return total;
  };

  const viewCredits = (itemId) => {
    let credits = ap.credits.filter((c) => c.itemId === itemId);
    if (credits.length === 0) {
      toast.error("No credits found for this item");
    } else {
      setCredits(credits);
      setCreditDrawer(true);
    }
  };

  const openOneCredit = (credit) => {
    setCreditDrawer(false);
    setCreditData(credit);
    setCreditModal(true);
    setTimeout(() => setCredits([]), 700);
  };

  const closeCreditDrawer = () => {
    setCreditDrawer(false);
    setCredits([]);
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center justify-start w-full h-full px-1 mt-1">
            <Helmet>
              <title>AP Invoice - {ap.invoiceNumber} | HTPS ERP</title>
            </Helmet>
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <div className="flex items-center justify-start gap-2">
                <p className="text-xl font-bold uppercase">
                  <span className="text-sm font-semibold">AP Invoice #</span> {ap.invoiceNumber}
                </p>
                {ap.poReference && validate(ap.poReference) && (
                  <button
                    className="px-4 py-1 duration-150 border border-gray-300 rounded-md hover:bg-gray-300 hover:border-black"
                    onClick={() => window.open(`/po/${ap.poReference}`, "_blank")}
                  >
                    <div className="flex flex-row items-center justify-center gap-2">
                      <p className="text-xs font-medium uppercase">View PO</p>
                      <OpenNewWindow className="w-3 h-3" />
                    </div>
                  </button>
                )}
                {ap.jobReference && validate(ap.jobReference) && (
                  <button
                    className="px-4 py-1 duration-150 border border-gray-300 rounded-md hover:bg-gray-300 hover:border-black"
                    onClick={() => window.open(`/jobs/${ap.jobReference}`, "_blank")}
                  >
                    <div className="flex flex-row items-center justify-center gap-2">
                      <p className="text-xs font-medium uppercase">View Job</p>
                      <OpenNewWindow className="w-3 h-3" />
                    </div>
                  </button>
                )}
              </div>
              <div className="flex flex-row items-center justify-end gap-1">
                <p className="uppercase text-xs font-medium pt-0.5">Status:</p>
                <p className="font-semibold uppercase">{apStatuses[ap.invoiceStatus]}</p>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-2">
              <div className="w-full">
                <label htmlFor="invoiceNumber" className="block text-sm font-medium leading-6 text-gray-900">
                  Invoice Number
                </label>
                <div className="mt-2">
                  <input
                    type="invoiceNumber"
                    name="invoiceNumber"
                    id="invoiceNumber"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    placeholder="Vendor Invoice Number"
                    defaultValue={ap.invoiceNumber}
                    onChange={valueChange}
                    disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void"}
                  />
                </div>
              </div>
              <div className="w-full">
                <label htmlFor="invoiceDate" className="block text-sm font-medium leading-6 text-gray-900">
                  Invoice Date
                </label>
                <div className="mt-2">
                  <DatePicker
                    defaultValue={dayjs(ap.invoiceDate)}
                    style={{ width: "100%" }}
                    format={"MM/DD/YYYY"}
                    onChange={(date, dateString) => changeDate("invoice", date.toJSON())}
                    disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void"}
                  />
                </div>
              </div>
              <div className="w-full">
                <label htmlFor="dueDate" className="block text-sm font-medium leading-6 text-gray-900">
                  Due Date
                </label>
                <div className="mt-2">
                  <DatePicker
                    defaultValue={dayjs(ap.dueDate)}
                    format={"MM/DD/YYYY"}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => changeDate("due", date.toJSON())}
                    disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void"}
                  />
                </div>
              </div>
              <div className="w-full">
                <label htmlFor="vendorId" className="block text-sm font-medium leading-6 text-gray-900">
                  Vendor
                </label>
                <Select
                  placeholder={"Select a vendor"}
                  onChange={vendorChange}
                  options={vendors.map((c) => ({
                    value: c.vendorId,
                    label: `${c.vendorCode.toUpperCase()}${c.vendorName.length > 0 ? " | " + c.vendorName : ""}`,
                  }))}
                  className="w-full font-sans"
                  controls={false}
                  showSearch
                  filterOption={filterOption}
                  defaultValue={ap.vendorId}
                  disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void"}
                />
              </div>
              <div className="w-full">
                <label htmlFor="jobReference" className="block text-sm font-medium leading-6 text-gray-900">
                  Job
                </label>
                <Select
                  placeholder={"Select a job"}
                  onChange={jobChange}
                  options={jobs.map((c) => ({
                    value: c.jobId,
                    label: c.jobNo.toUpperCase(),
                  }))}
                  className="w-full font-sans"
                  controls={false}
                  showSearch
                  filterOption={filterOption}
                  defaultValue={ap.jobReference}
                  disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void"}
                />
              </div>
              <div className="w-full">
                <label htmlFor="poReference" className="block text-sm font-medium leading-6 text-gray-900">
                  Purchase Order
                </label>
                <Select
                  placeholder={"Select a Purchase Order"}
                  onChange={poChange}
                  options={purchaseOrders.map((c) => ({
                    value: c.poId,
                    label: c.poNumber.toUpperCase(),
                  }))}
                  className="w-full font-sans"
                  controls={false}
                  showSearch
                  filterOption={filterOption}
                  defaultValue={ap.poReference}
                  disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void"}
                />
              </div>
              <div className="w-full">
                <label htmlFor="account" className="block text-sm font-medium leading-6 text-gray-900">
                  Account
                </label>
                <Select
                  placeholder={"Select an Account this invoice is to be paid from"}
                  onChange={accountChange}
                  options={accounts.map((c) => ({
                    value: c.accountId,
                    label: c.accountNo.toUpperCase() + " " + c.description,
                  }))}
                  className="w-full font-sans"
                  controls={false}
                  showSearch
                  filterOption={filterOption}
                  defaultValue={ap.account}
                  disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void"}
                />
              </div>
              <div className="w-full">
                <label htmlFor="tax" className="block text-sm font-medium leading-6 text-gray-900">
                  Tax
                </label>
                <InputNumber
                  placeholder="Enter the sales tax amount charged for this invoice"
                  onChange={(v) => setAP((prev) => ({ ...prev, tax: v }))}
                  defaultValue={ap.tax ? ap.tax : null}
                  className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                  controls={false}
                  disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void"}
                  addonBefore="$"
                  min={0}
                  step={0.01}
                  addonAfter="USD"
                />
              </div>
              <div className="w-full">
                <label htmlFor="shipping" className="block text-sm font-medium leading-6 text-gray-900">
                  Shipping / Freight
                </label>
                <InputNumber
                  placeholder="Enter the charged for shipping/freight"
                  onChange={(v) => setAP((prev) => ({ ...prev, shipping: v }))}
                  defaultValue={ap.shipping ? ap.shipping : null}
                  className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                  controls={false}
                  disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void"}
                  addonBefore="$"
                  min={0}
                  step={0.01}
                  addonAfter="USD"
                />
              </div>
              <div className="w-full pt-3 mt-2 border-t border-gray-300">
                <div className="flex items-center justify-between w-full py-2">
                  <h3 className="text-sm font-semibold shrink-0">INVOICE ITEMS:</h3>
                  <div className="flex items-center justify-end w-full gap-2">
                    <SecondaryButton label="Add non serial part" callback={() => setAddNonSerial(true)} />
                    <SecondaryButton label="Add serial part" callback={() => setAddSerial(true)} />
                  </div>
                </div>
                <Table className="w-full py-2" dataSource={ap.items} rowKey="itemId" pagination={false} bordered size="small">
                  <Table.Column title="Part No" dataIndex="partNo" key="partNo" width={120} />
                  <Table.Column title="Description" dataIndex="description" key="description" />
                  <Table.Column title="Price" dataIndex="price" key="price" render={(v) => formatCurrency(v)} align="right" width={120} />
                  <Table.Column title="Quantity" dataIndex="quantity" key="quantity" align="right" width={100} />
                  <Table.Column
                    title="Credits"
                    dataIndex="itemId"
                    key="itemIdCredits"
                    width={120}
                    align="right"
                    render={(v) => formatCurrency(ap.credits.filter((c) => c.itemId === v).reduce((acc, cred) => acc - cred.creditAmount, 0))}
                  />
                  <Table.Column
                    title="Total"
                    dataIndex="total"
                    key="total"
                    render={(v, r) => formatCurrency(calculateItemTotal(r))}
                    align="right"
                    width={120}
                  />
                  <Table.Column
                    key="itemId"
                    dataIndex="itemId"
                    width={130}
                    render={(v, r) => {
                      return (
                        ap.invoiceStatus !== "paid" &&
                        ap.invoiceStatus !== "void" && (
                          <td className="flex items-center justify-end gap-2 py-2 text-sm text-right text-gray-500 whitespace-nowrap">
                            {ap.credits.some((c) => c.itemId === v) && (
                              <IconButton icon={<Receipt size={16} strokeWidth={1.5} />} callback={() => viewCredits(v)} className="px-2" />
                            )}
                            <IconButton icon={<Edit size={16} strokeWidth={1.5} />} callback={() => editLineItem(r)} className="px-2" />
                            <IconButton
                              icon={<Trash2 size={16} strokeWidth={1.5} />}
                              className="px-2 text-red-500 ring-red-400 hover:bg-red-500 hover:text-white hover:ring-red-500"
                              callback={() => deleteItem(v)}
                            />
                          </td>
                        )
                      );
                    }}
                  />
                </Table>
              </div>
              <div className="grid items-start w-full grid-cols-2 gap-5 pt-3 mt-2 border-t border-gray-300">
                <div className="flex flex-col items-start justify-start w-full gap-2">
                  <div className="flex flex-row items-center justify-start w-full gap-2">
                    <h3 className="text-sm font-semibold uppercase pt-0.5">Recorded Payments</h3>
                    <button
                      className="border border-gray-300 hover:border-blue-300 duration-150 flex flex-row justify-center items-center rounded-md h-7 w-7 mt-0.5 group disabled:hover:border-gray-300 disabled:cursor-not-allowed text-gray-400 hover:text-blue-400 disabled:hover:text-gray-400"
                      onClick={() => setLogPaymentModal(true)}
                      disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void"}
                    >
                      <Plus className="w-5 h-5" />
                    </button>
                  </div>
                  <div className="flex flex-col items-start justify-start w-full h-full gap-2 mt-2 overflow-y-auto">
                    {ap.payments.length > 0 ? ap.payments.map((p) => renderPayment(p)) : <p className="text-sm font-semibold">No payments recorded.</p>}
                  </div>
                </div>
                <div className="flex flex-col items-end justify-start w-full gap-2 pt-6">
                  <p className="text-sm font-semibold uppercase">Subtotal: {formatCurrency(ap.subtotal)}</p>
                  <p className="text-sm font-semibold uppercase">Freight: {formatCurrency(ap.shipping)}</p>
                  <p className="text-sm font-semibold uppercase">Tax: {formatCurrency(ap.tax)}</p>
                  <p className="text-sm font-semibold uppercase">Credits: {formatCurrency(ap.credits.reduce((acc, cred) => acc - cred.creditAmount, 0))}</p>
                  <p className="text-sm font-semibold uppercase">Total: {formatCurrency(calculateTotal())}</p>
                  {ap.payments.length > 0 && (
                    <div className="flex flex-col items-end justify-start w-full pt-2 border-t border-gray-200">
                      <p className="text-sm font-semibold uppercase">Payments Made: {formatCurrency(ap.payments.reduce((a, b) => a + b.amount, 0))}</p>
                      <p className="text-sm font-semibold uppercase">
                        Remaining Balance: {formatCurrency(ap.total - ap.payments.reduce((a, b) => a + b.amount, 0))}
                      </p>
                    </div>
                  )}
                  <div className="flex flex-row items-center justify-end w-full gap-2 pt-4 mt-2 border-t border-gray-300">
                    <SecondaryButton
                      label="Apply Credits"
                      callback={() => setCreditModal(true)}
                      disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void"}
                    />
                    {ap.invoiceStatus !== "paid" && ap.invoiceStatus !== "void" && (
                      <SecondaryButton label="Add Attachments" callback={() => setAddAttachments(true)} />
                    )}
                    {ap.invoiceStatus !== "paid" && ap.invoiceStatus !== "void" && <SecondaryButton label="VOID Invoice" callback={() => voidInvoice()} />}
                    {ap.attachments.length > 0 && (
                      <SecondaryButton label={`Download Attachments [${ap.attachments.length}]`} callback={() => getAttachments()} />
                    )}
                    <PrimaryButton label="Save Changes" callback={() => saveChanges()} disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {renderLogPaymentModal()}
          {renderGenerateCheckModal()}
          <Modal open={addAttachments} destroyOnClose centered width={600} onOk={() => submitFiles()} onCancel={() => cancelUpload()}>
            <div className="flex flex-col items-center justify-center w-full mt-2">
              <label className="block w-full text-sm font-medium leading-6 text-gray-900">Attach a copy of the AP Invoice</label>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p className="text-sm text-gray-600">Click to upload or drag and drop files here</p>
                <p className="text-xs text-gray-400">Accepts PDF, DOCX, CSV, and Excel Files - Maximum of 5</p>
              </div>
              <div className="flex flex-col items-center justify-start w-full gap-2 mt-5">
                {selectedFiles.length > 0 ? (
                  selectedFiles.map((selectedFile, i) => {
                    return (
                      <div className="flex flex-row items-center justify-start w-full gap-2 p-2 text-xs font-medium text-gray-600 border border-gray-200 rounded-lg">
                        <Page className="w-5 h-5 text-gray-500" />
                        <p className="flex-grow">{selectedFile.name ? selectedFile.name : "Unknown File"}</p>
                        <Xmark
                          className="w-5 h-5 text-gray-500 transition-all duration-150 cursor-pointer hover:text-red-500"
                          onClick={() => removeFile(selectedFile, i)}
                        />
                      </div>
                    );
                  })
                ) : (
                  <p className="text-sm text-gray-500">No files added</p>
                )}
              </div>
            </div>
          </Modal>
          <Modal
            title="Add Serialized Part"
            open={addSerial}
            destroyOnClose
            centered
            width={600}
            onOk={() => submitSerial()}
            onCancel={() => cancelAddSerial()}
          >
            <div className="w-full mt-2">
              <label htmlFor="part" className="block text-sm font-medium leading-6 text-gray-900">
                Part
              </label>
              <Select
                placeholder={"Select a part"}
                onChange={(e) => pickPart(e)}
                options={parts.map((c) => ({
                  value: c.partId,
                  label: `${c.partNo} | ${c.description && c.description.length > 20 ? c.description.slice(0, 20) + "..." : c.description}`,
                }))}
                className="w-full font-sans"
                controls={false}
                showSearch
                filterOption={filterOption}
              />
            </div>
            <div className="w-full mt-3">
              <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                Price
              </label>
              <div className="relative mt-0.5 rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  name="price"
                  id="price"
                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  placeholder="0.00"
                  aria-describedby="price-per-part"
                  defaultValue={selectedSerialPart ? selectedSerialPart.chargeOut : null}
                  disabled={selectedSerialPart ? false : true}
                  step="0.01"
                  onChange={(e) => editSerialPartChange(e)}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <span className="text-gray-500 sm:text-sm" id="price-per-part">
                    USD
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full mt-2">
              <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-900">
                Quantity
              </label>
              <div className="mt-0.5">
                <input
                  type="number"
                  name="quantity"
                  id="quantity"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  placeholder="Part Quantity"
                  onChange={(e) => editSerialPartChange(e)}
                />
              </div>
            </div>
          </Modal>
          <Modal
            title="Add Non-Serialized Part"
            open={addNonSerial}
            destroyOnClose
            centered
            width={600}
            onOk={() => submitNonSerial()}
            onCancel={() => cancelAddNonSerial()}
          >
            <div key="partNo" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
                Part No.
              </label>
              <input
                className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                type="text"
                id="partNo"
                name="partNo"
                onChange={(e) => editNonSerialPartChange(e)}
              />
            </div>
            <div key="description" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                Description
              </label>
              <input
                className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed"
                type="text"
                id="description"
                name="description"
                onChange={(e) => editNonSerialPartChange(e)}
              />
            </div>
            <div className="w-full">
              <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                Price
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  name="price"
                  id="price"
                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  placeholder="0.00"
                  aria-describedby="price-per-part"
                  step="0.01"
                  onChange={(e) => editNonSerialPartChange(e)}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <span className="text-gray-500 sm:text-sm" id="price-per-part">
                    USD
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-900">
                Quantity
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="quantity"
                  id="quantity"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  placeholder="Part Quantity"
                  onChange={(e) => editNonSerialPartChange(e)}
                />
              </div>
            </div>
          </Modal>
          <Modal
            open={creditModal}
            destroyOnClose
            centered
            width={600}
            onOk={() => submitCreditModal()}
            onCancel={() => closeCreditModal()}
            onClose={() => closeCreditModal()}
            title={!creditData.creditId ? "Apply credit to invoice" : "Edit invoice credit"}
            okText={!creditData.creditId ? "Apply credit" : "Update credit"}
          >
            <div className="flex flex-col items-start justify-start w-full gap-4 py-2 pb-4 mb-2 border-b border-gray-200">
              <div className="flex flex-col items-start justify-start w-full gap-1">
                <label htmlFor="itemId" className="block text-xs font-medium leading-6 text-gray-900 uppercase">
                  Item to apply credit to:
                </label>
                <Select
                  placeholder="Select the item to apply the credit to"
                  onChange={(v) => setCreditData((prev) => ({ ...prev, itemId: v }))}
                  onSelect={(v) => setCreditData((prev) => ({ ...prev, itemId: v }))}
                  options={(ap.items || []).map((i) => {
                    return {
                      value: i.itemId,
                      label: `${i.partNo} | ${i.description}`,
                    };
                  })}
                  defaultValue={creditData.itemId ? creditData.itemId : null}
                  className="w-full font-sans"
                  controls={false}
                  showSearch
                  filterOption={filterOption}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full gap-1">
                <label htmlFor="creditDate" className="block text-xs font-medium leading-6 text-gray-900 uppercase">
                  Date credit was issued:
                </label>
                <DatePicker
                  defaultValue={creditData.creditDate ? dayjs(creditData.creditDate) : null}
                  style={{ width: "100%" }}
                  format={"MM/DD/YYYY"}
                  onChange={(date, dateString) => setCreditData((prev) => ({ ...prev, creditDate: dayjs(date).toJSON() }))}
                  disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void" || creditModalDisabledFields()}
                  disabledDate={disabledDate}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full gap-1">
                <label htmlFor="creditAmount" className="block text-xs font-medium leading-6 text-gray-900 uppercase">
                  Credit amount:
                </label>
                <InputNumber
                  placeholder="Enter the amount to credit"
                  onChange={(v) => updateCreditAmount(v)}
                  defaultValue={creditData.creditAmount ? creditData.creditAmount : null}
                  className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                  controls={false}
                  disabled={ap.invoiceStatus === "paid" || ap.invoiceStatus === "void" || creditModalDisabledFields()}
                  addonBefore="$"
                  min={0}
                  step={0.01}
                  addonAfter="USD"
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full gap-1">
                <label htmlFor="reason" className="block text-xs font-medium leading-6 text-gray-900 uppercase">
                  Credit reason:
                </label>
                <Input.TextArea
                  placeholder="Enter the reason for which the credit was issued"
                  onChange={(e) => {
                    setCreditData((prev) => ({ ...prev, reason: e.target.value }));
                  }}
                  rows={4}
                  defaultValue={creditData.reason ? creditData.reason : null}
                  autoSize={false}
                  className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full gap-1">
                <label htmlFor="note" className="block text-xs font-medium leading-6 text-gray-900 uppercase">
                  Notes:
                </label>
                <Input.TextArea
                  placeholder="Enter any additional notes"
                  onChange={(e) => {
                    setCreditData((prev) => ({ ...prev, note: e.target.value }));
                  }}
                  rows={4}
                  defaultValue={creditData.note ? creditData.note : null}
                  autoSize={false}
                  className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                />
              </div>
            </div>
          </Modal>
          <Modal
            open={editLineItemModal}
            destroyOnClose
            centered
            width={720}
            onOk={() => submitEditLineItem()}
            onCancel={() => closeEditLineItem()}
            title="Edit line item"
          >
            <div className="flex flex-col items-start justify-start w-full gap-4 py-2 pb-4 mb-2 border-b border-gray-200">
              {lineItemData.partId && validate(lineItemData.partId) ? (
                <div className="flex flex-col items-start justify-start w-full gap-1">
                  <label htmlFor="part" className="block text-xs font-medium leading-6 text-gray-900 uppercase">
                    Part:
                  </label>
                  <Select
                    placeholder={"Select a part"}
                    onChange={(e) => {
                      let part = parts.find((p) => p.partId === e);
                      if (!part) {
                        toast.error("Part not found, please try again");
                      } else {
                        setLineItemData((prev) => ({ ...prev, partId: e, partNo: part.partNo, description: part.description, price: part.chargeOut }));
                        setSelectedSerialPart(part);
                        pickPart(e);
                      }
                    }}
                    defaultValue={lineItemData.partId ? lineItemData.partId : null}
                    options={parts.map((c) => ({
                      value: c.partId,
                      label: `${c.partNo} | ${c.description && c.description.length > 20 ? c.description.slice(0, 20) + "..." : c.description}`,
                    }))}
                    className="w-full font-sans"
                    controls={false}
                    showSearch
                    filterOption={filterOption}
                  />
                </div>
              ) : (
                <>
                  <div className="flex flex-col items-start justify-start w-full gap-1">
                    <label htmlFor="partNo" className="block text-xs font-medium leading-6 text-gray-900 uppercase">
                      Part No:
                    </label>
                    <Input
                      placeholder="Enter the part number"
                      onChange={(e) => setLineItemData((prev) => ({ ...prev, partNo: e.target.value }))}
                      value={lineItemData.partNo ? lineItemData.partNo : null}
                      className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full gap-1">
                    <label htmlFor="description" className="block text-xs font-medium leading-6 text-gray-900 uppercase">
                      Part Description:
                    </label>
                    <Input
                      placeholder="Provide a description for the part"
                      onChange={(e) => setLineItemData((prev) => ({ ...prev, descirption: e.target.value }))}
                      value={lineItemData.description ? lineItemData.description : null}
                      className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                    />
                  </div>
                </>
              )}
              <div className="flex flex-col items-start justify-start w-full gap-1">
                <label htmlFor="price" className="block text-xs font-medium leading-6 text-gray-900 uppercase">
                  Price:
                </label>
                <InputNumber
                  placeholder="Enter the item price"
                  onChange={(v) => setLineItemData((prev) => ({ ...prev, price: v }))}
                  value={lineItemData.price ? lineItemData.price : null}
                  className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                  controls={false}
                  addonBefore="$"
                  min={0}
                  step={0.01}
                  addonAfter="USD"
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full gap-1">
                <label htmlFor="quantity" className="block text-xs font-medium leading-6 text-gray-900 uppercase">
                  Quantity:
                </label>
                <InputNumber
                  placeholder="Enter the quantity"
                  onChange={(v) => setLineItemData((prev) => ({ ...prev, quantity: v }))}
                  value={lineItemData.quantity ? lineItemData.quantity : null}
                  className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                  controls={false}
                  min={0}
                />
              </div>
            </div>
          </Modal>
          <Drawer open={creditDrawer} destroyOnClose centered width={450} onClose={() => closeCreditDrawer()} title="Credits">
            <div className="flex flex-col items-center justify-start w-full h-full gap-4 p-1">
              {credits.map((credit) => {
                return (
                  <div className="flex flex-col items-start justify-start w-full gap-1 p-5 border border-gray-200 rounded-md">
                    <div className="flex items-center justify-between w-full gap-5">
                      <p className="text-sm font-semibold uppercase truncate">Credit Date:</p>
                      <p className="text-sm font-medium truncate">{dayjs(credit.creditDate).format("MM/DD/YYYY")}</p>
                    </div>
                    <div className="flex items-center justify-between w-full gap-5">
                      <p className="text-sm font-semibold uppercase truncate">Credit Amount:</p>
                      <p className="text-sm font-medium truncate">{formatCurrency(credit.creditAmount)}</p>
                    </div>
                    {credit.reason && credit.reason.length > 0 && (
                      <div className="flex items-center justify-between w-full gap-5">
                        <p className="text-sm font-semibold uppercase truncate">Reason:</p>
                        <p className="text-sm font-medium truncate">{credit.reason}</p>
                      </div>
                    )}
                    {credit.note && credit.note.length > 0 && (
                      <div className="flex items-center justify-between w-full gap-5">
                        <p className="text-sm font-semibold uppercase truncate">Notes:</p>
                        <p className="text-sm font-medium truncate">{credit.note}</p>
                      </div>
                    )}
                    <div className="flex items-center justify-between w-full gap-5">
                      <p className="text-sm font-semibold uppercase truncate">Applied By:</p>
                      <p className="text-sm font-medium truncate">{credit.issuedBy}</p>
                    </div>
                    <div className="flex items-center justify-between w-full gap-5">
                      <p className="text-sm font-semibold uppercase truncate">Applied To:</p>
                      <p className="text-sm font-medium truncate">
                        {ap.items.find((i) => i.itemId === credit.itemId).partNo}-{ap.items.find((i) => i.itemId === credit.itemId).description}
                      </p>
                    </div>
                    <div className="flex items-center justify-end w-full mt-2">
                      <SecondaryButton label="Edit Credit" callback={() => openOneCredit(credit)} />
                    </div>
                  </div>
                );
              })}
            </div>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default OpenAP;
