import { useEffect, useState, Fragment } from "react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { CreateAJob, PreJobCheck } from "../../actions/quotes";
import { PasteClipboard } from "iconoir-react";
import { formatCurrency } from "../../components/tools";
import dayjs from "dayjs";
import _, { forEach, set } from "lodash";
import { Transition, Dialog } from "@headlessui/react";
import { Combobox } from "@headlessui/react";
import { Check, ArrowSeparateVertical, WarningCircle } from "iconoir-react";
import { Helmet } from "react-helmet-async";

const JobFromQuote = () => {
  const [loading, setLoading] = useState(true);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [inventoryTransmittals, setInventoryTransmittals] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [locations, setLocations] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [miscParts, setMiscParts] = useState([]);
  const [quote, setQuote] = useState({});
  const [dummyLoading, setDummyLoading] = useState(false);
  const [poData, setPOData] = useState({
    poId: "",
    vendorId: "",
    vendorName: "",
    vendorCode: "",
    locationId: "",
    poAccount: "",
    items: [],
  });
  const [editPOModal, setEditPOModal] = useState(false);
  const [editMiscPOModal, setEditMiscPOModal] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedVendor, setSelectedVendor] = useState(null);

  const [accQuery, setAccQuery] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);

  const { quoteId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      PreJobCheck(quoteId)
        .then((res) => {
          setQuote(res.data.quote);
          setPurchaseOrders(res.data.purchaseOrders);
          setInventoryTransmittals(res.data.inventoryTransmittals);
          setVendors(res.data.vendors);
          setLocations(res.data.locations);
          setAccounts(res.data.accounts);
          setMiscParts(res.data.miscParts);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "An unexpected error occurred. Please try again later.");
          setTimeout(() => {
            navigate(`/quotes/${quoteId}`);
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderContent = () => {
    if (inventoryTransmittals.length === 0 && purchaseOrders.length === 0 && miscParts.length === 0) {
      return (
        <>
          <p>Create a job for quote #{quote.quoteNo}</p>
        </>
      );
    } else {
      return (
        <>
          <div className="flex flex-col items-center justify-center w-full pb-5 mb-5 border-b border-gray-500">
            <p className="text-sm leading-6 text-gray-500">
              Some of the parts from this quote are currently unavailable at the {locations.find((l) => l.locationId === quote.locationId).locationName}{" "}
              location.
            </p>
            <p className="text-sm leading-6 text-gray-500">
              In order to proceed, please verify the information for the following items, and fill out missing in formation if needed.
            </p>
          </div>
        </>
      );
    }
  };

  let updateTransmit = (transmittalId) => {
    setDummyLoading(true);
    let tmp = inventoryTransmittals;
    let toAdd = [];
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].transmittalId === transmittalId) {
        tmp[i].skip = !tmp[i].skip;
      }
      toAdd.push(tmp[i]);
    }
    setInventoryTransmittals(toAdd);
    setTimeout(() => setDummyLoading(false), 700);
  };

  let excludePO = (poId) => {
    setDummyLoading(true);
    let tmp = purchaseOrders;
    let toAdd = [];
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].poId === poId) {
        tmp[i].skip = !tmp[i].skip;
      }
      toAdd.push(tmp[i]);
    }
    setPurchaseOrders(toAdd);
    setTimeout(() => setDummyLoading(false), 700);
  };

  let excludeMiscPO = (poId) => {
    setDummyLoading(true);
    let tmp = miscParts;
    let toAdd = [];
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].poId === poId) {
        tmp[i].skip = !tmp[i].skip;
      }
      toAdd.push(tmp[i]);
    }
    setMiscParts(toAdd);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const createJob = () => {
    let failed = false;
    if (miscParts.length !== 0) {
      for (let i = 0; i < miscParts.length; i++) {
        if (miscParts[i].skip == false) {
          if (miscParts[i].vendorId === "") {
            toast.error(`Please select a vendor for the Pre-Purchase Order #${i + 1 + purchaseOrders.length}, or remove it the quote.`);
            failed = true;
          }
          if (miscParts[i].poAccount === "") {
            toast.error(`Please select a payment account for the Pre-Purchase Order #${i + 1 + purchaseOrders.length}, or remove it the quote.`);
            failed = true;
          }
        }
      }
    }
    if (!failed) {
      setLoading(true);
      let its = [];
      let pos = [];
      for (let i = 0; i < inventoryTransmittals.length; i++) {
        if (!inventoryTransmittals[i].skip) {
          its.push(inventoryTransmittals[i]);
        }
      }
      for (let i = 0; i < purchaseOrders.length; i++) {
        if (!purchaseOrders[i].skip) {
          pos.push(purchaseOrders[i]);
        }
      }
      for (let i = 0; i < miscParts.length; i++) {
        if (!miscParts[i].skip) {
          pos.push(miscParts[i]);
        }
      }
      let payload = {
        inventoryTransmittals: its,
        purchaseOrders: pos,
      };
      CreateAJob(quoteId, payload)
        .then((res) => {
          toast.success("Job created successfully! You will be redirected shortly!");
          setTimeout(() => {
            navigate(`/jobs/${res.data.jobId}`);
          }, 3000);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "An unexpected error occurred. Please try again later.");
          setTimeout(() => {
            setLoading(false);
          }, 700);
        });
    }
  };

  const renderPOTotal = (po) => {
    let total = 0;
    forEach(po.items, (item) => {
      total += item.total;
    });
    return total;
  };

  const filteredVendors =
    query === ""
      ? vendors
      : vendors.filter((vendor) => {
          return vendor.vendorName.toLowerCase().includes(query.toLowerCase()) || vendor.vendorCode.toLowerCase().includes(query.toLowerCase());
        });

  const filteredAccounts =
    accQuery === ""
      ? accounts
      : accounts.filter((acc) => {
          return acc.accountNo.toLowerCase().includes(accQuery.toLowerCase()) || acc.description.toLowerCase().includes(accQuery.toLowerCase());
        });

  const renderEditPOModal = () => {
    return (
      <Transition.Root show={editPOModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeEditPOModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:min-h-[550px] sm:p-6">
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
                    Edit Pre-Purchase Order
                  </Dialog.Title>
                  <div className="flex flex-col items-start justify-start w-full gap-2 mt-2">
                    <Combobox as="div" value={selectedVendor} onChange={setSelectedVendor} className="w-full">
                      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">Vendor</Combobox.Label>
                      <div className="relative mt-2">
                        <Combobox.Input
                          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          onChange={(event) => setQuery(event.target.value)}
                          placeholder="Select a vendor"
                          displayValue={(vendor) => {
                            return vendor !== null ? vendor?.vendorCode + " | " + vendor?.vendorName : null;
                          }}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                          <ArrowSeparateVertical className="w-5 h-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>

                        {filteredVendors.length > 0 && (
                          <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredVendors.map((vendor) => (
                              <Combobox.Option
                                key={vendor.vendorId}
                                value={vendor}
                                className={({ active }) =>
                                  classNames("relative cursor-default select-none py-2 pl-3 pr-9", active ? "bg-blue-600 text-white" : "text-gray-900")
                                }
                              >
                                {({ active, selected }) => (
                                  <>
                                    <span className={classNames("block truncate", selected && "font-semibold")}>
                                      {vendor.vendorCode} | {vendor.vendorName}
                                    </span>

                                    {selected && (
                                      <span
                                        className={classNames("absolute inset-y-0 right-0 flex items-center pr-4", active ? "text-white" : "text-blue-600")}
                                      >
                                        <Check className="w-5 h-5" aria-hidden="true" />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        )}
                      </div>
                    </Combobox>
                    <Combobox as="div" value={selectedAccount} onChange={setSelectedAccount} className="w-full">
                      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">Payment Account</Combobox.Label>
                      <div className="relative mt-2">
                        <Combobox.Input
                          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          onChange={(event) => setAccQuery(event.target.value)}
                          placeholder="Select an account"
                          displayValue={(acc) => {
                            return acc !== null ? acc?.accountNo + " | " + acc?.description : null;
                          }}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                          <ArrowSeparateVertical className="w-5 h-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>

                        {filteredAccounts.length > 0 && (
                          <Combobox.Options className="absolute z-[999] mt-1 max-h-60 w-full overflow-visible rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredAccounts.map((acc) => (
                              <Combobox.Option
                                key={acc.accountId}
                                value={acc}
                                className={({ active }) =>
                                  classNames("relative cursor-default select-none py-2 pl-3 pr-9", active ? "bg-blue-600 text-white" : "text-gray-900")
                                }
                              >
                                {({ active, selected }) => (
                                  <>
                                    <span className={classNames("block truncate", selected && "font-semibold")}>
                                      {acc.accountNo} | {acc.description}
                                    </span>

                                    {selected && (
                                      <span
                                        className={classNames("absolute inset-y-0 right-0 flex items-center pr-4", active ? "text-white" : "text-blue-600")}
                                      >
                                        <Check className="w-5 h-5" aria-hidden="true" />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        )}
                      </div>
                    </Combobox>
                    <div className="flex flex-row items-center justify-between gap-4 mt-4">
                      <h3 className="text-lg font-semibold uppercase">Items</h3>
                    </div>
                    <div className="flex flex-col items-center justify-start w-full gap-4 mb-2">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                              Part No.
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Description
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Price
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Quantity
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                              Total
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                              <span className="sr-only">Remove from PO</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {poData.items.map((pt) => (
                            <tr key={pt.itemId}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 w-[120px]">{pt.partNo}</td>
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pt.description}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[120px]">
                                <input
                                  className="w-[80px] rounded-md py-1 px-3 text-gray-900 border border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                  type="number"
                                  id={pt.itemId}
                                  defaultValue={parseFloat(pt.price.toFixed(2))}
                                  onBlur={changePartPrice}
                                  step="0.01"
                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[100px]">
                                <input
                                  className="w-[80px] rounded-md py-1 px-3 text-gray-900 border border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                  type="number"
                                  id={pt.itemId}
                                  defaultValue={pt.quantity}
                                  onBlur={changePartQuantity}
                                  step="0.01"
                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[120px] text-right">
                                {formatCurrency(parseFloat(pt.price.toFixed(2)) * parseFloat(pt.quantity.toFixed(2)))}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[120px]">
                                <SecondaryButton label="Remove Part" callback={() => removeItemFromPO(pt.itemId)} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="flex items-center justify-end w-full gap-4 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeEditPOModal()} />
                    <PrimaryButton label="Save Changes" callback={() => updatePO()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderEditMiscPOModal = () => {
    return (
      <Transition.Root show={editMiscPOModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeEditMiscPOModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:min-h-[550px] sm:p-6">
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
                    Edit Misc. Parts Pre-Purchase Order
                  </Dialog.Title>
                  <div className="flex flex-col items-start justify-start w-full gap-2 mt-2">
                    <Combobox as="div" value={selectedVendor} onChange={setSelectedVendor} className="w-full">
                      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">Vendor</Combobox.Label>
                      <div className="relative mt-2">
                        <Combobox.Input
                          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          onChange={(event) => setQuery(event.target.value)}
                          placeholder="Select a vendor"
                          displayValue={(vendor) => {
                            return vendor !== null ? vendor?.vendorCode + " | " + vendor?.vendorName : null;
                          }}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                          <ArrowSeparateVertical className="w-5 h-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>

                        {filteredVendors.length > 0 && (
                          <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredVendors.map((vendor) => (
                              <Combobox.Option
                                key={vendor.vendorId}
                                value={vendor}
                                className={({ active }) =>
                                  classNames("relative cursor-default select-none py-2 pl-3 pr-9", active ? "bg-blue-600 text-white" : "text-gray-900")
                                }
                              >
                                {({ active, selected }) => (
                                  <>
                                    <span className={classNames("block truncate", selected && "font-semibold")}>
                                      {vendor.vendorCode} | {vendor.vendorName}
                                    </span>

                                    {selected && (
                                      <span
                                        className={classNames("absolute inset-y-0 right-0 flex items-center pr-4", active ? "text-white" : "text-blue-600")}
                                      >
                                        <Check className="w-5 h-5" aria-hidden="true" />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        )}
                      </div>
                    </Combobox>
                    <Combobox as="div" value={selectedAccount} onChange={setSelectedAccount} className="w-full">
                      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">Payment Account</Combobox.Label>
                      <div className="relative mt-2">
                        <Combobox.Input
                          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          onChange={(event) => setAccQuery(event.target.value)}
                          placeholder="Select an account"
                          displayValue={(acc) => {
                            return acc !== null ? acc?.accountNo + " | " + acc?.description : null;
                          }}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                          <ArrowSeparateVertical className="w-5 h-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>

                        {filteredAccounts.length > 0 && (
                          <Combobox.Options className="absolute z-[999] mt-1 max-h-60 w-full overflow-visible rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredAccounts.map((acc) => (
                              <Combobox.Option
                                key={acc.accountId}
                                value={acc}
                                className={({ active }) =>
                                  classNames("relative cursor-default select-none py-2 pl-3 pr-9", active ? "bg-blue-600 text-white" : "text-gray-900")
                                }
                              >
                                {({ active, selected }) => (
                                  <>
                                    <span className={classNames("block truncate", selected && "font-semibold")}>
                                      {acc.accountNo} | {acc.description}
                                    </span>

                                    {selected && (
                                      <span
                                        className={classNames("absolute inset-y-0 right-0 flex items-center pr-4", active ? "text-white" : "text-blue-600")}
                                      >
                                        <Check className="w-5 h-5" aria-hidden="true" />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        )}
                      </div>
                    </Combobox>
                    <div className="flex flex-row items-center justify-between gap-4 mt-4">
                      <h3 className="text-lg font-semibold uppercase">Items</h3>
                    </div>
                    <div className="flex flex-col items-center justify-start w-full gap-4 mb-2">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                              Part No.
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Description
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Price
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Quantity
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                              Total
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                              <span className="sr-only">Remove from PO</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {poData.items.map((pt) => (
                            <tr key={pt.itemId}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 w-[120px]">{pt.partNo}</td>
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pt.description}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[120px]">
                                <input
                                  className="w-[80px] rounded-md py-1 px-3 text-gray-900 border border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                  type="number"
                                  id={pt.itemId}
                                  defaultValue={parseFloat(pt.price.toFixed(2))}
                                  onBlur={changePartPrice}
                                  step="0.01"
                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[100px]">
                                <input
                                  className="w-[80px] rounded-md py-1 px-3 text-gray-900 border border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                  type="number"
                                  id={pt.itemId}
                                  defaultValue={pt.quantity}
                                  onBlur={changePartQuantity}
                                  step="0.01"
                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[120px] text-right">
                                {formatCurrency(parseFloat(pt.price.toFixed(2)) * parseFloat(pt.quantity.toFixed(2)))}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[120px]">
                                <SecondaryButton label="Remove Part" callback={() => removeItemFromPO(pt.itemId)} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="flex items-center justify-end w-full gap-4 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeEditMiscPOModal()} />
                    <PrimaryButton label="Save Changes" callback={() => updateMiscPO()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const changePartPrice = (e) => {
    let tmp = poData.items;
    let { id, value } = e.target;
    if (value === "" || value === null) value = 0;
    setDummyLoading(true);
    let existing = _.find(tmp, (pt) => pt.itemId === id);
    existing.price = parseFloat(value);
    existing.total = parseFloat((parseFloat(value) * parseFloat(existing.quantity)).toFixed(2));
    let index = _.findIndex(tmp, (pt) => pt.itemId === id);
    tmp[index] = existing;
    let preAdd = poData;
    preAdd.items = tmp;
    setPOData(preAdd);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const changePartQuantity = (e) => {
    let tmp = poData.items;
    let { id, value } = e.target;
    if (value === "" || value === null) value = 0;
    setDummyLoading(true);
    let existing = _.find(tmp, (pt) => pt.itemId === id);
    existing.quantity = parseFloat(value);
    existing.total = parseFloat((parseFloat(value) * parseFloat(existing.price)).toFixed(2));
    let index = _.findIndex(tmp, (pt) => pt.itemId === id);
    tmp[index] = existing;
    let preAdd = poData;
    preAdd.items = tmp;
    setPOData(preAdd);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const closeEditPOModal = () => {
    setEditPOModal(false);
    setPOData({
      poId: "",
      vendorId: "",
      vendorName: "",
      vendorCode: "",
      locationId: "",
      poAccount: "",
      items: [],
    });
    setQuery("");
    setAccQuery("");
    setSelectedVendor(null);
    setSelectedAccount(null);
  };

  const startPOEdit = (po) => {
    setPOData({
      poId: po.poId,
      vendorId: po.vendorId,
      vendorName: po.vendorName,
      vendorCode: po.vendorCode,
      locationId: po.locationId,
      poAccount: po.poAccount,
      items: po.items,
    });
    if (po.vendorId !== "") {
      let vendor = vendors.find((v) => v.vendorId === po.vendorId);
      setSelectedVendor(vendor);
    }
    if (po.poAccount !== "") {
      let acc = accounts.find((v) => v.accountId === po.poAccount);
      setSelectedAccount(acc);
    }
    setEditPOModal(true);
  };

  const closeEditMiscPOModal = () => {
    setEditMiscPOModal(false);
    setPOData({
      poId: "",
      vendorId: "",
      vendorName: "",
      vendorCode: "",
      locationId: "",
      poAccount: "",
      items: [],
    });
    setQuery("");
    setAccQuery("");
    setSelectedVendor(null);
    setSelectedAccount(null);
  };

  const startMiscPOEdit = (po) => {
    setPOData({
      poId: po.poId,
      vendorId: po.vendorId,
      vendorName: po.vendorName,
      vendorCode: po.vendorCode,
      locationId: po.locationId,
      poAccount: po.poAccount,
      items: po.items,
    });
    if (po.vendorId !== "") {
      let vendor = vendors.find((v) => v.vendorId === po.vendorId);
      setSelectedVendor(vendor);
    }
    if (po.poAccount !== "") {
      let acc = accounts.find((v) => v.accountId === po.poAccount);
      setSelectedAccount(acc);
    }
    setEditMiscPOModal(true);
  };

  const removeItemFromPO = (itemId) => {
    let tmp = poData.items;
    setDummyLoading(true);
    let toAdd = [];
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].itemId !== itemId) {
        toAdd.push(tmp[i]);
      }
    }
    let preAdd = poData;
    preAdd.items = toAdd;
    setPOData(preAdd);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const updatePO = () => {
    let failed = false;
    if (!selectedVendor) {
      toast.error("Please select a vendor for this Purchase Order.");
      failed = true;
    } else if (!failed && !selectedAccount) {
      toast.error("Please select a payment account for this Purchase Order.");
      failed = true;
    } else if (!failed && poData.items.length === 0) {
      toast.error("Please add at least one item to this Purchase Order, otherwise exclude it from the job creation");
      failed = true;
    } else if (!failed) {
      setLoading(true);
      let tmp = purchaseOrders;
      let toAdd = [];
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].poId === poData.poId) {
          tmp[i].vendorId = selectedVendor.vendorId;
          tmp[i].vendorName = selectedVendor.vendorName;
          tmp[i].vendorCode = selectedVendor.vendorCode;
          tmp[i].locationId = poData.locationId;
          tmp[i].poAccount = selectedAccount.accountId;
          tmp[i].items = poData.items;
        }
        toAdd.push(tmp[i]);
      }
      setPurchaseOrders(toAdd);
      closeEditPOModal();
      setTimeout(() => setLoading(false), 700);
    }
  };

  const updateMiscPO = () => {
    let failed = false;
    if (!selectedVendor) {
      toast.error("Please select a vendor for this Purchase Order.");
      failed = true;
    } else if (!failed && !selectedAccount) {
      toast.error("Please select a payment account for this Purchase Order.");
      failed = true;
    } else if (!failed && poData.items.length === 0) {
      toast.error("Please add at least one item to this Purchase Order, otherwise exclude it from the job creation");
      failed = true;
    } else if (!failed) {
      setLoading(true);
      let tmp = miscParts;
      let toAdd = [];
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].poId === poData.poId) {
          tmp[i].vendorId = selectedVendor.vendorId;
          tmp[i].vendorName = selectedVendor.vendorName;
          tmp[i].vendorCode = selectedVendor.vendorCode;
          tmp[i].locationId = poData.locationId;
          tmp[i].poAccount = selectedAccount.accountId;
          tmp[i].items = poData.items;
        }
        toAdd.push(tmp[i]);
      }
      setMiscParts(toAdd);
      closeEditMiscPOModal();
      setTimeout(() => setLoading(false), 700);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full pb-3">
      <div className="flex flex-col items-start justify-start flex-grow w-full pb-3 overflow-y-auto max-w-7xl">
        {!loading ? (
          <>
            <Helmet>
              <title>Job from Quote - {quote.quoteNo} | HTPS ERP</title>
            </Helmet>
            <div className="flex flex-row items-center justify-between w-full pb-3 mb-5 border-b border-gray-300">
              <div className="flex items-center gap-4">
                <PasteClipboard className="w-10 h-10 " />
                <div className="flex flex-col items-start justify-center h-full pl-6 border-l border-gray-900/30">
                  <p className="text-sm leading-6 text-gray-500">
                    QUOTE <span className="font-semibold text-gray-700">{quote.quoteNo}</span>
                  </p>
                  <p className="text-xs leading-6 text-gray-500">
                    EST. TOTAL: <span className="font-semibold text-gray-700">{formatCurrency(quote.estTotal)}</span>
                  </p>
                </div>
              </div>
              <h1 className="text-lg font-bold tracking-tight text-right uppercase text-gray-900/40">Create a Job from Quote</h1>
            </div>
            {renderContent()}
            {inventoryTransmittals.length > 0 && (
              <>
                <div className="flex flex-row items-center justify-between w-full px-4 pb-3 border-b border-gray-300 sm:px-0">
                  <div className="flex flex-col items-start justify-center">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Inventory Transmittals</h3>
                    <p className="max-w-2xl text-sm leading-6 text-gray-500">Stock transfers between locations</p>
                  </div>
                  <h3 className="text-3xl font-semibold text-gray-900/40">{inventoryTransmittals.length}</h3>
                </div>
                <div className="grid min-w-full grid-cols-12 px-1 align-middle grid-rows-auto">
                  <p className="col-span-1 sticky top-0 z-10 border-b border-gray-300 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                    Quantity
                  </p>
                  <p className="col-span-1 sticky top-0 z-10 border-b border-gray-300 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                    Part No.
                  </p>
                  <p className="col-span-2 sticky top-0 z-10 border-b border-gray-300 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                    Inventory Source
                  </p>
                  <p className="col-span-7 w-full sticky top-0 z-10 border-b border-gray-300 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                    Description
                  </p>
                  <p className="col-span-1 sticky top-0 z-10 border-b border-gray-300 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"></p>
                </div>
                {inventoryTransmittals.map((transmittal, index) => (
                  <div className="grid items-center min-w-full grid-cols-12 px-4 align-middle grid-rows-auto">
                    <p
                      className={classNames(
                        index !== inventoryTransmittals.length - 1 ? "border-b border-gray-200" : "",
                        "whitespace-nowrap py-4 text-sm font-medium text-gray-900 col-span-1 text-left",
                      )}
                    >
                      {transmittal.quantity}
                    </p>
                    <p
                      className={classNames(
                        index !== inventoryTransmittals.length - 1 ? "border-b border-gray-200" : "",
                        "whitespace-nowrap hidden py-4 text-sm text-gray-500 sm:table-cell col-span-1 text-left",
                      )}
                    >
                      {transmittal.partNo}
                    </p>
                    <p
                      className={classNames(
                        index !== inventoryTransmittals.length - 1 ? "border-b border-gray-200" : "",
                        "whitespace-nowrap hidden py-4 text-sm text-gray-500 lg:table-cell col-span-2 text-left",
                      )}
                    >
                      {locations.find((l) => l.locationId === transmittal.fromLocation).locationName}
                    </p>
                    <p
                      className={classNames(
                        index !== inventoryTransmittals.length - 1 ? "border-b border-gray-200" : "",
                        "whitespace-nowrap hidden py-4 text-sm text-gray-500 lg:table-cell col-span-7 text-left",
                      )}
                    >
                      {transmittal.description}
                    </p>
                    <p
                      className={classNames(
                        index !== inventoryTransmittals.length - 1 ? "border-b border-gray-200" : "",
                        "relative whitespace-nowrap py-4 text-sm font-medium col-span-1 flex justify-end items-center",
                      )}
                    >
                      <button
                        onClick={() => updateTransmit(transmittal.transmittalId)}
                        className="w-[200px] gap-x-1.5 rounded-md text-gray-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm hover:bg-gray-50 ring-1 rind-inset ring-gray-300"
                      >
                        {transmittal.skip ? "Include" : "Skip"}
                      </button>
                    </p>
                  </div>
                ))}
              </>
            )}
            {purchaseOrders.length > 0 && (
              <>
                <div className="flex flex-row items-center justify-between w-full px-4 pb-3 mt-5 border-b border-gray-300 sm:px-0">
                  <div className="flex flex-col items-start justify-center">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Purchase Orders</h3>
                    <p className="max-w-2xl text-sm leading-6 text-gray-500">Order placement to vendors and suppliers</p>
                  </div>
                  <h3 className="text-3xl font-semibold text-gray-900/40">{purchaseOrders.length}</h3>
                </div>
                {purchaseOrders.map((po, index) => (
                  <div key={`PO_${index}`} className="flex flex-col items-start justify-start min-w-full gap-2 p-4 border border-gray-200">
                    <div className="flex flex-row items-start justify-between w-full gap-2">
                      <div className="flex flex-col items-start justify-start gap-1">
                        <p className="text-lg font-semibold text-gray-900">Pre-Purchase Order #{index + 1}</p>
                      </div>
                      <div className="flex flex-col items-end justify-start gap-1">
                        <p className="mt-1 text-sm text-gray-900">
                          <span className="font-semibold">Vendor:</span> {po.vendorCode} | {po.vendorName}
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          <span className="font-semibold">Location:</span> {locations.find((acc) => acc.locationId === po.locationId).locationName}
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          <span className="font-semibold">Payment Account:</span> {accounts.find((acc) => acc.accountId === po.poAccount).accountNo} |{" "}
                          {accounts.find((acc) => acc.accountId === po.poAccount).description}
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          <span className="font-semibold">Purchase Order Total:</span> {formatCurrency(renderPOTotal(po))}
                        </p>
                      </div>
                    </div>
                    <div className="w-full pt-3">
                      <div className="w-full pb-2 border-b border-gray-300 sm:flex sm:items-center">
                        <h1 className="text-base font-semibold leading-6 uppercase text-gray-900/80">Items</h1>
                      </div>
                      <div className="flow-root w-full px-2">
                        <div className="inline-block w-full min-w-full align-middle">
                          <table className="w-full min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                  Quantity
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Part No.
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Description
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                  Cost
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                              {po.items.map((item) => (
                                <tr key={item.itemId}>
                                  <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">{item.quantity}</td>
                                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{item.partNo}</td>
                                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{item.description}</td>
                                  <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(item.price)}</td>
                                  <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(item.total)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-full gap-2 pt-4 border-t border-gray-300">
                      {!po.skip && <SecondaryButton label="Edit PO" callback={() => startPOEdit(po)} />}
                      <button
                        onClick={() => excludePO(po.poId)}
                        className="w-[150px] gap-x-1.5 rounded-md text-gray-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm hover:bg-gray-50 ring-1 rind-inset ring-gray-300"
                      >
                        {po.skip ? "Include this PO" : "Exclude this PO"}
                      </button>
                    </div>
                  </div>
                ))}
              </>
            )}
            {miscParts.length > 0 && (
              <>
                <div className="flex flex-row items-center justify-between w-full px-4 pb-3 mt-5 border-b border-gray-300 sm:px-0">
                  <div className="flex flex-col items-start justify-center">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Purchase Orders for Misc. Parts</h3>
                    <p className="max-w-2xl text-sm leading-6 text-gray-500">Order placement to vendors and suppliers</p>
                  </div>
                  <h3 className="text-3xl font-semibold text-gray-900/40">{miscParts.length}</h3>
                </div>
                {miscParts.map((po, index) => (
                  <div key={`PO_${index}`} className="flex flex-col items-start justify-start min-w-full gap-2 p-4 border border-gray-200">
                    <div className="flex flex-row items-start justify-between w-full gap-2">
                      <div className="flex flex-col items-start justify-start gap-1">
                        <p className="text-lg font-semibold text-gray-900">Pre-Purchase Order #{index + 1 + purchaseOrders.length}</p>
                      </div>
                      <div className="flex flex-col items-end justify-start gap-1">
                        <div className="flex items-center justify-end gap-1">
                          <p className="text-sm text-gray-900">
                            <span className="font-semibold">Vendor:</span> {po.vendorId !== "" ? po.vendorCode + " | " + po.vendorName : "Missing"}
                          </p>
                          {po.vendorId === "" && <WarningCircle className="w-5 h-5 text-red-500" />}
                        </div>
                        <p className="mt-1 text-sm text-gray-900">
                          <span className="font-semibold">Location:</span> {locations.find((acc) => acc.locationId === po.locationId).locationName}
                        </p>
                        <div className="flex items-center justify-end gap-1 mt-1">
                          <p className="text-sm text-gray-900">
                            <span className="font-semibold">Payment Account:</span>{" "}
                            {po.poAccount !== ""
                              ? accounts.find((acc) => acc.accountId === po.poAccount).accountNo +
                                " | " +
                                accounts.find((acc) => acc.accountId === po.poAccount).description
                              : "Missing"}
                          </p>
                          {po.vendorId === "" && <WarningCircle className="w-5 h-5 text-red-500" />}
                        </div>
                        <p className="mt-1 text-sm text-gray-900">
                          <span className="font-semibold">Purchase Order Total:</span> {formatCurrency(renderPOTotal(po))}
                        </p>
                      </div>
                    </div>
                    <div className="w-full pt-3">
                      <div className="w-full pb-2 border-b border-gray-300 sm:flex sm:items-center">
                        <h1 className="text-base font-semibold leading-6 uppercase text-gray-900/80">Items</h1>
                      </div>
                      <div className="flow-root w-full px-2">
                        <div className="inline-block w-full min-w-full align-middle">
                          <table className="w-full min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                  Quantity
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Part No.
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Description
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                  Cost
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                              {po.items.map((item) => (
                                <tr key={item.itemId}>
                                  <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">{item.quantity}</td>
                                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{item.partNo}</td>
                                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{item.description}</td>
                                  <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(item.price)}</td>
                                  <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(item.total)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-full gap-2 pt-4 border-t border-gray-300">
                      {!po.skip && <SecondaryButton label="Edit PO" callback={() => startMiscPOEdit(po)} />}
                      <button
                        onClick={() => excludeMiscPO(po.poId)}
                        className="w-[150px] gap-x-1.5 rounded-md text-gray-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm hover:bg-gray-50 ring-1 rind-inset ring-gray-300"
                      >
                        {po.skip ? "Include this PO" : "Exclude this PO"}
                      </button>
                    </div>
                  </div>
                ))}
              </>
            )}
            <div className="flex flex-row items-center justify-end w-full gap-2 px-3 pt-5 my-5 border-t border-gray-400">
              <SecondaryButton label="Cancel" callback={() => navigate(`/quotes/${quoteId}`)} />
              <PrimaryButton label="Create a job" callback={() => createJob()} />
            </div>
            {renderEditPOModal()}
            {renderEditMiscPOModal()}
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default JobFromQuote;
