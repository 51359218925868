import { useEffect, useState, Fragment, useRef } from "react";
import { GetOneQuote, GetRates, UpdateQuote, SubmitQuoteForReview, ReviseQuote, ApproveQuote, RejectQuote } from "../../actions/quotes";
import { useParams, useNavigate } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { GetParts } from "../../actions/parts";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { formatCurrency } from "../../components/tools";
import { Dialog, Transition } from "@headlessui/react";
import { DoubleCheck, Xmark } from "iconoir-react";
import { GetExpandedPartsList } from "../../actions/ims";
import { Helmet } from "react-helmet-async";

const QuoteReview = () => {
  let [loading, setLoading] = useState(true);
  let [quote, setQuote] = useState({});
  let [customer, setCustomer] = useState({});
  let [location, setLocation] = useState({});
  let [equipment, setEquipment] = useState([]);
  let [rawPartsData, setRawPartsData] = useState([]);
  let [partsData, setPartsData] = useState([]);
  let [rates, setRates] = useState([]);
  let [openApprove, setOpenApprove] = useState(false);
  let [openReject, setOpenReject] = useState(false);
  const [comment, setComment] = useState("");

  const { quoteId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneQuote(quoteId)
        .then((res) => {
          let quoteData = res.data.quote;
          if (quoteData.quoteStatus !== "approval") {
            toast.error("This quote does not require approval at this time.\nRedirecting you to the quote.");
            setTimeout(() => navigate(`/quotes/${quoteId}`), 3000);
          } else {
            setQuote(quoteData);
            setCustomer(res.data.customer);
            setLocation(res.data.location);
            setEquipment(res.data.equipment);
            GetRates()
              .then((res) => {
                setRates(res.data);
                GetExpandedPartsList()
                  .then((res) => {
                    setRawPartsData(res.data.parts);
                    setPartsData(res.data.parts);
                    setTimeout(() => setLoading(false), 700);
                  })
                  .catch((err) => {
                    toast.error("Error loading parts");
                    setLoading(false);
                  });
              })
              .catch((err) => {
                toast.error("Error loading labor rates");
                setLoading(false);
              });
          }
        })
        .catch((err) => {
          toast.error("Error loading quote");
          setTimeout(() => {
            navigate("/quotes");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
  }, [quoteId]);

  const renderTerms = (terms) => {
    switch (terms) {
      case "net10":
        return "NET-10";
      case "net20":
        return "NET-20";
      case "net30":
        return "NET-30";
      case "collectOnDelivery":
        return "Collect on Delivery";
      case "dueOnReceipt":
        return "Due on Receipt";
      case "prePay":
        return "Pre-Pay";
      default:
        return "NET-30";
    }
  };

  const renderEquipStatus = (status) => {
    if (status === "availableForUse") {
      return "Available for Use";
    } else if (status === "inoperable") {
      return "Inoperable";
    } else if (status === "retired") {
      return "Retired";
    } else {
      return "Unknown";
    }
  };

  const renderEquipType = (eqType) => {
    switch (eqType) {
      case "generator":
        return "Generator";
      case "pressureWasher":
        return "Pressure Washer";
      case "truck":
        return "Truck";
      case "trailer":
        return "Trailer";
      case "welder":
        return "Welder";
      case "airCompressor":
        return "Air Compressor";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const rejectQuote = () => {
    setLoading(true);
    RejectQuote(quoteId, { comment })
      .then((res) => {
        toast.success("Quote rejected successfully. You will be redirected shortly.");
        navigate(`/quotes/${quoteId}`);
      })
      .catch((err) => {
        toast.error(err.response.data.message.length > 0 ? err.response.data.message : "Error rejecting quote, please try again");
        setLoading(false);
      });
  };

  const approveQuote = () => {
    setLoading(true);
    ApproveQuote(quoteId, { comment })
      .then((res) => {
        toast.success("Quote approved successfully. You will be redirected shortly.");
        navigate(`/quotes/${quoteId}`);
      })
      .catch((err) => {
        toast.error(err.response.data.message.length > 0 ? err.response.data.message : "Error approving quote, please try again");
        setLoading(false);
      });
  };

  const renderApproveQuoteModal = () => {
    return (
      <Transition.Root show={openApprove} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeApproval()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:pt-6">
                  <div>
                    <div className="flex items-center justify-center w-12 h-12 mx-auto bg-blue-100 rounded-full">
                      <DoubleCheck className="w-6 h-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 sm:mt-5">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
                        Approve Quote
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-center text-gray-500">If you have any comments to include with this approval, enter them below.</p>
                      </div>
                    </div>
                  </div>
                  <textarea
                    rows={4}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none mt-4"
                    id="approvalComment"
                    defaultValue=""
                    placeholder="Enter your comments here."
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <div className="flex flex-row items-center justify-end gap-3 py-2 mt-4">
                    <SecondaryButton callback={() => closeApproval()} label="Cancel" />
                    <PrimaryButton callback={() => approveQuote()} label="Approve Quote" />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderRejectQuoteModal = () => {
    return (
      <Transition.Root show={openReject} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeRejection()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:pt-6">
                  <div>
                    <div className="flex items-center justify-center w-12 h-12 mx-auto bg-red-100 rounded-full">
                      <Xmark className="w-6 h-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 sm:mt-5">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
                        Reject Quote
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-center text-gray-500">If you have any comments to include with this rejection, enter them below.</p>
                      </div>
                    </div>
                  </div>
                  <textarea
                    rows={4}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none mt-4"
                    id="rejectionComment"
                    defaultValue=""
                    placeholder="Enter your comments here."
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <div className="flex flex-row items-center justify-end gap-3 py-2 mt-4">
                    <SecondaryButton callback={() => closeRejection()} label="Cancel" />
                    <PrimaryButton callback={() => rejectQuote()} label="Reject Quote" />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeApproval = () => {
    setOpenApprove(false);
    setComment("");
  };

  const closeRejection = () => {
    setOpenReject(false);
    setComment("");
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Review Quote - {quote.quoteNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-center w-full pb-3 border-b border-gray-300">
            <div className="flex flex-col items-center justify-center px-8 py-4 my-2 bg-gray-100 rounded-lg w-fit">
              <p className="font-mono text-xs">
                Quote <span className="font-semibold">{quote.quoteNo}</span> requires approval. Please review all items carefully before scrolling down to
                approve or reject it.
              </p>
            </div>
          </div>
          <h4 className="w-full pt-4 font-mono text-lg font-bold tracking-wider text-center text-red-500 uppercase">
            Internal Document - Not an official Estimate
          </h4>
          <div className="flex flex-col items-center justify-start w-full py-2">
            <p className="font-mono font-semibold text-gray-900">HiTech Power Systems Inc.</p>
            <p className="font-mono text-xs font-semibold text-gray-900">
              {location.address}
              {location.address2.length > 0 ? `, ${location.address2}` : null}
            </p>
            <p className="font-mono text-xs font-semibold text-gray-900">
              {location.city}, {location.state} {location.zip}
            </p>
            <p className="font-mono text-xs font-semibold text-gray-900">
              ({location.phoneNumber.slice(0, 3)}) {location.phoneNumber.slice(3, 6)}-{location.phoneNumber.slice(6, 10)}
            </p>
            <h4 className="w-full py-3 my-3 text-lg font-bold tracking-wide text-center uppercase border-gray-300 border-y">Estimate for Services</h4>
            <div className="flex flex-row items-start justify-between w-full">
              <div className="flex flex-col items-start justify-start w-1/2">
                <p className="text-base font-bold text-gray-900">{customer.company}</p>
                <p className="text-sm font-semibold text-gray-900">
                  {customer.contact.firstName} {customer.contact.lastName}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  {customer.arData.address} {customer.arData.address2.length > 0 ? `, ${customer.arData.address2}` : null}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  {customer.arData.city}, {customer.arData.state} {customer.arData.zip}
                </p>
                <p className="text-sm font-semibold text-gray-900">{customer.arData.email}</p>
              </div>
              <div className="flex flex-col items-end justify-start w-1/2">
                <p className="text-sm font-medium text-gray-900">Date of Estimate: {dayjs(quote.date).format("MM/DD/YYYY")}</p>
                <p className="text-sm font-medium text-gray-900">Estimate Expires: {dayjs(quote.date).add(30, "day").format("MM/DD/YYYY")}</p>
                <p className="text-sm font-medium text-gray-900">Payment Terms: {renderTerms(quote.paymentTerms)}</p>
              </div>
            </div>
            <h4 className="w-full py-3 my-3 text-lg font-bold tracking-wide text-center uppercase">Estimate: {quote.quoteNo}</h4>
            <div className="flex flex-col items-center justify-center w-full py-3 my-3 border-gray-300 border-y">
              <p className="font-semibold">{quote.description}</p>
              <p>{quote.details}</p>
            </div>
            <div className="w-full border-b border-gray-300"></div>
            <h1 className="w-full mt-5 text-lg font-bold leading-6 text-left text-gray-900 uppercase">Equipment</h1>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Equipment Type
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Customer's Equipment ID
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Equipment Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                    Equipment Make
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                    Equipment Model
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {quote.equipment.map((eq) => {
                  let element = equipment.find((e) => e.equipmentId === eq);
                  return (
                    <tr key={element.equipmentId}>
                      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">{renderEquipType(element.equipmentType)}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{element.customerEquipId}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{renderEquipStatus(element.details.status)}</td>
                      <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{element.details.make}</td>
                      <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{element.details.model}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <h1 className="w-full mt-5 text-lg font-bold leading-6 text-left text-gray-900 uppercase">Labor</h1>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Hours
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Labor Rate
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Description
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                    Per Hour
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                    Ext. Price
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {quote.labor.map((element) => (
                  <tr key={element.laborId}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">{element.hours}</td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{rates.find((rt) => rt.rateId === element.rate).laborCode}</td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{element.description}</td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(element.perHour)}</td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(element.extPrice)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h1 className="w-full mt-5 text-lg font-bold leading-6 text-left text-gray-900 uppercase">Parts</h1>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Quantity
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Part No.
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Description
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                    Unit Price
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                    Ext. Price
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {quote.parts.map((element) => (
                  <tr key={element.uniqueId}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">{element.quantity}</td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{element.partNo}</td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{element.description}</td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(element.pricePerPart)}</td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(element.extPrice)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h1 className="w-full mt-5 text-lg font-bold leading-6 text-left text-gray-900 uppercase">Miscellaneous</h1>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Quantity
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Fee Code
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Description
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                    Unit Price
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                    Ext. Price
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {quote.parking.map((element) => (
                  <tr key={element.parkingId}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">{element.quantity}</td>
                    <td className="px-3 py-4 text-sm text-gray-500 uppercase whitespace-nowrap">{element.parkingType} FEE</td>
                    <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">
                      {element.increment} {element.parkingType} Fee
                    </td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(element.unitCharge)}</td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(element.totalCharge)}</td>
                  </tr>
                ))}
                {quote.loadbank.map((element) => (
                  <tr key={element.loadbankId}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">1</td>
                    <td className="px-3 py-4 text-sm text-gray-500 uppercase whitespace-nowrap">LOADBANK FEE</td>
                    <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">{element.description}</td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(element.unitCharge)}</td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(element.unitCharge)}</td>
                  </tr>
                ))}
                {quote.freight.map((element) => (
                  <tr key={element.freightId}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">1</td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">FREIGHT FEE</td>
                    <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">
                      {element.poNumber.length > 0 ? `Freight Charge, PO Number: ${element.poNumber}` : "Freight Charge"}
                    </td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(element.freightChargeOut)}</td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(element.freightChargeOut)}</td>
                  </tr>
                ))}
                {quote.misc.map((element) => (
                  <tr key={element.miscId}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">{element.quantity}</td>
                    <td className="px-3 py-4 text-sm text-gray-500 uppercase whitespace-nowrap">Misc {element.miscType} Fee</td>
                    <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">Miscellaneous {element.miscType} Charge</td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                      {formatCurrency(parseFloat((element.chargeOutPrice / element.quantity).toFixed(2)))}
                    </td>
                    <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(element.chargeOutPrice)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex flex-col items-end justify-start w-full gap-2 pt-3 mt-5 border-t border-gray-300">
              <div className="flex flex-row items-center justify-between w-1/5">
                <p className="font-bold">Labor Total:</p>
                <p className="font-semibold">{formatCurrency(quote.laborTotal)}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-1/5">
                <p className="font-bold">Parts Total:</p>
                <p className="font-semibold">{formatCurrency(quote.partsTotal)}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-1/5">
                <p className="font-bold">Gas, Oil & Grease Total:</p>
                <p className="font-semibold">{formatCurrency(quote.gasOilGreaseTotal)}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-1/5">
                <p className="font-bold">Parking/Storage Total:</p>
                <p className="font-semibold">{formatCurrency(quote.parkingTotal)}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-1/5">
                <p className="font-bold">Loadbank Total:</p>
                <p className="font-semibold">{formatCurrency(quote.loadbankTotal)}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-1/5">
                <p className="font-bold">Freight Total:</p>
                <p className="font-semibold">{formatCurrency(quote.freightTotal)}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-1/5">
                <p className="font-bold">Misc Total:</p>
                <p className="font-semibold">{formatCurrency(quote.miscTotal)}</p>
              </div>
              <div className="flex flex-row items-center justify-between w-1/5 pt-2 mt-1 border-t border-gray-300">
                <p className="font-bold">ESTIMATE TOTAL:</p>
                <p className="font-semibold">{formatCurrency(quote.estTotal)}</p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-end w-full gap-3 py-5 mt-5 border-t border-gray-400">
              <SecondaryButton callback={() => setOpenReject(true)} label="Reject Quote" />
              <PrimaryButton callback={() => setOpenApprove(true)} label="Approve Quote" />
            </div>
          </div>
          {renderApproveQuoteModal()}
          {renderRejectQuoteModal()}
        </>
      )}
    </div>
  );
};
export default QuoteReview;
