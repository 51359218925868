import { useNavigate } from "react-router-dom";
import * as _ from "lodash";
import { Calculator, Calendar, Community, Hourglass, Okrs, Packages, PasteClipboard, SeaAndSun, TaskList, Timer, WhiteFlag } from "iconoir-react";
import { Helmet } from "react-helmet-async";

const AdminView = ({ authState, authDispatch }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Administrative Dashboard | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Administrative Dashboard</p>
        </div>
        <p className="text-sm uppercase text-gray-500/90">Employee Tools</p>
        <div className="grid w-full grid-cols-3 gap-5">
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/admin/timesheetReview")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <Hourglass className="w-4 h-4" />
              <p className="font-medium text-gray-600">Timesheet Review</p>
            </div>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/admin/timeOff")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <SeaAndSun className="w-4 h-4" />
              <p className="font-medium text-gray-600">Time-Off Management</p>
            </div>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/admin/employees")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <Community className="w-4 h-4" />
              <p className="font-medium text-gray-600">Employee Management</p>
            </div>
          </div>
        </div>
        <p className="mt-5 text-sm uppercase text-gray-500/90">Company</p>
        <div className="grid w-full grid-cols-3 gap-5">
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/admin/chartOfAccounts")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <Calculator className="w-4 h-4" />
              <p className="font-medium text-gray-600">Chart of Accounts</p>
            </div>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/admin/laborRates")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <WhiteFlag className="w-4 h-4" />
              <p className="font-medium text-gray-600">Labor Rates</p>
            </div>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/admin/cannedFees")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <PasteClipboard className="w-4 h-4" />
              <p className="font-medium text-gray-600">Canned Misc. Fees</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminView;
