import axiosInstance from "./axios";

export const GetUser = () => {
  return axiosInstance.get("/auth");
};

export const SignIn = (data) => {
  return axiosInstance.post("/auth/login", data);
};

export const GetLocations = () => {
  return axiosInstance.get("/auth/locations");
};

export const ChangeLocation = (data) => {
  return axiosInstance.post("/auth/changeLocation", data);
};

export const GetVendors = () => {
  return axiosInstance.get("/auth/vendors");
};

export const GetDepartments = () => {
  return axiosInstance.get("/auth/departments");
};

export const GetRates = () => {
  return axiosInstance.get("/auth/rates");
};

export const CheckInvite = (data) => {
  return axiosInstance.post("/auth/checkInvite", data);
};

export const RegisterEmployee = (data) => {
  return axiosInstance.post("/auth/registerEmployee", data);
};

export const GetPayPeriods = () => {
  return axiosInstance.get("/auth/getPayPeriods");
};

export const LogTime = (timesheetId, data) => {
  return axiosInstance.put(`/auth/${timesheetId}/logTime`, data);
};

export const LogPartialTime = (timesheetId, data) => {
  return axiosInstance.put(`/auth/${timesheetId}/logPartialTime`, data);
};

export const LogRNM = (timesheetId, data) => {
  return axiosInstance.put(`/auth/${timesheetId}/logRNM`, data);
};

export const GetEmployees = () => {
  return axiosInstance.get("/auth/employees");
};

export const ForgotPassword = (data) => {
  return axiosInstance.post("/auth/forgotPassword", data);
};

export const ResetPassword = (data) => {
  return axiosInstance.post("/auth/resetPassword", data);
};

export const GetDashboard = () => {
  return axiosInstance.get("/auth/dashboard");
};

export const UpdateProfile = (data) => {
  return axiosInstance.put("/auth/profile", data);
};

export const UpdateEmergencyContact = (data) => {
  return axiosInstance.put("/auth/profile/emergencyContact", data);
};
