import validator from "validator";

const testZip = (val) => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
};

const testPhone = (val) => {
  return /^((\+1|1)?( |-)?)?(\(([2-9])(?:\d(?!\5)\d|(?!\5)\d\d)\)|([2-9])(?:\d(?!\6)\d|(?!\6)\d\d))( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(val);
};

export const newCustomerBasicInfo = {
  customerCode: {
    fieldName: "customerCode",
    label: "Customer Code",
    inputType: "text",
    placeholder: "Customer Code",
    defaultValue: "",
    config: {
      required: "Customer code is required",
      validate: (value) => value.length >= 3 || "Customer code must be at least 3 characters long",
    },
  },
  company: {
    fieldName: "company",
    label: "Company / Customer Name",
    inputType: "text",
    placeholder: "Company / Customer Name",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const newCustomerContactInfo = {
  firstName: {
    fieldName: "firstName",
    label: "First Name",
    inputType: "text",
    placeholder: "First Name",
    defaultValue: "",
    config: {
      required: "First name is required",
      validate: (value) => (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "First name must be at least 2 characters long",
    },
  },
  lastName: {
    fieldName: "lastName",
    label: "Last Name",
    inputType: "text",
    placeholder: "Last Name",
    defaultValue: "",
    config: {
      required: "Last name is required",
      validate: (value) => (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "Last name must be at least 2 characters long",
    },
  },
  email: {
    fieldName: "email",
    label: "Email",
    inputType: "email",
    placeholder: "Email",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  phoneNumber: {
    fieldName: "phoneNumber",
    label: "Phone Number",
    inputType: "phoneNumber",
    placeholder: "Phone Number",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  address: {
    fieldName: "address",
    label: "Address",
    inputType: "text",
    placeholder: "Address",
    defaultValue: "",
    config: {
      required: "Customer's address is required",
      validate: (value) => value.length >= 3 || "Address must be at least 2 characters long",
    },
  },
  address2: {
    fieldName: "address2",
    label: "Apt/Suite/Unit",
    inputType: "text",
    placeholder: "Apt/Suite/Unit",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  city: {
    fieldName: "city",
    label: "City",
    inputType: "text",
    placeholder: "City",
    defaultValue: "",
    config: {
      required: "Customer's city is required",
      validate: (value) => (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "City must be at least 2 characters long",
    },
  },
  state: {
    fieldName: "state",
    label: "State",
    inputType: "state-picker",
    placeholder: "",
    defaultValue: "",
    config: {
      required: "Customer's state is required",
      validate: (value) => value.length === 2 || "Please select a valid state",
    },
  },
  zip: {
    fieldName: "zip",
    label: "ZIP Code",
    inputType: "number",
    placeholder: "ZIP Code",
    defaultValue: "",
    config: {
      required: "Customer's ZIP code is required",
      validate: (value) => testZip(value) || "Please enter a valid ZIP code",
    },
  },
};

export const newCustomerARInfo = {
  firstName: {
    fieldName: "firstName",
    label: "First Name",
    inputType: "text",
    placeholder: "First Name",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lastName: {
    fieldName: "lastName",
    label: "Last Name",
    inputType: "text",
    placeholder: "Last Name",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  email: {
    fieldName: "email",
    label: "Email",
    inputType: "email",
    placeholder: "Email",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  phoneNumber: {
    fieldName: "phoneNumber",
    label: "Phone Number",
    inputType: "phoneNumber",
    placeholder: "Phone Number",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  address: {
    fieldName: "address",
    label: "Address",
    inputType: "text",
    placeholder: "Address",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  address2: {
    fieldName: "address2",
    label: "Apt/Suite/Unit",
    inputType: "text",
    placeholder: "Apt/Suite/Unit",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  city: {
    fieldName: "city",
    label: "City",
    inputType: "text",
    placeholder: "City",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  state: {
    fieldName: "state",
    label: "State",
    inputType: "state-picker",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  zip: {
    fieldName: "zip",
    label: "ZIP Code",
    inputType: "number",
    placeholder: "ZIP Code",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  creditLimit: {
    fieldName: "creditLimit",
    label: "Credit Limit",
    inputType: "number",
    placeholder: "Credit Limit",
    defaultValue: "0",
    config: {
      required: false,
    },
  },
  paymentTerms: {
    fieldName: "paymentTerms",
    label: "Payment Terms",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "net10", label: "NET10" },
      { value: "net20", label: "NET20" },
      { value: "net30", label: "NET30" },
      { value: "collectOnDelivery", label: "Collect on Delivery" },
      { value: "dueOnReceipt", label: "Due on Receipt" },
      { value: "prePay", label: "Pre-Pay" },
    ],
    defaultValue: "net10",
    config: {
      required: false,
    },
  },
};

export const newCustomerDefaults = {
  laborDiscount: {
    fieldName: "laborDiscount",
    label: "Labor discount ($)",
    inputType: "number",
    placeholder: "Labor discount",
    defaultValue: "0",
    config: {
      required: "Labor discount is required",
      validate: (value) => validator.isInt(value) || "Please enter a labor discount",
    },
  },
  partsMarkup: {
    fieldName: "partsMarkup",
    label: "Parts Markup",
    inputType: "number",
    placeholder: "Parts Markup",
    defaultValue: "45",
    config: {
      required: "Parts markup is required",
      validate: (value) => validator.isInt(value) || "Please enter a valid parts markup",
    },
  },
  shopFee: {
    fieldName: "shopFees",
    label: "Shop Fee",
    inputType: "number",
    placeholder: "Shop Fee",
    defaultValue: "3",
    config: {
      required: "Shop fee is required",
      validate: (value) => validator.isInt(value) || "Please enter a valid shop fee",
    },
  },
  contactPref: {
    fieldName: "contactPref",
    label: "Communication Preference",
    inputType: "select",
    placeholder: "Communication preference",
    defaultValue: "email",
    options: [
      { value: "email", label: "Email" },
      { value: "phone", label: "Phone" },
      { value: "mail", label: "Mail" },
      { value: "fax", label: "Fax" },
    ],
    config: {
      required: "Communication preference is required",
      validate: (value) => value.length >= 3 || "Please select a preferred method of communication",
    },
  },
  docDetail: {
    fieldName: "docDetail",
    label: "Documents Detail",
    inputType: "select",
    placeholder: "Documents Detail",
    defaultValue: "itemized",
    options: [
      { value: "itemized", label: "Itemized" },
      { value: "subtotals", label: "Subtotals" },
      { value: "totals", label: "Totals" },
    ],
    config: {
      required: "Documents detail is required",
      validate: (value) => value.length > 4 || "Please select a document detail preference",
    },
  },
  tax: {
    fieldName: "tax",
    label: "What's the customer's tax status?",
    inputType: "select",
    placeholder: "What's the customer's tax status?",
    options: [
      { value: "taxable", label: "Taxable" },
      { value: "gov", label: "Government Entity" },
      { value: "st5biz", label: "Business w/ ST5" },
      { value: "outOfState", label: "Out of State" },
    ],
    defaultValue: "",
    config: {
      required: "Customer's tax status is required",
      validate: (value) => value.length >= 3 || "Please select a tax status",
    },
  },
  salesTaxZip: {
    fieldName: "salesTaxZip",
    label: "Sales Tax ZIP Code",
    inputType: "number",
    placeholder: "Sales Tax ZIP Code",
    defaultValue: "",
    config: {
      required: "Customer's sales tax ZIP code is required",
      validate: (value) => testZip(value) || "Please enter a valid ZIP code",
    },
  },
  warrantyProvider: {
    fieldName: "warrantyProvider",
    label: "Is this customer a warranty provider?",
    inputType: "checkbox",
    placeholder: "Is this customer a warranty provider?",
    defaultValue: false,
    config: {
      required: false,
    },
  },
};

export const customerDefaults = {
  laborDiscount: {
    fieldName: "laborDiscount",
    label: "Labor discount ($)",
    inputType: "number",
    placeholder: "Labor discount",
    defaultValue: "0",
    config: {
      required: "Labor discount is required",
      validate: (value) => validator.isInt(value.toString()) || "Please enter a labor discount",
    },
  },
  partsMarkup: {
    fieldName: "partsMarkup",
    label: "Parts Markup",
    inputType: "number",
    placeholder: "Parts Markup",
    defaultValue: "45",
    config: {
      required: "Parts markup is required",
      validate: (value) => validator.isInt(value.toString()) || "Please enter a valid parts markup",
    },
  },
  shopFee: {
    fieldName: "shopFees",
    label: "Shop Fee",
    inputType: "number",
    placeholder: "Shop Fee",
    defaultValue: "3",
    config: {
      required: "Shop fee is required",
      validate: (value) => validator.isInt(value.toString()) || "Please enter a valid shop fee",
    },
  },
  contactPref: {
    fieldName: "contactPref",
    label: "Communication Preference",
    inputType: "select",
    placeholder: "Communication preference",
    defaultValue: "",
    options: [
      { value: "email", label: "Email" },
      { value: "phone", label: "Phone" },
      { value: "mail", label: "Mail" },
      { value: "fax", label: "Fax" },
    ],
    config: {
      required: "Communication preference is required",
      validate: (value) => value.length >= 3 || "Please select a preferred method of communication",
    },
  },
  docDetail: {
    fieldName: "docDetail",
    label: "Documents Detail",
    inputType: "select",
    placeholder: "Documents Detail",
    defaultValue: "",
    options: [
      { value: "itemized", label: "Itemized" },
      { value: "subtotals", label: "Subtotals" },
      { value: "totals", label: "Totals" },
    ],
    config: {
      required: "Documents detail is required",
      validate: (value) => value.length > 4 || "Please select a document detail preference",
    },
  },
  tax: {
    fieldName: "tax",
    label: "What's the customer's tax status?",
    inputType: "select",
    placeholder: "What's the customer's tax status?",
    options: [
      { value: "taxable", label: "Taxable" },
      { value: "gov", label: "Government Entity" },
      { value: "st5biz", label: "Business w/ ST5" },
      { value: "outOfState", label: "Out of State" },
    ],
    defaultValue: "",
    config: {
      required: "Customer's tax status is required",
      validate: (value) => value.length >= 3 || "Please select a tax status",
    },
  },
  salesTaxZip: {
    fieldName: "salesTaxZip",
    label: "Sales Tax ZIP Code",
    inputType: "text",
    placeholder: "Sales Tax ZIP Code",
    defaultValue: "",
    config: {
      required: "Customer's sales tax ZIP code is required",
      validate: (value) => testZip(value) || "Please enter a valid ZIP code",
    },
  },
  warrantyProvider: {
    fieldName: "warrantyProvider",
    label: "Is this customer a warranty provider?",
    inputType: "checkbox",
    placeholder: "Is this customer a warranty provider?",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  split1: {
    fieldName: "split1",
    label: "Commission",
    inputType: "divider-label",
  },
  commissionEmployee: {
    fieldName: "commission.employee",
    label: "Commission Employee",
    inputType: "employee-picker",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
      // validate: (value) => value.length === 2 || "Please select a valid state",
    },
  },
  commissionPercentage: {
    fieldName: "commission.percentage",
    label: "Commission Percentage",
    inputType: "number",
    placeholder: "Commission Percentage",
    defaultValue: "3",
    config: {
      required: "Commission percentage is required",
      validate: (value) => validator.isInt(value.toString()) || "Please enter a valid shop fee",
    },
  },
};

export const customerContact = {
  firstName: {
    fieldName: "firstName",
    label: "First Name",
    inputType: "text",
    placeholder: "First Name",
    defaultValue: "",
    config: {
      required: "First name is required",
      validate: (value) => (value.length >= 3 && validator.isAlpha(value.replace(" ", "").replace(".", ""))) || "First name must be at least 2 characters long",
    },
  },
  lastName: {
    fieldName: "lastName",
    label: "Last Name",
    inputType: "text",
    placeholder: "Last Name",
    defaultValue: "",
    config: {
      required: "Last name is required",
      validate: (value) => (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "Last name must be at least 2 characters long",
    },
  },
  email: {
    fieldName: "email",
    label: "Email",
    inputType: "email",
    placeholder: "Email",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  phoneNumber: {
    fieldName: "phoneNumber",
    label: "Phone Number",
    inputType: "phoneNumber",
    placeholder: "Phone Number",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  address: {
    fieldName: "address",
    label: "Address",
    inputType: "text",
    placeholder: "Address",
    defaultValue: "",
    config: {
      required: "Customer's address is required",
      validate: (value) => value.length >= 3 || "Address must be at least 2 characters long",
    },
  },
  address2: {
    fieldName: "address2",
    label: "Apt/Suite/Unit",
    inputType: "text",
    placeholder: "Apt/Suite/Unit",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  city: {
    fieldName: "city",
    label: "City",
    inputType: "text",
    placeholder: "City",
    defaultValue: "",
    config: {
      required: "Customer's city is required",
      validate: (value) => (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "City must be at least 2 characters long",
    },
  },
  state: {
    fieldName: "state",
    label: "State",
    inputType: "state-picker",
    placeholder: "",
    defaultValue: "",
    config: {
      required: "Customer's state is required",
      validate: (value) => value.length === 2 || "Please select a valid state",
    },
  },
  zip: {
    fieldName: "zip",
    label: "ZIP Code",
    inputType: "number",
    placeholder: "ZIP Code",
    defaultValue: "",
    config: {
      required: "Customer's ZIP code is required",
      validate: (value) => testZip(value) || "Please enter a valid ZIP code",
    },
  },
};

export const customerArInfo = {
  name: {
    fieldName: "name",
    label: "Name",
    inputType: "text",
    placeholder: "Name",
    defaultValue: "",
    config: {
      required: false,
      // required: "Name is required",
      // validate: (value) =>
      //   // (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) ||
      //   value.length >= 3 || "Name must be at least 2 characters long",
    },
  },
  email: {
    fieldName: "email",
    label: "Email",
    inputType: "email",
    placeholder: "Email",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  phoneNumber: {
    fieldName: "phoneNumber",
    label: "Phone Number",
    inputType: "phoneNumber",
    placeholder: "Phone Number",
    defaultValue: "",
    config: {
      required: false,
      // required: "Customer's phone number is required",
      // validate: (value) => testPhone(value) || "Please enter a valid phone number",
    },
  },
  address: {
    fieldName: "address",
    label: "Address",
    inputType: "text",
    placeholder: "Address",
    defaultValue: "",
    config: {
      required: false,
      // required: "Customer's address is required",
      // validate: (value) => value.length >= 3 || "Address must be at least 2 characters long",
    },
  },
  address2: {
    fieldName: "address2",
    label: "Apt/Suite/Unit",
    inputType: "text",
    placeholder: "Apt/Suite/Unit",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  city: {
    fieldName: "city",
    label: "City",
    inputType: "text",
    placeholder: "City",
    defaultValue: "",
    config: {
      required: false,
      // required: "Customer's city is required",
      // validate: (value) => (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "City must be at least 2 characters long",
    },
  },
  state: {
    fieldName: "state",
    label: "State",
    inputType: "state-picker",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
      // required: "Customer's state is required",
      // validate: (value) => value.length === 2 || "Please select a valid state",
    },
  },
  zip: {
    fieldName: "zip",
    label: "ZIP Code",
    inputType: "number",
    placeholder: "ZIP Code",
    defaultValue: "",
    config: {
      required: false,
      // required: "Customer's ZIP code is required",
      // validate: (value) => testZip(value) || "Please enter a valid ZIP code",
    },
  },
  creditLimit: {
    fieldName: "creditLimit",
    label: "Credit Limit ($)",
    inputType: "number",
    placeholder: "Credit Limit",
    defaultValue: "0",
    config: {
      required: false,
      // required: "Customer's credit limit is required",
      // validate: (value) => validator.isInt(value.toString()) || "Please enter a credit limit for the customer",
    },
  },
  paymentTerms: {
    fieldName: "terms",
    label: "Payment Terms",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "net10", label: "NET10" },
      { value: "net20", label: "NET20" },
      { value: "net30", label: "NET30" },
      { value: "collectOnDelivery", label: "Collect on Delivery" },
      { value: "dueOnReceipt", label: "Due on Receipt" },
      { value: "prePay", label: "Pre-Pay" },
    ],
    defaultValue: "dueOnReceipt",
    config: {
      required: false,
      // required: "Customer's payment terms are required",
      // validate: (value) => value.length > 2 || "Please select payment terms for the customer",
    },
  },
};
