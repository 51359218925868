import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { PrimaryButton, SecondaryButton } from "../../../components/buttons";
import { GetAllEmployees, GetTimeOff, AddTimeOffComment, WithdrawTimeOff } from "../../../actions/admin";
import { DatePicker, Input, Select, Switch } from "antd";
import dayjs from "dayjs";
import { Calendar } from "iconoir-react";
import { Helmet } from "react-helmet-async";

var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const OpenTimeOff = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [data, setData] = useState({
    employeeId: null,
    employeeName: "",
    startDate: null,
    endDate: null,
    allDay: true,
    status: null,
    reason: null,
    comments: [],
  });
  const [newComment, setNewComment] = useState("");

  let { id } = useParams();

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (!id || id.length < 10) {
        toast.error("Invalid time-off entry id");
        navigate(-1);
      } else {
        GetTimeOff(id)
          .then((res) => {
            setData(res.data);
            GetAllEmployees()
              .then((res) => {
                setEmployees(res.data);
                setTimeout(() => setLoading(false), 700);
              })
              .catch((err) => {
                toast.error(err.response.data ? err.response.data.message : "Error loading employees");
                setLoading(false);
              });
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Error loading time-off entry");
            navigate(-1);
          });
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const navigate = useNavigate();

  const renderComment = (comment) => {
    return (
      <div className="flex flex-col w-full leading-1.5 p-4 border-gray-400 bg-gray-200/50 rounded-xl">
        <div className="flex items-center justify-between">
          <span className="text-sm font-semibold text-gray-600">{comment.addedByName}</span>
          <span className="text-xs font-normal text-gray-500">{dayjs(comment.date).format("MM-DD-YYYY h:mm A")}</span>
        </div>
        <p className="text-sm font-normal py-2.5 text-gray-500">{comment.comment}</p>
      </div>
    );
  };

  const addComment = () => {
    if (newComment.length > 0) {
      setLoading(true);
      AddTimeOffComment(id, { comment: newComment })
        .then((res) => {
          setData(res.data);
          toast.success("Comment added successfully");
          setNewComment("");
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error adding time-off comment");
          GetTimeOff(id)
            .then((res) => {
              setData(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Error loading time-off entry");
              navigate(-1);
            });
        });
    } else {
      toast.error("Comment cannot be empty");
      setNewComment("");
    }
  };

  const withdrawTimeOff = () => {
    setLoading(true);
    WithdrawTimeOff(id)
      .then((res) => {
        toast.success("Time-off entry withdrawn successfully");
        setData(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error withdrawing time-off entry");
        GetTimeOff(id)
          .then((res) => {
            setData(res.data);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Error loading time-off entry");
            navigate(-1);
          });
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <div className="flex flex-col items-center justify-start w-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Time-Off Entry</p>
          <SecondaryButton label="Go Back" callback={() => navigate(-1)} />
        </div>
        {loading ? (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
          </div>
        ) : (
          <div className="flex flex-col items-start justify-start w-full max-w-2xl gap-2 mt-5">
            <Helmet>
              <title>
                Time Off - {data.employeeName} - {dayjs(data.startDate).format(data.allDay ? "MM-DD-YYYY" : "MM-DD-YYYY HH:mm")} to{" "}
                {dayjs(data.endDate).format(data.allDay ? "MM-DD-YYYY" : "MM-DD-YYYY HH:mm")} | HTPS ERP
              </title>
            </Helmet>
            <h1 className="text-xl font-extrabold text-slate-950">Time Off - {data.employeeName}</h1>
            <div className="flex items-center justify-start gap-2">
              <Calendar className="w-5 h-5 text-zinc-500" />
              <p className="text-sm font-medium text-zinc-500">
                {dayjs(data.startDate).format(data.allDay ? "MM-DD-YYYY" : "MM-DD-YYYY HH:mm")} to{" "}
                {dayjs(data.endDate).format(data.allDay ? "MM-DD-YYYY" : "MM-DD-YYYY HH:mm")}
              </p>
            </div>
            <p className="text-xs font-medium text-zinc-500">
              Entered By: {data.submittedByName} on {dayjs(data.submitDate).format("dddd, MMMM Do YYYY, h:mm A")}
            </p>
            <p className="mt-4 text-sm font-normal text-gray-500">
              <span className="text-xs font-semibold text-gray-700 uppercase">Reason: </span>
              {data.reason}
            </p>
            <p className="text-sm font-normal text-gray-500">
              <span className="text-xs font-semibold text-gray-700 uppercase">Status: </span>
              {data.status}
            </p>
            <div className="flex items-center justify-between w-full pt-4 mt-4 border-t border-zinc-300">
              <h1 className="font-bold text-slate-800">Comments</h1>
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-4 px-2 mt-2">
              {data.comments.length > 0 && data.comments.map((comment) => renderComment(comment))}

              <div className="flex flex-col items-start justify-start w-full ">
                <label className="pb-2 text-xs font-semibold text-gray-700 uppercase">New Comment</label>
                <Input.TextArea
                  placeholder={"Add a new comment..."}
                  onChange={(e) => setNewComment(e.target.value)}
                  rows={3}
                  style={{ resize: "none" }}
                  value={newComment}
                />
                <div className="flex justify-end w-full pt-2">
                  <SecondaryButton label="Add Comment" callback={() => addComment()} />
                </div>
              </div>
            </div>
            {dayjs().isBefore(dayjs(data.endDate)) && data.status !== "Withdrawn" && (
              <div className="flex items-center justify-end w-full pt-4 mt-4 border-t border-zinc-300">
                <SecondaryButton label="Withdraw Time-Off Request" callback={() => withdrawTimeOff()} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OpenTimeOff;
