import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import validator from "validator";
import { ForgotPassword, ResetPassword } from "../../actions/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../components/loader";
import { Helmet } from "react-helmet-async";

// http://localhost:3000/resetPassword?token=NZKTOY3BIJKGOLSUMNFW2RKSJVYTII3RHE5XII3TFAZSMI3LN5KQ

const ResetPasswordView = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);
  const [token, setToken] = useState(null);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (authState.isAuth) {
        navigate("/");
      }
      let searchToken = searchParams.get("token");
      if (searchToken) {
        setToken(searchToken);
        setLoading(false);
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const updateEmail = (e) => {
    setEmail(e.target.value);
    setEmailError(null);
  };

  const updatePassword = (e) => {
    setPassword(e.target.value);
    setPasswordError(null);
  };

  const updateConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError(null);
  };

  const submitLogin = () => {
    if (!validator.isEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    if (password === "") {
      setPasswordError("Please enter a valid password");
      return;
    }
    if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }
    if (!loading) {
      setLoading(true);
      ResetPassword({ email: email, token: token, password: password })
        .then((res) => {
          toast.success(res.data.message);
          navigate("/login");
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Something went wrong, please try again.");
          navigate("/login");
        });
    }
  };

  return (
    <div className="flex flex-row items-center justify-between w-full h-screen">
      {loading && <Loader />}
      <Helmet>
        <title>Reset Password | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center w-1/2 h-screen bg-white-smoke">
        <div className="flex flex-col items-start justify-start w-3/4">
          <h1 className="text-2xl font-medium">Reset Password</h1>
          <p className="mt-3 text-sm font-light">Enter your email and your desired password below.</p>
          <div className="flex flex-row items-center justify-between w-full mt-10">
            <p className="text-sm font-semibold">E-Mail</p>
            <input placeholder="Email" type="email" className="w-3/4 px-3 py-2 rounded shadow-sm" ref={emailRef} onChange={(e) => updateEmail(e)} />
          </div>
          <p className="w-full h-4 px-2 text-xs font-medium text-right text-red-500">{emailError}</p>
          <div className="flex flex-row items-center justify-between w-full mt-1">
            <p className="text-sm font-semibold">Password</p>
            <input
              placeholder="New Password"
              type="password"
              className="w-3/4 px-3 py-2 rounded shadow-sm"
              ref={passwordRef}
              onChange={(e) => updatePassword(e)}
            />
          </div>
          <p className="w-full h-4 px-2 text-xs font-medium text-right text-red-500">{passwordError}</p>
          <div className="flex flex-row items-center justify-between w-full mt-1">
            <p className="text-sm font-semibold">Confirm Password</p>
            <input
              placeholder="Confirm New Password"
              type="password"
              className="w-3/4 px-3 py-2 rounded shadow-sm"
              ref={confirmPasswordRef}
              onChange={(e) => updateConfirmPassword(e)}
            />
          </div>
          <p className="w-full h-4 px-2 text-xs font-medium text-right text-red-500">{confirmPasswordError}</p>
          <div className="flex flex-row justify-end w-full gap-3">
            <button
              type="button"
              onClick={() => navigate("/login")}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-transparent px-5 py-2 text-xs font-semibold uppercase border border-transparent text-black hover:border-black duration-150"
            >
              Back to Login
            </button>
            <button
              type="button"
              onClick={() => submitLogin()}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-5 py-2 text-xs font-semibold uppercase text-white shadow-sm hover:bg-blue-500"
            >
              Reset Password
            </button>
          </div>
        </div>
      </div>
      <div className="h-screen w-1/2 flex flex-col items-center bg-gradient-to-bl from-[#0575e6] to-[#021b79] justify-center py-10">
        <div className="flex items-center justify-center w-full h-3/4">
          <img src="https://app.jetic.io/static/media/ControlHub.c38e37fd.png" className="w-1/2 h-auto" alt="asset" />
        </div>
        <div className="flex flex-col items-center justify-center w-2/3 gap-1 text-white">
          <h1 className="text-lg font-medium">Meet the HiTech Power Systems ERP</h1>
          <p className="text-xs font-light text-center">
            HiTech's Enterprise Resource Planning Platform ensures you have control and visibility across ongoing projects, inventory, accounting and more!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordView;
