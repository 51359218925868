import { SecondaryButton, PrimaryButton } from "../../components/buttons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { Input, Select, Table, Tooltip } from "antd";
import { QueryCustomers } from "../../actions/customers";
import { QueryEquipment } from "../../actions/ims";
import { QueryQuotes } from "../../actions/quotes";

const _ = require("lodash");

const BLANK_FILTERS = {
  quoteNo: null,
  customer: {
    customerId: null,
    customerCode: null,
    company: null,
  },
  description: null,
  equipment: {
    equipmentId: null,
    customerEquipId: null,
    equipmentType: null,
    customerId: null,
    details: null,
    engine: null,
  },
  customerPo: null,
  locationName: null,
};

const DEFAULT_SIZE = 12;

const AllQuotes = () => {
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [filters, setFilters] = useState(BLANK_FILTERS);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      runJobQuery(filters, page, size);
    }
    return () => {
      inView = false;
    };
  }, []);

  const renderStatus = (status) => {
    switch (status) {
      case "draft":
        return (
          <span className="inline-flex text-center items-center rounded-md bg-gray-50 px-3.5 py-1.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Draft
          </span>
        );
      case "approval":
        return (
          <span className="inline-flex text-center items-center rounded-md bg-yellow-50 px-3.5 py-1.5 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
            Awaiting Manager Approval
          </span>
        );
      case "returned":
        return (
          <span className="inline-flex text-center items-center rounded-md bg-red-50 px-3.5 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            Returned by Manager
          </span>
        );
      case "approved":
        return (
          <span className="inline-flex text-center items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Approved by Manager
          </span>
        );
      case "sent":
        return (
          <span className="inline-flex text-center items-center rounded-md bg-yellow-50 px-3.5 py-1.5 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
            Sent to Customer
          </span>
        );
      case "accepted":
        return (
          <span className="inline-flex text-center items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Accepted by Customer
          </span>
        );
      case "declined":
        return (
          <span className="inline-flex text-center items-center rounded-md bg-red-50 px-3.5 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            Declined by Customer
          </span>
        );
      case "void":
        return (
          <span className="inline-flex text-center items-center rounded-md bg-red-50 px-3.5 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            VOID
          </span>
        );
      case "jobCreated":
        return (
          <span className="inline-flex text-center items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Job Created
          </span>
        );
      default:
        return (
          <span className="inline-flex text-center items-center rounded-md bg-gray-50 px-3.5 py-1.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Unknown
          </span>
        );
    }
  };

  const clearFilters = () => {
    let blank = {
      quoteNo: null,
      customer: {
        customerId: null,
        customerCode: null,
        company: null,
      },
      description: null,
      equipment: {
        equipmentId: null,
        customerEquipId: null,
        equipmentType: null,
        customerId: null,
        details: null,
        engine: null,
      },
      customerPo: null,
      locationName: null,
    };
    setLoading(true);
    setFilters(blank);
    setCustomers([]);
    setEquipment([]);
    setPage(0);
    setSize(DEFAULT_SIZE);
    setTimeout(() => runJobQuery(blank, 0, DEFAULT_SIZE), 350);
  };

  const updateFilter = ({ target: { name, value } }) => {
    let tmp = filters;
    tmp[name] = value;
    setFilters((prev) => ({ ...prev, [name]: value }));
    runJobQuery(tmp, page, size);
  };

  const handleCustomerSearch = (query) => {
    if (query?.length > 2) {
      QueryCustomers(query)
        .then((res) => {
          setCustomers(res.data);
        })
        .catch((err) => {
          toast.error("Customer search failed, please try again");
          setCustomers([]);
        });
    }
  };

  const updateCustomer = (v) => {
    let tmp = filters;
    if (v) {
      let qry = customers.find((c) => c.customerId === v);
      if (qry) {
        tmp.customer = { customerId: qry.customerId, customerCode: qry.customerCode, company: qry.company };
      } else {
        tmp.customer = { customerId: null, customerCode: null, company: null };
      }
    } else {
      tmp.customer = { customerId: null, customerCode: null, company: null };
    }
    setFilters(tmp);
    setCustomers(customers.filter((c) => c.customerId === v));
    runJobQuery(tmp, page, size);
  };

  const handleEquipmentSearch = (query) => {
    if (query?.length > 2) {
      QueryEquipment(query, filters.customer.customerId)
        .then((res) => {
          setEquipment(res.data);
        })
        .catch((err) => {
          toast.error("Equipment search failed, please try again");
          setEquipment([]);
        });
    }
  };

  const updateEquipment = (v) => {
    let tmp = filters;
    if (v) {
      let qry = equipment.find((e) => e.equipmentId === v);
      if (qry) {
        tmp.equipment = {
          equipmentId: qry.equipmentId,
          customerEquipId: qry.customerEquipId,
          equipmentType: qry.equipmentType,
          customerId: qry.customerId,
          details: qry.details,
          engine: qry.details,
        };
      } else {
        tmp.equipment = {
          equipmentId: null,
          customerEquipId: null,
          equipmentType: null,
          customerId: null,
          details: null,
          engine: null,
        };
      }
    } else {
      tmp.equipment = {
        equipmentId: null,
        customerEquipId: null,
        equipmentType: null,
        customerId: null,
        details: null,
        engine: null,
      };
    }
    setFilters(tmp);
    setEquipment(equipment.filter((e) => e.equipmentId === v));
    runJobQuery(tmp, page, size);
  };

  let eqTypes = {
    generator: "Generator",
    pressureWasher: "Pressure Washer",
    truck: "Truck",
    trailer: "Trailer",
    welder: "Welder",
    airCompressor: "Air Compressor",
    other: "Other",
  };

  const runJobQuery = (data, offset, limit) => {
    QueryQuotes({
      quoteNo: data.quoteNo,
      customerId: data.customer.customerId,
      equipmentId: data.equipment.equipmentId,
      description: data.description,
      locationName: data.locationName,
      locationId: data.locationId,
      customerPo: data.customerPo,
      page: offset,
      limit: limit,
    })
      .then((res) => {
        setJobs(res.data.results);
        setTotal(res.data.total);
        setSize(res.data.size);
        setPage(res.data.page);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Failed to fetch jobs");
        setJobs([]);
        setTotal(0);
        setSize(DEFAULT_SIZE);
        setPage(0);
        setFilters(BLANK_FILTERS);
        setTimeout(() => setLoading(false), 700);
      });
  };

  const changePage = (pg, sz) => {
    setPage(pg);
    setSize(sz);
    setLoading(true);
    runJobQuery(filters, pg, sz);
  };

  const applyFilters = () => {
    setLoading(true);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runJobQuery(filters, 0, DEFAULT_SIZE);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  const updateLocation = (v) => {
    let tmp = filters;
    if (v) {
      tmp.locationName = v;
      tmp.locationId = v;
    } else {
      tmp.locationName = null;
      tmp.locationId = null;
    }
    setFilters(tmp);
    runJobQuery(tmp, page, size);
  };

  const locations = [
    {
      label: "Augusta, GA",
      value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf",
    },
    {
      label: "North Augusta, SC",
      value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
    },
  ];

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Quotes | HTPS ERP</title>
      </Helmet>
      <div className="flex-grow flow-root w-full px-2 mt-3">
        <div className="-my-2">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-2">
              <div className="grid w-full h-full gap-6 mx-auto md:grid-cols-12">
                <div className="flex flex-col h-full col-span-3 gap-4">
                  <div className="flex flex-col items-start justify-start w-full gap-4 p-6 border border-gray-300 rounded-lg">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Filters</h3>
                    <div className="flex flex-col items-start justify-start w-full mt-4">
                      <label htmlFor="quoteNo" className="pb-1 text-xs text-gray-600 uppercase">
                        Quote Number
                      </label>
                      <Input
                        placeholder="Quote Number"
                        name="quoteNo"
                        autoComplete="off"
                        onChange={(e) => {
                          updateFilter(e);
                        }}
                        value={filters.quoteNo ? filters.quoteNo : null}
                        defaultValue={filters.quoteNo ? filters.quoteNo : null}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                        Quote Description
                      </label>
                      <Input
                        placeholder="Quote Description"
                        name="description"
                        autoComplete="off"
                        onChange={(e) => {
                          updateFilter(e);
                        }}
                        value={filters.description}
                        defaultValue={filters.description}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="customer" className="pb-1 text-xs text-gray-600 uppercase">
                        Customer
                      </label>
                      <Select
                        placeholder="Search Customers"
                        onChange={(v) => updateCustomer(v)}
                        value={filters.customer.customerId}
                        options={(customers || []).map((p) => ({
                          label: `${p.customerCode} | ${p.company && p.company.length > 0 ? p.company : "No Company Provided"}`,
                          value: p.customerId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No customers found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onSearch={handleCustomerSearch}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="equipment" className="pb-1 text-xs text-gray-600 uppercase">
                        Equipment
                      </label>
                      <Select
                        placeholder="Search Equipment"
                        onChange={(v) => updateEquipment(v)}
                        value={filters.equipment.equipmentId}
                        options={(equipment || []).map((e) => ({
                          label: `${eqTypes[e.equipmentType]}${e?.details?.make?.length ? ` | ${e?.details?.make}` : ""}${e?.details?.model?.length ? ` | ${e?.details?.model}` : ""}${e?.customerEquipId?.length ? ` | ${e?.customerEquipId}` : ""}`,
                          value: e.equipmentId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No equipment found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onSearch={handleEquipmentSearch}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="customerPo" className="pb-1 text-xs text-gray-600 uppercase">
                        Customer PO #
                      </label>
                      <Input
                        placeholder="Customer PO #"
                        name="customerPo"
                        value={filters.customerPo}
                        onChange={(e) => {
                          updateFilter(e);
                        }}
                        allowClear
                        autoComplete="off"
                        defaultValue={filters.customerPo}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="locationName" className="pb-1 text-xs text-gray-600 uppercase">
                        Location
                      </label>
                      <Select
                        placeholder="Quote Location"
                        onChange={(v) => updateLocation(v)}
                        value={filters.locationId}
                        options={locations}
                        className="w-full mb-2 font-sans"
                        controls={false}
                        filterOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        defaultActiveFirstOption={false}
                        allowClear
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full gap-2">
                    <SecondaryButton
                      callback={() => clearFilters()}
                      label="Clear Filters"
                      className="block py-2.5 transition-all duration-300 normal-case w-full"
                    />
                    <PrimaryButton
                      callback={() => applyFilters()}
                      label="Apply Filters"
                      className="block py-2.5 transition-all duration-300 bg-black hover:bg-black/80 normal-case w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full col-span-9 gap-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Search Results</h3>
                    <button
                      className="inline-flex items-center justify-center h-8 px-4 py-2 leading-none text-sm font-medium transition-colors rounded-md whitespace-nowrap ring-offset-[#000064] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#18181b] focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#18181b] text-[#fafafa] hover:bg-[#18181b]/90"
                      onClick={() => navigate("/quotes/new")}
                    >
                      New Quote
                    </button>
                  </div>
                  <Table
                    dataSource={jobs}
                    loading={loading}
                    className="w-full"
                    sticky={true}
                    pagination={{
                      total: total,
                      responsive: true,
                      hideOnSinglePage: true,
                      onChange: (p, s) => changePage(p - 1, s),
                      pageSize: size,
                      current: page + 1,
                      showSizeChanger: false,
                    }}
                    rowKey="quoteId"
                  >
                    <Table.Column title="Status" dataIndex="quoteStatus" key="quoteStatus" render={(d) => renderStatus(d)} width={"160px"} />
                    <Table.Column title="Quote No." dataIndex="quoteNo" key="quoteNo" width={"145px"} align="center" />
                    <Table.Column
                      title="Customer"
                      dataIndex="customerName"
                      key="customerName"
                      render={(_v, r) => (
                        <Tooltip title={`${r?.customerCode}${r?.customerName?.length && ` | ${r.customerName}`}`}>
                          <p className="truncate cursor-pointer">
                            {r?.customerCode}
                            {r?.customerName?.length > 0 && ` | ${r.customerName}`}
                          </p>
                        </Tooltip>
                      )}
                    />
                    <Table.Column
                      title="Location"
                      dataIndex="locationId"
                      key="locationId"
                      width={"120px"}
                      render={(v) => locations.find((l) => l.value === v).label}
                    />
                    <Table.Column
                      title="Description"
                      dataIndex="description"
                      key="description"
                      render={(v) => (
                        <Tooltip title={v}>
                          <p className="truncate cursor-pointer">{v.length > 60 ? v.substring(0, 60) + "..." : v}</p>
                        </Tooltip>
                      )}
                    />
                    <Table.Column
                      title=""
                      dataIndex="quoteId"
                      key="actions"
                      width={"80px"}
                      render={(v) => (
                        <SecondaryButton
                          callback={() => navigate(`/quotes/${v}`)}
                          label="Open"
                          className="transition-all duration-300 border bg-white-smoke border-zinc-400 ring-0 hover:ring-0 hover:bg-zinc-100 hover:border-zinc-600 text-zinc-600 hover:text-zinc-900"
                        />
                      )}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllQuotes;
