import { SecondaryButton, PrimaryButton } from "../../components/buttons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { DatePicker, Input, Select, Table, Tooltip } from "antd";
import { QueryAPInvoices, QueryVendors } from "../../actions/ims";
import { formatCurrency } from "../../components/tools";
import dayjs from "dayjs";

const _ = require("lodash");

const BLANK_FILTERS = {
  invoiceNo: null,
  vendor: {
    vendorId: null,
    vendorCode: null,
    vendorName: null,
  },
  status: null,
  invoiceDate: [dayjs("01/01/2006", "MM/DD/YYYY"), dayjs()],
};

const DEFAULT_SIZE = 12;

const AllAPInvoices = () => {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [vendors, setVendors] = useState([]);
  const [filters, setFilters] = useState(BLANK_FILTERS);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      runJobQuery(filters, page, size);
    }
    return () => {
      inView = false;
    };
  }, []);

  const renderStatus = (status) => {
    switch (status) {
      case "received":
        return (
          <span className="inline-flex items-center rounded-md bg-gray-50 px-3.5 py-1.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Received
          </span>
        );
      case "paid":
        return (
          <span className="inline-flex items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            PAID
          </span>
        );
      case "overdue":
        return (
          <span className="inline-flex items-center rounded-md bg-red-50 px-3.5 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            Overdue
          </span>
        );
      case "partiallyPaid":
        return (
          <span className="inline-flex items-center rounded-md bg-amber-50 px-3.5 py-1.5 text-xs font-medium text-amber-700 ring-1 ring-inset ring-red-600/10">
            Partially Paid
          </span>
        );
      case "void":
        return (
          <span className="inline-flex items-center rounded-md bg-slate-50 px-3.5 py-1.5 text-xs font-medium text-slate-700 ring-1 ring-inset ring-red-600/10">
            VOID
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center rounded-md bg-gray-50 px-3.5 py-1.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Unknown
          </span>
        );
    }
  };

  const clearFilters = () => {
    setLoading(true);
    setFilters(BLANK_FILTERS);
    setVendors([]);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runJobQuery(BLANK_FILTERS, 0, DEFAULT_SIZE);
  };

  const updateFilter = ({ target: { name, value } }) => {
    let tmp = filters;
    tmp[name] = value;
    setFilters((prev) => ({ ...prev, [name]: value }));
    runJobQuery(tmp, page, size);
  };

  const handleVendorSearch = (query) => {
    if (query?.length > 2) {
      QueryVendors(query)
        .then((res) => {
          setVendors(res.data.results);
        })
        .catch((err) => {
          toast.error("Vendor search failed, please try again");
          setVendors([]);
        });
    }
  };

  const updateVendor = (v) => {
    let tmp = filters;
    if (v) {
      let qry = vendors.find((c) => c.vendorId === v);
      if (qry) {
        tmp.vendor = { vendorId: qry.vendorId, vendorCode: qry.vendorCode, vendorName: qry.vendorName };
      } else {
        tmp.vendor = { vendorId: null, vendorCode: null, vendorName: null };
      }
    } else {
      tmp.vendor = { vendorId: null, vendorCode: null, vendorName: null };
    }
    setFilters(tmp);
    setVendors(vendors.filter((c) => c.vendorId === v));
    runJobQuery(tmp, page, size);
  };

  const runJobQuery = (data, offset, limit) => {
    QueryAPInvoices({
      invoiceNo: data.invoiceNo,
      vendorId: data.vendor.vendorId,
      status: data.status,
      invoiceDate: data.invoiceDate,
      page: offset,
      limit: limit,
    })
      .then((res) => {
        setInvoices(res.data.results);
        setTotal(res.data.total);
        setSize(res.data.size);
        setPage(res.data.page);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Failed to fetch invoices");
        setInvoices([]);
        setTotal(0);
        setSize(DEFAULT_SIZE);
        setPage(0);
        setFilters(BLANK_FILTERS);
        setTimeout(() => setLoading(false), 700);
      });
  };

  const changePage = (pg, sz) => {
    setPage(pg);
    setSize(sz);
    setLoading(true);
    runJobQuery(filters, pg, sz);
  };

  const applyFilters = () => {
    setLoading(true);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runJobQuery(filters, 0, DEFAULT_SIZE);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  const updateStatus = (v) => {
    let tmp = filters;
    if (v) {
      tmp.status = v;
    } else {
      tmp.status = null;
    }
    setFilters(tmp);
    runJobQuery(tmp, page, size);
  };

  const disabledDate = (current) => {
    return current && current > dayjs();
  };

  const updateInvoiceDates = (dates) => {
    let tmp = filters;
    if (dates && dates.length === 2 && dates[0] && dates[1] && dayjs(dates[0]).isValid() && dayjs(dates[1]).isValid()) {
      tmp.invoiceDate = dates;
    } else {
      tmp.invoiceDate = [dayjs("01/01/2006", "MM/DD/YYYY"), dayjs()];
    }
    setFilters(tmp);
    runJobQuery(tmp, page, size);
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>AP Invoices | HTPS ERP</title>
      </Helmet>
      <div className="flex-grow flow-root w-full px-2 mt-3">
        <div className="-my-2">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-2">
              <div className="grid w-full h-full gap-6 mx-auto md:grid-cols-12">
                <div className="flex flex-col h-full col-span-3 gap-4">
                  <div className="flex flex-col items-start justify-start w-full gap-4 p-6 border border-gray-300 rounded-lg">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Filters</h3>
                    <div className="flex flex-col items-start justify-start w-full mt-4">
                      <label htmlFor="invoiceNo" className="pb-1 text-xs text-gray-600 uppercase">
                        Invoice No.
                      </label>
                      <Input
                        placeholder="Invoice No."
                        name="invoiceNo"
                        onChange={(e) => {
                          updateFilter(e);
                        }}
                        autoComplete="off"
                        value={filters.invoiceNo}
                        defaultValue={filters.invoiceNo}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="vendor" className="pb-1 text-xs text-gray-600 uppercase">
                        Vendor
                      </label>
                      <Select
                        placeholder="Search Vendors"
                        onChange={(v) => updateVendor(v)}
                        value={filters.vendor.vendorId}
                        options={(vendors || []).map((p) => ({
                          label: `${p.vendorCode} | ${p.vendorName}`,
                          value: p.vendorId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No vendors found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onSearch={handleVendorSearch}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="status" className="pb-1 text-xs text-gray-600 uppercase">
                        Invoice Status
                      </label>
                      <Select
                        placeholder="Invoice Status"
                        onChange={(v) => updateStatus(v)}
                        value={filters.status}
                        options={[
                          {
                            label: "All Statuses",
                            value: null,
                          },
                          {
                            label: "Received",
                            value: "received",
                          },
                          {
                            label: "Paid",
                            value: "paid",
                          },
                          {
                            label: "Overdue",
                            value: "overdue",
                          },
                          {
                            label: "VOID",
                            value: "void",
                          },
                          {
                            label: "Partially Paid",
                            value: "partiallyPaid",
                          },
                        ]}
                        className="w-full mb-2 font-sans"
                        controls={false}
                        filterOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        defaultActiveFirstOption={false}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="invoiceDate" className="pb-1 text-xs text-gray-600 uppercase">
                        Invoice Date
                      </label>
                      <DatePicker.RangePicker
                        className="w-full"
                        disabledDate={disabledDate}
                        value={[filters.invoiceDate[0], filters.invoiceDate[1]]}
                        defaultValue={[filters.invoiceDate[0], filters.invoiceDate[1]]}
                        format="MM/DD/YYYY"
                        onChange={(dates) => updateInvoiceDates(dates)}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full gap-2">
                    <SecondaryButton
                      callback={() => clearFilters()}
                      label="Clear Filters"
                      className="block py-2.5 transition-all duration-300 normal-case w-full"
                    />
                    <PrimaryButton
                      callback={() => applyFilters()}
                      label="Apply Filters"
                      className="block py-2.5 transition-all duration-300 bg-black hover:bg-black/80 normal-case w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full col-span-9 gap-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Search Results</h3>
                    <button
                      className="inline-flex items-center justify-center h-8 px-4 py-2 leading-none text-sm font-medium transition-colors rounded-md whitespace-nowrap ring-offset-[#000064] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#18181b] focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#18181b] text-[#fafafa] hover:bg-[#18181b]/90"
                      onClick={() => navigate("/ap/new")}
                    >
                      New AP Invoice
                    </button>
                  </div>
                  <Table
                    dataSource={invoices}
                    loading={loading}
                    className="w-full"
                    sticky={true}
                    pagination={{
                      total: total,
                      responsive: true,
                      hideOnSinglePage: true,
                      onChange: (p, s) => changePage(p - 1, s),
                      pageSize: size,
                      current: page + 1,
                      showSizeChanger: false,
                    }}
                    rowKey="invoiceId"
                  >
                    <Table.Column title="Status" dataIndex="invoiceStatus" key="invoiceStatus" render={(d) => renderStatus(d)} width={"100px"} align="center" />
                    <Table.Column
                      title="Invoice No."
                      dataIndex="invoiceNumber"
                      key="invoiceNumber"
                      width={"180px"}
                      align="left"
                      render={(v) => (
                        <Tooltip title={v}>
                          <p className="truncate cursor-pointer">{v}</p>
                        </Tooltip>
                      )}
                    />
                    <Table.Column
                      title="Invoice Date"
                      dataIndex="invoiceDate"
                      key="invoiceDate"
                      width={"180px"}
                      align="left"
                      render={(v, r) => (
                        <Tooltip
                          title={
                            <div className="flex flex-col items-start justify-start">
                              <p>Invoice date: {dayjs(v).format("MM/DD/YYYY")}</p>
                              <p>Due date: {dayjs(r.dueDate).format("MM/DD/YYYY")}</p>
                            </div>
                          }
                          placement="topLeft"
                        >
                          <p className="truncate cursor-pointer">{dayjs(v).format("MM/DD/YYYY")}</p>
                        </Tooltip>
                      )}
                    />
                    <Table.Column
                      title="Vendor"
                      dataIndex="vendorName"
                      key="vendorName"
                      render={(_v, r) => (
                        <Tooltip title={`${r?.vendorCode}${r?.vendorName?.length && ` | ${r.vendorName}`}`}>
                          <p className="truncate cursor-pointer">
                            {r?.vendorCode}
                            {r?.vendorName?.length > 0 && ` | ${r.vendorName}`}
                          </p>
                        </Tooltip>
                      )}
                    />
                    <Table.Column title="Total" dataIndex="total" key="total" render={(v) => formatCurrency(v)} align="right" width={"120px"} />
                    <Table.Column
                      title=""
                      dataIndex="invoiceId"
                      key="actions"
                      width={"80px"}
                      render={(v) => (
                        <SecondaryButton
                          callback={() => navigate(`/ap/${v}`)}
                          label="Open"
                          className="transition-all duration-300 border bg-white-smoke border-zinc-400 ring-0 hover:ring-0 hover:bg-zinc-100 hover:border-zinc-600 text-zinc-600 hover:text-zinc-900"
                        />
                      )}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllAPInvoices;
