import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import dayjs from "dayjs";
import { PrimaryButton, SecondaryButton } from "../../../components/buttons";
import { GetAllTimeOffRequests } from "../../../actions/admin";
import { Helmet } from "react-helmet-async";

const TimeOffOverview = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetAllTimeOffRequests()
        .then((res) => {
          setData(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error loading time-off entries");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const renderLine = (timeOff) => {
    return (
      <li className="grid items-center content-center justify-between w-full grid-cols-7 px-4 pt-0">
        <p className="text-sm font-medium text-gray-500 uppercase">{timeOff.employeeName}</p>
        <p className="text-sm font-medium text-gray-500 uppercase">{timeOff.status}</p>
        <p className="col-span-2 text-sm font-medium text-gray-500 uppercase">
          {dayjs(timeOff.startDate).format(timeOff.allDay ? "MM-DD-YYYY" : "MM-DD-YYYY HH:mm")} to{" "}
          {dayjs(timeOff.endDate).format(timeOff.allDay ? "MM-DD-YYYY" : "MM-DD-YYYY HH:mm")}
        </p>
        <p className="col-span-2 text-sm font-medium text-gray-500 uppercase">{timeOff.reason}</p>
        <button
          onClick={() => navigate(`/admin/timeOff/${timeOff.timeOffId}`)}
          className="px-4 py-2 text-xs font-semibold text-blue-600 uppercase rounded-md hover:text-blue-900 bg-gray-200/75"
        >
          Open
        </button>
      </li>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Time-Off Management | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-2 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Time-Off Management</p>
          <SecondaryButton label="New Time-Off Entry" callback={() => navigate("/admin/timeOff/new")} />
        </div>
        {loading ? (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
          </div>
        ) : data.length > 0 ? (
          <>
            <div className="grid items-center content-center justify-between w-full grid-cols-7 px-4 pb-4 mb-2 border-b border-gray-200">
              <p className="text-sm font-semibold text-gray-800 uppercase">Employee</p>
              <p className="text-sm font-semibold text-gray-800 uppercase">Status</p>
              <p className="col-span-2 text-sm font-semibold text-gray-800 uppercase">Date/Time</p>
              <p className="col-span-2 text-sm font-semibold text-gray-800 uppercase">Reason</p>
              <div />
            </div>
            {data.map((timeOff) => renderLine(timeOff))}
          </>
        ) : (
          <div className="flex flex-col items-center justify-center w-full gap-2 my-16">
            <p className="text-sm font-semibold text-gray-500 uppercase">No Time-Off Entries Added</p>
            <PrimaryButton label="Add a New Time-Off Entry" callback={() => navigate("/admin/timeOff/new")} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeOffOverview;
