import axiosInstance from "./axios";
import { validate } from "uuid";
import qs from "qs";
import dayjs from "dayjs";

export const GetAllInvoices = () => {
  return axiosInstance.get("/invoice");
};

export const GetOneInvoice = (invoiceId) => {
  return axiosInstance.get(`/invoice/${invoiceId}`);
};

export const CalculateTax = (data) => {
  return axiosInstance.post("/invoice/tax/calculate", data);
};

export const VoidInvoice = (invoiceId, data) => {
  return axiosInstance.put(`/invoice/${invoiceId}/void`, data);
};

export const UpdateInvoice = (invoiceId, data) => {
  return axiosInstance.put(`/invoice/${invoiceId}`, data);
};

export const SubmitInvoice = (invoiceId) => {
  return axiosInstance.put(`/invoice/${invoiceId}/submit`);
};

export const SendInvoice = (invoiceId, data) => {
  return axiosInstance.put(`/invoice/${invoiceId}/send`, data);
};

export const MarkPaid = (invoiceId, data) => {
  return axiosInstance.put(`/invoice/${invoiceId}/paid`, data);
};

export const GetInvoiceForPrint = (invoiceId) => {
  return axiosInstance.get(`/invoice/${invoiceId}/print`);
};

export const ReviseInvoice = (invoiceId) => {
  return axiosInstance.put(`/invoice/${invoiceId}/revise`);
};

export const LeanUpdateInvoice = (invoiceId, data) => {
  return axiosInstance.put(`/invoice/${invoiceId}/lean`, data);
};

export const AddInvoiceCredit = (invoiceId, data) => {
  return axiosInstance.post(`/invoice/${invoiceId}/credit`, data);
};

export const QueryInvoices = (data) => {
  let queryParams = {};
  if (data.customerId && validate(data.customerId)) {
    queryParams.customerId = data.customerId;
  }
  if (data.invoiceNo && data.invoiceNo.length > 2) {
    queryParams.invoiceNo = data.invoiceNo;
  }
  if (data.status && data.status.length > 0) {
    queryParams.status = data.status;
  }
  if (data.equipmentId && data.equipmentId.length > 2) {
    queryParams.equipmentId = data.equipmentId;
  }
  if (data.locationName && data.locationName.length > 2) {
    queryParams.locationName = data.locationName;
  }
  if (
    data.invoiceDate &&
    data.invoiceDate.length === 2 &&
    data.invoiceDate[0] &&
    data.invoiceDate[1] &&
    dayjs(data.invoiceDate[0]).isValid() &&
    dayjs(data.invoiceDate[1]).isValid()
  ) {
    if (!(dayjs(data.invoiceDate[0]).isSame(dayjs("01/01/2006", "MM/DD/YYYY"), "day") && dayjs(data.invoiceDate[1]).isSame(dayjs(), "day"))) {
      queryParams.invoiceDate = [dayjs(data.invoiceDate[0]).toJSON(), dayjs(data.invoiceDate[1]).toJSON()];
    }
  }
  if (
    data.total &&
    data.total.length === 2 &&
    data.total[0] &&
    data.total[1] &&
    !isNaN(data.total[0]) &&
    !isNaN(data.total[1]) &&
    data.total[0] >= 0 &&
    data.total[1] >= 0 &&
    data.total[0] <= data.total[1]
  ) {
    if (!(data.total[0] === 0 && data.total[1] === 100000)) {
      queryParams.total = data.total;
    }
  }
  if (data.page) {
    queryParams.page = data.page;
  }
  if (data.limit) {
    queryParams.limit = data.limit;
  }
  let queryString = qs.stringify(queryParams);
  return axiosInstance.get(`/invoice/query?${queryString}`);
};

export const RevertToJob = (invoiceId) => {
  return axiosInstance.put(`/invoice/${invoiceId}/returnToJob`);
};
