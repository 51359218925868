import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Check, ArrowSeparateVertical } from "iconoir-react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { toast } from "react-hot-toast";
import FormController from "../../components/FormController";
import { formatCurrency } from "../../components/tools";
import { newVendorAP, newVendorBasics, newVendorContact } from "../../data/vendorForms";
import { GetAccounts } from "../../actions/util";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import validator from "validator";
import { Combobox } from "@headlessui/react";
import { AddVendor } from "../../actions/ims";
import { Helmet } from "react-helmet-async";

const NewVendor = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [query, setQuery] = useState("");
  const [vendorBasics, setVendorBasics] = useState({
    vendorCode: "",
    vendorName: "",
  });
  const [vendorContact, setVendorContact] = useState({
    name: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    email: "",
    website: "",
    communication: "",
  });
  const [vendorAP, setVendorAP] = useState({
    apName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    apEmail: "",
  });
  const [vendorPayment, setVendorPayment] = useState({
    apTerms: "",
    creditLimit: 0,
    defaultAPAccount: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetAccounts()
        .then((res) => {
          setAccounts(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const steps = [
    {
      id: 0,
      name: "Basic Information",
      status: step === 0 ? "current" : "complete",
    },
    {
      id: 1,
      name: "Contact Information",
      status: step === 1 ? "current" : step > 1 ? "complete" : "incomplete",
    },
    {
      id: 2,
      name: "Accounts Payable",
      status: step === 2 ? "current" : step > 2 ? "complete" : "incomplete",
    },
    {
      id: 3,
      name: "Payment Information",
      status: step === 3 ? "current" : step > 3 ? "complete" : "incomplete",
    },
    { id: 4, name: "Overview", status: step === 4 ? "current" : "incomplete" },
  ];

  const submitStep = (data) => {
    if (step === 0) {
      setVendorBasics({
        vendorCode: data.vendorCode,
        vendorName: data.vendorName,
      });
      setStep(1);
    } else if (step === 1) {
      setVendorContact({
        name: data.name,
        address: data.address,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        phoneNumber: data.phoneNumber,
        email: data.email,
        website: data.website,
        communication: data.communication,
      });
      setStep(2);
    } else if (step === 2) {
      setVendorAP({
        apName: data.apName,
        address: data.address,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        phoneNumber: data.phoneNumber,
        apEmail: data.apEmail,
      });
      setStep(3);
    } else if (step === 3) {
      setVendorPayment({
        apTerms: data.apTerms,
        creditLimit: data.creditLimit,
        defaultAPAccount: data.defaultAPAccount,
      });
      setStep(4);
    }
  };

  const filteredAccounts =
    query === ""
      ? accounts
      : accounts.filter((acc) => {
          return acc.description.toLowerCase().includes(query.toLowerCase()) || acc.accountNo.toLowerCase().includes(query.toLowerCase());
        });

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderStep = () => {
    if (step === 0) {
      return <FormController onSubmit={submitStep} fields={newVendorBasics} values={vendorBasics} buttonText={"Next"} fullWidth={true} />;
    } else if (step === 1) {
      return <FormController onSubmit={submitStep} fields={newVendorContact} values={vendorContact} buttonText={"Next"} fullWidth={true} />;
    } else if (step === 2) {
      return <FormController onSubmit={submitStep} fields={newVendorAP} values={vendorAP} buttonText={"Next"} fullWidth={true} />;
    } else if (step === 3) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <form onSubmit={handleSubmit(submitStep)} className="flex flex-col items-start justify-start w-full gap-2" key={"vendorPaymentForm"}>
            <FormProvider {...formMethods}>
              <div className="flex flex-col items-start justify-start w-full">
                <label htmlFor="apTerms" className="pb-2 text-xs text-gray-600 uppercase">
                  Payment Terms
                </label>
                <select
                  className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  id="apTerms"
                  defaultValue={vendorPayment.apTerms}
                  {...register("apTerms", {
                    required: "Payment terms are required",
                    validate: (value) => value.length >= 3 || "Please select default payment terms",
                  })}
                >
                  <option hidden disabled value="">
                    Select One
                  </option>
                  <option key="net10" value="net10">
                    NET10
                  </option>
                  <option key="net20" value="net20">
                    NET20
                  </option>
                  <option key="net30" value="net30">
                    NET30
                  </option>
                  <option key="collectOnDelivery" value="collectOnDelivery">
                    Collect on Delivery
                  </option>
                  <option key="dueUponReceipt" value="dueUponReceipt">
                    Due Upon Receipt
                  </option>
                  <option key="prePay" value="prePay">
                    Pre-Pay
                  </option>
                </select>
                <ErrorMessage errors={errors} name="apTerms" as="p" className="px-1 pt-1 text-xs text-red-500" />
              </div>
              <div key="creditLimit" className="flex flex-col items-start justify-start w-full">
                <label htmlFor="creditLimit" className="pb-1 text-xs text-gray-600 uppercase">
                  Credit Limit ($)
                </label>
                <input
                  className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  type="number"
                  id="creditLimit"
                  defaultValue={vendorPayment.creditLimit}
                  placeholder="Credit Limit"
                  {...register("creditLimit", {
                    required: "Credit Limit is required",
                    validate: (value) => validator.isInt(value.toString()) || "Please enter a credit limit",
                  })}
                />
                <ErrorMessage errors={errors} name="creditLimit" as="p" className="px-1 pt-1 text-xs text-red-500" />
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <Controller
                  control={control}
                  defaultValue={null}
                  name="defaultAPAccount"
                  rules={{
                    required: "Default A/P Account is required",
                    validate: (value) => value.length >= 3 || "Please select default A/P Account",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Combobox as="div" onChange={(e) => onChange(e.accountId)} className="w-full">
                      <Combobox.Label className="block pb-2 text-xs text-gray-600 uppercase">Default A/P Account</Combobox.Label>
                      <div className="relative w-full">
                        <Combobox.Input
                          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          onChange={(event) => setQuery(event.target.value)}
                          displayValue={(acc) => acc?.accountNo + " | " + acc?.description}
                          placeholder="Select One"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                          <ArrowSeparateVertical className="w-5 h-5 text-gray-600" aria-hidden="true" />
                        </Combobox.Button>

                        {filteredAccounts.length > 0 && (
                          <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredAccounts.map((acc) => (
                              <Combobox.Option
                                key={acc.accountId}
                                value={acc}
                                className={({ active }) =>
                                  classNames("relative cursor-default select-none py-2 pl-3 pr-9", active ? "bg-blue-600 text-white" : "text-gray-900")
                                }
                              >
                                {({ active, selected }) => (
                                  <>
                                    <span className={classNames("block truncate", selected && "font-semibold")}>
                                      {acc.accountNo} | {acc.description}
                                    </span>

                                    {selected && (
                                      <span
                                        className={classNames("absolute inset-y-0 right-0 flex items-center pr-4", active ? "text-white" : "text-blue-600")}
                                      >
                                        <Check className="w-5 h-5" aria-hidden="true" />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        )}
                      </div>
                    </Combobox>
                  )}
                />
                <ErrorMessage errors={errors} name="defaultAPAccount" as="p" className="px-1 pt-1 text-xs text-red-500" />
              </div>
              <div className="flex flex-row items-center justify-end w-full mt-3">
                <button
                  type="submit"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-5 py-2 text-xs font-semibold uppercase text-white shadow-sm hover:bg-blue-500"
                >
                  {isSubmitting ? "Processing..." : "Next"}
                </button>
              </div>
            </FormProvider>
          </form>
        </div>
      );
    } else if (step === 4) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <div className="w-full px-6 py-1 pb-2 border-b border-gray-300 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Vendor Information</h3>
            <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">Basic information about the vendor</p>
          </div>
          <dl className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
              <dt className="text-sm font-medium leading-6 text-gray-900">Vendor Name:</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{vendorBasics.vendorName}</dd>
            </div>
            <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
              <dt className="text-sm font-medium leading-6 text-gray-900">Vendor Code:</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{vendorBasics.vendorCode}</dd>
            </div>
          </dl>
          <div className="w-full px-6 py-1 pb-2 border-b border-gray-300 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Contact Information</h3>
            <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">Information about the point of contact for the vendor</p>
          </div>
          <dl className="flex flex-row items-center justify-between w-full gap-4">
            <div className="flex flex-col items-start justify-start w-1/2 gap-2">
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">Name:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorContact.name.length > 0 ? vendorContact.name : "Not provided"}
                </dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">Address:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorContact.address.length > 0 ? vendorContact.address : "Not provided"}
                </dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">Apt / Suite / Unit:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorContact.address2.length > 0 ? vendorContact.address2 : "Not provided"}
                </dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">City:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorContact.city.length > 0 ? vendorContact.city : "Not provided"}
                </dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">State:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorContact.state.length > 0 ? vendorContact.state : "Not provided"}
                </dd>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-1/2 gap-2">
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">ZIP Code:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorContact.zip.length > 0 ? vendorContact.zip : "Not provided"}
                </dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">Phone Number:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorContact.phoneNumber.length > 0 ? vendorContact.phoneNumber : "Not provided"}
                </dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">Email:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorContact.email.length > 0 ? vendorContact.email : "Not provided"}
                </dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">Website:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorContact.website.length > 0 ? vendorContact.website : "Not provided"}
                </dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">Communication Preference:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
                  {vendorContact.communication.length > 0 ? vendorContact.communication : "Not provided"}
                </dd>
              </div>
            </div>
          </dl>
          <div className="w-full px-6 py-1 pb-2 border-b border-gray-300 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Accounts Payable Information</h3>
            <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">Information about the point of contact for the vendor's A/P</p>
          </div>
          <dl className="flex flex-row items-center justify-between w-full gap-4">
            <div className="flex flex-col items-start justify-start w-1/2 gap-2">
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">Name:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{vendorAP.apName.length > 0 ? vendorAP.apName : "Not provided"}</dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">Address:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorAP.address.length > 0 ? vendorAP.address : "Not provided"}
                </dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">Apt / Suite / Unit:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorAP.address2.length > 0 ? vendorAP.address2 : "Not provided"}
                </dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">City:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{vendorAP.city.length > 0 ? vendorAP.city : "Not provided"}</dd>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-1/2 gap-2">
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">State:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{vendorAP.state.length > 0 ? vendorAP.state : "Not provided"}</dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">ZIP Code:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{vendorAP.zip.length > 0 ? vendorAP.zip : "Not provided"}</dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">Phone Number:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorAP.phoneNumber.length > 0 ? vendorAP.phoneNumber : "Not provided"}
                </dd>
              </div>
              <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                <dt className="text-sm font-medium leading-6 text-gray-900">Email:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {vendorAP.apEmail.length > 0 ? vendorAP.apEmail : "Not provided"}
                </dd>
              </div>
            </div>
          </dl>
          <div className="w-full px-6 py-1 pb-2 border-b border-gray-300 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Payment Information</h3>
            <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">Payments arrangement information</p>
          </div>
          <dl className="flex flex-row items-center justify-between w-full mt-3">
            <div className="flex flex-row items-center justify-start gap-3 px-2 py-2">
              <dt className="text-sm font-medium leading-6 text-gray-900">Payment Terms:</dt>
              <dd className="text-sm leading-6 text-gray-700">{renderTerms(vendorPayment.apTerms)}</dd>
            </div>
            <div className="flex flex-row items-center justify-start gap-3 px-2 py-2">
              <dt className="text-sm font-medium leading-6 text-gray-900">Credit Limit:</dt>
              <dd className="text-sm leading-6 text-gray-700">{formatCurrency(vendorPayment.creditLimit)}</dd>
            </div>
            <div className="flex flex-row items-center justify-start gap-3 px-2 py-2">
              <dt className="text-sm font-medium leading-6 text-gray-900">Default A/P Account:</dt>
              <dd className="text-sm leading-6 text-gray-700">
                {accounts.find((acc) => acc.accountId === vendorPayment.defaultAPAccount)?.accountNo} |{" "}
                {accounts.find((acc) => acc.accountId === vendorPayment.defaultAPAccount)?.description}
              </dd>
            </div>
          </dl>
          <div className="flex flex-row items-center justify-end w-full pt-5 mt-3 border-t border-gray-300">
            <button
              onClick={() => createNewVendor()}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-5 py-2 text-xs font-semibold uppercase text-white shadow-sm hover:bg-blue-500"
            >
              Add Vendor
            </button>
          </div>
        </div>
      );
    }
  };

  const createNewVendor = () => {
    setLoading(true);
    let payload = {
      vendorCode: vendorBasics.vendorCode,
      vendorName: vendorBasics.vendorName,
      contact: vendorContact,
      accountsPayable: vendorAP,
      paymentInfo: vendorPayment,
    };
    AddVendor(payload)
      .then((res) => {
        toast.success("Vendor added successfully! You will be redirected shortly");
        navigate(`/vendors/${res.data.vendorId}`);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "An error has occurred. Please try again");
        setLoading(false);
      });
  };

  const renderTerms = (terms) => {
    switch (terms) {
      case "net10":
        return "NET-10";
      case "net20":
        return "NET-20";
      case "net30":
        return "NET-30";
      case "collectOnDelivery":
        return "Collect on Delivery";
      case "dueOnReceipt":
        return "Due on Receipt";
      case "prePay":
        return "Pre-Pay";
      default:
        return "NET-30";
    }
  };

  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting, errors, isDirty, dirtyFields },
  } = formMethods;

  return !loading ? (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>New Vendor | HTPS ERP</title>
      </Helmet>
      <nav aria-label="Progress" className="w-full mb-5">
        <ol role="list" className="border border-gray-300 divide-y divide-gray-300 rounded-md md:flex md:divide-y-0">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative md:flex md:flex-1">
              {step.status === "complete" ? (
                <div className="flex items-center w-full group">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-blue-600 rounded-full group-hover:bg-blue-800">
                      <Check className="w-6 h-6 text-white" aria-hidden="true" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                  </span>
                </div>
              ) : step.status === "current" ? (
                <div className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                  <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-blue-600 rounded-full">
                    <span className="text-blue-600">{step.id + 1}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-blue-600">{step.name}</span>
                </div>
              ) : (
                <div className="flex items-center cursor-pointer group">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900">{step.id + 1}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                  </span>
                </div>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  <div className="absolute top-0 right-0 hidden w-5 h-full md:block" aria-hidden="true">
                    <svg className="w-full h-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                      <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
      {step !== 4 ? (
        <div className="flex flex-col items-center justify-start flex-grow w-1/2 px-1 pb-3 mt-5 overflow-y-auto">{renderStep()}</div>
      ) : (
        <div className="flex flex-col items-center justify-start flex-grow w-3/4 px-1 pb-3 mt-5 overflow-y-auto">{renderStep()}</div>
      )}
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <p className="text-lg font-semibold text-gray-600 uppercase">Loading...</p>
    </div>
  );
};

export default NewVendor;
