import validator from "validator";

const testZip = (val) => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
};

const testPhone = (val) => {
  return /^((\+1|1)?( |-)?)?(\(([2-9])(?:\d(?!\5)\d|(?!\5)\d\d)\)|([2-9])(?:\d(?!\6)\d|(?!\6)\d\d))( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(val);
};

export const addEquipmentBasics = {
  customerId: {
    fieldName: "customerId",
    label: "Customer",
    inputType: "customer-picker",
    placeholder: "",
    defaultValue: "",
    config: {
      required: "Customer is required",
      validate: (value) => value.length >= 3 || "Please select a customer",
    },
  },
  customerEquipId: {
    fieldName: "customerEquipId",
    label: "Customer's Equipment ID",
    inputType: "text",
    placeholder: "Customer's Equipment ID",
    defaultValue: "",
    config: {
      required: "Customer's Equipment ID is Required",
      validate: (value) => value.length >= 3 || "Customer's Equipment ID must be at least 3 characters long",
    },
  },
  equipmentType: {
    fieldName: "equipmentType",
    label: "Equipment Type",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "generator", label: "Generator" },
      { value: "pressureWasher", label: "Pressure Washer" },
      { value: "truck", label: "Truck" },
      { value: "trailer", label: "Trailer" },
      { value: "welder", label: "Welder" },
      { value: "airCompressor", label: "Air Compressor" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: "Equipment type is required",
      validate: (value) => value.length >= 2 || "Please select the equipment type",
    },
  },
  fuelType: {
    fieldName: "fuelType",
    label: "Fuel Type",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "diesel", label: "Diesel" },
      { value: "gasoline", label: "Gasoline" },
      { value: "naturalGas", label: "Natural Gas" },
      { value: "propane", label: "Propane" },
      { value: "biFuel", label: "Bi-Fuel" },
      { value: "electricity", label: "Electricity" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: "Fuel Type is required",
      validate: (value) => value.length >= 2 || "Please select the fuel type",
    },
  },
  power: {
    fieldName: "power",
    label: "Power",
    inputType: "number",
    placeholder: "",
    defaultValue: "0",
    config: {
      required: "Power is required",
      validate: (value) => validator.isFloat(value.toString()) || "Please enter a power value",
    },
  },
  pwrUnit: {
    fieldName: "pwrUnit",
    label: "Power Unit",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "hp", label: "hp" },
      { value: "kw", label: "kW" },
      { value: "psi", label: "psi" },
    ],
    defaultValue: "",
    config: {
      required: "Power unit is required",
      validate: (value) => value.length >= 2 || "Please select the power unit",
    },
  },
  miles: {
    fieldName: "miles",
    label: "Miles/Hours",
    inputType: "number",
    placeholder: "",
    defaultValue: "0",
    config: {
      required: "Miles/Hours is required",
      validate: (value) => validator.isFloat(value.toString()) || "Please enter how many miles/hours the equipment has",
    },
  },
  milesUnit: {
    fieldName: "milesUnit",
    label: "Miles/Hours Unit",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "miles", label: "Miles" },
      { value: "hours", label: "Hours" },
    ],
    defaultValue: "",
    config: {
      required: "Miles/Hours unit is required",
      validate: (value) => value.length >= 2 || "Please select the miles/hours unit",
    },
  },
  location: {
    fieldName: "location",
    label: "Equipment Location",
    inputType: "text",
    placeholder: "Where is this piece of equipment based?",
    defaultValue: "",
    config: {
      required: "Equipment location is required",
      validate: (value) => value.length >= 2 || "Please enter a location for this piece of equipment",
    },
  },
  taxZip: {
    fieldName: "taxZip",
    label: "Sales Tax ZIP Code",
    inputType: "number",
    placeholder: "Sales tax ZIP code to be used with this equipment",
    defaultValue: "",
    config: {
      required: "Sales tax ZIP code is required",
      validate: (value) => testZip(value) || "Please enter a valid ZIP code",
    },
  },
};
export const newEquipmentBasics = {
  customerEquipId: {
    fieldName: "customerEquipId",
    label: "Customer's Equipment ID",
    inputType: "text",
    placeholder: "Customer's Equipment ID",
    defaultValue: "",
    config: {
      required: "Customer's Equipment ID is Required",
      validate: (value) => value.length >= 3 || "Customer's Equipment ID must be at least 3 characters long",
    },
  },
  equipmentType: {
    fieldName: "equipmentType",
    label: "Equipment Type",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "generator", label: "Generator" },
      { value: "pressureWasher", label: "Pressure Washer" },
      { value: "truck", label: "Truck" },
      { value: "trailer", label: "Trailer" },
      { value: "welder", label: "Welder" },
      { value: "airCompressor", label: "Air Compressor" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: "Equipment type is required",
      validate: (value) => value.length >= 2 || "Please select the equipment type",
    },
  },
  fuelType: {
    fieldName: "fuelType",
    label: "Fuel Type",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "diesel", label: "Diesel" },
      { value: "gasoline", label: "Gasoline" },
      { value: "naturalGas", label: "Natural Gas" },
      { value: "propane", label: "Propane" },
      { value: "biFuel", label: "Bi-Fuel" },
      { value: "electricity", label: "Electricity" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: "Fuel Type is required",
      validate: (value) => value.length >= 2 || "Please select the fuel type",
    },
  },
  power: {
    fieldName: "power",
    label: "Power",
    inputType: "number",
    placeholder: "",
    defaultValue: "0",
    config: {
      required: "Power is required",
      validate: (value) => validator.isFloat(value.toString()) || "Please enter a power value",
    },
  },
  pwrUnit: {
    fieldName: "pwrUnit",
    label: "Power Unit",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "hp", label: "hp" },
      { value: "kw", label: "kW" },
      { value: "psi", label: "psi" },
    ],
    defaultValue: "",
    config: {
      required: "Power unit is required",
      validate: (value) => value.length >= 2 || "Please select the power unit",
    },
  },
  miles: {
    fieldName: "miles",
    label: "Miles/Hours",
    inputType: "number",
    placeholder: "",
    defaultValue: "0",
    config: {
      required: "Miles/Hours is required",
      validate: (value) => validator.isFloat(value.toString()) || "Please enter how many miles/hours the equipment has",
    },
  },
  milesUnit: {
    fieldName: "milesUnit",
    label: "Miles/Hours Unit",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "miles", label: "Miles" },
      { value: "hours", label: "Hours" },
    ],
    defaultValue: "",
    config: {
      required: "Miles/Hours unit is required",
      validate: (value) => value.length >= 2 || "Please select the miles/hours unit",
    },
  },
  location: {
    fieldName: "location",
    label: "Equipment Location",
    inputType: "text",
    placeholder: "Where is this piece of equipment based?",
    defaultValue: "",
    config: {
      required: "Equipment location is required",
      validate: (value) => value.length >= 2 || "Please enter a location for this piece of equipment",
    },
  },
  taxZip: {
    fieldName: "taxZip",
    label: "Sales Tax ZIP Code",
    inputType: "number",
    placeholder: "Sales tax ZIP code to be used with this equipment",
    defaultValue: "",
    config: {
      required: "Sales tax ZIP code is required",
      validate: (value) => testZip(value) || "Please enter a valid ZIP code",
    },
  },
};

export const newEquipmentDetails = {
  make: {
    fieldName: "make",
    label: "Equipment Make",
    inputType: "text",
    placeholder: "Make of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Equipment Model",
    inputType: "text",
    placeholder: "Model of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  specNo: {
    fieldName: "specNo",
    label: "Equipment Spec. No.",
    inputType: "text",
    placeholder: "Spec. No. of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Equipment Serial No.",
    inputType: "text",
    placeholder: "Serial number of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  arrangement: {
    fieldName: "arrangement",
    label: "Equipment Arrangement No.",
    inputType: "text",
    placeholder: "Arrangement Number of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  status: {
    fieldName: "status",
    label: "Equipment Status",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "availableForUse", label: "Available for Use" },
      { value: "inoperable", label: "Inoperable" },
      { value: "retired", label: "Retired" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  size: {
    fieldName: "size",
    label: "Size",
    inputType: "text",
    placeholder: "Size",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  type: {
    fieldName: "type",
    label: "Type",
    inputType: "text",
    placeholder: "Type",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const openGeneratorDetails = {
  make: {
    fieldName: "make",
    label: "Equipment Make",
    inputType: "text",
    placeholder: "Make of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Equipment Model",
    inputType: "text",
    placeholder: "Model of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  specNo: {
    fieldName: "specNo",
    label: "Equipment Spec. No.",
    inputType: "text",
    placeholder: "Spec. No. of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Equipment Serial No.",
    inputType: "text",
    placeholder: "Serial number of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  arrangement: {
    fieldName: "arrangement",
    label: "Equipment Arrangement No.",
    inputType: "text",
    placeholder: "Arrangement Number of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  status: {
    fieldName: "status",
    label: "Equipment Status",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "availableForUse", label: "Available for Use" },
      { value: "inoperable", label: "Inoperable" },
      { value: "retired", label: "Retired" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const openPressureWasherDetails = {
  make: {
    fieldName: "make",
    label: "Equipment Make",
    inputType: "text",
    placeholder: "Make of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Equipment Model",
    inputType: "text",
    placeholder: "Model of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Equipment Serial No.",
    inputType: "text",
    placeholder: "Serial number of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const openTruckDetails = {
  make: {
    fieldName: "make",
    label: "Equipment Make",
    inputType: "text",
    placeholder: "Make of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Equipment Model",
    inputType: "text",
    placeholder: "Model of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Equipment Serial No.",
    inputType: "text",
    placeholder: "Serial number of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  status: {
    fieldName: "status",
    label: "Equipment Status",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "availableForUse", label: "Available for Use" },
      { value: "inoperable", label: "Inoperable" },
      { value: "retired", label: "Retired" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const openWelderDetails = {
  make: {
    fieldName: "make",
    label: "Equipment Make",
    inputType: "text",
    placeholder: "Make of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Equipment Model",
    inputType: "text",
    placeholder: "Model of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Equipment Serial No.",
    inputType: "text",
    placeholder: "Serial number of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  arrangement: {
    fieldName: "arrangement",
    label: "Equipment Arrangement No.",
    inputType: "text",
    placeholder: "Arrangement Number of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  status: {
    fieldName: "status",
    label: "Equipment Status",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "availableForUse", label: "Available for Use" },
      { value: "inoperable", label: "Inoperable" },
      { value: "retired", label: "Retired" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  type: {
    fieldName: "type",
    label: "Type",
    inputType: "text",
    placeholder: "Type",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const openAirCompressorDetails = {
  make: {
    fieldName: "make",
    label: "Equipment Make",
    inputType: "text",
    placeholder: "Make of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Equipment Model",
    inputType: "text",
    placeholder: "Model of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Equipment Serial No.",
    inputType: "text",
    placeholder: "Serial number of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  size: {
    fieldName: "size",
    label: "Size",
    inputType: "text",
    placeholder: "Size",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  type: {
    fieldName: "type",
    label: "Type",
    inputType: "text",
    placeholder: "Type",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const openOtherDetails = {
  make: {
    fieldName: "make",
    label: "Equipment Make",
    inputType: "text",
    placeholder: "Make of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Equipment Model",
    inputType: "text",
    placeholder: "Model of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Equipment Serial No.",
    inputType: "text",
    placeholder: "Serial number of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const newEquipmentEngine = {
  make: {
    fieldName: "make",
    label: "Equipment Make",
    inputType: "text",
    placeholder: "Make of the equipment",
    defaultValue: "",
    config: {
      required: "Equipment make is required",
      validate: (value) => value.length >= 2 || "Please enter a make for this piece of equipment",
    },
  },
  model: {
    fieldName: "model",
    label: "Equipment Model",
    inputType: "text",
    placeholder: "Model of the equipment",
    defaultValue: "",
    config: {
      required: "Equipment model is required",
      validate: (value) => value.length >= 2 || "Please enter a model for this piece of equipment",
    },
  },
  specNo: {
    fieldName: "specNo",
    label: "Equipment Spec. No.",
    inputType: "text",
    placeholder: "Spec. No. of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Equipment Serial Number",
    inputType: "text",
    placeholder: "Serial number of the equipment",
    defaultValue: "",
    config: {
      required: "Equipment serial number is required",
      validate: (value) => value.length >= 2 || "Please enter a serial number for this piece of equipment",
    },
  },
  codeNo: {
    fieldName: "codeNo",
    label: "Equipment Code. No.",
    inputType: "text",
    placeholder: "Code. No. of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelType: {
    fieldName: "fuelType",
    label: "Fuel Type",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "diesel", label: "Diesel" },
      { value: "gasoline", label: "Gasoline" },
      { value: "naturalGas", label: "Natural Gas" },
      { value: "propane", label: "Propane" },
      { value: "biFuel", label: "Bi-Fuel" },
      { value: "electricity", label: "Electricity" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  other: {
    fieldName: "other",
    label: "Other",
    inputType: "textarea-field",
    placeholder: "Other information about this equipment not covered above",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const newEquipmentATS = {
  make: {
    fieldName: "make",
    label: "Equipment Make",
    inputType: "text",
    placeholder: "Make of the equipment",
    defaultValue: "",
    config: {
      required: "Equipment make is required",
      validate: (value) => value.length >= 2 || "Please enter a make for this piece of equipment",
    },
  },
  model: {
    fieldName: "model",
    label: "Equipment Model",
    inputType: "text",
    placeholder: "Model of the equipment",
    defaultValue: "",
    config: {
      required: "Equipment model is required",
      validate: (value) => value.length >= 2 || "Please enter a model for this piece of equipment",
    },
  },
  specNo: {
    fieldName: "specNo",
    label: "Equipment Spec. No.",
    inputType: "text",
    placeholder: "Spec. No. of the equipment",
    defaultValue: "",
    config: {
      required: "Equipment spec. no. is required",
      validate: (value) => value.length >= 2 || "Please enter a spec. no. for this piece of equipment",
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Equipment Serial Number",
    inputType: "text",
    placeholder: "Serial number of the equipment",
    defaultValue: "",
    config: {
      required: "Equipment serial number is required",
      validate: (value) => value.length >= 2 || "Please enter a serial number for this piece of equipment",
    },
  },
  size: {
    fieldName: "size",
    label: "ATS Size",
    inputType: "text",
    placeholder: "ATS Size",
    defaultValue: "",
    config: {
      required: "ATS Size is required",
      validate: (value) => value.length >= 2 || "Please enter the ATS size for this piece of equipment",
    },
  },
  other: {
    fieldName: "other",
    label: "Other",
    inputType: "text",
    placeholder: "Other",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const newEquipmentContact = {
  firstName: {
    fieldName: "firstName",
    label: "First Name",
    inputType: "text",
    placeholder: "Contact's First Name",
    defaultValue: "",
    config: {
      required: "First Name is required",
      validate: (value) => value.length >= 2 || "Please enter a first name for this contact",
    },
  },
  lastName: {
    fieldName: "lastName",
    label: "Last Name",
    inputType: "text",
    placeholder: "Contact's Last Name",
    defaultValue: "",
    config: {
      required: "Last Name is required",
      validate: (value) => value.length >= 2 || "Please enter a last name for this contact",
    },
  },
  phoneNumber: {
    fieldName: "phoneNumber",
    label: "Phone Number",
    inputType: "phone",
    placeholder: "Phone Number",
    defaultValue: "",
    config: {
      required: "Contact's phone number is required",
      validate: (value) => testPhone(value) || "Please enter a valid phone number",
    },
  },
};

export const generatorDetails = {
  customerEquipId: {
    fieldName: "customerEquipId",
    label: "Customer's Equipment ID",
    inputType: "textfield",
    placeholder: "Customer's Equipment ID",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  make: {
    fieldName: "make",
    label: "Equipment Make",
    inputType: "textfield",
    placeholder: "Make of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Equipment Model",
    inputType: "textfield",
    placeholder: "Model of the equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  power: {
    fieldName: "power",
    label: "Power (kW)",
    inputType: "number-field",
    placeholder: "Generator's Power (kW)",
    defaultValue: "0",
    config: {
      required: false,
    },
  },
  specNo: {
    fieldName: "specNo",
    label: "Spec #",
    inputType: "textfield",
    placeholder: "Generator Spec #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Serial #",
    inputType: "textfield",
    placeholder: "Generator Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  arrangement: {
    fieldName: "arrangement",
    label: "Arrangement #",
    inputType: "textfield",
    placeholder: "Generator Arrangement #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelType: {
    fieldName: "fuelType",
    label: "Fuel Type",
    inputType: "select-field",
    placeholder: "Select One",
    options: [
      { value: "diesel", label: "Diesel" },
      { value: "gasoline", label: "Gasoline" },
      { value: "naturalGas", label: "Natural Gas" },
      { value: "propane", label: "Propane" },
      { value: "biFuel", label: "Bi-Fuel" },
      { value: "electricity", label: "Electricity" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  miles: {
    fieldName: "miles",
    label: "Hours",
    inputType: "number-field",
    placeholder: "Generator's Hours",
    defaultValue: "0",
    config: {
      required: false,
    },
  },
  location: {
    fieldName: "location",
    label: "Location",
    inputType: "textfield",
    placeholder: "Generator's Location",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  taxZip: {
    fieldName: "taxZip",
    label: "Sales Tax ZIP Code",
    inputType: "number-field",
    placeholder: "Sales tax ZIP code to be used with this equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  status: {
    fieldName: "status",
    label: "Equipment Status",
    inputType: "select-field",
    placeholder: "Select One",
    options: [
      { value: "availableForUse", label: "Available for Use" },
      { value: "inoperable", label: "Inoperable" },
      { value: "retired", label: "Retired" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  other: {
    fieldName: "other",
    label: "Other Information",
    inputType: "textarea-field",
    placeholder: "Any other information about this generator not covered above",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const engineForm = {
  make: {
    fieldName: "make",
    label: "Engine Make",
    inputType: "textfield",
    placeholder: "Make of the engine",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Engine Model",
    inputType: "textfield",
    placeholder: "Model of the engine",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  specNo: {
    fieldName: "specNo",
    label: "Spec #",
    inputType: "textfield",
    placeholder: "Engine Spec #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Serial #",
    inputType: "textfield",
    placeholder: "Engine Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  codeNo: {
    fieldName: "codeNo",
    label: "Code #",
    inputType: "textfield",
    placeholder: "Engine Code #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelType: {
    fieldName: "fuelType",
    label: "Engine Fuel Type",
    inputType: "select-field",
    placeholder: "Select One",
    options: [
      { value: "diesel", label: "Diesel" },
      { value: "gasoline", label: "Gasoline" },
      { value: "naturalGas", label: "Natural Gas" },
      { value: "propane", label: "Propane" },
      { value: "biFuel", label: "Bi-Fuel" },
      { value: "electricity", label: "Electricity" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  other: {
    fieldName: "other",
    label: "Other Information",
    inputType: "textarea-field",
    placeholder: "Any other information about this engine not covered above",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const atsForm = {
  make: {
    fieldName: "make",
    label: "ATS Make",
    inputType: "textfield",
    placeholder: "Make of the ATS",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "ATS Model",
    inputType: "textfield",
    placeholder: "Model of the ATS",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  specNo: {
    fieldName: "specNo",
    label: "Spec #",
    inputType: "textfield",
    placeholder: "ATS Spec #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Serial #",
    inputType: "textfield",
    placeholder: "ATS Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  size: {
    fieldName: "size",
    label: "Size",
    inputType: "textfield",
    placeholder: "ATS Size",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  status: {
    fieldName: "status",
    label: "Equipment Status",
    inputType: "select-field",
    placeholder: "Select One",
    options: [
      { value: "availableForUse", label: "Available for Use" },
      { value: "inoperable", label: "Inoperable" },
      { value: "retired", label: "Retired" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  other: {
    fieldName: "other",
    label: "Other Information",
    inputType: "textarea-field",
    placeholder: "Any other information about this engine not covered above",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const equipmentContact = {
  firstName: {
    fieldName: "firstName",
    label: "Contact's First Name",
    inputType: "textfield",
    placeholder: "First name of the contact person for this piece of equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lastName: {
    fieldName: "lastName",
    label: "Contact's Last Name",
    inputType: "textfield",
    placeholder: "Last name of the contact person for this piece of equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  phoneNumber: {
    fieldName: "phoneNumber",
    label: "Contact's Phone Number",
    inputType: "phoneNumber",
    placeholder: "Phone number of the contact person for this piece of equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const trailerDetails = {
  customerEquipId: {
    fieldName: "customerEquipId",
    label: "Customer's Equipment ID",
    inputType: "textfield",
    placeholder: "Customer's Equipment ID",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  make: {
    fieldName: "make",
    label: "Trailer Make",
    inputType: "textfield",
    placeholder: "Make of the trailer",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Trailer Model",
    inputType: "textfield",
    placeholder: "Model of the trailer",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Serial #",
    inputType: "textfield",
    placeholder: "Trailer Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  year: {
    fieldName: "year",
    label: "Year",
    inputType: "textfield",
    placeholder: "Trailer Year",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  taxZip: {
    fieldName: "taxZip",
    label: "Sales Tax ZIP Code",
    inputType: "number-field",
    placeholder: "Sales tax ZIP code to be used with this equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  status: {
    fieldName: "status",
    label: "Equipment Status",
    inputType: "select-field",
    placeholder: "Select One",
    options: [
      { value: "availableForUse", label: "Available for Use" },
      { value: "inoperable", label: "Inoperable" },
      { value: "retired", label: "Retired" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  other: {
    fieldName: "other",
    label: "Other Information",
    inputType: "textarea-field",
    placeholder: "Any other information about this trailer not covered above",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const truckDetails = {
  customerEquipId: {
    fieldName: "customerEquipId",
    label: "Customer's Equipment ID",
    inputType: "textfield",
    placeholder: "Customer's Equipment ID",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  make: {
    fieldName: "make",
    label: "Truck Make",
    inputType: "textfield",
    placeholder: "Make of the truck",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Truck Model",
    inputType: "textfield",
    placeholder: "Model of the truck",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Serial #",
    inputType: "textfield",
    placeholder: "Truck's Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  year: {
    fieldName: "year",
    label: "Year",
    inputType: "textfield",
    placeholder: "Truck's Year",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  miles: {
    fieldName: "miles",
    label: "Miles",
    inputType: "number-field",
    placeholder: "Truck's Mileage",
    defaultValue: "0",
    config: {
      required: false,
    },
  },
  taxZip: {
    fieldName: "taxZip",
    label: "Sales Tax ZIP Code",
    inputType: "number-field",
    placeholder: "Sales tax ZIP code to be used with this truck",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  status: {
    fieldName: "status",
    label: "Equipment Status",
    inputType: "select-field",
    placeholder: "Select One",
    options: [
      { value: "availableForUse", label: "Available for Use" },
      { value: "inoperable", label: "Inoperable" },
      { value: "retired", label: "Retired" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  other: {
    fieldName: "other",
    label: "Other Information",
    inputType: "textarea-field",
    placeholder: "Any other information about this truck not covered above",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const pressureWasherDetails = {
  make: {
    fieldName: "make",
    label: "Pressure Washer's Make",
    inputType: "textfield",
    placeholder: "Make of the pressure washer",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Pressure Washer's Model",
    inputType: "textfield",
    placeholder: "Model of the pressure washer",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Serial #",
    inputType: "textfield",
    placeholder: "Pressure Washer's Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  power: {
    fieldName: "power",
    label: "PSI",
    inputType: "number-field",
    placeholder: "Pressure Washer's PSI",
    defaultValue: "0",
    config: {
      required: false,
    },
  },
  pumpInfo: {
    fieldName: "pumpInfo",
    label: "Pump Info",
    inputType: "textfield",
    placeholder: "Information about the pressure washer's pump",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelType: {
    fieldName: "fuelType",
    label: "Fuel Type",
    inputType: "select-field",
    placeholder: "Select One",
    options: [
      { value: "diesel", label: "Diesel" },
      { value: "gasoline", label: "Gasoline" },
      { value: "naturalGas", label: "Natural Gas" },
      { value: "propane", label: "Propane" },
      { value: "biFuel", label: "Bi-Fuel" },
      { value: "electricity", label: "Electricity" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  taxZip: {
    fieldName: "taxZip",
    label: "Sales Tax ZIP Code",
    inputType: "number-field",
    placeholder: "Sales tax ZIP code to be used with this truck",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const airCompressorDetails = {
  make: {
    fieldName: "make",
    label: "Air Compressor's Make",
    inputType: "textfield",
    placeholder: "Make of the air compressor",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Air Compressor's Model",
    inputType: "textfield",
    placeholder: "Model of the air compressor",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Serial #",
    inputType: "textfield",
    placeholder: "Air Compressor's Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelType: {
    fieldName: "fuelType",
    label: "Fuel Type",
    inputType: "select-field",
    placeholder: "Select One",
    options: [
      { value: "diesel", label: "Diesel" },
      { value: "gasoline", label: "Gasoline" },
      { value: "naturalGas", label: "Natural Gas" },
      { value: "propane", label: "Propane" },
      { value: "biFuel", label: "Bi-Fuel" },
      { value: "electricity", label: "Electricity" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  power: {
    fieldName: "power",
    label: "PSI",
    inputType: "number-field",
    placeholder: "Pressure Washer's PSI",
    defaultValue: "0",
    config: {
      required: false,
    },
  },
  size: {
    fieldName: "size",
    label: "Size",
    inputType: "textfield",
    placeholder: "Air Compressor's Size",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  type: {
    fieldName: "type",
    label: "Type",
    inputType: "textfield",
    placeholder: "Type of the air compressor",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  location: {
    fieldName: "location",
    label: "Location",
    inputType: "textfield",
    placeholder: "Location of the air compressor",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  taxZip: {
    fieldName: "taxZip",
    label: "Sales Tax ZIP Code",
    inputType: "number-field",
    placeholder: "Sales tax ZIP code to be used with this truck",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  other: {
    fieldName: "other",
    label: "Other Information",
    inputType: "textarea-field",
    placeholder: "Any other information about this truck not covered above",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const welderDetails = {
  make: {
    fieldName: "make",
    label: "Welder's Make",
    inputType: "textfield",
    placeholder: "Make of the welder",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Welder's Model",
    inputType: "textfield",
    placeholder: "Model of the welder",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Serial #",
    inputType: "textfield",
    placeholder: "Welder's Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  arrangement: {
    fieldName: "arrangement",
    label: "Arrangement #",
    inputType: "textfield",
    placeholder: "Welder's Arrangement #",
    defaultValue: "0",
    config: {
      required: false,
    },
  },
  fuelType: {
    fieldName: "fuelType",
    label: "Engine Fuel Type",
    inputType: "select-field",
    placeholder: "Select One",
    options: [
      { value: "diesel", label: "Diesel" },
      { value: "gasoline", label: "Gasoline" },
      { value: "naturalGas", label: "Natural Gas" },
      { value: "propane", label: "Propane" },
      { value: "biFuel", label: "Bi-Fuel" },
      { value: "electricity", label: "Electricity" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  type: {
    fieldName: "type",
    label: "Type",
    inputType: "textfield",
    placeholder: "Type of the welder",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  miles: {
    fieldName: "miles",
    label: "Hours",
    inputType: "number-field",
    placeholder: "Welder's Hours",
    defaultValue: "0",
    config: {
      required: false,
    },
  },
  status: {
    fieldName: "status",
    label: "Equipment Status",
    inputType: "select-field",
    placeholder: "Select One",
    options: [
      { value: "availableForUse", label: "Available for Use" },
      { value: "inoperable", label: "Inoperable" },
      { value: "retired", label: "Retired" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  location: {
    fieldName: "location",
    label: "Location",
    inputType: "textfield",
    placeholder: "Location of the welder",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  taxZip: {
    fieldName: "taxZip",
    label: "Sales Tax ZIP Code",
    inputType: "number-field",
    placeholder: "Sales tax ZIP code to be used with this truck",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const otherEqDetails = {
  make: {
    fieldName: "make",
    label: "Make",
    inputType: "textfield",
    placeholder: "Equipment's Make",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  model: {
    fieldName: "model",
    label: "Model",
    inputType: "textfield",
    placeholder: "Equipment's Model",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serialNo: {
    fieldName: "serialNo",
    label: "Serial #",
    inputType: "textfield",
    placeholder: "Equipment's Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  miles: {
    fieldName: "miles",
    label: "Miles / Hours",
    inputType: "number-field",
    placeholder: "Equipment's Mileage / Hours",
    defaultValue: "0",
    config: {
      required: false,
    },
  },
  fuelType: {
    fieldName: "fuelType",
    label: "Fuel Type",
    inputType: "select-field",
    placeholder: "Select One",
    options: [
      { value: "diesel", label: "Diesel" },
      { value: "gasoline", label: "Gasoline" },
      { value: "naturalGas", label: "Natural Gas" },
      { value: "propane", label: "Propane" },
      { value: "biFuel", label: "Bi-Fuel" },
      { value: "electricity", label: "Electricity" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  taxZip: {
    fieldName: "taxZip",
    label: "Sales Tax ZIP Code",
    inputType: "number-field",
    placeholder: "Sales tax ZIP code to be used with this truck",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  year: {
    fieldName: "year",
    label: "Year",
    inputType: "textfield",
    placeholder: "Model Year of the Equipment",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  status: {
    fieldName: "status",
    label: "Equipment Status",
    inputType: "select-field",
    placeholder: "Select One",
    options: [
      { value: "availableForUse", label: "Available for Use" },
      { value: "inoperable", label: "Inoperable" },
      { value: "retired", label: "Retired" },
    ],
    defaultValue: "",
    config: {
      required: false,
    },
  },
  other: {
    fieldName: "other",
    label: "Other",
    inputType: "textarea-field",
    placeholder: "Other information about this equipment not covered above",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  customerEquipId: {
    fieldName: "customerEquipId",
    label: "Customer's Equipment ID",
    inputType: "textfield",
    placeholder: "Customer's Equipment ID",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};
