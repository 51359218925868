import { validate } from "uuid";
import axiosInstance from "./axios";
import qs from "qs";
import dayjs from "dayjs";

export const GetAllVendors = (page) => {
  return axiosInstance.get(`/ims/vendors?page=${page}`);
};

export const GetVendorsList = () => {
  return axiosInstance.get(`/ims/vendors/list`);
};

export const AddVendor = (data) => {
  return axiosInstance.post("/ims/vendors", data);
};

export const GetAllParts = (page) => {
  return axiosInstance.get(`/ims/parts?page=${page}`);
};

export const GetPartsList = () => {
  return axiosInstance.get(`/ims/parts/list`);
};

export const GetExpandedPartsList = () => {
  return axiosInstance.get(`/ims/parts/list/expanded`);
};

export const GetDepartments = () => {
  return axiosInstance.get("/inventory/departments");
};

export const AddNewPart = (data) => {
  return axiosInstance.post("/inventory", data);
};

export const GetAllPurchaseOrders = () => {
  return axiosInstance.get("/ims/purchaseOrders");
};

export const GetPOsLean = () => {
  return axiosInstance.get("/ims/purchaseOrders/lean");
};

export const GetOnePurchaseOrder = (poId) => {
  return axiosInstance.get(`/ims/purchaseOrders/${poId}`);
};

export const UpdatePurchaseOrder = (poId, data) => {
  return axiosInstance.put(`/ims/purchaseOrders/${poId}`, data);
};

export const VOIDPurchaseOrder = (poId, data) => {
  return axiosInstance.put(`/ims/purchaseOrders/${poId}/void`, data);
};

export const DenyPO = (poId, data) => {
  return axiosInstance.put(`/ims/purchaseOrders/${poId}/deny`, data);
};

export const ApprovePO = (poId, data) => {
  return axiosInstance.put(`/ims/purchaseOrders/${poId}/approve`, data);
};

export const SubmitPO = (poId, data) => {
  return axiosInstance.put(`/ims/purchaseOrders/${poId}/submit`, data);
};

export const ReceivePO = (poId, data) => {
  return axiosInstance.put(`/ims/purchaseOrders/${poId}/receive`, data);
};

export const SubmitPOInvoice = (poId, data) => {
  return axiosInstance.put(`/ims/purchaseOrders/${poId}/submitInvoice`, data);
};

export const GetAllAPInvoices = () => {
  return axiosInstance.get("/ims/ap");
};

export const GetOneAP = (apId) => {
  return axiosInstance.get(`/ims/ap/${apId}`);
};

export const UpdateAPInvoice = (apId, data) => {
  return axiosInstance.put(`/ims/ap/${apId}`, data);
};

export const LogAPPayment = (apId, data) => {
  return axiosInstance.put(`/ims/ap/${apId}/payment`, data);
};

export const SubmitAPCheck = (apId, paymentId, data) => {
  return axiosInstance.put(`/ims/ap/${apId}/payment/${paymentId}`, data);
};

export const CreateAPInvoice = (data) => {
  return axiosInstance.post("/ims/ap", data);
};

export const GetOneVendor = (vendorId) => {
  return axiosInstance.get(`/ims/vendors/${vendorId}`);
};

export const UpdateVendor = (vendorId, data) => {
  return axiosInstance.put(`/ims/vendors/${vendorId}`, data);
};

export const GetOnePart = (partId) => {
  return axiosInstance.get(`/ims/parts/${partId}`);
};

export const UpdatePart = (partId, data) => {
  return axiosInstance.put(`/ims/parts/${partId}`, data);
};

export const UpdatePartStock = (partId, data) => {
  return axiosInstance.put(`/ims/parts/${partId}/stock`, data);
};

export const GetAllEquipment = (page) => {
  return axiosInstance.get(`/ims/equipment?page=${page}`);
};

export const GetOneEquipment = (equipmentId) => {
  return axiosInstance.get(`/ims/equipment/${equipmentId}`);
};

export const UpdateEquipmentBasics = (equipmentId, data) => {
  return axiosInstance.put(`/ims/equipment/${equipmentId}/basics`, data);
};

export const UpdateEquipmentDetails = (equipmentId, data) => {
  return axiosInstance.put(`/ims/equipment/${equipmentId}/details`, data);
};

export const UpdateEquipmentEngine = (equipmentId, data) => {
  return axiosInstance.put(`/ims/equipment/${equipmentId}/engine`, data);
};

export const AddEquipmentATS = (equipmentId, data) => {
  return axiosInstance.put(`/ims/equipment/${equipmentId}/ats/add`, data);
};

export const UpdateEquipmentATS = (equipmentId, atsId, data) => {
  return axiosInstance.put(`/ims/equipment/${equipmentId}/ats/${atsId}`, data);
};

export const RemoveEquipmentATS = (equipmentId, atsId) => {
  return axiosInstance.delete(`/ims/equipment/${equipmentId}/ats/delete/${atsId}`);
};

export const UpdateEquipmentContact = (equipmentId, data) => {
  return axiosInstance.put(`/ims/equipment/${equipmentId}/contact`, data);
};

export const AddEquipmentNote = (equipmentId, data) => {
  return axiosInstance.put(`/ims/equipment/${equipmentId}/note`, data);
};

export const AddEquipmentPhoto = (equipmentId, data) => {
  return axiosInstance.put(`/ims/equipment/${equipmentId}/photo`, data);
};

export const AddEquipmentService = (equipmentId, data) => {
  return axiosInstance.put(`/ims/equipment/${equipmentId}/service`, data);
};

export const AddServiceNote = (equipmentId, serviceId, data) => {
  return axiosInstance.put(`/ims/equipment/${equipmentId}/${serviceId}/note`, data);
};

export const UpdateEquipmentService = (equipmentId, serviceId, data) => {
  return axiosInstance.put(`/ims/equipment/${equipmentId}/${serviceId}`, data);
};

export const GetEquipmentHistory = (equipmentId) => {
  return axiosInstance.get(`/ims/equipment/${equipmentId}/history`);
};

export const GetNewPOData = () => {
  return axiosInstance.get("/ims/purchaseOrders/new");
};

export const CreateAPO = (data) => {
  return axiosInstance.post("/ims/purchaseOrders", data);
};

export const UpdateTransmittalStatus = (transmittalId, data) => {
  return axiosInstance.put(`/ims/transmittals/${transmittalId}`, data);
};

export const GetDepartmentsAndVendors = () => {
  return axiosInstance.get("/ims/vendorsAndDepartments");
};

export const GetAccounts = () => {
  return axiosInstance.get("/ims/accounts");
};

export const AddAPAttachments = (apId, data) => {
  return axiosInstance.put(`/ims/ap/${apId}/files`, data);
};

export const VoidInvoice = (apId) => {
  return axiosInstance.post(`/ims/ap/${apId}/void`);
};

export const AddSerialPartToAPInvoice = (apId, data) => {
  return axiosInstance.put(`/ims/ap/${apId}/serialPart`, data);
};

export const AddNonSerialPartToAPInvoice = (apId, data) => {
  return axiosInstance.put(`/ims/ap/${apId}/nonSerialPart`, data);
};

export const RemoveAPInvoiceItem = (apId, itemId) => {
  return axiosInstance.delete(`/ims/ap/${apId}/delete/${itemId}`);
};

export const AddPONote = (poId, data) => {
  return axiosInstance.put(`/ims/purchaseOrders/${poId}/note`, data);
};

export const RemovePOItem = (poId, itemId) => {
  return axiosInstance.delete(`/ims/purchaseOrders/${poId}/remove/${itemId}`);
};

export const QueryParts = (query) => {
  let search = `/ims/query/part?search=${query}`;
  search = encodeURI(search);
  return axiosInstance.get(search);
};

export const DeletePart = (partId) => {
  return axiosInstance.delete(`/ims/parts/${partId}`);
};

export const QueryEquipment = (query, customerId) => {
  let queryParams = {
    search: query,
  };
  if (customerId && validate(customerId)) {
    queryParams.customerId = customerId;
  }
  let queryString = qs.stringify(queryParams);
  return axiosInstance.get(`/ims/equipment/query?${queryString}`);
};

export const AdvancedPartsQuery = (query) => {
  let queryParams = {};
  if (query.search && query.search.length > 0) {
    queryParams.search = query.search;
  }
  if (query.department && query.department.length > 0 && validate(query.department)) {
    queryParams.department = query.department;
  }
  if (query.manufacturer && query.manufacturer.length > 0) {
    queryParams.manufacturer = query.manufacturer;
  }
  if (query.limit) {
    queryParams.limit = query.limit;
  }
  if (query.page) {
    queryParams.page = query.page;
  }
  let queryString = qs.stringify(queryParams);
  return axiosInstance.get(`/ims/parts/query?${queryString}`);
};

export const ApplyCreditToInvoice = (apId, data) => {
  return axiosInstance.post(`/ims/ap/${apId}/credit`, data);
};

export const UpdateAPLineItem = (apId, itemId, data) => {
  return axiosInstance.put(`/ims/ap/${apId}/item/${itemId}`, data);
};

export const UpdateAPCredit = (apId, creditId, data) => {
  return axiosInstance.put(`/ims/ap/${apId}/credit/${creditId}`, data);
};

export const QueryVendors = (query) => {
  let search = `/ims/vendors/query?search=${query}`;
  search = encodeURI(search);
  return axiosInstance.get(search);
};

export const QueryAPInvoices = (query) => {
  let queryParams = {};
  if (query.invoiceNo && query.invoiceNo.length > 0) {
    queryParams.invoiceNo = query.invoiceNo;
  }
  if (query.vendorId && query.vendorId.length > 0 && validate(query.vendorId)) {
    queryParams.vendorId = query.vendorId;
  }
  if (query.status && query.status.length > 0) {
    queryParams.status = query.status;
  }
  if (
    query.invoiceDate &&
    query.invoiceDate.length === 2 &&
    query.invoiceDate[0] &&
    query.invoiceDate[1] &&
    dayjs(query.invoiceDate[0]).isValid() &&
    dayjs(query.invoiceDate[1]).isValid()
  ) {
    if (!(dayjs(query.invoiceDate[0]).isSame(dayjs("01/01/2006", "MM/DD/YYYY"), "day") && dayjs(query.invoiceDate[1]).isSame(dayjs(), "day"))) {
      queryParams.invoiceDate = [dayjs(query.invoiceDate[0]).toJSON(), dayjs(query.invoiceDate[1]).toJSON()];
    }
  }
  if (query.limit) {
    queryParams.limit = query.limit;
  }
  if (query.page) {
    queryParams.page = query.page;
  }
  let queryString = qs.stringify(queryParams);
  return axiosInstance.get(`/ims/ap/query?${queryString}`);
};

export const QueryPurchaseOrders = (query) => {
  let queryParams = {};
  if (query.poNo && query.poNo.length > 0) {
    queryParams.poNo = query.poNo;
  }
  if (query.vendorId && query.vendorId.length > 0 && validate(query.vendorId)) {
    queryParams.vendorId = query.vendorId;
  }
  if (query.jobId && query.jobId.length > 0 && validate(query.jobId)) {
    queryParams.jobId = query.jobId;
  }
  if (query.locationId && query.locationId.length > 0 && validate(query.locationId)) {
    queryParams.locationId = query.locationId;
  }
  if (query.status && query.status.length > 0) {
    queryParams.status = query.status;
  }
  if (query.limit) {
    queryParams.limit = query.limit;
  }
  if (query.page) {
    queryParams.page = query.page;
  }
  let queryString = qs.stringify(queryParams);
  return axiosInstance.get(`/ims/purchaseOrders/query?${queryString}`);
};

export const AdvancedEquipmentQuery = (query) => {
  let queryParams = {};
  if (query.equipmentType && query.equipmentType.length > 0) {
    queryParams.equipmentType = query.equipmentType;
  }
  if (query.make && query.make.length > 0) {
    queryParams.make = query.make;
  }
  if (query.model && query.model.length > 0) {
    queryParams.model = query.model;
  }
  if (query.serialNo && query.serialNo.length > 0) {
    queryParams.serialNo = query.serialNo;
  }
  if (query.customerId && query.customerId.length > 0 && validate(query.customerId)) {
    queryParams.customerId = query.customerId;
  }
  if (query.status && query.status.length > 0) {
    queryParams.status = query.status;
  }
  if (query.limit) {
    queryParams.limit = query.limit;
  }
  if (query.page) {
    queryParams.page = query.page;
  }
  let queryString = qs.stringify(queryParams);
  return axiosInstance.get(`/ims/equipment/advancedQuery?${queryString}`);
};

export const QueryEquipmentHistory = (query) => {
  let queryParams = {};
  if (query.description && query.description.length > 0) {
    queryParams.description = query.description;
  }
  if (query.type && query.type.length > 0) {
    queryParams.type = query.type;
  }
  if (query.status && query.status.length > 0) {
    queryParams.status = query.status;
  }
  if (query.limit) {
    queryParams.limit = query.limit;
  }
  if (query.page) {
    queryParams.page = query.page;
  }
  let queryString = qs.stringify(queryParams);
  return axiosInstance.get(`/ims/equipment/${query.equipmentId}/queryHistory?${queryString}`);
};
