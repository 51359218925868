import { useState, useEffect } from "react";
import { Input, InputNumber, Select, Drawer } from "antd";
import { MaskedInput } from "antd-mask-input";
import { useNavigate } from "react-router-dom";
import States from "../../components/stateList";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { toast } from "react-hot-toast";
import validator from "validator";
import { UpdateProfile, UpdateEmergencyContact } from "../../actions/auth";
import { Helmet } from "react-helmet-async";

const Settings = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [drawer, setDrawer] = useState(false);
  const [values, setValues] = useState({
    firstName: authState.user.firstName,
    lastName: authState.user.lastName,
    email: authState.user.email,
    phoneNumber: authState.user.phoneNumber,
    address: authState.user.address,
    address2: authState.user.address2,
    city: authState.user.city,
    state: authState.user.state,
    zip: authState.user.zip,
    title: authState.user.title,
    location: authState.user.location,
  });
  const [ec, setEC] = useState({
    name: authState.user.emergencyContact.name,
    relationship: authState.user.emergencyContact.relationship,
    phoneNumber: authState.user.emergencyContact.phoneNumber,
  });

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (authState.user.location && authState.user.location.length > 0) {
        let locs = {
          Augusta: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf",
          "North Augusta": "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
        };
        setValues({ ...values, location: locs[authState.user.location] });
        setTimeout(() => setLoading(false), 700);
      } else {
        setTimeout(() => setLoading(false), 700);
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const updateProfile = () => {
    if (values.firstName.length < 3) {
      toast.error("Please enter a valid first name");
    } else if (values.lastName.length < 3) {
      toast.error("Please enter a valid last name");
    } else if (!validator.isMobilePhone(values.phoneNumber)) {
      toast.error("Please enter a valid phone number");
    } else if (values.address.length < 3) {
      toast.error("Please enter a valid address");
    } else if (values.city.length < 3) {
      toast.error("Please enter a valid city");
    } else if (values.state.length !== 2) {
      toast.error("Please select a state");
    } else if (values.zip.toString().length !== 5) {
      toast.error("Please enter a valid ZIP code");
    } else if (values.location.length < 20) {
      toast.error("Please select a location");
    } else {
      setLoading(true);
      UpdateProfile(values)
        .then((res) => {
          let payload = {
            token: localStorage.getItem("auth-token"),
            email: res.data.user.email,
            user: res.data.user,
          };
          authDispatch({
            type: "valid-login",
            payload: payload,
          });
          toast.success("Profile updated successfully");
          setValues({
            firstName: res.data.user.firstName,
            lastName: res.data.user.lastName,
            email: res.data.user.email,
            phoneNumber: res.data.user.phoneNumber,
            address: res.data.user.address,
            address2: res.data.user.address2,
            city: res.data.user.city,
            state: res.data.user.state,
            zip: res.data.user.zip,
            title: res.data.user.title,
            location: res.data.user.location,
          });
          setEC({
            name: res.data.user.emergencyContact.name,
            relationship: res.data.user.emergencyContact.relationship,
            phoneNumber: res.data.user.emergencyContact.phoneNumber,
          });
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating your profile. Please try again");
          setLoading(false);
        });
    }
  };

  const closeDrawer = () => {
    setDrawer(false);
    setEC({
      name: authState.user.emergencyContact.name,
      relationship: authState.user.emergencyContact.relationship,
      phoneNumber: authState.user.emergencyContact.phoneNumber,
    });
  };

  const updateEmergencyContact = () => {
    if (ec.name.length < 3) {
      toast.error("Please enter a valid first name");
    } else if (ec.relationship.length < 3) {
      toast.error("Please enter a valid relationship");
    } else if (!validator.isMobilePhone(ec.phoneNumber)) {
      toast.error("Please enter a valid phone number");
    } else {
      setLoading(true);
      UpdateEmergencyContact(ec)
        .then((res) => {
          closeDrawer();
          let payload = {
            token: localStorage.getItem("auth-token"),
            email: res.data.user.email,
            user: res.data.user,
          };
          authDispatch({
            type: "valid-login",
            payload: payload,
          });
          toast.success("Emergency contact information updated successfully");
          setValues({
            firstName: res.data.user.firstName,
            lastName: res.data.user.lastName,
            email: res.data.user.email,
            phoneNumber: res.data.user.phoneNumber,
            address: res.data.user.address,
            address2: res.data.user.address2,
            city: res.data.user.city,
            state: res.data.user.state,
            zip: res.data.user.zip,
            title: res.data.user.title,
            location: res.data.user.location,
          });
          setEC({
            name: res.data.user.emergencyContact.name,
            relationship: res.data.user.emergencyContact.relationship,
            phoneNumber: res.data.user.emergencyContact.phoneNumber,
          });
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating your emergency contact information. Please try again");
          setLoading(false);
        });
    }
  };

  return loading ? (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <p className="text-sm font-semibold text-gray-400 uppercase">Loading</p>
    </div>
  ) : (
    <div className="flex flex-col items-start justify-start w-full h-full gap-2">
      <Helmet>
        <title>Settings | HTPS ERP</title>
      </Helmet>
      <div className="w-full border-b border-gray-200 dark:border-gray-700">
        <nav className="flex space-x-2" aria-label="Tabs" role="tablist">
          <button
            className="font-semibold border-blue-600 text-blue-600 py-4 px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap hover:text-blue-600 active"
            onClick={() => navigate("/settings")}
          >
            Profile Settings
          </button>
          <button
            className="hs-tab-active:font-semibold hs-tab-active:border-blue-600 hs-tab-active:text-blue-600 py-4 px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600"
            onClick={() => navigate("/settings/timesheets")}
          >
            Timesheets
          </button>
        </nav>
      </div>
      <div className="flex flex-row items-center justify-between w-full px-1">
        <h1 className="mt-2 mb-3 text-3xl font-bold">Settings</h1>
        <SecondaryButton label="Emergency Contact" callback={() => setDrawer(true)} />
      </div>
      <div className="flex flex-col items-start justify-center w-full max-w-xl gap-3 mx-auto">
        <div className="flex flex-col items-start justify-start w-full gap-1">
          <p className="text-sm font-semibold text-gray-800/80">First Name</p>
          <Input
            defaultValue={authState.user.firstName}
            className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            onChange={(e) => setValues({ ...values, firstName: e.target.value })}
          />
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-1">
          <p className="text-sm font-semibold text-gray-800/80">Last Name</p>
          <Input
            defaultValue={authState.user.lastName}
            className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            onChange={(e) => setValues({ ...values, lastName: e.target.value })}
          />
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-1">
          <p className="text-sm font-semibold text-gray-800/80">Email Address</p>
          <Input
            defaultValue={authState.user.email}
            disabled
            className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
          />
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-1">
          <p className="text-sm font-semibold text-gray-800/80">Phone Number</p>
          <MaskedInput
            id="phoneNumber"
            defaultValue={authState.user.phoneNumber}
            mask={"(000) 000-0000"}
            className="py-1 text-gray-900 border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 phoneInput"
            onChange={(e) => setValues({ ...values, phoneNumber: e.unmaskedValue })}
          />
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-1">
          <p className="text-sm font-semibold text-gray-800/80">Address</p>
          <Input
            defaultValue={authState.user.address}
            className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            onChange={(e) => setValues({ ...values, address: e.target.value })}
          />
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-1">
          <p className="text-sm font-semibold text-gray-800/80">Apt / Suite / Unit</p>
          <Input
            defaultValue={authState.user.address2}
            className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            onChange={(e) => setValues({ ...values, address2: e.target.value })}
          />
        </div>
        <div className="flex flex-row items-center justify-between w-full gap-2">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-sm font-semibold text-gray-800/80">City</p>
            <Input
              defaultValue={authState.user.city}
              className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
              onChange={(e) => setValues({ ...values, city: e.target.value })}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-sm font-semibold text-gray-800/80">State</p>
            <Select
              placeholder={"Pick a state"}
              name="state"
              options={States.map((c) => ({ value: c.code, label: c.name }))}
              defaultValue={authState.user.state ? authState.user.state : null}
              className="w-full font-sans"
              controls={false}
              showSearch
              filterOption={filterOption}
              onChange={(e) => setValues({ ...values, state: e })}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-sm font-semibold text-gray-800/80">ZIP Code</p>
            <InputNumber
              defaultValue={authState.user.zip}
              controls={false}
              maxLength={5}
              className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
              onChange={(e) => setValues({ ...values, zip: e })}
            />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-1">
          <p className="text-sm font-semibold text-gray-800/80">Title</p>
          <Input
            defaultValue={authState.user.title}
            disabled
            className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            onChange={(e) => setValues({ ...values, title: e.target.value })}
          />
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-1">
          <p className="text-sm font-semibold text-gray-800/80">Location</p>
          <Select
            placeholder={"Pick a location"}
            name="location"
            options={[
              {
                value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf",
                label: "Augusta, GA",
              },
              {
                value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
                label: "North Augusta, SC",
              },
            ]}
            defaultValue={authState.user.location ? authState.user.location : null}
            className="w-full font-sans"
            controls={false}
            showSearch
            filterOption={filterOption}
            onChange={(e) => setValues({ ...values, location: e })}
          />
        </div>
        <div className="flex flex-row items-center justify-end w-full gap-1 pt-3 border-t border-gray-200">
          <PrimaryButton label={"Save Changes"} callback={() => updateProfile()} />
        </div>
      </div>
      <Drawer open={drawer} onClose={() => closeDrawer()} destroyOnClose={true} width={400} placement="right" title="Emergency Contact Information">
        <div className="flex flex-col items-center justify-between w-full h-full gap-3">
          <div className="flex flex-col items-center justify-start w-full gap-3">
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Name</p>
              <Input
                defaultValue={authState.user.emergencyContact.name}
                className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                onChange={(e) => setEC({ ...ec, name: e.target.value })}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Relationship</p>
              <Input
                defaultValue={authState.user.emergencyContact.relationship}
                className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                onChange={(e) => setEC({ ...ec, relationship: e.target.value })}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Phone Number</p>
              <MaskedInput
                id="phoneNumber"
                defaultValue={authState.user.emergencyContact.phoneNumber}
                mask={"(000) 000-0000"}
                className="py-1 text-gray-900 border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 phoneInput"
                onChange={(e) => setEC({ ...ec, phoneNumber: e.unmaskedValue })}
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-end w-full gap-2 pt-3 border-t border-gray-200">
            <SecondaryButton label={"Cancel"} callback={() => closeDrawer()} />
            <PrimaryButton label={"Update"} callback={() => updateEmergencyContact()} />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Settings;
