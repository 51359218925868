import { useEffect, useState, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { GetOneJob } from "../../actions/jobs";
import toast from "react-hot-toast";
import { SecondaryButton } from "../../components/buttons";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { Xmark } from "iconoir-react";
import { GetJobChecklists } from "../../actions/checklist";
import { Helmet } from "react-helmet-async";

const JobTests = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [job, setJob] = useState({});
  // eslint-disable-next-line
  let [employees, setEmployees] = useState([]);
  let [equipment, setEquipment] = useState([]);
  let [data, setData] = useState([]);
  let [open, setOpen] = useState(false);

  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneJob(jobId)
        .then((res) => {
          setJob(res.data.job);
          setEmployees(res.data.employees);
          setEquipment(res.data.equipment);
          GetJobChecklists(jobId)
            .then((res) => {
              let sorted = res.data.sort((a, b) => new Date(b.date) - new Date(a.date));
              setData(sorted);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error retrieving test and checklist records");
              setTimeout(() => {
                navigate("/jobs");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
          setTimeout(() => {
            navigate("/jobs");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const tabs = [
    { name: "Overview", href: "#", current: false },
    { name: "Parts", href: "parts", current: false },
    { name: "Labor", href: "labor", current: false },
    { name: "Parking/Storage", href: "parking", current: false },
    { name: "Loadbank", href: "loadbank", current: false },
    { name: "Freight", href: "freight", current: false },
    { name: "Misc", href: "misc", current: false },
    { name: "Purchase Orders", href: "purchaseOrders", current: false },
    {
      name: "Inventory Transmittals",
      href: "inventoryTransmittals",
      current: false,
    },
    { name: "Equipment", href: "equipment", current: false },
    { name: "Tests", href: "tests", current: true },
    { name: "Warranty", href: "warranty", current: false },
    { name: "Payments", href: "payments", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderDrawer = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                    <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Create new record</Dialog.Title>
                          <div className="flex items-center ml-3 h-7">
                            <button type="button" className="relative text-gray-400 bg-white rounded-md hover:text-gray-900" onClick={() => setOpen(false)}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <Xmark className="w-6 h-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex flex-col items-start justify-start flex-1 gap-2 px-4 mt-6 sm:px-6">
                        <nav className="flex flex-col flex-1 w-full px-1" aria-label="CreateNewChecklistNavigation">
                          <ul role="list" className="flex flex-col flex-1 w-full gap-y-5">
                            <li>
                              <div className="pb-3 text-xs font-semibold text-gray-500 border-b leading-2 border-gray-200/80">Hi-Tech Power Systems Inc.</div>
                              <ul role="list" className="mt-2 space-y-1">
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() => window.open(`/checklists/annualMaintenance?jobId=${jobId}&customerId=${job.customerId}`, "_blank").focus()}
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      AM
                                    </span>
                                    <span className="truncate">Annual Preventive Maintenance Checklist</span>
                                  </div>
                                </li>
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() =>
                                      window.open(`/checklists/semiAnnualMaintenance?jobId=${jobId}&customerId=${job.customerId}`, "_blank").focus()
                                    }
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      SM
                                    </span>
                                    <span className="truncate">Semi-Annual Preventive Maintenance Checklist</span>
                                  </div>
                                </li>
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() =>
                                      window.open(`/checklists/quarterlyMaintenance?jobId=${jobId}&customerId=${job.customerId}`, "_blank").focus()
                                    }
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      QM
                                    </span>
                                    <span className="truncate">Quarterly Preventive Maintenance Checklist</span>
                                  </div>
                                </li>
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() => window.open(`/checklists/monthlyMaintenance?jobId=${jobId}&customerId=${job.customerId}`, "_blank").focus()}
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      MM
                                    </span>
                                    <span className="truncate">Monthly Preventive Maintenance Checklist</span>
                                  </div>
                                </li>
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() => window.open(`/checklists/levelOneService?jobId=${jobId}&customerId=${job.customerId}`, "_blank").focus()}
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      L1
                                    </span>
                                    <span className="truncate">Level One Service Checklist</span>
                                  </div>
                                </li>
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() => window.open(`/checklists/loadbank?jobId=${jobId}&customerId=${job.customerId}`, "_blank").focus()}
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      LB
                                    </span>
                                    <span className="truncate">Loadbank Load Test Log</span>
                                  </div>
                                </li>
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() => window.open(`/checklists/leakageTest?jobId=${jobId}&customerId=${job.customerId}`, "_blank").focus()}
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      LT
                                    </span>
                                    <span className="truncate">Gasoline Delivery Tank Leakage Test Certification</span>
                                  </div>
                                </li>
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() =>
                                      window.open(`/checklists/wetTestCertification?jobId=${jobId}&customerId=${job.customerId}`, "_blank").focus()
                                    }
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      WT
                                    </span>
                                    <span className="truncate">Wet Test Certification Form</span>
                                  </div>
                                </li>
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() => window.open(`/checklists/visualInspection?jobId=${jobId}&customerId=${job.customerId}`, "_blank").focus()}
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      VI
                                    </span>
                                    <span className="truncate">Internal Visual Inspection Report</span>
                                  </div>
                                </li>
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() =>
                                      window.open(`/checklists/externalVisualInspection?jobId=${jobId}&customerId=${job.customerId}`, "_blank").focus()
                                    }
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      EI
                                    </span>
                                    <span className="truncate">5-Year External Visual Inspection Report</span>
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div className="pb-3 text-xs font-semibold text-gray-500 border-b leading-2 border-gray-200/80">
                                Kinder-Morgan Southeast Terminals LLC
                              </div>
                              <ul role="list" className="mt-2 space-y-1">
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() =>
                                      window.open(`/checklists/annualTankTrailerInspection?jobId=${jobId}&customerId=${job.customerId}`, "_blank").focus()
                                    }
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      TI
                                    </span>
                                    <span className="truncate">Annual Tank Trailer Inspection Form</span>
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div className="pb-3 text-xs font-semibold text-gray-500 border-b leading-2 border-gray-200/80">Citgo Petroleum Corporation</div>
                              <ul role="list" className="mt-2 space-y-1">
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() => window.open(`/checklists/citgo?jobId=${jobId}&customerId=${job.customerId}`, "_blank").focus()}
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      CE
                                    </span>
                                    <span className="truncate">Carrier Equipment Inspection Form</span>
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div className="pb-3 text-xs font-semibold text-gray-500 border-b leading-2 border-gray-200/80">Buckeye Terminals LLC</div>
                              <ul role="list" className="mt-2 space-y-1">
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() => navigate("/checklists/buckeye")}
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      BE
                                    </span>
                                    <span className="truncate">Annual & Post Incident Trailer Inspection Form</span>
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div className="pb-3 text-xs font-semibold text-gray-500 border-b leading-2 border-gray-200/80">
                                Magellan Midstream Partners L.P.
                              </div>
                              <ul role="list" className="mt-2 space-y-1">
                                <li key={uuidv4()}>
                                  <div
                                    onClick={() => navigate("/checklists/magellan")}
                                    className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                                  >
                                    <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                                      MG
                                    </span>
                                    <span className="truncate">Truck Trailer Safety Equipment and Wet Test Certification Form</span>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Tests, Certifications & Checklists - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      value={tab.name}
                      key={uuidv4()}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Job Tests</p>
              {job.jobStatus === "open" && <SecondaryButton label="Log a new test" callback={() => setOpen(true)} />}
            </div>
            <div className="flex items-center justify-start flex-grow w-full gap-4 px-1 py-3">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="hidden md:table-cell sticky top-0 z-10 border-b border-gray-300 bg-white-smoke bg-opacity-75 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white-smoke bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white-smoke bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Technician
                    </th>
                    <th
                      scope="col"
                      className="hidden md:table-cell sticky top-0 z-10 border-b border-gray-300 bg-white-smoke bg-opacity-75 px-3 py-3.5 text-right text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white-smoke bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                    >
                      <span className="sr-only">Open</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {data.map((line) => (
                    <tr key={uuidv4()}>
                      <td className="w-full py-4 pl-4 pr-3 text-sm font-medium text-gray-900 max-w-0 sm:w-auto sm:max-w-none sm:pl-0 bg-white-smoke">
                        {line.customerId}
                        <dl className="font-normal lg:hidden">
                          <dt className="sr-only">Type</dt>
                          <dd className="mt-1 text-gray-700 text-wrap">{line.kind}</dd>
                        </dl>
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell bg-white-smoke">{line.kind}</td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell bg-white-smoke">{line.technician}</td>
                      <td className="hidden px-3 py-4 text-sm text-right text-gray-500 md:table-cell bg-white-smoke">
                        {dayjs(line.date).format("MM/DD/YYYY")}
                      </td>
                      <td className="py-4 pl-3 pr-4 text-sm font-medium text-right sm:pr-0 bg-white-smoke">
                        <SecondaryButton label="Open" callback={() => window.open(`/checklists/${line.type}/${line.fileId}`, "_blank").focus()} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {renderDrawer()}
        </>
      )}
    </div>
  );
};

export default JobTests;
