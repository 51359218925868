import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { GetDailyReport, GetPayPeriods, GetTimesheet, GetWeeklyReport } from "../../../actions/reports";
import { GenerateCountSheet, GenerateDailyTimeReport, GenerateTimesheet, GenerateWeeklyTimeReport } from "../../../data/pdf";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { SecondaryButton } from "../../../components/buttons";
import { Helmet } from "react-helmet-async";

const WeeklyTimesheetReport = () => {
  const [loading, setLoading] = useState(true);
  const [weeks, setWeeks] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(null);

  useEffect(() => {
    let inView = true;
    if (inView) {
      let x = dayjs().day(1).hour(0).minute(0).second(0).millisecond(0);
      let tmp = [];
      for (let i = 0; i < 36; i++) {
        tmp.push({
          value: x.toJSON(),
          label: "Week starting: " + x.format("MMM DD, YYYY"),
        });
        x = x.subtract(1, "week");
      }
      setWeeks(tmp);
      setTimeout(() => setLoading(false), 700);
    }
    return () => {
      inView = false;
    };
  }, []);

  const getTimesheet = () => {
    setLoading(true);
    GetWeeklyReport({ date: selectedWeek })
      .then((res) => {
        let weekString = dayjs(selectedWeek).format("MMMM Do, YYYY");
        if (res.data.length === 0) {
          toast.error(`No timesheet entries found for the week of ${weekString}`);
          setLoading(false);
        } else {
          let doc = GenerateWeeklyTimeReport(res.data);
          doc.setProperties({
            title: `Hi-Tech Power Systems | Weekly Time Report - Week of ${weekString}`,
            subject: `Hi-Tech Power Systems | Weekly Time Report - Week of ${weekString}`,
            author: "Hypertek Solutions LLC",
            keywords: "",
            creator: "contact@hypertek.dev",
          });
          window.open(doc.output("bloburl"), "_blank");
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data ? err.response.data.message : "Error retrieving data");
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Weekly Timesheet Report | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full h-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Weekly Timesheet Report</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          {!loading ? (
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="text-sm uppercase text-gray-500/90">Weekly Timesheet Report</p>
              <p className="text-sm text-gray-600/80">
                Please select a week you wish to get a report for. This will generate a report for all employees who worked on during that week.
              </p>
              <div className="flex flex-col items-start justify-start w-full gap-4 mt-5">
                <div className="flex flex-row items-center justify-start gap-4">
                  <Select placeholder="Select a week" options={weeks} className="w-96" onChange={(v) => setSelectedWeek(v)} defaultValue={selectedWeek} />
                </div>
                <SecondaryButton label="Get Timesheet" callback={() => getTimesheet()} disabled={!selectedWeek} />
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full gap-1">
              <p className="text-sm uppercase text-gray-500/90">Loading</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WeeklyTimesheetReport;
