import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, createBrowserRouter } from "react-router-dom";
import { AuthProvider, AuthConsumer } from "./context";
import Snowfall from "react-snowfall";
import dayjs from "dayjs";
import { HelmetProvider } from "react-helmet-async";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <App />,
//     children: [
//       {
//         path: "/login",
//         element: <Login />,
//         loader: loader,
//       }
//     ],
//   },
// ]);
// <RouterProvider router={router} />
// https://reactrouter.com/en/main/routers/create-browser-router

let snowChecker = () => {
  let showSnow = false;
  let date = dayjs();
  if (date.month() === 11) {
    showSnow = true;
  } else if (date.month() === 0 && date.date() < 5) {
    showSnow = true;
  }
  return showSnow;
};

const root = ReactDOM.createRoot(document.getElementById("hyp3rbody"));
root.render(
  <HelmetProvider>
    <AuthProvider>
      <AuthConsumer>
        {(authState) => (
          <BrowserRouter>
            {snowChecker() && <Snowfall speed={[0.5, 0.5]} wind={[-0.5, 0.5]} radius={[0.5, 2]} />}
            <App authState={authState} authDispatch={authState.authDispatch} />
          </BrowserRouter>
        )}
      </AuthConsumer>
    </AuthProvider>
  </HelmetProvider>,
);
