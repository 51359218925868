import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { ArrowSeparateVertical, Check, OpenInWindow, Printer } from "iconoir-react";
import { GetEquipmentLogByCustomer, GetOpenAPInvoices } from "../../../actions/reports";
import dayjs from "dayjs";
import { formatCurrency, formatPhoneNumber } from "../../../components/tools";
import { GenerateCustomerEquipmentLog } from "../../../data/pdf";
import { Combobox, Transition } from "@headlessui/react";
import { GetFullCustomerList } from "../../../actions/customers";
import { Select } from "antd";
import { Helmet } from "react-helmet-async";

const EquipmentLog = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState("");
  const [customers, setCustomers] = useState([]);
  const [data, setData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;

    if (inView) {
      GetFullCustomerList()
        .then((res) => {
          setCustomers(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "We encountered an error while loading Customer Equipment Log data");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const renderTableRow = (data) => {
    let linkto = "quotes";
    if (data.type === "Invoice") {
      linkto = "invoices";
    }
    if (data.type === "Job") {
      linkto = "jobs";
    }
    return (
      <tr className="even:bg-gray-50">
        <td className="py-2 pl-4 pr-3 text-xs font-medium text-gray-900 whitespace-nowrap sm:pl-3">{data.type}</td>
        <td className="px-3 py-2 text-xs text-gray-500 whitespace-nowrap">{data.readableId}</td>
        <td className="px-3 py-2 text-xs text-gray-500 whitespace-nowrap">{dayjs(data.date).format("MM/DD/YYYY")}</td>
        <td className="px-3 py-2 text-xs text-gray-500 whitespace-nowrap">{data.description}</td>
        <td className="relative py-2 pl-3 whitespace-nowrap pr-4sm:pr-3">
          <button
            onClick={() => navigate(`/${linkto}/${data.id}`)}
            className="py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md border-2 border-gray-200 font-semibold text-gray-500 hover:text-white hover:bg-black hover:border-black focus:outline-none focus:ring-0 transition-all text-xs uppercase"
          >
            <OpenInWindow className="w-4 h-4" />
            Open
          </button>
        </td>
      </tr>
    );
  };

  const printReport = () => {
    let customerData = {
      customerCode: data[0].customerCode,
      company: data[0].company,
    };
    let sortedData = data.sort((a, b) => b.events.length - a.events.length);
    let doc = GenerateCustomerEquipmentLog(sortedData, customerData);
    doc.setProperties({
      title: `Hi-Tech Power Systems | Customer Equipment Log`,
      subject: `Hi-Tech Power Systems | Customer Equipment Log`,
      author: "Hypertek Solutions LLC",
      keywords: "",
      creator: "contact@hypertek.dev",
    });
    window.open(doc.output("bloburl"), "_blank");
  };

  const updateCustomer = (v) => {
    setSelected(v);
    setLoading(true);
    GetEquipmentLogByCustomer(v)
      .then((res) => {
        setData(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "We encountered an error while loading Equipment Log report");
        setLoading(false);
      });
  };

  const changeCustomer = () => {
    setLoading(true);
    setSelected(null);
    setQuery("");
    setData(null);
    setTimeout(() => setLoading(false), 700);
  };

  const equipmentTypes = {
    generator: "Generator",
    pressureWasher: "Pressure Washer",
    truck: "Truck",
    trailer: "Trailer",
    welder: "Welder",
    airCompressor: "Air Compressor",
    other: "Other",
  };

  const fuelTypes = {
    diesel: "Diesel",
    gasoline: "Gasoline",
    naturalGas: "Natural Gas",
    propane: "Propane",
    biFuel: "Bi-Fuel",
    electricity: "Electricity",
    other: "Other",
  };

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const renderDataRow = (data) => {
    let sortedData = data.sort((a, b) => b.events.length - a.events.length);

    return (
      <div className="flex flex-col items-start justify-start w-full my-10">
        {sortedData.map((dt) => (
          <div className="flex flex-col items-start justify-start w-full gap-2 pt-5 mb-5 border-t border-gray-400">
            <div className="grid items-center justify-center w-full grid-cols-3 gap-10 text-sm text-gray-800">
              <div className="flex flex-col items-start justify-start gap-1">
                <p>
                  Customer Code: <span className="font-medium text-black">{dt.customerCode}</span>
                </p>
                <p>
                  Customer Company: <span className="font-medium text-black">{dt.company}</span>
                </p>
                <p>
                  Customer Contact: <span className="font-medium text-black">{dt.contact}</span>
                </p>
                <p>
                  Customer Email: <span className="font-medium text-black">{dt.email}</span>
                </p>
                <p>
                  Customer Phone: <span className="font-medium text-black">{formatPhoneNumber(dt.phoneNumber)}</span>
                </p>
              </div>
              <div className="flex flex-col items-start justify-start gap-1">
                <p>
                  Location: <span className="font-medium text-black">{dt.location}</span>
                </p>
                <p>
                  Customer Equip. ID: <span className="font-medium text-black">{dt.customerEquipId}</span>
                </p>
                <p>
                  Equipment Type:{" "}
                  <span className="font-medium text-black">
                    {dt.equipmentType !== "other"
                      ? equipmentTypes[dt.equipmentType]
                      : dt.otherType.length > 0
                        ? "Other - " + dt.otherType
                        : "Other (not provided)"}
                  </span>
                </p>
                <p>
                  Fuel Type:{" "}
                  <span className="font-medium text-black capitalize">{dt.fuelType && dt.fuelType.length > 0 ? fuelTypes[dt.fuelType] : "Unknown"}</span>
                </p>
                <p>
                  Equipment Make: <span className="font-medium text-black">{dt.make}</span>
                </p>
              </div>
              <div className="flex flex-col items-start justify-start gap-1">
                <p>
                  Equipment Model: <span className="font-medium text-black">{dt.model}</span>
                </p>
                <p>
                  Equipment Serial No: <span className="font-medium text-black">{dt.serialNo}</span>
                </p>
                <p>
                  Equipment Arrangement: <span className="font-medium text-black">{dt.arrangement}</span>
                </p>
                <p>
                  Engine Make: <span className="font-medium text-black">{dt.engineMake}</span>
                </p>
                <p>
                  Engine Spec: <span className="font-medium text-black">{dt.engineSpecNo}</span>
                </p>
              </div>
            </div>
            {!loading && dt.events.length > 0 ? (
              <table className="min-w-full mt-3 divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-2 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 sm:pl-3">
                      Type
                    </th>
                    <th scope="col" className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                      ID
                    </th>
                    <th scope="col" className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                      Date
                    </th>
                    <th scope="col" className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                      Description
                    </th>
                    <th scope="col" className="relative py-2 pl-3 pr-4 text-sm font-semibold text-right text-gray-900 sm:pr-3">
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody>{!loading && dt.events.map((evnt) => renderTableRow(evnt))}</tbody>
              </table>
            ) : (
              <div className="flex flex-row items-center justify-start w-full py-2 font-semibold uppercase border-t border-gray-200 text-gray-500/70">
                <p className="text-xs">
                  No Jobs / Quotes / Invoices found for this{" "}
                  {dt.equipmentType !== "other"
                    ? equipmentTypes[dt.equipmentType]
                    : dt.otherType.length > 0
                      ? "Other - " + dt.otherType
                      : "Other (not provided)"}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Equipment Log by Customer | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full h-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Equipment Log</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <div className="flex flex-col items-start justify-center gap-1">
            <p className="text-sm uppercase text-gray-500/90">Equipment Log By Customer</p>
            <p className="text-sm text-gray-600/80">Historical overview of all relevant quotes, invoices, and jobs for customer's equipment.</p>
          </div>
          {data && (
            <button
              onClick={() => printReport()}
              className="py-1.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-gray-200 font-semibold text-gray-500 hover:text-white hover:bg-black hover:border-black focus:outline-none focus:ring-0 transition-all text-sm"
            >
              <Printer className="w-5 h-5" />
              Print Out
            </button>
          )}
        </div>
        <div className="flex flex-row items-end justify-start w-full gap-4">
          <div className="flex flex-col items-start justify-center w-1/2 gap-1">
            <p className="text-sm text-gray-500 uppercase">Select a customer</p>
            <Select
              placeholder={"Select a customer"}
              name={"customerId"}
              onChange={(v) => updateCustomer(v)}
              options={customers.map((c) => ({
                value: c.customerId,
                label: `${c.customerCode}${c.company.length > 0 ? " | " + c.company : ""}`,
              }))}
              defaultValue={null}
              className="w-full mt-1 font-sans"
              controls={false}
              showSearch
              filterOption={filterOption}
            />
          </div>
          {data && (
            <button
              onClick={() => changeCustomer()}
              className="py-1.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-gray-200 font-semibold text-gray-500 hover:text-white hover:bg-black hover:border-black focus:outline-none focus:ring-0 transition-all text-sm"
            >
              Change Customer
            </button>
          )}
        </div>
        {data && !loading && renderDataRow(data)}

        {!data && <p className="text-sm text-gray-600/80">In order to generate an equipment log report start off by selecting a customer.</p>}
      </div>
    </div>
  );
};

export default EquipmentLog;
