import validator from "validator";

const testZip = (val) => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
};

const testPhone = (val) => {
  return /^((\+1|1)?( |-)?)?(\(([2-9])(?:\d(?!\5)\d|(?!\5)\d\d)\)|([2-9])(?:\d(?!\6)\d|(?!\6)\d\d))( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(val);
};

export const newVendorBasics = {
  vendorCode: {
    fieldName: "vendorCode",
    label: "Vendor Code",
    inputType: "text",
    placeholder: "Enter a short code for the vendor",
    defaultValue: "",
    config: {
      required: "Vendor code is required",
      validate: (value) => value.length >= 2 || "Vendor code is required and must be at least 2 characters long",
    },
  },
  vendorName: {
    fieldName: "vendorName",
    label: "Vendor Name",
    inputType: "text",
    placeholder: "Enter a name for the vendor",
    defaultValue: "",
    config: {
      required: "Vendor name is required",
      validate: (value) => value.length >= 2 || "Vendor name is required and must be at least 2 characters long",
    },
  },
};

export const newVendorContact = {
  name: {
    fieldName: "name",
    label: "Contact Person's Name",
    inputType: "text",
    placeholder: "Enter a name for the contact person",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || value.length >= 2 || "Contact's name is required and must be at least 2 characters long",
    },
  },
  address: {
    fieldName: "address",
    label: "Address",
    inputType: "text",
    placeholder: "Address",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || value.length >= 3 || "Address must be at least 2 characters long",
    },
  },
  address2: {
    fieldName: "address2",
    label: "Apt/Suite/Unit",
    inputType: "text",
    placeholder: "Apt/Suite/Unit",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  city: {
    fieldName: "city",
    label: "City",
    inputType: "text",
    placeholder: "City",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "City must be at least 2 characters long",
    },
  },
  state: {
    fieldName: "state",
    label: "State",
    inputType: "state-picker",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || value.length === 2 || "Please select a valid state",
    },
  },
  zip: {
    fieldName: "zip",
    label: "ZIP Code",
    inputType: "number",
    placeholder: "ZIP Code",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || testZip(value) || "Please enter a valid ZIP code",
    },
  },
  phoneNumber: {
    fieldName: "phoneNumber",
    label: "Phone Number",
    inputType: "phone",
    placeholder: "Phone Number",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || testPhone(value) || "Please enter a valid phone number",
    },
  },
  email: {
    fieldName: "email",
    label: "Email",
    inputType: "email",
    placeholder: "Email",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || validator.isEmail(value) || "Please enter a valid email address",
    },
  },
  website: {
    fieldName: "website",
    label: "Website",
    inputType: "text",
    placeholder: "Enter the vendor's website",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || validator.isURL(value) || "Please enter a valid website address",
    },
  },
  communication: {
    fieldName: "communication",
    label: "Communication Preference",
    inputType: "select",
    placeholder: "Select One",
    options: [
      { value: "phone", label: "Phone" },
      { value: "text", label: "Text" },
      { value: "email", label: "Email" },
      { value: "website", label: "Website" },
      { value: "fax", label: "Fax" },
      { value: "other", label: "Other" },
    ],
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || value.length > 2 || "Please select payment terms for the customer",
    },
  },
};

export const newVendorAP = {
  useAPAddress: {
    fieldName: "useAPAddress",
    label: "Use Accounts Payable Address?",
    inputType: "checkbox",
    placeholder: "Use Accounts Payable Address?",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  apName: {
    fieldName: "apName",
    label: "Contact Person's Name",
    inputType: "text",
    placeholder: "Enter a name for the contact person",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || value.length >= 2 || "Contact's name is required and must be at least 2 characters long",
    },
  },
  address: {
    fieldName: "address",
    label: "Address",
    inputType: "text",
    placeholder: "Address",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || value.length >= 3 || "Address must be at least 2 characters long",
    },
  },
  address2: {
    fieldName: "address2",
    label: "Apt/Suite/Unit",
    inputType: "text",
    placeholder: "Apt/Suite/Unit",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  city: {
    fieldName: "city",
    label: "City",
    inputType: "text",
    placeholder: "City",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || (value.length >= 3 && validator.isAlpha(value.replace(" ", ""))) || "City must be at least 2 characters long",
    },
  },
  state: {
    fieldName: "state",
    label: "State",
    inputType: "state-picker",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || value.length === 2 || "Please select a valid state",
    },
  },
  zip: {
    fieldName: "zip",
    label: "ZIP Code",
    inputType: "number",
    placeholder: "ZIP Code",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || testZip(value) || "Please enter a valid ZIP code",
    },
  },
  phoneNumber: {
    fieldName: "phoneNumber",
    label: "Phone Number",
    inputType: "phone",
    placeholder: "Phone Number",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || testPhone(value) || "Please enter a valid phone number",
    },
  },
  apEmail: {
    fieldName: "apEmail",
    label: "Email",
    inputType: "email",
    placeholder: "Email",
    defaultValue: "",
    config: {
      required: false,
      validate: (value) => value === "" || validator.isEmail(value) || "Please enter a valid email address",
    },
  },
};
