import { useEffect, useState, Fragment, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { AddNotesAndPhotos, GetOneJob, RemoveEquipmentFromJob, SubmitEquipmentOdometerReading, UpdateJobEquipment } from "../../actions/jobs";
import toast from "react-hot-toast";
import { IconButton, PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Xmark, CloudUpload } from "iconoir-react";
import dayjs from "dayjs";
import { UploadEquipmentPhotos } from "../../actions/cdn";
import { Drawer, Image, Input, InputNumber, Modal, Table } from "antd";
import { useDropzone } from "react-dropzone";
import { Helmet } from "react-helmet-async";
import { Trash2 } from "lucide-react";

const JobEquipment = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [job, setJob] = useState({});
  let [equipment, setEquipment] = useState([]);
  let [equipmentDrawer, setEquipmentDrawer] = useState(false);
  let [equipmentToAdd, setEquipmentToAdd] = useState([]);
  let [equipmentToRemove, setEquipmentToRemove] = useState([]);
  let [openEq, setOpenEq] = useState({
    equipmentId: "",
    notes: [],
    photos: [],
    miles: { reading: null, date: null, enteredBy: null, enteredById: null },
  });
  let [notesAndPhotos, setNotesAndPhotos] = useState(false);
  let [newNotes, setNewNotes] = useState([]);
  let [newPhotos, setNewPhotos] = useState([]);
  let [uploadModal, setUploadModal] = useState(false);
  let [selectedFile, setSelectedFile] = useState(null);
  let [eqQuery, setEqQuery] = useState("");
  let [odometerModal, setOdometerModal] = useState(false);
  let [odometerReading, setOdometerReading] = useState(0);
  let [odometerEquipId, setOdometerEquipId] = useState(null);

  const { jobId } = useParams();
  const navigate = useNavigate();

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDropAccepted: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((f) => {
        toast.error(`${f.file.name} was not accepted`);
      });
    },
  });

  let noteRef = useRef(null);

  useEffect(() => {
    let inView = true;
    if (inView) {
      reloadData();
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const reloadData = () => {
    GetOneJob(jobId)
      .then((res) => {
        setJob(res.data.job);
        setEquipment(res.data.equipment);
        setDummyLoading(false);
        setEquipmentDrawer(false);
        setEquipmentToAdd([]);
        setEquipmentToRemove([]);
        setOpenEq({
          equipmentId: "",
          notes: [],
          photos: [],
          miles: {
            reading: null,
            date: null,
            enteredBy: null,
            enteredById: null,
          },
        });
        setNotesAndPhotos(false);
        setNewNotes([]);
        setNewPhotos([]);
        setSelectedFile(null);
        setEqQuery("");
        setOdometerModal(false);
        setOdometerReading(0);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
        setTimeout(() => {
          navigate("/jobs");
        }, 3000);
      });
  };

  let eqStatus = {
    availableForUse: "Available for Use",
    inoperable: "Inoperable",
    retired: "Retired",
  };

  let eqType = {
    generator: "Generator",
    pressureWasher: "Pressure Washer",
    truck: "Truck",
    trailer: "Trailer",
    welder: "Welder",
    airCompressor: "Air Compressor",
    other: "Other",
  };

  const tabs = [
    { name: "Overview", href: "#", current: false },
    { name: "Parts", href: "parts", current: false },
    { name: "Labor", href: "labor", current: false },
    { name: "Parking/Storage", href: "parking", current: false },
    { name: "Loadbank", href: "loadbank", current: false },
    { name: "Freight", href: "freight", current: false },
    { name: "Misc", href: "misc", current: false },
    { name: "Purchase Orders", href: "purchaseOrders", current: false },
    {
      name: "Inventory Transmittals",
      href: "inventoryTransmittals",
      current: false,
    },
    { name: "Equipment", href: "equipment", current: true },
    { name: "Tests", href: "tests", current: false },
    { name: "Warranty", href: "warranty", current: false },
    { name: "Payments", href: "payments", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderEquipmentLine = (line) => {
    let eq = equipment.find((e) => e.equipmentId === line.equipmentId);
    if (eq) {
      return (
        <div className="grid items-center w-full grid-cols-3 gap-2 px-1 py-3 border-b border-gray-300 md:grid-cols-6" key={uuidv4()}>
          <p className="text-xs font-semibold text-gray-500 uppercase max-w-[150px]">{eqType[eq.equipmentType]}</p>
          <p className="hidden capitalize md:inline-flex">{eq.customerEquipId && eq.customerEquipId.length > 0 ? eq.customerEquipId : "Not Provided"}</p>
          <p className="hidden md:inline-flex">{eqStatus[eq.details.status]}</p>
          <p className="capitalize truncate">{eq.details.make}</p>
          <p className="text-base max-w-[140px]">{eq.miles && eq.miles.reading ? eq.miles.reading : "Missing"}</p>
          <div className="flex flex-row items-center justify-center gap-2">
            <SecondaryButton label="Notes/Photos/ODO" callback={() => openNotesAndPhotos(line)} />
            <SecondaryButton label="Remove" callback={() => removeEqFromJob(line.equipmentId)} />
          </div>
        </div>
      );
    }
  };

  const removeEqFromJob = (id) => {
    setLoading(true);
    RemoveEquipmentFromJob(jobId, id)
      .then((res) => {
        toast.success("Equipment removed successfully");
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error removing equipment");
        setLoading(false);
      });
  };

  const filterEquipment =
    eqQuery === ""
      ? equipment
      : equipment.filter((pos) => {
          return (
            pos.equipmentType.toLowerCase().includes(eqQuery.toLowerCase().replace(/ /g, "")) ||
            (pos.details && pos.details.make && pos.details.make.toLowerCase().includes(eqQuery.toLowerCase().replace(/ /g, ""))) ||
            (pos.details && pos.details.make && pos.details.model.toLowerCase().includes(eqQuery.toLowerCase().replace(/ /g, ""))) ||
            (pos.details && pos.details.serialNo && pos.details.serialNo.toLowerCase().includes(eqQuery.toLowerCase().replace(/ /g, ""))) ||
            (pos.engine && pos.engine.serialNo && pos.engine.serialNo.toLowerCase().includes(eqQuery.toLowerCase().replace(/ /g, ""))) ||
            (pos.engine && pos.engine.specNo && pos.engine.specNo.toLowerCase().includes(eqQuery.toLowerCase().replace(/ /g, ""))) ||
            (pos.engine && pos.engine.codeNo && pos.engine.codeNo.toLowerCase().includes(eqQuery.toLowerCase().replace(/ /g, ""))) ||
            (pos.power && pos.power.toString().includes(eqQuery.toLowerCase().replace(/ /g, ""))) ||
            (pos.pwrUnit && pos.pwrUnit.toLowerCase().includes(eqQuery.toLowerCase().replace(/ /g, ""))) ||
            (pos.fuelType && pos.fuelType.toLowerCase().includes(eqQuery.toLowerCase().replace(/ /g, ""))) ||
            (pos.location && pos.location.toLowerCase().includes(eqQuery.toLowerCase().replace(/ /g, ""))) ||
            (pos.customerEquipId && pos.customerEquipId.toLowerCase().includes(eqQuery.toLowerCase().replace(/ /g, "")))
          );
        });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "100%",
    padding: "80px 0px",
    margin: "15px 0px",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const renderEquipType = (eqType) => {
    switch (eqType) {
      case "generator":
        return "Generator";
      case "pressureWasher":
        return "Pressure Washer";
      case "truck":
        return "Truck";
      case "trailer":
        return "Trailer";
      case "welder":
        return "Welder";
      case "airCompressor":
        return "Air Compressor";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const updateJobEq = (eqId) => {
    setDummyLoading(true);
    if (isIncluded(eqId)) {
      let tmp = job;
      let newEq = tmp.equipment.filter((eq) => eq.equipmentId !== eqId);
      tmp.equipment = newEq;
      let tmp2 = equipmentToRemove;
      if (!tmp2.includes(eqId)) {
        tmp2.push(eqId);
      }
      setEquipmentToRemove(tmp2);
      if (equipmentToAdd.includes(eqId)) {
        let tmp3 = equipmentToAdd;
        let newEq = tmp3.filter((eq) => eq !== eqId);
        setEquipmentToAdd(newEq);
      }
      setJob(tmp);
    } else {
      let tmp = job;
      tmp.equipment.push({ equipmentId: eqId, notes: [], photos: [] });
      let tmp2 = equipmentToAdd;
      if (!tmp2.includes(eqId)) {
        tmp2.push(eqId);
      }
      setEquipmentToAdd(tmp2);
      if (equipmentToRemove.includes(eqId)) {
        let tmp3 = equipmentToRemove;
        let newEq = tmp3.filter((eq) => eq !== eqId);
        setEquipmentToRemove(newEq);
      }
      setJob(tmp);
    }
    setTimeout(() => setDummyLoading(false), 700);
  };

  const isIncluded = (eqId) => {
    let exists = _.find(job.equipment, (eq) => eq.equipmentId === eqId);
    if (exists) {
      return true;
    } else {
      return false;
    }
  };

  const closeEqDrawer = () => {
    if (equipmentToAdd.length === 0 && equipmentToRemove.length === 0) {
      setEquipmentDrawer(false);
      setEquipmentToAdd([]);
      setEquipmentToRemove([]);
      setEqQuery("");
    } else {
      setLoading(true);
      UpdateJobEquipment(jobId, { equipmentToAdd, equipmentToRemove })
        .then((res) => {
          toast.success("Job Equipment Updated Successfully");
          setEquipmentToAdd([]);
          setEquipmentToRemove([]);
          setEquipmentDrawer(false);
          setEqQuery("");
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error updating job equipment");
          setEquipmentToAdd([]);
          setEquipmentToRemove([]);
          setEquipmentDrawer(false);
          setLoading(false);
        });
    }
  };

  const renderEquipmentDrawer = () => {
    return (
      <Drawer open={equipmentDrawer} onClose={() => closeEqDrawer()} title="Manage Job Equipment" placement="right" closable={true} width={450}>
        <div className="flex flex-col w-full h-full overflow-y-scroll bg-white">
          <Input
            onChange={(e) => setEqQuery(e.target.value)}
            placeholder="Search Equipment"
            className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 hover:border-gray-400 focus:ring-0"
          />
          <div className="relative flex flex-col items-center justify-start flex-1 gap-2 mt-4 overflow-y-auto">
            {filterEquipment.map((eq) => (
              <div
                className={`w-full flex flex-col justify-start items-start border rounded-lg ${
                  isIncluded(eq.equipmentId) ? "border-gray-500" : "border-gray-300"
                } px-4 py-3 gap-1`}
                key={eq.equipmentId}
              >
                <p className="text-sm">
                  <span className="text-xs font-semibold uppercase">Type:</span> {renderEquipType(eq.equipmentType)}
                  {eq.equipmentType === "other" && eq.otherType.length > 0 && " | " + eq.otherType}
                </p>
                <p className="text-sm">
                  <span className="text-xs font-semibold uppercase">Customer's Equipment ID:</span>{" "}
                  {eq.customerEquipId && eq.customerEquipId.length > 0 ? eq.customerEquipId : "Not Provided"}
                </p>
                <p className="text-sm">
                  <span className="text-xs font-semibold uppercase">Equipment Status:</span>{" "}
                  {eq.details.status && eq.details.status.length > 0 ? eqStatus[eq.details.status] : "Unknown"}
                </p>
                <p className="text-sm">
                  <span className="text-xs font-semibold uppercase">Make:</span> {eq.details.make}
                </p>
                <p className="text-sm">
                  <span className="text-xs font-semibold uppercase">Model:</span> {eq.details.model}
                </p>
                <p className="text-sm">
                  <span className="text-xs font-semibold uppercase">Location:</span> {eq.location && eq.location.length > 0 ? eq.location : "Not Provided"}
                </p>
                <p className="text-sm">
                  <span className="text-xs font-semibold uppercase">Spec No:</span>{" "}
                  {eq.details && eq.details.specNo && eq.details.specNo.length > 0 ? eq.details.specNo : "Not Provided"}
                </p>
                <p className="text-sm">
                  <span className="text-xs font-semibold uppercase">Serial No:</span>{" "}
                  {eq.details && eq.details.serialNo && eq.details.serialNo.length > 0 ? eq.details.serialNo : "Not Provided"}
                </p>
                <p className="text-sm">
                  <span className="text-xs font-semibold uppercase">Engine Serial No:</span>{" "}
                  {eq.engine && eq.engine.serialNo && eq.engine.serialNo.length > 0 ? eq.engine.serialNo : "Not Provided"}
                </p>
                <p className="text-sm">
                  <span className="text-xs font-semibold uppercase">Engine Spec No:</span>{" "}
                  {eq.engine && eq.engine.specNo && eq.engine.specNo.length > 0 ? eq.engine.specNo : "Not Provided"}
                </p>
                <div className="flex flex-row items-center justify-end w-full">
                  <SecondaryButton
                    label={isIncluded(eq.equipmentId) ? "Remove from Job" : "Add to Job"}
                    callback={() => {
                      updateJobEq(eq.equipmentId);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-row items-center justify-end w-full gap-3 px-5 py-5 border-t border-gray-300">
            <SecondaryButton label="Save Changes" callback={() => closeEqDrawer()} />
          </div>
        </div>
      </Drawer>
    );
  };

  const renderNotesPhotosModal = () => {
    return (
      <Modal
        open={notesAndPhotos}
        onCancel={() => closeNotesAndPhotos()}
        title="Equipment Notes & Photos"
        centered
        width={800}
        footer={
          job.jobStatus === "open"
            ? [
                <div className="flex items-center justify-end w-full gap-2">
                  <SecondaryButton label="Discard Changes" callback={() => closeNotesAndPhotos()} />
                  <PrimaryButton label="Save Changes" callback={() => saveNotesAndPhotoChanges()} />
                </div>,
              ]
            : [<SecondaryButton label="Close" callback={() => closeNotesAndPhotos()} />]
        }
      >
        <div className="mt-2 w-full flex flex-row justify-between items-start gap-1 h-[700px]">
          <div className="flex flex-col items-start justify-start w-1/2 h-full gap-3 pr-4 border-r border-gray-300">
            <p className="w-full py-2 font-semibold text-center uppercase border-gray-300 border-y">Photos</p>
            <div className="grid w-full h-full grid-cols-2 gap-4 overflow-y-auto job-equipment-photo">
              {newPhotos.map((photo) => (
                <Image src={photo} className="object-cover w-56 h-56" width={"14rem"} height={"14rem"} />
              ))}
              {openEq.photos.map((photo) => (
                <div className="w-56 h-56 overflow-hidden">
                  <Image src={photo.photoUrl} className="object-cover w-56 h-56" width={"14rem"} height={"14rem"} />
                </div>
              ))}
            </div>
            {job.jobStatus === "open" && (
              <div className="flex flex-row items-center justify-center w-full py-2 border-t border-gray-100">
                <SecondaryButton label="Add Photos" callback={() => setUploadModal(true)} />
              </div>
            )}
          </div>
          <div className="flex flex-col items-start justify-start w-1/2 h-full gap-3 pl-4 border-l border-gray-300">
            <p className="w-full py-2 font-semibold text-center uppercase border-gray-300 border-y">Notes</p>
            <div className="flex flex-col items-start justify-start flex-grow w-full gap-2 overflow-y-auto">
              {newNotes.map((note) => (
                <div className="flex flex-col items-start justify-start w-full gap-2 px-4 py-2 bg-gray-100 rounded-sm">
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-xs font-semibold text-gray-500 uppercase">By You</p>
                    <p className="text-xs font-semibold text-gray-500 uppercase">Just Now</p>
                  </div>
                  <p className="text-sm text-gray-700">{note}</p>
                </div>
              ))}
              {openEq.notes.map((note) => (
                <div className="flex flex-col items-start justify-start w-full gap-2 px-4 py-2 bg-gray-100 rounded-sm">
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-xs font-semibold text-gray-500 uppercase">By {note.noteByName}</p>
                    <p className="text-xs font-semibold text-gray-500 uppercase">{dayjs(note.noteDate).format("MM/DD/YYYY [at] hh:mm A")}</p>
                  </div>
                  <p className="text-sm text-gray-700">{note.note}</p>
                </div>
              ))}
            </div>
            {job.jobStatus === "open" && (
              <div className="flex flex-row items-center justify-around w-full py-2 border-t border-gray-100">
                <textarea
                  rows={2}
                  name="comment"
                  id="comment"
                  className="block w-4/5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none"
                  placeholder="Add a note..."
                  defaultValue={""}
                  ref={noteRef}
                />
                <SecondaryButton label="Add" callback={() => addNewNote()} />
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  };

  const closeUploadModal = () => {
    setUploadModal(false);
    setSelectedFile(null);
  };

  const saveNotesAndPhotoChanges = () => {
    if (newNotes.length === 0 && newPhotos.length === 0) {
      toast.error("No changes detected");
      closeNotesAndPhotos();
    } else {
      setLoading(true);
      let toSend = {
        notes: newNotes,
        photos: newPhotos,
      };
      AddNotesAndPhotos(jobId, openEq.equipmentId, toSend)
        .then((res) => {
          toast.success("Changes saved successfully");
          closeNotesAndPhotos();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error saving changes");
          setLoading(false);
        });
    }
  };

  const closeNotesAndPhotos = () => {
    setOpenEq({
      equipmentId: "",
      notes: [],
      photos: [],
      miles: { reading: null, date: null, enteredBy: null, enteredById: null },
    });
    setNotesAndPhotos(false);
    setNewNotes([]);
    setNewPhotos([]);
    if (noteRef.current) {
      noteRef.current.value = "";
    }
    setUploadModal(false);
    setSelectedFile(null);
  };

  const openNotesAndPhotos = (id) => {
    let data = equipment.find((eq) => eq.equipmentId === id);
    if (data) {
      setOpenEq(data);
      setNotesAndPhotos(true);
    } else {
      toast.error("Error fetching equipment data");
    }
  };

  const addNewNote = () => {
    let val = noteRef.current.value;
    if (val.length > 0) {
      setDummyLoading(true);
      let tmp = newNotes;
      tmp.push(val);
      setNewNotes(tmp);
      noteRef.current.value = "";
      setTimeout(() => setDummyLoading(false), 700);
    } else {
      toast.error("Please enter a note");
    }
  };

  const uploadFile = () => {
    let formData = new FormData();
    formData.set("file", selectedFile, selectedFile.name);
    setLoading(true);
    UploadEquipmentPhotos(formData)
      .then((res) => {
        setDummyLoading(true);
        let tmp = newPhotos;
        tmp.push(res.data[0].url);
        setNewPhotos(tmp);
        closeUploadModal();
        setDummyLoading(false);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error uploading file");
        setLoading(false);
      });
  };

  const DropzoneComponent = () => {
    return (
      <div className="flex flex-col items-center justify-center w-full">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p className="text-sm text-gray-600">Click to upload or drag and drop the file here</p>
        </div>
        <div className="flex flex-col items-center justify-start w-full gap-2 mt-5">
          {selectedFile ? (
            <div className="flex flex-col items-center justify-start w-full gap-1 text-xs">
              <p>File Name: {selectedFile.name ? selectedFile.name : "Unknown File"}</p>
            </div>
          ) : (
            <p className="text-sm text-gray-500">No file selected</p>
          )}
        </div>
      </div>
    );
  };

  const renderFileUploadModal = () => {
    return (
      <Transition.Root show={uploadModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeUploadModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="w-full px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                        <CloudUpload className="w-6 h-6 text-blue-600" aria-hidden="true" />
                      </div>
                      <div className="w-full mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Add Photos
                        </Dialog.Title>
                        <div className="w-full mt-2">{DropzoneComponent()}</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 px-6 py-3">
                    <SecondaryButton label="Cancel" callback={() => closeUploadModal()} />
                    <PrimaryButton label="Upload" callback={() => (selectedFile ? uploadFile() : toast.error("Please select a file first"))} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderOdometerModal = () => {
    return (
      <Modal
        open={odometerModal}
        onCancel={() => closeOdometerModal()}
        onOk={() => saveOdometerReading()}
        title="Enter Odometer Reading"
        centered
        destroyOnClose
        width={650}
      >
        <div className="flex flex-col items-start justify-start w-full">
          <label className="pb-2 text-xs text-gray-600 uppercase">Current Miles / Hours Reading</label>
          <InputNumber
            placeholder="Enter the current miles or hours reading"
            name="odometerReading"
            onChange={(v) => setOdometerReading(v)}
            className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 !focus:ring-0 !focus:shadow-none"
            controls={false}
          />
        </div>
      </Modal>
    );
  };

  const closeOdometerModal = () => {
    setOdometerModal(false);
    setOdometerReading(0);
  };

  const saveOdometerReading = () => {
    if (odometerReading && odometerReading > 0) {
      setLoading(true);
      SubmitEquipmentOdometerReading(jobId, odometerEquipId, {
        reading: odometerReading,
      })
        .then((res) => {
          toast.success("Odometer Reading Saved Successfully");
          closeOdometerModal();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error saving odometer reading");
          setTimeout(() => setLoading(false), 700);
        });
    } else {
      toast.error("Please enter a valid reading");
    }
  };

  const fetchEquipData = (id, field) => {
    let data = equipment.find((eq) => eq.equipmentId === id);
    if (data) {
      if (field.includes(".")) {
        let fields = field.split(".");
        let data1 = data[fields[0]];
        if (!data1) return null;
        return data1[fields[1]];
      } else {
        return data[field];
      }
    } else {
      return null;
    }
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "equipmentId",
      key: "equipmentType",
      render: (id) => {
        let v = fetchEquipData(id, "equipmentType");
        return eqType[v];
      },
    },
    {
      title: "Cust. Equip ID",
      dataIndex: "equipmentId",
      key: "customerEquipId",
      render: (id) => {
        let v = fetchEquipData(id, "customerEquipId");
        return v ? v : "Missing";
      },
    },
    {
      title: "Status",
      dataIndex: "equipmentId",
      key: "details.status",
      render: (id) => {
        let v = fetchEquipData(id, "details.status");
        return eqStatus[v];
      },
    },
    {
      title: "Make",
      dataIndex: "equipmentId",
      key: "details.make",
      render: (id) => {
        let v = fetchEquipData(id, "details.make");
        return v ? v : "Missing";
      },
    },
    {
      title: "Miles/Hrs",
      dataIndex: "miles",
      key: "miles",
      render: (v, r) => (v && v.reading ? v.reading + ` ${equipment.find((e) => e.equipmentId === r.equipmentId)?.milesUnit ?? ""}` : "Missing"),
    },
    {
      title: "",
      dataIndex: "equipmentId",
      key: "equipmentId",
      render: (v) => (
        <div className="flex flex-row items-center justify-end gap-2">
          <SecondaryButton label="ODO Reading" callback={() => openOdometerModal(v)} />
          <SecondaryButton label="Notes/Photos" callback={() => openNotesAndPhotos(v)} />
          <IconButton
            icon={<Trash2 className="w-auto h-4 text-red-500 group-hover:text-red-700 group-disabled:text-red-300 group-disabled:hover:text-red-300" />}
            callback={() => removeEqFromJob(v)}
            className="ring-red-500 group hover:ring-red-700 disabled:ring-red-300 disabled:hover:ring-red-300"
          />
        </div>
      ),
    },
  ];

  const openOdometerModal = (id) => {
    setOdometerEquipId(id);
    setOdometerModal(true);
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Equipment - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      value={tab.name}
                      key={uuidv4()}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Job Equipment - {job.jobNo}</p>
              {job.jobStatus === "open" && <SecondaryButton label="Manage Job Equipment" callback={() => setEquipmentDrawer(true)} />}
            </div>
            <Table dataSource={job.equipment} columns={columns} pagination={false} className="w-full" loading={loading} />
          </div>
          {renderEquipmentDrawer()}
          {renderNotesPhotosModal()}
          {renderFileUploadModal()}
          {renderOdometerModal()}
        </>
      )}
    </div>
  );
};

export default JobEquipment;
