import { useState, useEffect, useRef, Fragment } from "react";
import { Cancel } from "iconoir-react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { GetAllParts, AddNewPart, GetDepartmentsAndVendors } from "../../actions/ims";
import { toast } from "react-hot-toast";
import { formatCurrency } from "../../components/tools";
import { Dialog, Transition } from "@headlessui/react";
import { Input, InputNumber, Modal, Select } from "antd";
import { Helmet } from "react-helmet-async";

const NewPart = () => {
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [newPartData, setNewPartData] = useState({
    department: "",
    partNo: "",
    category: "",
    description: "",
    unit: "",
    cost: 0,
    markup: 0,
    chargeOut: 0,
    manufacturer: "",
    defaultVendor: "",
    stock: [],
  });
  const [addVendor, setAddVendor] = useState(false);
  const [cost, setCost] = useState(0);
  const [markup, setMarkup] = useState(0);
  const [chargeOut, setChargeOut] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;

    if (inView) {
      GetDepartmentsAndVendors()
        .then((res) => {
          let dpts = [];
          let i = res.data.departments.length;
          while (i--) {
            dpts.push({
              label: res.data.departments[i].name,
              value: res.data.departments[i].departmentId,
            });
          }
          setDepartments(dpts);
          let vndr = [];
          let j = res.data.vendors.length;
          while (j--) {
            vndr.push({
              label: res.data.vendors[j].vendorCode + (res.data.vendors[j].vendorName.length > 0 ? " | " + res.data.vendors[j].vendorName : ""),
              value: res.data.vendors[j].vendorId,
            });
          }
          setVendors(vndr);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to load departments and vendors");
          navigate("/parts");
        });
    }

    return () => {
      inView = false;
    };
  }, []);

  const createPartProfile = () => {
    let failed = false;
    let data = newPartData;
    data.cost = cost;
    data.markup = markup;
    data.chargeOut = chargeOut;
    if (data.partNo === "") {
      toast.error("Part No is required");
      failed = true;
    } else if (data.department === "") {
      toast.error("Department is required");
      failed = true;
    } else if (data.category === "") {
      toast.error("Category is required");
      failed = true;
    } else if (data.description === "") {
      toast.error("Description is required");
      failed = true;
    } else if (data.unit === "") {
      toast.error("Unit is required");
      failed = true;
    } else if (data.manufacturer === "") {
      toast.error("Manufacturer is required");
      failed = true;
    } else if (data.defaultVendor === "") {
      toast.error("Default Vendor is required");
      failed = true;
    }

    if (!failed) {
      AddNewPart(data)
        .then((res) => {
          toast.success("Part added successfully");
          navigate(`/parts/${res.data.partId}`);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error creating part profile. Please try again");
        });
    }
  };

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const openAddVendor = () => {
    setAddVendor(true);
    setTimeout(() => window.open("/vendors/new", "_blank"), 2500);
  };

  const closeAddVendor = () => {
    setLoading(true);
    setAddVendor(false);
    GetDepartmentsAndVendors()
      .then((res) => {
        let dpts = [];
        let i = res.data.departments.length;
        while (i--) {
          dpts.push({
            label: res.data.departments[i].name,
            value: res.data.departments[i].departmentId,
          });
        }
        setDepartments(dpts);
        let vndr = [];
        let j = res.data.vendors.length;
        while (j--) {
          vndr.push({
            label: res.data.vendors[j].vendorCode + (res.data.vendors[j].vendorName.length > 0 ? " | " + res.data.vendors[j].vendorName : ""),
            value: res.data.vendors[j].vendorId,
          });
        }
        setVendors(vndr);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to load departments and vendors");
        navigate("/parts");
      });
  };

  const updateValue = (key, value) => {
    let tmp = newPartData;
    tmp[key] = value;
    setNewPartData(tmp);
  };

  const updatePrice = (key, value) => {
    if (key === "cost") {
      setCost(value);
      if (markup === 0 || markup === "") {
        setChargeOut(value);
      } else {
        let tmpMarkup = parseFloat(markup);
        let tmpCost = parseFloat(value);
        let chargeOut = tmpCost + tmpCost * (tmpMarkup / 100);
        setChargeOut(chargeOut.toFixed(2));
      }
    } else if (key === "markup") {
      setMarkup(value);
      if (value === 0 || value === "" || value === "0") {
        setChargeOut(cost);
      } else {
        let tmpMarkup = parseFloat(value);
        let chargeOut = parseFloat(cost) + parseFloat(cost) * (tmpMarkup / 100);
        setChargeOut(chargeOut.toFixed(2));
      }
    } else if (key === "chargeOut") {
      setChargeOut(value);
      let tmpMarkup = parseFloat(((value - cost) / cost) * 100);
      setMarkup(tmpMarkup.toFixed(2));
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <div className="flex flex-row items-center justify-between w-full max-w-4xl my-2">
        <p className="text-xl font-bold uppercase">New Part Profile</p>
        <SecondaryButton label="Cancel" callback={() => navigate("/parts")} />
      </div>
      {!loading ? (
        <div className="flex flex-col items-center justify-start w-full max-w-4xl gap-4 py-5 my-5 border-gray-300 border-y">
          <Helmet>
            <title>New Part Profile | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-between w-full gap-4">
            <div className="flex flex-col items-start justify-start w-1/2">
              <label className="block mb-1.5 pl-1 text-xs font-medium text-gray-700 uppercase">Part Number</label>
              <Input
                placeholder="Part Number"
                className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                defaultValue={newPartData.partNo}
                onChange={(e) => updateValue("partNo", e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-1/2">
              <label className="block mb-1.5 pl-1 text-xs font-medium text-gray-700 uppercase">Manufacturer</label>
              <Input
                placeholder="Manufacturer"
                className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                defaultValue={newPartData.manufacturer}
                onChange={(e) => updateValue("manufacturer", e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full">
            <label className="block mb-1.5 pl-1 text-xs font-medium text-gray-700 uppercase">Description</label>
            <Input.TextArea
              placeholder="Description"
              rows={4}
              className="border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 px-3 disabled:bg-white disabled:text-gray-600 !resize-none"
              defaultValue={newPartData.description}
              onChange={(e) => updateValue("description", e.target.value)}
            />
          </div>
          <div className="flex flex-row items-center justify-between w-full gap-4">
            <div className="flex flex-col items-start justify-start w-1/2">
              <label className="block mb-1.5 pl-1 text-xs font-medium text-gray-700 uppercase">Department</label>
              <Select
                placeholder="Department"
                options={departments}
                className="w-full"
                showSearch
                filterOption={filterOption}
                onSelect={(v) => updateValue("department", v)}
                defaultValue={newPartData.department.length > 0 ? newPartData.department : null}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-1/2">
              <label className="block mb-1.5 pl-1 text-xs font-medium text-gray-700 uppercase">Category</label>
              <Select
                placeholder="Category"
                showSearch
                filterOption={filterOption}
                options={[
                  { label: "Parts", value: "parts" },
                  { label: "Gas, Oil & Grease", value: "gog" },
                  { label: "Fluids", value: "fluids" },
                ]}
                className="w-full"
                onSelect={(v) => updateValue("category", v)}
                defaultValue={newPartData.category.length > 0 ? newPartData.category : null}
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-between w-full gap-4">
            <div className="flex flex-col items-start justify-start w-1/2">
              <label className="block mb-1.5 pl-1 text-xs font-medium text-gray-700 uppercase">Unit</label>
              <Select
                placeholder="Unit"
                showSearch
                filterOption={filterOption}
                options={[
                  { label: "Each", value: "each" },
                  { label: "Quart", value: "quart" },
                  { label: "Gallon", value: "gallon" },
                  { label: "Kit", value: "kit" },
                  { label: "Pound", value: "pound" },
                  { label: "Pair", value: "pair" },
                  { label: "Set", value: "set" },
                  { label: "Tube", value: "tube" },
                  { label: "Can", value: "can" },
                  { label: "Foot", value: "foot" },
                  { label: "Meter", value: "meter" },
                ]}
                className="w-full"
                onSelect={(v) => updateValue("unit", v)}
                defaultValue={newPartData.unit.length > 0 ? newPartData.unit : null}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-1/2">
              <label className="block mb-1.5 pl-1 text-xs font-medium text-gray-700 uppercase">Cost</label>
              <InputNumber
                className="block w-full py-1 text-sm text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                placeholder="Cost"
                addonBefore="$"
                controls={false}
                value={cost}
                onChange={(v) => updatePrice("cost", v)}
                step={"0.01"}
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-between w-full gap-4">
            <div className="flex flex-col items-start justify-start w-1/2">
              <label className="block mb-1.5 pl-1 text-xs font-medium text-gray-700 uppercase">Markup</label>
              <InputNumber
                className="block w-full py-1 text-sm text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                placeholder="Markup"
                addonAfter="%"
                controls={false}
                value={markup}
                onChange={(v) => updatePrice("markup", v)}
                step={"0.01"}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-1/2">
              <label className="block mb-1.5 pl-1 text-xs font-medium text-gray-700 uppercase">Charge Out</label>
              <InputNumber
                className="block w-full py-1 text-sm text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:bg-white disabled:text-gray-600"
                placeholder="Charge Out"
                addonBefore="$"
                controls={false}
                value={chargeOut}
                step={"0.01"}
                onChange={(v) => updatePrice("chargeOut", v)}
              />
            </div>
          </div>
          <div className="flex flex-row items-end justify-between w-full gap-4">
            <div className="flex flex-col items-start justify-start flex-grow">
              <label className="block mb-1.5 pl-1 text-xs font-medium text-gray-700 uppercase">Default Vendor</label>
              <Select
                placeholder="Default Vendor"
                options={vendors}
                className="w-full"
                showSearch
                filterOption={filterOption}
                onSelect={(v) => updateValue("defaultVendor", v)}
                defaultValue={newPartData.defaultVendor.length > 0 ? newPartData.defaultVendor : null}
              />
            </div>
            <div className="flex flex-col justify-end items-end h-full pb-0.5">
              <SecondaryButton label="Add Vendor" callback={() => openAddVendor()} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full max-w-4xl gap-4 py-5 my-5 border-gray-300 border-y">
          <p className="text-sm font-medium text-gray-700 uppercase animate-pulse">Loading</p>
        </div>
      )}
      <Modal
        open={addVendor}
        footer={[
          <div className="flex flex-row items-center justify-end w-full">
            <SecondaryButton label="Continue" callback={() => closeAddVendor()} />
          </div>,
        ]}
        centered
        title="Add a new Vendor"
        closable={false}
        width={680}
      >
        <div className="flex flex-col items-start justify-start w-full h-full gap-1 py-5">
          <p className="font-medium text-gray-500 uppercase">A new tab will open shortly. Create a new vendor profile, then return here.</p>
          <p className="font-medium text-gray-500 uppercase">Finally, click on continue to refresh the list of vendors.</p>
        </div>
      </Modal>
      <div className="flex flex-row items-center justify-end w-full max-w-4xl">
        <PrimaryButton label="Create Part Profile" callback={() => createPartProfile()} />
      </div>
    </div>
  );
};

export default NewPart;
