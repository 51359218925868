import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import validator from "validator";
import { CheckInvite, RegisterEmployee } from "../../actions/auth";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader";
import { Helmet } from "react-helmet-async";

const Invite = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const navigate = useNavigate();

  const { inviteId } = useParams();

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (authState.isAuth) {
        navigate("/");
      }
      if (inviteId && inviteId !== "") {
        CheckInvite({ inviteId })
          .then((res) => {
            emailRef.current.value = res.data.email;
            setEmail(res.data.email);
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "An error occurred");
            setTimeout(() => navigate("/"), 1500);
          });
      } else {
        toast.error("Invalid invite link");
        setTimeout(() => navigate("/"), 1500);
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const updateEmail = (e) => {
    setEmail(e.target.value);
    setEmailError(null);
  };

  const updatePassword = (e) => {
    setPassword(e.target.value);
    setPasswordError(null);
  };

  const updateConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError(null);
  };

  const submitLogin = () => {
    if (!validator.isEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    if (password === "") {
      setPasswordError("Please enter a valid password");
      return;
    }
    if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords must match");
      return;
    }
    if (!loading) {
      setLoading(true);
      RegisterEmployee({ inviteId, email, password, confirmPassword })
        .then((res) => {
          toast.success("Account created successfully, logging you in!");
          let payload = {
            token: res.data.token,
            email: email,
            user: res.data.user,
          };
          localStorage.setItem("auth-token", res.data.token);
          authDispatch({
            type: "valid-login",
            payload: payload,
          });
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 1000);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred");
          setLoading(false);
        });
    }
  };

  return (
    <div className="flex flex-row items-center justify-between w-full h-screen">
      {loading && <Loader />}
      <Helmet>
        <title>Create Employee Account | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center w-full h-screen md:w-1/2 bg-white-smoke">
        <div className="flex flex-col items-start justify-start w-3/4">
          <h1 className="text-2xl font-medium">Create Employee Account</h1>
          <p className="mt-3 text-sm font-light">Your journey beings here</p>
          <div className="flex flex-col items-start justify-start w-full mt-10">
            <p className="text-sm font-semibold">E-Mail</p>
            <input
              placeholder="Email"
              type="email"
              className="w-full px-3 py-2 mt-1 bg-gray-100 rounded shadow-sm cursor-not-allowed"
              ref={emailRef}
              onChange={(e) => updateEmail(e)}
              disabled
            />
          </div>
          <p className="w-full h-4 px-2 text-xs font-medium text-right text-red-500">{emailError}</p>
          <div className="flex flex-col items-start justify-start w-full mt-1">
            <p className="text-sm font-semibold">Your Password</p>
            <input
              placeholder="Password"
              type="password"
              className="w-full px-3 py-2 mt-1 rounded shadow-sm"
              ref={passwordRef}
              onChange={(e) => updatePassword(e)}
            />
          </div>
          <p className="w-full h-4 px-2 text-xs font-medium text-right text-red-500">{passwordError}</p>
          <div className="flex flex-col items-start justify-start w-full mt-1">
            <p className="text-sm font-semibold">Confirm Password</p>
            <input
              placeholder="Password"
              type="password"
              className="w-full px-3 py-2 mt-1 rounded shadow-sm"
              ref={confirmPasswordRef}
              onChange={(e) => updateConfirmPassword(e)}
            />
          </div>
          <p className="w-full h-4 px-2 text-xs font-medium text-right text-red-500">{confirmPasswordError}</p>
          <div className="w-full mt-4 mb-3 border-t border-slate-200" />
          <div className="flex flex-row justify-end w-full">
            <button
              type="button"
              onClick={() => submitLogin()}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-5 py-2 text-xs font-semibold uppercase text-white shadow-sm hover:bg-blue-500"
            >
              Create Account
            </button>
          </div>
        </div>
      </div>
      <div className="h-screen w-1/2 hidden md:flex flex-col items-center bg-gradient-to-bl from-[#0575e6] to-[#021b79] justify-center py-10">
        <div className="flex items-center justify-center w-full h-3/4">
          <img src="https://app.jetic.io/static/media/ControlHub.c38e37fd.png" className="w-1/2 h-auto" />
        </div>
        <div className="flex flex-col items-center justify-center w-2/3 gap-1 text-white">
          <h1 className="text-lg font-medium">Meet the HiTech Power Systems ERP</h1>
          <p className="text-xs font-light text-center">
            HiTech's Enterprise Resource Planning Platform ensures you have control and visibility across ongoing projects, inventory, accounting and more!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Invite;
