import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import StateList from "./stateList";
import { GetEmployees } from "../actions/auth";
import { GetFullCustomerList } from "../actions/customers";
import { Input, InputNumber, Select } from "antd";

export const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const DynamicField = ({ inputType, fieldName, defaultValue, placeholder, value, options, config, optionsPass, control, ...rest }) => {
  let [employees, setEmployees] = useState([]);
  let [employeeLoading, setEmployeeLoading] = useState(true);
  let [customers, setCustomers] = useState([]);
  let [customerLoading, setCustomerLoading] = useState(true);
  const { register } = useFormContext();

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (inputType === "employee-picker") {
        GetEmployees()
          .then((res) => {
            setEmployees(res.data);
            setEmployeeLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (inputType === "customer-picker") {
        GetFullCustomerList()
          .then((res) => {
            setCustomers(res.data);
            setCustomerLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  if (inputType === "text") {
    return (
      <input
        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
        type="text"
        id={fieldName}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...register(fieldName, config)}
        maxLength={75}
      />
    );
  } else if (inputType === "email") {
    return (
      <input
        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
        type="email"
        id={fieldName}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...register(fieldName, config)}
        maxLength={75}
      />
    );
  } else if (inputType === "state-picker") {
    return (
      <select
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
        id={fieldName}
        defaultValue={defaultValue}
        {...register(fieldName, config)}
      >
        <option hidden disabled value="">
          Select One
        </option>
        {StateList.map((state) => (
          <option key={state.code} value={state.code}>
            {state.name}
          </option>
        ))}
      </select>
    );
  } else if (inputType === "select") {
    return (
      <select
        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
        id={fieldName}
        defaultValue={defaultValue ? defaultValue : value ? value : ""}
        // value={value}
        {...register(fieldName, config)}
      >
        <option hidden disabled value="">
          Select One
        </option>
        {options.map((options) => (
          <option key={options.value} value={options.value}>
            {options.label}
          </option>
        ))}
      </select>
    );
  } else if (inputType === "phone") {
    return (
      <input
        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
        type="tel"
        id={fieldName}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...register(fieldName, config)}
        maxLength={10}
      />
    );
  } else if (inputType === "number") {
    return (
      <input
        className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
        type="number"
        id={fieldName}
        defaultValue={defaultValue}
        placeholder={placeholder}
        step="0.01"
        {...register(fieldName, config)}
      />
    );
  } else if (inputType === "checkbox") {
    return (
      <div className="flex items-center justify-between w-full my-3">
        <div className="text-sm leading-6 uppercase">
          <label className="text-gray-900">{rest.label}</label>
        </div>
        <div className="flex items-center h-6">
          <input
            type="checkbox"
            id={fieldName}
            className="w-5 h-5 text-blue-600 border-gray-300 rounded ring-0 focus:ring-0"
            defaultChecked={defaultValue}
            {...register(fieldName, config)}
          />
        </div>
      </div>
    );
  } else if (inputType === "employee-picker") {
    return !employeeLoading ? (
      <select
        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
        id={fieldName}
        defaultValue={defaultValue}
        {...register(fieldName, config)}
      >
        <option hidden disabled value="">
          Select One
        </option>
        <option key="blank" value="">
          BLANK
        </option>
        {employees.map((employee) => (
          <option key={employee.employeeId} value={employee.employeeId}>
            {employee.name}
          </option>
        ))}
      </select>
    ) : (
      <p className="text-sm font-semibold text-gray-400 uppercase">Loading...</p>
    );
  } else if (inputType === "customer-picker") {
    return !customerLoading ? (
      <Controller
        control={control}
        name={fieldName}
        rules={config}
        defaultValue={defaultValue}
        render={(props) => (
          <Select
            placeholder={"Select a customer"}
            ref={props.field.ref}
            name={fieldName}
            onBlur={props.field.onBlur}
            onChange={props.field.onChange}
            options={customers.map((c) => ({
              value: c.customerId,
              label: `${c.customerCode}${c.company.length > 0 ? " | " + c.company : ""}`,
            }))}
            defaultValue={value ? value : defaultValue ? defaultValue : null}
            {...props}
            className="w-full font-sans"
            controls={false}
            showSearch
            filterOption={filterOption}
          />
        )}
      />
    ) : (
      <p className="text-sm font-semibold text-gray-400 uppercase">Loading...</p>
    );
  } else if (inputType === "divider") {
    return <div className="w-full my-2 border-t border-gray-400" />;
  } else if (inputType === "divider-label") {
    return (
      <div className="flex flex-row items-center justify-center w-full gap-3 my-2">
        <div className="w-full border-t border-gray-400" />
        <p className="w-full text-sm font-medium text-center uppercase min-w-fit">{rest.label}</p>
        <div className="w-full border-t border-gray-400" />
      </div>
    );
  } else if (inputType === "label") {
    return <p className="w-full text-sm font-semibold text-center text-gray-600 uppercase">{rest.label}</p>;
  } else if (inputType === "textarea") {
    return (
      <textarea
        rows={4}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none"
        id={fieldName}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...register(fieldName, config)}
      />
    );
  } else if (inputType === "textfield") {
    return (
      <Controller
        control={control}
        name={fieldName}
        rules={config}
        defaultValue={defaultValue}
        render={(props) => (
          <Input
            placeholder={placeholder}
            ref={props.field.ref}
            name={fieldName}
            onBlur={props.field.onBlur}
            onChange={(e) => {
              props.field.onChange(e.target.value);
            }}
            defaultValue={value}
            {...props}
            className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
          />
        )}
      />
    );
  } else if (inputType === "number-field") {
    return (
      <Controller
        control={control}
        name={fieldName}
        rules={config}
        defaultValue={defaultValue}
        render={(props) => (
          <InputNumber
            placeholder={placeholder}
            ref={props.field.ref}
            name={fieldName}
            onBlur={props.field.onBlur}
            onChange={props.field.onChange}
            defaultValue={value ? value : defaultValue ? defaultValue : null}
            {...props}
            className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
            controls={false}
          />
        )}
      />
    );
  } else if (inputType === "select-field") {
    return (
      <Controller
        control={control}
        name={fieldName}
        rules={config}
        defaultValue={defaultValue}
        render={(props) => (
          <Select
            placeholder={placeholder}
            ref={props.field.ref}
            name={fieldName}
            onBlur={props.field.onBlur}
            onChange={props.field.onChange}
            options={options}
            defaultValue={value ? value : defaultValue ? defaultValue : null}
            {...props}
            className="w-full font-sans"
            controls={false}
          />
        )}
      />
    );
  } else if (inputType === "textarea-field") {
    return (
      <Controller
        control={control}
        name={fieldName}
        rules={config}
        defaultValue={defaultValue}
        render={(props) => (
          <Input.TextArea
            placeholder={placeholder}
            ref={props.field.ref}
            name={fieldName}
            onBlur={props.field.onBlur}
            onChange={(e) => {
              props.field.onChange(e.target.value);
            }}
            rows={4}
            defaultValue={value}
            {...props}
            autoSize={false}
            className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
          />
        )}
      />
    );
  } else {
    return <input className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6" type="text" />;
  }
};
