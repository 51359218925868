import { useEffect, useState, Fragment, useRef } from "react";
import dayjs from "dayjs";
import { Dialog, Transition } from "@headlessui/react";
import { formatCurrency } from "../../components/tools";
import { useParams, useNavigate } from "react-router-dom";
import { GetOneEquipment, AddEquipmentService, AddServiceNote, UpdateEquipmentService } from "../../actions/ims";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { Calendar, Xmark } from "iconoir-react";
import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet-async";

const EquipmentServiceSchedule = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [equipment, setEquipment] = useState({});
  let [customer, setCustomer] = useState({});
  let [newServiceModal, setNewServiceModal] = useState(false);
  let [serviceDrawer, setServiceDrawer] = useState(false);
  let [serviceData, setServiceData] = useState({
    month: null,
    serviceType: "",
    serviceNotes: [],
    serviceId: "",
  });
  let [note, setNote] = useState("");
  let [dummyLoad, setDummyLoad] = useState(false);

  let { equipmentId } = useParams();
  let navigate = useNavigate();

  let noteRef = useRef();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneEquipment(equipmentId)
        .then((res) => {
          setEquipment(res.data.equipment);
          setCustomer(res.data.customer);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error loading equipment");
          setTimeout(() => navigate("/equipment"), 1800);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const tabs = [
    { name: "Basic Information", href: "#", current: false },
    { name: "Details", href: "details", current: false },
    { name: "Engine", href: "engine", current: false },
    { name: "ATS", href: "ats", current: false },
    { name: "Contact", href: "contact", current: false },
    { name: "Notes", href: "notes", current: false },
    { name: "Photos", href: "photos", current: false },
    { name: "Service Schedule", href: "serviceSchedule", current: true },
    { name: "History", href: "history", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", { month: "long" });
  };

  const renderNewServiceModal = () => {
    return (
      <Transition.Root show={newServiceModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeNewService}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                  <div className="flex flex-col items-start justify-start w-full gap-4">
                    <Dialog.Title as="h3" className="mt-3 text-base font-semibold leading-6 text-gray-900">
                      Schedule new service
                    </Dialog.Title>
                    <div className="w-full">
                      <label htmlFor="month" className="block text-sm font-medium leading-6 text-gray-900">
                        Service Month
                      </label>
                      <select
                        id="month"
                        name="month"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        defaultValue=""
                        onChange={(e) => changeData(e)}
                      >
                        <option value="" hidden>
                          Select One
                        </option>
                        <option value={1}>January</option>
                        <option value={2}>February</option>
                        <option value={3}>March</option>
                        <option value={4}>April</option>
                        <option value={5}>May</option>
                        <option value={6}>June</option>
                        <option value={7}>July</option>
                        <option value={8}>August</option>
                        <option value={9}>September</option>
                        <option value={10}>October</option>
                        <option value={11}>November</option>
                        <option value={12}>December</option>
                      </select>
                    </div>
                    <div className="w-full">
                      <label htmlFor="serviceType" className="block text-sm font-medium leading-6 text-gray-900">
                        Service Type
                      </label>
                      <select
                        id="serviceType"
                        name="serviceType"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        defaultValue=""
                        onChange={(e) => changeData(e)}
                      >
                        <option value="" hidden>
                          Select One
                        </option>
                        <option value="annual">Annual Service</option>
                        <option value="semiAnnual">Semi-Annual Service</option>
                        <option value="quarterly">Quarterly Service</option>
                        <option value="2hrLoadbank">2hr Loadbank Test</option>
                        <option value="4hrLoadbank">4hr Loadbank Test</option>
                        <option value="other">Other Service</option>
                      </select>
                    </div>
                    <div className="w-full">
                      <label htmlFor="note" className="block text-sm font-medium leading-6 text-gray-900">
                        Service Notes
                      </label>
                      <div className="mt-2">
                        <textarea
                          rows={4}
                          name="note"
                          id="note"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none"
                          defaultValue={""}
                          onChange={(e) => setNote(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5 sm:mt-6">
                    <SecondaryButton label="Cancel" callback={() => closeNewService()} />
                    <PrimaryButton label="Add Service" callback={() => addNewService()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const addNewService = () => {
    if (!serviceData.month) {
      toast.error("Please select a month during which this service is supposed to occur");
    } else if (serviceData.serviceType === "") {
      toast.error("Please select a service type");
    } else {
      let payload = {
        month: serviceData.month,
        serviceType: serviceData.serviceType,
        note: note,
      };
      setLoading(true);
      AddEquipmentService(equipmentId, payload)
        .then((res) => {
          toast.success("Service added successfully");
          closeNewService();
          GetOneEquipment(equipmentId)
            .then((res) => {
              setEquipment(res.data.equipment);
              setCustomer(res.data.customer);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Error loading equipment");
              setTimeout(() => navigate("/equipment"), 1800);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error adding service");
          setLoading(false);
        });
    }
  };

  const changeData = (e) => {
    let tmp = serviceData;
    tmp[e.target.name] = e.target.value;
    setServiceData(tmp);
  };

  const closeNewService = () => {
    setNewServiceModal(false);
    setServiceData({
      month: null,
      serviceType: "",
      serviceNotes: [],
      serviceId: "",
    });
    setNote("");
  };

  const renderServiceType = (type) => {
    switch (type) {
      case "annual":
        return "Annual Service";
      case "semiAnnual":
        return "Semi-Annual Service";
      case "quarterly":
        return "Quarterly Service";
      case "2hrLoadbank":
        return "2hr Loadbank Test";
      case "4hrLoadbank":
        return "4hr Loadbank Test";
      case "other":
        return "Other Service";
      default:
        return "Unknown Service";
    }
  };

  const renderServiceDrawer = () => {
    return (
      <Transition.Root show={serviceDrawer} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeService}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                    <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            View/Edit {renderServiceType(serviceData.serviceType)}
                          </Dialog.Title>
                          <div className="flex items-center ml-3 h-7">
                            <button
                              type="button"
                              className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none"
                              onClick={() => closeService()}
                            >
                              <span className="sr-only">Close panel</span>
                              <Xmark className="w-6 h-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex flex-col items-start justify-start flex-1 w-full h-full gap-4 px-4 mt-6 overflow-y-auto sm:px-6">
                        <div className="w-full">
                          <label htmlFor="month" className="block text-sm font-medium leading-6 text-gray-900">
                            Service Month
                          </label>
                          <select
                            id="month"
                            name="month"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            defaultValue={serviceData.month}
                            onChange={(e) => changeData(e)}
                          >
                            <option value="" hidden>
                              Select One
                            </option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                          </select>
                        </div>
                        <div className="w-full">
                          <label htmlFor="serviceType" className="block text-sm font-medium leading-6 text-gray-900">
                            Service Type
                          </label>
                          <select
                            id="serviceType"
                            name="serviceType"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            defaultValue={serviceData.serviceType}
                            onChange={(e) => changeData(e)}
                          >
                            <option value="" hidden>
                              Select One
                            </option>
                            <option value="annual">Annual Service</option>
                            <option value="semiAnnual">Semi-Annual Service</option>
                            <option value="quarterly">Quarterly Service</option>
                            <option value="2hrLoadbank">2hr Loadbank Test</option>
                            <option value="4hrLoadbank">4hr Loadbank Test</option>
                            <option value="other">Other Service</option>
                          </select>
                        </div>
                        <div className="flex flex-col items-start justify-start flex-grow w-full gap-2">
                          {serviceData.serviceNotes.map((note, index) => (
                            <div className="flex flex-col items-start justify-start w-full gap-1 px-3 py-2 bg-gray-100 rounded-md">
                              <div className="flex flex-row items-center justify-between w-full">
                                <p className="text-sm font-medium text-gray-400">{note.createdBy}</p>
                                <p className="text-xs text-gray-400">{dayjs(note.createdAt).format("MM/DD/YYYY [at] hh:mm A")}</p>
                              </div>
                              <p className="text-sm text-gray-800">{note.note}</p>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-col items-center justify-start w-full gap-2">
                          <div className="w-full">
                            <label htmlFor="note" className="block text-sm font-medium leading-6 text-gray-900">
                              New Note
                            </label>
                            <div className="mt-2">
                              <textarea
                                rows={4}
                                name="note"
                                id="note"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none"
                                defaultValue={""}
                                onChange={(e) => setNote(e.target.value)}
                                ref={noteRef}
                              />
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-end w-full gap-2 pb-2">
                            <SecondaryButton label="Add Note" callback={() => addNoteToService()} />
                          </div>
                          <div className="flex flex-row items-center justify-end w-full gap-2 py-2 border-t border-gray-300">
                            <SecondaryButton label="Close" callback={() => closeService()} />
                            <PrimaryButton label="Save Changes" callback={() => saveService()} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const saveService = () => {
    let payload = serviceData;
    if (note.length > 0) {
      payload.newNote = note;
    }
    setLoading(true);
    UpdateEquipmentService(equipmentId, serviceData.serviceId, payload)
      .then((res) => {
        toast.success("Service updated successfully!");
        closeService();
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error updating service");
        setLoading(false);
      });
  };

  const addNoteToService = () => {
    if (note.length === 0) {
      toast.error("Please enter a note to add to this service");
    } else {
      AddServiceNote(equipmentId, serviceData.serviceId, { note: note })
        .then((res) => {
          toast.success("Note added successfully");
          setDummyLoad(true);
          let tmp = serviceData;
          tmp.serviceNotes.push({
            createdBy: "You",
            createdAt: new Date(),
            note: note,
            noteId: uuidv4(),
          });
          setServiceData(tmp);
          noteRef.current.value = "";
          setNote("");
          setTimeout(() => setDummyLoad(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error adding note to service");
        });
    }
  };

  const closeService = () => {
    setLoading(true);
    setServiceDrawer(false);
    setServiceData({
      month: null,
      serviceType: "",
      serviceNotes: [],
      serviceId: "",
    });
    setNote("");

    GetOneEquipment(equipmentId)
      .then((res) => {
        setEquipment(res.data.equipment);
        setCustomer(res.data.customer);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error loading equipment");
        setTimeout(() => navigate("/equipment"), 1800);
      });
  };

  const openOneService = (data) => {
    setServiceData(data);
    setServiceDrawer(true);
  };

  const renderEqType = () => {
    return equipment.equipmentType === "airCompressor"
      ? "Air Compressor"
      : equipment.equipmentType === "pressureWasher"
        ? "Pressure Washer"
        : equipment.equipmentType[0].toUpperCase() + equipment.equipmentType.slice(1);
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>
              {renderEqType()} Service Schedule - {customer.customerCode} | HTPS ERP
            </title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/equipment/${equipmentId}/${v.target.value}`)}
              >
                {tabs.map((tab) => {
                  if ((tab.name === "ATS" && equipment.equipmentType === "truck") || (tab.name === "ATS" && equipment.equipmentType === "trailer")) {
                    return;
                  }
                  return <option value={tab.href}>{tab.name}</option>;
                })}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => {
                    if ((tab.name === "ATS" && equipment.equipmentType === "truck") || (tab.name === "ATS" && equipment.equipmentType === "trailer")) {
                      return;
                    }
                    return (
                      <p
                        key={tab.name}
                        onClick={() => navigate(`/equipment/${equipmentId}/${tab.href}`)}
                        className={classNames(
                          tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        {tab.name}
                      </p>
                    );
                  })}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full h-full gap-4 px-1 mb-5">
            <div className="flex flex-row items-center justify-between w-full pb-3 mb-5 border-b border-gray-300">
              <p className="font-semibold uppercase">Service Schedule</p>
              <SecondaryButton label="Add Service" callback={() => setNewServiceModal(true)} />
            </div>
            <div className="flex flex-col items-start justify-start flex-grow w-full gap-4 overflow-y-auto">
              {equipment.scheduledService.length > 0 ? (
                equipment.scheduledService.map((service, index) => (
                  <div className="flex flex-row items-center justify-between w-full px-4 py-4 bg-gray-100 rounded-md">
                    <div className="flex flex-col items-start justify-start gap-1">
                      <p className="font-semibold">{renderServiceType(service.serviceType)}</p>
                      <p className="text-sm">Scheduled for {getMonthName(service.month)}</p>
                      <p className="text-xs font-medium">Notes: {service.serviceNotes.length}</p>
                    </div>
                    <SecondaryButton label="Open" callback={() => openOneService(service)} />
                  </div>
                ))
              ) : (
                <p className="w-full text-sm font-semibold text-center uppercase text-gray-500/80">No Scheduled Services</p>
              )}
            </div>
          </div>
          {renderNewServiceModal()}
          {renderServiceDrawer()}
        </>
      )}
    </div>
  );
};

export default EquipmentServiceSchedule;
