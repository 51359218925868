import { SecondaryButton, PrimaryButton } from "../../components/buttons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { Input, Select, Table, Tooltip } from "antd";
import { QueryJobs } from "../../actions/jobs";
import { AdvancedQueryCustomers, QueryByEquipment, QueryCustomers } from "../../actions/customers";
import { QueryEquipment } from "../../actions/ims";

const _ = require("lodash");

const BLANK_FILTERS = {
  customer: {
    customerId: null,
    customerCode: null,
    company: null,
  },
  equipment: {
    equipmentId: null,
    customerEquipId: null,
    equipmentType: null,
    customerId: null,
    details: null,
    engine: null,
  },
  search: "",
};

const DEFAULT_SIZE = 12;

const AllCustomers = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [equipment, setEquipment] = useState([]);
  const [filters, setFilters] = useState(BLANK_FILTERS);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      runJobQuery(filters, page, size);
    }
    return () => {
      inView = false;
    };
  }, []);

  const clearFilters = () => {
    setLoading(true);
    setFilters(BLANK_FILTERS);
    setCustomers([]);
    setEquipment([]);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runJobQuery(BLANK_FILTERS, 0, DEFAULT_SIZE);
  };

  const updateFilter = ({ target: { name, value } }) => {
    let tmp = filters;
    tmp[name] = value;
    setFilters((prev) => ({ ...prev, [name]: value }));
    runJobQuery(tmp, page, size);
  };

  const handleCustomerSearch = (query) => {
    if (query?.length > 2) {
      QueryCustomers(query)
        .then((res) => {
          setCustomers(res.data);
        })
        .catch((err) => {
          toast.error("Customer search failed, please try again");
          setCustomers([]);
        });
    }
  };

  const updateCustomer = (v) => {
    let tmp = filters;
    if (v) {
      let qry = customers.find((c) => c.customerId === v);
      if (qry) {
        tmp.customer = { customerId: qry.customerId, customerCode: qry.customerCode, company: qry.company };
      } else {
        tmp.customer = { customerId: null, customerCode: null, company: null };
      }
    } else {
      tmp.customer = { customerId: null, customerCode: null, company: null };
    }
    setFilters(tmp);
    setCustomers(customers.filter((c) => c.customerId === v));
    runJobQuery(tmp, page, size);
  };

  const handleEquipmentSearch = (query) => {
    if (query?.length > 2) {
      QueryByEquipment({ search: query, customerId: filters.customer.customerId })
        .then((res) => {
          setEquipment(res.data.results);
        })
        .catch((err) => {
          toast.error("Equipment search failed, please try again");
          setEquipment([]);
        });
    }
  };

  const updateEquipment = (v) => {
    let tmp = filters;
    if (v) {
      let qry = equipment.find((e) => e.equipmentId === v);
      if (qry) {
        tmp.equipment = {
          equipmentId: qry.equipmentId,
          customerEquipId: qry.customerEquipId,
          equipmentType: qry.equipmentType,
          customerId: qry.customerId,
          details: qry.details,
          engine: qry.details,
        };
      } else {
        tmp.equipment = {
          equipmentId: null,
          customerEquipId: null,
          equipmentType: null,
          customerId: null,
          details: null,
          engine: null,
        };
      }
    } else {
      tmp.equipment = {
        equipmentId: null,
        customerEquipId: null,
        equipmentType: null,
        customerId: null,
        details: null,
        engine: null,
      };
    }
    setFilters(tmp);
    setEquipment(equipment.filter((e) => e.equipmentId === v));
    runJobQuery(tmp, page, size);
  };

  let eqTypes = {
    generator: "Generator",
    pressureWasher: "Pressure Washer",
    truck: "Truck",
    trailer: "Trailer",
    welder: "Welder",
    airCompressor: "Air Compressor",
    other: "Other",
  };

  const runJobQuery = (data, offset, limit) => {
    AdvancedQueryCustomers({ customerId: data.equipment.customerId }, offset, limit)
      .then((res) => {
        setCustomers(res.data.results);
        setTotal(res.data.total);
        setSize(res.data.size);
        setPage(res.data.page);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Failed to fetch customers");
        setCustomers([]);
        setTotal(0);
        setSize(DEFAULT_SIZE);
        setPage(0);
        setFilters(BLANK_FILTERS);
        setTimeout(() => setLoading(false), 700);
      });
  };

  const changePage = (pg, sz) => {
    setPage(pg);
    setSize(sz);
    setLoading(true);
    runJobQuery(filters, pg, sz);
  };

  const applyFilters = () => {
    setLoading(true);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runJobQuery(filters, 0, DEFAULT_SIZE);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Customers | HTPS ERP</title>
      </Helmet>
      <div className="flex-grow flow-root w-full px-2 mt-3">
        <div className="-my-2">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-2">
              <div className="grid w-full h-full gap-6 mx-auto md:grid-cols-12">
                <div className="flex flex-col h-full col-span-3 gap-4">
                  <div className="flex flex-col items-start justify-start w-full gap-4 p-6 border border-gray-300 rounded-lg">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Filters</h3>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="customer" className="pb-1 text-xs text-gray-600 uppercase">
                        Customer
                      </label>
                      <Select
                        placeholder="Search Customers"
                        onChange={(v) => updateCustomer(v)}
                        value={filters.customer.customerId}
                        options={(customers || []).map((p) => ({
                          label: `${p.customerCode} | ${p.company && p.company.length > 0 ? p.company : "No Company Provided"}`,
                          value: p.customerId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No customers found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onSearch={handleCustomerSearch}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="equipment" className="pb-1 text-xs text-gray-600 uppercase">
                        Equipment
                      </label>
                      <Select
                        placeholder="Search Equipment"
                        onChange={(v) => updateEquipment(v)}
                        value={filters.equipment.equipmentId}
                        options={(equipment || []).map((e) => ({
                          label: `${eqTypes[e.equipmentType]}${e?.details?.make?.length ? ` | ${e?.details?.make}` : ""}${e?.details?.model?.length ? ` | ${e?.details?.model}` : ""}${e?.customerEquipId?.length ? ` | ${e?.customerEquipId}` : ""}`,
                          value: e.equipmentId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No equipment found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onSearch={handleEquipmentSearch}
                        allowClear
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full gap-2">
                    <SecondaryButton
                      callback={() => clearFilters()}
                      label="Clear Filters"
                      className="block py-2.5 transition-all duration-300 normal-case w-full"
                    />
                    <PrimaryButton
                      callback={() => applyFilters()}
                      label="Apply Filters"
                      className="block py-2.5 transition-all duration-300 bg-black hover:bg-black/80 normal-case w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full col-span-9 gap-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Search Results</h3>
                    <button
                      className="inline-flex items-center justify-center h-8 px-4 py-2 leading-none text-sm font-medium transition-colors rounded-md whitespace-nowrap ring-offset-[#000064] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#18181b] focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#18181b] text-[#fafafa] hover:bg-[#18181b]/90"
                      onClick={() => navigate("/customers/new")}
                    >
                      New Customer
                    </button>
                  </div>
                  <Table
                    dataSource={customers}
                    loading={loading}
                    className="w-full"
                    sticky={true}
                    pagination={{
                      total: total,
                      responsive: true,
                      hideOnSinglePage: true,
                      onChange: (p, s) => changePage(p - 1, s),
                      pageSize: size,
                      current: page + 1,
                      showSizeChanger: false,
                    }}
                    rowKey="customerId"
                  >
                    <Table.Column title="Customer Code" dataIndex="customerCode" key="customerCode" width={"180px"} align="center" />
                    <Table.Column
                      title="Contact Person"
                      dataIndex="contact"
                      key="contact"
                      width={"260px"}
                      align="left"
                      render={(v, r) =>
                        `${r?.contact?.firstName} ${r?.contact?.lastName} ${r?.contact?.firstName?.length + r?.contact?.lastName?.length === 0 ? "Unknown Company" : ""}`
                      }
                    />
                    <Table.Column title="Company" dataIndex="company" key="company" render={(v) => (v.length > 0 ? v : "Unknown Company")} />
                    <Table.Column
                      title=""
                      dataIndex="customerId"
                      key="actions"
                      width={"80px"}
                      render={(v) => (
                        <SecondaryButton
                          callback={() => navigate(`/customers/${v}`)}
                          label="Open"
                          className="transition-all duration-300 border bg-white-smoke border-zinc-400 ring-0 hover:ring-0 hover:bg-zinc-100 hover:border-zinc-600 text-zinc-600 hover:text-zinc-900"
                        />
                      )}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCustomers;
