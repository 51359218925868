import { useEffect, useState, Fragment, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { GetOneJob, AddLoadbankToAJob, UpdateJobLoadbank, RemoveJobLoadbank } from "../../actions/jobs";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Cancel, EditPencil, Trash } from "iconoir-react";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";

const JobLoadbankCharges = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [job, setJob] = useState({});
  // eslint-disable-next-line
  let [location, setLocation] = useState({});
  let [addLoadbankModal, setAddLoadbankModal] = useState(false);
  let [loadbankData, setLoadbankData] = useState({
    description: "",
    unitCharge: "",
    loadbankId: "",
  });
  let [editLoadbankModal, setEditLoadbankModal] = useState(false);

  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneJob(jobId)
        .then((res) => {
          setJob(res.data.job);
          setLocation(res.data.location);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
          setTimeout(() => {
            navigate("/jobs");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const tabs = [
    { name: "Overview", href: "#", current: false },
    { name: "Parts", href: "parts", current: false },
    { name: "Labor", href: "labor", current: false },
    { name: "Parking/Storage", href: "parking", current: false },
    { name: "Loadbank", href: "loadbank", current: true },
    { name: "Freight", href: "freight", current: false },
    { name: "Misc", href: "misc", current: false },
    { name: "Purchase Orders", href: "purchaseOrders", current: false },
    {
      name: "Inventory Transmittals",
      href: "inventoryTransmittals",
      current: false,
    },
    { name: "Equipment", href: "equipment", current: false },
    { name: "Tests", href: "tests", current: false },
    { name: "Warranty", href: "warranty", current: false },
    { name: "Payments", href: "payments", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderLoadbankLine = (line, index) => {
    return (
      <div className="grid items-center w-full grid-cols-8 gap-4 py-3 border-b border-gray-300" key={uuidv4()}>
        <p className="capitalize">#{index + 1}</p>
        <p className="col-span-5 truncate">{line.description}</p>
        <p>{formatCurrency(line.unitCharge)}</p>
        {job.jobStatus === "open" && (
          <div className="flex flex-row items-center justify-center gap-2">
            <SecondaryButton label="Remove" callback={() => submitLoadbankRemove(line.loadbankId)} />
            <SecondaryButton label="Edit" callback={() => openLoadbankEdit(line.loadbankId)} />
          </div>
        )}
      </div>
    );
  };

  const renderAddLoadbankModal = () => {
    return (
      <Transition.Root show={addLoadbankModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddLoadbank}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Loadbank Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="description"
                            onChange={changeLoadbankData}
                          />
                        </div>
                        <div key="unitCharge" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="unitCharge" className="pb-1 text-xs text-gray-600 uppercase">
                            Loadbank Fee
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="unitCharge"
                            onChange={changeLoadbankData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeAddLoadbank()} />
                    <PrimaryButton label="Add Loadbank Fee" callback={() => addNewLoadbank()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAddLoadbank = () => {
    setAddLoadbankModal(false);
    setLoadbankData({
      description: "",
      unitCharge: 0,
      loadbankId: "",
    });
  };

  const changeLoadbankData = (e) => {
    let tmp = loadbankData;
    let { id, value } = e.target;
    tmp[id] = value;
    setLoadbankData(tmp);
  };

  const addNewLoadbank = () => {
    let failed = false;
    if (loadbankData.description === "") {
      toast.error("Please enter a loadbank fee description");
      failed = true;
    } else if (loadbankData.unitCharge === 0) {
      toast.error("Please enter a unit charge");
      failed = true;
    }

    if (!failed) {
      setLoading(true);
      AddLoadbankToAJob(jobId, loadbankData)
        .then((res) => {
          toast.success("Loadbank fee added successfully");
          closeAddLoadbank();
          GetOneJob(jobId)
            .then((res) => {
              setJob(res.data.job);
              setLocation(res.data.location);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
              setTimeout(() => {
                navigate("/jobs");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error adding loadbank fee to this job");
          setLoading(false);
        });
    }
  };

  const renderLoadbankEditModal = () => {
    return (
      <Transition.Root show={editLoadbankModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeLoadbankEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Loadbank Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="description"
                            defaultValue={loadbankData.description}
                            onChange={changeLoadbankData}
                          />
                        </div>
                        <div key="unitCharge" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="unitCharge" className="pb-1 text-xs text-gray-600 uppercase">
                            Loadbank Fee
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="unitCharge"
                            defaultValue={loadbankData.unitCharge}
                            onChange={changeLoadbankData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeLoadbankEdit()} />
                    <PrimaryButton label="Save" callback={() => submitLoadbankEdit()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const openLoadbankEdit = (id) => {
    let frt = _.find(job.loadbank, { loadbankId: id });
    if (frt) {
      setLoadbankData(frt);
      setTimeout(() => setEditLoadbankModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const closeLoadbankEdit = () => {
    setEditLoadbankModal(false);
    setLoadbankData({
      description: "",
      unitCharge: 0,
      loadbankId: "",
    });
  };

  const submitLoadbankEdit = () => {
    setLoading(true);
    UpdateJobLoadbank(jobId, loadbankData.loadbankId, loadbankData)
      .then((res) => {
        toast.success("Loadbank fee updated successfully");
        closeLoadbankEdit();
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setLocation(res.data.location);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating loadbank fee");
        setLoading(false);
      });
  };

  const submitLoadbankRemove = (loadbankId) => {
    setLoading(true);
    RemoveJobLoadbank(jobId, loadbankId)
      .then((res) => {
        toast.success("Loadbank fee removed successfully");
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setLocation(res.data.location);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error removing loadbank fee");
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Loadbank - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      value={tab.name}
                      key={uuidv4()}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Job Loadbank Charges</p>
              {job.jobStatus === "open" && <SecondaryButton label="Add a Loadbank Charge" callback={() => setAddLoadbankModal(true)} />}
            </div>
            <div className="grid w-full grid-cols-8 gap-4 py-3 border-b border-gray-300">
              <p className="text-xs font-semibold uppercase"></p>
              <p className="col-span-5 text-xs font-semibold uppercase">Description</p>
              <p className="text-xs font-semibold uppercase">Ext. Price</p>
              <div className="flex flex-row items-center justify-center"></div>
            </div>
            {job.loadbank.map((line, index) => renderLoadbankLine(line, index))}
          </div>
          {renderAddLoadbankModal()}
          {renderLoadbankEditModal()}
        </>
      )}
    </div>
  );
};

export default JobLoadbankCharges;
