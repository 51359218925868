import { useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate, useParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { SecondaryButton, PrimaryButton } from "../../../components/buttons";
import * as _ from "lodash";
import { GetData, GetVisualInspection, UpdateVisualInspection } from "../../../actions/checklist";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import validator from "validator";
import { DatePicker, Select, Modal } from "antd";
import dayjs from "dayjs";
import { visualInspectionForm } from "../../../data/checklistForms";
import FormController from "../../../components/FormController";
import ReactSignatureCanvas from "react-signature-canvas";
import { Helmet } from "react-helmet-async";

const EditVisualInspection = () => {
  let [loading, setLoading] = useState(true);
  let [signature, setSignature] = useState(false);
  // eslint-disable-next-line
  let [customers, setCustomers] = useState([]);
  let [employees, setEmployees] = useState([]);
  let [equipment, setEquipment] = useState([]);
  let [jobs, setJobs] = useState([]);
  let [data, setData] = useState({
    customerId: "",
    equipmentId: "",
    workOrder: "",
    date: "",
    technician: "",
    trailer: "",
    serial: "",
    dotNo: "",
    mfg: "",
    year: "",
    material: "",
    capacity: "",
    compartments: "",
    lining: "",
    specialService: "",
    tankMawp: "",
    steamPanel: "",
    defects: "",
    inspector: "",
    dotRegNo: "",
    lined: false,
    insulated: false,
    s1Corroded: false,
    s1Dents: false,
    s1Distortions: false,
    s1Welds: false,
    s1Leaks: false,
    s1Baffles: false,
    s1Valves: false,
    s1Lining: false,
    s1Tube: false,
    s1Cleanout: false,
    s2Corroded: false,
    s2Dents: false,
    s2Distortions: false,
    s2Welds: false,
    s2Leaks: false,
    s2Unsafe: false,
    s2Marking: false,
    meetsReq: false,
  });

  let canvasRef = useRef(null);
  let { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  let [search, setSearch] = useSearchParams();

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetData()
        .then((res) => {
          setCustomers(res.data.customers);
          setEquipment(res.data.equipment);
          setEmployees(res.data.employees);
          setJobs(res.data.jobs);
          let customerId = search.get("customerId");
          let jobId = search.get("jobId");
          if (customerId && jobId) {
            let tmp = data;
            tmp.customerId = customerId;
            tmp.workOrder = jobId;
            setData(tmp);
            setValue("customerId", customerId);
            setValue("workOrder", jobId);
          }
          GetVisualInspection(id)
            .then((res) => {
              setData(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(
                err.response.data ? err.response.data.message : "Something went wrong in retrieving this record. You will be redirected to the previous page.",
              );
              setTimeout(() => navigate("/checklists"), 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading base checklist information");
          setTimeout(() => {
            navigate("/tests");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isDirty, dirtyFields, isValid },
    setValue,
    trigger,
    getValues,
    setError,
    watch,
    resetField,
    control,
  } = formMethods;

  const onSubmit = (values) => {
    trigger();
    if (isValid) {
      values.customerId = getValues("customerId");
      values.equipmentId = getValues("equipmentId");
      values.workOrder = getValues("workOrder");
      values.date = getValues("date");
      values.technician = getValues("technician");
      delete values.keepDefaults;
      delete values.formModified;
      delete values.modifiedFields;
      setData(values);
      setSignature(true);
    } else {
      toast.error("Please correct the highlighted fields");
    }
  };

  const submitInspection = () => {
    let toSend = data;
    if (canvasRef.isEmpty()) {
      toast.error("Please sign the inspection report");
    } else {
      setLoading(true);
      toSend.signature = canvasRef.toDataURL("png", 100);
      UpdateVisualInspection(toSend, id)
        .then((res) => {
          toast.success("Checklist updated successfully! You will be redirected shortly!");
          navigate(`/checklists/visualInspection/${res.data.id}`);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error updating checklist");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    let subscription = watch((values, { name }) => {
      if (name === "customerId") {
        resetField("equipmentId");
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const renderEquipmentType = (type) => {
    switch (type) {
      case "generator":
        return "Generator";
      case "pressureWasher":
        return "Pressure Washer";
      case "truck":
        return "Truck";
      case "trailer":
        return "Trailer";
      case "welder":
        return "Welder";
      case "airCompressor":
        return "Air Compressor";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const equipmentOptions = () => {
    let customerId = getValues("customerId");
    if (customerId && customerId.length > 30) {
      let filtered = equipment.filter((eq) => eq.customerId === customerId);
      let organized = filtered.map((eq) => ({
        value: eq.equipmentId,
        label: `${renderEquipmentType(eq.equipmentType)}${eq.equipmentType === "other" && eq.otherType.length > 0 ? " - " + eq.otherType : ""}${
          eq.details.make && eq.details.make.length > 0 ? " | " + eq.details.make : " | Unknown Make"
        }${eq.details.model && eq.details.model.length > 0 ? " | " + eq.details.model : " | Unknown Model"}`,
      }));
      return organized;
    } else {
      return [];
    }
  };

  const jobOptions = () => {
    let customerId = getValues("customerId");
    if (customerId && customerId.length > 30) {
      let filtered = jobs.filter((eq) => eq.customerId === customerId);
      let organized = filtered.map((eq) => ({
        value: eq.jobId,
        label: `${eq.jobNo}`,
      }));
      return organized;
    } else {
      return [];
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Edit Internal Visual Inspection Report | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Internal Visual Inspection Report</p>
              <SecondaryButton
                label="Cancel & Go back"
                callback={() => (location.key !== "default" ? navigate(-1) : navigate("/checklists", { replace: true }))}
              />
            </div>
            <div className="w-full flex-grow flex-col justify-start items-center overflow-y-auto h-full px-0.5 gap-5">
              <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row items-center justify-between w-full gap-5" key="upperForm">
                <FormProvider {...formMethods}>
                  <div className="grid w-full grid-cols-2 gap-2 pt-3">
                    <div key="customerId" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="customerId" className="pb-1 text-xs text-gray-600 uppercase">
                        Customer
                      </label>
                      <Controller
                        control={control}
                        defaultValue={data.customerId}
                        name="customerId"
                        rules={{
                          required: "Please select the customer this checklist is for",
                          validate: (value) => value.length >= 3 || "Please select the customer this checklist is for",
                        }}
                        render={(props) => (
                          <Select
                            placeholder={"Select a customer"}
                            ref={props.field.ref}
                            name={"customerId"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            options={customers.map((c) => ({
                              value: c.customerId,
                              label: `${c.customerCode}${c.company.length > 0 ? " | " + c.company : ""}`,
                            }))}
                            defaultValue={data.customerId}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="customerId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="equipmentId" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="equipmentId" className="pb-1 text-xs text-gray-600 uppercase">
                        Equipment
                      </label>
                      <Controller
                        control={control}
                        defaultValue={data.equipmentId}
                        name="equipmentId"
                        rules={{
                          required: "Please select the piece of equipment this checklist is for",
                          validate: (value) => value.length >= 3 || "Please select the piece of equipment this checklist is for",
                        }}
                        disabled={getValues("customerId") && getValues("customerId").length > 30 ? false : true}
                        render={(props) => (
                          <Select
                            placeholder={"Select One"}
                            ref={props.field.ref}
                            name={"equipmentId"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            options={equipmentOptions()}
                            disabled={getValues("customerId") && getValues("customerId").length > 30 ? false : true}
                            defaultValue={data.equipmentId}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="equipmentId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="workOrder" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="workOrder" className="pb-1 text-xs text-gray-600 uppercase">
                        Job / Work Order
                      </label>
                      <Controller
                        control={control}
                        defaultValue={data.workOrder}
                        name="workOrder"
                        rules={{
                          required: "Please select the piece of equipment this checklist is for",
                          validate: (value) => value.length >= 3 || "Please select the piece of equipment this checklist is for",
                        }}
                        disabled={getValues("customerId") && getValues("customerId").length > 30 ? false : true}
                        render={(props) => (
                          <Select
                            placeholder={"Select One"}
                            ref={props.field.ref}
                            name={"workOrder"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            options={jobOptions()}
                            disabled={getValues("customerId") && getValues("customerId").length > 30 ? false : true}
                            defaultValue={data.workOrder}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="workOrder" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="date" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="date" className="pb-1 text-xs text-gray-600 uppercase">
                        Inspection Date
                      </label>
                      <Controller
                        control={control}
                        name="date"
                        rules={{
                          required: false,
                          validate: (v) => dayjs(v).isValid() || "Date is required",
                        }}
                        defaultValue={data.date !== "" ? dayjs(data.date) : null}
                        render={({ field, fieldState }) => (
                          <DatePicker
                            placeholder="Inspection Date"
                            status={fieldState.error ? "error" : undefined}
                            ref={field.ref}
                            name={field.name}
                            format={"MM/DD/YYYY"}
                            onBlur={field.onBlur}
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(date) => {
                              field.onChange(date ? date.toJSON() : null);
                            }}
                            className="w-full mt-1 py-1.5"
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="date" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="technician" className="flex flex-col items-start justify-start w-full col-span-2">
                      <label htmlFor="technician" className="pb-1 text-xs text-gray-600 uppercase">
                        Technician
                      </label>
                      <Controller
                        control={control}
                        defaultValue={data.technician}
                        name="technician"
                        rules={{
                          required: "Please select the technician that completed this checklist",
                          validate: (value) => value.length >= 3 || "Please select the technician that completed this checklist",
                        }}
                        render={(props) => (
                          <Select
                            placeholder={"Select One"}
                            ref={props.field.ref}
                            name={"technician"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            options={employees.map((e) => ({
                              value: e.userId,
                              label: `${e.employeeCode} | ${e.firstName} ${e.lastName}`,
                            }))}
                            defaultValue={data.technician}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="technician" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                  </div>
                </FormProvider>
              </form>
              <div className="flex flex-row items-center justify-center w-full py-4 border-b border-gray-400" />
              <div className="flex flex-col items-center justify-start w-1/2 mx-auto my-5 mb-10">
                <FormController onSubmit={onSubmit} fields={visualInspectionForm} values={data} buttonText={"Submit Checklist"} fullWidth={true} />
              </div>
            </div>
          </div>
          <Modal
            open={signature}
            onClose={() => setSignature(false)}
            className="flex flex-col items-center justify-center w-full"
            centered
            title="Technician's Signature"
            footer={[]}
          >
            <ReactSignatureCanvas
              penColor="black"
              canvasProps={{
                width: 300,
                height: 80,
                className: "border border-dashed border-gray-300 mt-4 rounded-md mx-auto",
              }}
              backgroundColor="rgba(234,234,234,0.05)"
              ref={(ref) => {
                canvasRef = ref;
              }}
              throttle={10}
            />

            <div className="flex flex-row items-center justify-center w-full mt-2">
              <SecondaryButton label={"Clear Signature"} callback={() => canvasRef.clear()} />
            </div>
            <div className="flex flex-row items-center justify-end w-full gap-2 pt-3 mt-5 border-t border-gray-300">
              <SecondaryButton label={"Cancel"} callback={() => setSignature(false)} />
              <PrimaryButton label={"Submit"} callback={() => submitInspection()} />
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default EditVisualInspection;
