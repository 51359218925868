import { useEffect, useState } from "react";
import { SecondaryButton } from "../../components/buttons";
import { GetAllCannedFees } from "../../actions/jobs";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Input, InputNumber, Modal, Table } from "antd";
import { AddACannedFee, DeleteCannedFee, UpdateCannedFee } from "../../actions/admin";
import { formatCurrency } from "../../components/tools";
import { EditPencil, Trash } from "iconoir-react";
import { Helmet } from "react-helmet-async";

const CannedFees = () => {
  const [loading, setLoading] = useState(true);
  const [fees, setFees] = useState([]);
  const [feeData, setFeeData] = useState({
    name: null,
    price: 0,
    feeId: null,
  });
  const [newFeeModal, setNewFeeModal] = useState(false);
  const [editFeeModal, setEditFeeModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      reloadData();
    }
    return () => {
      inView = false;
    };
  }, []);

  const reloadData = () => {
    GetAllCannedFees()
      .then((res) => {
        setFees(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error fetching canned fees");
        navigate("/admin");
      });
  };

  const closeNewFeeModal = () => {
    setFeeData({
      name: null,
      price: 0,
      feeId: null,
    });
    setNewFeeModal(false);
  };

  const closeEditFeeModal = () => {
    setFeeData({
      name: null,
      price: 0,
      feeId: null,
    });
    setEditFeeModal(false);
  };

  const submitNewCannedFee = () => {
    let valid = true;
    if (!feeData.name || feeData.name.length < 3) {
      valid = false;
      toast.error("Please name this fee.");
    }
    if (feeData.price <= 0) {
      valid = false;
      toast.error("Please enter a valid price for this fee.");
    }
    if (valid) {
      setLoading(true);
      AddACannedFee(feeData)
        .then((res) => {
          toast.success("New fee added successfully.");
          closeNewFeeModal();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error adding new fee.");
          setLoading(false);
        });
    }
  };

  const renderAction = (feeId) => {
    return (
      <div className="flex flex-row items-center justify-end gap-3">
        <EditPencil className="text-blue-500 transition-all duration-200 cursor-pointer hover:text-blue-700" onClick={() => editFee(feeId)} />
        <Trash className="text-red-500 transition-all duration-200 cursor-pointer hover:text-red-700" onClick={() => removeFee(feeId)} />
      </div>
    );
  };

  const editFee = (feeId) => {
    let fee = fees.find((f) => f.feeId === feeId);
    setFeeData(fee);
    setEditFeeModal(true);
  };

  const removeFee = (feeId) => {
    setLoading(true);
    DeleteCannedFee(feeId)
      .then((res) => {
        toast.success("Fee removed successfully.");
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error removing fee.");
        setLoading(false);
      });
  };

  const submitCannedFeeEdit = () => {
    let valid = true;
    if (!feeData.name || feeData.name.length < 3) {
      valid = false;
      toast.error("Please name this fee.");
    }
    if (feeData.price <= 0) {
      valid = false;
      toast.error("Please enter a valid price for this fee.");
    }
    if (!feeData.feeId || feeData.feeId.length < 10) {
      valid = false;
      toast.error("Please enter a valid price for this fee.");
    }
    if (valid) {
      setLoading(true);
      UpdateCannedFee(feeData)
        .then((res) => {
          toast.success("Canned fee updated successfully.");
          closeEditFeeModal();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error updating canned fee.");
          setLoading(false);
        });
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Canned Misc. Fees | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-row items-center justify-between w-full gap-2 px-1 pb-1 border-b border-gray-300">
        <h1 className="mt-3 mb-3 text-3xl font-bold">Canned Misc. Fees</h1>
        <SecondaryButton callback={() => setNewFeeModal(true)} label={"Add a New Canned Fee"} />
      </div>
      <div className="flex-grow flow-root w-full px-4 mt-8">
        <Table dataSource={fees} loading={loading} rowKey="feeId">
          <Table.Column title="Fee Name" dataIndex="name" key="name" />
          <Table.Column title="Fee Price" dataIndex="price" key="price" render={(v) => formatCurrency(v)} />
          <Table.Column title="" dataIndex="feeId" key="feeId" render={(v) => renderAction(v)} />
        </Table>
      </div>
      <Modal
        centered
        width={600}
        title="Add a New Canned Fee"
        open={newFeeModal}
        onOk={() => submitNewCannedFee()}
        onCancel={() => closeNewFeeModal()}
        destroyOnClose
      >
        <p>Fee Name</p>
        <Input
          placeholder="Fee Name"
          onChange={(e) => setFeeData({ ...feeData, name: e.target.value })}
          className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
        />
        <p>Fee Price</p>
        <InputNumber
          placeholder="Price for the fee"
          onChange={(v) => setFeeData({ ...feeData, price: v })}
          defaultValue={feeData.price}
          className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
          controls={false}
        />
      </Modal>
      <Modal
        centered
        width={600}
        title="Edit a Canned Fee"
        open={editFeeModal}
        onOk={() => submitCannedFeeEdit()}
        onCancel={() => closeEditFeeModal()}
        okText="Save Changes"
        destroyOnClose
      >
        <p>Fee Name</p>
        <Input
          placeholder="Fee Name"
          onChange={(e) => setFeeData({ ...feeData, name: e.target.value })}
          defaultValue={feeData.name}
          className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
        />
        <p>Fee Price</p>
        <InputNumber
          placeholder="Price for the fee"
          onChange={(v) => setFeeData({ ...feeData, price: v })}
          defaultValue={feeData.price}
          className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
          controls={false}
        />
      </Modal>
    </div>
  );
};

export default CannedFees;
