import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "../components/buttons";
import { Helmet } from "react-helmet-async";

const NotFound = ({ authState, authDispatch }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Helmet>
        <title>Oops - Page Not Found | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center w-full px-1 mt-1 gap-7">
        <p className="w-full pb-5 text-xl font-bold text-center uppercase border-b border-gray-300">Oops. We couldn't take you there...</p>
        <p className="text-sm uppercase text-gray-500/90">
          The page you're trying to visit either doesn't exist or you don't have permissions to access it. If you think this is an issue, please contact the
          administration.
        </p>
        <SecondaryButton label="Go Back" callback={() => navigate(-1)} />
      </div>
    </div>
  );
};

export default NotFound;
