import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { formatCurrency } from "../../components/tools";
import { useParams, useNavigate } from "react-router-dom";
import { GetOneEquipment, UpdateEquipmentATS, UpdateEquipmentDetails, UpdateEquipmentEngine, UpdateEquipmentContact } from "../../actions/ims";
import toast from "react-hot-toast";
import { newEquipmentATS, addEquipmentBasics, newEquipmentContact, newEquipmentDetails, newEquipmentEngine } from "../../data/equipmentForms";
import FormController from "../../components/FormController";
import { Helmet } from "react-helmet-async";

const EquipmentContact = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [equipment, setEquipment] = useState({});
  let [customer, setCustomer] = useState({});

  let { equipmentId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneEquipment(equipmentId)
        .then((res) => {
          setEquipment(res.data.equipment);
          setCustomer(res.data.customer);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error loading equipment");
          setTimeout(() => navigate("/equipment"), 1800);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const tabs = [
    { name: "Basic Information", href: "#", current: false },
    { name: "Details", href: "details", current: false },
    { name: "Engine", href: "engine", current: false },
    { name: "ATS", href: "ats", current: false },
    { name: "Contact", href: "contact", current: true },
    { name: "Notes", href: "notes", current: false },
    { name: "Photos", href: "photos", current: false },
    { name: "Service Schedule", href: "serviceSchedule", current: false },
    { name: "History", href: "history", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const submitStep = (data) => {
    if (!data.formModified) {
      toast.error("No changes detected");
    } else {
      let payload = {};
      for (let property in data.modifiedFields) {
        payload[property] = data[property];
      }
      setLoading(true);
      UpdateEquipmentContact(equipmentId, payload)
        .then((res) => {
          toast.success("Equipment updated successfully");
          GetOneEquipment(equipmentId)
            .then((res) => {
              setEquipment(res.data.equipment);
              setCustomer(res.data.customer);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Error loading equipment");
              setTimeout(() => navigate("/equipment"), 1800);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error updating equipment");
          setLoading(false);
        });
    }
  };

  const renderEqType = () => {
    return equipment.equipmentType === "airCompressor"
      ? "Air Compressor"
      : equipment.equipmentType === "pressureWasher"
        ? "Pressure Washer"
        : equipment.equipmentType[0].toUpperCase() + equipment.equipmentType.slice(1);
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>
              {renderEqType()} Contact - {customer.customerCode} | HTPS ERP
            </title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/equipment/${equipmentId}/${v.target.value}`)}
              >
                {tabs.map((tab) => {
                  if ((tab.name === "ATS" && equipment.equipmentType === "truck") || (tab.name === "ATS" && equipment.equipmentType === "trailer")) {
                    return;
                  }
                  return <option value={tab.href}>{tab.name}</option>;
                })}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => {
                    if ((tab.name === "ATS" && equipment.equipmentType === "truck") || (tab.name === "ATS" && equipment.equipmentType === "trailer")) {
                      return;
                    }
                    return (
                      <p
                        key={tab.name}
                        onClick={() => navigate(`/equipment/${equipmentId}/${tab.href}`)}
                        className={classNames(
                          tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        {tab.name}
                      </p>
                    );
                  })}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
            <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
              <FormController onSubmit={submitStep} fields={newEquipmentContact} values={equipment.contact} buttonText={"Update"} fullWidth={true} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EquipmentContact;
