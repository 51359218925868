import axiosInstance from "./axios";
import qs from "qs";
import dayjs from "dayjs";
import { validate } from "uuid";

export const GetData = () => {
  return axiosInstance.get("/checklist/data");
};

export const SubmitNewAnnualMaintenanceChecklist = (data) => {
  return axiosInstance.post("/checklist/newAnnualMaintenance", data);
};

export const GetAnnualMaintenanceRecord = (id) => {
  return axiosInstance.get(`/checklist/annualMaintenance/${id}`);
};

export const SubmitNewSemiAnnualMaintenanceChecklist = (data) => {
  return axiosInstance.post("/checklist/newSemiAnnualMaintenance", data);
};

export const GetSemiAnnualMaintenanceRecord = (id) => {
  return axiosInstance.get(`/checklist/semiAnnualMaintenance/${id}`);
};

export const SubmitNewQuarterlyMaintenanceChecklist = (data) => {
  return axiosInstance.post("/checklist/newQuarterlyMaintenance", data);
};

export const GetQuarterlyMaintenanceRecord = (id) => {
  return axiosInstance.get(`/checklist/quarterlyMaintenance/${id}`);
};

export const SubmitNewMonthlyMaintenanceChecklist = (data) => {
  return axiosInstance.post("/checklist/newMonthlyMaintenance", data);
};

export const GetMonthlyMaintenanceRecord = (id) => {
  return axiosInstance.get(`/checklist/monthlyMaintenance/${id}`);
};

export const SubmitLoadbankTestLog = (data) => {
  return axiosInstance.post("/checklist/newLoadbankTestLog", data);
};

export const GetLoadbankTestLog = (id) => {
  return axiosInstance.get(`/checklist/loadbank/${id}`);
};

export const SubmitKMTankTrailerInspection = (data) => {
  return axiosInstance.post("/checklist/newKMTankTrailerInspection", data);
};

export const GetKMTankTrailerInspection = (id) => {
  return axiosInstance.get(`/checklist/kmTankTrailerInspection/${id}`);
};

export const SubmitLevelOneServiceChecklist = (data) => {
  return axiosInstance.post("/checklist/newLevelOneServiceChecklist", data);
};

export const GetLevelOneServiceChecklist = (id) => {
  return axiosInstance.get(`/checklist/levelOneServiceChecklist/${id}`);
};

export const SubmitCitgoInspection = (data) => {
  return axiosInstance.post("/checklist/newCitgoInspection", data);
};

export const GetCitgoInspection = (id) => {
  return axiosInstance.get(`/checklist/citgo/${id}`);
};

export const SubmitLeakageTest = (data) => {
  return axiosInstance.post("/checklist/newLeakageTest", data);
};

export const GetLeakageTest = (id) => {
  return axiosInstance.get(`/checklist/leakageTest/${id}`);
};

export const SubmitVisualInspection = (data) => {
  return axiosInstance.post("/checklist/newVisualInspection", data);
};

export const GetVisualInspection = (id) => {
  return axiosInstance.get(`/checklist/visualInspection/${id}`);
};

export const SubmitWetTestCertification = (data) => {
  return axiosInstance.post("/checklist/newWetTestCertification", data);
};

export const GetWetTestCertification = (id) => {
  return axiosInstance.get(`/checklist/wetTestCertification/${id}`);
};

export const SubmitExternalVisualInspection = (data) => {
  return axiosInstance.post("/checklist/newExternalVisualInspection", data);
};

export const GetExternalVisualInspection = (id) => {
  return axiosInstance.get(`/checklist/externalVisualInspection/${id}`);
};

export const GetAllChecklists = () => {
  return axiosInstance.get("/checklist");
};

export const GetJobChecklists = (id) => {
  return axiosInstance.get(`/checklist/job/${id}`);
};

export const UpdateAnnualMaintenanceChecklist = (data, id) => {
  return axiosInstance.put(`/checklist/annualMaintenance/${id}`, data);
};

export const UpdateSemiAnnualMaintenanceChecklist = (data, id) => {
  return axiosInstance.put(`/checklist/semiAnnualMaintenance/${id}`, data);
};

export const UpdateQuarterlyMaintenanceChecklist = (data, id) => {
  return axiosInstance.put(`/checklist/quarterlyMaintenance/${id}`, data);
};

export const UpdateMonthlyMaintenanceChecklist = (data, id) => {
  return axiosInstance.put(`/checklist/monthlyMaintenance/${id}`, data);
};

export const UpdateLevelOneChecklist = (data, id) => {
  return axiosInstance.put(`/checklist/levelOneServiceChecklist/${id}`, data);
};

export const UpdateLoadbankTestLog = (data, id) => {
  return axiosInstance.put(`/checklist/loadbank/${id}`, data);
};

export const UpdateLeakageTest = (data, id) => {
  return axiosInstance.put(`/checklist/leakageTest/${id}`, data);
};

export const UpdateWetTestCertification = (data, id) => {
  return axiosInstance.put(`/checklist/wetTestCertification/${id}`, data);
};

export const UpdateVisualInspection = (data, id) => {
  return axiosInstance.put(`/checklist/visualInspection/${id}`, data);
};

export const UpdateExternalVisualInspection = (data, id) => {
  return axiosInstance.put(`/checklist/externalVisualInspection/${id}`, data);
};

export const UpdateKMAnnualTankTrailerInspection = (data, id) => {
  return axiosInstance.put(`/checklist/kmTankTrailerInspection/${id}`, data);
};

export const UpdateCitgoInspection = (data, id) => {
  return axiosInstance.put(`/checklist/citgo/${id}`, data);
};

export const SubmitBuckeyeTrailerInspection = (data) => {
  return axiosInstance.post("/checklist/newBuckeyeTrailerInspection", data);
};

export const GetBuckeyeInspection = (id) => {
  return axiosInstance.get(`/checklist/buckeyeTrailerInspection/${id}`);
};

export const UpdateBuckeyeInspection = (data, id) => {
  return axiosInstance.put(`/checklist/buckeyeTrailerInspection/${id}`, data);
};

export const SubmitMagellanTrailerCertification = (data) => {
  return axiosInstance.post("/checklist/newMagellanTrailerInspection", data);
};

export const GetMagellanInspection = (id) => {
  return axiosInstance.get(`/checklist/magellanTrailerInspection/${id}`);
};

export const UpdateMagellanInspection = (data, id) => {
  return axiosInstance.put(`/checklist/magellanTrailerInspection/${id}`, data);
};

export const QueryChecklists = (data) => {
  let queryParams = {};
  if (data.customerId && validate(data.customerId)) {
    queryParams.customerId = data.customerId;
  }
  if (data.type && data.type.length > 2) {
    queryParams.type = data.type;
  }
  if (data.technician && data.technician.length > 2) {
    queryParams.technician = data.technician;
  }
  if (
    data.createdBetween &&
    data.createdBetween.length === 2 &&
    data.createdBetween[0] &&
    data.createdBetween[1] &&
    dayjs(data.createdBetween[0]).isValid() &&
    dayjs(data.createdBetween[1]).isValid()
  ) {
    if (!(dayjs(data.createdBetween[0]).isSame(dayjs("01/01/2006", "MM/DD/YYYY"), "day") && dayjs(data.createdBetween[1]).isSame(dayjs(), "day"))) {
      queryParams.createdBetween = [dayjs(data.createdBetween[0]).toJSON(), dayjs(data.createdBetween[1]).toJSON()];
    }
  }
  if (data.page) {
    queryParams.page = data.page;
  }
  if (data.limit) {
    queryParams.limit = data.limit;
  }
  let queryString = qs.stringify(queryParams);
  return axiosInstance.get(`/checklist/query?${queryString}`);
};
