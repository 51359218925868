import { useState } from "react";
import { Check } from "iconoir-react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import toast from "react-hot-toast";
import FormController from "../../components/FormController";
import { formatPhoneNumber } from "../../components/tools";
import { AddEquipmentToCustomer, GetFullCustomerList, GetOneCustomer } from "../../actions/customers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Input, Modal, Select } from "antd";
import {
  airCompressorDetails,
  atsForm,
  engineForm,
  equipmentContact,
  generatorDetails,
  otherEqDetails,
  pressureWasherDetails,
  trailerDetails,
  truckDetails,
  welderDetails,
} from "../../data/equipmentForms";
import { Helmet } from "react-helmet-async";

const NewEquipment = () => {
  const [step, setStep] = useState(0);
  let defaultSteps = [
    {
      id: 0,
      name: "Equipment Type",
    },
    {
      id: 1,
      name: "Customer Information",
    },
    {
      id: 2,
      name: "Contact Information",
    },
    {
      id: 3,
      name: "Equipment Details",
    },
    { id: 4, name: "Overview" },
  ];
  let blankATS = {
    make: "",
    model: "",
    specNo: "",
    serialNo: "",
    size: "",
    other: "",
    status: "",
  };
  const [steps, setSteps] = useState(defaultSteps);
  const [loading, setLoading] = useState(false);
  const [equipmentType, setEquipmentType] = useState(null);
  const [otherEquipmentType, setOtherEquipmentType] = useState("");
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [details, setDetails] = useState({
    make: "",
    model: "",
    power: "",
    specNo: "",
    serialNo: "",
    arrangement: "",
    fuelType: "",
    hours: "",
    other: "",
    location: "",
    taxZip: "",
    year: "",
    miles: "",
    status: "",
    pumpInfo: "",
    size: "",
    type: "",
    customerEquipId: "",
  });
  const [engine, setEngine] = useState({
    make: "",
    model: "",
    specNo: "",
    serialNo: "",
    codeNo: "",
    fuelType: "",
    other: "",
  });
  const [contact, setContact] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [ats, setAts] = useState([]);
  const [atsModal, setAtsModal] = useState(false);
  const [newAts, setNewAts] = useState(blankATS);
  const [description, setDescription] = useState("");

  const navigate = useNavigate();
  let [search, setSearch] = useSearchParams();

  useState(() => {
    let inView = true;
    if (inView) {
      GetFullCustomerList()
        .then((res) => {
          let tmp = [];
          let i = res.data.length;
          while (i--) {
            tmp.push({
              value: res.data[i].customerId,
              label: `${res.data[i].customerCode.toUpperCase()}${res.data[i].company ? " | " + res.data[i].company : ""}`,
            });
          }
          setCustomers(tmp);
          let customerId = search.get("customerId");
          if (customerId && customerId.length > 0) {
            setCustomerId(customerId);
            GetOneCustomer(customerId)
              .then((res) => {
                setCustomer(res.data);
              })
              .catch((err) => {
                toast.error("Error loading customer.");
              });
          }
        })
        .catch((err) => {
          toast.error("Error loading customers.");
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  let generatorSteps = [
    {
      id: 0,
      name: "Equipment Type",
    },
    {
      id: 1,
      name: "Customer Information",
    },
    {
      id: 2,
      name: "Contact Information",
    },
    {
      id: 3,
      name: "Equipment Details",
    },
    {
      id: 4,
      name: "Engine Details",
    },
    {
      id: 5,
      name: "Add ATS Details",
    },
    {
      id: 6,
      name: "ATS Overview",
    },
    { id: 7, name: "Overview" },
  ];

  let trailerSteps = [
    {
      id: 0,
      name: "Equipment Type",
    },
    {
      id: 1,
      name: "Customer Information",
    },
    {
      id: 2,
      name: "Contact Information",
    },
    {
      id: 3,
      name: "Equipment Details",
    },
    { id: 4, name: "Overview" },
  ];

  let truckSteps = [
    {
      id: 0,
      name: "Equipment Type",
    },
    {
      id: 1,
      name: "Customer Information",
    },
    {
      id: 2,
      name: "Contact Information",
    },
    {
      id: 3,
      name: "Equipment Details",
    },
    { id: 4, name: "Engine Details" },
    { id: 5, name: "Overview" },
  ];

  const createEquipment = () => {
    let formattedATS = [];
    ats.forEach((el) => {
      el.status = el.status ? el.status : "";
      formattedATS.push(el);
    });
    let toSend = {
      equipmentType: equipmentType,
      otherType: otherEquipmentType,
      customerEquipId: details.customerEquipId,
      fuelType: details.fuelType ? details.fuelType : "",
      power: details.power,
      pwrUnit:
        equipmentType === "generator"
          ? "kw"
          : equipmentType === "pressureWasher"
            ? "psi"
            : equipmentType === "airCompressor"
              ? "psi"
              : equipmentType === "welder"
                ? "kw"
                : equipmentType === "truck"
                  ? "hp"
                  : "",
      miles: details.miles ? details.miles : details.hours,
      milesUnit: equipmentType === "truck" ? "miles" : "hours",
      details: {
        make: details.make,
        model: details.model,
        specNo: details.specNo,
        serialNo: details.serialNo,
        arrangement: details.arrangement,
        status: details.status ? details.status : "",
        size: details.size,
        type: details.type,
        description: description,
      },
      engine: {
        make: engine.make,
        model: engine.model,
        specNo: engine.specNo,
        serialNo: engine.serialNo,
        codeNo: engine.codeNo,
        fuelType: engine.fuelType ? engine.fuelType : "",
        other: engine.other,
      },
      ats: formattedATS,
      contact: {
        firstName: customer.contact.firstName.length > 0 ? customer.contact.firstName : null,
        lastName: customer.contact.lastName.length > 0 ? customer.contact.lastName : null,
        phoneNumber: customer.contact.phoneNumber.length === 10 ? customer.contact.phoneNumber : null,
      },
      location: details.location,
      taxZip: details.taxZip,
      other: details.other,
      year: details.year,
      pumpInfo: details.pumpInfo,
    };
    setLoading(true);
    AddEquipmentToCustomer(toSend, customerId)
      .then((res) => {
        let id = res.data.equipmentId;
        toast.success("Equipment added successfully\nRedirecting...");
        navigate("/equipment/" + id);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error adding equipment");
        setLoading(false);
      });
  };

  const filterTypeOption = (input, option) => {
    return (
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase()) ||
      (option?.value ?? "").toLowerCase().includes(input.toLowerCase()) ||
      option?.value === "other"
    );
  };

  const filterCustomers = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase()) || (option?.value ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const renderStep = () => {
    if (steps[step].name === "Equipment Type") {
      return (
        <div className="flex flex-col items-center justify-between w-full h-full max-w-4xl">
          <div className="flex flex-col items-center justify-start w-full gap-1">
            <div className="flex flex-col items-start justify-start w-full mt-2">
              <label className="block mb-2 text-sm font-medium">Equipment Type</label>
              <Select
                className="w-full"
                showSearch
                placeholder="Pick One"
                optionFilterProp="children"
                onChange={(v) => setEquipmentType(v)}
                // onSearch={onSearch}
                filterOption={filterTypeOption}
                defaultValue={equipmentType}
                allowClear
                onClear={() => {
                  setEquipmentType(null);
                  setOtherEquipmentType(null);
                }}
                options={[
                  {
                    value: "generator",
                    label: "Generator",
                  },
                  {
                    value: "truck",
                    label: "Truck",
                  },
                  {
                    value: "trailer",
                    label: "Trailer",
                  },
                  {
                    value: "welder",
                    label: "Welder",
                  },
                  {
                    value: "airCompressor",
                    label: "Air Compressor",
                  },
                  {
                    value: "pressureWasher",
                    label: "Pressure Washer",
                  },
                  {
                    value: "other",
                    label: "Other",
                  },
                ]}
              />
            </div>
            {equipmentType === "other" && (
              <>
                <div className="flex flex-col items-start justify-start w-full mt-2">
                  <label className="block mb-2 text-sm font-medium">Other Equipment Type</label>
                  <Input
                    className="block w-full px-4 py-2 text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                    placeholder="Other Equipment Type"
                    onChange={(e) => setOtherEquipmentType(e.target.value)}
                    defaultValue={otherEquipmentType}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full mt-2">
                  <label className="block mb-2 text-sm font-medium">Other Equipment Description</label>
                  <Input.TextArea
                    className="block w-full px-4 py-2 text-sm border-gray-200 rounded-md resize-none focus:border-blue-500 focus:ring-0"
                    placeholder="Other Equipment Description"
                    onChange={(e) => setDescription(e.target.value)}
                    defaultValue={description}
                    rows={4}
                  />
                </div>
              </>
            )}
          </div>
          <div className="flex flex-row items-center justify-end w-full">
            <SecondaryButton label="Next" callback={() => nextStep()} disabled={!equipmentType} />
          </div>
        </div>
      );
    } else if (steps[step].name === "Customer Information") {
      return (
        <div className="flex flex-col items-center justify-between w-full h-full max-w-4xl">
          <div className="flex flex-col items-center justify-start w-full gap-1">
            <div className="flex flex-col items-start justify-start w-full mt-2">
              <label className="block mb-2 text-sm font-medium">Customer</label>
              <Select
                className="w-full"
                showSearch
                placeholder="Pick a customer"
                optionFilterProp="children"
                onChange={(v) => setCustomerId(v)}
                filterOption={filterCustomers}
                defaultValue={customerId}
                allowClear
                onClear={() => setCustomerId(null)}
                options={customers}
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <SecondaryButton label="Back" callback={() => previousStep()} />
            <SecondaryButton label="Next" callback={() => nextStep()} disabled={!customerId} />
          </div>
        </div>
      );
    } else if (steps[step].name === "Contact Information") {
      return (
        <div className="flex flex-col items-center justify-between w-full h-full max-w-4xl">
          <FormController
            onSubmit={submitStep}
            fields={equipmentContact}
            values={contact}
            buttonText={"Next"}
            fullWidth={true}
            back={true}
            backFunction={() => previousStep()}
          />
        </div>
      );
    } else if (steps[step].name === "Equipment Details") {
      return (
        <div className="flex flex-col items-center justify-between w-full h-full max-w-4xl">
          {equipmentType === "generator" && (
            <FormController
              onSubmit={submitStep}
              fields={generatorDetails}
              values={details}
              buttonText={"Next"}
              fullWidth={true}
              back={true}
              backFunction={() => previousStep()}
            />
          )}
          {equipmentType === "trailer" && (
            <FormController
              onSubmit={submitStep}
              fields={trailerDetails}
              values={details}
              buttonText={"Next"}
              fullWidth={true}
              back={true}
              backFunction={() => previousStep()}
            />
          )}
          {equipmentType === "truck" && (
            <FormController
              onSubmit={submitStep}
              fields={truckDetails}
              values={details}
              buttonText={"Next"}
              fullWidth={true}
              back={true}
              backFunction={() => previousStep()}
            />
          )}
          {equipmentType === "pressureWasher" && (
            <FormController
              onSubmit={submitStep}
              fields={pressureWasherDetails}
              values={details}
              buttonText={"Next"}
              fullWidth={true}
              back={true}
              backFunction={() => previousStep()}
            />
          )}
          {equipmentType === "airCompressor" && (
            <FormController
              onSubmit={submitStep}
              fields={airCompressorDetails}
              values={details}
              buttonText={"Next"}
              fullWidth={true}
              back={true}
              backFunction={() => previousStep()}
            />
          )}
          {equipmentType === "welder" && (
            <FormController
              onSubmit={submitStep}
              fields={welderDetails}
              values={details}
              buttonText={"Next"}
              fullWidth={true}
              back={true}
              backFunction={() => previousStep()}
            />
          )}
          {equipmentType === "other" && (
            <FormController
              onSubmit={submitStep}
              fields={otherEqDetails}
              values={details}
              buttonText={"Next"}
              fullWidth={true}
              back={true}
              backFunction={() => previousStep()}
            />
          )}
        </div>
      );
    } else if (steps[step].name === "Engine Details") {
      return (
        <div className="flex flex-col items-center justify-between w-full h-full max-w-4xl">
          <FormController
            onSubmit={submitStep}
            fields={engineForm}
            values={engine}
            buttonText={"Next"}
            fullWidth={true}
            back={true}
            backFunction={() => previousStep()}
          />
        </div>
      );
    } else if (steps[step].name === "Add ATS Details") {
      return (
        <div className="flex flex-col items-center justify-start w-full h-full max-w-4xl gap-2">
          <FormController
            onSubmit={submitStep}
            fields={atsForm}
            values={newAts}
            buttonText={"Next"}
            fullWidth={true}
            back={true}
            backFunction={() => previousStep()}
          />
          <div className="flex flex-row items-center justify-end w-full">
            <SecondaryButton label="Skip ATS" callback={() => skipATS()} />
          </div>
        </div>
      );
    } else if (steps[step].name === "ATS Overview") {
      return (
        <div className="flex flex-col items-center justify-between w-full h-full max-w-4xl">
          <div className="flex flex-col items-center justify-start w-full h-full gap-2">
            <p className="w-full pb-3 mb-2 text-sm uppercase border-b border-gray-300">Automatic Transfer Switches Added: {ats.length}</p>
            {ats.map((el, i) => (
              <div className="grid items-center content-center justify-center w-full grid-cols-3 px-6 py-3 border border-gray-300 rounded-md gap-y-2">
                <p className="text-sm text-gray-500">
                  <span className="font-medium text-gray-700">Make: </span>
                  {el.make && el.make.length > 0 ? el.make : "Not Provided"}
                </p>
                <p className="text-sm text-gray-500">
                  <span className="font-medium text-gray-700">Model: </span>
                  {el.model && el.model.length > 0 ? el.model : "Not Provided"}
                </p>
                <p className="text-sm text-gray-500">
                  <span className="font-medium text-gray-700">Spec #: </span>
                  {el.specNo && el.specNo.length > 0 ? el.specNo : "Not Provided"}
                </p>
                <p className="text-sm text-gray-500">
                  <span className="font-medium text-gray-700">Serial #: </span>
                  {el.serialNo && el.serialNo.length > 0 ? el.serialNo : "Not Provided"}
                </p>
                <p className="text-sm text-gray-500">
                  <span className="font-medium text-gray-700">Size: </span>
                  {el.size & (el.size.length > 0) ? el.size : "Not Provided"}
                </p>
                <p className="text-sm text-gray-500">
                  <span className="font-medium text-gray-700">Status: </span>
                  {el.status === "availableForUse"
                    ? "Available For Use"
                    : el.status === "inoperable"
                      ? "Inoperable"
                      : el.status === "retired"
                        ? "Retired"
                        : "Unknown"}
                </p>
                <p className="col-span-3 text-sm text-gray-500">
                  <span className="font-medium text-gray-700">Other: </span>
                  {el.other && el.other.length > 0 ? el.other : "Not Provided"}
                </p>
                <div className="flex flex-row items-center justify-end w-full col-span-3 gap-2">
                  <SecondaryButton label="Remove" callback={() => deleteAts(i)} />
                  <SecondaryButton label="Edit" callback={() => editAts(i)} />
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-row items-center justify-between w-full my-3">
            <SecondaryButton label="Back" callback={() => previousStep()} />
            <div className="flex flex-row items-center justify-end gap-2">
              <SecondaryButton label="Add Another" callback={() => setStep(step - 1)} />
              <PrimaryButton label="Next" callback={() => setStep(step + 1)} />
            </div>
          </div>
        </div>
      );
    } else if (steps[step].name === "Overview") {
      return (
        <div className="flex flex-col items-center justify-start w-full h-full max-w-4xl">
          <div className="w-full px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900 capitalize">
              New {equipmentType === "pressureWasher" ? "Pressure Washer" : equipmentType === "airCompressor" ? "Air Compressor" : equipmentType}
              {equipmentType === "other" && " Equipment Type"}
              {equipmentType === "other" && " | " + otherEquipmentType}
            </h3>
            <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">
              Customer: {customer.customerCode.toUpperCase()}
              {customer.company && customer.company.length > 0 ? " | " + customer.company : ""}
            </p>
          </div>
          <div className="w-full mt-3 border-t border-gray-400">
            <dl className="w-full divide-y divide-gray-300">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-semibold leading-6 text-gray-900">Equipment Type</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
                  {equipmentType === "pressureWasher" ? "Pressure Washer" : equipmentType === "airCompressor" ? "Air Compressor" : equipmentType}
                  {equipmentType === "other" && " (" + otherEquipmentType + ")"}
                </dd>
                <dt className="text-sm font-semibold leading-6 text-gray-900">Customer</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
                  {customer.customerCode.toUpperCase()}
                  {customer.company && customer.company.length > 0 ? " | " + customer.company : ""}
                </dd>
                {equipmentType === "other" && (
                  <>
                    <dt className="text-sm font-semibold leading-6 text-gray-900">Description</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
                      {description && description.length > 0 ? description : "Not Provided"}
                    </dd>
                  </>
                )}
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-semibold leading-6 text-gray-900">Contact Information</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0"></dd>
                <dt className="text-sm font-medium leading-6 text-gray-700">First Name</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                  {contact.firstName && contact.firstName.length > 0 ? contact.firstName : "Not Provided"}
                </dd>
                <dt className="text-sm font-medium leading-6 text-gray-700">Last Name</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                  {contact.lastName && contact.lastName.length > 0 ? contact.lastName : "Not Provided"}
                </dd>
                <dt className="text-sm font-medium leading-6 text-gray-700">Phone Number</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                  {contact.phoneNumber && contact.phoneNumber.length === 10 ? formatPhoneNumber(contact.phoneNumber) : "Not Provided"}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-semibold leading-6 text-gray-900">Equipment Details</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"></dd>
                {equipmentType === "generator" ? (
                  <>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Customer Equipment ID</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.customerEquipId && details.customerEquipId.length > 0 ? details.customerEquipId : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Make</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.make && details.make.length > 0 ? details.make : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Model</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.model && details.model.length > 0 ? details.model : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Power</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">{details.power ? details.power + " kW" : "Not Provided"}</dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Spec #</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.specNo && details.specNo.length > 0 ? details.specNo : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Serial #</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.serialNo && details.serialNo.length > 0 ? details.serialNo : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Arrangement #</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.arrangement && details.arrangement.length > 0 ? details.arrangement : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Fuel Type</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 capitalize sm:col-span-2 sm:mt-0">
                      {details.fuelType && details.fuelType.length === 0
                        ? "Not Provided"
                        : details.fuelType === "biFuel"
                          ? "Bi-Fuel"
                          : details.fuelType
                            ? details.fuelType
                            : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Hours</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">{details.miles ? details.miles + " hrs" : "Not Provided"}</dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Location</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.location && details.location.length > 0 ? details.location : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Sales Tax ZIP Code</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.taxZip && details.taxZip > 0 ? details.taxZip : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Status</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.status && details.status.length === 0
                        ? "Not Provided"
                        : details.status === "availableForUse"
                          ? "Available For Use"
                          : details.status === "inoperable"
                            ? "Inoperable"
                            : details.status === "retired"
                              ? "Retired"
                              : "Unknown"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Other</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.other && details.other.length > 0 ? details.other : "Not Provided"}
                    </dd>
                  </>
                ) : null}
                {equipmentType === "trailer" && (
                  <>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Customer Equipment ID</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.customerEquipId && details.customerEquipId.length > 0 ? details.customerEquipId : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Make</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.make && details.make.length > 0 ? details.make : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Model</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.model && details.model.length > 0 ? details.model : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Serial #</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.serialNo && details.serialNo.length > 0 ? details.serialNo : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Year</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.year && details.year.length > 0 ? details.year : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Sales Tax ZIP Code</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.taxZip && details.taxZip > 0 ? details.taxZip : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Status</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.status && details.status.length === 0
                        ? "Not Provided"
                        : details.status === "availableForUse"
                          ? "Available For Use"
                          : details.status === "inoperable"
                            ? "Inoperable"
                            : details.status === "retired"
                              ? "Retired"
                              : "Unknown"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Other</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.other && details.other.length > 0 ? details.other : "Not Provided"}
                    </dd>
                  </>
                )}
                {equipmentType === "truck" && (
                  <>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Customer Equipment ID</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.customerEquipId && details.customerEquipId.length > 0 ? details.customerEquipId : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Make</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.make && details.make.length > 0 ? details.make : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Model</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.model && details.model.length > 0 ? details.model : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Serial #</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.serialNo && details.serialNo.length > 0 ? details.serialNo : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Year</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.year && details.year.length > 0 ? details.year : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Miles</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">{details.miles ? details.miles + " mi" : "Not Provided"}</dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Sales Tax ZIP Code</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.taxZip && details.taxZip > 0 ? details.taxZip : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Status</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.status && details.status.length === 0
                        ? "Not Provided"
                        : details.status === "availableForUse"
                          ? "Available For Use"
                          : details.status === "inoperable"
                            ? "Inoperable"
                            : details.status === "retired"
                              ? "Retired"
                              : "Unknown"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Other</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.other && details.other.length > 0 ? details.other : "Not Provided"}
                    </dd>
                  </>
                )}
                {equipmentType === "pressureWasher" && (
                  <>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Make</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.make && details.make.length > 0 ? details.make : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Model</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.model && details.model.length > 0 ? details.model : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Serial #</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.serialNo && details.serialNo.length > 0 ? details.serialNo : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">PSI</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">{details.power ? details.power + " PSI" : "Not Provided"}</dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Pump Info</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.pumpInfo && details.pumpInfo.length > 0 ? details.pumpInfo : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Fuel Type</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 capitalize sm:col-span-2 sm:mt-0">
                      {details.fuelType && details.fuelType.length === 0
                        ? "Not Provided"
                        : details.fuelType === "biFuel"
                          ? "Bi-Fuel"
                          : details.fuelType
                            ? details.fuelType
                            : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Sales Tax ZIP Code</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.taxZip && details.taxZip > 0 ? details.taxZip : "Not Provided"}
                    </dd>
                  </>
                )}
                {equipmentType === "airCompressor" && (
                  <>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Make</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.make && details.make.length > 0 ? details.make : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Model</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.model && details.model.length > 0 ? details.model : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Serial #</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.serialNo && details.serialNo.length > 0 ? details.serialNo : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Fuel Type</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 capitalize sm:col-span-2 sm:mt-0">
                      {details.fuelType && details.fuelType.length === 0
                        ? "Not Provided"
                        : details.fuelType === "biFuel"
                          ? "Bi-Fuel"
                          : details.fuelType
                            ? details.fuelType
                            : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">PSI</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">{details.power ? details.power + " PSI" : "Not Provided"}</dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Size</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.size && details.size.length > 0 ? details.size : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Type</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.type && details.type.length > 0 ? details.type : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Location</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.location && details.location.length > 0 ? details.location : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Sales Tax ZIP Code</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.taxZip && details.taxZip > 0 ? details.taxZip : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Other</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.other && details.other.length > 0 ? details.other : "Not Provided"}
                    </dd>
                  </>
                )}
                {equipmentType === "welder" && (
                  <>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Make</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.make && details.make.length > 0 ? details.make : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Model</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.model && details.model.length > 0 ? details.model : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Serial #</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.serialNo && details.serialNo.length > 0 ? details.serialNo : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Arrangement #</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">{details.arrangement ? details.arrangement : "Not Provided"}</dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Fuel Type</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 capitalize sm:col-span-2 sm:mt-0">
                      {details.fuelType && details.fuelType.length === 0
                        ? "Not Provided"
                        : details.fuelType === "biFuel"
                          ? "Bi-Fuel"
                          : details.fuelType
                            ? details.fuelType
                            : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Type</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.type && details.type.length > 0 ? details.type : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Hours</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">{details.miles ? details.miles + " hrs" : "Not Provided"}</dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Status</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.status && details.status.length === 0
                        ? "Not Provided"
                        : details.status === "availableForUse"
                          ? "Available For Use"
                          : details.status === "inoperable"
                            ? "Inoperable"
                            : details.status === "retired"
                              ? "Retired"
                              : "Unknown"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Location</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.location && details.location.length > 0 ? details.location : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Sales Tax ZIP Code</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.taxZip && details.taxZip > 0 ? details.taxZip : "Not Provided"}
                    </dd>
                  </>
                )}
                {equipmentType === "other" && (
                  <>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Make</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.make && details.make.length > 0 ? details.make : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Model</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.model && details.model.length > 0 ? details.model : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Serial #</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.serialNo && details.serialNo.length > 0 ? details.serialNo : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Miles / Hours</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">{details.miles ? details.miles : "Not Provided"}</dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Fuel Type</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 capitalize sm:col-span-2 sm:mt-0">
                      {details.fuelType && details.fuelType.length === 0
                        ? "Not Provided"
                        : details.fuelType === "biFuel"
                          ? "Bi-Fuel"
                          : details.fuelType
                            ? details.fuelType
                            : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Year</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.year && details.year.length > 0 ? details.year : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Status</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.status && details.status.length === 0
                        ? "Not Provided"
                        : details.status === "availableForUse"
                          ? "Available For Use"
                          : details.status === "inoperable"
                            ? "Inoperable"
                            : details.status === "retired"
                              ? "Retired"
                              : "Unknown"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Sales Tax ZIP Code</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.taxZip && details.taxZip > 0 ? details.taxZip : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Other</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.other && details.other.length > 0 ? details.other : "Not Provided"}
                    </dd>
                    <dt className="text-sm font-medium leading-6 text-gray-700">Customer's Equipment ID</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                      {details.customerEquipId && details.customerEquipId.length > 0 ? details.customerEquipId : "Not Provided"}
                    </dd>
                  </>
                )}
              </div>
              {equipmentType === "generator" ||
              equipmentType === "truck" ||
              equipmentType === "pressureWasher" ||
              equipmentType === "airCompressor" ||
              equipmentType === "welder" ? (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">Engine Information</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"></dd>
                  <dt className="text-sm font-medium leading-6 text-gray-700">Make</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                    {engine.make && engine.make.length > 0 ? engine.make : "Not Provided"}
                  </dd>
                  <dt className="text-sm font-medium leading-6 text-gray-700">Model</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                    {engine.model && engine.model.length > 0 ? engine.model : "Not Provided"}
                  </dd>
                  <dt className="text-sm font-medium leading-6 text-gray-700">Spec #</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                    {engine.specNo && engine.specNo.length > 0 ? engine.specNo : "Not Provided"}
                  </dd>
                  <dt className="text-sm font-medium leading-6 text-gray-700">Serial #</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                    {engine.serialNo && engine.serialNo.length > 0 ? engine.serialNo : "Not Provided"}
                  </dd>
                  <dt className="text-sm font-medium leading-6 text-gray-700">Code #</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                    {engine.codeNo && engine.codeNo.length > 0 ? engine.codeNo : "Not Provided"}
                  </dd>
                  <dt className="text-sm font-medium leading-6 text-gray-700">Fuel Type</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-500 capitalize sm:col-span-2 sm:mt-0">
                    {engine.fuelType && engine.fuelType.length === 0
                      ? "Not Provided"
                      : engine.fuelType === "biFuel"
                        ? "Bi-Fuel"
                        : engine.fuelType
                          ? engine.fuelType
                          : "Not Provided"}
                  </dd>
                  <dt className="text-sm font-medium leading-6 text-gray-700">Other</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                    {engine.other && engine.other.length > 0 ? engine.other : "Not Provided"}
                  </dd>
                </div>
              ) : null}
              {equipmentType === "generator" && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">ATS Information</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{ats.length} Provided</dd>
                  {ats.map((el, i) => (
                    <div className="grid items-center content-center justify-center w-full grid-cols-3 col-span-3 px-6 py-3 border border-gray-300 rounded-md gap-y-2">
                      <p className="text-sm text-gray-500">
                        <span className="font-medium text-gray-700">Make: </span>
                        {el.make.length > 0 ? el.make : "Not Provided"}
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-medium text-gray-700">Model: </span>
                        {el.model.length > 0 ? el.model : "Not Provided"}
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-medium text-gray-700">Spec #: </span>
                        {el.specNo.length > 0 ? el.specNo : "Not Provided"}
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-medium text-gray-700">Serial #: </span>
                        {el.serialNo.length > 0 ? el.serialNo : "Not Provided"}
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-medium text-gray-700">Size: </span>
                        {el.size.length > 0 ? el.size : "Not Provided"}
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-medium text-gray-700">Status: </span>
                        {el.status === "availableForUse"
                          ? "Available For Use"
                          : el.status === "inoperable"
                            ? "Inoperable"
                            : el.status === "retired"
                              ? "Retired"
                              : "Unknown"}
                      </p>
                      <p className="col-span-3 text-sm text-gray-500">
                        <span className="font-medium text-gray-700">Other: </span>
                        {el.other.length > 0 ? el.other : "Not Provided"}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </dl>
          </div>
          <div className="flex flex-row items-center justify-between w-full py-3 my-3 border-t border-gray-400">
            <SecondaryButton label="Back" callback={() => previousStep()} />
            <PrimaryButton label="Add Equipment" callback={() => createEquipment()} />
          </div>
        </div>
      );
    }
  };

  const deleteAts = (i) => {
    setLoading(true);
    let tmp = [];
    for (let j = 0; j < ats.length; j++) {
      if (j !== i) {
        tmp.push(ats[j]);
      }
    }
    setAts(tmp);
    setTimeout(() => setLoading(false), 700);
  };

  const editAts = (i) => {
    setLoading(true);
    let tmp = [];
    for (let j = 0; j < ats.length; j++) {
      if (j !== i) {
        tmp.push(ats[j]);
      } else {
        setNewAts(ats[j]);
      }
    }
    setAts(tmp);
    setStep(step - 1);
    setTimeout(() => setLoading(false), 700);
  };

  const skipATS = () => {
    if (ats.length === 0) {
      setStep(step + 2);
    } else {
      setStep(step + 1);
    }
  };

  const previousStep = () => {
    setLoading(true);
    setStep(step - 1);
    setTimeout(() => setLoading(false), 700);
  };

  const submitStep = (data) => {
    setNewAts(blankATS);
    if (steps[step].name === "Equipment Details") {
      setLoading(true);
      setDetails(data);
      setStep(step + 1);
      setTimeout(() => setLoading(false), 700);
    } else if (steps[step].name === "Engine Details") {
      setLoading(true);
      setEngine(data);
      setStep(step + 1);
      setTimeout(() => setLoading(false), 700);
    } else if (steps[step].name === "Add ATS Details") {
      setLoading(true);
      if (data.formModified) {
        if (
          data.make &&
          data.make.length > 0 &&
          data.model &&
          data.model.length > 0 &&
          data.specNo &&
          data.specNo.length > 0 &&
          data.serialNo &&
          data.serialNo.length > 0 &&
          data.size &&
          data.size.length > 0 &&
          data.other &&
          data.other.length > 0
        ) {
          if (ats.length === 0) {
            setStep(step + 2);
          } else {
            setStep(step + 1);
          }
          setTimeout(() => setLoading(false), 700);
        } else {
          setAts([...ats, data]);
          setAtsModal(true);
        }
      } else {
        if (ats.length === 0) {
          setStep(step + 2);
        } else {
          setStep(step + 1);
        }
        setTimeout(() => setLoading(false), 700);
      }
    } else if (steps[step].name === "Contact Information") {
      setLoading(true);
      setContact(data);
      setStep(step + 1);
      setTimeout(() => setLoading(false), 700);
    }
  };

  const nextStep = () => {
    if (step === 0) {
      if (!equipmentType || equipmentType.length === 0) {
        toast.error("Please select an equipment type.");
      } else if (equipmentType === "other" && (!otherEquipmentType || otherEquipmentType.length === 0)) {
        toast.error("Please enter an equipment type.");
      } else {
        setLoading(true);
        if (equipmentType === "generator") {
          setSteps(generatorSteps);
        } else if (equipmentType === "trailer") {
          setSteps(trailerSteps);
        } else if (equipmentType === "truck" || equipmentType === "pressureWasher" || equipmentType === "airCompressor" || equipmentType === "welder") {
          setSteps(truckSteps);
        } else if (equipmentType === "other") {
          let baseSteps = [
            {
              id: 0,
              name: "Equipment Type",
            },
            {
              id: 1,
              name: "Customer Information",
            },
            {
              id: 2,
              name: "Contact Information",
            },
            {
              id: 3,
              name: "Equipment Details",
            },
          ];
          baseSteps.push({
            id: baseSteps.length,
            name: "Overview",
          });
          setSteps(baseSteps);
        }
        if (customerId && customer) {
          setStep(step + 2);
          setTimeout(() => setLoading(false), 700);
        } else {
          setStep(step + 1);
          setTimeout(() => setLoading(false), 700);
        }
      }
    } else if (step === 1) {
      if (!customerId || customerId.length === 0) {
        toast.error("Please select a customer.");
      } else {
        setLoading(true);
        GetOneCustomer(customerId)
          .then((res) => {
            setCustomer(res.data);
            let el = res.data;
            if (el.defaults.tax === "taxable") {
              if (el.arData.zip && el.arData.zip.length === 5) {
                setDetails({ ...details, taxZip: el.arData.zip });
              } else if (el.contact.zip && el.contact.zip.length === 5) {
                setDetails({ ...details, taxZip: el.contact.zip });
              }
            }
            let tmpContact = contact;
            if (el.contact.firstName && el.contact.firstName.length > 0) {
              tmpContact.firstName = el.contact.firstName;
            }
            if (el.contact.lastName && el.contact.lastName.length > 0) {
              tmpContact.lastName = el.contact.lastName;
            }
            if (el.contact.phoneNumber && el.contact.phoneNumber.length === 10) {
              tmpContact.phoneNumber = el.contact.phoneNumber;
            }
            setContact(tmpContact);
            setStep(step + 1);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error("Error loading customer.");
            setLoading(false);
          });
      }
    }
  };

  const noMoreATS = () => {
    setAtsModal(false);
    setStep(step + 1);
    setTimeout(() => setLoading(false), 700);
  };

  const addAnotherATS = () => {
    setAtsModal(false);
    setStep(step);
    setTimeout(() => setLoading(false), 700);
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full mb-1">
      {!loading ? (
        <>
          <Helmet>
            <title>Add Equipment | HTPS ERP</title>
          </Helmet>
          <nav aria-label="Progress" className="w-full mb-5">
            <ol className="border border-gray-300 divide-y divide-gray-300 rounded-md md:flex md:divide-y-0">
              {steps.map((st, stepIdx) => (
                <li key={st.name} className="relative md:flex md:flex-1">
                  {step > st.id ? (
                    <div className="flex items-center w-full group">
                      <span className="flex items-center px-6 py-4 text-sm font-medium">
                        <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-blue-600 rounded-full group-hover:bg-blue-800">
                          <Check className="w-6 h-6 text-white" aria-hidden="true" />
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-900">{st.name}</span>
                      </span>
                    </div>
                  ) : step === st.id ? (
                    <div className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                      <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-blue-600 rounded-full">
                        <span className="text-blue-600">{st.id + 1}</span>
                      </span>
                      <span className="ml-4 text-sm font-medium text-blue-600">{st.name}</span>
                    </div>
                  ) : (
                    <div className="flex items-center cursor-pointer group">
                      <span className="flex items-center px-6 py-4 text-sm font-medium">
                        <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                          <span className="text-gray-500 group-hover:text-gray-900">{st.id + 1}</span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{st.name}</span>
                      </span>
                    </div>
                  )}

                  {stepIdx !== steps.length - 1 ? (
                    <>
                      <div className="absolute top-0 right-0 hidden w-5 h-full md:block" aria-hidden="true">
                        <svg className="w-full h-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                          <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentColor" strokeLinejoin="round" />
                        </svg>
                      </div>
                    </>
                  ) : null}
                </li>
              ))}
            </ol>
          </nav>
          <div className="flex flex-col items-center justify-start flex-grow w-full pb-4 mb-10 overflow-y-auto">{renderStep()}</div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center flex-grow w-full">
          <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
        </div>
      )}
      <Modal
        title="Add another ATS?"
        open={atsModal}
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-2 pt-5">
            <SecondaryButton label="No" callback={() => noMoreATS()} />
            <PrimaryButton label="Yes" callback={() => addAnotherATS()} />
          </div>,
        ]}
        centered
        closable={false}
      >
        <p>Would you like to add another ATS to this generator?</p>
      </Modal>
    </div>
  );
};

export default NewEquipment;
