import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import validator from "validator";
import { SignIn } from "../../actions/auth";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import DashboardAsset from "../../assets/dashboard.png";
import { Helmet } from "react-helmet-async";

const Login = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (authState.isAuth) {
        navigate("/");
      }
      if (emailRef.current) {
        emailRef.current.focus();
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const updateEmail = (e) => {
    setEmail(e.target.value);
    setEmailError(null);
  };

  const updatePassword = (e) => {
    setPassword(e.target.value);
    setPasswordError(null);
  };

  const submitLogin = () => {
    if (!validator.isEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    if (password === "") {
      setPasswordError("Please enter a valid password");
      return;
    }
    if (!loading) {
      setLoading(true);
      SignIn({ email, password })
        .then((res) => {
          let payload = {
            token: res.data.token,
            email: email,
            user: res.data.user,
          };
          localStorage.setItem("auth-token", res.data.token);
          authDispatch({
            type: "valid-login",
            payload: payload,
          });
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitLogin();
    }
  };

  return (
    <div className="flex w-full h-screen md:flex-row md:items-center md:justify-between">
      {loading && <Loader />}
      <Helmet>
        <title>Log In | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center w-full h-screen md:w-1/2 bg-white-smoke">
        <div className="flex flex-col items-start justify-start w-3/4">
          <h1 className="text-2xl font-medium">Log in</h1>
          <p className="mt-3 text-sm font-light">Welcome to the HiTech Power Systems ERP, please enter you login credentials to begin using the application</p>
          <div className="flex flex-row items-center justify-between w-full mt-10">
            <p className="text-sm font-semibold">E-Mail</p>
            <input
              placeholder="Email"
              type="email"
              className="w-3/4 px-3 py-2 rounded shadow-sm"
              ref={emailRef}
              onChange={(e) => updateEmail(e)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </div>
          <p className="w-full h-4 px-2 text-xs font-medium text-right text-red-500">{emailError}</p>
          <div className="flex flex-row items-center justify-between w-full mt-1">
            <p className="text-sm font-semibold">Password</p>
            <input
              placeholder="Password"
              type="password"
              className="w-3/4 px-3 py-2 rounded shadow-sm"
              ref={passwordRef}
              onChange={(e) => updatePassword(e)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </div>
          <p className="w-full h-4 px-2 text-xs font-medium text-right text-red-500">{passwordError}</p>
          <p className="w-full h-4 px-2 text-xs font-medium text-right cursor-pointer" onClick={() => navigate("/forgotPassword")}>
            Forgot password?
          </p>
          <div className="w-full mt-4 mb-3 border-t border-slate-200" />
          <div className="flex flex-row justify-end w-full">
            <button
              type="button"
              onClick={() => submitLogin()}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-5 py-2 text-xs font-semibold uppercase text-white shadow-sm hover:bg-blue-500"
            >
              Login
            </button>
          </div>
        </div>
      </div>
      <div className="hidden md:flex h-screen w-1/2 flex-col items-center bg-gradient-to-bl from-[#0575e6] to-[#021b79] justify-center py-10">
        <div className="flex items-center justify-center w-full h-3/4">
          <img src={DashboardAsset} className="w-4/5 h-auto rounded-md shadow-2xl" alt="asset" />
        </div>
        <div className="flex flex-col items-center justify-center w-2/3 gap-1 text-white">
          <h1 className="text-lg font-medium">Meet the HiTech Power Systems ERP</h1>
          <p className="text-xs font-light text-center">
            HiTech's Enterprise Resource Planning Platform ensures you have control and visibility across ongoing projects, inventory, accounting and more!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
