import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { GetInventoryCount } from "../../../actions/reports";
import { GenerateCountSheet } from "../../../data/pdf";
import { Helmet } from "react-helmet-async";

const InventoryCount = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;

    if (inView) {
      GetInventoryCount()
        .then((res) => {
          setCount(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "We encountered an error while loading Inventory Count Sheet data");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const printReport = (loc) => {
    if (loc === "northAugusta") {
      let doc = GenerateCountSheet(count, loc);
      doc.setProperties({
        title: `Hi-Tech Power Systems | Inventory Count Sheet`,
        subject: `Hi-Tech Power Systems | Inventory Count Sheet`,
        author: "Hypertek Solutions LLC",
        keywords: "",
        creator: "contact@hypertek.dev",
      });
      window.open(doc.output("bloburl"), "_blank");
    } else if (loc === "augusta") {
      let doc = GenerateCountSheet(count, loc);
      doc.setProperties({
        title: `Hi-Tech Power Systems | Inventory Count Sheet`,
        subject: `Hi-Tech Power Systems | Inventory Count Sheet`,
        author: "Hypertek Solutions LLC",
        keywords: "",
        creator: "contact@hypertek.dev",
      });
      window.open(doc.output("bloburl"), "_blank");
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Inventory Count Sheet | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full h-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Inventory Count Sheet</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-col items-start justify-center gap-1">
            <p className="text-sm uppercase text-gray-500/90">Inventory Count Sheet</p>
            <p className="text-sm text-gray-600/80">
              Accurately assess the actual state of your inventory by doing a physical count of all items in your warehouse.
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center justify-start w-full gap-5 px-5 mt-10">
          <div
            className="flex flex-col rounded-xl w-80 bg-gradient-to-br from-gray-800 to-slate-900 shadow-slate-700/[.7] shadow-md transition-all hover:scale-105 cursor-pointer"
            onClick={() => printReport("augusta")}
          >
            <div className="p-4 md:p-7">
              <h3 className="text-lg font-bold text-white">Augusta, GA</h3>
              <p className="mt-2 text-gray-400">Print out a count sheet for the Augusta, GA location</p>
            </div>
          </div>
          <div
            className="flex flex-col rounded-xl w-80 bg-gradient-to-br from-gray-800 to-slate-900 shadow-slate-700/[.7] shadow-md transition-all hover:scale-105 cursor-pointer"
            onClick={() => printReport("northAugusta")}
          >
            <div className="p-4 md:p-7">
              <h3 className="text-lg font-bold text-white">North Augusta, SC</h3>
              <p className="mt-2 text-gray-400">Print out a count sheet for the North Augusta, SC location</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryCount;
