import { useEffect, useState, Fragment, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GetOneJob, UpdateJobPart, AddPartsToAJob } from "../../actions/jobs";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import { Input, InputNumber, Modal, Select, Switch, Table, Tag, Tooltip } from "antd";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { BoxIso, Xmark, Check } from "iconoir-react";
import { AdvancedPartsQuery } from "../../actions/ims";
import { Helmet } from "react-helmet-async";
import { GetDepartments } from "../../actions/auth";
import { filterOption } from "../../components/DynamicField";

const JobParts = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  let [job, setJob] = useState({});
  // eslint-disable-next-line
  let [location, setLocation] = useState({});
  let [editPart, setEditPart] = useState(false);
  let [part, setPart] = useState({
    quantity: 0,
    partNo: "",
    partId: "",
    category: "",
    description: "",
    cost: 0,
    pricePerPart: 0,
    extPrice: 0,
    commissioned: 0,
    uniqueId: "",
    partStatus: "",
    chargeToCustomer: false,
  });
  let [addPartsModal, setAddPartsModal] = useState(false);
  let [rawPartsData, setRawPartsData] = useState([]);
  let [partsData, setPartsData] = useState([]);
  let [partsToAdd, setPartsToAdd] = useState([]);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [search, setSearch] = useState("");
  let [addPartsReportModal, setAddPartsReportModal] = useState(false);
  let [departments, setDepartments] = useState([]);
  let [total, setTotal] = useState(0);
  let [page, setPage] = useState(0);
  let [size, setSize] = useState(10);

  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneJob(jobId)
        .then((res) => {
          setJob(res.data.job);
          setLocation(res.data.location);
          GetDepartments()
            .then((res) => {
              setDepartments(res.data);
              AdvancedPartsQuery({ search, limit: 10, page: 0 })
                .then((res) => {
                  setRawPartsData(res.data.results);
                  setPartsData(res.data.results);
                  setTotal(res.data.total);
                  setPage(res.data.page);
                  setSize(res.data.size);
                  setTimeout(() => setLoading(false), 700);
                })
                .catch((err) => {
                  toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                  setTimeout(() => setLoading(false), 700);
                });
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
              setTimeout(() => setLoading(false), 700);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
          setTimeout(() => {
            navigate("/jobs");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const quantityRef = useRef(null);
  const pricePerPartRef = useRef(null);
  const extPriceRef = useRef(null);

  const tabs = [
    { name: "Overview", href: "#", current: false },
    { name: "Parts", href: "parts", current: true },
    { name: "Labor", href: "labor", current: false },
    { name: "Parking/Storage", href: "parking", current: false },
    { name: "Loadbank", href: "loadbank", current: false },
    { name: "Freight", href: "freight", current: false },
    { name: "Misc", href: "misc", current: false },
    { name: "Purchase Orders", href: "purchaseOrders", current: false },
    {
      name: "Inventory Transmittals",
      href: "inventoryTransmittals",
      current: false,
    },
    { name: "Equipment", href: "equipment", current: false },
    { name: "Tests", href: "tests", current: false },
    { name: "Warranty", href: "warranty", current: false },
    { name: "Payments", href: "payments", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderPart = (part, index) => {
    return (
      <div
        className={`grid items-center w-full ${
          authState.user.functionCategory !== "technician" ? "grid-cols-3 md:grid-cols-9" : "grid-cols-3 md:grid-cols-8"
        } gap-4 py-3 border-b border-gray-300`}
        key={uuidv4()}
      >
        <p>{part.partNo}</p>
        <p className="hidden md:inline-flex">
          {part.description.slice(0, 50)}
          {part.description.length > 50 && "..."}
        </p>
        <p className="text-center">{part.quantity}</p>
        <p className="hidden text-center md:inline-flex">
          {part.commissioned ? part.commissioned : 0}/{part.quantity}
        </p>
        {authState.user.functionCategory !== "technician" && <p className="hidden text-center md:inline-flex">{formatCurrency(part.cost)}</p>}
        <p className="hidden text-center md:inline-flex">{formatCurrency(part.pricePerPart)}</p>
        {job.warranty && part.chargeToCustomer ? (
          <div className="flex-col items-start justify-center hidden gap-1 md:flex">
            <p className="text-center">{formatCurrency(part.extPrice)}</p>
            <Tag color="blue" className="py-0.5 font-medium" style={{ fontSize: "0.6rem", lineHeight: "0.75rem" }}>
              Charged to Cust.
            </Tag>
          </div>
        ) : (
          <p className="hidden text-center md:inline-flex">{formatCurrency(part.extPrice)}</p>
        )}
        {renderPartStatus(part.partStatus)}
        {job.jobStatus === "open" && (
          <div className="flex flex-row items-center justify-center">
            <SecondaryButton label="Edit" callback={() => openEditPart(part)} />
          </div>
        )}
      </div>
    );
  };

  const renderPartStatus = (status) => {
    switch (status) {
      case "entered":
        return (
          <p className="hidden px-2 py-1 text-xs font-semibold text-center text-gray-500 border border-gray-500 rounded-md md:block bg-gray-50">Entered</p>
        );
      case "onOrder":
        return (
          <p className="hidden px-2 py-1 text-xs font-semibold text-center border rounded-md md:block text-amber-500 border-amber-500 bg-amber-50">Ordered</p>
        );
      case "inventory":
        return (
          <p className="hidden px-2 py-1 text-xs font-semibold text-center text-green-700 border border-green-700 rounded-md md:block bg-green-50">Inventory</p>
        );
      case "backOrdered":
        return (
          <p className="hidden px-2 py-1 text-xs font-semibold text-center text-green-700 border border-green-700 rounded-md md:block bg-green-50">
            Back Ordered
          </p>
        );
      case "returnedToInventory":
        return (
          <p className="hidden px-2 py-1 text-xs font-semibold text-center text-red-700 border border-red-700 rounded-md md:block bg-red-50">
            Returned to Inventory
          </p>
        );
      case "returnedToVendor":
        return (
          <p className="hidden px-2 py-1 text-xs font-semibold text-center text-red-700 border border-red-700 rounded-md md:block bg-red-50">
            Returned to Vendor
          </p>
        );
      case "inTransit":
        return (
          <p className="hidden px-2 py-1 text-xs font-semibold text-center border rounded-md md:block text-lime-500 border-lime-500 bg-lime-50">In Transit</p>
        );
      case "partiallyAvailable":
        return (
          <p className="hidden px-2 py-1 text-xs font-semibold text-center border rounded-md md:block text-sky-500 border-sky-500 bg-sky-50">
            Partially Available
          </p>
        );
      case "committed":
        return (
          <p className="hidden px-2 py-1 text-xs font-semibold text-center text-green-700 border border-green-700 rounded-md md:block bg-green-50">Committed</p>
        );
      default:
        return (
          <p className="hidden px-2 py-1 text-xs font-semibold text-center text-gray-500 border border-gray-500 rounded-md md:block bg-gray-50">Unknown</p>
        );
    }
  };

  const closeEditPart = () => {
    setEditPart(false);
    setPart({
      quantity: 0,
      partNo: "",
      partId: "",
      category: "",
      description: "",
      cost: 0,
      pricePerPart: 0,
      extPrice: 0,
      commissioned: 0,
      uniqueId: "",
      partStatus: "",
    });
  };

  const openEditPart = (part) => {
    setPart(part);
    setEditPart(true);
  };

  const submitEditPart = () => {
    setLoading(true);
    let toSend = {
      quantity: part.quantity,
      pricePerPart: part.pricePerPart,
      extPrice: part.extPrice,
      partStatus: part.partStatus,
    };
    if (job.warranty) {
      toSend.chargeToCustomer = part.chargeToCustomer;
    }
    UpdateJobPart(jobId, part.uniqueId, toSend)
      .then((res) => {
        toast.success("Job part updated successfully");
        closeEditPart();
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setLocation(res.data.location);
            AdvancedPartsQuery({ limit: 10, page: 0 })
              .then((res) => {
                setRawPartsData(res.data.results);
                setPartsData(res.data.results);
                setTotal(res.data.total);
                setPage(res.data.page);
                setSize(res.data.size);
                setTimeout(() => setLoading(false), 700);
              })
              .catch((err) => {
                toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                setTimeout(() => setLoading(false), 700);
              });
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating job part");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  const editPartChange = (e) => {
    setDummyLoading(true);
    let { name, value } = e.target;
    let tmp = part;
    // tmp[name] = value;
    if (name === "quantity") {
      tmp[name] = parseFloat(value);
      tmp.extPrice = value * tmp.pricePerPart;
      if (extPriceRef.current) {
        extPriceRef.current.value = tmp.extPrice;
      }
    } else if (name === "pricePerPart") {
      if (parseFloat(value) >= 0) {
        tmp[name] = parseFloat(value);
        tmp.extPrice = value * tmp.quantity;
        if (extPriceRef.current) {
          extPriceRef.current.value = tmp.extPrice;
        }
      } else {
        toast.error("Price per part must be greater than 0");
      }
    } else if (name === "extPrice") {
      if (parseFloat(value) >= 0) {
        tmp[name] = parseFloat(value);
        tmp.pricePerPart = parseFloat((value / tmp.quantity).toFixed(2));
        if (pricePerPartRef.current) {
          pricePerPartRef.current.value = tmp.extPrice;
        }
      } else {
        toast.error("Extended price must be greater than 0");
      }
    } else if (name === "partStatus") {
      tmp.partStatus = value;
      if (value.includes("returned")) {
        tmp.extPrice = 0;
        tmp.quantity = 0;
      }
    }
    setPart(tmp);
    setTimeout(() => setDummyLoading(false), 400);
  };

  const renderEditPartModal = () => {
    return (
      <Modal
        title="Edit Job Part"
        open={editPart}
        onCancel={closeEditPart}
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-2 pt-3 mt-6 border-t border-gray-300">
            <SecondaryButton label="Cancel" callback={() => closeEditPart()} />
            <PrimaryButton label="Save changes" callback={() => submitEditPart()} />
          </div>,
        ]}
        centered
        destroyOnClose
        width={650}
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 mt-2">
          <p className="text-sm font-semibold">
            Part No: <span className="font-normal">{part.partNo}</span>
          </p>
          <p className="text-sm font-semibold">
            Description: <span className="font-normal">{part.description}</span>
          </p>
          {authState.user.functionCategory !== "technician" && (
            <p className="text-sm font-semibold">
              Cost: <span className="font-normal">{formatCurrency(part.cost)}</span>
            </p>
          )}
          <div className="flex flex-row items-center justify-start gap-2">
            <p className="text-sm font-semibold">Part Status: </p>
            <p className="font-normal">{renderPartStatus(part.partStatus)}</p>
          </div>
          <div className="w-full">
            <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-900">
              Quantity
            </label>
            <div className="mt-2">
              <InputNumber
                placeholder="Part Quantity"
                value={part.quantity}
                name="quantity"
                onBlur={(e) => editPartChange(e)}
                className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                min={0}
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="pricePerPart" className="block text-sm font-medium leading-6 text-gray-900">
              Price per Part
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <InputNumber
                placeholder="Price per Part"
                value={part.pricePerPart}
                name="pricePerPart"
                id="pricePerPart"
                onBlur={(e) => editPartChange(e)}
                className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                min={0}
                addonBefore="$"
                addonAfter="USD"
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="extPrice" className="block text-sm font-medium leading-6 text-gray-900">
              Ext. Price
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <InputNumber
                placeholder="Ext. Price"
                value={part.extPrice}
                name="extPrice"
                id="extPrice"
                onBlur={(e) => editPartChange(e)}
                className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                min={0}
                addonBefore="$"
                addonAfter="USD"
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="partStatus" className="block text-sm font-medium leading-6 text-gray-900">
              Part Status
            </label>
            <Select
              defaultValue={part.partStatus ? part.partStatus : null}
              placeholder="Pick a status"
              options={[
                { value: "entered", label: "Entered" },
                { value: "backOrdered", label: "Back Ordered" },
                { value: "onOrder", label: "Ordered" },
                { value: "inventory", label: "In inventory" },
                { value: "returnedToInventory", label: "Returned To Inventory" },
                { value: "returnedToVendor", label: "Returned To Vendor" },
                { value: "inTransit", label: "In Transit" },
                { value: "committed", label: "Committed" },
              ]}
              className="w-full font-sans"
              controls={false}
              onChange={(e) => editPartChange({ target: { name: "partStatus", value: e } })}
              onSelect={(e) => editPartChange({ target: { name: "partStatus", value: e } })}
              showSearch
              filterOption={filterOption}
            />
          </div>
          {job.warranty && (
            <div className="flex items-center justify-between w-full px-1 py-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">Charge to Customer</label>
              <Switch defaultChecked={part.chargeToCustomer} onChange={(v) => setPart({ ...part, chargeToCustomer: v })} />
            </div>
          )}
        </div>
      </Modal>
    );
  };

  const renderAddPartQuantityChange = (partId) => {
    let pt = partsData.find((pt) => pt.partId === partId);
    let defVal = 0;
    let find = partsToAdd.find((p) => p.partId === partId);
    if (find) {
      defVal = find.quantity;
    }
    if (pt && !dummyLoading) {
      return (
        <InputNumber
          className="w-[140px]"
          id={pt.partId}
          defaultValue={parseFloat(defVal)}
          onBlur={changePartQuantity}
          min={0}
          controls={false}
          addonBefore="QTY"
        />
      );
    } else {
      return (
        <input
          className="w-[140px] rounded-md py-1 px-3 text-gray-900 border border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          type="text"
          id="LOADING"
          defaultValue={"LOADING"}
          disabled={true}
        />
      );
    }
  };

  const renderAddPartPriceChange = (partId) => {
    let pt = partsData.find((pt) => pt.partId === partId);
    if (pt && !dummyLoading) {
      return (
        <InputNumber
          className="w-[140px]"
          defaultValue={parseFloat(pt.chargeOut)}
          onBlur={changePartPrice}
          min={0}
          step={0.01}
          id={pt.partId}
          controls={false}
          addonBefore="$"
        />
      );
    } else {
      return (
        <input
          className="w-[140px] rounded-md py-1 px-3 text-gray-900 border border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          type="text"
          id="LOADING"
          defaultValue={"LOADING"}
          disabled={true}
        />
      );
    }
  };

  const renderAddPartsModal = () => {
    return (
      <Modal open={addPartsModal} onCancel={() => closeAddPartsModal()} footer={[]} title="Add Parts to Job" centered destroyOnClose width={900}>
        <div className="flex flex-col items-start justify-between w-full h-full gap-1">
          <div className="flex flex-row items-center justify-end w-full gap-2">
            <Input
              placeholder="Start typing to search parts"
              onChange={(e) => setSearch(e.target.value)}
              defaultValue={search}
              className="w-[280px] px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
              allowClear
              onPressEnter={() => onPartSearch(page, size)}
              onClear={() => resetPartSearch(page, size)}
            />
            <SecondaryButton label="Search" callback={() => onPartSearch(page, size)} />
          </div>
          <Table
            dataSource={partsData}
            size="small"
            pagination={{
              total: total,
              responsive: true,
              hideOnSinglePage: true,
              onChange: (p, s) => changePage(p - 1, s),
              pageSize: size,
              current: page + 1,
              showSizeChanger: false,
            }}
            className="flex-grow w-full h-full py-2"
            loading={dummyLoading}
          >
            <Table.Column title="Department" dataIndex="department" key="department" render={(r) => departments.find((d) => d.departmentId === r)?.name} />
            <Table.Column title="Category" dataIndex="category" key="category" render={(r) => r.toUpperCase()} />
            <Table.Column
              title="Part No."
              dataIndex="partNo"
              key="partNo"
              render={(v) => <Tooltip title={v}>{v.length > 20 ? v.substring(0, 20) + "..." : v}</Tooltip>}
            />
            <Table.Column
              title="Description"
              dataIndex="description"
              key="description"
              render={(v) => <Tooltip title={v}>{v.length > 20 ? v.substring(0, 20) + "..." : v}</Tooltip>}
            />
            <Table.Column title="Stock" dataIndex="stock" key="stock" render={(r) => renderQtyInStock(r)} />
            <Table.Column title="Ext. Price" dataIndex="partId" key="partId" render={(r) => renderAddPartPriceChange(r)} />
            <Table.Column title="Quantity" dataIndex="partId" key="partId" render={(r) => renderAddPartQuantityChange(r)} />
          </Table>
          <div className="flex flex-row items-center justify-end w-full gap-4 pt-5 border-t border-gray-300">
            <SecondaryButton label="Cancel" callback={() => closeAddPartsModal()} />
            <PrimaryButton label="Add Parts to Job" callback={() => addNewParts()} />
          </div>
        </div>
      </Modal>
    );
  };

  const onPartSearch = (pg, lmt) => {
    setDummyLoading(true);
    AdvancedPartsQuery({ search, limit: lmt, page: pg })
      .then((res) => {
        setRawPartsData(res.data.results);
        setPartsData(res.data.results);
        setTotal(res.data.total);
        setPage(res.data.page);
        setSize(res.data.size);
        setTimeout(() => setDummyLoading(false), 400);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
        setTimeout(() => setDummyLoading(false), 400);
      });
  };

  const resetPartSearch = (pg, lmt) => {
    setSearch("");
    setDummyLoading(true);
    AdvancedPartsQuery({ search: "", limit: lmt, page: pg })
      .then((res) => {
        setRawPartsData(res.data.results);
        setPartsData(res.data.results);
        setTotal(res.data.total);
        setPage(res.data.page);
        setSize(res.data.size);
        setTimeout(() => setDummyLoading(false), 400);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
        setTimeout(() => setDummyLoading(false), 400);
      });
  };

  const changePage = (pg, sz) => {
    setPage(pg);
    setSize(sz);
    onPartSearch(pg, sz);
  };

  const renderQtyInStock = (pt) => {
    if (pt && pt.length > 0) {
      let total = 0;
      for (let i = 0; i < pt.length; i++) {
        const element = pt[i];
        total += element.quantityInStock;
      }
      return total;
    } else {
      return "0";
    }
  };

  const closeAddPartsModal = () => {
    setAddPartsModal(false);
    setPartsToAdd([]);
    setPartsData([]);
    setAddPartsReportModal(false);
    setSearch("");
    setPage(0);
    setTotal(0);
    setSize(10);
  };

  const changePartPrice = (e) => {
    let tmp = partsToAdd;
    let { id, value } = e.target;
    if (value !== "") {
      if (parseFloat(value) >= 0) {
        let part = partsData.find((pt) => pt.partId === id);
        let existing = _.find(tmp, (p) => p.partId === id);
        if (existing && existing !== undefined) {
          existing.pricePerPart = value;
          existing.extPrice = parseFloat((existing.quantity * value).toFixed(2));
          let index = _.findIndex(tmp, (p) => p.uniqueId === existing.uniqueId);
          tmp[index] = existing;
          setPartsToAdd(tmp);
        } else {
          tmp.push({
            quantity: 0,
            partNo: part.partNo,
            partId: id,
            category: part.category,
            description: part.description,
            cost: part.cost,
            pricePerPart: value,
            extPrice: 0,
            uniqueId: uuidv4(),
          });
          setPartsToAdd(tmp);
        }
      } else {
        toast.error("Price must be greater than 0");
      }
    } else {
      toast.error("Please enter a valid price");
    }
  };

  const changePartQuantity = (e) => {
    let tmp = partsToAdd;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      if (parseFloat(value) > 0) {
        let part = partsData.find((pt) => pt.partId === id);
        let existing = _.find(tmp, (p) => p.partId === id);
        if (existing && existing !== undefined) {
          existing.quantity = value;
          existing.extPrice = parseFloat((part.chargeOut * value).toFixed(2));
          let index = _.findIndex(tmp, (p) => p.uniqueId === existing.uniqueId);
          tmp[index] = existing;
          setPartsToAdd(tmp);
        } else {
          tmp.push({
            quantity: value,
            partNo: part.partNo,
            partId: id,
            category: part.category,
            description: part.description,
            cost: part.cost,
            pricePerPart: parseFloat(part.chargeOut.toFixed(2)),
            extPrice: parseFloat((part.chargeOut * value).toFixed(2)),
            uniqueId: uuidv4(),
          });
          setPartsToAdd(tmp);
        }
      } else {
        toast.error("Quantity must be greater than 0");
      }
    } else {
      toast.error("Please enter a valid quantity");
    }
  };

  const addNewParts = () => {
    let tmp = partsToAdd;
    for (let i = 0; i < tmp.length; i++) {
      const element = tmp[i];
      tmp[i].toCommit = 0;
      tmp[i].quantity = parseFloat(tmp[i].quantity);
      tmp[i].haveLocalStock = false;
      tmp[i].remoteSource = false;
      tmp[i].uniqueId = uuidv4();
      let existing = _.find(rawPartsData, (p) => p.partId === element.partId);
      if (existing && existing !== undefined) {
        if (existing.stock && existing.stock.length > 0) {
          let stock = existing.stock.find((st) => st.location === job.locationId);
          if (stock) {
            if (stock.quantityInStock >= element.quantity) {
              tmp[i].status = "inventory";
              tmp[i].haveLocalStock = true;
              tmp[i].toCommit = element.quantity;
            } else if (stock.quantityInStock !== 0) {
              tmp[i].status = "partiallyAvailable";
              tmp[i].haveLocalStock = true;
              tmp[i].toCommit = stock.quantityInStock;
            } else {
              tmp[i].status = "noStock";
              tmp[i].toCommit = 0;
            }
          }
          if (!existing || tmp[i].status !== "inventory" || tmp[i].toCommit !== tmp[i].quantity) {
            for (let j = 0; j < existing.stock.length; j++) {
              const element = existing.stock[j];
              if (element.quantityInStock >= tmp[i].quantity) {
                tmp[i].status = "availableForTransfer";
                tmp[i].remoteSource = element.location;
                tmp[i].toCommit += tmp[i].quantity;
              } else if (element.quantityInStock > 0 && element.quantityInStock < tmp[i].quantity) {
                tmp[i].status = "partiallyAvailableForTransfer";
                tmp[i].remoteSource = element.location;
                tmp[i].toCommit += element.quantityInStock;
              } else {
                tmp[i].status = "noStock";
              }
            }
          }
        } else {
          tmp[i].status = "noStock";
        }
      } else {
        tmp[i].status = "unknown";
      }
    }
    setPartsToAdd(tmp);
    setAddPartsReportModal(true);
  };

  const renderAddPartsReportModal = () => {
    let inventory = 0;
    let partiallyAvailable = 0;
    let noStock = 0;
    let availableForTransfer = 0;
    let partiallyAvailableForTransfer = 0;
    let unknown = 0;
    let tmp = partsToAdd;
    for (let i = 0; i < tmp.length; i++) {
      let el = tmp[i];
      if (el.status === "inventory") {
        inventory += 1;
      } else if (el.status === "partiallyAvailable") {
        partiallyAvailable += 1;
      } else if (el.status === "noStock") {
        noStock += 1;
      } else if (el.status === "availableForTransfer") {
        availableForTransfer += 1;
      } else if (el.status === "partiallyAvailableForTransfer") {
        partiallyAvailableForTransfer += 1;
      } else if (el.status === "unknown") {
        unknown += 1;
      }
    }
    return (
      <Modal
        open={addPartsReportModal}
        onCancel={() => setAddPartsReportModal(false)}
        footer={[]}
        title="Add Parts - REPORT"
        centered
        destroyOnClose
        style={{ minWidth: 950 }}
      >
        <div className="w-full sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
            <BoxIso className="w-6 h-6 text-blue-600" aria-hidden="true" />
          </div>
          <div className="w-full mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <div className="w-full my-2">
              <table className="min-w-full w-full divide-y divide-gray-300 max-h-[700px] overflow-y-auto">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Part No
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Quantity
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Available
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Local Inventory
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Transfer
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Requires PO
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Requires IT
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Remove</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {partsToAdd.map((pt) => (
                    <tr key={uuidv4()}>
                      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">{pt.partNo}</td>
                      <td className="px-3 py-4 text-sm font-medium text-center text-gray-900 whitespace-nowrap">{pt.quantity}</td>
                      <td className="px-3 py-4 text-sm font-medium text-center text-gray-900 whitespace-nowrap">{pt.toCommit}</td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{pt.haveLocalStock ? renderYes() : renderNo()}</td>
                      <td className="px-3 py-4 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                        {pt.remoteSource ? "From: " + location.find((l) => l.locationId === pt.remoteSource).locationName : renderNo()}
                      </td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{pt.toCommit < pt.quantity ? renderYes() : renderNo()}</td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{pt.remoteSource ? renderYes() : renderNo()}</td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{renderAddPartStatus(pt.status)}</td>
                      <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                        <SecondaryButton label="Remove" callback={() => {}} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full pb-3 text-sm font-medium px-14">
          <p>Purchase Orders to be generated: {partiallyAvailable + noStock + partiallyAvailableForTransfer}</p>
          <p>Inventory Transmittals to be generated: {availableForTransfer + partiallyAvailableForTransfer}</p>
        </div>
        <div className="flex flex-row items-center justify-end w-full gap-4 pt-4 mt-2 border-t border-gray-300">
          <SecondaryButton label="Cancel" callback={() => setAddPartsReportModal(false)} />
          <PrimaryButton label="Proceed" callback={() => submitNewParts()} />
        </div>
      </Modal>
    );
  };

  const renderAddPartStatus = (status) => {
    if (status === "inventory" || status === "availableForTransfer") {
      return "In Inventory";
    } else if (status === "partiallyAvailable" || status === "partiallyAvailableForTransfer") {
      return "Partially Available";
    } else if (status === "noStock") {
      return "No Stock";
    } else {
      return "Unknown";
    }
  };

  const renderYes = () => {
    return (
      <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 mx-auto bg-green-100 rounded-full">
        <Check className="w-4 h-4 text-green-600" aria-hidden="true" />
      </div>
    );
  };

  const renderNo = () => {
    return (
      <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 mx-auto bg-red-100 rounded-full">
        <Xmark className="w-4 h-4 text-red-600" aria-hidden="true" />
      </div>
    );
  };

  const submitNewParts = () => {
    let tmp = [];
    for (let i = 0; i < partsToAdd.length; i++) {
      let el = partsToAdd[i];
      tmp.push({
        quantity: el.quantity,
        partId: el.partId,
        pricePerPart: el.pricePerPart,
        extPrice: el.extPrice,
        uniqueId: el.uniqueId,
      });
    }
    if (tmp.length > 0) {
      setLoading(true);
      AddPartsToAJob(jobId, tmp)
        .then((res) => {
          toast.success("Job part updated successfully");
          closeAddPartsModal();
          GetOneJob(jobId)
            .then((res) => {
              setJob(res.data.job);
              setLocation(res.data.location);
              AdvancedPartsQuery({ limit: 10, page: 0 })
                .then((res) => {
                  setRawPartsData(res.data.results);
                  setPartsData(res.data.results);
                  setTotal(res.data.total);
                  setPage(res.data.page);
                  setSize(res.data.size);
                  setTimeout(() => setLoading(false), 700);
                })
                .catch((err) => {
                  toast.error(err.response.data.message ? err.response.data.message : "Error loading parts information");
                  setTimeout(() => setLoading(false), 700);
                });
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
              setTimeout(() => {
                navigate("/jobs");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error updating job part");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        });
    } else {
      toast.error("No parts to add to job");
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Parts - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      value={tab.name}
                      key={uuidv4()}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Job Parts</p>
              {job.jobStatus === "open" && <SecondaryButton label="Add Parts" callback={() => setAddPartsModal(true)} />}
            </div>
            <div
              className={`grid w-full ${
                authState.user.functionCategory !== "technician" ? "grid-cols-3 md:grid-cols-9" : "grid-cols-3 md:grid-cols-8"
              } gap-4 py-3 border-b border-gray-300`}
            >
              <p className="text-xs font-semibold uppercase">Part No</p>
              <p className="hidden text-xs font-semibold uppercase md:inline-flex">Description</p>
              <p className="text-xs font-semibold text-center uppercase">Quantity</p>
              <p className="hidden text-xs font-semibold text-center uppercase md:inline-flex">Commissioned</p>
              {authState.user.functionCategory !== "technician" && <p className="hidden text-xs font-semibold text-center uppercase md:inline-flex">Cost</p>}
              <p className="hidden text-xs font-semibold text-center uppercase md:inline-flex">Price Per Part</p>
              <p className="hidden text-xs font-semibold text-center uppercase md:inline-flex">Ext. Price</p>
              <p className="hidden text-xs font-semibold text-center uppercase md:inline-flex">Part Status</p>
              {authState.user.functionCategory !== "technician" && <div className="flex flex-row items-center justify-center"></div>}
            </div>
            {job.parts.map((part, index) => renderPart(part, index))}
          </div>
          {renderEditPartModal()}
          {renderAddPartsModal()}
          {renderAddPartsReportModal()}
        </>
      )}
    </div>
  );
};

export default JobParts;
