import axios from "axios";

let api_url = process.env.REACT_APP_API_HOST;

const axiosInstance = axios.create({
  baseURL: api_url,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("auth-token");
    if (token) {
      config.headers["token"] = `${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (err.response.status !== 402) {
      const originalConfig = err.config;
      if (originalConfig.url !== "/login" && err.response) {
        if (err.response.status === 440) {
          window.location.href = "/login";
        } else {
          return Promise.reject(err);
        }
      } else {
        return Promise.reject(err);
      }
    } else {
      window.location.href = "/";
    }
    return Promise.reject(err);
  },
);

export default axiosInstance;
