import validator from "validator";

const testZip = (val) => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
};

const testPhone = (val) => {
  return /^((\+1|1)?( |-)?)?(\(([2-9])(?:\d(?!\5)\d|(?!\5)\d\d)\)|([2-9])(?:\d(?!\6)\d|(?!\6)\d\d))( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(val);
};

export const annualMaintenanceChecklist = {
  batterySystem: {
    fieldName: "batterySystem",
    label: "Battery System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  electrolyteLevel: {
    fieldName: "electrolyteLevel",
    label: "Electrolyte Levels Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTestBatteryVolts: {
    fieldName: "loadTestBatteryVolts",
    label: "Load test battery set-record voltage",
    inputType: "text",
    placeholder: "Load test battery set-record voltage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  terminals: {
    fieldName: "terminals",
    label: "Terminals - Removed / Cleaned / Coated",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  electricalSystem: {
    fieldName: "electricalSystem",
    label: "Electrical System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wiringCondition: {
    fieldName: "wiringCondition",
    label: "General Wiring Condition / Termination Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferSwitchWiring: {
    fieldName: "transferSwitchWiring",
    label: "Transfer Switch Wiring Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  controlPanelLights: {
    fieldName: "controlPanelLights",
    label: "Control Panel Indicator Lights Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferSwitchLights: {
    fieldName: "transferSwitchLights",
    label: "Transfer Switch Indicator Lights Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineSafetyShutdownTest: {
    fieldName: "engineSafetyShutdownTest",
    label: "Engine Safety Shutdown Tests Performed",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  highWaterTemperature: {
    fieldName: "highWaterTemperature",
    label: "High Water Temperature",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lowOilPressure: {
    fieldName: "lowOilPressure",
    label: "Low Oil Pressure",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  overSpeed: {
    fieldName: "overSpeed",
    label: "Over Speed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  overCrank: {
    fieldName: "overCrank",
    label: "Over Crank",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  cycleCrank: {
    fieldName: "cycleCrank",
    label: "Cycle Crank",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lowWaterTemp: {
    fieldName: "lowWaterTemp",
    label: "Low Water Temperature",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preAlarm: {
    fieldName: "preAlarm",
    label: "Pre-Alarm",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  remoteAnnunciator: {
    fieldName: "remoteAnnunciator",
    label: "Remote Annunciator Tested",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exhaustSystemLabel: {
    fieldName: "exhaustSystemLabel",
    label: "Exhaust System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exhaustInspection: {
    fieldName: "exhaustInspection",
    label: "Overall Exhaust System Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exhaustDrainLine: {
    fieldName: "exhaustDrainLine",
    label: "Exhaust Drain Line Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelSystemLabel: {
    fieldName: "fuelSystemLabel",
    label: "Fuel System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelTankWater: {
    fieldName: "fuelTankWater",
    label: "Fuel Tank Inspected and Checked for Water",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferPump: {
    fieldName: "transferPump",
    label: "Transfer Pump Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelLineLeaks: {
    fieldName: "fuelLineLeaks",
    label: "Fuel Lines and Connections Checked for Leaks",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelFilterAndSeparator: {
    fieldName: "fuelFilterAndSeparator",
    label: "Fuel Filter and / or Separator Replaced",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolingSystemLabel: {
    fieldName: "coolingSystemLabel",
    label: "Cooling System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantLevel: {
    fieldName: "coolantLevel",
    label: "Coolant Level Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  antifreeze: {
    fieldName: "antifreeze",
    label: "Antifreeze Protection Level Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantAdditive: {
    fieldName: "coolantAdditive",
    label: "Coolant additive for diesel engines (test) and (DCA) the above",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantHoses: {
    fieldName: "coolantHoses",
    label: "Hoses and Connections inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  jacketWaterHeater: {
    fieldName: "jacketWaterHeater",
    label: "Jacket Water Heater Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  radiator: {
    fieldName: "radiator",
    label: "Radiator and Radiator Cap Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantWaterLeak: {
    fieldName: "coolantWaterLeak",
    label: "Water Leakage Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantBelts: {
    fieldName: "coolantBelts",
    label: "Belts and Tensioners Inspected / Adjusted",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  waterFilters: {
    fieldName: "waterFilters",
    label: "Water Filters Replaced",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  optionalService: {
    fieldName: "optionalService",
    label: "Optional Service",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadbankTesting: {
    fieldName: "loadbankTesting",
    label: "Load Bank Testing Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  generatorsLabel: {
    fieldName: "generatorsLabel",
    label: "Generators",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  rearBearing: {
    fieldName: "rearBearing",
    label: "Rear Bearing Inspected / Lubricated",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilHousingDoorsAndHardware: {
    fieldName: "oilHousingDoorsAndHardware",
    label: "Oil Housing Doors and Hardware Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lubricationLabel: {
    fieldName: "lubricationLabel",
    label: "Lubrication",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilLeakage: {
    fieldName: "oilLeakage",
    label: "Oil Leakage Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lubeOilHeater: {
    fieldName: "lubeOilHeater",
    label: "Lube Oil Heater Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  crankCaseBreather: {
    fieldName: "crankCaseBreather",
    label: "Crank Case Breather Inspected / Cleaned",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineOilFilterGasket: {
    fieldName: "engineOilFilterGasket",
    label: "Engine Oil Filter / Gasket Replaced",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilSampleTaken: {
    fieldName: "oilSampleTaken",
    label: "Engine Oil Sample Collected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  primeMoverLabel: {
    fieldName: "primeMoverLabel",
    label: "Prime Mover",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  primeMoverInspection: {
    fieldName: "primeMoverInspection",
    label: "Prime Mover General Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dieselEngineLabel: {
    fieldName: "dieselEngineLabel",
    label: "Diesel Engine Checklist",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dieselEngineOperational: {
    fieldName: "dieselEngineOperational",
    label: "Diesel Engine Operational Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dieselEngineOil: {
    fieldName: "dieselEngineOil",
    label: "Diesel Engine Oil Level Checked / Changed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dieselEngineAirFilter: {
    fieldName: "dieselEngineAirFilter",
    label: "Diesel Engine Air Filter Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sparkIgnitedChecklist: {
    fieldName: "sparkIgnitedChecklist",
    label: "Spark Ignited Engine Checklist",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sparkOperational: {
    fieldName: "sparkOperational",
    label: "Engine Operational Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sparkOil: {
    fieldName: "sparkOil",
    label: "Oil Level Checked / Changed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sparkIgnition: {
    fieldName: "sparkIgnition",
    label: "Ignition System and Distributor Cap Inspected / Adjusted",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sparkAirFilter: {
    fieldName: "sparkAirFilter",
    label: "Air Filter Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  testingLabel: {
    fieldName: "testingLabel",
    label: "Testing",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  testRunUnitsLoad: {
    fieldName: "testRunUnitsLoad",
    label: "Test Run Units - Actual Load Tested (if authorized)",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  voltageAndFrequency: {
    fieldName: "voltageAndFrequency",
    label: "Voltage and Frequency Checked / Adjusted",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  recordValues: {
    fieldName: "recordValues",
    label: "Record Values",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineReadingsLabel: {
    fieldName: "engineReadingsLabel",
    label: "Engine Readings",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilPressureReading: {
    fieldName: "oilPressureReading",
    label: "Oil Pressure Reading",
    inputType: "text",
    placeholder: "Oil Pressure Reading",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  waterTemperatureReading: {
    fieldName: "waterTemperatureReading",
    label: "Water Temperature Reading",
    inputType: "text",
    placeholder: "Water Temperature Reading",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineAlternatorVoltage: {
    fieldName: "engineAlternatorVoltage",
    label: "Engine Alternator Voltage",
    inputType: "text",
    placeholder: "Engine Alternator Voltage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  generatorReadingLabel: {
    fieldName: "generatorReadingLabel",
    label: "Generator Readings",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  voltageNoLoad: {
    fieldName: "voltageNoLoad",
    label: "Voltage (No Load)",
    inputType: "text",
    placeholder: "Voltage (No Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  voltageLoad: {
    fieldName: "voltageLoad",
    label: "Voltage (With Load)",
    inputType: "text",
    placeholder: "Voltage (With Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  frequencyNoLoad: {
    fieldName: "frequencyNoLoad",
    label: "Frequency (No Load)",
    inputType: "text",
    placeholder: "Frequency (No Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  frequencyLoad: {
    fieldName: "frequencyLoad",
    label: "Frequency (With Load)",
    inputType: "text",
    placeholder: "Frequency (With Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  amperageL: {
    fieldName: "amperageL",
    label: "Amperage (L)",
    inputType: "text",
    placeholder: "Amperage (L)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  amperageA: {
    fieldName: "amperageA",
    label: "Amperage (A)",
    inputType: "text",
    placeholder: "Amperage (A)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  amperageB: {
    fieldName: "amperageB",
    label: "Amperage (B)",
    inputType: "text",
    placeholder: "Amperage (B)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  amperageC: {
    fieldName: "amperageC",
    label: "Amperage (C)",
    inputType: "text",
    placeholder: "Amperage (C)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferSwitchLabel: {
    fieldName: "transferSwitchLabel",
    label: "Transfer Switch Operation (Authorization Required)",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayEngineStart: {
    fieldName: "timeDelayEngineStart",
    label: "Time Delay Engine Start",
    inputType: "text",
    placeholder: "Time Delay Engine Start",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayNormalToEmergency: {
    fieldName: "timeDelayNormalToEmergency",
    label: "Time Delay Normal to Emergency",
    inputType: "text",
    placeholder: "Time Delay Normal to Emergency",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayEmergencyToNormal: {
    fieldName: "timeDelayEmergencyToNormal",
    label: "Time Delay Emergency to Normal",
    inputType: "text",
    placeholder: "Time Delay Emergency to Normal",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayCoolDown: {
    fieldName: "timeDelayCoolDown",
    label: "Time Delay Cool Down",
    inputType: "text",
    placeholder: "Time Delay Cool Down",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exerciseClockDay: {
    fieldName: "exerciseClockDay",
    label: "Exercise Clock Day",
    inputType: "select",
    placeholder: "Select One",
    defaultValue: "",
    options: [
      { value: "Monday", label: "Monday" },
      { value: "Tuesday", label: "Tuesday" },
      { value: "Wednesday", label: "Wednesday" },
      { value: "Thursday", label: "Thursday" },
      { value: "Friday", label: "Friday" },
      { value: "Saturday", label: "Saturday" },
      { value: "Sunday", label: "Sunday" },
    ],
    config: {
      required: false,
    },
  },
  exerciseClockTime: {
    fieldName: "exerciseClockTime",
    label: "Exercise Clock Time",
    inputType: "text",
    placeholder: "Exercise Clock Time",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  restoreToAutomaticLabel: {
    fieldName: "restoreToAutomaticLabel",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  restoreToAutomatic: {
    fieldName: "restoreToAutomatic",
    label: "Restore System to Automatic",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preNoteDivider: {
    fieldName: "preNotesDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  notes: {
    fieldName: "notes",
    label: "notes",
    inputType: "textarea-field",
    placeholder: "Remarks, Findings, Recommendations",
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const semiAnnualMaintenanceChecklist = {
  batterySystem: {
    fieldName: "batterySystem",
    label: "Battery System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  electrolyteLevel: {
    fieldName: "electrolyteLevel",
    label: "Electrolyte Levels Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTestBatteryVolts: {
    fieldName: "loadTestBatteryVolts",
    label: "Load test battery set-record voltage",
    inputType: "text",
    placeholder: "Load test battery set-record voltage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  terminals: {
    fieldName: "terminals",
    label: "Terminals - Removed / Cleaned / Coated",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  electricalSystem: {
    fieldName: "electricalSystem",
    label: "Electrical System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wiringCondition: {
    fieldName: "wiringCondition",
    label: "General Wiring Condition / Termination Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferSwitchWiring: {
    fieldName: "transferSwitchWiring",
    label: "Transfer Switch Wiring Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  controlPanelLights: {
    fieldName: "controlPanelLights",
    label: "Control Panel Indicator Lights Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferSwitchLights: {
    fieldName: "transferSwitchLights",
    label: "Transfer Switch Indicator Lights Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineSafetyShutdownTest: {
    fieldName: "engineSafetyShutdownTest",
    label: "Engine Safety Shutdown Tests Performed",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  highWaterTemperature: {
    fieldName: "highWaterTemperature",
    label: "High Water Temperature",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lowOilPressure: {
    fieldName: "lowOilPressure",
    label: "Low Oil Pressure",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  overSpeed: {
    fieldName: "overSpeed",
    label: "Over Speed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  overCrank: {
    fieldName: "overCrank",
    label: "Over Crank",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  cycleCrank: {
    fieldName: "cycleCrank",
    label: "Cycle Crank",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lowWaterTemp: {
    fieldName: "lowWaterTemp",
    label: "Low Water Temperature",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preAlarm: {
    fieldName: "preAlarm",
    label: "Pre-Alarm",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  remoteAnnunciator: {
    fieldName: "remoteAnnunciator",
    label: "Remote Annunciator Tested",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exhaustSystemLabel: {
    fieldName: "exhaustSystemLabel",
    label: "Exhaust System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exhaustInspection: {
    fieldName: "exhaustInspection",
    label: "Overall Exhaust System Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exhaustDrainLine: {
    fieldName: "exhaustDrainLine",
    label: "Exhaust Drain Line Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelSystemLabel: {
    fieldName: "fuelSystemLabel",
    label: "Fuel System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelTankWater: {
    fieldName: "fuelTankWater",
    label: "Fuel Tank Inspected and Checked for Water",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferPump: {
    fieldName: "transferPump",
    label: "Transfer Pump Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelLineLeaks: {
    fieldName: "fuelLineLeaks",
    label: "Fuel Lines and Connections Checked for Leaks",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolingSystemLabel: {
    fieldName: "coolingSystemLabel",
    label: "Cooling System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantLevel: {
    fieldName: "coolantLevel",
    label: "Coolant Level Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  antifreeze: {
    fieldName: "antifreeze",
    label: "Antifreeze Protection Level Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantAdditive: {
    fieldName: "coolantAdditive",
    label: "Coolant additive for diesel engines (test) and (DCA) the above",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantHoses: {
    fieldName: "coolantHoses",
    label: "Hoses and Connections inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  jacketWaterHeater: {
    fieldName: "jacketWaterHeater",
    label: "Jacket Water Heater Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  radiator: {
    fieldName: "radiator",
    label: "Radiator and Radiator Cap Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantWaterLeak: {
    fieldName: "coolantWaterLeak",
    label: "Water Leakage Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantBelts: {
    fieldName: "coolantBelts",
    label: "Belts and Tensioners Inspected / Adjusted",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  generatorsLabel: {
    fieldName: "generatorsLabel",
    label: "Generators",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilHousingDoorsAndHardware: {
    fieldName: "oilHousingDoorsAndHardware",
    label: "Oil Housing Doors and Hardware Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lubricationLabel: {
    fieldName: "lubricationLabel",
    label: "Lubrication",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilLevel: {
    fieldName: "oilLevel",
    label: "Oil Level Checked / Changed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilLeakage: {
    fieldName: "oilLeakage",
    label: "Oil Leakage Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lubeOilHeater: {
    fieldName: "lubeOilHeater",
    label: "Lube Oil Heater Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  primeMoverLabel: {
    fieldName: "primeMoverLabel",
    label: "Prime Mover",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  primeMoverInspection: {
    fieldName: "primeMoverInspection",
    label: "Prime Mover General Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dieselEngineLabel: {
    fieldName: "dieselEngineLabel",
    label: "Diesel Engine Checklist",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dieselEngineOperational: {
    fieldName: "dieselEngineOperational",
    label: "Diesel Engine Operational Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sparkIgnitedChecklist: {
    fieldName: "sparkIgnitedChecklist",
    label: "Spark Ignited Engine Checklist",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sparkOperational: {
    fieldName: "sparkOperational",
    label: "Engine Operational Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  testingLabel: {
    fieldName: "testingLabel",
    label: "Testing",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  testRunUnitsLoad: {
    fieldName: "testRunUnitsLoad",
    label: "Test Run Units - Actual Load Tested (if authorized)",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  voltageAndFrequency: {
    fieldName: "voltageAndFrequency",
    label: "Voltage and Frequency Checked / Adjusted",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  recordValues: {
    fieldName: "recordValues",
    label: "Record Values",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineReadingsLabel: {
    fieldName: "engineReadingsLabel",
    label: "Engine Readings",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilPressureReading: {
    fieldName: "oilPressureReading",
    label: "Oil Pressure Reading",
    inputType: "text",
    placeholder: "Oil Pressure Reading",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  waterTemperatureReading: {
    fieldName: "waterTemperatureReading",
    label: "Water Temperature Reading",
    inputType: "text",
    placeholder: "Water Temperature Reading",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineAlternatorVoltage: {
    fieldName: "engineAlternatorVoltage",
    label: "Engine Alternator Voltage",
    inputType: "text",
    placeholder: "Engine Alternator Voltage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  generatorReadingLabel: {
    fieldName: "generatorReadingLabel",
    label: "Generator Readings",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  voltageNoLoad: {
    fieldName: "voltageNoLoad",
    label: "Voltage (No Load)",
    inputType: "text",
    placeholder: "Voltage (No Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  voltageLoad: {
    fieldName: "voltageLoad",
    label: "Voltage (With Load)",
    inputType: "text",
    placeholder: "Voltage (With Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  frequencyNoLoad: {
    fieldName: "frequencyNoLoad",
    label: "Frequency (No Load)",
    inputType: "text",
    placeholder: "Frequency (No Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  frequencyLoad: {
    fieldName: "frequencyLoad",
    label: "Frequency (With Load)",
    inputType: "text",
    placeholder: "Frequency (With Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayEngineStart: {
    fieldName: "timeDelayEngineStart",
    label: "Time Delay Engine Start",
    inputType: "text",
    placeholder: "Time Delay Engine Start",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayNormalToEmergency: {
    fieldName: "timeDelayNormalToEmergency",
    label: "Time Delay Normal to Emergency",
    inputType: "text",
    placeholder: "Time Delay Normal to Emergency",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayEmergencyToNormal: {
    fieldName: "timeDelayEmergencyToNormal",
    label: "Time Delay Emergency to Normal",
    inputType: "text",
    placeholder: "Time Delay Emergency to Normal",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayCoolDown: {
    fieldName: "timeDelayCoolDown",
    label: "Time Delay Cool Down",
    inputType: "text",
    placeholder: "Time Delay Cool Down",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exerciseClockDay: {
    fieldName: "exerciseClockDay",
    label: "Exercise Clock Day",
    inputType: "select",
    placeholder: "Select One",
    defaultValue: "",
    options: [
      { value: "Monday", label: "Monday" },
      { value: "Tuesday", label: "Tuesday" },
      { value: "Wednesday", label: "Wednesday" },
      { value: "Thursday", label: "Thursday" },
      { value: "Friday", label: "Friday" },
      { value: "Saturday", label: "Saturday" },
      { value: "Sunday", label: "Sunday" },
    ],
    config: {
      required: false,
    },
  },
  exerciseClockTime: {
    fieldName: "exerciseClockTime",
    label: "Exercise Clock Time",
    inputType: "text",
    placeholder: "Exercise Clock Time",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  restoreToAutomaticLabel: {
    fieldName: "restoreToAutomaticLabel",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  restoreToAutomatic: {
    fieldName: "restoreToAutomatic",
    label: "Restore System to Automatic",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preNoteDivider: {
    fieldName: "preNotesDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  notes: {
    fieldName: "notes",
    label: "notes",
    inputType: "textarea-field",
    placeholder: "Remarks, Findings, Recommendations",
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const quarterlyMaintenanceChecklist = {
  batterySystem: {
    fieldName: "batterySystem",
    label: "Battery System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  electrolyteLevel: {
    fieldName: "electrolyteLevel",
    label: "Electrolyte Levels Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTestBatteryVolts: {
    fieldName: "loadTestBatteryVolts",
    label: "Load test battery set-record voltage",
    inputType: "text",
    placeholder: "Load test battery set-record voltage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  terminals: {
    fieldName: "terminals",
    label: "Terminals - Removed / Cleaned / Coated",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  electricalSystem: {
    fieldName: "electricalSystem",
    label: "Electrical System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wiringCondition: {
    fieldName: "wiringCondition",
    label: "General Wiring Condition / Termination Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferSwitchWiring: {
    fieldName: "transferSwitchWiring",
    label: "Transfer Switch Wiring Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  controlPanelLights: {
    fieldName: "controlPanelLights",
    label: "Control Panel Indicator Lights Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferSwitchLights: {
    fieldName: "transferSwitchLights",
    label: "Transfer Switch Indicator Lights Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineSafetyShutdownTest: {
    fieldName: "engineSafetyShutdownTest",
    label: "Engine Safety Shutdown Tests Performed",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  highWaterTemperature: {
    fieldName: "highWaterTemperature",
    label: "High Water Temperature",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lowOilPressure: {
    fieldName: "lowOilPressure",
    label: "Low Oil Pressure",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  overSpeed: {
    fieldName: "overSpeed",
    label: "Over Speed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  overCrank: {
    fieldName: "overCrank",
    label: "Over Crank",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  cycleCrank: {
    fieldName: "cycleCrank",
    label: "Cycle Crank",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lowWaterTemp: {
    fieldName: "lowWaterTemp",
    label: "Low Water Temperature",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preAlarm: {
    fieldName: "preAlarm",
    label: "Pre-Alarm",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  remoteAnnunciator: {
    fieldName: "remoteAnnunciator",
    label: "Remote Annunciator Tested",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exhaustSystemLabel: {
    fieldName: "exhaustSystemLabel",
    label: "Exhaust System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exhaustInspection: {
    fieldName: "exhaustInspection",
    label: "Overall Exhaust System Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exhaustDrainLine: {
    fieldName: "exhaustDrainLine",
    label: "Exhaust Drain Line Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelSystemLabel: {
    fieldName: "fuelSystemLabel",
    label: "Fuel System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelTankWater: {
    fieldName: "fuelTankWater",
    label: "Fuel Tank Inspected and Checked for Water",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferPump: {
    fieldName: "transferPump",
    label: "Transfer Pump Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelLineLeaks: {
    fieldName: "fuelLineLeaks",
    label: "Fuel Lines and Connections Checked for Leaks",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolingSystemLabel: {
    fieldName: "coolingSystemLabel",
    label: "Cooling System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantLevel: {
    fieldName: "coolantLevel",
    label: "Coolant Level Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  antifreeze: {
    fieldName: "antifreeze",
    label: "Antifreeze Protection Level Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantAdditive: {
    fieldName: "coolantAdditive",
    label: "Coolant additive for diesel engines (test) and (DCA) the above",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantHoses: {
    fieldName: "coolantHoses",
    label: "Hoses and Connections inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  jacketWaterHeater: {
    fieldName: "jacketWaterHeater",
    label: "Jacket Water Heater Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  radiator: {
    fieldName: "radiator",
    label: "Radiator and Radiator Cap Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantWaterLeak: {
    fieldName: "coolantWaterLeak",
    label: "Water Leakage Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantBelts: {
    fieldName: "coolantBelts",
    label: "Belts and Tensioners Inspected / Adjusted",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  generatorsLabel: {
    fieldName: "generatorsLabel",
    label: "Generators",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilHousingDoorsAndHardware: {
    fieldName: "oilHousingDoorsAndHardware",
    label: "Oil Housing Doors and Hardware Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lubricationLabel: {
    fieldName: "lubricationLabel",
    label: "Lubrication",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilLevel: {
    fieldName: "oilLevel",
    label: "Oil Level Checked / Changed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilLeakage: {
    fieldName: "oilLeakage",
    label: "Oil Leakage Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lubeOilHeater: {
    fieldName: "lubeOilHeater",
    label: "Lube Oil Heater Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  primeMoverLabel: {
    fieldName: "primeMoverLabel",
    label: "Prime Mover",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  primeMoverInspection: {
    fieldName: "primeMoverInspection",
    label: "Prime Mover General Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dieselEngineLabel: {
    fieldName: "dieselEngineLabel",
    label: "Diesel Engine Checklist",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dieselEngineOperational: {
    fieldName: "dieselEngineOperational",
    label: "Diesel Engine Operational Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sparkIgnitedChecklist: {
    fieldName: "sparkIgnitedChecklist",
    label: "Spark Ignited Engine Checklist",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sparkOperational: {
    fieldName: "sparkOperational",
    label: "Engine Operational Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  testingLabel: {
    fieldName: "testingLabel",
    label: "Testing",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  testRunUnitsLoad: {
    fieldName: "testRunUnitsLoad",
    label: "Test Run Units - Actual Load Tested (if authorized)",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  voltageAndFrequency: {
    fieldName: "voltageAndFrequency",
    label: "Voltage and Frequency Checked / Adjusted",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  recordValues: {
    fieldName: "recordValues",
    label: "Record Values",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineReadingsLabel: {
    fieldName: "engineReadingsLabel",
    label: "Engine Readings",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilPressureReading: {
    fieldName: "oilPressureReading",
    label: "Oil Pressure Reading",
    inputType: "text",
    placeholder: "Oil Pressure Reading",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  waterTemperatureReading: {
    fieldName: "waterTemperatureReading",
    label: "Water Temperature Reading",
    inputType: "text",
    placeholder: "Water Temperature Reading",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineAlternatorVoltage: {
    fieldName: "engineAlternatorVoltage",
    label: "Engine Alternator Voltage",
    inputType: "text",
    placeholder: "Engine Alternator Voltage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  generatorReadingLabel: {
    fieldName: "generatorReadingLabel",
    label: "Generator Readings",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  voltageNoLoad: {
    fieldName: "voltageNoLoad",
    label: "Voltage (No Load)",
    inputType: "text",
    placeholder: "Voltage (No Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  voltageLoad: {
    fieldName: "voltageLoad",
    label: "Voltage (With Load)",
    inputType: "text",
    placeholder: "Voltage (With Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  frequencyNoLoad: {
    fieldName: "frequencyNoLoad",
    label: "Frequency (No Load)",
    inputType: "text",
    placeholder: "Frequency (No Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  frequencyLoad: {
    fieldName: "frequencyLoad",
    label: "Frequency (With Load)",
    inputType: "text",
    placeholder: "Frequency (With Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayEngineStart: {
    fieldName: "timeDelayEngineStart",
    label: "Time Delay Engine Start",
    inputType: "text",
    placeholder: "Time Delay Engine Start",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayNormalToEmergency: {
    fieldName: "timeDelayNormalToEmergency",
    label: "Time Delay Normal to Emergency",
    inputType: "text",
    placeholder: "Time Delay Normal to Emergency",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayEmergencyToNormal: {
    fieldName: "timeDelayEmergencyToNormal",
    label: "Time Delay Emergency to Normal",
    inputType: "text",
    placeholder: "Time Delay Emergency to Normal",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayCoolDown: {
    fieldName: "timeDelayCoolDown",
    label: "Time Delay Cool Down",
    inputType: "text",
    placeholder: "Time Delay Cool Down",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exerciseClockDay: {
    fieldName: "exerciseClockDay",
    label: "Exercise Clock Day",
    inputType: "select",
    placeholder: "Select One",
    defaultValue: "",
    options: [
      { value: "Monday", label: "Monday" },
      { value: "Tuesday", label: "Tuesday" },
      { value: "Wednesday", label: "Wednesday" },
      { value: "Thursday", label: "Thursday" },
      { value: "Friday", label: "Friday" },
      { value: "Saturday", label: "Saturday" },
      { value: "Sunday", label: "Sunday" },
    ],
    config: {
      required: false,
    },
  },
  exerciseClockTime: {
    fieldName: "exerciseClockTime",
    label: "Exercise Clock Time",
    inputType: "text",
    placeholder: "Exercise Clock Time",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  restoreToAutomaticLabel: {
    fieldName: "restoreToAutomaticLabel",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  restoreToAutomatic: {
    fieldName: "restoreToAutomatic",
    label: "Restore System to Automatic",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preNoteDivider: {
    fieldName: "preNotesDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  notes: {
    fieldName: "notes",
    label: "notes",
    inputType: "textarea-field",
    placeholder: "Remarks, Findings, Recommendations",
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const monthlyMaintenanceChecklist = {
  batterySystem: {
    fieldName: "batterySystem",
    label: "Battery System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  electrolyteLevel: {
    fieldName: "electrolyteLevel",
    label: "Electrolyte Levels Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTestBatteryVolts: {
    fieldName: "loadTestBatteryVolts",
    label: "Load test battery set-record voltage",
    inputType: "text",
    placeholder: "Load test battery set-record voltage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  terminals: {
    fieldName: "terminals",
    label: "Terminals - Removed / Cleaned / Coated",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  electricalSystem: {
    fieldName: "electricalSystem",
    label: "Electrical System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wiringCondition: {
    fieldName: "wiringCondition",
    label: "General Wiring Condition / Termination Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferSwitchWiring: {
    fieldName: "transferSwitchWiring",
    label: "Transfer Switch Wiring Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  controlPanelLights: {
    fieldName: "controlPanelLights",
    label: "Control Panel Indicator Lights Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferSwitchLights: {
    fieldName: "transferSwitchLights",
    label: "Transfer Switch Indicator Lights Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineSafetyShutdownTest: {
    fieldName: "engineSafetyShutdownTest",
    label: "Engine Safety Shutdown Tests Performed",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  highWaterTemperature: {
    fieldName: "highWaterTemperature",
    label: "High Water Temperature",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lowOilPressure: {
    fieldName: "lowOilPressure",
    label: "Low Oil Pressure",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  overSpeed: {
    fieldName: "overSpeed",
    label: "Over Speed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  overCrank: {
    fieldName: "overCrank",
    label: "Over Crank",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  cycleCrank: {
    fieldName: "cycleCrank",
    label: "Cycle Crank",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lowWaterTemp: {
    fieldName: "lowWaterTemp",
    label: "Low Water Temperature",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preAlarm: {
    fieldName: "preAlarm",
    label: "Pre-Alarm",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  remoteAnnunciator: {
    fieldName: "remoteAnnunciator",
    label: "Remote Annunciator Tested",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exhaustSystemLabel: {
    fieldName: "exhaustSystemLabel",
    label: "Exhaust System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exhaustInspection: {
    fieldName: "exhaustInspection",
    label: "Overall Exhaust System Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exhaustDrainLine: {
    fieldName: "exhaustDrainLine",
    label: "Exhaust Drain Line Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelSystemLabel: {
    fieldName: "fuelSystemLabel",
    label: "Fuel System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelTankWater: {
    fieldName: "fuelTankWater",
    label: "Fuel Tank Inspected and Checked for Water",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transferPump: {
    fieldName: "transferPump",
    label: "Transfer Pump Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelLineLeaks: {
    fieldName: "fuelLineLeaks",
    label: "Fuel Lines and Connections Checked for Leaks",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolingSystemLabel: {
    fieldName: "coolingSystemLabel",
    label: "Cooling System",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantLevel: {
    fieldName: "coolantLevel",
    label: "Coolant Level Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  antifreeze: {
    fieldName: "antifreeze",
    label: "Antifreeze Protection Level Checked",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantAdditive: {
    fieldName: "coolantAdditive",
    label: "Coolant additive for diesel engines (test) and (DCA) the above",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantHoses: {
    fieldName: "coolantHoses",
    label: "Hoses and Connections inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  jacketWaterHeater: {
    fieldName: "jacketWaterHeater",
    label: "Jacket Water Heater Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  radiator: {
    fieldName: "radiator",
    label: "Radiator and Radiator Cap Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantWaterLeak: {
    fieldName: "coolantWaterLeak",
    label: "Water Leakage Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolantBelts: {
    fieldName: "coolantBelts",
    label: "Belts and Tensioners Inspected / Adjusted",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  generatorsLabel: {
    fieldName: "generatorsLabel",
    label: "Generators",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilHousingDoorsAndHardware: {
    fieldName: "oilHousingDoorsAndHardware",
    label: "Oil Housing Doors and Hardware Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lubricationLabel: {
    fieldName: "lubricationLabel",
    label: "Lubrication",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilLevel: {
    fieldName: "oilLevel",
    label: "Oil Level Checked / Changed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilLeakage: {
    fieldName: "oilLeakage",
    label: "Oil Leakage Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lubeOilHeater: {
    fieldName: "lubeOilHeater",
    label: "Lube Oil Heater Inspected",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  primeMoverLabel: {
    fieldName: "primeMoverLabel",
    label: "Prime Mover",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  primeMoverInspection: {
    fieldName: "primeMoverInspection",
    label: "Prime Mover General Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dieselEngineLabel: {
    fieldName: "dieselEngineLabel",
    label: "Diesel Engine Checklist",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dieselEngineOperational: {
    fieldName: "dieselEngineOperational",
    label: "Diesel Engine Operational Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sparkIgnitedChecklist: {
    fieldName: "sparkIgnitedChecklist",
    label: "Spark Ignited Engine Checklist",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sparkOperational: {
    fieldName: "sparkOperational",
    label: "Engine Operational Inspection Performed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  testingLabel: {
    fieldName: "testingLabel",
    label: "Testing",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  testRunUnitsLoad: {
    fieldName: "testRunUnitsLoad",
    label: "Test Run Units - Actual Load Tested (if authorized)",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  voltageAndFrequency: {
    fieldName: "voltageAndFrequency",
    label: "Voltage and Frequency Checked / Adjusted",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  recordValues: {
    fieldName: "recordValues",
    label: "Record Values",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineReadingsLabel: {
    fieldName: "engineReadingsLabel",
    label: "Engine Readings",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oilPressureReading: {
    fieldName: "oilPressureReading",
    label: "Oil Pressure Reading",
    inputType: "text",
    placeholder: "Oil Pressure Reading",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  waterTemperatureReading: {
    fieldName: "waterTemperatureReading",
    label: "Water Temperature Reading",
    inputType: "text",
    placeholder: "Water Temperature Reading",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  engineAlternatorVoltage: {
    fieldName: "engineAlternatorVoltage",
    label: "Engine Alternator Voltage",
    inputType: "text",
    placeholder: "Engine Alternator Voltage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  generatorReadingLabel: {
    fieldName: "generatorReadingLabel",
    label: "Generator Readings",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  voltageNoLoad: {
    fieldName: "voltageNoLoad",
    label: "Voltage (No Load)",
    inputType: "text",
    placeholder: "Voltage (No Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  voltageLoad: {
    fieldName: "voltageLoad",
    label: "Voltage (With Load)",
    inputType: "text",
    placeholder: "Voltage (With Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  frequencyNoLoad: {
    fieldName: "frequencyNoLoad",
    label: "Frequency (No Load)",
    inputType: "text",
    placeholder: "Frequency (No Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  frequencyLoad: {
    fieldName: "frequencyLoad",
    label: "Frequency (With Load)",
    inputType: "text",
    placeholder: "Frequency (With Load)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayEngineStart: {
    fieldName: "timeDelayEngineStart",
    label: "Time Delay Engine Start",
    inputType: "text",
    placeholder: "Time Delay Engine Start",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayNormalToEmergency: {
    fieldName: "timeDelayNormalToEmergency",
    label: "Time Delay Normal to Emergency",
    inputType: "text",
    placeholder: "Time Delay Normal to Emergency",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayEmergencyToNormal: {
    fieldName: "timeDelayEmergencyToNormal",
    label: "Time Delay Emergency to Normal",
    inputType: "text",
    placeholder: "Time Delay Emergency to Normal",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  timeDelayCoolDown: {
    fieldName: "timeDelayCoolDown",
    label: "Time Delay Cool Down",
    inputType: "text",
    placeholder: "Time Delay Cool Down",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  exerciseClockDay: {
    fieldName: "exerciseClockDay",
    label: "Exercise Clock Day",
    inputType: "select",
    placeholder: "Select One",
    defaultValue: "",
    options: [
      { value: "Monday", label: "Monday" },
      { value: "Tuesday", label: "Tuesday" },
      { value: "Wednesday", label: "Wednesday" },
      { value: "Thursday", label: "Thursday" },
      { value: "Friday", label: "Friday" },
      { value: "Saturday", label: "Saturday" },
      { value: "Sunday", label: "Sunday" },
    ],
    config: {
      required: false,
    },
  },
  exerciseClockTime: {
    fieldName: "exerciseClockTime",
    label: "Exercise Clock Time",
    inputType: "text",
    placeholder: "Exercise Clock Time",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  restoreToAutomaticLabel: {
    fieldName: "restoreToAutomaticLabel",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  restoreToAutomatic: {
    fieldName: "restoreToAutomatic",
    label: "Restore System to Automatic",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preNoteDivider: {
    fieldName: "preNotesDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  notes: {
    fieldName: "notes",
    label: "notes",
    inputType: "textarea-field",
    placeholder: "Remarks, Findings, Recommendations",
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const loadbankTestLog = {
  loadTestsLabel: {
    fieldName: "loadTestsLabel",
    label: "Load Tests",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests0Time: {
    fieldName: "loadTests[0].time",
    label: "Time",
    inputType: "text",
    placeholder: "Time when the readings were taken",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests0ampsL1: {
    fieldName: "loadTests[0].ampsL1",
    label: "Amps L1",
    inputType: "text",
    placeholder: "Amps L1",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests0ampsL2: {
    fieldName: "loadTests[0].ampsL2",
    label: "Amps L2",
    inputType: "text",
    placeholder: "Amps L2",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests0ampsL3: {
    fieldName: "loadTests[0].ampsL3",
    label: "Amps L3",
    inputType: "text",
    placeholder: "Amps L3",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests0volts: {
    fieldName: "loadTests[0].volts",
    label: "Volts",
    inputType: "text",
    placeholder: "Volts",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests0kW: {
    fieldName: "loadTests[0].kW",
    label: "kW",
    inputType: "text",
    placeholder: "kW",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests0pf: {
    fieldName: "loadTests[0].pf",
    label: "pf",
    inputType: "text",
    placeholder: "pf",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests0oilPsi: {
    fieldName: "loadTests[0].oilPsi",
    label: "oilPsi",
    inputType: "text",
    placeholder: "oilPsi",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests0h2oTemp: {
    fieldName: "loadTests[0].h2oTemp",
    label: "H20 Temperature",
    inputType: "text",
    placeholder: "H2O Temperature",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests0hZ: {
    fieldName: "loadTests[0].hZ",
    label: "hZ",
    inputType: "text",
    placeholder: "hZ",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests0ambAirTemp: {
    fieldName: "loadTests[0].ambAirTemp",
    label: "Ambient Air Temperature",
    inputType: "text",
    placeholder: "Ambient Air Temperature",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests0percentOfLoad: {
    fieldName: "loadTests[0].percentOfLoad",
    label: "% of Load",
    inputType: "text",
    placeholder: "% of Load",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests1Time: {
    fieldName: "loadTests[1].time",
    label: "Time",
    inputType: "text",
    placeholder: "Time when the readings were taken",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests1ampsL1: {
    fieldName: "loadTests[1].ampsL1",
    label: "Amps L1",
    inputType: "text",
    placeholder: "Amps L1",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests1ampsL2: {
    fieldName: "loadTests[1].ampsL2",
    label: "Amps L2",
    inputType: "text",
    placeholder: "Amps L2",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests1ampsL3: {
    fieldName: "loadTests[1].ampsL3",
    label: "Amps L3",
    inputType: "text",
    placeholder: "Amps L3",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests1volts: {
    fieldName: "loadTests[1].volts",
    label: "Volts",
    inputType: "text",
    placeholder: "Volts",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests1kW: {
    fieldName: "loadTests[1].kW",
    label: "kW",
    inputType: "text",
    placeholder: "kW",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests1pf: {
    fieldName: "loadTests[1].pf",
    label: "pf",
    inputType: "text",
    placeholder: "pf",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests1oilPsi: {
    fieldName: "loadTests[1].oilPsi",
    label: "oilPsi",
    inputType: "text",
    placeholder: "oilPsi",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests1h2oTemp: {
    fieldName: "loadTests[1].h2oTemp",
    label: "H20 Temperature",
    inputType: "text",
    placeholder: "H2O Temperature",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests1hZ: {
    fieldName: "loadTests[1].hZ",
    label: "hZ",
    inputType: "text",
    placeholder: "hZ",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests1ambAirTemp: {
    fieldName: "loadTests[1].ambAirTemp",
    label: "Ambient Air Temperature",
    inputType: "text",
    placeholder: "Ambient Air Temperature",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadTests1percentOfLoad: {
    fieldName: "loadTests[1].percentOfLoad",
    label: "% of Load",
    inputType: "text",
    placeholder: "% of Load",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const kmTankTrailerInspection = {
  trailerInfoLbl: {
    fieldName: "trailerInfoLbl",
    label: "General Information",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  ownerOperator: {
    fieldName: "ownerOperator",
    label: "Vehicle Owner/Operator",
    inputType: "text",
    placeholder: "Vehicle Owner/Operator",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  mailingAddress: {
    fieldName: "mailingAddress",
    label: "Mailing Address",
    inputType: "text",
    placeholder: "Mailing Address",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  eqNumber: {
    fieldName: "eqNumber",
    label: "Unit/Equipment Number",
    inputType: "text",
    placeholder: "Unit/Equipment Number",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  mfr: {
    fieldName: "mfr",
    label: "Manufacturer",
    inputType: "text",
    placeholder: "Manufacturer",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vin: {
    fieldName: "vin",
    label: "VIN",
    inputType: "text",
    placeholder: "VIN",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  specNo: {
    fieldName: "specNo",
    label: "MC/DOT Spec #",
    inputType: "text",
    placeholder: "MC/DOT Spec #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  overfillBrandModel: {
    fieldName: "overfillBrandModel",
    label: "Brand / Model of the Overfill System",
    inputType: "text",
    placeholder: "Brand / Model of the Overfill System",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  compartmentCapacityLabel: {
    fieldName: "compartmentCapacityLabel",
    label: "Compartment Capacity",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity1: {
    fieldName: "capacity1",
    label: "Capacity of compartment #1",
    inputType: "text",
    placeholder: "Capacity of compartment #1",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity2: {
    fieldName: "capacity2",
    label: "Capacity of compartment #2",
    inputType: "text",
    placeholder: "Capacity of compartment #2",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity3: {
    fieldName: "capacity3",
    label: "Capacity of compartment #3",
    inputType: "text",
    placeholder: "Capacity of compartment #3",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity4: {
    fieldName: "capacity4",
    label: "Capacity of compartment #4",
    inputType: "text",
    placeholder: "Capacity of compartment #4",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity5: {
    fieldName: "capacity5",
    label: "Capacity of compartment #5",
    inputType: "text",
    placeholder: "Capacity of compartment #5",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity6: {
    fieldName: "capacity6",
    label: "Capacity of compartment #6",
    inputType: "text",
    placeholder: "Capacity of compartment #6",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  maxAllowableCapacityLbl: {
    fieldName: "maxAllowableCapacityLbl",
    label: "Maximum Allowable Capacity",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sixty1: {
    fieldName: "sixty1",
    label: "Capacity - 60 Gallons | Compartment #1",
    inputType: "text",
    placeholder: "Capacity - 60 Gallons | Compartment #1",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sixty2: {
    fieldName: "sixty2",
    label: "Capacity - 60 Gallons | Compartment #2",
    inputType: "text",
    placeholder: "Capacity - 60 Gallons | Compartment #2",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sixty3: {
    fieldName: "sixty3",
    label: "Capacity - 60 Gallons | Compartment #3",
    inputType: "text",
    placeholder: "Capacity - 60 Gallons | Compartment #3",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sixty4: {
    fieldName: "sixty4",
    label: "Capacity - 60 Gallons | Compartment #4",
    inputType: "text",
    placeholder: "Capacity - 60 Gallons | Compartment #4",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sixty5: {
    fieldName: "sixty5",
    label: "Capacity - 60 Gallons | Compartment #5",
    inputType: "text",
    placeholder: "Capacity - 60 Gallons | Compartment #5",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  sixty6: {
    fieldName: "sixty6",
    label: "Capacity - 60 Gallons | Compartment #6",
    inputType: "text",
    placeholder: "Capacity - 60 Gallons | Compartment #6",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  probesTestedLbl: {
    fieldName: "probesTestedLbl",
    label: "Probes Tested",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  outage1: {
    fieldName: "outage1",
    label: "Compartment #1 Probe Allows 60 Gallon Outage?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  outage2: {
    fieldName: "outage2",
    label: "Compartment #2 Probe Allows 60 Gallon Outage?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  outage3: {
    fieldName: "outage3",
    label: "Compartment #3 Probe Allows 60 Gallon Outage?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  outage4: {
    fieldName: "outage4",
    label: "Compartment #4 Probe Allows 60 Gallon Outage?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  outage5: {
    fieldName: "outage5",
    label: "Compartment #5 Probe Allows 60 Gallon Outage?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  outage6: {
    fieldName: "outage6",
    label: "Compartment #6 Probe Allows 60 Gallon Outage?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  probeWetTest: {
    fieldName: "probeWetTest",
    label: "Probe Wet Test",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wetTest1: {
    fieldName: "wetTest1",
    label: "Compartment #1 Probe Passed Wet Test?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wetTest2: {
    fieldName: "wetTest2",
    label: "Compartment #2 Probe Passed Wet Test?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wetTest3: {
    fieldName: "wetTest3",
    label: "Compartment #3 Probe Passed Wet Test?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wetTest4: {
    fieldName: "wetTest4",
    label: "Compartment #4 Probe Passed Wet Test?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wetTest5: {
    fieldName: "wetTest5",
    label: "Compartment #5 Probe Passed Wet Test?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wetTest6: {
    fieldName: "wetTest6",
    label: "Compartment #6 Probe Passed Wet Test?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  splitDivider: {
    fieldName: "splitDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  bypassTested: {
    fieldName: "bypassTested",
    label: "Trailer inspected for bypass system and dummy probes?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  passedBypass: {
    fieldName: "passedBypass",
    label: "Trailer passed bypass system and dummy probes inspection?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vaporHose: {
    fieldName: "vaporHose",
    label: "Loading connection and vapor hose brake interlocks tested OK?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },

  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const level1ServiceChecklist = {
  generalLbl: {
    fieldName: "generalLbl",
    label: "General Information",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  truckNo: {
    fieldName: "truckNo",
    label: "Truck / Trailer Number",
    inputType: "text",
    placeholder: "Truck / Trailer Number",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  miles: {
    fieldName: "miles",
    label: "Miles",
    inputType: "text",
    placeholder: "Miles",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serviceLbl: {
    fieldName: "serviceLbl",
    label: "Services Items & Inspection",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  changeOil: {
    fieldName: "changeOil",
    label: "Change engine oil and filter(s)",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  fuelFilter: {
    fieldName: "fuelFilter",
    label: "Replace fuel and water filter(s)",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  greaseFittings: {
    fieldName: "greaseFittings",
    label: "Grease all fittings and 5th wheel",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  inspectBelts: {
    fieldName: "inspectBelts",
    label: "Inspect belts and hoses for visual wear",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wheelBearings: {
    fieldName: "wheelBearings",
    label: "Check wheel bearings and oil levels",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  transmissionLeaks: {
    fieldName: "transmissionLeaks",
    label: "Check for transmission leaks",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  rearDiff: {
    fieldName: "rearDiff",
    label: "Check rear differential oil and check for leaks",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  coolant: {
    fieldName: "coolant",
    label: "Check coolant levels and adjust if needed",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  brakes: {
    fieldName: "brakes",
    label: "Visual inspection of brakes",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wipers: {
    fieldName: "wipers",
    label: "Check wiper blades and fill washer fluid",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lights: {
    fieldName: "lights",
    label: "Check all lights",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  airLines: {
    fieldName: "airLines",
    label: "Check air lines and glad hands for leaks",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  remarksLbl: {
    fieldName: "remarksLbl",
    label: "Notes / Remarks",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  notes: {
    fieldName: "notes",
    label: "Notes",
    inputType: "textarea",
    placeholder: "Notes",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const citgoInspectionForm = {
  generalLbl: {
    fieldName: "generalLbl",
    label: "General Information",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  carrier: {
    fieldName: "carrier",
    label: "Carrier Name",
    inputType: "text",
    placeholder: "Carrier Name",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  trailerNo: {
    fieldName: "trailerNo",
    label: "Trailer Unit Number",
    inputType: "text",
    placeholder: "Trailer Unit Number",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  trailerLbl: {
    fieldName: "trailerLbl",
    label: "Trailer Information",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  make: {
    fieldName: "make",
    label: "Make",
    inputType: "text",
    placeholder: "Make",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  year: {
    fieldName: "year",
    label: "Year",
    inputType: "text",
    placeholder: "Year",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dotType: {
    fieldName: "dotType",
    label: "DOT Type",
    inputType: "text",
    placeholder: "DOT Type",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serial: {
    fieldName: "serial",
    label: "Serial Number",
    inputType: "text",
    placeholder: "Serial Number",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  retainSensorsInstalled: {
    fieldName: "retainSensorsInstalled",
    label: "Retain Sensors Installed",
    inputType: "switch",
    placeholder: "Retain Sensors Installed",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  postTrailerDivider: {
    fieldName: "postTrailerDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity1: {
    fieldName: "capacity1",
    label: "Compartment #1 Maximum Capacity",
    inputType: "text",
    placeholder: "Compartment #1 Maximum Capacity",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity2: {
    fieldName: "capacity2",
    label: "Compartment #2 Maximum Capacity",
    inputType: "text",
    placeholder: "Compartment #2 Maximum Capacity",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity3: {
    fieldName: "capacity3",
    label: "Compartment #3 Maximum Capacity",
    inputType: "text",
    placeholder: "Compartment #3 Maximum Capacity",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity4: {
    fieldName: "capacity4",
    label: "Compartment #4 Maximum Capacity",
    inputType: "text",
    placeholder: "Compartment #4 Maximum Capacity",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity5: {
    fieldName: "capacity5",
    label: "Compartment #5 Maximum Capacity",
    inputType: "text",
    placeholder: "Compartment #5 Maximum Capacity",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity6: {
    fieldName: "capacity6",
    label: "Compartment #6 Maximum Capacity",
    inputType: "text",
    placeholder: "Compartment #6 Maximum Capacity",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  outage1: {
    fieldName: "outage1",
    label: "Compartment #1 Probe Outage (60 gal min)",
    inputType: "text",
    placeholder: "Compartment #1 Probe Outage (60 gal min)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  outage2: {
    fieldName: "outage2",
    label: "Compartment #2 Probe Outage (60 gal min)",
    inputType: "text",
    placeholder: "Compartment #2 Probe Outage (60 gal min)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  outage3: {
    fieldName: "outage3",
    label: "Compartment #3 Probe Outage (60 gal min)",
    inputType: "text",
    placeholder: "Compartment #3 Probe Outage (60 gal min)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  outage4: {
    fieldName: "outage4",
    label: "Compartment #4 Probe Outage (60 gal min)",
    inputType: "text",
    placeholder: "Compartment #4 Probe Outage (60 gal min)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  outage5: {
    fieldName: "outage5",
    label: "Compartment #5 Probe Outage (60 gal min)",
    inputType: "text",
    placeholder: "Compartment #5 Probe Outage (60 gal min)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  outage6: {
    fieldName: "outage6",
    label: "Compartment #6 Probe Outage (60 gal min)",
    inputType: "text",
    placeholder: "Compartment #6 Probe Outage (60 gal min)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  carrierOutage1: {
    fieldName: "carrierOutage1",
    label: "Compartment #1 Carrier Outage",
    inputType: "text",
    placeholder: "Compartment #1 Carrier Outage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  carrierOutage2: {
    fieldName: "carrierOutage2",
    label: "Compartment #2 Carrier Outage",
    inputType: "text",
    placeholder: "Compartment #2 Carrier Outage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  carrierOutage3: {
    fieldName: "carrierOutage3",
    label: "Compartment #3 Carrier Outage",
    inputType: "text",
    placeholder: "Compartment #3 Carrier Outage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  carrierOutage4: {
    fieldName: "carrierOutage4",
    label: "Compartment #4 Carrier Outage",
    inputType: "text",
    placeholder: "Compartment #4 Carrier Outage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  carrierOutage5: {
    fieldName: "carrierOutage5",
    label: "Compartment #5 Carrier Outage",
    inputType: "text",
    placeholder: "Compartment #5 Carrier Outage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  carrierOutage6: {
    fieldName: "carrierOutage6",
    label: "Compartment #6 Carrier Outage",
    inputType: "text",
    placeholder: "Compartment #6 Carrier Outage",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preset1: {
    fieldName: "preset1",
    label: "Compartment #1 Maximum Preset",
    inputType: "text",
    placeholder: "Compartment #1 Maximum Preset",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preset2: {
    fieldName: "preset2",
    label: "Compartment #2 Maximum Preset",
    inputType: "text",
    placeholder: "Compartment #2 Maximum Preset",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preset3: {
    fieldName: "preset3",
    label: "Compartment #3 Maximum Preset",
    inputType: "text",
    placeholder: "Compartment #3 Maximum Preset",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preset4: {
    fieldName: "preset4",
    label: "Compartment #4 Maximum Preset",
    inputType: "text",
    placeholder: "Compartment #4 Maximum Preset",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preset5: {
    fieldName: "preset5",
    label: "Compartment #5 Maximum Preset",
    inputType: "text",
    placeholder: "Compartment #5 Maximum Preset",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  preset6: {
    fieldName: "preset6",
    label: "Compartment #6 Maximum Preset",
    inputType: "text",
    placeholder: "Compartment #6 Maximum Preset",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  postCapacityDivider: {
    fieldName: "postCapacityDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  tested60Below: {
    fieldName: "tested60Below",
    label: "Has the overfill protection probe been set & tested to a minimum of 60 gross gallons below the maximum compartment capacity?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  overfillProtection: {
    fieldName: "overfillProtection",
    label: "Is the overfill protection system in working condition?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  probesTested: {
    fieldName: "probesTested",
    label: "Has each compartments probe been tested with liquid to verify that it activates the shutdown circuitry?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  groundingChecked: {
    fieldName: "groundingChecked",
    label: "Has the grounding system been checked and is in proper operating condition?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  groundingUnmodified: {
    fieldName: "groundingUnmodified",
    label:
      "Has the grounding system been checked to ensure that has not been modified or rewired in any manner that would allow it to provide a false reading to allow loading?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  gaugeRodsGrounded: {
    fieldName: "gaugeRodsGrounded",
    label: "Are all gauge rods and any other compartment protrusions properly grounded with secure bonding wires?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  brakeInterlockSystem: {
    fieldName: "brakeInterlockSystem",
    label: "Is a functional brake interlock system installed on the loading header and vapor recovery hose?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dotSpecPlateInstalled: {
    fieldName: "dotSpecPlateInstalled",
    label: "Is an MC306, DOT406 or other specification plate installed?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  placecardInstalled: {
    fieldName: "placecardInstalled",
    label: "Is proper placarding installed for the product(s) that are hauled?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dotInspectionCurrent: {
    fieldName: "dotInspectionCurrent",
    label: "Is the state DOT inspection or DOT 396/17 data current?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  decalsCurrent: {
    fieldName: "decalsCurrent",
    label: "Are pressure, leakage and visual decals current?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  emergencyInfoOnboard: {
    fieldName: "emergencyInfoOnboard",
    label: "Is emergency response information (including guidebook) on board?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  appropriateUnitNumber: {
    fieldName: "appropriateUnitNumber",
    label: "Is each tank/trailer marked with appropriate unit numbers?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacityChartsCurrent: {
    fieldName: "capacityChartsCurrent",
    label: "Are compartment capacity charts current and available upon request?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  loadingHeadersMatch: {
    fieldName: "loadingHeadersMatch",
    label: "Is each compartment loading headers matching with maximum presets recorded above?",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const leakageInspectionForm = {
  generalLbl: {
    fieldName: "generalLbl",
    label: "General Information",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  trailer: {
    fieldName: "trailer",
    label: "Trailer #",
    inputType: "text",
    placeholder: "Trailer #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serial: {
    fieldName: "serial",
    label: "Serial #",
    inputType: "text",
    placeholder: "Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dotNo: {
    fieldName: "dotNo",
    label: "DOT / MC #",
    inputType: "text",
    placeholder: "DOT / MC #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  mfg: {
    fieldName: "mfg",
    label: "Manufacturer",
    inputType: "text",
    placeholder: "Manufacturer",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  year: {
    fieldName: "year",
    label: "Year",
    inputType: "text",
    placeholder: "Year",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  material: {
    fieldName: "material",
    label: "Material",
    inputType: "text",
    placeholder: "Material",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity: {
    fieldName: "capacity",
    label: "Capacity",
    inputType: "text",
    placeholder: "Capacity",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  compartments: {
    fieldName: "compartments",
    label: "No. of Compartments",
    inputType: "text",
    placeholder: "No. of Compartments",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  postBasic: {
    fieldName: "postBasic",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  oneIn: {
    fieldName: "oneIn",
    label: "Truck Certified to 1 inch",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  threeIn: {
    fieldName: "threeIn",
    label: "Truck Certified to 3 inch",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  pt1Lbl: {
    fieldName: "pt1Lbl",
    label: "Pressure Test #1",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  pt1Start: {
    fieldName: "pt1Start",
    label: "Start Pressure",
    inputType: "text",
    placeholder: "Start Pressure",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  pt1StartTime: {
    fieldName: "pt1StartTime",
    label: "Time When Starting Pressure was Measured",
    inputType: "text",
    placeholder: "Time When Starting Pressure was Measured",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  pt1Finish: {
    fieldName: "pt1Finish",
    label: "Finish Pressure",
    inputType: "text",
    placeholder: "Finish Pressure",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  pt1FinishTime: {
    fieldName: "pt1FinishTime",
    label: "Time When Finishing Pressure was Measured",
    inputType: "text",
    placeholder: "Time When Finishing Pressure was Measured",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  pt1Change: {
    fieldName: "pt1Change",
    label: "Change",
    inputType: "text",
    placeholder: "Change",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  pt2Lbl: {
    fieldName: "pt2Lbl",
    label: "Pressure Test #2",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  pt2Start: {
    fieldName: "pt2Start",
    label: "Start Pressure",
    inputType: "text",
    placeholder: "Start Pressure",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  pt2StartTime: {
    fieldName: "pt2StartTime",
    label: "Time When Starting Pressure was Measured",
    inputType: "text",
    placeholder: "Time When Starting Pressure was Measured",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  pt2Finish: {
    fieldName: "pt2Finish",
    label: "Finish Pressure",
    inputType: "text",
    placeholder: "Finish Pressure",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  pt2FinishTime: {
    fieldName: "pt2FinishTime",
    label: "Time When Finishing Pressure was Measured",
    inputType: "text",
    placeholder: "Time When Finishing Pressure was Measured",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  pt2Change: {
    fieldName: "pt2Change",
    label: "Change",
    inputType: "text",
    placeholder: "Change",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  ptEndDivider: {
    fieldName: "ptEndDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  ptChange: {
    fieldName: "ptChange",
    label: "Measured Change from Test 1 to Test 2",
    inputType: "text",
    placeholder: "Measured Change from Test 1 to Test 2",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  ptAverage: {
    fieldName: "ptAverage",
    label: "Average of the two tests",
    inputType: "text",
    placeholder: "Average of the two tests",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vt1Lbl: {
    fieldName: "vt1Lbl",
    label: "Vacuum Test #1",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vt1Start: {
    fieldName: "vt1Start",
    label: "Start Pressure",
    inputType: "text",
    placeholder: "Start Pressure",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vt1StartTime: {
    fieldName: "vt1StartTime",
    label: "Time When Starting Pressure was Measured",
    inputType: "text",
    placeholder: "Time When Starting Pressure was Measured",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vt1Finish: {
    fieldName: "vt1Finish",
    label: "Finish Pressure",
    inputType: "text",
    placeholder: "Finish Pressure",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vt1FinishTime: {
    fieldName: "vt1FinishTime",
    label: "Time When Finishing Pressure was Measured",
    inputType: "text",
    placeholder: "Time When Finishing Pressure was Measured",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vt1Change: {
    fieldName: "vt1Change",
    label: "Change",
    inputType: "text",
    placeholder: "Change",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vt2Lbl: {
    fieldName: "vt2Lbl",
    label: "Vacuum Test #2",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vt2Start: {
    fieldName: "vt2Start",
    label: "Start Pressure",
    inputType: "text",
    placeholder: "Start Pressure",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vt2StartTime: {
    fieldName: "vt2StartTime",
    label: "Time When Starting Pressure was Measured",
    inputType: "text",
    placeholder: "Time When Starting Pressure was Measured",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vt2Finish: {
    fieldName: "vt2Finish",
    label: "Finish Pressure",
    inputType: "text",
    placeholder: "Finish Pressure",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vt2FinishTime: {
    fieldName: "vt2FinishTime",
    label: "Time When Finishing Pressure was Measured",
    inputType: "text",
    placeholder: "Time When Finishing Pressure was Measured",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vt2Change: {
    fieldName: "vt2Change",
    label: "Change",
    inputType: "text",
    placeholder: "Change",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vtEndDivider: {
    fieldName: "vtEndDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vtChange: {
    fieldName: "vtChange",
    label: "Measured Change from Test 1 to Test 2",
    inputType: "text",
    placeholder: "Measured Change from Test 1 to Test 2",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vtAverage: {
    fieldName: "vtAverage",
    label: "Average of the two tests",
    inputType: "text",
    placeholder: "Average of the two tests",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vvtEndDivider: {
    fieldName: "vvtEndDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vv: {
    fieldName: "vv",
    label: "Vapor Valves Tested",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  vvResult: {
    fieldName: "vvResult",
    label: "Result of the Vapor Valves Test",
    inputType: "text",
    placeholder: "Result of the Vapor Valves Test",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  vvEndDivider: {
    fieldName: "vvEndDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  probe: {
    fieldName: "probe",
    label: "Probe System Checked and Operational?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  intValves: {
    fieldName: "intValves",
    label: "Internal Valves Tested?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  extValves: {
    fieldName: "extValves",
    label: "External Valves Tested?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  preDefectDivider: {
    fieldName: "preDefectDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  defects: {
    fieldName: "defects",
    label:
      "If trailer fails to hold test pressure or leaks have been detected, note the location of all defects found and the method used to repair each defect",
    inputType: "textarea",
    placeholder: "Defects noted and location",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  postDefectDivider: {
    fieldName: "postDefectDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  tank: {
    fieldName: "tank",
    label: "Tank marking and service symbols K-EPA27 month and year updated?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  postTank: {
    fieldName: "postTank",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dotRegNo: {
    fieldName: "dotRegNo",
    label: "DOT Registration Number of the Inspector",
    inputType: "text",
    placeholder: "DOT Registration Number of the Inspector",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  final: {
    fieldName: "final",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  meetsReq: {
    fieldName: "meetsReq",
    label: "Does the cargo tank meet the requirements of the DOT specification identified in this report?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const visualInspectionForm = {
  generalLbl: {
    fieldName: "generalLbl",
    label: "General Information",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  trailer: {
    fieldName: "trailer",
    label: "Trailer #",
    inputType: "text",
    placeholder: "Trailer #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serial: {
    fieldName: "serial",
    label: "Serial #",
    inputType: "text",
    placeholder: "Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dotNo: {
    fieldName: "dotNo",
    label: "DOT / MC #",
    inputType: "text",
    placeholder: "DOT / MC #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  mfg: {
    fieldName: "mfg",
    label: "Manufacturer",
    inputType: "text",
    placeholder: "Manufacturer",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  year: {
    fieldName: "year",
    label: "Year",
    inputType: "text",
    placeholder: "Year",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  material: {
    fieldName: "material",
    label: "Material",
    inputType: "text",
    placeholder: "Material",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity: {
    fieldName: "capacity",
    label: "Capacity",
    inputType: "text",
    placeholder: "Capacity",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  compartments: {
    fieldName: "compartments",
    label: "No. of Compartments",
    inputType: "text",
    placeholder: "No. of Compartments",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  insulated: {
    fieldName: "insulated",
    label: "Insulated?",
    inputType: "switch",
    placeholder: "Insulated?",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lined: {
    fieldName: "lined",
    label: "Lined?",
    inputType: "switch",
    placeholder: "Lined?",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lining: {
    fieldName: "lining",
    label: "Lining Type",
    inputType: "text",
    placeholder: "Lining Type",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  specialService: {
    fieldName: "specialService",
    label: "Special Service (e.g. Dedicated Service, Corrosive Service, etc.)",
    inputType: "text",
    placeholder: "Special Service",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  tankMawp: {
    fieldName: "tankMawp",
    label: "Tank Maximum Allowable Operating Pressure (MAWP)",
    inputType: "text",
    placeholder: "Tank Maximum Allowable Operating Pressure (MAWP)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  steamPanel: {
    fieldName: "steamPanel",
    label: "Steam Panel Design Pressure",
    inputType: "text",
    placeholder: "Steam Panel Design Pressure",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  inspectionDividerTop: {
    fieldName: "inspectionDividerTop",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  inspectionLabel: {
    fieldName: "inspectionLabel",
    label: "Internal Visual Inspection",
    inputType: "label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  checklistSection1: {
    fieldName: "checklistSection1",
    label: "Section 1 Tank Shell / Heads",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  s1Corroded: {
    fieldName: "s1Corroded",
    label: "Are there any Corroded or Abraded Areas?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Dents: {
    fieldName: "s1Dents",
    label: "Are there any Dents?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Distortions: {
    fieldName: "s1Distortions",
    label: "Are there any Distortions?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Welds: {
    fieldName: "s1Welds",
    label: "Are there any Defects in Welds?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Leaks: {
    fieldName: "s1Leaks",
    label: "Are there any Leaks?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Baffles: {
    fieldName: "s1Baffles",
    label: "Are there any Defects in Baffles?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Valves: {
    fieldName: "s1Valves",
    label: "Are there any Defects in Interior Emergency Valves?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Lining: {
    fieldName: "s1Lining",
    label: "Are there any Defects in Lining?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Tube: {
    fieldName: "s1Tube",
    label: "Are there any Defects in Dip Tube?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Cleanout: {
    fieldName: "s1Cleanout",
    label: "Are there any Defects in Cleanout Ports / Nozzles?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  checklistSection2: {
    fieldName: "checklistSection2",
    label: "Section 2 Tank Shell Areas Covered by the Upper Skid Plate",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  s2Corroded: {
    fieldName: "s2Corroded",
    label: "Are there any Corroded or Abraded Areas?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Dents: {
    fieldName: "s2Dents",
    label: "Are there any Dents?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Distortions: {
    fieldName: "s2Distortions",
    label: "Are there any Distortions?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Welds: {
    fieldName: "s2Welds",
    label: "Are there any Defects in Welds?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Leaks: {
    fieldName: "s2Leaks",
    label: "Are there any Leaks?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Unsafe: {
    fieldName: "s2Unsafe",
    label: "Are there any conditions that might render tank unsafe for transportation service?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Marking: {
    fieldName: "s2Marking",
    label: "Did you update the markings on the tank?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  concludeDivider: {
    fieldName: "concludeDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  defects: {
    fieldName: "defects",
    label: "On all 'Yes' responses note the location of all defects found and the method used to repair each defect",
    inputType: "textarea",
    placeholder: "Defects noted and their location",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dotRegNo: {
    fieldName: "dotRegNo",
    label: "Inspector's DOT Registration Number",
    inputType: "text",
    placeholder: "Inspector's DOT Registration Number",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  finalDivider: {
    fieldName: "finalDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  meetsReq: {
    fieldName: "meetsReq",
    label: "Does the cargo tank meet the requirements of the DOT specification identified on this report?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const wetTestCertificationForm = {
  generalLbl: {
    fieldName: "generalLbl",
    label: "General Information",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  address: {
    fieldName: "address",
    label: "Address",
    inputType: "text",
    placeholder: "Address",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  trailer: {
    fieldName: "trailer",
    label: "Trailer #",
    inputType: "text",
    placeholder: "Trailer #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  inspectionDividerTop: {
    fieldName: "inspectionDividerTop",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  inspectionLabel: {
    fieldName: "inspectionLabel",
    label:
      "This document certifies the above trailer has completed and passed the annual wet test requirement for overfill protection probes. This certifies the whole operation of the truck overfill protection system is correctly wired and the entire system is working properly. The trailer meets the requirement for the overfill probes to be set where the maximum safe fill is 60 gallons less than the manufacturer's specified compartment capacity.",
    inputType: "label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  checklistSection1: {
    fieldName: "checklistSection1",
    label: "Probes tested and set properly",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  compartment1: {
    fieldName: "compartment1",
    label: "Compartment #1",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  compartment2: {
    fieldName: "compartment2",
    label: "Compartment #2",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  compartment3: {
    fieldName: "compartment3",
    label: "Compartment #3",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  compartment4: {
    fieldName: "compartment4",
    label: "Compartment #4",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  compartment5: {
    fieldName: "compartment5",
    label: "Compartment #5",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  compartment6: {
    fieldName: "compartment6",
    label: "Compartment #6",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const externalVisualInspectionForm = {
  generalLbl: {
    fieldName: "generalLbl",
    label: "General Information",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  trailer: {
    fieldName: "trailer",
    label: "Trailer #",
    inputType: "text",
    placeholder: "Trailer #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  serial: {
    fieldName: "serial",
    label: "Serial #",
    inputType: "text",
    placeholder: "Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  dotNo: {
    fieldName: "dotNo",
    label: "DOT / MC #",
    inputType: "text",
    placeholder: "DOT / MC #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  mfg: {
    fieldName: "mfg",
    label: "Manufacturer",
    inputType: "text",
    placeholder: "Manufacturer",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  year: {
    fieldName: "year",
    label: "Year",
    inputType: "text",
    placeholder: "Year",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  material: {
    fieldName: "material",
    label: "Material",
    inputType: "text",
    placeholder: "Material",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacity: {
    fieldName: "capacity",
    label: "Capacity",
    inputType: "text",
    placeholder: "Capacity",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  compartments: {
    fieldName: "compartments",
    label: "No. of Compartments",
    inputType: "text",
    placeholder: "No. of Compartments",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  insulated: {
    fieldName: "insulated",
    label: "Insulated?",
    inputType: "switch",
    placeholder: "Insulated?",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lined: {
    fieldName: "lined",
    label: "Lined?",
    inputType: "switch",
    placeholder: "Lined?",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  lining: {
    fieldName: "lining",
    label: "Lining Type",
    inputType: "text",
    placeholder: "Lining Type",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  specialService: {
    fieldName: "specialService",
    label: "Special Service (e.g. Dedicated Service, Corrosive Service, etc.)",
    inputType: "text",
    placeholder: "Special Service",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  tankMawp: {
    fieldName: "tankMawp",
    label: "Tank Maximum Allowable Operating Pressure (MAWP)",
    inputType: "text",
    placeholder: "Tank Maximum Allowable Operating Pressure (MAWP)",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  steamPanel: {
    fieldName: "steamPanel",
    label: "Steam Panel Design Pressure",
    inputType: "text",
    placeholder: "Steam Panel Design Pressure",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  inspectionDividerTop: {
    fieldName: "inspectionDividerTop",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  inspectionLabel: {
    fieldName: "inspectionLabel",
    label: "External Visual Inspection",
    inputType: "label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  checklistSection1: {
    fieldName: "checklistSection1",
    label: "Section 1 - Tank Shell / Heads",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  s1Corroded: {
    fieldName: "s1Corroded",
    label: "Are there any Corroded or Abraded Areas?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Dents: {
    fieldName: "s1Dents",
    label: "Are there any Dents?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Distortions: {
    fieldName: "s1Distortions",
    label: "Are there any Distortions?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Welds: {
    fieldName: "s1Welds",
    label: "Are there any Defects in Welds?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Leaks: {
    fieldName: "s1Leaks",
    label: "Are there any Leaks?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s1Unsafe: {
    fieldName: "s1Unsafe",
    label: "Are there any conditions that might render the tank unsafe for transportation service?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  checklistSection2: {
    fieldName: "checklistSection2",
    label: "Section 2 - External Piping / Valves",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  s2Corroded: {
    fieldName: "s2Corroded",
    label: "Are there any Corroded or Abraded Areas?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Welds: {
    fieldName: "s2Welds",
    label: "Are there any Defects in Welds?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Valves: {
    fieldName: "s2Valves",
    label: "Are there any Defects in Valves or Gaskets?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Manifold: {
    fieldName: "s2Manifold",
    label: "Are there any Defects in Manifold?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Dust: {
    fieldName: "s2Dust",
    label: "Are there any Defects in Dust Cap?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Safety: {
    fieldName: "s2Safety",
    label: "Are there any Defects in Safety Valve Operator?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Remote: {
    fieldName: "s2Remote",
    label: "Are there any Defects in Remote Control?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Fusible: {
    fieldName: "s2Fusible",
    label: "Are there any Defects in Fusible Link / Plug?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Pump: {
    fieldName: "s2Pump",
    label: "Are there any Defects in Pump off line?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Leaks: {
    fieldName: "s2Leaks",
    label: "Are there any Leaks?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s2Unsafe: {
    fieldName: "s2Unsafe",
    label: "Are there any conditions that might render tank unsafe for transportation service?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  checklistSection3: {
    fieldName: "checklistSection3",
    label: "Section 3 - Spill Collar / Manhole",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  s3Manhole: {
    fieldName: "s3Manhole",
    label: "Manhole cover hold-downs stripped inoperative?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3Dome: {
    fieldName: "s3Dome",
    label: "Are there any defects in the dome cover?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3Gaskets: {
    fieldName: "s3Gaskets",
    label: "Are there any defects in the gaskets?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3Vacuum: {
    fieldName: "s3Vacuum",
    label: "Are there any defects in the vacuum vent?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3Valves: {
    fieldName: "s3Valves",
    label: "Are there any defects in the popoff valves?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3Rupture: {
    fieldName: "s3Rupture",
    label: "Are there any defects in the rupture disc?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3APGauge: {
    fieldName: "s3APGauge",
    label: "Are there any defects in the air pressure gauge?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3APHookup: {
    fieldName: "s3APHookup",
    label: "Are there any defects in the air pressure hook up?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3Fusible: {
    fieldName: "s3Fusible",
    label: "Are there any defects in the fusible disc / cap?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3AirIn: {
    fieldName: "s3AirIn",
    label: "Are there any defects in the air inlet valve?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3AirBleed: {
    fieldName: "s3AirBleed",
    label: "Are there any defects in the air bleeder valve?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3Cleanout: {
    fieldName: "s3Cleanout",
    label: "Are there any defects in the cleanout ports or gaskets?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3Drains: {
    fieldName: "s3Drains",
    label: "Are the spill collar drains clogged?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3Spill: {
    fieldName: "s3Spill",
    label: "Are there any defects in the spill collar?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s3Leaks: {
    fieldName: "s3Leaks",
    label: "Are there any leaks?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  checklistSection4: {
    fieldName: "checklistSection4",
    label: "Section 4 - Emergency Devices",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  s4Corroded: {
    fieldName: "s4Corroded",
    label: "Are there any corroded or abraded areas?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s4Distortions: {
    fieldName: "s4Distortions",
    label: "Are there any distortions?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s4Erosion: {
    fieldName: "s4Erosion",
    label: "Are there any indications of erosion?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s4Damage: {
    fieldName: "s4Damage",
    label: "Is there any external damage that will prevent safe operation?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s4NotFunctioning: {
    fieldName: "s4NotFunctioning",
    label: "Are there any devices that are not functioning properly?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  checklistSection5: {
    fieldName: "checklistSection5",
    label: "Section 5 - Bolts / Flanges",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  s5Missing: {
    fieldName: "s5Missing",
    label: "Are there any bolts, nuts, or fusible links missing?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s5Loose: {
    fieldName: "s5Loose",
    label: "Are there any bolts, nuts, or fusible links loose?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  checklistSection6: {
    fieldName: "checklistSection6",
    label: "Section 6 - General Vehicle Condition",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  s6Lights: {
    fieldName: "s6Lights",
    label: "Are there any lights that are not in good / working condition?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6UBolts: {
    fieldName: "s6UBolts",
    label: "Are there any U Bolts that are not in good condition?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6Ladder: {
    fieldName: "s6Ladder",
    label: "Are there any defects on the ladder?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6Frame: {
    fieldName: "s6Frame",
    label: "Are there any defects on the frame?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s65thWheel: {
    fieldName: "s65thWheel",
    label: "Are there any defects on the 5th wheel plate and pin?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6Landing: {
    fieldName: "s6Landing",
    label: "Are there any defects on the landing gear and legs?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6Springs: {
    fieldName: "s6Springs",
    label: "Are there any defects on the sprigs or air bags?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6Brakes: {
    fieldName: "s6Brakes",
    label: "Are there any defects on the brakes?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6AirLines: {
    fieldName: "s6AirLines",
    label: "Are there any defects on the air lines?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6Tires: {
    fieldName: "s6Tires",
    label: "Are there any defects on the tires?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6Cargo: {
    fieldName: "s6Cargo",
    label: "Are any required markings on the cargo unit illegible?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6Spec: {
    fieldName: "s6Spec",
    label: "Are any required markings on the spec plate illegible?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6PMSticker: {
    fieldName: "s6PMSticker",
    label: "Does the tank have an expired PM sticker?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s65thWheelDamage: {
    fieldName: "s65thWheelDamage",
    label:
      "Does the 5th wheel assembly, the suspension system attachments and connecting structure show signs of corrosion or damage which might prevent safe operation?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6AttachmentDamage: {
    fieldName: "s6AttachmentDamage",
    label: "Are any major accessories or attachments showing signs of corrosion or damage which might prevent safe operation?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6Jacket: {
    fieldName: "s6Jacket",
    label: "Is the jacket damaged?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  s6Marking: {
    fieldName: "s6Marking",
    label: "Did you update the markings on the tank?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  checklistSection7: {
    fieldName: "checklistSection7",
    label: "Section 7 - Upper Coupler",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  s7Coupler: {
    fieldName: "s7Coupler",
    label: "Was the coupler dropped and inspected?",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  concludeDivider: {
    fieldName: "concludeDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  defects: {
    fieldName: "defects",
    label: "On all 'Yes' responses note the location of all defects found and the method used to repair each defect",
    inputType: "textarea",
    placeholder: "Defects noted and their location",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  finalDivider: {
    fieldName: "finalDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  notes: {
    fieldName: "notes",
    label: "Notes",
    inputType: "textarea",
    placeholder: "Notes",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const buckeyeTrailerInspection = {
  trailerInfoLbl: {
    fieldName: "trailerInfoLbl",
    label: "Trailer Information",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  carrierName: {
    fieldName: "carrierName",
    label: "Carrier Name",
    inputType: "text",
    placeholder: "Carrier Name",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  trailerNo: {
    fieldName: "trailerNo",
    label: "Trailer #",
    inputType: "text",
    placeholder: "Trailer #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  trailerSerialNo: {
    fieldName: "trailerSerialNo",
    label: "Trailer Serial #",
    inputType: "text",
    placeholder: "Trailer Serial #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  certificationDate: {
    fieldName: "certificationDate",
    label: "Certification Date",
    inputType: "date-picker",
    placeholder: "Certification Date",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  capacityLbl: {
    fieldName: "capacityLbl",
    label: "Calculate Working Volume (Max volume minus 60 gallons ullage) for each compartment below",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  maxCapacityLbl: {
    fieldName: "maxCapacityLbl",
    label: "Max Capacity",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  mc1: {
    fieldName: "maxCapacity.one",
    label: "Max Capacity #1",
    inputType: "text",
    placeholder: "Max Capacity #1",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  mc2: {
    fieldName: "maxCapacity.two",
    label: "Max Capacity #2",
    inputType: "text",
    placeholder: "Max Capacity #2",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  mc3: {
    fieldName: "maxCapacity.three",
    label: "Max Capacity #3",
    inputType: "text",
    placeholder: "Max Capacity #3",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  mc4: {
    fieldName: "maxCapacity.four",
    label: "Max Capacity #4",
    inputType: "text",
    placeholder: "Max Capacity #4",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  mc5: {
    fieldName: "maxCapacity.five",
    label: "Max Capacity #5",
    inputType: "text",
    placeholder: "Max Capacity #5",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  workingCapacityLbl: {
    fieldName: "workingCapacityLbl",
    label: "Working Capacity",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wc1: {
    fieldName: "workingCapacity.one",
    label: "Working Capacity #1",
    inputType: "text",
    placeholder: "Working Capacity #1",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wc2: {
    fieldName: "workingCapacity.two",
    label: "Working Capacity #2",
    inputType: "text",
    placeholder: "Working Capacity #2",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wc3: {
    fieldName: "workingCapacity.three",
    label: "Working Capacity #3",
    inputType: "text",
    placeholder: "Working Capacity #3",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wc4: {
    fieldName: "workingCapacity.four",
    label: "Working Capacity #4",
    inputType: "text",
    placeholder: "Working Capacity #4",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  wc5: {
    fieldName: "workingCapacity.five",
    label: "Working Capacity #5",
    inputType: "text",
    placeholder: "Working Capacity #5",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  inspectorDivider: {
    fieldName: "inspectorDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  inspectorsDotRegNo: {
    fieldName: "inspectorsDotRegNo",
    label: "Inspector's DOT Reg. #",
    inputType: "text",
    placeholder: "Inspector's DOT Reg. #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  inspectionDate: {
    fieldName: "inspectionDate",
    label: "Inspection Date",
    inputType: "date-picker",
    placeholder: "Inspection Date",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};

export const magellanTrailerInspection = {
  trailerInfoLbl: {
    fieldName: "trailerInfoLbl",
    label: "Carrier & Trailer Information",
    inputType: "divider-label",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  carrierName: {
    fieldName: "carrierName",
    label: "Carrier Name",
    inputType: "text",
    placeholder: "Carrier Name",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  address1: {
    fieldName: "address1",
    label: "Address",
    inputType: "text",
    placeholder: "Address",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  address2: {
    fieldName: "address2",
    label: "City / State / ZIP Code",
    inputType: "text",
    placeholder: "City / State / ZIP Code",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  trailerNo: {
    fieldName: "trailerNo",
    label: "Trailer #",
    inputType: "text",
    placeholder: "Trailer #",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  probesSet: {
    fieldName: "probesSet",
    label: "Probes tested and set properly",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  otherTested: {
    fieldName: "otherTested",
    label: "Other safety devices tested and working properly",
    inputType: "switch",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  certificationDate: {
    fieldName: "certificationDate",
    label: "Certification Date",
    inputType: "date-picker",
    placeholder: "Certification Date",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  endDivider: {
    fieldName: "endDivider",
    label: "",
    inputType: "divider",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
};
