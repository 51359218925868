import { useState, useEffect } from "react";
import { Search } from "iconoir-react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-hot-toast";
import { formatCurrency } from "../../components/tools";
import { GetRates } from "../../actions/auth";
import { Input, Modal, InputNumber, DatePicker, Drawer } from "antd";
import { AddALaborRate, UpdateLaborRate } from "../../actions/admin";
import { Helmet } from "react-helmet-async";

const LaborRates = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [rates, setRates] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [newRate, setNewRate] = useState(false);
  const [data, setData] = useState({
    rateId: null,
    laborCode: "",
    rate: 0,
    description: "",
    effectiveDate: null,
    discontinuedDate: null,
  });
  const [viewRate, setViewRate] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetRates()
        .then((res) => {
          setRates(res.data);
          setRawData(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleSearch = (val) => {
    if (val.length > 0) {
      setLoading(true);
      let filtered = _.filter(rawData, (o) => {
        return (
          o.laborCode.toLowerCase().includes(val.toLowerCase()) ||
          o.rate.toLowerCase().includes(val.toLowerCase()) ||
          o.description.toLowerCase().includes(val.toLowerCase())
        );
      });
      setRates(filtered);
      setTimeout(() => {
        setLoading(false);
      }, 700);
    } else {
      setRates(rawData);
      setLoading(false);
    }
  };

  const openRate = (rate) => {
    setData(rate);
    setViewRate(true);
  };

  const closeNewRate = () => {
    setNewRate(false);
    setTimeout(() => {
      setData({
        rateId: null,
        laborCode: "",
        rate: 0,
        description: "",
        effectiveDate: null,
        discontinuedDate: null,
      });
    }, 700);
  };

  const submitNewRate = () => {
    if (data.laborCode.length < 3) {
      toast.error("Labor Code must be at least 3 characters");
    } else if (data.description.length < 3) {
      toast.error("Labor Description must be at least 3 characters");
    } else if (data.rate < 0.1) {
      toast.error("Labor rate must be at least $0.1");
    } else {
      setLoading(true);
      AddALaborRate(data)
        .then((res) => {
          toast.success("Labor rate added successfully");
          GetRates()
            .then((res) => {
              closeNewRate();
              setRates(res.data);
              setRawData(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
              setLoading(false);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error adding labor rate. Please try again");
          setLoading(false);
        });
    }
  };

  const closeOpenRate = () => {
    setViewRate(false);
    setTimeout(() => {
      setData({
        rateId: null,
        laborCode: "",
        rate: 0,
        description: "",
        effectiveDate: null,
        discontinuedDate: null,
      });
    }, 700);
  };

  const updateRate = () => {
    if (data.rateId.length < 3) {
      toast.error("Rate ID not found. Please try again");
    } else if (data.laborCode.length < 3) {
      toast.error("Labor Code must be at least 3 characters");
    } else if (data.description.length < 3) {
      toast.error("Labor Description must be at least 3 characters");
    } else if (data.rate < 0.1) {
      toast.error("Labor rate must be at least $0.1");
    } else {
      setLoading(true);
      UpdateLaborRate(data)
        .then((res) => {
          toast.success("Labor rate added successfully");
          GetRates()
            .then((res) => {
              closeOpenRate();
              setRates(res.data);
              setRawData(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
              setLoading(false);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error adding labor rate. Please try again");
          setLoading(false);
        });
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Labor Rates | HTPS ERP</title>
      </Helmet>
      <h1 className="mt-3 mb-3 text-3xl font-bold">Labor Rates</h1>
      <div className="flex flex-row items-center justify-between w-full gap-2 px-1">
        <div className="relative rounded-md shadow-sm w-80">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <Search className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder="Search rates"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <SecondaryButton callback={() => setNewRate(true)} label={"Add a New Labor Rate"} />
      </div>
      <div className="flex-grow flow-root w-full px-4 mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {loading ? (
              <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
            ) : rates.length > 0 ? (
              <table className="min-w-full border-separate border-spacing-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8 w-fit"
                    >
                      Code
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell w-1/2"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell w-fit"
                    >
                      Rate
                    </th>
                    <th scope="col" className="sticky top-0 z-10 border-b border-gray-300 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                      <span className="sr-only">View / Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rates.map((acc, accIndex) => (
                    <tr key={acc.rateId}>
                      <td
                        className={classNames(
                          accIndex !== rates.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8",
                        )}
                      >
                        {acc.laborCode}
                      </td>
                      <td
                        className={classNames(
                          accIndex !== rates.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell",
                        )}
                      >
                        {acc.description}
                      </td>
                      <td
                        className={classNames(
                          accIndex !== rates.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap capitalize hidden px-3 py-4 text-sm text-gray-500 lg:table-cell",
                        )}
                      >
                        {formatCurrency(acc.rate)}
                      </td>
                      <td
                        className={classNames(
                          accIndex !== rates.length - 1 ? "border-b border-gray-200" : "",
                          "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8",
                        )}
                      >
                        <button
                          onClick={() => openRate(acc)}
                          className="px-4 py-2 text-xs font-semibold text-blue-600 uppercase rounded-md hover:text-blue-900 bg-gray-200/75"
                        >
                          View / Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex flex-col items-center justify-start w-full h-full gap-5 pt-10 border-t border-gray-300">
                <h4 className="text-lg font-semibold text-gray-500">No labor rates found</h4>
                <SecondaryButton callback={() => {}} label={"Add a New Labor Rate"} />
              </div>
            )}
          </div>
        </div>
        <Modal
          open={newRate}
          title="Add a New Labor Rate"
          centered
          width={800}
          okText={"Add Rate"}
          destroyOnClose
          onCancel={() => closeNewRate()}
          onOk={() => submitNewRate()}
        >
          <div className="flex flex-col items-start justify-start w-full gap-4 py-4">
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Labor Code</p>
              <Input
                defaultValue={data.laborCode}
                placeholder="TIER-4"
                className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 hover:border-gray-400 focus:ring-0"
                onChange={(e) => setData({ ...data, laborCode: e.target.value })}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Labor Rate</p>
              <InputNumber
                placeholder="Labor Rate ($)"
                defaultValue={data.rate > 0 ? data.rate : null}
                controls={false}
                step={0.01}
                className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                onChange={(e) => setData({ ...data, rate: e })}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Description</p>
              <Input.TextArea
                defaultValue={data.description}
                placeholder="Tier 4 - Standard Rate"
                onChange={(e) => setData({ ...data, description: e.target.value })}
                rows={2}
                autoSize={false}
                className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
              />
            </div>
            {/* <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Effective Date (optional)</p>
              <DatePicker
                placeholder="Date the rate should become effective (if not immediately)"
                onChange={(date) => setData({ ...data, effectiveDate: date })}
                format={"MM/DD/YYYY"}
                className="w-full"
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Discontinue on (optional)</p>
              <DatePicker
                placeholder="Date the rate should be discontinued"
                onChange={(date) => setData({ ...data, discontinuedDate: date })}
                format={"MM/DD/YYYY"}
                className="w-full"
              />
            </div> */}
          </div>
        </Modal>
        <Drawer
          title="View/Edit Rate"
          placement="right"
          closable={true}
          onClose={() => closeOpenRate()}
          open={viewRate}
          destroyOnClose
          footer={[
            <div className="flex items-center justify-end w-full gap-4 py-2">
              <SecondaryButton label="Close" callback={() => closeOpenRate()} />
              <PrimaryButton label="Update" callback={() => updateRate()} />
            </div>,
          ]}
        >
          <div className="flex flex-col items-start justify-start w-full gap-4">
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Labor Code</p>
              <Input
                defaultValue={data.laborCode}
                placeholder="TIER-4"
                className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 hover:border-gray-400 focus:ring-0"
                onChange={(e) => setData({ ...data, laborCode: e.target.value })}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Labor Rate</p>
              <InputNumber
                placeholder="Labor Rate ($)"
                defaultValue={data.rate > 0 ? data.rate : null}
                controls={false}
                step={0.01}
                className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                onChange={(e) => setData({ ...data, rate: e })}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Description</p>
              <Input.TextArea
                defaultValue={data.description}
                placeholder="Tier 4 - Standard Rate"
                onChange={(e) => setData({ ...data, description: e.target.value })}
                rows={2}
                autoSize={false}
                className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
              />
            </div>
            {/* <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Effective Date (optional)</p>
              <DatePicker
                placeholder="Date the rate should become effective (if not immediately)"
                onChange={(date) => setData({ ...data, effectiveDate: date })}
                format={"MM/DD/YYYY"}
                className="w-full"
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-sm font-semibold text-gray-800/80">Discontinue on (optional)</p>
              <DatePicker
                placeholder="Date the rate should be discontinued"
                onChange={(date) => setData({ ...data, discontinuedDate: date })}
                format={"MM/DD/YYYY"}
                className="w-full"
              />
            </div> */}
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default LaborRates;
