import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { GetDailyReport } from "../../../actions/reports";
import { GenerateCountSheet, GenerateDailyTimeReport } from "../../../data/pdf";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { SecondaryButton } from "../../../components/buttons";
import { Helmet } from "react-helmet-async";

const DailyTimesheetReport = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const generateTimesheet = () => {
    let tmpDate = date.hour(0).minute(0).second(0).millisecond(0);
    setLoading(true);
    GetDailyReport({ date: tmpDate })
      .then((res) => {
        if (res.data.timeCards.length === 0) {
          toast.error("No time cards found for this date");
          setLoading(false);
        } else {
          let doc = GenerateDailyTimeReport(res.data);
          doc.setProperties({
            title: `Hi-Tech Power Systems | Daily Time Report ${tmpDate.format("MM-DD-YYYY")}`,
            subject: `Hi-Tech Power Systems | Daily Time Report ${tmpDate.format("MM-DD-YYYY")}`,
            author: "Hypertek Solutions LLC",
            keywords: "",
            creator: "contact@hypertek.dev",
          });
          window.open(doc.output("bloburl"), "_blank");
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "We encountered an error while loading Daily Timesheet Report data");
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Daily Timesheet Report | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full h-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Daily Timesheet Report</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          {!loading ? (
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="text-sm uppercase text-gray-500/90">Daily Timesheet Report</p>
              <p className="text-sm text-gray-600/80">
                Please select a date you wish to get a report for. This will generate a report for all employees who worked on that day.
              </p>
              <div className="flex flex-col items-start justify-start w-full gap-4 mt-5">
                <DatePicker format="MM-DD-YYYY" disabledDate={disabledDate} defaultValue={date} onChange={(date, dateString) => setDate(date)} />
                <SecondaryButton label="Generate Report" callback={() => generateTimesheet()} disabled={date ? false : true} />
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full gap-1">
              <p className="text-sm uppercase text-gray-500/90">Loading</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DailyTimesheetReport;
