import { useEffect, useState, Fragment, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import FormController from "../../components/FormController";
import { Button, DatePicker, Input, InputNumber, Modal, Popconfirm, Select, Switch, Tabs } from "antd";
import validator from "validator";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { Dialog, Transition, Menu } from "@headlessui/react";
import { Trash, EditPencil, NavArrowDown, Xmark, OpenNewWindow, Printer } from "iconoir-react";
import { v4 as uuidv4, validate } from "uuid";
import { formatCurrency } from "../../components/tools";
import _ from "lodash";
import { GenerateInvoicePDF } from "../../data/pdf";
import { newEquipmentATS, newEquipmentBasics, newEquipmentContact, newEquipmentDetails, newEquipmentEngine } from "../../data/equipmentForms";
import { AddEquipmentToCustomer } from "../../actions/customers";
import {
  GetOneInvoice,
  CalculateTax,
  VoidInvoice,
  UpdateInvoice,
  SubmitInvoice,
  SendInvoice,
  MarkPaid,
  GetInvoiceForPrint,
  ReviseInvoice,
  LeanUpdateInvoice,
  RevertToJob,
} from "../../actions/invoices";
import { MaskedInput } from "antd-mask-input";
import { Table } from "antd";
import { ReprintReceipt } from "../../actions/util";
import { Helmet } from "react-helmet-async";
import { TriangleAlert } from "lucide-react";

const testZip = (val) => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
};

const OpenInvoice = ({ authState }) => {
  let [loading, setLoading] = useState(true);
  let [invoice, setInvoice] = useState({
    parts: [],
    labor: [],
    misc: [],
    freight: [],
    loadbank: [],
    parking: [],
    equipment: [],
    payments: [],
  });
  let [employees, setEmployees] = useState([]);
  let [customer, setCustomer] = useState({});
  let [equipment, setEquipment] = useState([]);
  let [equipmentDrawer, setEquipmentDrawer] = useState(false);
  let [rates, setRates] = useState([]);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [addLaborModal, setAddLaborModal] = useState(false);
  let [laborData, setLaborData] = useState({
    laborId: "",
    rate: "",
    laborDescription: "",
    hours: 0,
    perHour: "",
    extPrice: 0,
    log: [],
    billableTime: 0,
    billedAmount: 0,
    quoted: 0,
    quotedPrice: 0,
    status: "",
    priceOverride: null,
    hideOnPrint: false,
  });
  let [addPartsModal, setAddPartsModal] = useState(false);
  let [rawPartsData, setRawPartsData] = useState([]);
  let [partsData, setPartsData] = useState([]);
  let [partsToAdd, setPartsToAdd] = useState([]);
  let [addParkingModal, setAddParkingModal] = useState(false);
  let [parkingData, setParkingData] = useState({
    parkingType: "",
    increment: "",
    startDate: "",
    endDate: "",
    unitCharge: 0,
    quantity: 0,
    totalCharge: 0,
    parkingId: "",
  });
  let [addLoadbankModal, setAddLoadbankModal] = useState(false);
  let [loadbankData, setLoadbankData] = useState({
    description: "",
    unitCharge: "",
    loadbankId: "",
  });
  let [addFreightModal, setAddFreightModal] = useState(false);
  let [freightData, setFreightData] = useState({
    poNumber: "",
    freightCost: 0,
    freightMarkup: 0,
    freightChargeOut: 0,
    vendor: "",
    freightId: "",
  });
  let [addMiscModal, setAddMiscModal] = useState(false);
  let [miscData, setMiscData] = useState({
    miscType: "",
    partNo: "",
    description: "",
    quantity: 0,
    chargeOutPrice: 0,
    miscId: "",
  });
  let [editLaborModal, setEditLaborModal] = useState(false);
  let [editPartModal, setEditPartModal] = useState(false);
  let [editPartData, setEditPartData] = useState({
    quantity: 0,
    partNo: "",
    partId: "",
    category: "",
    description: "",
    cost: 0,
    pricePerPart: 0,
    extPrice: 0,
    uniqueId: "",
    commissioned: 0,
    partStatus: "",
  });
  let [editParkingModal, setEditParkingModal] = useState(false);
  let [editFreightModal, setEditFreightModal] = useState(false);
  let [editLoadbankModal, setEditLoadbankModal] = useState(false);
  let [editMiscModal, setEditMiscModal] = useState(false);
  let [sales, setSales] = useState([]);

  const [addEquipmentModal, setAddEquipmentModal] = useState(false);
  const [step, setStep] = useState(0);
  const [eqBasics, setEqBasics] = useState({
    customerEquipId: "",
    equipmentType: "",
    power: "",
    miles: "",
    pwrUnit: "",
    milesUnit: "",
    fuelType: "",
    location: "",
    taxZip: "",
  });
  const [eqDetails, setEqDetails] = useState({
    make: "",
    model: "",
    specNo: "",
    serialNo: "",
    arrangement: "",
    status: "",
  });
  const [eqEngine, setEqEngine] = useState({
    make: "",
    model: "",
    specNo: "",
    serialNo: "",
    codeNo: "",
  });
  const [eqATS, setEqATS] = useState({
    make: "",
    model: "",
    specNo: "",
    serialNo: "",
    codeNo: "",
  });
  const [eqContact, setEqContact] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [sendEmailCheck, setSendEmailCheck] = useState(false);
  const [sendTextCheck, setSendTextCheck] = useState(false);
  const [acceptData, setAcceptData] = useState({
    approvedBy: "",
    title: "",
    approvalMethod: "",
    poNumber: "",
    acceptanceNotes: "",
  });
  const [rejectData, setRejectData] = useState({
    rejectedBy: "",
    approvalMethod: "",
    reasonForRejection: "",
  });
  const [search, setSearch] = useState("");
  const [taxAmount, setTaxAmount] = useState("0");
  const [voidModal, setVoidModal] = useState(false);
  const [shopFeeModal, setShopFeeModal] = useState(false);
  const [shopFeeVal, setShopFeeVal] = useState(0);
  const [sendToCustomerModal, setSendToCustomerModal] = useState(false);
  const [sendEmail, setSendEmail] = useState("");
  const [sendPhone, setSendPhone] = useState("");
  const [departments, setDepartments] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentData, setPaymentData] = useState({
    paymentAmount: 0,
    checkNo: "",
    note: "",
    paymentDate: "",
    nameOnTheCheck: "",
    location: "",
  });
  const [paymentModal, setPaymentModal] = useState(false);
  const [usePercentage, setUsePercentage] = useState(false);
  const [credit, setCredit] = useState({ creditAmount: 0, reason: "" });

  const { invoiceId } = useParams();
  const navigate = useNavigate();

  const freightCostRef = useRef();
  const freightMarkupRef = useRef();
  const freightChargeOutRef = useRef();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneInvoice(invoiceId)
        .then((res) => {
          setInvoice(res.data.invoice);
          if (
            res.data.invoice.taxZip === "29860" &&
            res.data.invoice.locationId === "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f" &&
            (invoice.invoiceStatus === "draft" || invoice.invoiceStatus === "ready")
          ) {
            setTimeout(() => {
              toast("Please make sure you're using the correct tax ZIP code for this invoice");
            }, 2500);
          }
          setSales(res.data.sales);
          setTaxAmount(res.data.invoice.tax);
          setShopFeeVal(res.data.invoice.shopFeeTotal);
          setPartsData(res.data.parts);
          setRawPartsData(res.data.parts);
          setRates(res.data.rates);
          setEmployees(res.data.employees);
          setEquipment(res.data.equipment);
          setCustomer(res.data.customer);
          setDepartments(res.data.departments);
          if (res.data.invoice.paymentTerms && res.data.invoice.paymentTerms !== "") {
            setValue("paymentTerms", res.data.invoice.paymentTerms);
          }
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error loading invoice");
          setTimeout(() => {
            navigate("/invoices");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
  }, [invoiceId]);

  const renderInvoiceStatus = () => {
    let status = invoice.invoiceStatus;
    switch (status) {
      case "draft":
        return (
          <span className="inline-flex items-center rounded-md bg-gray-50 px-3.5 py-1.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Draft
          </span>
        );
      case "ready":
        return (
          <span className="inline-flex items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Ready
          </span>
        );
      case "void":
        return (
          <span className="inline-flex items-center rounded-md bg-red-50 px-3.5 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            VOID
          </span>
        );
      case "overdue":
        return (
          <span className="inline-flex items-center rounded-md bg-red-50 px-3.5 py-1.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            Overdue
          </span>
        );
      case "sent":
        return (
          <span className="inline-flex items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Sent
          </span>
        );
      case "paid":
        return (
          <span className="inline-flex items-center rounded-md bg-green-50 px-3.5 py-1.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Paid
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center rounded-md bg-gray-50 px-3.5 py-1.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Unknown
          </span>
        );
    }
  };

  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isDirty, dirtyFields, isValid },
    getValues,
    trigger,
    setValue,
  } = formMethods;

  const onSubmit = (data) => {
    //
  };

  useEffect(() => {
    let zipVal = getValues("taxZip");
    let taxStatus = getValues("taxStatus");
    if (taxStatus === "taxable" && zipVal && zipVal.length > 0) {
      setDummyLoading(true);
      if (dayjs(invoice.invoiceDate).isBefore(dayjs("04/01/2024", "MM/DD/YYYY"))) {
        let richmond = ["30805", "30812", "30815", "30901", "30903", "30904", "30905", "30906", "30909", "30912", "30914", "30916", "30917", "30919", "30999"];
        if (richmond.includes(zipVal)) {
          let taxAmount = getTaxableAmount() * 0.08;
          setTaxAmount(taxAmount);
          setTimeout(() => setDummyLoading(false), 300);
        } else {
          CalculateTax({
            taxZip: zipVal,
            subtotal: getTaxableAmount(),
            invoiceDate: invoice.invoiceDate,
          })
            .then((res) => {
              setTaxAmount(res.data.tax);
              setTimeout(() => setDummyLoading(false), 300);
            })
            .catch((err) => {
              setDummyLoading(false);
            });
        }
      } else {
        CalculateTax({
          taxZip: zipVal,
          subtotal: getTaxableAmount(),
          invoiceDate: invoice.invoiceDate,
        })
          .then((res) => {
            setTaxAmount(res.data.tax);
            setTimeout(() => setDummyLoading(false), 300);
          })
          .catch((err) => {
            setDummyLoading(false);
          });
      }
    } else {
      setValue("taxStatus", invoice.taxStatus);
    }
  }, [invoice]);

  const changePaymentTerms = (e) => {
    setValue("paymentTerms", e.target.value);
    let invoiceDate = getValues("invoiceDate");
    invoiceDate = dayjs(invoice.invoiceDate);
    if (!invoiceDate || !invoiceDate.isValid()) {
      invoiceDate = invoice.invoiceDate ? dayjs(invoice.invoiceDate) : dayjs();
    }
    let val = e.target.value;
    if (val === "net10") {
      setValue("dueDate", invoiceDate.add(10, "day").toJSON());
    } else if (val === "net20") {
      setValue("dueDate", invoiceDate.add(20, "day").toJSON());
    } else if (val === "net30") {
      setValue("dueDate", invoiceDate.add(30, "day").toJSON());
    } else {
      setValue("dueDate", invoiceDate.toJSON());
    }
  };

  const renderUpperForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className={`w-full flex flex-col justify-start items-center gap-5 px-1`} key="upperForm">
        <FormProvider {...formMethods}>
          <div className="flex flex-col items-center justify-between w-full gap-2 md:flex-row">
            <div key="invoiceDate" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="invoiceDate" className="pb-1 text-xs text-gray-600 uppercase">
                Invoice Date
              </label>
              <Controller
                control={formMethods.control}
                name="invoiceDate"
                rules={{
                  required: "Invoice Date is required",
                  validate: (v) => dayjs(v).isValid() || "Invoice Date is required",
                }}
                defaultValue={invoice.invoiceDate ? dayjs(invoice.invoiceDate) : null}
                render={({ field, fieldState }) => (
                  <DatePicker
                    placeholder="Invoice Date"
                    status={fieldState.error ? "error" : undefined}
                    ref={field.ref}
                    name={field.name}
                    onBlur={field.onBlur}
                    format={"MM/DD/YYYY"}
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => {
                      field.onChange(date ? date.toJSON() : null);
                    }}
                    className="w-full"
                    disabled={invoice.invoiceStatus !== "draft" || authState.user.functionCategory === "technician"}
                  />
                )}
              />
              <ErrorMessage errors={errors} name="invoiceDate" as="p" className="px-1 pt-1 text-xs text-red-500" />
            </div>
            <div key="dueDate" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="dueDate" className="pb-1 text-xs text-gray-600 uppercase">
                Invoice Due Date
              </label>
              <Controller
                control={formMethods.control}
                name="dueDate"
                rules={{
                  required: "Invoice due date is required",
                  validate: (v) => dayjs(v).isValid() || "Invoice due date is required",
                }}
                defaultValue={invoice.dueDate ? dayjs(invoice.dueDate) : null}
                render={({ field, fieldState }) => (
                  <DatePicker
                    placeholder="Invoice due date"
                    status={fieldState.error ? "error" : undefined}
                    ref={field.ref}
                    name={field.name}
                    format={"MM/DD/YYYY"}
                    onBlur={field.onBlur}
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => {
                      field.onChange(date ? date.toJSON() : null);
                    }}
                    className="w-full"
                    disabled={invoice.invoiceStatus !== "draft" || authState.user.functionCategory === "technician"}
                  />
                )}
              />
              <ErrorMessage errors={errors} name="dueDate" as="p" className="px-1 pt-1 text-xs text-red-500" />
            </div>
            <div key="paymentTerms" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="paymentTerms" className="pb-1 text-xs text-gray-600 uppercase">
                Payment Terms
              </label>
              <select
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                id="paymentTerms"
                defaultValue={invoice.paymentTerms}
                {...register("paymentTerms", {
                  required: false,
                })}
                onChange={(e) => changePaymentTerms(e)}
                disabled={invoice.invoiceStatus !== "draft" || authState.user.functionCategory === "technician"}
              >
                <option hidden disabled value="">
                  Select One
                </option>
                <option key={uuidv4()} value={"net10"}>
                  NET 10
                </option>
                <option key={uuidv4()} value={"net20"}>
                  NET 20
                </option>
                <option key={uuidv4()} value={"net30"}>
                  NET 30
                </option>
                <option key={uuidv4()} value={"collectOnDelivery"}>
                  Collect on Delivery
                </option>
                <option key={uuidv4()} value={"dueOnReceipt"}>
                  Due on Receipt
                </option>
                <option key={uuidv4()} value={"prePay"}>
                  Pay in Advance
                </option>
              </select>
              <ErrorMessage errors={errors} name="paymentTerms" as="p" className="px-1 pt-1 text-xs text-red-500" />
            </div>
          </div>
          <div className="flex flex-col items-center justify-between w-full gap-2 md:flex-row">
            <div key="customerPo" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="customerPo" className="pb-1 text-xs text-gray-600 uppercase">
                Customer's PO
              </label>
              <input
                className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                type="text"
                id="customerPo"
                defaultValue={invoice.customerPo}
                placeholder="Customer's PO Number"
                {...register("customerPo", {
                  required: false,
                })}
                onBlur={(e) => leanUpdate(e)}
              />
              <ErrorMessage errors={errors} name="customerPo" as="p" className="px-1 pt-1 text-xs text-red-500" />
            </div>
            <div key="taxStatus" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="taxStatus" className="pb-1 text-xs text-gray-600 uppercase">
                Tax Status
              </label>
              <select
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                id="taxStatus"
                defaultValue={invoice.taxStatus}
                {...register("taxStatus", {
                  required: false,
                })}
                disabled={invoice.invoiceStatus !== "draft" || authState.user.functionCategory === "technician"}
              >
                <option hidden disabled value="">
                  Select One
                </option>
                <option key={uuidv4()} value={"taxable"}>
                  Taxable
                </option>
                <option key={uuidv4()} value={"gov"}>
                  Government Agency
                </option>
                <option key={uuidv4()} value={"st5biz"}>
                  Business with ST-5
                </option>
                <option key={uuidv4()} value={"outOfState"}>
                  Out of State
                </option>
              </select>
              <ErrorMessage errors={errors} name="taxStatus" as="p" className="px-1 pt-1 text-xs text-red-500" />
            </div>
            <div key="taxZip" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="taxZip" className="pb-1 text-xs text-gray-600 uppercase">
                Sales Tax ZIP
              </label>
              <input
                className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
                type="text"
                id="taxZip"
                defaultValue={invoice.taxZip}
                placeholder="Sales Tax ZIP"
                {...register("taxZip", {
                  required: "Customer's sales tax ZIP code is required",
                  validate: (value) => testZip(value) || "Please enter a valid ZIP code",
                })}
                disabled={invoice.invoiceStatus !== "draft" || authState.user.functionCategory === "technician"}
              />
              <ErrorMessage errors={errors} name="taxZip" as="p" className="px-1 pt-1 text-xs text-red-500" />
            </div>
          </div>
          <div key="jobDescription" className="flex flex-col items-start justify-start w-full">
            <label htmlFor="jobDescription" className="pb-1 text-xs text-gray-600 uppercase">
              Job Description
            </label>
            <input
              className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
              type="text"
              id="jobDescription"
              defaultValue={invoice.jobDescription}
              placeholder="Job Description"
              {...register("jobDescription", {
                required: false,
              })}
              disabled={invoice.invoiceStatus !== "draft" || authState.user.functionCategory === "technician"}
            />
            <ErrorMessage errors={errors} name="jobDescription" as="p" className="px-1 pt-1 text-xs text-red-500" />
          </div>
          <div key="jobDetails" className="flex flex-col items-start justify-start w-full">
            <label htmlFor="jobDetails" className="pb-1 text-xs text-gray-600 uppercase">
              Job Details
            </label>
            <input
              className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
              type="text"
              id="jobDetails"
              defaultValue={invoice.jobDetails}
              placeholder="Job Description"
              {...register("jobDetails", {
                required: false,
              })}
              disabled={invoice.invoiceStatus !== "draft" || authState.user.functionCategory === "technician"}
            />
            <ErrorMessage errors={errors} name="jobDetails" as="p" className="px-1 pt-1 text-xs text-red-500" />
          </div>
          <div key="commission" className="flex flex-col items-start justify-start w-full">
            <label htmlFor="commission" className="pb-1 text-xs text-gray-600 uppercase">
              Commissioned Employee
            </label>
            <select
              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-[#00000008] disabled:text-gray-400"
              id="commission"
              defaultValue={invoice.commission ? invoice.commission : ""}
              {...register("commission", {
                required: false,
              })}
              disabled={invoice.invoiceStatus !== "draft" || authState.user.functionCategory === "technician"}
            >
              <option disabled value="">
                Select One
              </option>
              {employees.map((emp) => {
                return (
                  <option key={uuidv4()} value={emp.userId}>
                    {emp.firstName} {emp.lastName}
                  </option>
                );
              })}
            </select>
            <ErrorMessage errors={errors} name="commission" as="p" className="px-1 pt-1 text-xs text-red-500" />
          </div>
        </FormProvider>
      </form>
    );
  };

  const renderEquipStatus = (status) => {
    if (status === "availableForUse") {
      return "Available for Use";
    } else if (status === "inoperable") {
      return "Inoperable";
    } else if (status === "retired") {
      return "Retired";
    } else {
      return "Unknown";
    }
  };

  const renderEquipType = (eqType) => {
    switch (eqType) {
      case "generator":
        return "Generator";
      case "pressureWasher":
        return "Pressure Washer";
      case "truck":
        return "Truck";
      case "trailer":
        return "Trailer";
      case "welder":
        return "Welder";
      case "airCompressor":
        return "Air Compressor";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const openLaborEdit = (id) => {
    let lbr = _.find(invoice.labor, { laborId: id });
    if (lbr) {
      setLaborData(lbr);
      setTimeout(() => setEditLaborModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const removeLabor = (id) => {
    setDummyLoading(true);
    let tmp = invoice;
    tmp.labor = tmp.labor.filter((l) => l.laborId !== id);
    setInvoice(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const openPartEdit = (id) => {
    let prt = _.find(invoice.parts, { uniqueId: id });
    if (prt) {
      setEditPartData(prt);
      setTimeout(() => setEditPartModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const removePart = (id) => {
    setDummyLoading(true);
    let tmp = invoice;
    tmp.parts = tmp.parts.filter((p) => p.uniqueId !== id);
    setInvoice(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const openPartsModal = () => {
    setPartsData(rawPartsData);
    setAddPartsModal(true);
  };

  const openParkingEdit = (id) => {
    let prk = _.find(invoice.parking, { parkingId: id });
    if (prk) {
      setParkingData(prk);
      setTimeout(() => setEditParkingModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const removeParking = (id) => {
    setDummyLoading(true);
    let tmp = invoice;
    tmp.parking = tmp.parking.filter((p) => p.parkingId !== id);
    setInvoice(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const removeLoadbank = (id) => {
    setDummyLoading(true);
    let tmp = invoice;
    tmp.loadbank = tmp.loadbank.filter((l) => l.loadbankId !== id);
    setInvoice(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const removeFreight = (id) => {
    setDummyLoading(true);
    let tmp = invoice;
    tmp.freight = tmp.freight.filter((f) => f.freightId !== id);
    setInvoice(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const removeMisc = (id) => {
    setDummyLoading(true);
    let tmp = invoice;
    tmp.misc = tmp.misc.filter((m) => m.miscId !== id);
    setInvoice(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const openLoadbankEdit = (id) => {
    let frt = _.find(invoice.loadbank, { loadbankId: id });
    if (frt) {
      setLoadbankData(frt);
      setTimeout(() => setEditLoadbankModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const openFreightEdit = (id) => {
    let frt = _.find(invoice.freight, { freightId: id });
    if (frt) {
      setFreightData(frt);
      setTimeout(() => setEditFreightModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const openMiscEdit = (id) => {
    let frt = _.find(invoice.misc, { miscId: id });
    if (frt) {
      setMiscData(frt);
      setTimeout(() => setEditMiscModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const calculateLabor = () => {
    let total = 0;
    invoice.labor.forEach((labor) => {
      total += parseFloat(labor.quotedPrice);
    });
    return total;
  };

  const calculateParts = () => {
    let total = 0;
    invoice.parts.forEach((part) => {
      if (part.category === "parts") {
        total += parseFloat(part.extPrice);
      }
    });
    invoice.misc.forEach((part) => {
      if (part.miscType === "part") {
        total += parseFloat(part.chargeOutPrice);
      }
    });
    return total;
  };

  const calculateGOG = () => {
    let total = 0;
    invoice.parts.forEach((part) => {
      if (part.category !== "parts") {
        total += parseFloat(part.extPrice);
      }
    });
    return total;
  };

  const calculateFreight = () => {
    let total = 0;
    invoice.freight.forEach((freight) => {
      total += parseFloat(freight.freightChargeOut);
    });
    return total;
  };

  const calculateMisc = () => {
    let total = 0;
    invoice.loadbank.forEach((el) => {
      total += parseFloat(el.unitCharge);
    });
    invoice.parking.forEach((el) => {
      total += parseFloat(el.totalCharge);
    });
    return total;
  };

  const calculateServices = () => {
    let total = 0;
    invoice.misc.forEach((misc) => {
      if (misc.miscType === "service") total += parseFloat(misc.chargeOutPrice);
    });
    return total;
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    subtotal += calculateLabor();
    subtotal += calculateParts();
    subtotal += calculateGOG();
    subtotal += calculateFreight();
    subtotal += calculateServices();
    subtotal += calculateMisc();
    subtotal += invoice.shopFeeTotal;
    return parseFloat(subtotal.toFixed(2));
  };

  const calculateTotal = () => {
    let total = 0;
    total += calculateSubtotal();
    total += taxAmount;
    total = total - calculatePayments();
    return total;
  };

  const calculatePayments = () => {
    let paid = 0;
    if (invoice.payments) {
      for (let i = 0; i < invoice.payments.length; i++) {
        paid += parseFloat(invoice.payments[i].paymentAmount);
      }
    }
    return paid;
  };

  const updateQuoteEq = (eqId) => {
    setDummyLoading(true);
    if (equipmentExists(eqId)) {
      let tmp = invoice;
      let newEq = tmp.equipment.filter((eq) => eq.equipmentId !== eqId);
      tmp.equipment = newEq;
      setInvoice(tmp);
    } else {
      let tmp = invoice;
      tmp.equipment.push({
        equipmentId: eqId,
        photos: [],
        notes: [],
      });
      setInvoice(tmp);
    }
    setTimeout(() => setDummyLoading(false), 700);
  };

  const equipmentExists = (eqId) => {
    let existing = false;
    invoice.equipment.forEach((eq) => {
      if (eq.equipmentId === eqId) {
        existing = true;
      }
    });
    return existing;
  };

  const eqSteps = [
    { name: "Basic Info | Step 1", href: "#", status: "current" },
    { name: "Details | Step 2", href: "#", status: "upcoming" },
    { name: "Engine | Step 3", href: "#", status: "upcoming" },
    { name: "ATS | Step 4", href: "#", status: "upcoming" },
    { name: "Contact Info | Step 5", href: "#", status: "upcoming" },
    { name: "Notes | Step 6", href: "#", status: "upcoming" },
  ];

  const renderEquipmentType = (type) => {
    switch (type) {
      case "generator":
        return "Generator";
      case "pressureWasher":
        return "Pressure Washer";
      case "truck":
        return "Truck";
      case "trailer":
        return "Trailer";
      case "welder":
        return "Welder";
      case "airCompressor":
        return "Air Compressor";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const renderFuelType = (type) => {
    switch (type) {
      case "diesel":
        return "Diesel";
      case "gasoline":
        return "Gasoline";
      case "naturalGas":
        return "Natural Gas";
      case "propane":
        return "Propane";
      case "biFuel":
        return "Bi-Fuel";
      case "electricity":
        return "Electricity";
      default:
        return "Other";
    }
  };

  const renderEquipmentStatus = (status) => {
    switch (status) {
      case "availableForUse":
        return "Available For Use";
      case "inoperable":
        return "Inoperable";
      case "retired":
        return "Retired";
      default:
        return "Unknown";
    }
  };

  const renderPhoneNumber = (number) => {
    let toRet = "(";
    toRet += number.slice(0, 3);
    toRet += ") ";
    toRet += number.slice(3, 6);
    toRet += "-";
    toRet += number.slice(6, 10);
    return toRet;
  };

  const renderEquipmentDrawer = () => {
    return (
      <Transition.Root show={equipmentDrawer} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setEquipmentDrawer}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                    <div className="flex flex-col h-full pt-6 overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Add / Remove Equipment</Dialog.Title>
                          <div className="flex items-center ml-3 h-7">
                            <button
                              type="button"
                              className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                              onClick={() => setEquipmentDrawer(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <Xmark className="w-6 h-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex flex-col items-center justify-start flex-1 gap-2 px-4 mt-6 overflow-y-auto sm:px-6">
                        {equipment.map((eq) => (
                          <div
                            className={`w-full flex flex-col justify-start items-start border rounded-lg ${
                              equipmentExists(eq.equipmentId) ? "border-gray-500" : "border-gray-300"
                            } px-4 py-3 gap-1`}
                            key={eq.equipmentId}
                          >
                            <p className="text-sm">
                              <span className="text-xs font-semibold uppercase">Type:</span> {renderEquipType(eq.equipmentType)}
                            </p>
                            <p className="text-sm">
                              <span className="text-xs font-semibold uppercase">Customer's Equipment ID:</span>{" "}
                              {eq.customerEquipId && eq.customerEquipId.length > 0 ? eq.customerEquipId : "Not Provided"}
                            </p>
                            <p className="text-sm">
                              <span className="text-xs font-semibold uppercase">Make:</span> {eq.details.make}
                            </p>
                            <p className="text-sm">
                              <span className="text-xs font-semibold uppercase">Model:</span> {eq.details.model}
                            </p>
                            <div className="flex flex-row items-center justify-end w-full">
                              <SecondaryButton
                                label={equipmentExists(eq.equipmentId) ? "Remove from Quote" : "Add to Quote"}
                                callback={() => {
                                  updateQuoteEq(eq.equipmentId);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-row items-center justify-end w-full gap-3 px-5 py-5 border-t border-gray-300">
                        <SecondaryButton label="Add New Equipment" callback={() => window.open("/equipment/new", "_blank")} />
                        <SecondaryButton label="Close" callback={() => setEquipmentDrawer(false)} />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderNewEqStep = () => {
    if (step === 0) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
            <FormController onSubmit={submitStep} fields={newEquipmentBasics} values={eqBasics} buttonText={"Next"} fullWidth={true} />
          </div>
        </div>
      );
    } else if (step === 1) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
            <FormController onSubmit={submitStep} fields={newEquipmentDetails} values={eqDetails} buttonText={"Next"} fullWidth={true} />
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
            <FormController onSubmit={submitStep} fields={newEquipmentEngine} values={eqEngine} buttonText={"Next"} fullWidth={true} />
          </div>
        </div>
      );
    } else if (step === 3) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
            <FormController onSubmit={submitStep} fields={newEquipmentATS} values={eqATS} buttonText={"Next"} fullWidth={true} />
          </div>
        </div>
      );
    } else if (step === 4) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
            <FormController onSubmit={submitStep} fields={newEquipmentContact} values={eqContact} buttonText={"Next"} fullWidth={true} />
          </div>
        </div>
      );
    } else if (step === 5) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 my-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 px-4">
            <p className="w-full text-lg font-bold text-center text-gray-500 uppercase">New Equipment Overview</p>
            <div className="grid w-full grid-cols-2 gap-4 px-4 gap-y-4">
              <div className="flex flex-col items-start justify-start gap-2">
                <div className="w-full py-2 border-b border-gray-300">
                  <p className="text-sm font-bold text-gray-400 uppercase">Basic Information</p>
                </div>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Customer's Equipment ID: </span>
                  {eqBasics.customerEquipId}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Equipment Type: </span>
                  {renderEquipmentType(eqBasics.equipmentType)}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Power: </span>
                  {eqBasics.power} {eqBasics.pwrUnit}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal capitalize">{eqBasics.milesUnit}: </span>
                  {eqBasics.miles} {eqBasics.milesUnit}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Fuel: </span>
                  {renderFuelType(eqBasics.fuelType)}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Location: </span>
                  {eqBasics.location}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Sales Tax ZIP: </span>
                  {eqBasics.taxZip}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start gap-2">
                <div className="w-full py-2 border-b border-gray-300">
                  <p className="text-sm font-bold text-gray-400 uppercase">Detailed Information</p>
                </div>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Make: </span>
                  {eqDetails.make}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Model: </span>
                  {eqDetails.model}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Spec No: </span>
                  {eqDetails.specNo}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Serial No: </span>
                  {eqDetails.serialNo}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Arrangement: </span>
                  {eqDetails.arrangement}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Equipment Status: </span>
                  {renderEquipmentStatus(eqDetails.status)}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start gap-2">
                <div className="w-full py-2 border-b border-gray-300">
                  <p className="text-sm font-bold text-gray-400 uppercase">Engine Information</p>
                </div>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Make: </span>
                  {eqEngine.make}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Model: </span>
                  {eqEngine.model}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Spec No: </span>
                  {eqEngine.specNo}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Serial No: </span>
                  {eqEngine.serialNo}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Code No: </span>
                  {eqEngine.codeNo}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start gap-2">
                <div className="w-full py-2 border-b border-gray-300">
                  <p className="text-sm font-bold text-gray-400 uppercase">ATS Information</p>
                </div>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Make: </span>
                  {eqATS.make}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Model: </span>
                  {eqATS.model}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Spec No: </span>
                  {eqATS.specNo}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Serial No: </span>
                  {eqATS.serialNo}
                </p>
                <p className="text-sm font-semibold text-gray-900">
                  <span className="font-normal">Code No: </span>
                  {eqATS.codeNo}
                </p>
              </div>
            </div>
            <div className="grid w-full grid-cols-3 gap-4 px-4 gap-y-4">
              <div className="w-full col-span-3 py-2 border-b border-gray-300">
                <p className="text-sm font-bold text-gray-400 uppercase">Contact Information</p>
              </div>
              <p className="text-sm font-semibold text-gray-900">
                <span className="font-normal">First Name: </span>
                {eqContact.firstName}
              </p>
              <p className="text-sm font-semibold text-gray-900">
                <span className="font-normal">Last Name: </span>
                {eqContact.lastName}
              </p>
              <p className="text-sm font-semibold text-gray-900">
                <span className="font-normal">Phone Number: </span>
                {renderPhoneNumber(eqContact.phoneNumber)}
              </p>
            </div>
            <div className="flex flex-row items-center justify-end w-full gap-2 px-4 pt-3 mt-4 border-t border-gray-300">
              <SecondaryButton label="Cancel" callback={() => closeAddEquipment()} />
              {step === 5 && <PrimaryButton label="Add Equipment" callback={() => submitAddEquipment()} />}
            </div>
          </div>
        </div>
      );
    }
  };

  const submitStep = (data) => {
    if (step === 0) {
      setEqBasics(data);
      setStep(1);
    } else if (step === 1) {
      setEqDetails(data);
      setStep(2);
    } else if (step === 2) {
      setEqEngine(data);
      setStep(3);
    } else if (step === 3) {
      setEqATS(data);
      setStep(4);
    } else if (step === 4) {
      setEqContact(data);
      setStep(5);
    }
  };

  const closeAddEquipment = () => {
    setAddEquipmentModal(false);
    setStep(0);
    setEqBasics({
      customerEquipId: "",
      equipmentType: "",
      power: "",
      miles: "",
      pwrUnit: "",
      milesUnit: "",
      fuelType: "",
      location: "",
      taxZip: "",
    });
    setEqDetails({
      make: "",
      model: "",
      specNo: "",
      serialNo: "",
      arrangement: "",
      status: "",
    });
    setEqEngine({
      make: "",
      model: "",
      specNo: "",
      serialNo: "",
      codeNo: "",
    });
    setEqATS({
      make: "",
      model: "",
      specNo: "",
      serialNo: "",
      codeNo: "",
    });
    setEqContact({
      firstName: "",
      lastName: "",
      phoneNumber: "",
    });
  };

  const submitAddEquipment = () => {
    let toSend = eqBasics;
    toSend.details = eqDetails;
    toSend.engine = eqEngine;
    toSend.ats = eqATS;
    toSend.contact = eqContact;
    delete toSend.modifiedFields;
    delete toSend.formModified;
    delete toSend.details.modifiedFields;
    delete toSend.details.formModified;
    delete toSend.engine.formModified;
    delete toSend.engine.modifiedFields;
    delete toSend.ats.formModified;
    delete toSend.ats.modifiedFields;
    delete toSend.contact.formModified;
    delete toSend.contact.modifiedFields;
    setLoading(true);
    AddEquipmentToCustomer(toSend, customer.customerId)
      .then((res) => {
        toast.success("Equipment added successfully");
        closeAddEquipment();
        let tmp = equipment;
        tmp.push(res.data.equipment);
        setEquipment(tmp);
        setTimeout(() => setLoading(false), 700);
        // reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please try again.");
        setLoading(false);
      });
  };

  const renderNewEquipmentModal = () => {
    return (
      <Transition.Root show={addEquipmentModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddEquipment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-0 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl">
                  <div>
                    <div>
                      <div className="flex flex-row items-center justify-between w-full px-4 pb-3 border-b border-gray-300">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Add New Equipment
                        </Dialog.Title>
                        <nav className="flex items-center justify-center" aria-label="Progress">
                          <p className="text-sm font-medium">
                            Step {step + 1} of {eqSteps.length}
                          </p>
                          <ol role="list" className="flex items-center ml-5 space-x-5">
                            {eqSteps.map((currentStep, index) => (
                              <li key={currentStep.name}>
                                {index < step ? (
                                  <p className="block h-2.5 w-2.5 rounded-full bg-blue-600 hover:bg-blue-900">
                                    <span className="sr-only">{currentStep.name}</span>
                                  </p>
                                ) : index === step ? (
                                  <p className="relative flex items-center justify-center" aria-current="step">
                                    <span className="absolute flex w-5 h-5 p-px" aria-hidden="true">
                                      <span className="w-full h-full bg-blue-200 rounded-full" />
                                    </span>
                                    <span className="relative block h-2.5 w-2.5 rounded-full bg-blue-600" aria-hidden="true" />
                                    <span className="sr-only">{currentStep.name}</span>
                                  </p>
                                ) : (
                                  <p className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">
                                    <span className="sr-only">{currentStep.name}</span>
                                  </p>
                                )}
                              </li>
                            ))}
                          </ol>
                        </nav>
                        <button
                          type="button"
                          className="text-gray-400 bg-white rounded-md hover:text-gray-500"
                          // onClick={() => closeAddEquipment()}
                        >
                          <span className="sr-only">Close</span>
                          <Xmark className="w-6 h-6" aria-hidden="true" />
                        </button>
                      </div>
                      {renderNewEqStep()}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAddLaborModal = () => {
    setAddLaborModal(false);
    setLaborData({
      laborId: "",
      rate: "",
      description: "",
      hours: 0,
      perHour: "",
      extPrice: 0,
      hideOnPrint: false,
    });
  };

  const changeLaborData = (e) => {
    let { id, value } = e.target;
    let tmp = laborData;
    tmp[id] = value;
    setLaborData(tmp);
  };

  const addNewLabor = () => {
    let failed = false;
    if (laborData.rate === "") {
      toast.error("Please select a labor rate");
      failed = true;
    } else if (laborData.hours === 0) {
      toast.error("Please enter the number of hours to be worked");
      failed = true;
    } else if (laborData.description.length < 5) {
      toast.error("Please enter a description for the labor being quoted");
      failed = true;
    }

    if (!failed) {
      setDummyLoading(true);
      let tmp = invoice;
      let toPush = {
        laborId: uuidv4(),
        log: [],
        laborDescription: laborData.description,
        rate: laborData.rate,
        quoted: parseFloat(laborData.hours),
        billableTime: 0,
        perHour: rates.find((rt) => rt.rateId === laborData.rate).rate,
        status: "additionalLabor",
        hideOnPrint: laborData.hideOnPrint,
      };
      if (parseFloat(invoice.laborDiscount) > 0) {
        toPush.perHour = parseFloat((toPush.perHour * ((100 - parseFloat(invoice.laborDiscount)) / 100)).toFixed(2));
      }
      toPush.quotedPrice = parseFloat((toPush.quoted * toPush.perHour).toFixed(2));
      tmp.labor.push(toPush);
      setInvoice(tmp);
      closeAddLaborModal();
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const renderAddLaborModal = () => {
    return (
      <Transition.Root show={addLaborModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddLaborModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-md sm:px-6">
                  <div>
                    <div className="text-center">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Labor to Quote
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="rate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="rate" className="pb-1 text-xs text-gray-600 uppercase">
                            Labor Rate
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="rate"
                            defaultValue=""
                            onChange={changeLaborData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            {rates.map((rt) => (
                              <option key={rt.rateId} value={rt.rateId}>
                                {rt.laborCode} - {rt.description} | {formatCurrency(rt.rate)}/hr
                              </option>
                            ))}
                          </select>
                        </div>
                        <div key="hours" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="hours" className="pb-1 text-xs text-gray-600 uppercase">
                            Hours
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="hours"
                            placeholder="How many hours of labor is being quoted?"
                            onChange={changeLaborData}
                          />
                        </div>
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Labor Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="description"
                            placeholder="Describe the labor being quoted"
                            onChange={changeLaborData}
                          />
                        </div>
                        <div key="hideOnPrint" className="flex items-center justify-between w-full">
                          <label htmlFor="hideOnPrint" className="pb-1 text-xs text-gray-600 uppercase">
                            Hide labor description on print
                          </label>
                          <Switch checked={laborData.hideOnPrint} onChange={(v) => setLaborData((prevVal) => ({ ...prevVal, hideOnPrint: v }))} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeAddLaborModal()} />
                    <PrimaryButton label="Add Labor" callback={() => addNewLabor()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderLaborStatus = (status) => {
    let statuses = {
      quoted: "Quoted",
      incomplete: "Incomplete",
      inProgress: "In Progress",
      completed: "Completed",
      skipped: "Skipped",
      additionalLabor: "Additional Labor",
      void: "VOID",
    };
    return statuses[status];
  };

  const closeLaborEdit = () => {
    setDummyLoading(false);
    setEditLaborModal(false);
    setLaborData({
      laborId: "",
      rate: "",
      description: "",
      hours: 0,
      perHour: "",
      extPrice: 0,
      hideOnPrint: false,
    });
    recalculateShopFee();
  };

  const recalculateShopFee = () => {
    let amount = calculateLabor();
    let shopFee = parseFloat((amount * 0.03).toFixed(2));
    setShopFeeVal(shopFee || 0);
  };

  useEffect(() => {
    if (!shopFeeModal) {
      updateShopFee();
    }
  }, [shopFeeVal]);

  const submitLaborEdit = () => {
    setDummyLoading(true);
    let tmp = invoice;
    let lbrIndex = _.findIndex(tmp.labor, { laborId: laborData.laborId });
    let toAdd = laborData;
    toAdd.quoted = parseFloat(laborData.quoted);
    toAdd.perHour = rates.find((rt) => rt.rateId === laborData.rate).rate;
    toAdd.quotedPrice = toAdd.perHour * toAdd.quoted;
    if (laborData.priceOverride) {
      toAdd.quotedPrice = parseFloat(laborData.priceOverride);
    }
    tmp.labor[lbrIndex] = toAdd;
    setInvoice(tmp);
    setTimeout(() => closeLaborEdit(), 700);
  };

  const renderEditLaborModal = () => {
    return (
      <Modal
        open={editLaborModal}
        onClose={closeLaborEdit}
        onCancel={closeLaborEdit}
        title="Edit Labor Entry"
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
            <SecondaryButton label="Cancel" callback={() => closeLaborEdit()} />
            <PrimaryButton label="Save" callback={() => submitLaborEdit()} />
          </div>,
        ]}
        centered
        destroyOnClose
        width={"60%"}
      >
        <div>
          <div className="text-center">
            <div className="flex flex-col items-center justify-start gap-4 mt-2">
              <div key="rate" className="flex flex-col items-start justify-start w-full">
                <label htmlFor="rate" className="pb-1 text-xs text-gray-600 uppercase">
                  Labor Rate
                </label>
                <select
                  className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  id="rate"
                  defaultValue={laborData.rate}
                  onChange={changeLaborData}
                >
                  <option hidden disabled value="">
                    Select One
                  </option>
                  {rates.map((rt) => (
                    <option key={rt.rateId} value={rt.rateId}>
                      {rt.laborCode} - {rt.description} | {formatCurrency(rt.rate)}/hr
                    </option>
                  ))}
                </select>
              </div>
              <div key="quoted" className="flex flex-col items-start justify-start w-full">
                <label htmlFor="quoted" className="pb-1 text-xs text-gray-600 uppercase">
                  Hours
                </label>
                <input
                  className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  type="number"
                  id="quoted"
                  defaultValue={laborData.quoted}
                  placeholder="How many hours of labor has been quoted?"
                  onChange={changeLaborData}
                />
              </div>
              <div key="laborDescription" className="flex flex-col items-start justify-start w-full">
                <label htmlFor="laborDescription" className="pb-1 text-xs text-gray-600 uppercase">
                  Labor Description
                </label>
                <textarea
                  className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  type="text"
                  rows={4}
                  id="laborDescription"
                  defaultValue={laborData.laborDescription}
                  placeholder="Describe the labor being quoted"
                  onChange={changeLaborData}
                />
              </div>
              <div key="priceOverride" className="flex flex-col items-start justify-start w-full">
                <label htmlFor="priceOverride" className="pb-1 text-xs text-gray-600 uppercase">
                  Ext. Price Override
                </label>
                <input
                  className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  type="number"
                  id="priceOverride"
                  defaultValue={laborData.quotedPrice}
                  placeholder="If you wish to manually override the ext. price - change it here"
                  onChange={changeLaborData}
                />
              </div>
              <div key="hideOnPrint" className="flex items-center justify-between w-full">
                <label htmlFor="hideOnPrint" className="pb-1 text-xs text-gray-600 uppercase">
                  Hide labor description on print
                </label>
                <Switch checked={laborData.hideOnPrint} onChange={(v) => setLaborData((prevVal) => ({ ...prevVal, hideOnPrint: v }))} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const closePartsModal = () => {
    setPartsData(rawPartsData);
    setAddPartsModal(false);
    setPartsToAdd([]);
    setSearch("");
  };

  const changePartPrice = (e) => {
    let tmp = partsToAdd;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      setDummyLoading(true);
      let part = partsData.find((pt) => pt.partId === id);
      let existing = _.find(tmp, (p) => p.partId === id);
      if (existing && existing !== undefined) {
        existing.pricePerPart = value;
        existing.extPrice = parseFloat((existing.quantity * value).toFixed(2));
        let index = _.findIndex(tmp, (p) => p.uniqueId === existing.uniqueId);
        tmp[index] = existing;
        setPartsToAdd(tmp);
      } else {
        tmp.push({
          quantity: 0,
          partNo: part.partNo,
          partId: id,
          category: part.category,
          description: part.description,
          cost: part.cost,
          pricePerPart: value,
          extPrice: 0,
          uniqueId: uuidv4(),
        });
        setPartsToAdd(tmp);
      }
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const changePartQuantity = (e) => {
    let tmp = partsToAdd;
    let { id, value } = e.target;
    if (value !== "0" || value !== "") {
      setDummyLoading(true);
      let part = partsData.find((pt) => pt.partId === id);
      let existing = _.find(tmp, (p) => p.partId === id);
      if (existing && existing !== undefined) {
        existing.quantity = value;
        existing.extPrice = parseFloat((existing.pricePerPart * value).toFixed(2));
        let index = _.findIndex(tmp, (p) => p.uniqueId === existing.uniqueId);
        tmp[index] = existing;
        setPartsToAdd(tmp);
      } else {
        tmp.push({
          quantity: value,
          partNo: part.partNo,
          partId: id,
          category: part.category,
          description: part.description,
          cost: part.cost,
          pricePerPart: parseFloat((part.cost + part.cost * (invoice.partsMarkup / 100)).toFixed(2)),
          extPrice: parseFloat(((part.cost + part.cost * (invoice.partsMarkup / 100)) * value).toFixed(2)),
          uniqueId: uuidv4(),
        });
        setPartsToAdd(tmp);
      }
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const addNewParts = () => {
    setDummyLoading(true);
    let tmp = invoice;
    for (let i = 0; i < partsToAdd.length; i++) {
      if (partsToAdd[i].quantity !== "0" || partsToAdd[i].quantity !== "" || partsToAdd[i].quantity !== 0) {
        tmp.parts.push(partsToAdd[i]);
      }
    }
    setInvoice(tmp);
    closePartsModal();
    setTimeout(() => setDummyLoading(false), 700);
  };

  const onPartSearch = () => {
    if (search === "") {
      setPartsData(rawPartsData);
    } else {
      let tmp = rawPartsData.filter(
        (pt) => pt.partNo.toLowerCase().includes(search.toLowerCase()) || pt.description.toLowerCase().includes(search.toLowerCase()),
      );
      if (tmp.length === 0) {
        toast.error("No parts found matching your search");
        setPartsData(rawPartsData);
      } else {
        setPartsData(tmp);
      }
    }
  };

  let addPartsColumns = [
    {
      title: "Department",
      dataIndex: "department",
      width: 120,
      fixed: "left",
      render: (r) => departments.find((d) => d.departmentId === r)?.name,
    },
    {
      title: "Category",
      dataIndex: "category",
      width: 120,
      fixed: "left",
      render: (r) => r.toUpperCase(),
    },
    {
      title: "Part No.",
      dataIndex: "partNo",
      width: 120,
      fixed: "left",
    },
    {
      title: "Description",
      dataIndex: "description",
      fixed: "left",
      render: (r) => (r.length > 40 ? r.substring(0, 40) + "..." : r),
    },
    {
      title: "Stock",
      dataIndex: "stock",
      width: 120,
      fixed: "left",
      render: (r) => renderQtyInStock(r),
    },
    {
      title: "Cost",
      dataIndex: "cost",
      width: 120,
      fixed: "left",
      render: (r) => formatCurrency(r),
    },
    {
      title: "Ext. Price",
      dataIndex: "partId",
      width: 120,
      fixed: "left",
      render: (r) => renderAddPartPriceChange(r),
    },
    {
      title: "Quantity",
      dataIndex: "partId",
      width: 100,
      fixed: "left",
      render: (r) => renderAddPartQuantityChange(r),
    },
  ];

  const renderAddPartQuantityChange = (partId) => {
    let pt = partsData.find((pt) => pt.partId === partId);
    let defVal = 0;
    let find = partsToAdd.find((p) => p.partId === partId);
    if (find) {
      defVal = find.quantity;
    }
    if (pt && !dummyLoading) {
      return (
        <input
          className="w-[80px] rounded-md py-1 px-3 text-gray-900 border border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          type="number"
          id={partId}
          defaultValue={defVal}
          onBlur={changePartQuantity}
          min={1}
        />
      );
    } else {
      return (
        <input
          className="w-[80px] rounded-md py-1 px-3 text-gray-900 border border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          type="text"
          id="LOADING"
          defaultValue={"LOADING"}
          disabled={true}
        />
      );
    }
  };

  const renderAddPartPriceChange = (partId) => {
    let pt = partsData.find((pt) => pt.partId === partId);
    let markup = 45;
    if (invoice.partsMarkup) {
      markup = invoice.partsMarkup;
    }
    let defVal = parseFloat((pt.cost + pt.cost * (markup / 100)).toFixed(2));
    let find = partsToAdd.find((p) => p.partId === partId);
    if (find) {
      defVal = find.pricePerPart;
    }
    if (pt && !dummyLoading) {
      return (
        <input
          className="w-[80px] rounded-md py-1 px-3 text-gray-900 border border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          type="number"
          id={pt.partId}
          defaultValue={defVal}
          onBlur={changePartPrice}
          min={1}
        />
      );
    } else {
      return (
        <input
          className="w-[80px] rounded-md py-1 px-3 text-gray-900 border border-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          type="text"
          id="LOADING"
          defaultValue={"LOADING"}
          disabled={true}
        />
      );
    }
  };

  const renderAddPartsModal = () => {
    return (
      <Transition.Root show={addPartsModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closePartsModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:px-6 h-[800px]">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
                        Add Parts to Invoice
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 my-2">
                        <div className="flex flex-row items-center justify-end w-full gap-2">
                          <input
                            type="search"
                            name="search"
                            id="search"
                            className="block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="Search Parts"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <SecondaryButton label="Search" callback={() => onPartSearch()} />
                        </div>
                        <Table columns={addPartsColumns} dataSource={partsData} size="small" pagination={{ showSizeChanger: false }} className="w-full" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closePartsModal()} />
                    <PrimaryButton label="Add Parts" callback={() => addNewParts()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderQtyInStock = (pt) => {
    if (pt.stock && pt.stock.length > 0) {
      let stock = pt.stock.find((st) => st.location === invoice.locationId);
      if (stock) {
        return stock.quantityInStock;
      } else {
        return "0";
      }
    } else {
      return "0";
    }
  };

  const closePartEdit = () => {
    setEditPartModal(false);
    setEditPartData({
      quantity: 0,
      partNo: "",
      partId: "",
      category: "",
      description: "",
      cost: 0,
      pricePerPart: 0,
      extPrice: 0,
      uniqueId: "",
    });
  };

  const submitPartEdit = () => {
    setDummyLoading(true);
    let tmp = invoice;
    let lbrIndex = _.findIndex(tmp.parts, { uniqueId: editPartData.uniqueId });
    let toAdd = editPartData;
    tmp.parts[lbrIndex] = toAdd;
    setInvoice(tmp);
    closePartEdit();
    setTimeout(() => setDummyLoading(false), 700);
  };

  const changeEditPartData = (e) => {
    setDummyLoading(true);
    let { id, value } = e.target;
    value = parseFloat(value);
    let tmp = editPartData;
    tmp[id] = value;
    tmp.extPrice = parseFloat((tmp.quantity * tmp.pricePerPart).toFixed(2));
    setEditPartData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderPartEditModal = () => {
    return (
      <Transition.Root show={editPartModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closePartEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Part
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div className="flex flex-row items-center justify-between w-full pt-3 mt-3 mb-1 border-t border-gray-300">
                          <p className="text-sm font-semibold uppercase">Part No.</p>
                          <p className="uppercase">{editPartData.partNo}</p>
                        </div>
                        <div className="flex flex-row items-center justify-between w-full mb-1">
                          <p className="text-sm font-semibold uppercase">Category</p>
                          <p className="uppercase">{editPartData.category}</p>
                        </div>
                        <div className="flex flex-row items-center justify-between w-full mb-1">
                          <p className="text-sm font-semibold uppercase">Description</p>
                          <p className="uppercase">{editPartData.description}</p>
                        </div>
                        <div className="flex flex-row items-center justify-between w-full mb-1">
                          <p className="text-sm font-semibold uppercase">Cost</p>
                          <p className="uppercase">{formatCurrency(editPartData.cost)}</p>
                        </div>
                        <div key="pricePerPart" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="pricePerPart" className="pb-1 text-xs text-gray-600 uppercase">
                            Price Per Part
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="pricePerPart"
                            defaultValue={editPartData.pricePerPart}
                            onChange={changeEditPartData}
                          />
                        </div>
                        <div key="quantity" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="quantity" className="pb-1 text-xs text-gray-600 uppercase">
                            Quantity
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="quantity"
                            defaultValue={editPartData.quantity}
                            onChange={changeEditPartData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closePartEdit()} />
                    <PrimaryButton label="Save" callback={() => submitPartEdit()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeParkingModal = () => {
    setAddParkingModal(false);
    setParkingData({
      parkingType: "",
      increment: "",
      startDate: "",
      endDate: "",
      unitCharge: 0,
      quantity: 0,
      totalCharge: 0,
      parkingId: "",
    });
  };

  const addNewParking = () => {
    let failed = false;
    if (parkingData.parkingType === "") {
      toast.error("Please select a fee type");
      failed = true;
    } else if (parkingData.increment === "") {
      toast.error("Please select a charge increment");
      failed = true;
    } else if (parkingData.startDate === "") {
      toast.error("Please select a start date");
      failed = true;
    } else if (parkingData.endDate === "") {
      toast.error("Please select an end date");
      failed = true;
    } else if (parkingData.unitCharge === 0) {
      toast.error("Please enter a unit charge");
      failed = true;
    } else if (parkingData.quantity === 0 || parkingData.totalCharge === 0) {
      toast.error("There is not enough quantity for the charge");
      failed = true;
    }
    if (!failed) {
      setDummyLoading(true);
      let tmp = invoice;
      let toAdd = parkingData;
      toAdd.parkingId = uuidv4();
      tmp.parking.push(toAdd);
      setInvoice(tmp);
      closeParkingModal();
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const changeParkingData = (e) => {
    let { id, value } = e.target;
    setDummyLoading(true);
    let tmp = parkingData;
    tmp[id] = value;
    if (tmp.startDate !== "" && tmp.endDate !== "" && tmp.increment !== "") {
      let start = dayjs(tmp.startDate);
      let end = dayjs(tmp.endDate);
      let diff = end.diff(start, "days");
      if (tmp.increment === "daily") {
        tmp.quantity = Math.ceil(diff);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "weekly") {
        tmp.quantity = Math.ceil(diff / 7);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "monthly") {
        tmp.quantity = Math.ceil(diff / 30);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "annually") {
        tmp.quantity = Math.ceil(diff / 365);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      }
    }
    setParkingData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const changeParkingStart = (date) => {
    let tmp = parkingData;
    setDummyLoading(true);
    tmp.startDate = date;
    if (tmp.startDate !== "" && tmp.endDate !== "" && tmp.increment !== "") {
      let start = dayjs(tmp.startDate);
      let end = dayjs(tmp.endDate);
      let diff = end.diff(start, "days");
      if (tmp.increment === "daily") {
        tmp.quantity = Math.ceil(diff);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "weekly") {
        tmp.quantity = Math.ceil(diff / 7);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "monthly") {
        tmp.quantity = Math.ceil(diff / 30);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "annually") {
        tmp.quantity = Math.ceil(diff / 365);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      }
    }
    setParkingData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const changeParkingEnd = (date) => {
    let tmp = parkingData;
    setDummyLoading(true);
    tmp.endDate = date;
    if (tmp.startDate !== "" && tmp.endDate !== "" && tmp.increment !== "") {
      let start = dayjs(tmp.startDate);
      let end = dayjs(tmp.endDate);
      let diff = end.diff(start, "days");
      if (tmp.increment === "daily") {
        tmp.quantity = Math.ceil(diff);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "weekly") {
        tmp.quantity = Math.ceil(diff / 7);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "monthly") {
        tmp.quantity = Math.ceil(diff / 30);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      } else if (tmp.increment === "annually") {
        tmp.quantity = Math.ceil(diff / 365);
        if (tmp.unitCharge !== 0) {
          tmp.totalCharge = parseFloat((tmp.unitCharge * tmp.quantity).toFixed(2));
        }
      }
    }
    setParkingData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderAddParkingModal = () => {
    return (
      <Transition.Root show={addParkingModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeParkingModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Parking/Storage Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="parkingType" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="parkingType" className="pb-1 text-xs text-gray-600 uppercase">
                            Fee Type
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="parkingType"
                            defaultValue=""
                            onChange={changeParkingData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="parking" value="parking">
                              Parking
                            </option>
                            <option key="storage" value="storage">
                              Storage
                            </option>
                          </select>
                        </div>
                        <div key="startDate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="startDate" className="pb-1 text-xs text-gray-600 uppercase">
                            Start Date
                          </label>
                          <DatePicker onChange={(date, dateString) => changeParkingStart(dateString)} style={{ width: "100%" }} format={"MM/DD/YYYY"} />
                        </div>
                        <div key="endDate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="endDate" className="pb-1 text-xs text-gray-600 uppercase">
                            End Date
                          </label>
                          <DatePicker onChange={(date, dateString) => changeParkingEnd(dateString)} style={{ width: "100%" }} format={"MM/DD/YYYY"} />
                        </div>
                        <div key="increment" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="increment" className="pb-1 text-xs text-gray-600 uppercase">
                            Charge increment
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="increment"
                            defaultValue=""
                            onChange={changeParkingData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="daily" value="daily">
                              Daily
                            </option>
                            <option key="weekly" value="weekly">
                              Weekly
                            </option>
                            <option key="monthly" value="monthly">
                              Monthly
                            </option>
                            <option key="annually" value="annually">
                              Annually
                            </option>
                          </select>
                        </div>
                        <div key="unitCharge" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="unitCharge" className="pb-1 text-xs text-gray-600 uppercase">
                            Unit Charge
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="unitCharge"
                            onChange={changeParkingData}
                          />
                        </div>
                        <div className="flex flex-col items-end justify-center w-full gap-1 px-1 py-2 border-gray-300 border-y">
                          <p className="text-sm uppercase">
                            Quantity: <span className="font-semibold">{parkingData.quantity}</span>
                          </p>
                          <p className="text-sm uppercase">
                            Total: <span className="font-semibold">{formatCurrency(parkingData.totalCharge)}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeParkingModal()} />
                    <PrimaryButton label="Add Parking/Storage Fee" callback={() => addNewParking()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeParkingEdit = () => {
    setEditParkingModal(false);
    setParkingData({
      parkingType: "",
      increment: "",
      startDate: "",
      endDate: "",
      unitCharge: 0,
      quantity: 0,
      totalCharge: 0,
      parkingId: "",
    });
  };

  const submitParkingEdit = () => {
    setDummyLoading(true);
    let tmp = invoice;
    let lbrIndex = _.findIndex(tmp.parking, {
      parkingId: parkingData.parkingId,
    });
    let toAdd = parkingData;
    tmp.parking[lbrIndex] = toAdd;
    setInvoice(tmp);
    closeParkingEdit();
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderParkingEditModal = () => {
    return (
      <Transition.Root show={editParkingModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeParkingEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Parking/Storage Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="parkingType" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="parkingType" className="pb-1 text-xs text-gray-600 uppercase">
                            Fee Type
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="parkingType"
                            defaultValue={parkingData.parkingType}
                            onChange={changeParkingData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="parking" value="parking">
                              Parking
                            </option>
                            <option key="storage" value="storage">
                              Storage
                            </option>
                          </select>
                        </div>
                        <div key="startDate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="startDate" className="pb-1 text-xs text-gray-600 uppercase">
                            Start Date
                          </label>
                          <DatePicker
                            onChange={(date, dateString) => changeParkingStart(dateString)}
                            defaultValue={dayjs(parkingData.startDate)}
                            style={{ width: "100%" }}
                            format={"MM/DD/YYYY"}
                          />
                        </div>
                        <div key="endDate" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="endDate" className="pb-1 text-xs text-gray-600 uppercase">
                            End Date
                          </label>
                          <DatePicker
                            onChange={(date, dateString) => changeParkingEnd(dateString)}
                            defaultValue={dayjs(parkingData.endDate)}
                            format={"MM/DD/YYYY"}
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div key="increment" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="increment" className="pb-1 text-xs text-gray-600 uppercase">
                            Charge increment
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="increment"
                            defaultValue={parkingData.increment}
                            onChange={changeParkingData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="daily" value="daily">
                              Daily
                            </option>
                            <option key="weekly" value="weekly">
                              Weekly
                            </option>
                            <option key="monthly" value="monthly">
                              Monthly
                            </option>
                            <option key="annually" value="annually">
                              Annually
                            </option>
                          </select>
                        </div>
                        <div key="unitCharge" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="unitCharge" className="pb-1 text-xs text-gray-600 uppercase">
                            Unit Charge
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="unitCharge"
                            defaultValue={parkingData.unitCharge}
                            onChange={changeParkingData}
                          />
                        </div>
                        <div className="flex flex-col items-end justify-center w-full gap-1 px-1 py-2 border-gray-300 border-y">
                          <p className="text-sm uppercase">
                            Quantity: <span className="font-semibold">{parkingData.quantity}</span>
                          </p>
                          <p className="text-sm uppercase">
                            Total: <span className="font-semibold">{formatCurrency(parkingData.totalCharge)}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeParkingEdit()} />
                    <PrimaryButton label="Save" callback={() => submitParkingEdit()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAddLoadbank = () => {
    setAddLoadbankModal(false);
    setLoadbankData({
      description: "",
      unitCharge: 0,
      loadbankId: "",
    });
  };

  const changeLoadbankData = (e) => {
    let tmp = loadbankData;
    let { id, value } = e.target;
    tmp[id] = value;
    setLoadbankData(tmp);
  };

  const addNewLoadbank = () => {
    let failed = false;
    if (loadbankData.description === "") {
      toast.error("Please enter a loadbank fee description");
      failed = true;
    } else if (loadbankData.unitCharge === 0) {
      toast.error("Please enter a unit charge");
      failed = true;
    }

    if (!failed) {
      setDummyLoading(true);
      let tmp = invoice;
      let toAdd = loadbankData;
      toAdd.loadbankId = uuidv4();
      tmp.loadbank.push(toAdd);
      setInvoice(tmp);
      closeAddLoadbank();
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const renderAddLoadbankModal = () => {
    return (
      <Transition.Root show={addLoadbankModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddLoadbank}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Loadbank Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="description"
                            onChange={changeLoadbankData}
                          />
                        </div>
                        <div key="unitCharge" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="unitCharge" className="pb-1 text-xs text-gray-600 uppercase">
                            Loadbank Fee
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="unitCharge"
                            onChange={changeLoadbankData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeAddLoadbank()} />
                    <PrimaryButton label="Add Loadbank Fee" callback={() => addNewLoadbank()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeLoadbankEdit = () => {
    setEditLoadbankModal(false);
    setLoadbankData({
      description: "",
      unitCharge: 0,
      loadbankId: "",
    });
  };

  const submitLoadbankEdit = () => {
    setDummyLoading(true);
    let tmp = invoice;
    let lbrIndex = _.findIndex(tmp.loadbank, {
      loadbankId: loadbankData.loadbankId,
    });
    let toAdd = loadbankData;
    tmp.loadbank[lbrIndex] = toAdd;
    setInvoice(tmp);
    closeLoadbankEdit();
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderLoadbankEditModal = () => {
    return (
      <Transition.Root show={editLoadbankModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeLoadbankEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Loadbank Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 mt-2">
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="description"
                            defaultValue={loadbankData.description}
                            onChange={changeLoadbankData}
                          />
                        </div>
                        <div key="unitCharge" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="unitCharge" className="pb-1 text-xs text-gray-600 uppercase">
                            Loadbank Fee
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="unitCharge"
                            defaultValue={loadbankData.unitCharge}
                            onChange={changeLoadbankData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeLoadbankEdit()} />
                    <PrimaryButton label="Save" callback={() => submitLoadbankEdit()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAddFreightModal = () => {
    setAddFreightModal(false);
    setFreightData({
      poNumber: "",
      freightCost: 0,
      freightMarkup: 0,
      freightChargeOut: 0,
      vendor: "",
      freightId: "",
    });
  };

  const addNewFreight = () => {
    let failed = false;
    if (freightData.poNumber === "") {
      toast.error("Please enter a PO Number");
      failed = true;
    } else if (freightData.vendor === "") {
      toast.error("Please enter a vendor");
      failed = true;
    } else if (freightData.freightCost === 0) {
      toast.error("Please enter a freight cost");
      failed = true;
    } else if (freightData.freightChargeOut === 0) {
      toast.error("Please enter a freight charge out");
      failed = true;
    }
    if (!failed) {
      setDummyLoading(true);
      let tmp = invoice;
      let toAdd = freightData;
      toAdd.freightId = uuidv4();
      tmp.freight.push(toAdd);
      setInvoice(tmp);
      closeAddFreightModal();
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const changeFreightData = (e) => {
    setDummyLoading(true);
    let tmp = freightData;
    let { id, value } = e.target;
    if (id === "freightCost" || id === "freightMarkup" || id === "freightChargeOut") {
      value = parseFloat(value);
    }
    tmp[id] = value;
    if (id === "freightCost") {
      if (tmp.freightMarkup === 0) {
        tmp.freightChargeOut = tmp.freightCost;
        freightChargeOutRef.current.value = tmp.freightChargeOut;
      } else {
        tmp.freightChargeOut = parseFloat((tmp.freightCost + tmp.freightCost * (tmp.freightMarkup / 100)).toFixed(2));
        freightChargeOutRef.current.value = tmp.freightChargeOut;
      }
    }
    if (id === "freightMarkup") {
      if (tmp.freightCost !== 0) {
        tmp.freightChargeOut = parseFloat((tmp.freightCost + tmp.freightCost * (tmp.freightMarkup / 100)).toFixed(2));
        freightChargeOutRef.current.value = tmp.freightChargeOut;
      }
    }
    if (id === "freightChargeOut") {
      if (tmp.freightCost !== 0) {
        tmp.freightMarkup = parseFloat((((tmp.freightChargeOut - tmp.freightCost) / tmp.freightCost) * 100).toFixed(2));
        freightMarkupRef.current.value = tmp.freightMarkup;
      }
    }
    setFreightData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderAddFreightModal = () => {
    return (
      <Transition.Root show={addFreightModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddFreightModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Freight Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 py-2 mt-2">
                        <div key="poNumber" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="poNumber" className="pb-1 text-xs text-gray-600 uppercase">
                            PO Number
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="poNumber"
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="vendor" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="vendor" className="pb-1 text-xs text-gray-600 uppercase">
                            Vendor
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="vendor"
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightCost" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightCost" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Cost
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightCost"
                            ref={freightCostRef}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightMarkup" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightMarkup" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Markup
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightMarkup"
                            ref={freightMarkupRef}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightChargeOut" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightChargeOut" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Charge Out
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightChargeOut"
                            ref={freightChargeOutRef}
                            onChange={changeFreightData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeAddFreightModal()} />
                    <PrimaryButton label="Add Freight Fee" callback={() => addNewFreight()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeFreightEdit = () => {
    setEditFreightModal(false);
    setFreightData({
      poNumber: "",
      freightCost: 0,
      freightMarkup: 0,
      freightChargeOut: 0,
      vendor: "",
      freightId: "",
    });
  };

  const submitFreightEdit = () => {
    setDummyLoading(true);
    let tmp = invoice;
    let lbrIndex = _.findIndex(tmp.freight, {
      freightId: freightData.freightId,
    });
    let toAdd = freightData;
    tmp.freight[lbrIndex] = toAdd;
    setInvoice(tmp);
    closeFreightEdit();
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderFreightEditModal = () => {
    return (
      <Transition.Root show={editFreightModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeFreightEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Freight Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 py-2 mt-2">
                        <div key="poNumber" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="poNumber" className="pb-1 text-xs text-gray-600 uppercase">
                            PO Number
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="poNumber"
                            defaultValue={freightData.poNumber}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="vendor" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="vendor" className="pb-1 text-xs text-gray-600 uppercase">
                            Vendor
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="vendor"
                            defaultValue={freightData.vendor}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightCost" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightCost" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Cost
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightCost"
                            ref={freightCostRef}
                            defaultValue={freightData.freightCost}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightMarkup" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightMarkup" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Markup
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightMarkup"
                            ref={freightMarkupRef}
                            defaultValue={freightData.freightMarkup}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightChargeOut" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightChargeOut" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Charge Out
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightChargeOut"
                            ref={freightChargeOutRef}
                            defaultValue={freightData.freightChargeOut}
                            onChange={changeFreightData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeFreightEdit()} />
                    <PrimaryButton label="Save" callback={() => submitFreightEdit()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAddMiscModal = () => {
    setAddMiscModal(false);
    setMiscData({
      miscType: "",
      partNo: "",
      description: "",
      quantity: 0,
      chargeOutPrice: 0,
      miscId: "",
    });
  };

  const addNewMisc = () => {
    let failed = false;

    if (miscData.miscType === "") {
      toast.error("Please select a fee type");
      failed = true;
    } else if (miscData.description === "") {
      toast.error("Please enter a description");
      failed = true;
    } else if (miscData.quantity === 0) {
      toast.error("Please enter a quantity");
      failed = true;
    } else if (miscData.chargeOutPrice === 0) {
      toast.error("Please enter a charge out price");
      failed = true;
    }

    if (!failed) {
      setDummyLoading(true);
      let tmp = invoice;
      let toAdd = miscData;
      toAdd.miscId = uuidv4();
      tmp.misc.push(toAdd);
      setInvoice(tmp);
      closeAddMiscModal();
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const changeMiscData = (e) => {
    setDummyLoading(true);
    let { id, value } = e.target;
    let tmp = miscData;
    if (id === "quantity" || id === "chargeOutPrice") {
      value = parseFloat(value);
    }
    tmp[id] = value;
    setMiscData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderAddMiscModal = () => {
    return (
      <Transition.Root show={addMiscModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddMiscModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Misc Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 py-2 mt-2">
                        <div key="parkingType" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="parkingType" className="pb-1 text-xs text-gray-600 uppercase">
                            Fee Type
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="miscType"
                            defaultValue=""
                            onChange={changeMiscData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="service" value="service">
                              Service
                            </option>
                            <option key="part" value="part">
                              Part
                            </option>
                          </select>
                        </div>
                        <div key="partNo" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
                            Part No.
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="partNo"
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="description"
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="quantity" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="quantity" className="pb-1 text-xs text-gray-600 uppercase">
                            Quantity
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="quantity"
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="chargeOutPrice" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="chargeOutPrice" className="pb-1 text-xs text-gray-600 uppercase">
                            Charge Out Price
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="chargeOutPrice"
                            onChange={changeMiscData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeAddMiscModal()} />
                    <PrimaryButton label="Add Misc Fee" callback={() => addNewMisc()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeMiscEdit = () => {
    setEditMiscModal(false);
    setMiscData({
      miscType: "",
      partNo: "",
      description: "",
      quantity: 0,
      chargeOutPrice: 0,
      miscId: "",
    });
  };

  const submitMiscEdit = () => {
    setDummyLoading(true);
    let tmp = invoice;
    let lbrIndex = _.findIndex(tmp.misc, {
      miscId: miscData.miscId,
    });
    let toAdd = miscData;
    tmp.misc[lbrIndex] = toAdd;
    setInvoice(tmp);
    closeMiscEdit();
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderMiscEditModal = () => {
    return (
      <Transition.Root show={editMiscModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeMiscEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Misc Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 py-2 mt-2">
                        <div key="parkingType" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="parkingType" className="pb-1 text-xs text-gray-600 uppercase">
                            Fee Type
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="miscType"
                            defaultValue={miscData.miscType}
                            onChange={changeMiscData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="service" value="service">
                              Service
                            </option>
                            <option key="part" value="part">
                              Part
                            </option>
                          </select>
                        </div>
                        <div key="partNo" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
                            Part No.
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="partNo"
                            defaultValue={miscData.partNo}
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="description"
                            defaultValue={miscData.description}
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="quantity" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="quantity" className="pb-1 text-xs text-gray-600 uppercase">
                            Quantity
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="quantity"
                            defaultValue={miscData.quantity}
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="chargeOutPrice" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="chargeOutPrice" className="pb-1 text-xs text-gray-600 uppercase">
                            Charge Out Price
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="chargeOutPrice"
                            defaultValue={miscData.chargeOutPrice}
                            onChange={changeMiscData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeMiscEdit()} />
                    <PrimaryButton label="Save" callback={() => submitMiscEdit()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderVoidInvoiceModal = () => {
    return (
      <Transition.Root show={voidModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setVoidModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div className="flex flex-col items-start justify-start w-full gap-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      VOID Invoice
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Once this invoice is voided, it cannot be undone. Please make sure you want to void this invoice before proceeding. You can either
                        completely VOID the invoice, or decide to reopen the job it's related to, when applicable.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="VOID" callback={() => voidInvoice(false)} />
                    <SecondaryButton label="VOID and Reopen Job" callback={() => voidInvoice(true)} />
                    <PrimaryButton label="CANCEL" callback={() => setVoidModal(false)} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderShopFeeModal = () => {
    return (
      <Modal open={shopFeeModal} onCancel={closeShopFeeModal} title="Update Shop Fee" destroyOnClose centered width={750} onOk={() => updateShopFee()}>
        <div className="w-full py-4">
          <div className="w-full">
            <label htmlFor="shopFee" className="block text-sm font-medium leading-6 text-gray-900">
              Shop Fee - Fixed Amount
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <InputNumber
                placeholder="Enter the shop fee amount"
                name="shopFee"
                onChange={(v) => setShopFeeVal(v)}
                value={shopFeeVal}
                className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                disabled={usePercentage}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <span className="text-gray-500 sm:text-sm" id="shopFee-currency">
                  USD
                </span>
              </div>
            </div>
            <div className="flex items-center justify-end w-full mt-2">
              <SecondaryButton label="Set to 3%" callback={() => setShopFeeVal(calculateShopFee())} />
            </div>
          </div>
          <div className="flex items-center justify-between w-full py-4 mt-2 border-gray-200 border-y">
            <p className="block text-sm font-medium leading-6 text-gray-900">Use Percentage</p>
            <Switch onChange={(v) => setUsePercentage(v)} />
          </div>
          <div className="w-full mt-2">
            <label htmlFor="shopFee" className="block text-sm font-medium leading-6 text-gray-900">
              Shop Fee - % of Subtotal
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <InputNumber
                placeholder="Enter the desired percentage"
                name="shopFee"
                onChange={(v) => calculatePercentage(v)}
                className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                controls={false}
                disabled={!usePercentage}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <span className="text-gray-500 sm:text-sm" id="shopFee-currency">
                  %
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const calculatePercentage = (percent) => {
    let subtotal = 0;
    subtotal += calculateLabor();
    subtotal += calculateParts();
    subtotal += calculateGOG();
    subtotal += calculateFreight();
    subtotal += calculateServices();
    subtotal += calculateMisc();
    setShopFeeVal(parseFloat(((subtotal * percent) / 100).toFixed(2)));
  };

  const updateShopFee = () => {
    if (!loading) {
      let valid = parseFloat(shopFeeVal);
      if (!isNaN(valid)) {
        setDummyLoading(true);
        let tmp = invoice;
        tmp.shopFeeTotal = parseFloat(shopFeeVal);
        setInvoice(tmp);
        setTimeout(() => setDummyLoading(false), 700);
        closeShopFeeModal();
        let subtotal = 0;
        subtotal += calculateLabor();
        subtotal += calculateParts();
        subtotal += calculateGOG();
        subtotal += calculateFreight();
        subtotal += calculateServices();
        subtotal += calculateMisc();
        subtotal += valid;
        setShopFeeVal(valid);
        let zipVal = getValues("taxZip");
        let taxStatus = getValues("taxStatus");
        if (taxStatus === "taxable" && zipVal?.length > 0) {
          setDummyLoading(true);
          if (dayjs(invoice.invoiceDate).isBefore(dayjs("04/01/2024", "MM/DD/YYYY"))) {
            let richmond = [
              "30805",
              "30812",
              "30815",
              "30901",
              "30903",
              "30904",
              "30905",
              "30906",
              "30909",
              "30912",
              "30914",
              "30916",
              "30917",
              "30919",
              "30999",
            ];
            if (richmond.includes(zipVal)) {
              let taxAmount = getTaxableAmount() * 0.08;
              setTaxAmount(taxAmount);
              setTimeout(() => setDummyLoading(false), 300);
            } else {
              CalculateTax({
                taxZip: zipVal,
                subtotal: getTaxableAmount(),
                invoiceDate: invoice.invoiceDate,
              })
                .then((res) => {
                  setTaxAmount(res.data.tax);
                  setTimeout(() => setDummyLoading(false), 300);
                })
                .catch((err) => {
                  setDummyLoading(false);
                });
            }
          } else {
            CalculateTax({
              taxZip: zipVal,
              subtotal: getTaxableAmount(),
              invoiceDate: invoice.invoiceDate,
            })
              .then((res) => {
                setTaxAmount(res.data.tax);
                setTimeout(() => setDummyLoading(false), 300);
              })
              .catch((err) => {
                setDummyLoading(false);
              });
          }
        }
      } else {
        toast.error("Please enter a valid shop fee amount.");
      }
    }
  };

  const calculateTax = async () => {
    try {
      let zipVal = getValues("taxZip");
      if (zipVal.length === 0) {
        if (invoice.locationId === "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f") {
          zipVal = "29841";
        } else {
          zipVal = "30906";
        }
      }
      if (dayjs(invoice.invoiceDate).isBefore(dayjs("04/01/2024", "MM/DD/YYYY"))) {
        let richmond = ["30805", "30812", "30815", "30901", "30903", "30904", "30905", "30906", "30909", "30912", "30914", "30916", "30917", "30919", "30999"];
        if (richmond.includes(zipVal)) {
          let taxAmount = getTaxableAmount() * 0.08;
          return taxAmount;
        } else {
          let res = await CalculateTax({
            taxZip: zipVal,
            subtotal: getTaxableAmount(),
            invoiceDate: invoice.invoiceDate,
          });
          return res.data.tax;
        }
      } else {
        let res = await CalculateTax({
          taxZip: zipVal,
          subtotal: getTaxableAmount(),
          invoiceDate: invoice.invoiceDate,
        });
        return res.data.tax;
      }
    } catch (err) {
      console.error(err);
      return 0;
    }
  };

  const getTaxableAmount = () => {
    let amount = 0;
    amount += calculateParts();
    amount += calculateGOG();
    amount += calculateFreight();
    if (invoice.shopFeeTotal && invoice.shopFeeTotal > 0) {
      amount += invoice.shopFeeTotal;
    }
    return amount;
  };

  const calculateShopFee = () => {
    if (invoice.shopFeeTotal && invoice.shopFeeTotal > 0) {
      return invoice.shopFeeTotal;
    } else {
      let amount = calculateLabor();
      let shopFee = parseFloat((amount * 0.03).toFixed(2));
      return shopFee || 0;
    }
  };

  const closeShopFeeModal = () => {
    setShopFeeModal(false);
    setShopFeeVal(0);
    setUsePercentage(false);
  };

  const voidInvoice = (reopenJob) => {
    setLoading(true);
    VoidInvoice(invoiceId, { reopenJob: reopenJob })
      .then((res) => {
        toast.success("Invoice voided successfully");
        if (reopenJob) {
          setTimeout(() => navigate(`/jobs/${invoice.jobId}`), 2500);
        } else {
          setTimeout(() => navigate(`/`), 2500);
        }
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Something went wrong, please try again.");
        setLoading(false);
      });
  };

  const printInvoice = () => {
    GetInvoiceForPrint(invoiceId)
      .then((res) => {
        let data = {
          invoice: res.data.invoice,
          customer: res.data.company,
          rates: res.data.rates,
        };
        let doc = GenerateInvoicePDF(data);
        doc.setProperties({
          title: `Invoice - ${invoice.invoiceNo}`,
          subject: `Invoice - ${invoice.invoiceNo}`,
          author: "Hypertek Solutions LLC",
          keywords: "",
          creator: "contact@hypertek.dev",
        });
        window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Something went wrong, please try again.");
      });
  };

  const saveChanges = async () => {
    let payload = invoice;
    trigger();
    if (isValid) {
      let data = getValues();
      payload.invoiceDate = data.invoiceDate;
      payload.dueDate = data.dueDate;
      payload.paymentTerms = data.paymentTerms;
      payload.customerPo = data.customerPo;
      payload.taxStatus = data.taxStatus;
      payload.taxZip = data.taxZip;
      payload.jobDescription = data.jobDescription;
      payload.jobDetails = data.jobDetails;
      payload.commission = data.commission;
      payload.tax = await calculateTax();
      // payload.shopFeeTotal = calculateShopFee();
      payload.shopFeeTotal = invoice.shopFeeTotal;
      setLoading(true);
      UpdateInvoice(invoiceId, payload)
        .then((res) => {
          toast.success("Invoice updated successfully");
          GetOneInvoice(invoiceId)
            .then((res) => {
              setSales(res.data.sales);
              setInvoice(res.data.invoice);
              setTaxAmount(res.data.invoice.tax);
              setShopFeeVal(res.data.invoice.shopFeeTotal);
              setPartsData(res.data.parts);
              setRawPartsData(res.data.parts);
              setRates(res.data.rates);
              setEmployees(res.data.employees);
              setEquipment(res.data.equipment);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error("Error loading invoice");
              setTimeout(() => {
                navigate("/invoices");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Something went wrong, please try again.");
          setLoading(false);
        });
    } else {
      toast.error("Please review all fields and correct any errors.");
    }
  };

  const submitInvoice = () => {
    setLoading(true);
    SubmitInvoice(invoiceId)
      .then((res) => {
        toast.success("Invoice finalized successfully");
        GetOneInvoice(invoiceId)
          .then((res) => {
            setInvoice(res.data.invoice);
            setSales(res.data.sales);
            setTaxAmount(res.data.invoice.tax);
            setShopFeeVal(res.data.invoice.shopFeeTotal);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error("Error loading invoice");
            setTimeout(() => {
              navigate("/invoices");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Something went wrong, please try again.");
        setLoading(false);
      });
  };

  const renderSendToCustomerModal = () => {
    return (
      <Transition.Root show={sendToCustomerModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeSendToCustomerModal}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                    <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Send Invoice to Customer</Dialog.Title>
                          <div className="flex items-center ml-3 h-7">
                            <button
                              type="button"
                              className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => closeSendToCustomerModal()}
                            >
                              <span className="sr-only">Close panel</span>
                              <Xmark className="w-6 h-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex flex-col items-start justify-between flex-1 h-full px-4 mt-6 sm:px-6">
                        <div className="flex flex-col items-start justify-start w-full gap-2">
                          <p className="text-sm">Please enter a phone, email address or both where you'd like to send this invoice</p>
                          <div className="w-full">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                              Email
                            </label>
                            <div className="mt-2">
                              <input
                                type="email"
                                name="email"
                                id="email"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                placeholder="jdoe@email.com"
                                onChange={(e) => setSendEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="w-full">
                            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                              Phone Number
                            </label>
                            <div className="mt-2">
                              <MaskedInput
                                mask="(000) 000-0000"
                                onChange={(e) => setSendPhone(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-end w-full gap-2">
                          <SecondaryButton label="Cancel" callback={() => closeSendToCustomerModal()} />
                          <PrimaryButton label="Send" callback={() => sendToCustomer()} />
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeSendToCustomerModal = () => {
    setSendToCustomerModal(false);
    setSendEmail("");
    setSendPhone("");
  };

  const sendToCustomer = () => {
    let emailValid = false;
    let phoneValid = false;
    if (sendEmail.length > 0 && validator.isEmail(sendEmail)) {
      emailValid = true;
    }
    if (sendPhone.length > 0 && validator.isMobilePhone(sendPhone)) {
      phoneValid = true;
    }
    if (!emailValid && !phoneValid) {
      toast.error("Please enter a valid email address or phone number");
    } else {
      let payload = {};
      if (emailValid) {
        payload.email = sendEmail;
      }
      if (phoneValid) {
        payload.phone = sendPhone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
      }
      setLoading(true);
      SendInvoice(invoiceId, payload)
        .then((res) => {
          toast.success("Invoice sent successfully");
          closeSendToCustomerModal();
          GetOneInvoice(invoiceId)
            .then((res) => {
              setInvoice(res.data.invoice);
              setTaxAmount(res.data.invoice.tax);
              setShopFeeVal(res.data.invoice.shopFeeTotal);
              setPartsData(res.data.parts);
              setRawPartsData(res.data.parts);
              setSales(res.data.sales);
              setRates(res.data.rates);
              setEmployees(res.data.employees);
              setEquipment(res.data.equipment);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Error loading invoice");
              setTimeout(() => {
                navigate("/invoices");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error sending invoice to customer.");
          setLoading(false);
        });
    }
  };

  const collectPayment = () => {
    navigate(`/invoices/${invoiceId}/payment`);
  };

  const markPaid = (method) => {
    setPaymentMethod(method);
    setPaymentModal(true);
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
    setTimeout(() => {
      setPaymentData({
        paymentAmount: 0,
        checkNo: "",
        note: "",
        paymentDate: "",
        nameOnTheCheck: "",
        location: "",
      });
      setPaymentMethod(null);
    }, 500);
  };

  const submitPayment = () => {
    let valid = true;
    if (!paymentMethod) {
      valid = false;
      toast.error("Please select a payment method");
    } else if (!paymentData.paymentDate || !dayjs(paymentData.paymentDate).isValid()) {
      valid = false;
      toast.error("Please select a valid payment date");
    } else if (paymentData.paymentAmount <= 0) {
      valid = false;
      toast.error("Please enter a valid payment amount");
    } else if (!paymentData.location || paymentData.location.length < 1) {
      valid = false;
      toast.error("Please select a location the payment was collected at");
    }
    if (paymentMethod === "check" && valid) {
      if (paymentData.checkNo.length < 2) {
        valid = false;
        toast.error("Please enter a valid check number");
      } else if (paymentData.nameOnTheCheck.length < 2) {
        valid = false;
        toast.error("Please enter the name on the check");
      }
    }
    if (valid) {
      setLoading(true);
      MarkPaid(invoiceId, { ...paymentData, paymentMethod: paymentMethod })
        .then((res) => {
          toast.success("Invoice payment logged successfully");
          closePaymentModal();
          GetOneInvoice(invoiceId)
            .then((res) => {
              setInvoice(res.data.invoice);
              setTaxAmount(res.data.invoice.tax);
              setShopFeeVal(res.data.invoice.shopFeeTotal);
              setPartsData(res.data.parts);
              setRawPartsData(res.data.parts);
              setSales(res.data.sales);
              setRates(res.data.rates);
              setEmployees(res.data.employees);
              setEquipment(res.data.equipment);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Error loading invoice");
              setTimeout(() => {
                navigate("/invoices");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error marking invoice as paid.");
          setLoading(false);
        });
    }
  };

  const printReceipt = (saleId, location) => {
    ReprintReceipt({ saleId: saleId, terminal: location })
      .then((res) => {
        toast.success("Receipt printed successfully");
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error printing receipt");
      });
  };

  const reviseInvoice = () => {
    setLoading(true);
    ReviseInvoice(invoiceId)
      .then((res) => {
        toast.success("Invoice revised successfully!");
        GetOneInvoice(invoiceId)
          .then((res) => {
            setInvoice(res.data.invoice);
            setTaxAmount(res.data.invoice.tax);
            setShopFeeVal(res.data.invoice.shopFeeTotal);
            setPartsData(res.data.parts);
            setRawPartsData(res.data.parts);
            setRates(res.data.rates);
            setSales(res.data.sales);
            setEmployees(res.data.employees);
            setEquipment(res.data.equipment);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Error loading invoice");
            setTimeout(() => {
              navigate("/invoices");
            }, 3000);
          });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data ? err.response.data.message : "Error revising invoice");
      });
  };

  const leanUpdate = (e) => {
    let { id, value } = e.target;
    LeanUpdateInvoice(invoiceId, { [id]: value })
      .then((res) => {
        //
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error updating invoice");
      });
  };

  const renderPaymentModal = () => {
    return (
      <Modal
        open={paymentModal}
        destroyOnClose
        centered
        title="Log Invoice Payment"
        onCancel={() => closePaymentModal()}
        onOk={() => submitPayment()}
        okText="Submit"
        cancelText="Cancel"
        width={650}
      >
        <div className="flex flex-col items-center justify-between w-full gap-2 py-3">
          <div key="paymentDate" className="flex flex-col items-start justify-start w-full">
            <label htmlFor="paymentDate" className="pb-1 text-xs text-gray-600 uppercase">
              Payment Date
            </label>
            <DatePicker
              placeholder="Payment Date"
              name={"paymentDate"}
              format={"MM/DD/YYYY"}
              onChange={(date) => {
                updatePaymentInfo("paymentDate", date ? date.toJSON() : null);
              }}
              className="w-full"
            />
          </div>
          <div key="paymentAmount" className="flex flex-col items-start justify-start w-full">
            <label htmlFor="paymentAmount" className="pb-1 text-xs text-gray-600 uppercase">
              Payment Amount
            </label>
            <InputNumber
              placeholder="Enter the payment amount"
              name="paymentAmount"
              onChange={(v) => updatePaymentInfo("paymentAmount", v)}
              className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
              controls={false}
              min={0}
              addonBefore="$"
            />
          </div>
          <div key="note" className="flex flex-col items-start justify-start w-full">
            <label htmlFor="note" className="pb-1 text-xs text-gray-600 uppercase">
              Payment Note
            </label>
            <Input.TextArea
              placeholder="Enter any notes regarding this payment"
              name="note"
              onChange={(e) => {
                updatePaymentInfo("note", e.target.value);
              }}
              rows={4}
              defaultValue={paymentData.note}
              autoSize={false}
              className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
            />
          </div>
          {paymentMethod === "check" && (
            <div key="checkNo" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="checkNo" className="pb-1 text-xs text-gray-600 uppercase">
                Check No.
              </label>
              <Input
                placeholder="Check No."
                name="checkNo"
                onChange={(e) => {
                  updatePaymentInfo("checkNo", e.target.value);
                }}
                rows={4}
                defaultValue={paymentData.checkNo}
                autoSize={false}
                className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
              />
            </div>
          )}
          {paymentMethod === "check" && (
            <div key="nameOnTheCheck" className="flex flex-col items-start justify-start w-full">
              <label htmlFor="nameOnTheCheck" className="pb-1 text-xs text-gray-600 uppercase">
                Name on the check
              </label>
              <Input
                placeholder="Name on the check"
                name="nameOnTheCheck"
                onChange={(e) => {
                  updatePaymentInfo("nameOnTheCheck", e.target.value);
                }}
                rows={4}
                defaultValue={paymentData.nameOnTheCheck}
                autoSize={false}
                className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
              />
            </div>
          )}
          <div key="location" className="flex flex-col items-start justify-start w-full">
            <label htmlFor="location" className="pb-1 text-xs text-gray-600 uppercase">
              Location
            </label>
            <Select
              placeholder="Select a location"
              name="location"
              onChange={(e) => {
                updatePaymentInfo("location", e);
              }}
              defaultValue={paymentData.location.length > 3 ? paymentData.location : undefined}
              className="w-full"
              options={[
                { label: "Augusta, GA", value: "Augusta, GA" },
                { label: "North Augusta, GA", value: "North Augusta, GA" },
              ]}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const updatePaymentInfo = (field, value) => {
    let tmp = paymentData;
    tmp[field] = value;
    setPaymentData(tmp);
  };

  const renderCardFees = () => {
    return (
      <tr key={uuidv4()} className="border-t border-gray-300">
        <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Processing Fees:</td>
        <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(calculateCardFees())}</td>
      </tr>
    );
  };

  const calculateCardFees = () => {
    let paidWithCard = invoice.payments.filter((p) => p.paymentMethod === "card");
    let total = 0;
    for (let i = 0; i < paidWithCard.length; i++) {
      total += parseFloat(((paidWithCard[i].paymentAmount / 1.03) * 0.03).toFixed(2));
    }
    if (invoiceId === "585271b0-6614-4f48-8bc8-0eb70b7afca0") {
      total += 0.01;
    }
    return total;
  };

  const calcVisibleBalanceDue = () => {
    let salesRefunded = 0;
    for (let i = 0; i < sales.length; i++) {
      if (sales[i].refunds && sales[i].refunds.length > 0) {
        if (sales[i].refunds[0] && sales[i].refunds[0].status && sales[i].refunds[0].status === "succeeded") {
          salesRefunded += sales[i].refunds[0].amount;
        }
      }
    }
    let val = parseFloat((calculateTotal() + calculateCardFees()).toFixed(2));
    val = parseFloat((val - salesRefunded).toFixed(2));
    if (val < 0.1 && val > -0.1) {
      return 0;
    } else {
      return val;
    }
  };

  const calculateVisibleTotal = () => {
    return parseFloat((calculateSubtotal() + parseFloat(taxAmount) + calculateCardFees()).toFixed(2));
  };

  const revertToJob = () => {
    Modal.confirm({
      title: (
        <div className="flex items-center justify-start gap-1">
          <TriangleAlert className="w-auto h-5 text-red-600" />
          <p>Are you sure you want to revert this invoice?</p>
        </div>
      ),
      content:
        "This action cannot be undone. The invoice will be removed and the job will be reopened. Although we try our best to ensure all data is preserved, some data may be lost. If you decide to proceed we advise to save a copy of the invoice \n\nAre you sure you want to proceed?",
      okText: "Proceed",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      icon: null,
      onOk: (close) => {
        submitRevertToJob();
        return false;
      },
      onCancel: () => {},
      onClose: () => {},
      width: 550,
      mask: true,
      maskClosable: true,
    });
  };

  const submitRevertToJob = () => {
    RevertToJob(invoiceId)
      .then((res) => {
        toast.success("Invoice reverted successfully");
        Modal.destroyAll();
        navigate(`/jobs/${res.data.jobId}`);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error reverting invoice");
        Modal.destroyAll();
      });
  };

  const renderRefunds = () => {
    let refunds = [];
    for (let i = 0; i < sales.length; i++) {
      if (sales[i].refunds && sales[i].refunds.length > 0) {
        if (sales[i].refunds[0] && sales[i].refunds[0].status && sales[i].refunds[0].status === "succeeded") {
          let paymentMethod = invoice.payments.find((p) => p.saleId === sales[i].saleId);
          if (paymentMethod && paymentMethod.paymentMethod === "card") {
            paymentMethod = ` | ` + paymentMethod.note;
          }
          refunds.push({
            amount: sales[i].refunds[0].amount,
            date: dayjs(sales[i].refunds[0].date).format("MM/DD/YYYY"),
            note: paymentMethod,
            chargeId: sales[i].refunds[0].chargeId,
          });
        }
      }
    }
    if (refunds.length > 0) {
      return refunds.map((pmt) => (
        <div className="flex flex-row items-center justify-between w-full px-4 py-3 rounded-md bg-red-100/60">
          <p className="text-sm font-medium text-red-600 uppercase">
            [REFUND] {pmt.date}
            {pmt.note}
          </p>
          <div className="flex items-center justify-between gap-4">
            <SecondaryButton
              label="View in Stripe"
              callback={() => window.open(`https://dashboard.stripe.com/payments/${pmt.chargeId}`, "_blank")}
              className="text-red-900 hover:bg-red-50 ring-red-300 hover:ring-red-900 "
            />
            <p className="w-16 text-sm font-semibold text-right text-red-700 uppercase">{formatCurrency(pmt.amount)}</p>
          </div>
        </div>
      ));
    }
  };

  const renderPaymentString = (pmt) => {
    let string = `[${pmt.paymentMethod.toUpperCase()}]`;
    if (pmt.note && pmt.note.length > 1) {
      string += ` ${pmt.note}`;
    }
    string += ` | ${dayjs(pmt.paymentDate).format("MM/DD/YYYY")} `;
    if (pmt.paymentMethod === "check") {
      string += `| Check No. ${pmt.checkNo}`;
      if (pmt.nameOnTheCheck && pmt.nameOnTheCheck.length > 0) {
        string += ` | Name: ${pmt.nameOnTheCheck}`;
      }
    }
    if (pmt.location && pmt.location.length > 0) {
      string += ` | Loc: ${pmt.location}`;
    }
    return string;
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Invoice - {invoice.invoiceNo.replace("INV ", "").replace("HTJ-", "")} | HTPS ERP</title>
          </Helmet>
          {/* HEADING */}
          <div className="flex flex-row items-center justify-between w-full pb-3 text-sm border-b border-gray-300">
            <div className="flex flex-col items-start justify-center gap-2">
              <div className="flex items-center justify-start gap-3">
                <p className="text-base">
                  Invoice: <span className="font-semibold">{invoice.invoiceNo}</span>
                </p>
                {invoice.jobId && validate(invoice.jobId) && (
                  <button
                    className="px-2 py-1 duration-150 border border-gray-300 rounded-md hover:bg-gray-300 hover:border-black"
                    onClick={() => window.open(`/jobs/${invoice.jobId}`, "_blank")}
                  >
                    <div className="flex flex-row items-center justify-center gap-2">
                      <p className="text-xs font-medium uppercase">Open Job</p>
                      <OpenNewWindow className="w-3 h-3" />
                    </div>
                  </button>
                )}
              </div>
              <p className="text-base">
                Prepared for:{" "}
                <span className="font-semibold">
                  {invoice.customerCode}
                  {invoice.customerName.length > 0 ? " | " + invoice.customerName : ""}
                </span>
              </p>
              <p className="text-base">
                Location: <span className="font-semibold">{invoice.locationName}</span>
              </p>
            </div>
            <div className="flex flex-col items-end justify-center gap-2">
              <p>
                Invoice started on <span className="font-semibold">{dayjs(invoice.createdAt).format("MM/DD/YYYY")}</span>
              </p>
              <div className="flex flex-row items-center justify-end gap-2">
                <p>Status:</p>
                {renderInvoiceStatus()}
              </div>
              {invoice.warranty && invoice.warrantyInformation.customerInvoiceId && (
                <div className="flex flex-row items-center justify-end gap-2">
                  <p>Customer Invoice:</p>
                  <button
                    className="px-4 py-1 duration-150 border border-gray-300 rounded-md hover:bg-gray-300 hover:border-black"
                    onClick={() => navigate(`/invoices/${invoice.warrantyInformation.customerInvoiceId}`)}
                  >
                    <div className="flex flex-row items-center justify-center gap-2">
                      <p className="text-xs font-medium uppercase">View</p>
                      <OpenNewWindow className="w-3 h-3" />
                    </div>
                  </button>
                </div>
              )}
              {invoice.warranty && invoice.warrantyInformation.providerInvoiceId && (
                <div className="flex flex-row items-center justify-end gap-2">
                  <p>Warranty Provider Invoice:</p>
                  <button
                    className="px-4 py-1 duration-150 border border-gray-300 rounded-md hover:bg-gray-300 hover:border-black"
                    onClick={() => navigate(`/invoices/${invoice.warrantyInformation.providerInvoiceId}`)}
                  >
                    <div className="flex flex-row items-center justify-center gap-2">
                      <p className="text-xs font-medium uppercase">View</p>
                      <OpenNewWindow className="w-3 h-3" />
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* TOP FIELDS */}
          <div className="flex flex-row items-center justify-between w-full gap-5 mt-5">{renderUpperForm()}</div>
          {/* EQUIPMENT */}
          <div className="flex flex-col items-start justify-start w-full gap-5 mt-5">
            <div className="flex flex-row items-center justify-between w-full pb-3 border-b border-gray-300">
              <p className="text-lg font-semibold">Equipment</p>
              {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                <button
                  onClick={() => setEquipmentDrawer(true)}
                  className="px-4 py-2 text-xs font-medium text-gray-800 uppercase duration-150 rounded-md bg-gray-300/75 hover:text-white hover:bg-gray-400"
                >
                  Manage Equipment
                </button>
              )}
            </div>
            {invoice.equipment.length === 0 ? (
              <div className="flex flex-row items-center justify-center w-full pb-3">
                <p className="text-sm font-semibold text-gray-700 uppercase">No Equipment Was Added to This Invoice</p>
              </div>
            ) : (
              <table className="w-full max-w-[95%] mx-auto divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      Type
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Equipment ID
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Equipment Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Equipment Make
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.equipment.map((eqId) => {
                    let eq = equipment.find((e) => e.equipmentId === eqId.equipmentId);
                    return (
                      <tr key={eq.equipmentId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-3">
                          {renderEquipType(eq.equipmentType || "other")}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {eq.customerEquipId && eq.customerEquipId.length > 0 ? eq.customerEquipId : "Not Provided"}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{renderEquipStatus(eq.details.status)}</td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{eq.details.make}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          {/* LABOR */}
          <div className="flex flex-col items-start justify-start w-full gap-5 mt-5">
            <div className="flex flex-row items-center justify-between w-full pb-3 border-b border-gray-300">
              <p className="text-lg font-semibold">Labor</p>
              {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                <button
                  onClick={() => setAddLaborModal(true)}
                  className="px-4 py-2 text-xs font-medium text-gray-800 uppercase duration-150 rounded-md bg-gray-300/75 hover:text-white hover:bg-gray-400"
                >
                  Add Labor
                </button>
              )}
            </div>
            {invoice.labor.length === 0 ? (
              <div className="flex flex-row items-center justify-center w-full pb-3">
                <p className="text-sm font-semibold text-gray-700 uppercase">No Labor Was Added to This Invoice</p>
              </div>
            ) : (
              <table className="w-full max-w-[95%] mx-auto divide-y divide-gray-300">
                <thead>
                  <tr>
                    {authState.user.functionCategory !== "technician" && (
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                        Rate
                      </th>
                    )}
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Description
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    {authState.user.functionCategory !== "technician" && (
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Quoted Hours
                      </th>
                    )}
                    {authState.user.functionCategory !== "technician" && (
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Hours Worked
                      </th>
                    )}
                    {authState.user.functionCategory !== "technician" && (
                      <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                        Per Hour
                      </th>
                    )}
                    <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                      Ext. Price
                    </th>
                    {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <span className="sr-only">Edit/Remove</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {invoice.labor.map((lbr) => (
                    <tr key={lbr.laborId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                      {authState.user.functionCategory !== "technician" && (
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-3">
                          {rates.find((rt) => rt.rateId === lbr.rate)?.laborCode}
                        </td>
                      )}
                      <td className="px-3 py-4 text-sm text-gray-500 truncate whitespace-nowrap">
                        {lbr.laborDescription.substring(0, 45)}
                        {lbr.laborDescription.length > 45 ? "..." : ""}
                      </td>
                      <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap">{renderLaborStatus(lbr.status)}</td>
                      {authState.user.functionCategory !== "technician" && (
                        <>
                          <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap">{lbr.quoted ? lbr.quoted.toFixed(2) : 0}</td>
                          <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap">
                            {lbr.billableTime ? lbr.billableTime.toFixed(2) : 0}
                          </td>
                          <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(lbr.perHour)}/hr</td>
                        </>
                      )}
                      <td className="px-3 py-4 text-sm text-right text-gray-500 whitespace-nowrap">{formatCurrency(lbr.quotedPrice)}</td>
                      {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <EditPencil
                            onClick={() => openLaborEdit(lbr.laborId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-blue-500"
                          />
                          <Trash onClick={() => removeLabor(lbr.laborId)} className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-red-500" />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {/* PARTS */}
          <div className="flex flex-col items-start justify-start w-full gap-5 mt-5">
            <div className="flex flex-row items-center justify-between w-full pb-3 border-b border-gray-300">
              <p className="text-lg font-semibold">Parts</p>
              {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                <button
                  onClick={() => openPartsModal()}
                  className="px-4 py-2 text-xs font-medium text-gray-800 uppercase duration-150 rounded-md bg-gray-300/75 hover:text-white hover:bg-gray-400"
                >
                  Add Parts
                </button>
              )}
            </div>
            {invoice.parts.length === 0 ? (
              <div className="flex flex-row items-center justify-center w-full pb-3">
                <p className="text-sm font-semibold text-gray-700 uppercase">No Parts Were Added to This Invoice</p>
              </div>
            ) : (
              <table className="w-full max-w-[95%] mx-auto divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      Description
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Part No.
                    </th>
                    {authState.user.functionCategory !== "technician" && (
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Cost
                      </th>
                    )}
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Quantity
                    </th>
                    {authState.user.functionCategory !== "technician" && (
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Ext. Price
                      </th>
                    )}
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Ext. Total
                    </th>
                    {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <span className="sr-only">Edit/Remove</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {invoice.parts.map((pt) => (
                    <tr key={pt.uniqueId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                      <td className="w-1/2 px-3 py-4 text-sm text-gray-500 whitespace-nowrap clip">
                        {pt.description.slice(0, 50)}
                        {pt.description.length > 50 && "..."}
                      </td>
                      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-3">{pt.partNo}</td>
                      {authState.user.functionCategory !== "technician" && (
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{formatCurrency(pt.cost)}</td>
                      )}
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pt.quantity}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{formatCurrency(pt.pricePerPart)}</td>
                      {authState.user.functionCategory !== "technician" && (
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{formatCurrency(pt.extPrice)}</td>
                      )}
                      {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <EditPencil
                            onClick={() => openPartEdit(pt.uniqueId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-blue-500"
                          />
                          <Trash onClick={() => removePart(pt.uniqueId)} className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-red-500" />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {/* MISC */}
          <div className="flex flex-col items-start justify-start w-full gap-5 mt-5">
            <div className="flex flex-row items-center justify-between w-full pb-3 border-b border-gray-300">
              <p className="text-lg font-semibold">Misc</p>
              {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex flex-row items-center justify-between px-4 py-2 text-xs font-medium text-gray-800 uppercase duration-150 rounded-md bg-gray-300/75 group-hover:text-white group-hover:bg-gray-400">
                      Add Misc Charges
                      <NavArrowDown className="w-5 h-5 ml-2 group" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-[1px] mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-1 py-1 ">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => setAddParkingModal(true)}
                              className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              Add Parking Charges
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => setAddLoadbankModal(true)}
                              className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              Add Loadbank Charges
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => setAddFreightModal(true)}
                              className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              Add Freight Charges
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => setAddMiscModal(true)}
                              className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              Add Misc Charges
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
            </div>
            {invoice.parking.length === 0 && invoice.loadbank.length === 0 && invoice.freight.length === 0 && invoice.misc.length === 0 ? (
              <div className="flex flex-row items-center justify-center w-full pb-3">
                <p className="text-sm font-semibold text-gray-700 uppercase">No Misc. Charges Added to This Invoice</p>
              </div>
            ) : (
              <table className="w-full max-w-[95%] mx-auto divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      Description
                    </th>
                    {authState.user.functionCategory !== "technician" && (
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Price
                      </th>
                    )}
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Quantity
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Ext. Price
                    </th>
                    {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <span className="sr-only">Edit/Remove</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {invoice.parking.map((pt) => (
                    <tr key={pt.parkingId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                      <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">
                        {pt.parkingType} | {pt.increment}
                      </td>
                      {authState.user.functionCategory !== "technician" && (
                        <td className="py-4 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-3">{formatCurrency(pt.unitCharge)}</td>
                      )}
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pt.quantity}</td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{formatCurrency(pt.totalCharge)}</td>
                      {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <EditPencil
                            onClick={() => openParkingEdit(pt.parkingId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-blue-500"
                          />
                          <Trash onClick={() => removeParking(pt.parkingId)} className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-red-500" />
                        </td>
                      )}
                    </tr>
                  ))}
                  {invoice.loadbank.map((pt) => (
                    <tr key={pt.loadbankId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                      <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">Loadbank Fee</td>
                      {authState.user.functionCategory !== "technician" && (
                        <td className="py-4 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-3">{formatCurrency(pt.unitCharge)}</td>
                      )}
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">1</td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{formatCurrency(pt.unitCharge)}</td>
                      {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <EditPencil
                            onClick={() => openLoadbankEdit(pt.loadbankId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-blue-500"
                          />
                          <Trash
                            onClick={() => removeLoadbank(pt.loadbankId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-red-500"
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                  {invoice.freight.map((pt) => (
                    <tr key={pt.freightId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                      <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">Freight | {pt.poNumber}</td>
                      {authState.user.functionCategory !== "technician" && (
                        <td className="py-4 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-3">{formatCurrency(pt.freightCost)}</td>
                      )}
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">1</td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{formatCurrency(pt.freightChargeOut)}</td>
                      {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <EditPencil
                            onClick={() => openFreightEdit(pt.freightId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-blue-500"
                          />
                          <Trash onClick={() => removeFreight(pt.freightId)} className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-red-500" />
                        </td>
                      )}
                    </tr>
                  ))}
                  {invoice.misc.map((pt) => (
                    <tr key={pt.miscId} className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
                      <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">Misc | {pt.miscType}</td>
                      {authState.user.functionCategory !== "technician" && (
                        <td className="py-4 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-3">{formatCurrency(pt.chargeOutPrice / pt.quantity)}</td>
                      )}
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{pt.quantity}</td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{formatCurrency(pt.chargeOutPrice)}</td>
                      {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                        <td className="flex flex-row items-center justify-end gap-3 px-3 py-4">
                          <EditPencil
                            onClick={() => openMiscEdit(pt.miscId)}
                            className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-blue-500"
                          />
                          <Trash onClick={() => removeMisc(pt.miscId)} className="w-6 h-6 text-gray-300 duration-150 cursor-pointer hover:text-red-500" />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {/* TOTALS */}
          <div className="flex flex-col items-start justify-between w-full gap-5 pb-10 mt-5 border-t border-gray-300 md:flex-row pt-7">
            {/* ACTION ITEMS */}
            <div className="flex flex-col items-start justify-start w-full gap-2 px-1 md:w-3/5">
              <p className="w-full pb-4 mb-3 font-semibold uppercase border-b border-gray-300">Invoice Actions</p>
              {invoice.invoiceStatus === "draft" && authState.user.functionCategory !== "technician" && (
                <>
                  <div className="flex flex-row flex-wrap items-center justify-end w-full gap-3">
                    {authState.user.functionCategory !== "technician" && (
                      <SecondaryButton
                        label="Revert to a Job"
                        callback={() => revertToJob()}
                        className="text-red-500 bg-red-50 ring-red-200 hover:bg-red-500 hover:text-white hover:ring-red-300"
                      />
                    )}
                    <SecondaryButton label="VOID Invoice" callback={() => setVoidModal(true)} />
                    <SecondaryButton label="Print Invoice" callback={() => printInvoice()} />
                    <SecondaryButton label="Save Changes" callback={() => saveChanges()} />
                    <PrimaryButton label="Finalize Invoice" callback={() => submitInvoice()} />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full gap-3 mt-3 border-t border-gray-200">
                    <p className="font-medium uppercase">Payments:</p>
                    {invoice.payments.map((pmt) => (
                      <div className="flex flex-row items-center justify-between w-full px-4 py-3 rounded-md bg-green-100/60">
                        <p className="text-sm font-medium text-green-600 uppercase">{renderPaymentString(pmt)}</p>
                        <div className="flex flex-row items-center justify-end gap-4">
                          <Popconfirm
                            placement="topRight"
                            title="Location"
                            description="Select a location where you'd like to print this receipt"
                            okText="Augusta, GA"
                            cancelText="North Augusta, SC"
                            icon={<Printer className="text-green-600" />}
                            onCancel={() => printReceipt(pmt.saleId, "tmr_FDp7QwhBsyuvKe")}
                            onConfirm={() => printReceipt(pmt.saleId, "tmr_FDp6LAZdjM5CBc")}
                          >
                            <button className="inline-flex items-center gap-x-1.5 rounded-md text-green-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm hover:bg-green-50 ring-1 rind-inset ring-green-300 disabled:hover:ring-green-400 hover:ring-green-900 transition-all duration-150 disabled:hover:cursor-not-allowed disabled:text-green-300 disabled:hover:text-green-400">
                              Print Receipt
                            </button>
                          </Popconfirm>
                          <p className="w-16 text-sm font-semibold text-right text-green-700 uppercase">{formatCurrency(pmt.paymentAmount)}</p>
                        </div>
                      </div>
                    ))}
                    {renderRefunds()}
                  </div>
                </>
              )}
              {invoice.invoiceStatus === "ready" && authState.user.functionCategory !== "technician" && (
                <>
                  <div className="flex flex-row flex-wrap items-center justify-end w-full gap-3">
                    {authState.user.functionCategory !== "technician" && (
                      <SecondaryButton
                        label="Revert to a Job"
                        callback={() => revertToJob()}
                        className="text-red-500 bg-red-50 ring-red-200 hover:bg-red-500 hover:text-white hover:ring-red-300"
                      />
                    )}
                    <SecondaryButton label="VOID Invoice" callback={() => setVoidModal(true)} />
                    <SecondaryButton label="Print Invoice" callback={() => printInvoice()} />
                    <SecondaryButton label="Send to Customer" callback={() => setSendToCustomerModal(true)} />
                    <SecondaryButton label="Collect Payment" callback={() => collectPayment()} />
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="w-full rounded-md bg-blue-600 px-4 py-1.5 text-xs font-semibold uppercase text-white hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 flex flex-row justify-center items-center g">
                          Mark as Paid
                          <NavArrowDown className="w-5 h-5 ml-2 -mr-1 text-blue-200 hover:text-blue-100" aria-hidden="true" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="px-1 py-1 ">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? "bg-blue-500 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                  onClick={() => markPaid("cash")}
                                >
                                  Paid in Cash
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? "bg-blue-500 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                  onClick={() => markPaid("check")}
                                >
                                  Paid with a Check
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? "bg-blue-500 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                  onClick={() => markPaid("card")}
                                >
                                  Paid with a Card
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? "bg-blue-500 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                  onClick={() => markPaid("other")}
                                >
                                  Paid using a different method
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="flex flex-col items-start justify-start w-full gap-3 mt-3 border-t border-gray-200">
                    <p className="font-medium uppercase">Payments:</p>
                    {invoice.payments.map((pmt) => (
                      <div className="flex flex-row items-center justify-between w-full px-4 py-3 rounded-md bg-green-100/60">
                        <p className="text-sm font-medium text-green-600 uppercase">{renderPaymentString(pmt)}</p>
                        <div className="flex flex-row items-center justify-end gap-4">
                          <Popconfirm
                            placement="topRight"
                            title="Location"
                            description="Select a location where you'd like to print this receipt"
                            okText="Augusta, GA"
                            cancelText="North Augusta, SC"
                            icon={<Printer className="text-green-600" />}
                            onCancel={() => printReceipt(pmt.saleId, "tmr_FDp7QwhBsyuvKe")}
                            onConfirm={() => printReceipt(pmt.saleId, "tmr_FDp6LAZdjM5CBc")}
                          >
                            <button className="inline-flex items-center gap-x-1.5 rounded-md text-green-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm hover:bg-green-50 ring-1 rind-inset ring-green-300 disabled:hover:ring-green-400 hover:ring-green-900 transition-all duration-150 disabled:hover:cursor-not-allowed disabled:text-green-300 disabled:hover:text-green-400">
                              Print Receipt
                            </button>
                          </Popconfirm>
                          <p className="w-16 text-sm font-semibold text-right text-green-700 uppercase">{formatCurrency(pmt.paymentAmount)}</p>
                        </div>
                      </div>
                    ))}
                    {renderRefunds()}
                  </div>
                </>
              )}
              {(invoice.invoiceStatus === "sent" || invoice.invoiceStatus === "overdue") && authState.user.functionCategory !== "technician" && (
                <>
                  <div className="flex flex-row flex-wrap items-center justify-end w-full gap-3">
                    {authState.user.functionCategory !== "technician" && (
                      <SecondaryButton
                        label="Revert to a Job"
                        callback={() => revertToJob()}
                        className="text-red-500 bg-red-50 ring-red-200 hover:bg-red-500 hover:text-white hover:ring-red-300"
                      />
                    )}
                    <SecondaryButton label="VOID Invoice" callback={() => setVoidModal(true)} />
                    <SecondaryButton label="Print Invoice" callback={() => printInvoice()} />
                    <SecondaryButton label="Send to Customer" callback={() => setSendToCustomerModal(true)} />
                    <SecondaryButton label="Collect Payment" callback={() => collectPayment()} />
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="w-full rounded-md bg-blue-600 px-4 py-1.5 text-xs font-semibold uppercase text-white hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 flex flex-row justify-center items-center g">
                          Log Collected Payment
                          <NavArrowDown className="w-5 h-5 ml-2 -mr-1 text-blue-200 hover:text-blue-100" aria-hidden="true" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="px-1 py-1 ">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? "bg-blue-500 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                  onClick={() => markPaid("cash")}
                                >
                                  Paid with Cash
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? "bg-blue-500 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                  onClick={() => markPaid("check")}
                                >
                                  Paid with a Check
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? "bg-blue-500 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                  onClick={() => markPaid("card")}
                                >
                                  Paid with a Card
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? "bg-blue-500 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                  onClick={() => markPaid("other")}
                                >
                                  Paid using a different method
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="flex flex-col items-start justify-start w-full gap-3 mt-3 border-t border-gray-200">
                    <p className="font-medium uppercase">Payments:</p>
                    {invoice.payments.map((pmt) => (
                      <div className="flex flex-row items-center justify-between w-full gap-2 px-4 py-3 rounded-md bg-green-100/60">
                        <p className="text-sm font-medium text-green-600 uppercase">{renderPaymentString(pmt)}</p>
                        <div className="flex flex-row items-center justify-end gap-4">
                          <Popconfirm
                            placement="topRight"
                            title="Location"
                            description="Select a location where you'd like to print this receipt"
                            okText="Augusta, GA"
                            cancelText="North Augusta, SC"
                            icon={<Printer className="text-green-600" />}
                            onCancel={() => printReceipt(pmt.saleId, "tmr_FDp7QwhBsyuvKe")}
                            onConfirm={() => printReceipt(pmt.saleId, "tmr_FDp6LAZdjM5CBc")}
                          >
                            <button className="inline-flex items-center gap-x-1.5 rounded-md text-green-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm hover:bg-green-50 ring-1 rind-inset ring-green-300 disabled:hover:ring-green-400 hover:ring-green-900 transition-all duration-150 disabled:hover:cursor-not-allowed disabled:text-green-300 disabled:hover:text-green-400">
                              Print Receipt
                            </button>
                          </Popconfirm>
                          <p className="w-16 text-sm font-semibold text-right text-green-700 uppercase">{formatCurrency(pmt.paymentAmount)}</p>
                        </div>
                      </div>
                    ))}
                    {renderRefunds()}
                  </div>
                </>
              )}
              {invoice.invoiceStatus === "paid" && authState.user.functionCategory !== "technician" && (
                <>
                  <div className="flex flex-row items-center justify-end w-full gap-3">
                    {authState.user.functionCategory !== "technician" && (
                      <SecondaryButton
                        label="Revert to a Job"
                        callback={() => revertToJob()}
                        className="text-red-500 bg-red-50 ring-red-200 hover:bg-red-500 hover:text-white hover:ring-red-300"
                      />
                    )}
                    <SecondaryButton label="Print Invoice" callback={() => printInvoice()} />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full gap-3 mt-3 border-t border-gray-200">
                    <p className="font-medium uppercase">Payments:</p>
                    {invoice.payments.map((pmt) => (
                      <div className="flex flex-row items-center justify-between w-full px-4 py-3 rounded-md bg-green-100/60">
                        <p className="text-sm font-medium text-green-600 uppercase">{renderPaymentString(pmt)}</p>
                        <div className="flex flex-row items-center justify-end gap-4">
                          <Popconfirm
                            placement="topRight"
                            title="Location"
                            description="Select a location where you'd like to print this receipt"
                            okText="Augusta, GA"
                            cancelText="North Augusta, SC"
                            icon={<Printer className="text-green-600" />}
                            onCancel={() => printReceipt(pmt.saleId, "tmr_FDp7QwhBsyuvKe")}
                            onConfirm={() => printReceipt(pmt.saleId, "tmr_FDp6LAZdjM5CBc")}
                          >
                            <button className="inline-flex items-center gap-x-1.5 rounded-md text-green-900 px-5 py-2 text-xs font-semibold uppercase shadow-sm hover:bg-green-50 ring-1 rind-inset ring-green-300 disabled:hover:ring-green-400 hover:ring-green-900 transition-all duration-150 disabled:hover:cursor-not-allowed disabled:text-green-300 disabled:hover:text-green-400">
                              Print Receipt
                            </button>
                          </Popconfirm>
                          <p className="w-16 text-sm font-semibold text-right text-green-700 uppercase">{formatCurrency(pmt.paymentAmount)}</p>
                        </div>
                      </div>
                    ))}
                    {renderRefunds()}
                  </div>
                </>
              )}
              {authState.user.functionCategory === "technician" && invoice.invoiceStatus === "paid" && (
                <div className="flex flex-row items-center justify-end w-full">
                  <SecondaryButton label="Print Invoice" callback={() => printInvoice()} />
                </div>
              )}
              {authState.user.functionCategory !== "technician" &&
                (invoice.invoiceStatus === "ready" ||
                  invoice.invoiceStatus === "sent" ||
                  (invoice.invoiceStatus === "overdue" && invoice.payments && invoice.payments.length === 0) ||
                  invoice.invoiceStatus === "void") && (
                  <div className="flex flex-row items-center justify-end w-full">
                    <SecondaryButton label="Revise Invoice" callback={() => reviseInvoice()} />
                  </div>
                )}
            </div>
            {/* TOTALS TABLE */}
            <div className="flex flex-col items-start justify-start w-full gap-2 md:w-2/5">
              <table className="min-w-full">
                <tbody>
                  {authState.user.functionCategory !== "technician" && (
                    <Fragment key="Line Items">
                      <tr className="border-t border-gray-500">
                        <th colSpan={5} scope="colgroup" className="px-2 py-2 text-sm font-semibold text-left text-gray-900 bg-gray-50">
                          Line Items
                        </th>
                      </tr>
                      <tr key={uuidv4()} className="border-t border-gray-500">
                        <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Labor:</td>
                        <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(calculateLabor())}</td>
                      </tr>
                      <tr key={uuidv4()} className="border-t border-gray-300">
                        <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Parts:</td>
                        <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(calculateParts())}</td>
                      </tr>
                      <tr key={uuidv4()} className="border-t border-gray-300">
                        <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Gas, Oil, Grease:</td>
                        <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(calculateGOG())}</td>
                      </tr>
                      <tr key={uuidv4()} className="border-t border-gray-300">
                        <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Freight:</td>
                        <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(calculateFreight())}</td>
                      </tr>
                      <tr key={uuidv4()} className="border-t border-gray-300">
                        <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Services:</td>
                        <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">
                          {formatCurrency(calculateServices())}
                        </td>
                      </tr>
                      <tr key={uuidv4()} className="border-t border-gray-300">
                        <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Misc. Charges:</td>
                        <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(calculateMisc())}</td>
                      </tr>
                      <tr key={uuidv4()} className="border-t border-gray-300">
                        <td className="flex flex-row items-center justify-start w-3/5 gap-2 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">
                          <p>Shop Fees:</p>
                          {invoice.invoiceStatus === "draft" && (
                            <button className="font-semibold text-blue-600 duration-150 hover:text-blue-950" onClick={() => setShopFeeModal(true)}>
                              EDIT
                            </button>
                          )}
                        </td>
                        <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">
                          {formatCurrency(invoice.shopFeeTotal)}
                        </td>
                      </tr>
                    </Fragment>
                  )}
                  <Fragment key="Totals">
                    <tr className="border-t border-gray-500">
                      <th colSpan={5} scope="colgroup" className="px-2 py-2 text-sm font-semibold text-left text-gray-900 bg-gray-50">
                        Totals
                      </th>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-500">
                      <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Subtotal:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(calculateSubtotal())}</td>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-300">
                      <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Tax:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(taxAmount)}</td>
                    </tr>
                    {invoice.payments && invoice.payments.length > 0 && renderCardFees()}
                    <tr key={uuidv4()} className="border-t border-gray-700">
                      <td className="w-3/5 px-2 py-2 text-sm font-bold text-gray-900 whitespace-nowrap">TOTAL:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-semibold text-right text-gray-900 whitespace-nowrap">
                        {formatCurrency(calculateVisibleTotal())}
                      </td>
                    </tr>
                    {invoice.payments && invoice.payments.length > 0 && (
                      <tr key={uuidv4()} className="border-t border-gray-700">
                        <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">PAYMENTS MADE:</td>
                        <td className="w-2/5 px-2 py-2 text-sm font-semibold text-right text-gray-900 whitespace-nowrap">
                          {formatCurrency(calculatePayments())}
                        </td>
                      </tr>
                    )}
                    <tr key={uuidv4()} className="border-t border-gray-700">
                      <td className="w-3/5 px-2 py-2 text-sm font-semibold text-gray-900 whitespace-nowrap">BALANCE DUE:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-semibold text-right text-gray-900 whitespace-nowrap">
                        {formatCurrency(calcVisibleBalanceDue())}
                      </td>
                    </tr>
                  </Fragment>
                </tbody>
              </table>
            </div>
          </div>
          {renderEquipmentDrawer()}
          {renderNewEquipmentModal()}
          {renderAddLaborModal()}
          {renderEditLaborModal()}
          {renderAddPartsModal()}
          {renderPartEditModal()}
          {renderAddParkingModal()}
          {renderParkingEditModal()}
          {renderAddLoadbankModal()}
          {renderLoadbankEditModal()}
          {renderAddFreightModal()}
          {renderFreightEditModal()}
          {renderAddMiscModal()}
          {renderMiscEditModal()}
          {renderVoidInvoiceModal()}
          {renderShopFeeModal()}
          {renderSendToCustomerModal()}
          {renderPaymentModal()}
        </>
      )}
    </div>
  );
};
export default OpenInvoice;
