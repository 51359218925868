import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { GetDailyReport, GetPayPeriods, GetTimesheet } from "../../../actions/reports";
import { GenerateCountSheet, GenerateDailyTimeReport, GenerateTimesheet } from "../../../data/pdf";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { SecondaryButton } from "../../../components/buttons";
import { Helmet } from "react-helmet-async";

const EmployeeTimesheets = () => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetPayPeriods()
        .then((res) => {
          let tmpPeriods = [];
          res.data.periods.forEach((period) => {
            tmpPeriods.push({
              label: period.periodName,
              value: period.periodId,
            });
          });
          setPeriods(tmpPeriods);
          let tmpEmployees = [];
          res.data.employees.forEach((employee) => {
            tmpEmployees.push({
              label: `${employee.firstName} ${employee.lastName}`,
              value: employee.userId,
            });
          });
          setEmployees(tmpEmployees);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data ? err.response.data.message : "Error retrieving data");
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const getTimesheet = () => {
    let toSend = {
      employee: selectedEmployee,
      period: selectedPeriod,
    };
    setLoading(true);
    GetTimesheet(toSend)
      .then((res) => {
        let doc = GenerateTimesheet(res.data);
        doc.setProperties({
          title: `Hi-Tech Power Systems | Timesheet - ${employees.find((e) => e.value === toSend.employee).label} - ${
            periods.find((p) => p.value === toSend.period).label
          }`,
          subject: `Hi-Tech Power Systems | Timesheet - ${employees.find((e) => e.value === toSend.employee).label} - ${
            periods.find((p) => p.value === toSend.period).label
          }`,
          author: "Hypertek Solutions LLC",
          keywords: "",
          creator: "contact@hypertek.dev",
        });
        window.open(doc.output("bloburl"), "_blank");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data ? err.response.data.message : "Error retrieving data");
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Employee Timesheets | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full h-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Employee Timesheets</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          {!loading ? (
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="text-sm uppercase text-gray-500/90">Employee Timesheets</p>
              <p className="text-sm text-gray-600/80">Please select the employee and the period date you wish to retrieve.</p>
              <div className="flex flex-col items-start justify-start w-full gap-4 mt-5">
                <div className="flex flex-row items-center justify-start gap-4">
                  <Select
                    placeholder="Select a Pay Period"
                    options={periods}
                    className="w-96"
                    onChange={(v) => setSelectedPeriod(v)}
                    defaultValue={selectedPeriod}
                  />
                  <Select
                    placeholder="Select an Employee"
                    options={employees}
                    className="w-96"
                    onChange={(v) => setSelectedEmployee(v)}
                    defaultValue={selectedEmployee}
                  />
                </div>
                <SecondaryButton label="Get Timesheet" callback={() => getTimesheet()} disabled={!selectedEmployee || !selectedPeriod} />
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full gap-1">
              <p className="text-sm uppercase text-gray-500/90">Loading</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeTimesheets;
