import { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { SecondaryButton } from "../../components/buttons";
import * as _ from "lodash";
import { GetData, SubmitLoadbankTestLog } from "../../actions/checklist";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import validator from "validator";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { loadbankTestLog, monthlyMaintenanceChecklist } from "../../data/checklistForms";
import FormController from "../../components/FormController";
import { Helmet } from "react-helmet-async";

const LoadbankTest = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  let [customers, setCustomers] = useState([]);
  let [employees, setEmployees] = useState([]);
  let [equipment, setEquipment] = useState([]);
  let [jobs, setJobs] = useState([]);
  let [data, setData] = useState({
    customerId: "",
    locationId: "",
    equipmentId: "",
    hours: 0,
    workOrder: "",
    date: "",
    technician: "",
    loadTests: [
      {
        time: "",
        ampsL1: "",
        ampsL2: "",
        ampsL3: "",
        volts: "",
        kW: "",
        pf: "",
        oilPsi: "",
        h2oTemp: "",
        hZ: "",
        ambAirTemp: "",
        percentOfLoad: "",
      },
      {
        time: "",
        ampsL1: "",
        ampsL2: "",
        ampsL3: "",
        volts: "",
        kW: "",
        pf: "",
        oilPsi: "",
        h2oTemp: "",
        hZ: "",
        ambAirTemp: "",
        percentOfLoad: "",
      },
      {
        time: "",
        ampsL1: "",
        ampsL2: "",
        ampsL3: "",
        volts: "",
        kW: "",
        pf: "",
        oilPsi: "",
        h2oTemp: "",
        hZ: "",
        ambAirTemp: "",
        percentOfLoad: "",
      },
      {
        time: "",
        ampsL1: "",
        ampsL2: "",
        ampsL3: "",
        volts: "",
        kW: "",
        pf: "",
        oilPsi: "",
        h2oTemp: "",
        hZ: "",
        ambAirTemp: "",
        percentOfLoad: "",
      },
      {
        time: "",
        ampsL1: "",
        ampsL2: "",
        ampsL3: "",
        volts: "",
        kW: "",
        pf: "",
        oilPsi: "",
        h2oTemp: "",
        hZ: "",
        ambAirTemp: "",
        percentOfLoad: "",
      },
      {
        time: "",
        ampsL1: "",
        ampsL2: "",
        ampsL3: "",
        volts: "",
        kW: "",
        pf: "",
        oilPsi: "",
        h2oTemp: "",
        hZ: "",
        ambAirTemp: "",
        percentOfLoad: "",
      },
      {
        time: "",
        ampsL1: "",
        ampsL2: "",
        ampsL3: "",
        volts: "",
        kW: "",
        pf: "",
        oilPsi: "",
        h2oTemp: "",
        hZ: "",
        ambAirTemp: "",
        percentOfLoad: "",
      },
      {
        time: "",
        ampsL1: "",
        ampsL2: "",
        ampsL3: "",
        volts: "",
        kW: "",
        pf: "",
        oilPsi: "",
        h2oTemp: "",
        hZ: "",
        ambAirTemp: "",
        percentOfLoad: "",
      },
      {
        time: "",
        ampsL1: "",
        ampsL2: "",
        ampsL3: "",
        volts: "",
        kW: "",
        pf: "",
        oilPsi: "",
        h2oTemp: "",
        hZ: "",
        ambAirTemp: "",
        percentOfLoad: "",
      },
      {
        time: "",
        ampsL1: "",
        ampsL2: "",
        ampsL3: "",
        volts: "",
        kW: "",
        pf: "",
        oilPsi: "",
        h2oTemp: "",
        hZ: "",
        ambAirTemp: "",
        percentOfLoad: "",
      },
      {
        time: "",
        ampsL1: "",
        ampsL2: "",
        ampsL3: "",
        volts: "",
        kW: "",
        pf: "",
        oilPsi: "",
        h2oTemp: "",
        hZ: "",
        ambAirTemp: "",
        percentOfLoad: "",
      },
    ],
    remarks: "",
  });

  const navigate = useNavigate();
  let location = useLocation();
  let [search, setSearch] = useSearchParams();

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetData()
        .then((res) => {
          setCustomers(res.data.customers);
          setEquipment(res.data.equipment);
          setEmployees(res.data.employees);
          setJobs(res.data.jobs);
          let customerId = search.get("customerId");
          let jobId = search.get("jobId");
          if (customerId && jobId) {
            let tmp = data;
            tmp.customerId = customerId;
            tmp.workOrder = jobId;
            setData(tmp);
            setValue("customerId", customerId);
            setValue("workOrder", jobId);
          }
          setTimeout(() => setLoading(false), 900);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading base checklist information");
          setTimeout(() => {
            navigate("/tests");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isDirty, dirtyFields, isValid },
    setValue,
    trigger,
    getValues,
    setError,
    watch,
    resetField,
    control,
  } = formMethods;

  const onSubmit = (values) => {
    trigger();
    if (isValid) {
      values.customerId = getValues("customerId");
      values.locationId = getValues("locationId");
      values.equipmentId = getValues("equipmentId");
      values.hours = getValues("hours");
      values.workOrder = getValues("workOrder");
      values.date = getValues("date");
      values.technician = getValues("technician");
      delete values.keepDefaults;
      delete values.formModified;
      delete values.modifiedFields;
      setLoading(true);
      SubmitLoadbankTestLog(values)
        .then((res) => {
          toast.success("Loadbank Test Log submitted successfully! You will be redirected shortly!");
          navigate(`/checklists/loadbank/${res.data.id}`);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error submitting test log");
          setLoading(false);
        });
    } else {
      toast.error("Please correct the highlighted fields");
    }
  };

  useEffect(() => {
    let subscription = watch((values, { name }) => {
      if (name === "customerId") {
        resetField("equipmentId");
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const renderEquipmentType = (type) => {
    switch (type) {
      case "generator":
        return "Generator";
      case "pressureWasher":
        return "Pressure Washer";
      case "truck":
        return "Truck";
      case "trailer":
        return "Trailer";
      case "welder":
        return "Welder";
      case "airCompressor":
        return "Air Compressor";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const equipmentOptions = () => {
    let customerId = getValues("customerId");
    if (customerId && customerId.length > 30) {
      let filtered = equipment.filter((eq) => eq.customerId === customerId);
      let organized = filtered.map((eq) => ({
        value: eq.equipmentId,
        label: `${renderEquipmentType(eq.equipmentType)}${eq.equipmentType === "other" && eq.otherType.length > 0 ? " - " + eq.otherType : ""}${
          eq.details.make && eq.details.make.length > 0 ? " | " + eq.details.make : " | Unknown Make"
        }${eq.details.model && eq.details.model.length > 0 ? " | " + eq.details.model : " | Unknown Model"}`,
      }));
      return organized;
    } else {
      return [];
    }
  };

  const jobOptions = () => {
    let customerId = getValues("customerId");
    if (customerId && customerId.length > 30) {
      let filtered = jobs.filter((eq) => eq.customerId === customerId);
      let organized = filtered.map((eq) => ({
        value: eq.jobId,
        label: `${eq.jobNo}`,
      }));
      return organized;
    } else {
      return [];
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>New Loadbank Test Log | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Loadbank Test Log</p>
              <SecondaryButton
                label="Cancel & Go back"
                callback={() => (location.key !== "default" ? navigate(-1) : navigate("/checklists", { replace: true }))}
              />
            </div>
            <div className="w-full flex-grow flex-col justify-start items-center overflow-y-auto h-full px-0.5 gap-5 mb-20">
              <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row items-center justify-between w-full gap-5" key="upperForm">
                <FormProvider {...formMethods}>
                  <div className="grid w-full grid-cols-2 gap-2 pt-3">
                    <div key="customerId" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="customerId" className="pb-1 text-xs text-gray-600 uppercase">
                        Customer
                      </label>
                      <Controller
                        control={control}
                        defaultValue={null}
                        name="customerId"
                        rules={{
                          required: "Please select the customer this checklist is for",
                          validate: (value) => value.length >= 3 || "Please select the customer this checklist is for",
                        }}
                        render={(props) => (
                          <Select
                            placeholder={"Select a customer"}
                            ref={props.field.ref}
                            name={"customerId"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            value={props.field.value}
                            options={customers.map((c) => ({
                              value: c.customerId,
                              label: `${c.customerCode}${c.company.length > 0 ? " | " + c.company : ""}`,
                            }))}
                            defaultValue={null}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="customerId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="locationId" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="locationId" className="pb-1 text-xs text-gray-600 uppercase">
                        Location
                      </label>
                      <Controller
                        control={control}
                        name={"locationId"}
                        rules={{ required: "Please select a location" }}
                        defaultValue={null}
                        render={(props) => (
                          <Select
                            placeholder={"Select a location"}
                            ref={props.field.ref}
                            name={"locationId"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            options={[
                              {
                                label: "Augusta, GA",
                                value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf",
                              },
                              {
                                label: "North Augusta, SC",
                                value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
                              },
                            ]}
                            defaultValue={null}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="locationId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="equipmentId" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="equipmentId" className="pb-1 text-xs text-gray-600 uppercase">
                        Equipment
                      </label>
                      <Controller
                        control={control}
                        defaultValue={null}
                        name="equipmentId"
                        rules={{
                          required: "Please select the piece of equipment this checklist is for",
                          validate: (value) => value.length >= 3 || "Please select the piece of equipment this checklist is for",
                        }}
                        disabled={getValues("customerId") && getValues("customerId").length > 30 ? false : true}
                        render={(props) => (
                          <Select
                            placeholder={"Select One"}
                            ref={props.field.ref}
                            name={"equipmentId"}
                            onBlur={props.field.onBlur}
                            value={props.field.value}
                            onChange={props.field.onChange}
                            options={equipmentOptions()}
                            disabled={getValues("customerId") && getValues("customerId").length > 30 ? false : true}
                            defaultValue={null}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="equipmentId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="hours" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="hours" className="pb-1 text-xs text-gray-600 uppercase">
                        Hours on Unit
                      </label>
                      <input
                        className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        type="number"
                        id="hours"
                        defaultValue={data.hours}
                        placeholder="Hours on Unit's meter"
                        {...register("hours", {
                          required: "Hours on Unit's meter is required",
                          validate: (value) => validator.isInt(value.toString()) || "Please enter the number of hours on the unit's meter",
                        })}
                      />
                      <ErrorMessage errors={errors} name="hours" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="workOrder" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="workOrder" className="pb-1 text-xs text-gray-600 uppercase">
                        Job / Work Order
                      </label>
                      <Controller
                        control={control}
                        defaultValue={null}
                        name="workOrder"
                        rules={{
                          required: "Please select the piece of equipment this checklist is for",
                          validate: (value) => value.length >= 3 || "Please select the piece of equipment this checklist is for",
                        }}
                        disabled={getValues("customerId") && getValues("customerId").length > 30 ? false : true}
                        render={(props) => (
                          <Select
                            placeholder={"Select One"}
                            ref={props.field.ref}
                            name={"workOrder"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            options={jobOptions()}
                            disabled={getValues("customerId") && getValues("customerId").length > 30 ? false : true}
                            defaultValue={null}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="workOrder" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="date" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="date" className="pb-1 text-xs text-gray-600 uppercase">
                        Test Date
                      </label>
                      <Controller
                        control={control}
                        name="date"
                        rules={{
                          required: false,
                          validate: (v) => dayjs(v).isValid() || "Date is required",
                        }}
                        defaultValue={data.date !== "" ? dayjs(data.date) : null}
                        render={({ field, fieldState }) => (
                          <DatePicker
                            placeholder="Test Date"
                            status={fieldState.error ? "error" : undefined}
                            ref={field.ref}
                            name={field.name}
                            format={"MM/DD/YYYY"}
                            onBlur={field.onBlur}
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(date) => {
                              field.onChange(date ? date.toJSON() : null);
                            }}
                            className="w-full mt-1 py-1.5"
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="date" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="technician" className="flex flex-col items-start justify-start w-full col-span-2">
                      <label htmlFor="technician" className="pb-1 text-xs text-gray-600 uppercase">
                        Technician
                      </label>
                      <Controller
                        control={control}
                        defaultValue={null}
                        name="technician"
                        rules={{
                          required: "Please select the technician that completed this checklist",
                          validate: (value) => value.length >= 3 || "Please select the technician that completed this checklist",
                        }}
                        render={(props) => (
                          <Select
                            placeholder={"Select One"}
                            ref={props.field.ref}
                            name={"technician"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            options={employees.map((e) => ({
                              value: e.userId,
                              label: `${e.employeeCode} | ${e.firstName} ${e.lastName}`,
                            }))}
                            defaultValue={null}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="technician" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div className="flex flex-col items-center justify-start w-full col-span-2 py-4 mt-5 border-gray-400 border-y">
                      <p className="text-sm font-semibold text-gray-500 uppercase">Enter the results of the test and your remarks below</p>
                      <div className="grid items-center justify-center w-full grid-cols-12 gap-2 pt-5 mt-5 border-t border-gray-300 gap-y-5">
                        <div key="loadTests[0].time" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[0].time" className="pb-1 text-xs text-gray-600 uppercase">
                            Time
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[0].time"
                            defaultValue={data.loadTests[0].time || ""}
                            placeholder="Time"
                            {...register("loadTests[0].time", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[0].time" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[0].ampsL1" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[0].ampsL1" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L1
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[0].ampsL1"
                            defaultValue={data.loadTests[0].ampsL1 || ""}
                            placeholder="AMPS L1"
                            {...register("loadTests[0].ampsL1", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[0].ampsL1" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[0].ampsL2" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[0].ampsL2" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L2
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[0].ampsL2"
                            defaultValue={data.loadTests[0].ampsL2 || ""}
                            placeholder="AMPS L2"
                            {...register("loadTests[0].ampsL2", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[0].ampsL2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[0].ampsL3" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[0].ampsL3" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L3
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[0].ampsL3"
                            defaultValue={data.loadTests[0].ampsL3 || ""}
                            placeholder="AMPS L3"
                            {...register("loadTests[0].ampsL3", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[0].ampsL3" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[0].volts" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[0].volts" className="pb-1 text-xs text-gray-600 uppercase">
                            Volts
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[0].volts"
                            defaultValue={data.loadTests[0].volts || ""}
                            placeholder="Volts"
                            {...register("loadTests[0].volts", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[0].volts" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[0].kW" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[0].kW" className="pb-1 text-xs text-gray-600 uppercase">
                            KW
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[0].kW"
                            defaultValue={data.loadTests[0].kW || ""}
                            placeholder="KW"
                            {...register("loadTests[0].kW", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[0].kW" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[0].pf" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[0].pf" className="pb-1 text-xs text-gray-600 uppercase">
                            PF
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[0].pf"
                            defaultValue={data.loadTests[0].pf || ""}
                            placeholder="PF"
                            {...register("loadTests[0].pf", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[0].pf" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[0].oilPsi" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[0].oilPsi" className="pb-1 text-xs text-gray-600 uppercase">
                            Oil PSI
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[0].oilPsi"
                            defaultValue={data.loadTests[0].oilPsi || ""}
                            placeholder="Oil PSI"
                            {...register("loadTests[0].oilPsi", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[0].oilPsi" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[0].h2oTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[0].h2oTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            H2O Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[0].h2oTemp"
                            defaultValue={data.loadTests[0].h2oTemp || ""}
                            placeholder="H2O Temp"
                            {...register("loadTests[0].h2oTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[0].h2oTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[0].hZ" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[0].hZ" className="pb-1 text-xs text-gray-600 uppercase">
                            Hz
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[0].hZ"
                            defaultValue={data.loadTests[0].hZ || ""}
                            placeholder="Hz"
                            {...register("loadTests[0].hZ", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[0].hZ" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[0].ambAirTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[0].ambAirTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            Amb Air Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[0].ambAirTemp"
                            defaultValue={data.loadTests[0].ambAirTemp || ""}
                            placeholder="Amb Air Temp"
                            {...register("loadTests[0].ambAirTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[0].ambAirTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[0].percentOfLoad" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[0].percentOfLoad" className="pb-1 text-xs text-gray-600 uppercase">
                            % of Load
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[0].percentOfLoad"
                            defaultValue={data.loadTests[0].percentOfLoad || ""}
                            placeholder="% of Load"
                            {...register("loadTests[0].percentOfLoad", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[0].percentOfLoad" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                      </div>
                      <div className="grid items-center justify-center w-full grid-cols-12 gap-2 pt-5 mt-5 border-t border-gray-300 gap-y-5">
                        <div key="loadTests[1].time" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[1].time" className="pb-1 text-xs text-gray-600 uppercase">
                            Time
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[1].time"
                            defaultValue={data.loadTests[1].time || ""}
                            placeholder="Time"
                            {...register("loadTests[1].time", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[1].time" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[1].ampsL1" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[1].ampsL1" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L1
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[1].ampsL1"
                            defaultValue={data.loadTests[1].ampsL1 || ""}
                            placeholder="AMPS L1"
                            {...register("loadTests[1].ampsL1", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[1].ampsL1" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[1].ampsL2" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[1].ampsL2" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L2
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[1].ampsL2"
                            defaultValue={data.loadTests[1].ampsL2 || ""}
                            placeholder="AMPS L2"
                            {...register("loadTests[1].ampsL2", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[1].ampsL2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[1].ampsL3" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[1].ampsL3" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L3
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[1].ampsL3"
                            defaultValue={data.loadTests[1].ampsL3 || ""}
                            placeholder="AMPS L3"
                            {...register("loadTests[1].ampsL3", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[1].ampsL3" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[1].volts" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[1].volts" className="pb-1 text-xs text-gray-600 uppercase">
                            Volts
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[1].volts"
                            defaultValue={data.loadTests[1].volts || ""}
                            placeholder="Volts"
                            {...register("loadTests[1].volts", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[1].volts" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[1].kW" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[1].kW" className="pb-1 text-xs text-gray-600 uppercase">
                            KW
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[1].kW"
                            defaultValue={data.loadTests[1].kW || ""}
                            placeholder="KW"
                            {...register("loadTests[1].kW", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[1].kW" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[1].pf" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[1].pf" className="pb-1 text-xs text-gray-600 uppercase">
                            PF
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[1].pf"
                            defaultValue={data.loadTests[1].pf || ""}
                            placeholder="PF"
                            {...register("loadTests[1].pf", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[1].pf" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[1].oilPsi" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[1].oilPsi" className="pb-1 text-xs text-gray-600 uppercase">
                            Oil PSI
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[1].oilPsi"
                            defaultValue={data.loadTests[1].oilPsi || ""}
                            placeholder="Oil PSI"
                            {...register("loadTests[1].oilPsi", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[1].oilPsi" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[1].h2oTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[1].h2oTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            H2O Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[1].h2oTemp"
                            defaultValue={data.loadTests[1].h2oTemp || ""}
                            placeholder="H2O Temp"
                            {...register("loadTests[1].h2oTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[1].h2oTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[1].hZ" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[1].hZ" className="pb-1 text-xs text-gray-600 uppercase">
                            Hz
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[1].hZ"
                            defaultValue={data.loadTests[1].hZ || ""}
                            placeholder="Hz"
                            {...register("loadTests[1].hZ", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[1].hZ" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[1].ambAirTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[1].ambAirTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            Amb Air Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[1].ambAirTemp"
                            defaultValue={data.loadTests[1].ambAirTemp || ""}
                            placeholder="Amb Air Temp"
                            {...register("loadTests[1].ambAirTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[1].ambAirTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[1].percentOfLoad" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[1].percentOfLoad" className="pb-1 text-xs text-gray-600 uppercase">
                            % of Load
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[1].percentOfLoad"
                            defaultValue={data.loadTests[1].percentOfLoad || ""}
                            placeholder="% of Load"
                            {...register("loadTests[1].percentOfLoad", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[1].percentOfLoad" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                      </div>
                      <div className="grid items-center justify-center w-full grid-cols-12 gap-2 pt-5 mt-5 border-t border-gray-300 gap-y-5">
                        <div key="loadTests[2].time" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[2].time" className="pb-1 text-xs text-gray-600 uppercase">
                            Time
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[2].time"
                            defaultValue={data.loadTests[2].time || ""}
                            placeholder="Time"
                            {...register("loadTests[2].time", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[2].time" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[2].ampsL1" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[2].ampsL1" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L1
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[2].ampsL1"
                            defaultValue={data.loadTests[2].ampsL1 || ""}
                            placeholder="AMPS L1"
                            {...register("loadTests[2].ampsL1", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[2].ampsL1" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[2].ampsL2" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[2].ampsL2" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L2
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[2].ampsL2"
                            defaultValue={data.loadTests[2].ampsL2 || ""}
                            placeholder="AMPS L2"
                            {...register("loadTests[2].ampsL2", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[2].ampsL2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[2].ampsL3" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[2].ampsL3" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L3
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[2].ampsL3"
                            defaultValue={data.loadTests[2].ampsL3 || ""}
                            placeholder="AMPS L3"
                            {...register("loadTests[2].ampsL3", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[2].ampsL3" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[2].volts" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[2].volts" className="pb-1 text-xs text-gray-600 uppercase">
                            Volts
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[2].volts"
                            defaultValue={data.loadTests[2].volts || ""}
                            placeholder="Volts"
                            {...register("loadTests[2].volts", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[2].volts" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[2].kW" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[2].kW" className="pb-1 text-xs text-gray-600 uppercase">
                            KW
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[2].kW"
                            defaultValue={data.loadTests[2].kW || ""}
                            placeholder="KW"
                            {...register("loadTests[2].kW", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[2].kW" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[2].pf" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[2].pf" className="pb-1 text-xs text-gray-600 uppercase">
                            PF
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[2].pf"
                            defaultValue={data.loadTests[2].pf || ""}
                            placeholder="PF"
                            {...register("loadTests[2].pf", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[2].pf" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[2].oilPsi" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[2].oilPsi" className="pb-1 text-xs text-gray-600 uppercase">
                            Oil PSI
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[2].oilPsi"
                            defaultValue={data.loadTests[2].oilPsi || ""}
                            placeholder="Oil PSI"
                            {...register("loadTests[2].oilPsi", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[2].oilPsi" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[2].h2oTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[2].h2oTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            H2O Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[2].h2oTemp"
                            defaultValue={data.loadTests[2].h2oTemp || ""}
                            placeholder="H2O Temp"
                            {...register("loadTests[2].h2oTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[2].h2oTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[2].hZ" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[2].hZ" className="pb-1 text-xs text-gray-600 uppercase">
                            Hz
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[2].hZ"
                            defaultValue={data.loadTests[2].hZ || ""}
                            placeholder="Hz"
                            {...register("loadTests[2].hZ", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[2].hZ" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[2].ambAirTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[2].ambAirTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            Amb Air Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[2].ambAirTemp"
                            defaultValue={data.loadTests[2].ambAirTemp || ""}
                            placeholder="Amb Air Temp"
                            {...register("loadTests[2].ambAirTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[2].ambAirTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[2].percentOfLoad" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[2].percentOfLoad" className="pb-1 text-xs text-gray-600 uppercase">
                            % of Load
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[2].percentOfLoad"
                            defaultValue={data.loadTests[2].percentOfLoad || ""}
                            placeholder="% of Load"
                            {...register("loadTests[2].percentOfLoad", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[2].percentOfLoad" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                      </div>
                      <div className="grid items-center justify-center w-full grid-cols-12 gap-2 pt-5 mt-5 border-t border-gray-300 gap-y-5">
                        <div key="loadTests[3].time" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[3].time" className="pb-1 text-xs text-gray-600 uppercase">
                            Time
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[3].time"
                            defaultValue={data.loadTests[3].time || ""}
                            placeholder="Time"
                            {...register("loadTests[3].time", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[3].time" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[3].ampsL1" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[3].ampsL1" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L1
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[3].ampsL1"
                            defaultValue={data.loadTests[3].ampsL1 || ""}
                            placeholder="AMPS L1"
                            {...register("loadTests[3].ampsL1", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[3].ampsL1" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[3].ampsL2" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[3].ampsL2" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L2
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[3].ampsL2"
                            defaultValue={data.loadTests[3].ampsL2 || ""}
                            placeholder="AMPS L2"
                            {...register("loadTests[3].ampsL2", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[3].ampsL2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[3].ampsL3" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[3].ampsL3" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L3
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[3].ampsL3"
                            defaultValue={data.loadTests[3].ampsL3 || ""}
                            placeholder="AMPS L3"
                            {...register("loadTests[3].ampsL3", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[3].ampsL3" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[3].volts" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[3].volts" className="pb-1 text-xs text-gray-600 uppercase">
                            Volts
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[3].volts"
                            defaultValue={data.loadTests[3].volts || ""}
                            placeholder="Volts"
                            {...register("loadTests[3].volts", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[3].volts" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[3].kW" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[3].kW" className="pb-1 text-xs text-gray-600 uppercase">
                            KW
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[3].kW"
                            defaultValue={data.loadTests[3].kW || ""}
                            placeholder="KW"
                            {...register("loadTests[3].kW", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[3].kW" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[3].pf" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[3].pf" className="pb-1 text-xs text-gray-600 uppercase">
                            PF
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[3].pf"
                            defaultValue={data.loadTests[3].pf || ""}
                            placeholder="PF"
                            {...register("loadTests[3].pf", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[3].pf" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[3].oilPsi" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[3].oilPsi" className="pb-1 text-xs text-gray-600 uppercase">
                            Oil PSI
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[3].oilPsi"
                            defaultValue={data.loadTests[3].oilPsi || ""}
                            placeholder="Oil PSI"
                            {...register("loadTests[3].oilPsi", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[3].oilPsi" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[3].h2oTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[3].h2oTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            H2O Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[3].h2oTemp"
                            defaultValue={data.loadTests[3].h2oTemp || ""}
                            placeholder="H2O Temp"
                            {...register("loadTests[3].h2oTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[3].h2oTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[3].hZ" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[3].hZ" className="pb-1 text-xs text-gray-600 uppercase">
                            Hz
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[3].hZ"
                            defaultValue={data.loadTests[3].hZ || ""}
                            placeholder="Hz"
                            {...register("loadTests[3].hZ", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[3].hZ" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[3].ambAirTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[3].ambAirTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            Amb Air Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[3].ambAirTemp"
                            defaultValue={data.loadTests[3].ambAirTemp || ""}
                            placeholder="Amb Air Temp"
                            {...register("loadTests[3].ambAirTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[3].ambAirTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[3].percentOfLoad" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[3].percentOfLoad" className="pb-1 text-xs text-gray-600 uppercase">
                            % of Load
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[3].percentOfLoad"
                            defaultValue={data.loadTests[3].percentOfLoad || ""}
                            placeholder="% of Load"
                            {...register("loadTests[3].percentOfLoad", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[3].percentOfLoad" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                      </div>
                      <div className="grid items-center justify-center w-full grid-cols-12 gap-2 pt-5 mt-5 border-t border-gray-300 gap-y-5">
                        <div key="loadTests[4].time" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[4].time" className="pb-1 text-xs text-gray-600 uppercase">
                            Time
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[4].time"
                            defaultValue={data.loadTests[4].time || ""}
                            placeholder="Time"
                            {...register("loadTests[4].time", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[4].time" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[4].ampsL1" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[4].ampsL1" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L1
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[4].ampsL1"
                            defaultValue={data.loadTests[4].ampsL1 || ""}
                            placeholder="AMPS L1"
                            {...register("loadTests[4].ampsL1", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[4].ampsL1" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[4].ampsL2" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[4].ampsL2" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L2
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[4].ampsL2"
                            defaultValue={data.loadTests[4].ampsL2 || ""}
                            placeholder="AMPS L2"
                            {...register("loadTests[4].ampsL2", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[4].ampsL2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[4].ampsL3" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[4].ampsL3" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L3
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[4].ampsL3"
                            defaultValue={data.loadTests[4].ampsL3 || ""}
                            placeholder="AMPS L3"
                            {...register("loadTests[4].ampsL3", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[4].ampsL3" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[4].volts" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[4].volts" className="pb-1 text-xs text-gray-600 uppercase">
                            Volts
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[4].volts"
                            defaultValue={data.loadTests[4].volts || ""}
                            placeholder="Volts"
                            {...register("loadTests[4].volts", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[4].volts" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[4].kW" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[4].kW" className="pb-1 text-xs text-gray-600 uppercase">
                            KW
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[4].kW"
                            defaultValue={data.loadTests[4].kW || ""}
                            placeholder="KW"
                            {...register("loadTests[4].kW", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[4].kW" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[4].pf" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[4].pf" className="pb-1 text-xs text-gray-600 uppercase">
                            PF
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[4].pf"
                            defaultValue={data.loadTests[4].pf || ""}
                            placeholder="PF"
                            {...register("loadTests[4].pf", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[4].pf" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[4].oilPsi" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[4].oilPsi" className="pb-1 text-xs text-gray-600 uppercase">
                            Oil PSI
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[4].oilPsi"
                            defaultValue={data.loadTests[4].oilPsi || ""}
                            placeholder="Oil PSI"
                            {...register("loadTests[4].oilPsi", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[4].oilPsi" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[4].h2oTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[4].h2oTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            H2O Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[4].h2oTemp"
                            defaultValue={data.loadTests[4].h2oTemp || ""}
                            placeholder="H2O Temp"
                            {...register("loadTests[4].h2oTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[4].h2oTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[4].hZ" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[4].hZ" className="pb-1 text-xs text-gray-600 uppercase">
                            Hz
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[4].hZ"
                            defaultValue={data.loadTests[4].hZ || ""}
                            placeholder="Hz"
                            {...register("loadTests[4].hZ", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[4].hZ" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[4].ambAirTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[4].ambAirTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            Amb Air Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[4].ambAirTemp"
                            defaultValue={data.loadTests[4].ambAirTemp || ""}
                            placeholder="Amb Air Temp"
                            {...register("loadTests[4].ambAirTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[4].ambAirTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[4].percentOfLoad" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[4].percentOfLoad" className="pb-1 text-xs text-gray-600 uppercase">
                            % of Load
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[4].percentOfLoad"
                            defaultValue={data.loadTests[4].percentOfLoad || ""}
                            placeholder="% of Load"
                            {...register("loadTests[4].percentOfLoad", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[4].percentOfLoad" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                      </div>
                      <div className="grid items-center justify-center w-full grid-cols-12 gap-2 pt-5 mt-5 border-t border-gray-300 gap-y-5">
                        <div key="loadTests[5].time" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[5].time" className="pb-1 text-xs text-gray-600 uppercase">
                            Time
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[5].time"
                            defaultValue={data.loadTests[5].time || ""}
                            placeholder="Time"
                            {...register("loadTests[5].time", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[5].time" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[5].ampsL1" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[5].ampsL1" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L1
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[5].ampsL1"
                            defaultValue={data.loadTests[5].ampsL1 || ""}
                            placeholder="AMPS L1"
                            {...register("loadTests[5].ampsL1", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[5].ampsL1" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[5].ampsL2" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[5].ampsL2" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L2
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[5].ampsL2"
                            defaultValue={data.loadTests[5].ampsL2 || ""}
                            placeholder="AMPS L2"
                            {...register("loadTests[5].ampsL2", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[5].ampsL2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[5].ampsL3" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[5].ampsL3" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L3
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[5].ampsL3"
                            defaultValue={data.loadTests[5].ampsL3 || ""}
                            placeholder="AMPS L3"
                            {...register("loadTests[5].ampsL3", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[5].ampsL3" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[5].volts" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[5].volts" className="pb-1 text-xs text-gray-600 uppercase">
                            Volts
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[5].volts"
                            defaultValue={data.loadTests[5].volts || ""}
                            placeholder="Volts"
                            {...register("loadTests[5].volts", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[5].volts" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[5].kW" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[5].kW" className="pb-1 text-xs text-gray-600 uppercase">
                            KW
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[5].kW"
                            defaultValue={data.loadTests[5].kW || ""}
                            placeholder="KW"
                            {...register("loadTests[5].kW", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[5].kW" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[5].pf" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[5].pf" className="pb-1 text-xs text-gray-600 uppercase">
                            PF
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[5].pf"
                            defaultValue={data.loadTests[5].pf || ""}
                            placeholder="PF"
                            {...register("loadTests[5].pf", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[5].pf" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[5].oilPsi" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[5].oilPsi" className="pb-1 text-xs text-gray-600 uppercase">
                            Oil PSI
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[5].oilPsi"
                            defaultValue={data.loadTests[5].oilPsi || ""}
                            placeholder="Oil PSI"
                            {...register("loadTests[5].oilPsi", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[5].oilPsi" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[5].h2oTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[5].h2oTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            H2O Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[5].h2oTemp"
                            defaultValue={data.loadTests[5].h2oTemp || ""}
                            placeholder="H2O Temp"
                            {...register("loadTests[5].h2oTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[5].h2oTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[5].hZ" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[5].hZ" className="pb-1 text-xs text-gray-600 uppercase">
                            Hz
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[5].hZ"
                            defaultValue={data.loadTests[5].hZ || ""}
                            placeholder="Hz"
                            {...register("loadTests[5].hZ", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[5].hZ" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[5].ambAirTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[5].ambAirTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            Amb Air Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[5].ambAirTemp"
                            defaultValue={data.loadTests[5].ambAirTemp || ""}
                            placeholder="Amb Air Temp"
                            {...register("loadTests[5].ambAirTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[5].ambAirTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[5].percentOfLoad" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[5].percentOfLoad" className="pb-1 text-xs text-gray-600 uppercase">
                            % of Load
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[5].percentOfLoad"
                            defaultValue={data.loadTests[5].percentOfLoad || ""}
                            placeholder="% of Load"
                            {...register("loadTests[5].percentOfLoad", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[5].percentOfLoad" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                      </div>
                      <div className="grid items-center justify-center w-full grid-cols-12 gap-2 pt-5 mt-5 border-t border-gray-300 gap-y-5">
                        <div key="loadTests[6].time" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[6].time" className="pb-1 text-xs text-gray-600 uppercase">
                            Time
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[6].time"
                            defaultValue={data.loadTests[6].time || ""}
                            placeholder="Time"
                            {...register("loadTests[6].time", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[6].time" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[6].ampsL1" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[6].ampsL1" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L1
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[6].ampsL1"
                            defaultValue={data.loadTests[6].ampsL1 || ""}
                            placeholder="AMPS L1"
                            {...register("loadTests[6].ampsL1", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[6].ampsL1" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[6].ampsL2" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[6].ampsL2" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L2
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[6].ampsL2"
                            defaultValue={data.loadTests[6].ampsL2 || ""}
                            placeholder="AMPS L2"
                            {...register("loadTests[6].ampsL2", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[6].ampsL2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[6].ampsL3" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[6].ampsL3" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L3
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[6].ampsL3"
                            defaultValue={data.loadTests[6].ampsL3 || ""}
                            placeholder="AMPS L3"
                            {...register("loadTests[6].ampsL3", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[6].ampsL3" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[6].volts" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[6].volts" className="pb-1 text-xs text-gray-600 uppercase">
                            Volts
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[6].volts"
                            defaultValue={data.loadTests[6].volts || ""}
                            placeholder="Volts"
                            {...register("loadTests[6].volts", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[6].volts" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[6].kW" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[6].kW" className="pb-1 text-xs text-gray-600 uppercase">
                            KW
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[6].kW"
                            defaultValue={data.loadTests[6].kW || ""}
                            placeholder="KW"
                            {...register("loadTests[6].kW", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[6].kW" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[6].pf" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[6].pf" className="pb-1 text-xs text-gray-600 uppercase">
                            PF
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[6].pf"
                            defaultValue={data.loadTests[6].pf || ""}
                            placeholder="PF"
                            {...register("loadTests[6].pf", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[6].pf" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[6].oilPsi" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[6].oilPsi" className="pb-1 text-xs text-gray-600 uppercase">
                            Oil PSI
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[6].oilPsi"
                            defaultValue={data.loadTests[6].oilPsi || ""}
                            placeholder="Oil PSI"
                            {...register("loadTests[6].oilPsi", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[6].oilPsi" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[6].h2oTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[6].h2oTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            H2O Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[6].h2oTemp"
                            defaultValue={data.loadTests[6].h2oTemp || ""}
                            placeholder="H2O Temp"
                            {...register("loadTests[6].h2oTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[6].h2oTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[6].hZ" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[6].hZ" className="pb-1 text-xs text-gray-600 uppercase">
                            Hz
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[6].hZ"
                            defaultValue={data.loadTests[6].hZ || ""}
                            placeholder="Hz"
                            {...register("loadTests[6].hZ", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[6].hZ" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[6].ambAirTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[6].ambAirTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            Amb Air Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[6].ambAirTemp"
                            defaultValue={data.loadTests[6].ambAirTemp || ""}
                            placeholder="Amb Air Temp"
                            {...register("loadTests[6].ambAirTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[6].ambAirTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[6].percentOfLoad" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[6].percentOfLoad" className="pb-1 text-xs text-gray-600 uppercase">
                            % of Load
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[6].percentOfLoad"
                            defaultValue={data.loadTests[6].percentOfLoad || ""}
                            placeholder="% of Load"
                            {...register("loadTests[6].percentOfLoad", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[6].percentOfLoad" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                      </div>
                      <div className="grid items-center justify-center w-full grid-cols-12 gap-2 pt-5 mt-5 border-t border-gray-300 gap-y-5">
                        <div key="loadTests[7].time" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[7].time" className="pb-1 text-xs text-gray-600 uppercase">
                            Time
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[7].time"
                            defaultValue={data.loadTests[7].time || ""}
                            placeholder="Time"
                            {...register("loadTests[7].time", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[7].time" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[7].ampsL1" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[7].ampsL1" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L1
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[7].ampsL1"
                            defaultValue={data.loadTests[7].ampsL1 || ""}
                            placeholder="AMPS L1"
                            {...register("loadTests[7].ampsL1", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[7].ampsL1" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[7].ampsL2" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[7].ampsL2" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L2
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[7].ampsL2"
                            defaultValue={data.loadTests[7].ampsL2 || ""}
                            placeholder="AMPS L2"
                            {...register("loadTests[7].ampsL2", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[7].ampsL2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[7].ampsL3" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[7].ampsL3" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L3
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[7].ampsL3"
                            defaultValue={data.loadTests[7].ampsL3 || ""}
                            placeholder="AMPS L3"
                            {...register("loadTests[7].ampsL3", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[7].ampsL3" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[7].volts" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[7].volts" className="pb-1 text-xs text-gray-600 uppercase">
                            Volts
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[7].volts"
                            defaultValue={data.loadTests[7].volts || ""}
                            placeholder="Volts"
                            {...register("loadTests[7].volts", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[7].volts" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[7].kW" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[7].kW" className="pb-1 text-xs text-gray-600 uppercase">
                            KW
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[7].kW"
                            defaultValue={data.loadTests[7].kW || ""}
                            placeholder="KW"
                            {...register("loadTests[7].kW", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[7].kW" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[7].pf" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[7].pf" className="pb-1 text-xs text-gray-600 uppercase">
                            PF
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[7].pf"
                            defaultValue={data.loadTests[7].pf || ""}
                            placeholder="PF"
                            {...register("loadTests[7].pf", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[7].pf" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[7].oilPsi" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[7].oilPsi" className="pb-1 text-xs text-gray-600 uppercase">
                            Oil PSI
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[7].oilPsi"
                            defaultValue={data.loadTests[7].oilPsi || ""}
                            placeholder="Oil PSI"
                            {...register("loadTests[7].oilPsi", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[7].oilPsi" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[7].h2oTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[7].h2oTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            H2O Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[7].h2oTemp"
                            defaultValue={data.loadTests[7].h2oTemp || ""}
                            placeholder="H2O Temp"
                            {...register("loadTests[7].h2oTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[7].h2oTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[7].hZ" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[7].hZ" className="pb-1 text-xs text-gray-600 uppercase">
                            Hz
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[7].hZ"
                            defaultValue={data.loadTests[7].hZ || ""}
                            placeholder="Hz"
                            {...register("loadTests[7].hZ", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[7].hZ" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[7].ambAirTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[7].ambAirTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            Amb Air Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[7].ambAirTemp"
                            defaultValue={data.loadTests[7].ambAirTemp || ""}
                            placeholder="Amb Air Temp"
                            {...register("loadTests[7].ambAirTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[7].ambAirTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[7].percentOfLoad" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[7].percentOfLoad" className="pb-1 text-xs text-gray-600 uppercase">
                            % of Load
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[7].percentOfLoad"
                            defaultValue={data.loadTests[7].percentOfLoad || ""}
                            placeholder="% of Load"
                            {...register("loadTests[7].percentOfLoad", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[7].percentOfLoad" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                      </div>
                      <div className="grid items-center justify-center w-full grid-cols-12 gap-2 pt-5 mt-5 border-t border-gray-300 gap-y-5">
                        <div key="loadTests[8].time" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[8].time" className="pb-1 text-xs text-gray-600 uppercase">
                            Time
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[8].time"
                            defaultValue={data.loadTests[8].time || ""}
                            placeholder="Time"
                            {...register("loadTests[8].time", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[8].time" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[8].ampsL1" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[8].ampsL1" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L1
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[8].ampsL1"
                            defaultValue={data.loadTests[8].ampsL1 || ""}
                            placeholder="AMPS L1"
                            {...register("loadTests[8].ampsL1", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[8].ampsL1" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[8].ampsL2" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[8].ampsL2" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L2
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[8].ampsL2"
                            defaultValue={data.loadTests[8].ampsL2 || ""}
                            placeholder="AMPS L2"
                            {...register("loadTests[8].ampsL2", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[8].ampsL2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[8].ampsL3" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[8].ampsL3" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L3
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[8].ampsL3"
                            defaultValue={data.loadTests[8].ampsL3 || ""}
                            placeholder="AMPS L3"
                            {...register("loadTests[8].ampsL3", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[8].ampsL3" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[8].volts" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[8].volts" className="pb-1 text-xs text-gray-600 uppercase">
                            Volts
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[8].volts"
                            defaultValue={data.loadTests[8].volts || ""}
                            placeholder="Volts"
                            {...register("loadTests[8].volts", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[8].volts" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[8].kW" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[8].kW" className="pb-1 text-xs text-gray-600 uppercase">
                            KW
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[8].kW"
                            defaultValue={data.loadTests[8].kW || ""}
                            placeholder="KW"
                            {...register("loadTests[8].kW", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[8].kW" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[8].pf" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[8].pf" className="pb-1 text-xs text-gray-600 uppercase">
                            PF
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[8].pf"
                            defaultValue={data.loadTests[8].pf || ""}
                            placeholder="PF"
                            {...register("loadTests[8].pf", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[8].pf" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[8].oilPsi" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[8].oilPsi" className="pb-1 text-xs text-gray-600 uppercase">
                            Oil PSI
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[8].oilPsi"
                            defaultValue={data.loadTests[8].oilPsi || ""}
                            placeholder="Oil PSI"
                            {...register("loadTests[8].oilPsi", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[8].oilPsi" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[8].h2oTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[8].h2oTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            H2O Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[8].h2oTemp"
                            defaultValue={data.loadTests[8].h2oTemp || ""}
                            placeholder="H2O Temp"
                            {...register("loadTests[8].h2oTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[8].h2oTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[8].hZ" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[8].hZ" className="pb-1 text-xs text-gray-600 uppercase">
                            Hz
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[8].hZ"
                            defaultValue={data.loadTests[8].hZ || ""}
                            placeholder="Hz"
                            {...register("loadTests[8].hZ", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[8].hZ" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[8].ambAirTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[8].ambAirTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            Amb Air Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[8].ambAirTemp"
                            defaultValue={data.loadTests[8].ambAirTemp || ""}
                            placeholder="Amb Air Temp"
                            {...register("loadTests[8].ambAirTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[8].ambAirTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[8].percentOfLoad" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[8].percentOfLoad" className="pb-1 text-xs text-gray-600 uppercase">
                            % of Load
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[8].percentOfLoad"
                            defaultValue={data.loadTests[8].percentOfLoad || ""}
                            placeholder="% of Load"
                            {...register("loadTests[8].percentOfLoad", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[8].percentOfLoad" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                      </div>
                      <div className="grid items-center justify-center w-full grid-cols-12 gap-2 pt-5 mt-5 border-t border-gray-300 gap-y-5">
                        <div key="loadTests[9].time" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[9].time" className="pb-1 text-xs text-gray-600 uppercase">
                            Time
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[9].time"
                            defaultValue={data.loadTests[9].time || ""}
                            placeholder="Time"
                            {...register("loadTests[9].time", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[9].time" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[9].ampsL1" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[9].ampsL1" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L1
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[9].ampsL1"
                            defaultValue={data.loadTests[9].ampsL1 || ""}
                            placeholder="AMPS L1"
                            {...register("loadTests[9].ampsL1", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[9].ampsL1" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[9].ampsL2" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[9].ampsL2" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L2
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[9].ampsL2"
                            defaultValue={data.loadTests[9].ampsL2 || ""}
                            placeholder="AMPS L2"
                            {...register("loadTests[9].ampsL2", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[9].ampsL2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[9].ampsL3" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[9].ampsL3" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L3
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[9].ampsL3"
                            defaultValue={data.loadTests[9].ampsL3 || ""}
                            placeholder="AMPS L3"
                            {...register("loadTests[9].ampsL3", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[9].ampsL3" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[9].volts" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[9].volts" className="pb-1 text-xs text-gray-600 uppercase">
                            Volts
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[9].volts"
                            defaultValue={data.loadTests[9].volts || ""}
                            placeholder="Volts"
                            {...register("loadTests[9].volts", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[9].volts" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[9].kW" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[9].kW" className="pb-1 text-xs text-gray-600 uppercase">
                            KW
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[9].kW"
                            defaultValue={data.loadTests[9].kW || ""}
                            placeholder="KW"
                            {...register("loadTests[9].kW", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[9].kW" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[9].pf" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[9].pf" className="pb-1 text-xs text-gray-600 uppercase">
                            PF
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[9].pf"
                            defaultValue={data.loadTests[9].pf || ""}
                            placeholder="PF"
                            {...register("loadTests[9].pf", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[9].pf" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[9].oilPsi" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[9].oilPsi" className="pb-1 text-xs text-gray-600 uppercase">
                            Oil PSI
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[9].oilPsi"
                            defaultValue={data.loadTests[9].oilPsi || ""}
                            placeholder="Oil PSI"
                            {...register("loadTests[9].oilPsi", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[9].oilPsi" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[9].h2oTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[9].h2oTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            H2O Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[9].h2oTemp"
                            defaultValue={data.loadTests[9].h2oTemp || ""}
                            placeholder="H2O Temp"
                            {...register("loadTests[9].h2oTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[9].h2oTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[9].hZ" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[9].hZ" className="pb-1 text-xs text-gray-600 uppercase">
                            Hz
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[9].hZ"
                            defaultValue={data.loadTests[9].hZ || ""}
                            placeholder="Hz"
                            {...register("loadTests[9].hZ", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[9].hZ" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[9].ambAirTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[9].ambAirTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            Amb Air Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[9].ambAirTemp"
                            defaultValue={data.loadTests[9].ambAirTemp || ""}
                            placeholder="Amb Air Temp"
                            {...register("loadTests[9].ambAirTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[9].ambAirTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[9].percentOfLoad" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[9].percentOfLoad" className="pb-1 text-xs text-gray-600 uppercase">
                            % of Load
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[9].percentOfLoad"
                            defaultValue={data.loadTests[9].percentOfLoad || ""}
                            placeholder="% of Load"
                            {...register("loadTests[9].percentOfLoad", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[9].percentOfLoad" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                      </div>
                      <div className="grid items-center justify-center w-full grid-cols-12 gap-2 pt-5 mt-5 border-t border-gray-300 gap-y-5">
                        <div key="loadTests[10].time" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[10].time" className="pb-1 text-xs text-gray-600 uppercase">
                            Time
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[10].time"
                            defaultValue={data.loadTests[10].time || ""}
                            placeholder="Time"
                            {...register("loadTests[10].time", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[10].time" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[10].ampsL1" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[10].ampsL1" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L1
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[10].ampsL1"
                            defaultValue={data.loadTests[10].ampsL1 || ""}
                            placeholder="AMPS L1"
                            {...register("loadTests[10].ampsL1", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[10].ampsL1" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[10].ampsL2" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[10].ampsL2" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L2
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[10].ampsL2"
                            defaultValue={data.loadTests[10].ampsL2 || ""}
                            placeholder="AMPS L2"
                            {...register("loadTests[10].ampsL2", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[10].ampsL2" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[10].ampsL3" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[10].ampsL3" className="pb-1 text-xs text-gray-600 uppercase">
                            AMPS L3
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[10].ampsL3"
                            defaultValue={data.loadTests[10].ampsL3 || ""}
                            placeholder="AMPS L3"
                            {...register("loadTests[10].ampsL3", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[10].ampsL3" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[10].volts" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[10].volts" className="pb-1 text-xs text-gray-600 uppercase">
                            Volts
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[10].volts"
                            defaultValue={data.loadTests[10].volts || ""}
                            placeholder="Volts"
                            {...register("loadTests[10].volts", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[10].volts" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[10].kW" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[10].kW" className="pb-1 text-xs text-gray-600 uppercase">
                            KW
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[10].kW"
                            defaultValue={data.loadTests[10].kW || ""}
                            placeholder="KW"
                            {...register("loadTests[10].kW", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[10].kW" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[10].pf" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[10].pf" className="pb-1 text-xs text-gray-600 uppercase">
                            PF
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[10].pf"
                            defaultValue={data.loadTests[10].pf || ""}
                            placeholder="PF"
                            {...register("loadTests[10].pf", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[10].pf" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[10].oilPsi" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[10].oilPsi" className="pb-1 text-xs text-gray-600 uppercase">
                            Oil PSI
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[10].oilPsi"
                            defaultValue={data.loadTests[10].oilPsi || ""}
                            placeholder="Oil PSI"
                            {...register("loadTests[10].oilPsi", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[10].oilPsi" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[10].h2oTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[10].h2oTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            H2O Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[10].h2oTemp"
                            defaultValue={data.loadTests[10].h2oTemp || ""}
                            placeholder="H2O Temp"
                            {...register("loadTests[10].h2oTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[10].h2oTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[10].hZ" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[10].hZ" className="pb-1 text-xs text-gray-600 uppercase">
                            Hz
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[10].hZ"
                            defaultValue={data.loadTests[10].hZ || ""}
                            placeholder="Hz"
                            {...register("loadTests[10].hZ", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[10].hZ" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[10].ambAirTemp" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[10].ambAirTemp" className="pb-1 text-xs text-gray-600 uppercase">
                            Amb Air Temp
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[10].ambAirTemp"
                            defaultValue={data.loadTests[10].ambAirTemp || ""}
                            placeholder="Amb Air Temp"
                            {...register("loadTests[10].ambAirTemp", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[10].ambAirTemp" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div key="loadTests[10].percentOfLoad" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="loadTests[10].percentOfLoad" className="pb-1 text-xs text-gray-600 uppercase">
                            % of Load
                          </label>
                          <input
                            className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="loadTests[10].percentOfLoad"
                            defaultValue={data.loadTests[10].percentOfLoad || ""}
                            placeholder="% of Load"
                            {...register("loadTests[10].percentOfLoad", {
                              required: false,
                            })}
                          />
                          <ErrorMessage errors={errors} name="loadTests[10].percentOfLoad" as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                      </div>
                    </div>
                    <div key="remarks" className="flex flex-col items-start justify-start w-full col-span-2 pt-2 pb-5 border-b border-gray-400">
                      <label htmlFor="remarks" className="pb-1 text-xs text-gray-600 uppercase">
                        Remarks
                      </label>
                      <textarea
                        className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md resize-none placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        rows={2}
                        id="remarks"
                        defaultValue={data.remarks || ""}
                        placeholder="Remarks"
                        {...register("remarks", {
                          required: false,
                        })}
                      />
                      <ErrorMessage errors={errors} name="remarks" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div className="flex flex-row items-center justify-end w-full col-span-2 mt-3">
                      <button
                        type="submit"
                        className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-5 py-2 text-xs font-semibold uppercase text-white shadow-sm hover:bg-blue-500"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </FormProvider>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LoadbankTest;
