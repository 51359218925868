import { SecondaryButton, PrimaryButton } from "../../components/buttons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { DatePicker, Drawer, Select, Table, Tooltip } from "antd";
import { QueryCustomers } from "../../actions/customers";
import dayjs from "dayjs";
import { QueryChecklists } from "../../actions/checklist";
import { GetEmployees } from "../../actions/auth";
import { Xmark } from "iconoir-react";
import { v4 as uuidv4 } from "uuid";

const _ = require("lodash");

const BLANK_FILTERS = {
  customer: {
    customerId: null,
    customerCode: null,
    company: null,
    contact: null,
  },
  type: null,
  technician: null,
  createdBetween: [dayjs("01/01/2006", "MM/DD/YYYY"), dayjs()],
};

const DEFAULT_SIZE = 12;

const AllChecklists = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [checklists, setChecklists] = useState([]);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [filters, setFilters] = useState(BLANK_FILTERS);
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      runChecklistQuery(filters, page, size);
      GetEmployees()
        .then((res) => {
          setEmployees(res.data);
        })
        .catch((err) => {
          toast.error("Failed to fetch employees");
          console.error(err);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const clearFilters = () => {
    setLoading(true);
    setFilters(BLANK_FILTERS);
    setCustomers([]);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runChecklistQuery(BLANK_FILTERS, 0, DEFAULT_SIZE);
  };

  const updateType = (value) => {
    let tmp = filters;
    tmp.type = value;
    setFilters((prev) => ({ ...prev, type: value }));
    runChecklistQuery(tmp, page, size);
  };

  const updateTechnician = (value) => {
    let tmp = filters;
    tmp.technician = value;
    setFilters((prev) => ({ ...prev, technician: value }));
    runChecklistQuery(tmp, page, size);
  };

  const handleCustomerSearch = (query) => {
    if (query?.length > 2) {
      QueryCustomers(query)
        .then((res) => {
          setCustomers(res.data);
        })
        .catch((err) => {
          toast.error("Customer search failed, please try again");
          setCustomers([]);
        });
    }
  };

  const updateCustomer = (v) => {
    let tmp = filters;
    if (v) {
      let qry = customers.find((c) => c.customerId === v);
      if (qry) {
        tmp.customer = { customerId: qry.customerId, customerCode: qry.customerCode, company: qry.company, contact: qry.contact };
      } else {
        tmp.customer = { customerId: null, customerCode: null, company: null, contact: null };
      }
    } else {
      tmp.customer = { customerId: null, customerCode: null, company: null, contact: null };
    }
    setFilters(tmp);
    setCustomers(customers.filter((c) => c.customerId === v));
    runChecklistQuery(tmp, page, size);
  };

  const runChecklistQuery = (data, offset, limit) => {
    QueryChecklists({
      customerId: data.customer.customerId,
      type: data.type,
      technician: data.technician,
      createdBetween: data.createdBetween,
      page: offset,
      limit: limit,
    })
      .then((res) => {
        setChecklists(res.data.results);
        setTotal(res.data.total);
        setSize(res.data.size);
        setPage(res.data.page);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Failed to fetch checklists");
        setChecklists([]);
        setTotal(0);
        setSize(DEFAULT_SIZE);
        setPage(0);
        setFilters(BLANK_FILTERS);
        setTimeout(() => setLoading(false), 700);
      });
  };

  const changePage = (pg, sz) => {
    setPage(pg);
    setSize(sz);
    setLoading(true);
    runChecklistQuery(filters, pg, sz);
  };

  const applyFilters = () => {
    setLoading(true);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runChecklistQuery(filters, 0, DEFAULT_SIZE);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  const disabledDate = (current) => {
    return current && current > dayjs();
  };

  const updateCreateBetween = (dates) => {
    let tmp = filters;
    if (dates && dates.length === 2 && dates[0] && dates[1] && dayjs(dates[0]).isValid() && dayjs(dates[1]).isValid()) {
      tmp.createdBetween = dates;
    } else {
      tmp.createdBetween = [dayjs("01/01/2006", "MM/DD/YYYY"), dayjs()];
    }
    setFilters(tmp);
    runChecklistQuery(tmp, page, size);
  };

  const renderDrawer = () => {
    return (
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        width={650}
        destroyOnClose
        title="Create new checklist/test record"
        footer={[
          <div className="flex items-center justify-end w-full">
            <SecondaryButton
              label="Close"
              callback={() => setOpen(false)}
              className="py-2.5 transition-all duration-300 bg-black hover:bg-black/80 normal-case text-white"
            />
          </div>,
        ]}
      >
        <div className="flex flex-col items-start justify-start flex-1 gap-2 overflow-y-auto">
          <nav className="flex flex-col flex-1 w-full px-1" aria-label="CreateNewChecklistNavigation">
            <ul role="list" className="flex flex-col flex-1 w-full gap-y-5">
              <li>
                <div className="pb-3 text-xs font-semibold text-gray-500 border-b leading-2 border-gray-200/80">Hi-Tech Power Systems Inc.</div>
                <ul role="list" className="mt-2 space-y-1">
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/annualMaintenance")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        AM
                      </span>
                      <span className="truncate">Annual Preventive Maintenance Checklist</span>
                    </div>
                  </li>
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/semiAnnualMaintenance")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        SM
                      </span>
                      <span className="truncate">Semi-Annual Preventive Maintenance Checklist</span>
                    </div>
                  </li>
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/quarterlyMaintenance")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        QM
                      </span>
                      <span className="truncate">Quarterly Preventive Maintenance Checklist</span>
                    </div>
                  </li>
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/monthlyMaintenance")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        MM
                      </span>
                      <span className="truncate">Monthly Preventive Maintenance Checklist</span>
                    </div>
                  </li>
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/levelOneService")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        L1
                      </span>
                      <span className="truncate">Level One Service Checklist</span>
                    </div>
                  </li>
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/loadbank")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        LB
                      </span>
                      <span className="truncate">Loadbank Load Test Log</span>
                    </div>
                  </li>
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/leakageTest")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        LT
                      </span>
                      <span className="truncate">Gasoline Delivery Tank Leakage Test Certification</span>
                    </div>
                  </li>
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/wetTestCertification")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        WT
                      </span>
                      <span className="truncate">Wet Test Certification Form</span>
                    </div>
                  </li>
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/visualInspection")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        VI
                      </span>
                      <span className="truncate">Internal Visual Inspection Report</span>
                    </div>
                  </li>
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/externalVisualInspection")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        EI
                      </span>
                      <span className="truncate">5-Year External Visual Inspection Report</span>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <div className="pb-3 text-xs font-semibold text-gray-500 border-b leading-2 border-gray-200/80">Kinder-Morgan Southeast Terminals LLC</div>
                <ul role="list" className="mt-2 space-y-1">
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/annualTankTrailerInspection")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        TI
                      </span>
                      <span className="truncate">Annual Tank Trailer Inspection Form</span>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <div className="pb-3 text-xs font-semibold text-gray-500 border-b leading-2 border-gray-200/80">Citgo Petroleum Corporation</div>
                <ul role="list" className="mt-2 space-y-1">
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/citgo")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        CE
                      </span>
                      <span className="truncate">Carrier Equipment Inspection Form</span>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <div className="pb-3 text-xs font-semibold text-gray-500 border-b leading-2 border-gray-200/80">Buckeye Terminals LLC</div>
                <ul role="list" className="mt-2 space-y-1">
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/buckeye")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        BE
                      </span>
                      <span className="truncate">Annual & Post Incident Trailer Inspection Form</span>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <div className="pb-3 text-xs font-semibold text-gray-500 border-b leading-2 border-gray-200/80">Magellan Midstream Partners L.P.</div>
                <ul role="list" className="mt-2 space-y-1">
                  <li key={uuidv4()}>
                    <div
                      onClick={() => navigate("/checklists/magellan")}
                      className="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md cursor-pointer hover:text-blue-600 hover:bg-gray-100 group gap-x-3"
                    >
                      <span className="text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                        MG
                      </span>
                      <span className="truncate">Truck Trailer Safety Equipment and Wet Test Certification Form</span>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </Drawer>
    );
  };

  const openLink = (id, type) => {
    let link = "/checklists/";
    let checklistType = type;
    switch (checklistType) {
      case "HTPS - Annual Preventive Maintenance Checklist":
        link += "annualMaintenance/" + id;
        break;
      case "HTPS - Semi-Annual Preventive Maintenance Checklist":
        link += "semiAnnualMaintenance/" + id;
        break;
      case "HTPS - Quarterly Preventive Maintenance Checklist":
        link += "quarterlyMaintenance/" + id;
        break;
      case "HTPS - Monthly Preventive Maintenance Checklist":
        link += "monthlyMaintenance/" + id;
        break;
      case "HTPS - Level 1 Service Checklist":
        link += "levelOneService/" + id;
        break;
      case "HTPS - Loadbank Load Test Log":
        link += "loadbank/" + id;
        break;
      case "HTPS - Gasoline Delivery Tank Leakage Test Certification":
        link += "leakageTest/" + id;
        break;
      case "HTPS - Wet Test Certification Form":
        link += "wetTestCertification/" + id;
        break;
      case "HTPS - Internal Visual Inspection Report":
        link += "visualInspection/" + id;
        break;
      case "HTPS - 5-Year External Visual Inspection Report":
        link += "externalVisualInspection/" + id;
        break;
      case "Kinder Morgan - Annual Tank Trailer Inspection Form":
        link += "annualTankTrailerInspection/" + id;
        break;
      case "Citgo - Carrier Equipment Inspection Form":
        link += "citgo/" + id;
        break;
      case "Buckeye - Annual & Post Incident Trailer Inspection Form":
        link += "buckeye/" + id;
        break;
      case "Magellan - Trailer Safety Equipoment & Wet Test Certification":
        link += "magellan/" + id;
        break;
      default:
        console.log("Unknown checklist type: " + checklistType);
        toast.error("Something went wrong, please try again. If the issue persists, contact support.");
        break;
    }
    if (link.length > 15) {
      navigate(link);
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Checklists, Logs, Forms, and Reports | HTPS ERP</title>
      </Helmet>
      <div className="flex-grow flow-root w-full px-2 mt-3">
        <div className="-my-2">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-2">
              <div className="grid w-full h-full gap-6 mx-auto md:grid-cols-12">
                <div className="flex flex-col h-full col-span-3 gap-4">
                  <div className="flex flex-col items-start justify-start w-full gap-4 p-6 border border-gray-300 rounded-lg">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Filters</h3>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="customer" className="pb-1 text-xs text-gray-600 uppercase">
                        Customer
                      </label>
                      <Select
                        placeholder="Search Customers"
                        onChange={(v) => updateCustomer(v)}
                        value={filters.customer.customerId}
                        options={(customers || []).map((p) => ({
                          label: `${p.customerCode} | ${p.company && p.company.length > 0 ? p.company : "No Company Provided"}`,
                          value: p.customerId,
                        }))}
                        className="w-full mb-2 font-sans"
                        notFoundContent="No customers found, start typing to search"
                        controls={false}
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onSearch={handleCustomerSearch}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="type" className="pb-1 text-xs text-gray-600 uppercase">
                        Type
                      </label>
                      <Select
                        placeholder="Form Type"
                        onChange={(v) => updateType(v)}
                        onSelect={(v) => updateType(v)}
                        value={filters.type}
                        options={[
                          { label: "All Checklists", value: null },
                          { label: "HTPS - Annual Preventive Maintenance Checklist", value: "HTPS - Annual Preventive Maintenance Checklist" },
                          {
                            label: "HTPS - Semi-Annual Preventive Maintenance Checklist",
                            value: "HTPS - Semi-Annual Preventive Maintenance Checklist",
                          },
                          {
                            label: "HTPS - Gasoline Delivery Tank Leakage Test Certification",
                            value: "HTPS - Gasoline Delivery Tank Leakage Test Certification",
                          },
                          {
                            label: "HTPS - Internal Visual Inspection Report",
                            value: "HTPS - Internal Visual Inspection Report",
                          },
                          {
                            label: "HTPS - 5-Year External Visual Inspection Report",
                            value: "HTPS - 5-Year External Visual Inspection Report",
                          },
                          {
                            label: "Kinder Morgan - Annual Tank Trailer Inspection Form",
                            value: "Kinder Morgan - Annual Tank Trailer Inspection Form",
                          },
                          {
                            label: "Buckeye - Annual & Post Incident Trailer Inspection Form",
                            value: "Buckeye - Annual & Post Incident Trailer Inspection Form",
                          },
                        ]}
                        className="w-full mb-2 font-sans"
                        controls={false}
                        filterOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        defaultActiveFirstOption={false}
                        allowClear
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="createdBetween" className="pb-1 text-xs text-gray-600 uppercase">
                        Created Between
                      </label>
                      <DatePicker.RangePicker
                        className="w-full"
                        disabledDate={disabledDate}
                        value={[filters.createdBetween[0], filters.createdBetween[1]]}
                        defaultValue={[filters.createdBetween[0], filters.createdBetween[1]]}
                        format="MM/DD/YYYY"
                        onChange={(dates) => updateCreateBetween(dates)}
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="technician" className="pb-1 text-xs text-gray-600 uppercase">
                        Technician
                      </label>
                      <Select
                        placeholder="Select a Technician"
                        onChange={(v) => updateTechnician(v)}
                        onSelect={(v) => updateTechnician(v)}
                        value={filters.technician}
                        options={employees.map((e) => ({ label: `${e.employeeCode} | ${e.name}`, value: e.employeeId }))}
                        className="w-full mb-2 font-sans"
                        controls={false}
                        filterOption={false}
                        onKeyDown={(e) => handleKeyDown(e)}
                        defaultActiveFirstOption={false}
                        allowClear
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full gap-2">
                    <SecondaryButton
                      callback={() => clearFilters()}
                      label="Clear Filters"
                      className="block py-2.5 transition-all duration-300 normal-case w-full"
                    />
                    <PrimaryButton
                      callback={() => applyFilters()}
                      label="Apply Filters"
                      className="block py-2.5 transition-all duration-300 bg-black hover:bg-black/80 normal-case w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full col-span-9 gap-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Search Results</h3>
                    <button
                      className="inline-flex items-center justify-center h-8 px-4 py-2 leading-none text-sm font-medium transition-colors rounded-md whitespace-nowrap ring-offset-[#000064] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#18181b] focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#18181b] text-[#fafafa] hover:bg-[#18181b]/90"
                      onClick={() => setOpen(true)}
                    >
                      New Checklist/Test
                    </button>
                  </div>
                  <Table
                    dataSource={checklists}
                    loading={loading}
                    className="w-full"
                    sticky={true}
                    pagination={{
                      total: total,
                      responsive: true,
                      hideOnSinglePage: true,
                      onChange: (p, s) => changePage(p - 1, s),
                      pageSize: size,
                      current: page + 1,
                      showSizeChanger: false,
                    }}
                    rowKey="invoiceId"
                  >
                    <Table.Column
                      title="Customer"
                      dataIndex="customerCompany"
                      key="customerCompany"
                      render={(_v, r) => (
                        <Tooltip title={`${r?.customerCode}${r?.customerCompany?.length && ` | ${r.customerCompany}`}`}>
                          <p className="truncate cursor-pointer">
                            {r?.customerCode}
                            {r?.customerCompany?.length > 0 && ` | ${r.customerCompany}`}
                          </p>
                        </Tooltip>
                      )}
                    />
                    <Table.Column title="Type" dataIndex="type" key="type" align="left" />
                    <Table.Column title="Tech" dataIndex="technician" key="technician" align="left" />
                    <Table.Column
                      title="Date"
                      dataIndex="createdAt"
                      key="createdAt"
                      width={"120px"}
                      align="right"
                      render={(v) => dayjs(v).format("MM/DD/YYYY")}
                    />
                    <Table.Column
                      title=""
                      dataIndex="_id"
                      key="actions"
                      width={"80px"}
                      render={(v, r) => (
                        <SecondaryButton
                          callback={() => openLink(v, r.type)}
                          label="Open"
                          className="transition-all duration-300 border bg-white-smoke border-zinc-400 ring-0 hover:ring-0 hover:bg-zinc-100 hover:border-zinc-600 text-zinc-600 hover:text-zinc-900"
                        />
                      )}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderDrawer()}
    </div>
  );
};

export default AllChecklists;
