import { useState } from "react";
import StateList from "../../components/stateList";
import { Check } from "iconoir-react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import toast from "react-hot-toast";
import DynamicForm from "../../components/DynamicForm";
import FormController from "../../components/FormController";
import { newCustomerBasicInfo, newCustomerContactInfo, newCustomerARInfo, newCustomerDefaults } from "../../data/customerForms";
import { formatCurrency } from "../../components/tools";
import { AddCustomer } from "../../actions/customers";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const NewCustomer = () => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [basicInfo, setBasicInfo] = useState({
    customerCode: "",
    company: "",
  });
  const [contactInfo, setContactInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [arInfo, setArInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    creditLimit: 0,
    paymentTerms: "net10",
  });
  const [customerDefaults, setCustomerDefaults] = useState({
    laborDiscount: 0,
    partsMarkup: "45",
    shopFee: 2,
    contactPref: "email",
    docDetail: "itemized",
    tax: "taxable",
    taxZip: "",
    warrantyProvider: false,
  });
  const [useContactInfo, setUseContactInfo] = useState(false);

  const steps = [
    {
      id: 0,
      name: "Basic Information",
      status: step === 0 ? "current" : "complete",
    },
    {
      id: 1,
      name: "Contact Information",
      status: step === 1 ? "current" : step > 1 ? "complete" : "incomplete",
    },
    {
      id: 2,
      name: "Accounts Receivable",
      status: step === 2 ? "current" : step > 2 ? "complete" : "incomplete",
    },
    {
      id: 3,
      name: "Customer Defaults",
      status: step === 3 ? "current" : step > 3 ? "complete" : "incomplete",
    },
    { id: 4, name: "Overview", status: step === 4 ? "current" : "incomplete" },
  ];

  const stepBack = () => {
    setStep(step - 1);
  };

  const navigate = useNavigate();

  const submitStep = (data) => {
    if (step === 0) {
      setBasicInfo(data);
      setStep(step + 1);
    } else if (step === 1) {
      setContactInfo(data);
      setStep(step + 1);
    } else if (step === 2) {
      setArInfo({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        address: data.address,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        creditLimit: data.creditLimit,
        paymentTerms: data.paymentTerms,
      });
      setStep(step + 1);
    } else if (step === 3) {
      setCustomerDefaults({
        laborDiscount: data.laborDiscount,
        partsMarkup: data.partsMarkup,
        shopFee: data.shopFee,
        contactPref: data.contactPref,
        docDetail: data.docDetail,
        tax: data.tax,
        salesTaxZip: data.salesTaxZip,
        warrantyProvider: data.warrantyProvider ? true : false,
      });
      setStep(step + 1);
    }
  };

  const applyContactInfo = (checked) => {
    setLoading(true);
    if (checked) {
      let tmp = contactInfo;
      tmp.creditLimit = 0;
      tmp.paymentTerms = "";
      setArInfo(tmp);
      setUseContactInfo(true);
    } else {
      setArInfo({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        creditLimit: "",
        paymentTerms: "",
      });
      setUseContactInfo(false);
    }
    setTimeout(() => {
      setLoading(false);
    }, 700);
  };

  const renderStateName = (abbr) => {
    let result = "Unknown";
    if (abbr && abbr.length === 2) {
      let stateFind = StateList.find((s) => s.code === abbr);
      if (stateFind && stateFind.name) {
        result = stateFind.name;
      }
    }
    return result;
  };

  const renderStep = () => {
    if (step === 0) {
      return <FormController onSubmit={submitStep} fields={newCustomerBasicInfo} values={basicInfo} buttonText={"Next"} />;
    } else if (step === 1) {
      return <FormController onSubmit={submitStep} fields={newCustomerContactInfo} values={contactInfo} buttonText={"Next"} />;
    } else if (step === 2) {
      return (
        <>
          <div className="flex items-center justify-between w-full max-w-lg pb-4 mb-4 border-b border-gray-300">
            <div className="text-xs leading-6 uppercase">
              <label htmlFor="use-contact-data-for-accounts-receivable" className="text-gray-500">
                Use contact information for Accounts Receivable
              </label>
            </div>
            <div className="flex items-center h-6">
              <input
                id="use-contact-data-for-accounts-receivable"
                aria-describedby="use-contact-data-for-accounts-receivable"
                name="use-contact-data-for-accounts-receivable"
                type="checkbox"
                className="w-4 h-4 text-blue-600 border-gray-300 rounded ring-0 focus:ring-0"
                onChange={(e) => applyContactInfo(e.target.checked)}
                value={useContactInfo}
              />
            </div>
          </div>
          {loading ? "LOADING" : <FormController onSubmit={submitStep} fields={newCustomerARInfo} defaultValues={arInfo} values={arInfo} buttonText={"Next"} />}
        </>
      );
    } else if (step === 3) {
      return <FormController onSubmit={submitStep} fields={newCustomerDefaults} values={contactInfo} buttonText={"Next"} />;
    } else if (step === 4) {
      return (
        <div className="grid items-start justify-between w-full grid-cols-2 px-4 gap-x-2 gap-y-3">
          <div className="flex flex-col items-start justify-start h-full col-span-2 gap-1 px-4 py-3 border border-gray-200/50">
            <div className="px-6 py-1 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Basic Information</h3>
              <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">Internally identifiable information for the customer</p>
            </div>
            <div className="w-full mt-2 border-t border-gray-300">
              <dl className="flex flex-row items-center justify-between w-full">
                <div className="flex flex-row items-start justify-start w-1/2 gap-3 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Customer Code:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{basicInfo.customerCode}</dd>
                </div>
                <div className="flex flex-row items-start justify-start w-1/2 gap-3 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Company:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{basicInfo.company}</dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start h-full gap-1 px-4 py-3 border border-gray-200/50">
            <div className="px-6 py-1 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Contact Information</h3>
              <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">Information about the customer's primary point of contact</p>
            </div>
            <div className="w-full mt-2 border-t border-gray-300">
              <dl className="w-full">
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">First Name:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{contactInfo.firstName}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Last Name:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{contactInfo.lastName}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Email:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{contactInfo.email}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Phone Number:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    ({contactInfo.phoneNumber.slice(0, 3)}) {contactInfo.phoneNumber.slice(3, 6)}-{contactInfo.phoneNumber.slice(6, 10)}
                  </dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Address:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{contactInfo.address}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Apt/Suite/Unit:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {contactInfo.address2.length > 0 ? contactInfo.address2 : "N/A"}
                  </dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">City:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{contactInfo.city}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">State:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{renderStateName(contactInfo.state)}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">ZIP Code:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{contactInfo.zip}</dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start h-full gap-1 px-4 py-3 border border-gray-200/50">
            <div className="px-6 py-1 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Accounts Receivable Information</h3>
              <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">Information about the customer's AR contact</p>
            </div>
            <div className="w-full mt-2 border-t border-gray-300">
              <dl className="w-full">
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">First Name:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{arInfo.firstName}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Last Name:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{arInfo.lastName}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Email:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{arInfo.email}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Phone Number:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    ({arInfo.phoneNumber.slice(0, 3)}) {arInfo.phoneNumber.slice(3, 6)}-{arInfo.phoneNumber.slice(6, 10)}
                  </dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Address:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{arInfo.address}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Apt/Suite/Unit:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{arInfo.address2.length > 0 ? arInfo.address2 : "N/A"}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">City:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{arInfo.city}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">State:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{renderStateName(arInfo.state)}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">ZIP Code:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{arInfo.zip}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Credit Limit:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formatCurrency(arInfo.creditLimit)}</dd>
                </div>
                <div className="flex flex-row items-center justify-start w-full gap-3 px-2 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Payment Terms:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {newCustomerARInfo.paymentTerms.options.find((o) => o.value === arInfo.paymentTerms)?.label ?? "N/A"}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start h-full col-span-2 gap-1 px-4 py-3 border border-gray-200/50">
            <div className="px-6 py-1 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Customer Defaults</h3>
              <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">Preset values to be used when creating new quotes and invoices</p>
            </div>
            <div className="w-full mt-2 border-t border-gray-300">
              <dl className="grid items-start justify-between w-full grid-cols-2">
                <div className="flex flex-row items-center justify-start gap-3 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Labor Discount:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customerDefaults.laborDiscount}</dd>
                </div>
                <div className="flex flex-row items-center justify-start gap-3 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Parts Markup:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customerDefaults.partsMarkup}%</dd>
                </div>
                <div className="flex flex-row items-center justify-start gap-3 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Shop Fee:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customerDefaults.shopFee}%</dd>
                </div>
                <div className="flex flex-row items-center justify-start gap-3 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Contact Preference:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {newCustomerDefaults.contactPref.options.find((o) => o.value === customerDefaults.contactPref)?.label ?? "N/A"}
                  </dd>
                </div>
                <div className="flex flex-row items-center justify-start gap-3 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Document Detail Level:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {newCustomerDefaults.docDetail.options.find((o) => o.value === customerDefaults.docDetail)?.label ?? "N/A"}
                  </dd>
                </div>
                <div className="flex flex-row items-center justify-start gap-3 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Tax Status:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {newCustomerDefaults.tax.options.find((o) => o.value === customerDefaults.tax)?.label ?? "N/A"}
                  </dd>
                </div>
                <div className="flex flex-row items-center justify-start gap-3 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Sales Tax ZIP Code:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customerDefaults.salesTaxZip}</dd>
                </div>
                <div className="flex flex-row items-center justify-start gap-3 py-2">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Warranty Provider:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customerDefaults.warrantyProvider ? "Yes" : "No"}</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      );
    }
  };

  const createCustomer = () => {
    let payload = {
      company: basicInfo.company,
      customerCode: basicInfo.customerCode,
      contact: contactInfo,
      arData: arInfo,
      defaults: customerDefaults,
      notes: [],
      equipment: [],
    };
    setLoading(true);
    AddCustomer(payload)
      .then((res) => {
        toast.success("Customer created successfully!");
        navigate(`/customers/${res.data.customerId}`);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Create a Customer | HTPS ERP</title>
      </Helmet>
      <nav aria-label="Progress" className="w-full mb-5">
        <ol role="list" className="border border-gray-300 divide-y divide-gray-300 rounded-md md:flex md:divide-y-0">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative md:flex md:flex-1">
              {step.status === "complete" ? (
                <div className="flex items-center w-full group">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-blue-600 rounded-full group-hover:bg-blue-800">
                      <Check className="w-6 h-6 text-white" aria-hidden="true" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                  </span>
                </div>
              ) : step.status === "current" ? (
                <div className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                  <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-blue-600 rounded-full">
                    <span className="text-blue-600">{step.id + 1}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-blue-600">{step.name}</span>
                </div>
              ) : (
                <div className="flex items-center cursor-pointer group">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900">{step.id + 1}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                  </span>
                </div>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  <div className="absolute top-0 right-0 hidden w-5 h-full md:block" aria-hidden="true">
                    <svg className="w-full h-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                      <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
      <div className="flex flex-col items-center justify-start flex-grow w-full pb-3 overflow-y-auto">{renderStep()}</div>
      {step === 4 && (
        <div className="flex flex-row items-center justify-end w-full gap-1 px-8 py-3 border-t border-gray-200/50">
          <PrimaryButton label={"Create Customer"} callback={() => createCustomer()} disabled={loading} />
        </div>
      )}
    </div>
  );
};

export default NewCustomer;
