import { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { SecondaryButton } from "../../components/buttons";
import * as _ from "lodash";
import { GetData, SubmitNewSemiAnnualMaintenanceChecklist } from "../../actions/checklist";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import validator from "validator";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { semiAnnualMaintenanceChecklist } from "../../data/checklistForms";
import FormController from "../../components/FormController";
import { Helmet } from "react-helmet-async";

const SemiAnnualChecklist = () => {
  let [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  let [customers, setCustomers] = useState([]);
  let [employees, setEmployees] = useState([]);
  let [equipment, setEquipment] = useState([]);
  let [jobs, setJobs] = useState([]);
  let [data, setData] = useState({
    customerId: "",
    locationId: "",
    equipmentId: "",
    hours: 0,
    workOrder: "",
    date: "",
    technician: "",
    electrolyteLevel: false,
    loadTestBatteryVolts: "",
    terminals: false,
    wiringCondition: false,
    transferSwitchWiring: false,
    controlPanelLights: false,
    transferSwitchLights: false,
    highWaterTemperature: false,
    lowOilPressure: false,
    overSpeed: false,
    overCrank: false,
    cycleCrank: false,
    lowWaterTemp: false,
    preAlarm: false,
    remoteAnnunciator: false,
    exhaustInspection: false,
    exhaustDrainLine: false,
    fuelTankWater: false,
    transferPump: false,
    fuelLineLeaks: false,
    coolantLevel: false,
    antifreeze: false,
    coolantAdditive: false,
    coolantHoses: false,
    jacketWaterHeater: false,
    radiator: false,
    coolantWaterLeak: false,
    coolantBelts: false,
    oilHousingDoorsAndHardware: false,
    oilLeakage: false,
    lubeOilHeater: false,
    primeMoverInspection: false,
    dieselEngineOperational: false,
    sparkOperational: false,
    testRunUnitsLoad: false,
    voltageAndFrequency: false,
    recordValues: false,
    oilPressureReading: "",
    waterTemperatureReading: "",
    engineAlternatorVoltage: "",
    voltageNoLoad: "",
    voltageLoad: "",
    frequencyNoLoad: "",
    frequencyLoad: "",
    timeDelayEngineStart: "",
    timeDelayNormalToEmergency: "",
    timeDelayEmergencyToNormal: "",
    timeDelayCoolDown: "",
    exerciseClockDay: "",
    exerciseClockTime: "",
    restoreToAutomatic: false,
    notes: "",
  });

  const navigate = useNavigate();
  let location = useLocation();
  let [search, setSearch] = useSearchParams();

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetData()
        .then((res) => {
          setCustomers(res.data.customers);
          setEquipment(res.data.equipment);
          setEmployees(res.data.employees);
          setJobs(res.data.jobs);
          let customerId = search.get("customerId");
          let jobId = search.get("jobId");
          if (customerId && jobId) {
            let tmp = data;
            tmp.customerId = customerId;
            tmp.workOrder = jobId;
            setData(tmp);
            setValue("customerId", customerId);
            setValue("workOrder", jobId);
          }
          setTimeout(() => setLoading(false), 900);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading base checklist information");
          setTimeout(() => {
            navigate("/tests");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isDirty, dirtyFields, isValid },
    setValue,
    trigger,
    getValues,
    setError,
    watch,
    resetField,
    control,
  } = formMethods;

  const onSubmit = (values) => {
    trigger();
    if (isValid) {
      values.customerId = getValues("customerId");
      values.locationId = getValues("locationId");
      values.equipmentId = getValues("equipmentId");
      values.hours = getValues("hours");
      values.workOrder = getValues("workOrder");
      values.date = getValues("date");
      values.technician = getValues("technician");
      delete values.keepDefaults;
      delete values.formModified;
      delete values.modifiedFields;
      setLoading(true);
      SubmitNewSemiAnnualMaintenanceChecklist(values)
        .then((res) => {
          toast.success("Checklist submitted successfully! You will be redirected shortly!");
          navigate(`/checklists/semiAnnualMaintenance/${res.data.id}`);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error submitting checklist");
          setLoading(false);
        });
    } else {
      toast.error("Please correct the highlighted fields");
    }
    // saveChanges();
  };

  useEffect(() => {
    let subscription = watch((values, { name }) => {
      if (name === "customerId") {
        resetField("equipmentId");
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const renderEquipmentType = (type) => {
    switch (type) {
      case "generator":
        return "Generator";
      case "pressureWasher":
        return "Pressure Washer";
      case "truck":
        return "Truck";
      case "trailer":
        return "Trailer";
      case "welder":
        return "Welder";
      case "airCompressor":
        return "Air Compressor";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const equipmentOptions = () => {
    let customerId = getValues("customerId");
    if (customerId && customerId.length > 30) {
      let filtered = equipment.filter((eq) => eq.customerId === customerId);
      let organized = filtered.map((eq) => ({
        value: eq.equipmentId,
        label: `${renderEquipmentType(eq.equipmentType)}${eq.equipmentType === "other" && eq.otherType.length > 0 ? " - " + eq.otherType : ""}${
          eq.details.make && eq.details.make.length > 0 ? " | " + eq.details.make : " | Unknown Make"
        }${eq.details.model && eq.details.model.length > 0 ? " | " + eq.details.model : " | Unknown Model"}`,
      }));
      return organized;
    } else {
      return [];
    }
  };

  const jobOptions = () => {
    let customerId = getValues("customerId");
    if (customerId && customerId.length > 30) {
      let filtered = jobs.filter((eq) => eq.customerId === customerId);
      let organized = filtered.map((eq) => ({
        value: eq.jobId,
        label: `${eq.jobNo}`,
      }));
      return organized;
    } else {
      return [];
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>New Semi-Annual Preventive Maintenance Checklist | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Semi-Annual Preventive Maintenance Checklist</p>
              <SecondaryButton
                label="Cancel & Go back"
                callback={() => (location.key !== "default" ? navigate(-1) : navigate("/checklists", { replace: true }))}
              />
            </div>
            <div className="w-full flex-grow flex-col justify-start items-center overflow-y-auto h-full px-0.5 gap-5">
              <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row items-center justify-between w-full gap-5" key="upperForm">
                <FormProvider {...formMethods}>
                  <div className="grid w-full grid-cols-2 gap-2 pt-3">
                    <div key="customerId" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="customerId" className="pb-1 text-xs text-gray-600 uppercase">
                        Customer
                      </label>
                      <Controller
                        control={control}
                        defaultValue={null}
                        name="customerId"
                        rules={{
                          required: "Please select the customer this checklist is for",
                          validate: (value) => value.length >= 3 || "Please select the customer this checklist is for",
                        }}
                        render={(props) => (
                          <Select
                            placeholder={"Select a customer"}
                            ref={props.field.ref}
                            name={"customerId"}
                            onBlur={props.field.onBlur}
                            value={props.field.value}
                            onChange={props.field.onChange}
                            options={customers.map((c) => ({
                              value: c.customerId,
                              label: `${c.customerCode}${c.company.length > 0 ? " | " + c.company : ""}`,
                            }))}
                            defaultValue={null}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="customerId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="locationId" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="locationId" className="pb-1 text-xs text-gray-600 uppercase">
                        Location
                      </label>
                      <Controller
                        control={control}
                        name={"locationId"}
                        rules={{ required: "Please select a location" }}
                        defaultValue={null}
                        render={(props) => (
                          <Select
                            placeholder={"Select a location"}
                            ref={props.field.ref}
                            name={"locationId"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            options={[
                              {
                                label: "Augusta, GA",
                                value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf",
                              },
                              {
                                label: "North Augusta, SC",
                                value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
                              },
                            ]}
                            defaultValue={null}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="locationId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="equipmentId" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="equipmentId" className="pb-1 text-xs text-gray-600 uppercase">
                        Equipment
                      </label>
                      <Controller
                        control={control}
                        defaultValue={null}
                        name="equipmentId"
                        rules={{
                          required: "Please select the piece of equipment this checklist is for",
                          validate: (value) => value.length >= 3 || "Please select the piece of equipment this checklist is for",
                        }}
                        disabled={getValues("customerId") && getValues("customerId").length > 30 ? false : true}
                        render={(props) => (
                          <Select
                            placeholder={"Select One"}
                            ref={props.field.ref}
                            name={"equipmentId"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            options={equipmentOptions()}
                            disabled={getValues("customerId") && getValues("customerId").length > 30 ? false : true}
                            defaultValue={null}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="equipmentId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="hours" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="hours" className="pb-1 text-xs text-gray-600 uppercase">
                        Hours on Unit
                      </label>
                      <input
                        className="w-full px-3 py-1 mt-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        type="number"
                        id="hours"
                        defaultValue={data.hours}
                        placeholder="Hours on Unit's meter"
                        {...register("hours", {
                          required: "Hours on Unit's meter is required",
                          validate: (value) => validator.isInt(value.toString()) || "Please enter the number of hours on the unit's meter",
                        })}
                      />
                      <ErrorMessage errors={errors} name="hours" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="workOrder" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="workOrder" className="pb-1 text-xs text-gray-600 uppercase">
                        Job / Work Order
                      </label>
                      <Controller
                        control={control}
                        defaultValue={null}
                        name="workOrder"
                        rules={{
                          required: "Please select the piece of equipment this checklist is for",
                          validate: (value) => value.length >= 3 || "Please select the piece of equipment this checklist is for",
                        }}
                        disabled={getValues("customerId") && getValues("customerId").length > 30 ? false : true}
                        render={(props) => (
                          <Select
                            placeholder={"Select One"}
                            ref={props.field.ref}
                            name={"workOrder"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            value={props.field.value}
                            options={jobOptions()}
                            disabled={getValues("customerId") && getValues("customerId").length > 30 ? false : true}
                            defaultValue={null}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="workOrder" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="date" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="date" className="pb-1 text-xs text-gray-600 uppercase">
                        Inspection Date
                      </label>
                      <Controller
                        control={control}
                        name="date"
                        rules={{
                          required: false,
                          validate: (v) => dayjs(v).isValid() || "Date is required",
                        }}
                        defaultValue={data.date !== "" ? dayjs(data.date) : null}
                        render={({ field, fieldState }) => (
                          <DatePicker
                            placeholder="Inspection Date"
                            status={fieldState.error ? "error" : undefined}
                            ref={field.ref}
                            name={field.name}
                            format={"MM/DD/YYYY"}
                            onBlur={field.onBlur}
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(date) => {
                              field.onChange(date ? date.toJSON() : null);
                            }}
                            className="w-full mt-1 py-1.5"
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="date" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                    <div key="technician" className="flex flex-col items-start justify-start w-full col-span-2">
                      <label htmlFor="technician" className="pb-1 text-xs text-gray-600 uppercase">
                        Technician
                      </label>
                      <Controller
                        control={control}
                        defaultValue={null}
                        name="technician"
                        rules={{
                          required: "Please select the technician that completed this checklist",
                          validate: (value) => value.length >= 3 || "Please select the technician that completed this checklist",
                        }}
                        render={(props) => (
                          <Select
                            placeholder={"Select One"}
                            ref={props.field.ref}
                            name={"technician"}
                            onBlur={props.field.onBlur}
                            onChange={props.field.onChange}
                            options={employees.map((e) => ({
                              value: e.userId,
                              label: `${e.employeeCode} | ${e.firstName} ${e.lastName}`,
                            }))}
                            defaultValue={null}
                            className="w-full font-sans"
                            controls={false}
                            showSearch
                            filterOption={filterOption}
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="technician" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                  </div>
                </FormProvider>
              </form>
              <div className="flex flex-row items-center justify-center w-full py-4 mt-5 border-gray-400 border-y">
                <p className="text-sm font-semibold text-gray-500 uppercase">Get All Generator/Engine/ATS/Battery Numbers off unit</p>
              </div>
              <div className="flex flex-col items-center justify-start w-1/2 mx-auto my-5 mb-10">
                <FormController onSubmit={onSubmit} fields={semiAnnualMaintenanceChecklist} values={data} buttonText={"Submit Checklist"} fullWidth={true} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SemiAnnualChecklist;
