import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { OpenInWindow, Printer } from "iconoir-react";
import { GetARAgingReport } from "../../../actions/reports";
import dayjs from "dayjs";
import { formatCurrency } from "../../../components/tools";
import { GenerateARAgingReport } from "../../../data/pdf";
import { Button, Input, Table } from "antd";
import { Helmet } from "react-helmet-async";

const ARAgingReport = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [rawInvoices, setRawInvoices] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;

    if (inView) {
      GetARAgingReport()
        .then((res) => {
          setInvoices(res.data);
          setRawInvoices(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "We encountered an error while loading AR Aging report");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const printReport = () => {
    let final = [];
    for (let i = 0; i < invoices.length; i++) {
      final.push(calculatePrintData(invoices[i]));
      if (i === 300) {
        break;
      }
    }
    let doc = GenerateARAgingReport(final, authState.user);
    doc.setProperties({
      title: `Hi-Tech Power Systems | Accounts Receivable Aging Report`,
      subject: `Hi-Tech Power Systems | Accounts Receivable Aging Report`,
      author: "Hypertek Solutions LLC",
      keywords: "",
      creator: "contact@hypertek.dev",
    });
    window.open(doc.output("bloburl"), "_blank");
  };

  const calculatePrintData = (customerRecord) => {
    let data = [];
    let totalCurrent = 0;
    let totalZeroToThirty = 0;
    let totalThirtyOneToSixty = 0;
    let totalOverSixty = 0;
    let totalCredit = 0;
    let totalTotal = 0;
    customerRecord.invoices.forEach((invoice) => {
      let current = 0;
      let zeroToThirty = 0;
      let thirtyOneToSixty = 0;
      let overSixty = 0;
      let total = parseFloat((invoice.total - calculatePayments(invoice.payments)).toFixed(2));
      if (dayjs().diff(dayjs(invoice.invoiceDate), "day") <= 30) {
        current = total;
      } else if (dayjs().diff(dayjs(invoice.invoiceDate), "day") > 30 && dayjs().diff(dayjs(invoice.invoiceDate), "day") <= 60) {
        zeroToThirty = total;
      } else if (dayjs().diff(dayjs(invoice.invoiceDate), "day") > 60 && dayjs().diff(dayjs(invoice.invoiceDate), "day") <= 90) {
        thirtyOneToSixty = total;
      } else if (dayjs().diff(dayjs(invoice.invoiceDate), "day") > 90) {
        overSixty = total;
      }
      totalCurrent += parseFloat(current);
      totalZeroToThirty += parseFloat(zeroToThirty);
      totalThirtyOneToSixty += parseFloat(thirtyOneToSixty);
      totalOverSixty += parseFloat(overSixty);
      totalCredit += parseFloat(calculatePayments(invoice.payments));
      totalTotal += parseFloat(total);
      data.push({
        key: invoice.invoiceId,
        invoiceId: invoice.invoiceId,
        invoiceNo: invoice.invoiceNo,
        invoiceDate: invoice.invoiceDate,
        credit: calculatePayments(invoice.payments) > 0 ? formatCurrency(calculatePayments(invoice.payments)) : "",
        total: total > 0 ? formatCurrency(total) : "",
        current: current > 0 ? formatCurrency(current) : "",
        zeroToThirty: zeroToThirty > 0 ? formatCurrency(zeroToThirty) : "",
        thirtyOneToSixty: thirtyOneToSixty > 0 ? formatCurrency(thirtyOneToSixty) : "",
        overSixty: overSixty > 0 ? formatCurrency(overSixty) : "",
      });
    });
    data.push({
      key: "TOTALS",
      invoiceNo: "TOTALS",
      total: totalTotal > 0 ? formatCurrency(totalTotal) : "",
      current: totalCurrent > 0 ? formatCurrency(totalCurrent) : "",
      zeroToThirty: totalZeroToThirty > 0 ? formatCurrency(totalZeroToThirty) : "",
      thirtyOneToSixty: totalThirtyOneToSixty > 0 ? formatCurrency(totalThirtyOneToSixty) : "",
      overSixty: totalOverSixty > 0 ? formatCurrency(totalOverSixty) : "",
      credit: totalCredit > 0 ? formatCurrency(totalCredit) : "",
    });
    return {
      invoices: data,
      customerId: customerRecord.customerId,
      customerCode: customerRecord.customerCode,
      customerName: customerRecord.customerName.length > 0 ? customerRecord.customerName : "Unknown / No Company",
    };
  };

  const columns = [
    {
      title: "Customer Code",
      dataIndex: "customerCode",
      key: "customerCode",
    },
    {
      title: "Company / Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Unpaid Invoices",
      dataIndex: "invoices",
      key: "invoices",
      render: (r) => <p className="text-right">{r.length}</p>,
    },
    {
      title: "",
      dataIndex: "customerId",
      key: "customerId",
      render: (r) => (
        <div className="flex items-center justify-end w-full">
          <Button onClick={() => window.open(`/customers/${r}`, "_blank")}>View Customer</Button>
        </div>
      ),
    },
  ];

  const expandedRowRender = (expandedData) => {
    const columns = [
      {
        title: "Invoice #",
        dataIndex: "invoiceNo",
        key: "invoiceNo",
      },
      {
        title: "Invoice Date",
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        render: (r) => r && dayjs(r).format("MM/DD/YYYY"),
      },
      {
        title: "Due Date",
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        render: (r) => r && dayjs(r).add(30, "day").format("MM/DD/YYYY"),
      },
      {
        title: "Current",
        dataIndex: "current",
        key: "current",
      },
      {
        title: "0-30",
        dataIndex: "zeroToThirty",
        key: "zeroToThirty",
      },
      {
        title: "31-60",
        dataIndex: "thirtyOneToSixty",
        key: "thirtyOneToSixty",
      },
      {
        title: "Over 60",
        dataIndex: "overSixty",
        key: "overSixty",
      },
      {
        title: "Credit",
        dataIndex: "credit",
        key: "credit",
        render: (r) => (r && r.length > 0 && r !== "0" ? `(${r})` : r === "0" ? "0" : r),
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
      },
      {
        title: "",
        key: "invoiceId",
        dataIndex: "invoiceId",
        render: (r) => r && <Button onClick={() => window.open(`/invoices/${r}`, "_blank")}>View Invoice</Button>,
      },
    ];
    let data = [];
    let totalCurrent = 0;
    let totalZeroToThirty = 0;
    let totalThirtyOneToSixty = 0;
    let totalOverSixty = 0;
    let totalCredit = 0;
    let totalTotal = 0;
    expandedData.invoices.forEach((invoice) => {
      let current = 0;
      let zeroToThirty = 0;
      let thirtyOneToSixty = 0;
      let overSixty = 0;
      let total = parseFloat((invoice.total - calculatePayments(invoice.payments)).toFixed(2));
      if (dayjs().diff(dayjs(invoice.invoiceDate), "day") <= 30) {
        current = total;
      } else if (dayjs().diff(dayjs(invoice.invoiceDate), "day") > 30 && dayjs().diff(dayjs(invoice.invoiceDate), "day") <= 60) {
        zeroToThirty = total;
      } else if (dayjs().diff(dayjs(invoice.invoiceDate), "day") > 60 && dayjs().diff(dayjs(invoice.invoiceDate), "day") <= 90) {
        thirtyOneToSixty = total;
      } else if (dayjs().diff(dayjs(invoice.invoiceDate), "day") > 90) {
        overSixty = total;
      }
      totalCurrent += parseFloat(current);
      totalZeroToThirty += parseFloat(zeroToThirty);
      totalThirtyOneToSixty += parseFloat(thirtyOneToSixty);
      totalOverSixty += parseFloat(overSixty);
      totalCredit += parseFloat(calculatePayments(invoice.payments));
      totalTotal += parseFloat(total);
      data.push({
        key: invoice.invoiceId,
        invoiceId: invoice.invoiceId,
        invoiceNo: invoice.invoiceNo,
        invoiceDate: invoice.invoiceDate,
        credit: calculatePayments(invoice.payments) > 0 ? formatCurrency(calculatePayments(invoice.payments)) : "0",
        total: total > 0 ? formatCurrency(total) : "0",
        current: current > 0 ? formatCurrency(current) : "0",
        zeroToThirty: zeroToThirty > 0 ? formatCurrency(zeroToThirty) : "0",
        thirtyOneToSixty: thirtyOneToSixty > 0 ? formatCurrency(thirtyOneToSixty) : "0",
        overSixty: overSixty > 0 ? formatCurrency(overSixty) : "0",
      });
    });
    data.push({
      key: expandedData.customerId + "_TOTALS",
      invoiceNo: "TOTALS",
      total: totalTotal > 0 ? formatCurrency(totalTotal) : "0",
      current: totalCurrent > 0 ? formatCurrency(totalCurrent) : "0",
      zeroToThirty: totalZeroToThirty > 0 ? formatCurrency(totalZeroToThirty) : "0",
      thirtyOneToSixty: totalThirtyOneToSixty > 0 ? formatCurrency(totalThirtyOneToSixty) : "0",
      overSixty: totalOverSixty > 0 ? formatCurrency(totalOverSixty) : "0",
      credit: totalCredit > 0 ? formatCurrency(totalCredit) : "0",
    });
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const calculatePayments = (payments) => {
    let total = 0;
    if (payments && payments.length > 0) {
      payments.forEach((payment) => {
        total += payment.paymentAmount;
      });
    }
    return parseFloat(total.toFixed(2));
  };

  const filterOption = (input) => {
    setLoading(true);
    if (input !== "") {
      let filtered = rawInvoices.filter(
        (invoice) => invoice.customerName.toLowerCase().includes(input.toLowerCase()) || invoice.customerCode.toLowerCase().includes(input.toLowerCase()),
      );
      if (filtered.length > 0) {
        setInvoices(filtered);
        setTimeout(() => setLoading(false), 700);
      } else {
        setInvoices(rawInvoices);
        setTimeout(() => setLoading(false), 700);
      }
    } else {
      setInvoices(rawInvoices);
      setTimeout(() => setLoading(false), 700);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>AR Aging Report | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full h-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">AR Aging Report</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full mb-2">
          <div className="flex flex-col items-start justify-center gap-1">
            <p className="text-sm uppercase text-gray-500/90">Accounts Receivable | Aging Report</p>
            <p className="text-sm text-gray-600/80">View and manage invoices with outstanding payments, and how long they are past due.</p>
          </div>
          <button
            onClick={() => printReport()}
            className="py-1.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-gray-200 font-semibold text-gray-500 hover:text-white hover:bg-black hover:border-black focus:outline-none focus:ring-0 transition-all text-sm"
          >
            <Printer className="w-5 h-5" />
            Print Out
          </button>
        </div>
        <div className="flex flex-row items-center justify-end w-full py-4 border-gray-200 border-y">
          <Input
            placeholder="Search by Customer Name or Code"
            name="agingArInput"
            onBlur={(e) => filterOption(e.target.value)}
            defaultValue={null}
            allowClear
            className="max-w-[400px]"
            // className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
          />
        </div>
        {!loading ? (
          <>
            <Table
              columns={columns}
              expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: [invoices[0].customerId],
              }}
              dataSource={invoices}
              size="small"
              className="w-full"
              loading={loading}
              rowKey={(r) => r.customerId}
              bordered
            />
          </>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full gap-1">
            <p className="text-sm uppercase text-gray-500/90">Loading</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ARAgingReport;
