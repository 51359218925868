import { useState, useEffect } from "react";
import { SecondaryButton } from "../../../components/buttons";
import { useNavigate } from "react-router-dom";
import _, { set } from "lodash";
import { GetTimesheets } from "../../../actions/admin";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import { Select, Table } from "antd";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { Helmet } from "react-helmet-async";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const TimesheetReview = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [timesheets, setTimesheets] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [payPeriods, setPayPeriods] = useState([]);
  const [allTimesheets, setAllTimesheets] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetTimesheets()
        .then((res) => {
          let payPeriods = res.data.payPeriods;
          payPeriods = res.data.payPeriods.sort((a, b) => (dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? -1 : 1));
          setPayPeriods(payPeriods);
          setAllTimesheets(res.data.timesheets);
          let periods = res.data.payPeriods;
          let today = dayjs();
          today = today.hour(0);
          today = today.minute(0);
          today = today.second(0);
          today = today.millisecond(0);
          let currentPeriod = periods.find(
            (p) => dayjs(p.startDate).isSameOrBefore(dayjs()) && dayjs(p.endDate).hour(23).minute(59).second(59).isSameOrAfter(dayjs()),
          );
          if (currentPeriod) {
            setSelectedPeriod(currentPeriod.periodId);
            let tsFilter = res.data.timesheets.filter((t) => t.periodId === currentPeriod.periodId);
            setRawData(tsFilter);
            setTimesheets(tsFilter);
            setTimeout(() => setLoading(false), 700);
          } else {
            toast.error("Failed to retrieve current pay period");
            setSelectedPeriod(periods[0].periodId);
            let tsFilter = res.data.timesheets.filter((t) => t.periodId === periods[0].periodId);
            setRawData(tsFilter);
            setTimesheets(tsFilter);
            setTimeout(() => setLoading(false), 700);
          }
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Failed to fetch timesheets");
          setTimeout(() => setLoading(false), 700);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleSearch = (val) => {
    if (val.length > 0) {
      setLoading(true);
      let filtered = _.filter(rawData, (o) => {
        return (
          o.accountNo.toLowerCase().includes(val.toLowerCase()) ||
          o.description.toLowerCase().includes(val.toLowerCase()) ||
          o.category.toLowerCase().includes(val.toLowerCase())
        );
      });
      setTimesheets(filtered);
      setTimeout(() => {
        setLoading(false);
      }, 700);
    } else {
      setTimesheets(rawData);
      setLoading(false);
    }
  };

  let cols = [
    {
      title: "Timesheet ID",
      dataIndex: "timesheetId",
      key: "timesheetId",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Employee",
      dataIndex: "employeeName",
      key: "employeeName",
    },
    {
      title: "Period",
      dataIndex: "periodName",
      key: "periodName",
    },
    {
      title: "# of Entries",
      dataIndex: "entries",
      key: "entries",
      render: (e) => e.length,
    },
    {
      title: "Total Hours",
      dataIndex: "timesheetId",
      key: "timesheetId",
      render: (e) => getHoursWorked(e),
    },
    {
      title: "",
      dataIndex: "timesheetId",
      key: "timesheetId",
      render: (e) => <SecondaryButton label="View" callback={() => navigate(`/admin/timesheetReview/${e}`)} />,
    },
  ];

  const getHoursWorked = (tsId) => {
    let ts = timesheets.find((t) => t.timesheetId === tsId);
    if (ts) {
      let entries = ts.entries;
      let minutes = 0;
      for (let i = 0; i < entries.length; i++) {
        let timeIn = dayjs(entries[i].timeIn);
        let timeOut = dayjs(entries[i].timeOut);
        let lunchIn = dayjs(entries[i].lunchIn);
        let lunchOut = dayjs(entries[i].lunchOut);
        if (entries[i].skipLunch === false) {
          let preLunch = lunchOut.diff(timeIn, "minutes", true);
          let postLunch = timeOut.diff(lunchIn, "minutes", true);
          minutes = minutes + preLunch + postLunch;
        } else {
          let total = timeOut.diff(timeIn, "minutes", true);
          minutes = minutes + total;
        }
      }
      for (let i = 0; i < ts.rnm.length; i++) {
        let el = ts.rnm[i];
        let start = dayjs(`2000-01-01 ${el.start}`, "YYYY-MM-DD HH:mm");
        let end = dayjs(`2000-01-01 ${el.end}`, "YYYY-MM-DD HH:mm");
        let rnmTime = end.diff(start, "minutes", true);
        minutes = minutes + rnmTime;
      }
      let hrs = Math.floor(minutes / 60);
      let mins = minutes - hrs * 60;
      if (mins >= 8 && mins <= 22) {
        return `${hrs}.25 hrs`;
      } else if (mins >= 23 && mins <= 37) {
        return `${hrs}.50 hrs`;
      } else if (mins >= 38 && mins <= 52) {
        return `${hrs}.75 hrs`;
      } else if (mins >= 53) {
        hrs++;
        return `${hrs}.00 hrs`;
      } else if (mins >= 0 && mins <= 7) {
        return `${hrs}.00 hrs`;
      }
    } else {
      return "Unknown";
    }
  };

  const changePeriod = (val) => {
    setLoading(true);
    let tsFilter = allTimesheets.filter((t) => t.periodId === val);
    setRawData(tsFilter);
    setTimesheets(tsFilter);
    setSelectedPeriod(val);
    setTimeout(() => setLoading(false), 700);
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Timesheet Review | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-row items-center justify-between w-full gap-2 px-1 pb-4 border-b border-gray-200">
        <h1 className="mt-3 mb-3 text-3xl font-bold">Timesheet Review</h1>
        <Select
          options={payPeriods.map((p) => ({
            label: p.periodName,
            value: p.periodId,
          }))}
          className="w-full max-w-md min-w-sm"
          value={selectedPeriod}
          onChange={(v) => changePeriod(v)}
        />
      </div>
      <div className="flex-grow flow-root w-full px-4 mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {loading ? (
              <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
            ) : timesheets.length > 0 ? (
              <Table columns={cols} dataSource={timesheets} pagination={false} />
            ) : (
              <div className="flex flex-col items-center justify-start w-full h-full gap-5 pt-10 border-t border-gray-300">
                <h4 className="text-lg font-semibold text-gray-500">No timesheets found</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimesheetReview;
