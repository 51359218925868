import axiosInstance from "./axios";
import { validate } from "uuid";
import qs from "qs";

export const GetAllJobs = () => {
  return axiosInstance.get("/jobs");
};

export const GetLeanJobs = () => {
  return axiosInstance.get("/jobs/lean");
};

export const GetOneJob = (jobId) => {
  return axiosInstance.get(`/jobs/${jobId}`);
};

export const AddANote = (jobId, note) => {
  return axiosInstance.put(`/jobs/${jobId}/note`, note);
};

export const UpdateJob = (jobId, data) => {
  return axiosInstance.put(`/jobs/${jobId}`, data);
};

export const UpdateJobPart = (jobId, uniqueId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/parts/${uniqueId}`, data);
};

export const AddPartsToAJob = (jobId, data) => {
  return axiosInstance.post(`/jobs/${jobId}/parts`, data);
};

export const GetRates = () => {
  return axiosInstance.get("/jobs/rates");
};

export const GetEmployees = () => {
  return axiosInstance.get("/jobs/employees");
};

export const UpdateJobLabor = (jobId, laborId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/labor/${laborId}`, data);
};

export const AddLaborToAJob = (jobId, data) => {
  return axiosInstance.post(`/jobs/${jobId}/labor`, data);
};

export const AddParkingToAJob = (jobId, data) => {
  return axiosInstance.post(`/jobs/${jobId}/parking`, data);
};

export const UpdateJobParking = (jobId, parkingId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/parking/${parkingId}`, data);
};

export const RemoveJobParking = (jobId, parkingId) => {
  return axiosInstance.delete(`/jobs/${jobId}/parking/${parkingId}`);
};

export const AddLoadbankToAJob = (jobId, data) => {
  return axiosInstance.post(`/jobs/${jobId}/loadbank`, data);
};

export const UpdateJobLoadbank = (jobId, loadbankId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/loadbank/${loadbankId}`, data);
};

export const RemoveJobLoadbank = (jobId, loadbankId) => {
  return axiosInstance.delete(`/jobs/${jobId}/loadbank/${loadbankId}`);
};

export const AddFreightToAJob = (jobId, data) => {
  return axiosInstance.post(`/jobs/${jobId}/freight`, data);
};

export const UpdateJobFreight = (jobId, freightId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/freight/${freightId}`, data);
};

export const RemoveJobFreight = (jobId, freightId) => {
  return axiosInstance.delete(`/jobs/${jobId}/freight/${freightId}`);
};

export const AddMiscToAJob = (jobId, data) => {
  return axiosInstance.post(`/jobs/${jobId}/misc`, data);
};

export const UpdateJobMisc = (jobId, miscId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/misc/${miscId}`, data);
};

export const RemoveJobMisc = (jobId, miscId, partNo) => {
  return axiosInstance.delete(`/jobs/${jobId}/misc/${miscId}/${partNo}`);
};

export const SubmitJobForInvoice = (jobId) => {
  return axiosInstance.put(`/jobs/${jobId}/invoice`);
};

export const CreateAJob = (data) => {
  return axiosInstance.post("/jobs", data);
};

export const UpdateJobEquipment = (jobId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/equipment`, data);
};

export const AddNotesAndPhotos = (jobId, equipmentId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/notesAndPhotos/${equipmentId}`, data);
};

export const SubmitJobWarranty = (jobId, data) => {
  return axiosInstance.post(`/jobs/${jobId}/warranty`, data);
};

export const UpdateJobWarranty = (jobId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/warranty`, data);
};

export const VoidAJob = (jobId) => {
  return axiosInstance.put(`/jobs/${jobId}/void`);
};

export const GetNextJobNo = (locationId) => {
  return axiosInstance.get(`/jobs/jobNumber/${locationId}`);
};

export const UpdateJobShopFee = (jobId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/shopFee`, data);
};

export const GetAllCannedFees = () => {
  return axiosInstance.get("/jobs/cannedFees");
};

export const ReplaceJobCustomer = (jobId, data) => {
  return axiosInstance.post(`/jobs/${jobId}/replaceCustomer`, data);
};

export const RemoveEquipmentFromJob = (jobId, equipmentId) => {
  return axiosInstance.delete(`/jobs/${jobId}/equipment/${equipmentId}`);
};

export const SubmitEquipmentOdometerReading = (jobId, equipmentId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/equipment/${equipmentId}/odometerReading`, data);
};

export const LogJobPayment = (jobId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/payment/log`, data);
};

export const UpdateJobPayment = (jobId, data) => {
  return axiosInstance.put(`/jobs/${jobId}/payment/${data.paymentId}`, data);
};

export const DeleteJobPayment = (jobId, paymentId) => {
  return axiosInstance.delete(`/jobs/${jobId}/payment/${paymentId}`);
};

export const QueryJobs = (data) => {
  let queryParams = {};
  if (data.customerId && validate(data.customerId)) {
    queryParams.customerId = data.customerId;
  }
  if (data.jobNo && data.jobNo.length > 2) {
    queryParams.jobNo = data.jobNo;
  }
  if (data.description && data.description.length > 2) {
    queryParams.description = data.description;
  }
  if (data.equipmentId && data.equipmentId.length > 2) {
    queryParams.equipmentId = data.equipmentId;
  }
  if (data.locationName && data.locationName.length > 2) {
    queryParams.locationName = data.locationName;
  }
  if (data.customerPo && data.customerPo.length > 2) {
    queryParams.customerPo = data.customerPo;
  }
  if (data.page) {
    queryParams.page = data.page;
  }
  if (data.limit) {
    queryParams.limit = data.limit;
  }
  let queryString = qs.stringify(queryParams);
  return axiosInstance.get(`/jobs/query?${queryString}`);
};
