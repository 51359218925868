import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddTimesheetNote, EditTimesheetEntry, EditTimesheetRNM, GetOneTimesheet, RemoveTimesheetRNM, ReviewTimesheet } from "../../../actions/admin";
import toast from "react-hot-toast";
import { Avatar, Input, Modal, Table, DatePicker, TimePicker, Button, Drawer, Popconfirm, Switch } from "antd";
import { Calendar, Clock, ClockRotateRight, Printer, WarningCircle, WarningCircleSolid, Wrench } from "iconoir-react";
import dayjs from "dayjs";
import { SecondaryButton } from "../../../components/buttons";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import weekday from "dayjs/plugin/weekday";
import customParseFormat from "dayjs/plugin/customParseFormat";
import en from "dayjs/locale/en";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { PrintTimesheet } from "../../../data/pdf";
import { Helmet } from "react-helmet-async";
import { validate } from "uuid";
import utc from "dayjs/plugin/utc";

dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(customParseFormat);
dayjs.locale({ ...en, weekStart: 1 });
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

dayjs.extend(utc);
dayjs.tz.setDefault("America/New_York");

const OpenTimesheet = () => {
  const [loading, setLoading] = useState(true);
  const [timesheet, setTimesheet] = useState(null);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [entryModal, setEntryModal] = useState(false);
  const [entryData, setEntryData] = useState({
    date: null,
    timeIn: null,
    timeOut: null,
    lunchIn: null,
    lunchOut: null,
    entryId: null,
  });
  const [rnmModal, setRnmModal] = useState(false);
  const [rnmData, setRnmData] = useState({
    date: null,
    start: null,
    end: null,
    description: null,
    rnmId: null,
  });
  const [rnmDrawer, setRnmDrawer] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [reviewComment, setReviewComment] = useState("");

  let navigate = useNavigate();
  let { timesheetId } = useParams();

  useEffect(() => {
    GetOneTimesheet(timesheetId)
      .then((res) => {
        setTimesheet(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Failed to fetch timesheet");
        setTimeout(() => setLoading(false), 700);
      });
  }, []);

  const getHoursWorked = () => {
    let entries = timesheet.entries;
    let minutes = 0;
    for (let i = 0; i < entries.length; i++) {
      let timeIn = dayjs(entries[i].timeIn);
      let timeOut = dayjs(entries[i].timeOut);
      let lunchIn = dayjs(entries[i].lunchIn);
      let lunchOut = dayjs(entries[i].lunchOut);
      let preLunch = lunchOut.diff(timeIn, "minutes", true);
      let postLunch = timeOut.diff(lunchIn, "minutes", true);
      minutes = minutes + preLunch + postLunch;
    }
    for (let i = 0; i < timesheet.rnm.length; i++) {
      let el = timesheet.rnm[i];
      let start = dayjs(`2000-01-01 ${el.start}`, "YYYY-MM-DD HH:mm");
      let end = dayjs(`2000-01-01 ${el.end}`, "YYYY-MM-DD HH:mm");
      let rnmTime = end.diff(start, "minutes", true);
      minutes = minutes + rnmTime;
    }
    let hrs = Math.floor(minutes / 60);
    let mins = minutes - hrs * 60;
    if (mins >= 8 && mins <= 22) {
      return `${hrs}.25 hrs`;
    } else if (mins >= 23 && mins <= 37) {
      return `${hrs}.50 hrs`;
    } else if (mins >= 38 && mins <= 52) {
      return `${hrs}.75 hrs`;
    } else if (mins >= 53) {
      hrs++;
      return `${hrs}.00 hrs`;
    } else if (mins >= 0 && mins <= 7) {
      return `${hrs}.00 hrs`;
    }
  };

  const renderStatus = () => {
    if (timesheet.status === "Not Reviewed") {
      return (
        <div className="center relative inline-block select-none whitespace-nowrap rounded-lg bg-slate-100 py-2 px-3.5 align-baseline text-xs font-semibold uppercase leading-none text-slate-500 border-2 border-slate-500">
          <div className="mt-px">{timesheet.status}</div>
        </div>
      );
    } else if (timesheet.status === "Approved") {
      return (
        <div className="center relative inline-block select-none whitespace-nowrap rounded-lg bg-green-100 py-2 px-3.5 align-baseline text-xs font-semibold uppercase leading-none text-green-500 border-2 border-green-500">
          <div className="mt-px">{timesheet.status}</div>
        </div>
      );
    } else if (timesheet.status === "Rejected") {
      return (
        <div className="center relative inline-block select-none whitespace-nowrap rounded-lg bg-red-100 py-2 px-3.5 align-baseline text-xs font-semibold uppercase leading-none text-red-500 border-2 border-red-500">
          <div className="mt-px">{timesheet.status}</div>
        </div>
      );
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Time In",
      dataIndex: "timeIn",
      key: "timeIn",
    },
    {
      title: "Time Out",
      dataIndex: "timeOut",
      key: "timeOut",
    },
    {
      title: "Lunch Out",
      dataIndex: "lunchOut",
      key: "lunchOut",
    },
    {
      title: "Lunch In",
      dataIndex: "lunchIn",
      key: "lunchIn",
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
    },
    {
      title: "Accounted For",
      dataIndex: "entryId",
      key: "entryId",
      render: (r) => renderAccountedFor(r),
    },
    {
      title: "",
      dataIndex: "date",
      key: "date",
      render: (r) => (
        <div className="flex items-center justify-center">
          <SecondaryButton label="Edit" callback={() => openEditEntry(r)} disabled={timesheet.status !== "Not Reviewed"} />
        </div>
      ),
    },
  ];

  const renderAccountedFor = (entryId) => {
    let id = entryId.split("_")[0];
    let date = entryId.split("_")[1];
    let hasLabor = timesheet.logs.filter((l) => dayjs(l.laborDate).format("MM/DD/YYYY") === date);
    let hasRNM = timesheet.rnm.filter((r) => dayjs(r.date).format("MM/DD/YYYY") === date);
    let laborHrs = 0;
    for (let i = 0; i < hasLabor.length; ++i) {
      let el = hasLabor[i];
      let hours = "0.00";
      let start = dayjs(el.clockIn);
      let end = dayjs(el.clockOut);
      let difference = end.diff(start, "minute");
      let hrs = Math.floor(difference / 60);
      let mins = difference - hrs * 60;
      if (mins >= 8 && mins <= 22) {
        hours = `${hrs}.25`;
      } else if (mins >= 23 && mins <= 37) {
        hours = `${hrs}.50`;
      } else if (mins >= 38 && mins <= 52) {
        hours = `${hrs}.75`;
      } else if (mins >= 53) {
        hrs++;
        hours = `${hrs}.00`;
      } else if (mins >= 0 && mins < 8) {
        hours = `${hrs}.00`;
      }
      laborHrs += parseFloat(hours);
    }
    for (let i = 0; i < hasRNM.length; ++i) {
      let el = hasRNM[i];
      let hours = "0.00";
      let start = dayjs(el.start);
      let end = dayjs(el.end);
      let difference = end.diff(start, "minute");
      let hrs = Math.floor(difference / 60);
      let mins = difference - hrs * 60;
      if (mins >= 8 && mins <= 22) {
        hours = `${hrs}.25`;
      } else if (mins >= 23 && mins <= 37) {
        hours = `${hrs}.50`;
      } else if (mins >= 38 && mins <= 52) {
        hours = `${hrs}.75`;
      } else if (mins >= 53) {
        hrs++;
        hours = `${hrs}.00`;
      } else if (mins >= 0 && mins < 8) {
        hours = `${hrs}.00`;
      }
      laborHrs += parseFloat(hours);
    }
    if (id === "missing") {
      return "Missing Entry";
    } else {
      let entry = timesheet.entries.find((e) => e.entryId === id);
      if (entry) {
        let hours = calculateHours(entry);
        hours = parseFloat(hours);
        if (hours === laborHrs) {
          return `${hours.toFixed(2)} hrs accounted for`;
        } else {
          if (hours > 0) {
            return `${(hours - laborHrs).toFixed(2)} hours unaccounted for`;
          } else {
            return "Missing Entry";
          }
        }
      }
    }
  };

  const calculateAllAccountedForHours = () => {
    let dates = [];
    for (let i = 0; i < timesheet.entries.length; ++i) {
      dates.push(dayjs(timesheet.entries[i].date).format("MM/DD/YYYY"));
    }
    let laborHrs = 0;
    for (let i = 0; i < dates.length; ++i) {
      let date = dates[i];
      let hasLabor = timesheet.logs.filter((l) => dayjs(l.laborDate).format("MM/DD/YYYY") === date);
      let hasRNM = timesheet.rnm.filter((r) => dayjs(r.date).format("MM/DD/YYYY") === date);
      for (let i = 0; i < hasLabor.length; ++i) {
        let el = hasLabor[i];
        let hours = "0.00";
        let start = dayjs(el.clockIn);
        let end = dayjs(el.clockOut);
        let difference = end.diff(start, "minute");
        let hrs = Math.floor(difference / 60);
        let mins = difference - hrs * 60;
        if (mins >= 8 && mins <= 22) {
          hours = `${hrs}.25`;
        } else if (mins >= 23 && mins <= 37) {
          hours = `${hrs}.50`;
        } else if (mins >= 38 && mins <= 52) {
          hours = `${hrs}.75`;
        } else if (mins >= 53) {
          hrs++;
          hours = `${hrs}.00`;
        } else if (mins >= 0 && mins < 8) {
          hours = `${hrs}.00`;
        }
        laborHrs += parseFloat(hours);
      }
      for (let i = 0; i < hasRNM.length; ++i) {
        let el = hasRNM[i];
        let hours = "0.00";
        let start = dayjs(el.start);
        let end = dayjs(el.end);
        let difference = end.diff(start, "minute");
        let hrs = Math.floor(difference / 60);
        let mins = difference - hrs * 60;
        if (mins >= 8 && mins <= 22) {
          hours = `${hrs}.25`;
        } else if (mins >= 23 && mins <= 37) {
          hours = `${hrs}.50`;
        } else if (mins >= 38 && mins <= 52) {
          hours = `${hrs}.75`;
        } else if (mins >= 53) {
          hrs++;
          hours = `${hrs}.00`;
        } else if (mins >= 0 && mins < 8) {
          hours = `${hrs}.00`;
        }
        laborHrs += parseFloat(hours);
      }
    }
    return `${laborHrs}`;
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Job #",
        dataIndex: "jobNo",
        key: "jobNo",
        className: "w-3/5",
      },
      {
        title: "Clock-In",
        dataIndex: "clockIn",
        key: "clockIn",
      },
      {
        title: "Clock-Out",
        key: "clockOut",
        dataIndex: "clockOut",
      },
      {
        title: "Job Finished",
        dataIndex: "jobCompleted",
        key: "jobCompleted",
      },
      {
        title: "Hours",
        key: "hours",
        dataIndex: "hours",
      },
    ];
    let data = [];
    let hasLabor = timesheet.logs.filter((l) => dayjs(l.laborDate).format("MM/DD/YYYY") === record.date);
    if (hasLabor.length > 0 || record.rnm.length > 0) {
      for (let i = 0; i < hasLabor.length; ++i) {
        let el = hasLabor[i];
        let hours = "0.00";
        let start = dayjs(el.clockIn);
        let end = dayjs(el.clockOut);
        let difference = end.diff(start, "minute");
        let hrs = Math.floor(difference / 60);
        let mins = difference - hrs * 60;
        if (mins >= 8 && mins <= 22) {
          hours = `${hrs}.25`;
        } else if (mins >= 23 && mins <= 37) {
          hours = `${hrs}.50`;
        } else if (mins >= 38 && mins <= 52) {
          hours = `${hrs}.75`;
        } else if (mins >= 53) {
          hrs++;
          hours = `${hrs}.00`;
        } else if (mins >= 0 && mins < 8) {
          hours = `${hrs}.00`;
        }
        data.push({
          key: el.logId,
          jobNo: (
            <p className="p-1">
              {validate(el.jobId) ? (
                <span
                  className="font-medium text-blue-600 transition-all duration-200 cursor-pointer hover:text-blue-800"
                  onClick={() => window.open(`/jobs/${el.jobId}/labor`, "_blank")}
                >
                  {el.jobNo}
                </span>
              ) : (
                el.jobNo
              )}{" "}
              | {el.description}
            </p>
          ),
          clockIn: dayjs(el.clockIn).tz("America/New_York").format("hh:mm A"),
          clockOut: dayjs(el.clockOut).tz("America/New_York").format("hh:mm A"),
          jobCompleted: el.jobCompleted ? "Yes" : "No",
          hours: `${hours} hrs`,
          sortBy: dayjs(el.clockIn).tz("America/New_York").format("HH:mm"),
        });
      }
      if (record.rnm.length > 0) {
        for (let i = 0; i < record.rnm.length; ++i) {
          let el = record.rnm[i];
          let hours = "0.00";
          let start = dayjs(el.start);
          let end = dayjs(el.end);
          let difference = end.diff(start, "minute");
          let hrs = Math.floor(difference / 60);
          let mins = difference - hrs * 60;
          if (mins >= 8 && mins <= 22) {
            hours = `${hrs}.25`;
          } else if (mins >= 23 && mins <= 37) {
            hours = `${hrs}.50`;
          } else if (mins >= 38 && mins <= 52) {
            hours = `${hrs}.75`;
          } else if (mins >= 53) {
            hrs++;
            hours = `${hrs}.00`;
          } else if (mins >= 0 && mins < 8) {
            hours = `${hrs}.00`;
          }
          data.push({
            key: el.rnmId,
            jobNo: "R&M | " + el.description,
            clockIn: dayjs(el.start).tz("America/New_York").format("hh:mm A"),
            clockOut: dayjs(el.end).tz("America/New_York").format("hh:mm A"),
            jobCompleted: "N/A",
            hours: `${hours} hrs`,
            sortBy: dayjs(el.start).tz("America/New_York").format("HH:mm"),
          });
        }
      }

      data = data.sort((a, b) => a.sortBy.localeCompare(b.sortBy));
      return <Table columns={columns} dataSource={data} pagination={false} bordered size="small" />;
    } else {
      return <p className="w-full text-sm text-center text-gray-400">No labor entries for this day</p>;
    }
  };

  const calculateHours = (entry) => {
    let minutes = 0;
    let timeIn = dayjs(entry.timeIn);
    let timeOut = dayjs(entry.timeOut);
    let lunchIn = dayjs(entry.lunchIn);
    let lunchOut = dayjs(entry.lunchOut);
    if (entry.skipLunch) {
      if (entry.timeIn && timeIn.isValid() && entry.timeOut && timeOut.isValid()) {
        let total = timeOut.diff(timeIn, "minutes", true);
        minutes = minutes + total;
      }
    } else {
      if (
        entry.timeIn &&
        timeIn.isValid() &&
        entry.timeOut &&
        timeOut.isValid() &&
        entry.lunchIn &&
        lunchIn.isValid() &&
        entry.lunchOut &&
        lunchOut.isValid()
      ) {
        let preLunch = lunchOut.diff(timeIn, "minutes", true);
        let postLunch = timeOut.diff(lunchIn, "minutes", true);
        minutes = minutes + preLunch + postLunch;
      }
    }
    let hrs = Math.floor(minutes / 60);
    let mins = minutes - hrs * 60;
    if (mins >= 8 && mins <= 22) {
      return `${hrs}.25`;
    } else if (mins >= 23 && mins <= 37) {
      return `${hrs}.50`;
    } else if (mins >= 38 && mins <= 52) {
      return `${hrs}.75`;
    } else if (mins >= 53) {
      hrs++;
      return `${hrs}.00`;
    } else {
      return `${hrs}.00`;
    }
  };

  const data = [];

  if (!loading) {
    for (let i = 0; i < 14; ++i) {
      let startDate = dayjs(timesheet.startDate).add(i, "day");
      let hasEntry = timesheet.entries?.find((e) => dayjs(e.date).format("MM/DD/YYYY") === startDate.format("MM/DD/YYYY"));
      let hours = "0.00";
      if (hasEntry) {
        hours = calculateHours(hasEntry);
      }
      let rnmTmp = [];
      if (timesheet.rnm) {
        for (let j = 0; j < timesheet.rnm.length; j++) {
          let rnmEntry = timesheet.rnm[j];
          if (dayjs(rnmEntry.date).format("MM/DD/YYYY") === startDate.format("MM/DD/YYYY")) {
            rnmTmp.push(rnmEntry);
          }
        }
      }
      let hasPTO = timesheet.timeOff.some((t) => dayjs(t.startDate).isSameOrBefore(startDate, "day") && dayjs(t.endDate).isSameOrAfter(startDate, "day"));
      data.push({
        key: startDate.format("MM/DD/YYYY [ | ] ddd"),
        date: startDate.format("MM/DD/YYYY"),
        timeIn: hasPTO
          ? "N/A - Off Day"
          : hasEntry && hasEntry.timeIn && dayjs(hasEntry.timeIn).isValid()
            ? dayjs(hasEntry.timeIn).tz("America/New_York").format("hh:mm A")
            : "Not Entered",
        timeOut: hasPTO
          ? "N/A - Off Day"
          : hasEntry && hasEntry.timeOut && dayjs(hasEntry.timeOut).isValid()
            ? dayjs(hasEntry.timeOut).tz("America/New_York").format("hh:mm A")
            : "Not Entered",
        lunchOut: hasPTO
          ? "N/A - Off Day"
          : hasEntry
            ? !hasEntry.skipLunch && hasEntry.lunchIn && hasEntry.lunchOut && dayjs(hasEntry.lunchIn).isValid() && dayjs(hasEntry.lunchOut).isValid()
              ? dayjs(hasEntry.lunchOut).tz("America/New_York").format("hh:mm A")
              : "Skipped Lunch"
            : "Not Entered",
        lunchIn: hasPTO
          ? "N/A - Off Day"
          : hasEntry
            ? !hasEntry.skipLunch && hasEntry.lunchIn && hasEntry.lunchOut && dayjs(hasEntry.lunchIn).isValid() && dayjs(hasEntry.lunchOut).isValid()
              ? dayjs(hasEntry.lunchIn).tz("America/New_York").format("hh:mm A")
              : "Skipped Lunch"
            : "Not Entered",
        hours: hasPTO ? "N/A - Off Day" : hours + " hrs",
        rnm: rnmTmp,
        entryId: hasEntry ? hasEntry.entryId + "_" + startDate.format("MM/DD/YYYY") : `missing_${startDate.format("MM/DD/YYYY")}`,
      });
    }
  }

  const renderNote = (note) => {
    return (
      <div className="flex flex-col items-center justify-center w-full gap-2 p-6 rounded-lg bg-slate-200/75">
        <p className="w-full text-sm font-light text-slate-800">{note.note}</p>
        <div className="flex items-center justify-end w-full">
          <p className="text-sm text-slate-600">
            {note.addedByName} on {dayjs(note.date).format("MMMM Do, YYYY [at] h:mm A")}
          </p>
        </div>
      </div>
    );
  };

  const closeNoteModal = () => {
    setAddNoteModal(false);
    setNewNote("");
  };

  const submitNewNote = () => {
    if (newNote.length < 5) {
      toast.error("Note must be at least 5 characters long");
    } else {
      setLoading(true);
      AddTimesheetNote(timesheet.timesheetId, { note: newNote })
        .then((res) => {
          toast.success("Note added successfully");
          closeNoteModal();
          GetOneTimesheet(timesheetId)
            .then((res) => {
              setTimesheet(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Failed to fetch timesheet");
              setTimeout(() => setLoading(false), 700);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Failed to add note");
          setTimeout(() => setLoading(false), 700);
        });
    }
  };

  const closeEntryModal = () => {
    setEntryModal(false);
    setEntryData({
      date: null,
      timeIn: null,
      timeOut: null,
      lunchIn: null,
      lunchOut: null,
      entryId: null,
    });
  };

  const submitEntry = () => {
    let failed = false;
    if (!entryData.date) {
      toast.error("Please select a date");
      failed = true;
    } else if (!entryData.timeIn) {
      toast.error("Please select a time in");
      failed = true;
    } else if (!entryData.timeOut) {
      toast.error("Please select a time out");
      failed = true;
    } else if (!entryData.skipLunch) {
      if (!entryData.lunchOut) {
        toast.error("Please select a time when you went to lunch");
        failed = true;
      } else if (!entryData.lunchIn) {
        toast.error("Please select a time when you came back from lunch");
        failed = true;
      }
    }
    if (!failed) {
      setLoading(true);
      let timeIn;
      let timeOut;
      let lunchOut;
      let lunchIn;
      if (!dayjs(entryData.timeIn).isValid()) {
        timeIn = dayjs(entryData.date, "MM/DD/YYYY")
          .set("hour", parseInt(entryData.timeIn.split(":")[0]))
          .set("minute", parseInt(entryData.timeIn.split(":")[1]))
          .set("second", 0);
      } else {
        timeIn = dayjs(entryData.timeIn);
      }
      if (!entryData.skipLunch) {
        if (!dayjs(entryData.lunchOut).isValid()) {
          lunchOut = dayjs(entryData.date, "MM/DD/YYYY")
            .set("hour", parseInt(entryData.lunchOut.split(":")[0]))
            .set("minute", parseInt(entryData.lunchOut.split(":")[1]))
            .set("second", 0);
        } else {
          lunchOut = dayjs(entryData.lunchOut);
        }
        if (!dayjs(entryData.lunchIn).isValid()) {
          lunchIn = dayjs(entryData.date, "MM/DD/YYYY")
            .set("hour", parseInt(entryData.lunchIn.split(":")[0]))
            .set("minute", parseInt(entryData.lunchIn.split(":")[1]))
            .set("second", 0);
        } else {
          lunchIn = dayjs(entryData.lunchIn);
        }
      }
      if (!dayjs(entryData.timeOut).isValid()) {
        timeOut = dayjs(entryData.date, "MM/DD/YYYY")
          .set("hour", parseInt(entryData.timeOut.split(":")[0]))
          .set("minute", parseInt(entryData.timeOut.split(":")[1]))
          .set("second", 0);
      } else {
        timeOut = dayjs(entryData.timeOut);
      }

      if (!entryData.skipLunch) {
        if (timeOut.isSameOrAfter(timeIn) && timeOut.isSameOrAfter(lunchOut) && timeOut.isSameOrAfter(lunchIn)) {
          if (lunchOut.isSameOrAfter(timeIn) && lunchIn.isSameOrAfter(timeIn) && lunchIn.isSameOrBefore(timeOut)) {
            failed = false;
          } else {
            failed = true;
          }
        } else {
          failed = true;
        }
      } else {
        if (!timeOut.isAfter(timeIn, "minute")) {
          failed = true;
        }
      }
      let payload = {
        date: entryData.date,
        timeIn: timeIn.toJSON(),
        timeOut: timeOut.toJSON(),
      };
      if (!entryData.skipLunch) {
        payload = {
          ...payload,
          lunchOut: lunchOut.toJSON(),
          lunchIn: lunchIn.toJSON(),
        };
      }
      if (entryData.entryId) {
        payload.entryId = entryData.entryId;
      }
      if (failed) {
        toast.error("Something went wrong, please try again");
        setLoading(false);
      } else {
        EditTimesheetEntry(timesheet.timesheetId, payload)
          .then((res) => {
            toast.success("Entry edited successfully");
            closeEntryModal();
            GetOneTimesheet(timesheetId)
              .then((res) => {
                setTimesheet(res.data);
                setTimeout(() => setLoading(false), 700);
              })
              .catch((err) => {
                toast.error(err.response.data ? err.response.data.message : "Failed to fetch timesheet");
                setTimeout(() => setLoading(false), 700);
              });
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Failed to edit entry");
            setTimeout(() => setLoading(false), 700);
          });
      }
    }
  };

  const updateDate = (value) => {
    let tmp = entryData;
    tmp.date = value;
    setEntryData(tmp);
  };

  const updateTime = (timeType, value) => {
    let tmp = entryData;
    if (timeType === "timeIn") {
      tmp.timeIn = value;
    } else if (timeType === "timeOut") {
      tmp.timeOut = value;
    } else if (timeType === "lunchOut") {
      tmp.lunchOut = value;
    } else if (timeType === "lunchIn") {
      tmp.lunchIn = value;
    }
    setEntryData(tmp);
  };

  const openEditEntry = (date) => {
    let exists = timesheet.entries.find((e) => dayjs(e.date).format("MM/DD/YYYY") === date);
    if (exists) {
      setEntryData(exists);
    }
    setEntryModal(true);
  };

  const rnmColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (r) => dayjs(r).format("MM/DD/YYYY"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "320px",
    },
    {
      title: "Start",
      dataIndex: "start",
      key: "start",
      render: (r) => dayjs(r).tz("America/New_York").format("HH:mm"),
    },
    {
      title: "End",
      dataIndex: "end",
      key: "end",
      render: (r) => dayjs(r).tz("America/New_York").format("HH:mm"),
    },
    {
      title: "",
      dataIndex: "rnmId",
      key: "rnmId",
      render: (r) => (
        <Button size="small" onClick={() => openRNMEntry(r)}>
          Edit
        </Button>
      ),
    },
  ];

  const openRNMEntry = (rnmId) => {
    let rnmEntry = timesheet.rnm.find((r) => r.rnmId === rnmId);
    setRnmData(rnmEntry);
    setRnmDrawer(true);
  };

  const closeRNMDrawer = () => {
    setRnmDrawer(false);
    setRnmData({
      date: null,
      start: null,
      end: null,
      description: null,
      rnmId: null,
    });
  };

  const updateRNMDate = (value) => {
    let tmp = rnmData;
    tmp.date = value.toJSON();
    setRnmData(tmp);
  };

  const updateRNMTime = (timeType, value) => {
    let tmp = rnmData;
    if (timeType === "start") {
      tmp.start = value.toJSON();
    } else if (timeType === "end") {
      tmp.end = value.toJSON();
    }
    setRnmData(tmp);
  };

  const updateRNMDescription = (value) => {
    let tmp = rnmData;
    tmp.description = value;
    setRnmData(tmp);
  };

  const submitRNM = () => {
    if (!rnmData.date) {
      toast.error("Please select a date for the R&M Entry");
    } else if (!rnmData.start) {
      toast.error("Please select a start time for the R&M Entry");
    } else if (!rnmData.end) {
      toast.error("Please select an end time for the R&M Entry");
    } else if (rnmData.description.length < 5) {
      toast.error("Please describe the work you did under this R&M Entry");
    } else {
      let tmp = rnmData;
      let date = dayjs(tmp.date).second(0).millisecond(0);
      let start = dayjs(tmp.start).second(0).millisecond(0);
      date = date.hour(start.hour()).minute(start.minute());
      start = date.hour(start.hour()).minute(start.minute());
      let end = dayjs(tmp.end).second(0).millisecond(0);
      end = date.hour(end.hour()).minute(end.minute());
      let toSend = {
        date: date.toJSON(),
        start: start.toJSON(),
        end: end.toJSON(),
        description: tmp.description,
      };
      if (rnmData.rnmId) {
        toSend.rnmId = rnmData.rnmId;
      }
      setLoading(true);
      EditTimesheetRNM(timesheet.timesheetId, toSend)
        .then((res) => {
          toast.success("R&M entry edited successfully");
          closeRNMDrawer();
          GetOneTimesheet(timesheetId)
            .then((res) => {
              setTimesheet(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Failed to fetch timesheet");
              setTimeout(() => setLoading(false), 700);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Failed to edit R&M entry");
          setTimeout(() => setLoading(false), 700);
        });
    }
  };

  const removeRNM = (id) => {
    setLoading(true);
    RemoveTimesheetRNM(timesheet.timesheetId, id)
      .then((res) => {
        toast.success("R&M entry removed successfully");
        closeRNMDrawer();
        GetOneTimesheet(timesheetId)
          .then((res) => {
            setTimesheet(res.data);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Failed to fetch timesheet");
            setTimeout(() => setLoading(false), 700);
          });
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Failed to remove R&M entry");
        setTimeout(() => setLoading(false), 700);
      });
  };

  const closeReviewModal = () => {
    setReviewModal(false);
    setReviewComment("");
  };

  const submitReview = (approve) => {
    let toSend = {
      approve: approve,
    };
    if (reviewComment.length > 0) {
      toSend.comment = reviewComment;
    }
    if (!approve && reviewComment.length < 5) {
      toast.error("Please enter a reason for rejecting this timesheet");
    } else {
      ReviewTimesheet(timesheet.timesheetId, toSend)
        .then((res) => {
          toast.success("Timesheet reviewed successfully");
          closeReviewModal();
          GetOneTimesheet(timesheetId)
            .then((res) => {
              setTimesheet(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Failed to fetch timesheet");
              setTimeout(() => setLoading(false), 700);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Failed to remove R&M entry");
          setTimeout(() => setLoading(false), 700);
        });
    }
  };

  const printTimesheet = () => {
    let data = {
      hoursWorked: `${parseFloat(calculateAllAccountedForHours()).toFixed(2)} hrs`,
      overtime: `${parseFloat(calculateAllAccountedForHours()) > 80 ? (parseFloat(calculateAllAccountedForHours()) - 80).toFixed(2) : "0.00"} hrs`,
      totalHours: `${parseFloat(calculateAllAccountedForHours()).toFixed(2)} hrs`,
      employeeName: timesheet.employeeName,
      payPeriod: timesheet.periodName,
      entries: timesheet.entries,
    };
    let doc = PrintTimesheet(data);
    doc.setProperties({
      title: `Employee Timesheet - ${data.employeeName} - ${data.payPeriod}`,
      subject: `Employee Timesheet - ${data.employeeName} - ${data.payPeriod}`,
      author: "Hypertek Solutions LLC",
      keywords: "",
      creator: "contact@hypertek.dev",
    });
    window.open(doc.output("bloburl"), "_blank");
  };

  return !loading ? (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Review {timesheet.timesheetId} | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-row items-center justify-between w-full gap-2 px-1 pt-1 pb-4 border-b border-gray-200">
        <h1 className="text-2xl font-bold">Reviewing Timesheet {timesheet.timesheetId}</h1>
        {renderStatus()}
      </div>
      <div className="flex flex-col items-start justify-start w-full gap-2 mt-5">
        <div className="flex items-start justify-between w-full gap-2 px-4">
          <div className="flex items-center justify-start gap-2">
            <Avatar size="default" style={{ backgroundColor: "#cbd5e1", verticalAlign: "middle" }}>
              {timesheet.employeeName.split(" ")[0][0]}
              {timesheet.employeeName.split(" ")[1][0]}
            </Avatar>
            <p className="font-medium text-slate-600">{timesheet.employeeName}</p>
          </div>
          <div className="flex flex-col items-end justify-start gap-2">
            <div className="flex items-center justify-end gap-2">
              <Calendar className="w-5 h-5 text-slate-600" strokeWidth={1.5} />
              <p className="text-slate-600">{timesheet.periodName.replace("-", "to")}</p>
            </div>
            <div className="flex items-center justify-end gap-2">
              <Clock className="w-5 h-5 text-slate-600" strokeWidth={1.5} />
              <p className="text-slate-600">Logged Time: {getHoursWorked()}</p>
            </div>
            <div className="flex items-center justify-end gap-2">
              <Wrench className="w-5 h-5 text-slate-600" strokeWidth={1.5} />
              <p className="text-slate-600">R&M Entries: {timesheet.rnm.length}</p>
              {timesheet.status === "Not Reviewed" && (
                <Button size="default" type="default" onClick={() => setRnmModal(true)}>
                  Manage
                </Button>
              )}
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record),
            // defaultExpandAllRows: true,
          }}
          dataSource={data}
          className="hidden w-full px-5 py-4 md:table"
          pagination={false}
          bordered
        />
        <div className="flex flex-col items-end justify-start w-full gap-2 px-4 py-4 font-medium border-gray-200 border-y">
          <div className="flex items-start justify-end w-full gap-2 px-2">
            <ClockRotateRight className="w-5 h-5 text-slate-600" strokeWidth={1.5} />
            <p className="text-slate-600">Overtime:</p>
            <p className="w-20 text-right text-slate-600">
              {parseFloat(calculateAllAccountedForHours()) > 80 ? (parseFloat(calculateAllAccountedForHours()) - 80).toFixed(2) : "0.00"} hrs
            </p>
          </div>
          <div className="flex items-start justify-end w-full gap-2 px-2">
            <Clock className="w-5 h-5 text-slate-600" strokeWidth={1.5} />
            <p className="text-slate-600">Total Hours Worked:</p>
            <p className="w-20 text-right text-slate-600">{parseFloat(calculateAllAccountedForHours()).toFixed(2)} hrs</p>
          </div>
          <Button onClick={() => printTimesheet()}>Print Timesheet</Button>
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-2 px-4 py-4 mb-5">
          <div className="flex items-center justify-between w-full mb-3">
            <p className="mb-2 text-xl font-bold text-slate-800">Notes and Comments</p>
            <SecondaryButton label="Add Note" callback={() => setAddNoteModal(true)} />
          </div>
          {timesheet.notes.length > 0 ? (
            timesheet.notes.map((note) => renderNote(note))
          ) : (
            <p className="w-full text-sm text-center text-gray-400">No notes or comments added</p>
          )}
        </div>
        {timesheet.status === "Not Reviewed" && (
          <div className="flex items-center justify-end w-full px-4 py-3 mb-3 border-t border-gray-200">
            <Button type="primary" onClick={() => setReviewModal(true)}>
              Review Timesheet
            </Button>
          </div>
        )}
      </div>
      <Modal
        open={addNoteModal}
        onCancel={() => closeNoteModal()}
        onClose={() => closeNoteModal()}
        centered
        title="Add a note to timesheet"
        destroyOnClose
        onOk={() => submitNewNote()}
      >
        <p className="mb-2 font-medium text-slate-600">Enter your note</p>
        <Input.TextArea placeholder="Enter your note here" onChange={(e) => setNewNote(e.target.value)} rows={5} autoSize={{ maxRows: 5, minRows: 5 }} />
      </Modal>
      <Modal
        open={entryModal}
        onCancel={() => closeEntryModal()}
        onClose={() => closeEntryModal()}
        centered
        title="Edit Timesheet Entry"
        destroyOnClose
        onOk={() => submitEntry()}
      >
        <div className="flex flex-col items-start justify-start w-full gap-4">
          <div className="flex flex-row items-center justify-between w-full">
            <p className="text-sm font-medium uppercase">Date you worked</p>
            <DatePicker
              format={"MM/DD/YYYY"}
              onChange={(date, dateString) => updateDate(dateString)}
              className="w-1/2"
              changeOnBlur={true}
              defaultValue={entryData.date ? dayjs(entryData.date, "MM/DD/YYYY") : null}
            />
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="text-sm font-medium uppercase">Clock-In Time</p>
            <TimePicker
              format={"HH:mm"}
              onChange={(time, timeString) => updateTime("timeIn", timeString)}
              className="w-1/2"
              changeOnBlur={true}
              defaultValue={entryData.timeIn ? dayjs(entryData.timeIn) : null}
            />
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="text-sm font-medium uppercase">Skip Lunch</p>
            <Switch defaultChecked={entryData.skipLunch} onChange={(c) => setEntryData((prev) => ({ ...prev, skipLunch: c }))} />
          </div>
          {entryData.skipLunch !== true && (
            <>
              <div className="flex flex-row items-center justify-between w-full">
                <p className="text-sm font-medium uppercase">Lunch-Out Time</p>
                <TimePicker
                  defaultValue={entryData.lunchOut ? dayjs(entryData.lunchOut) : null}
                  format={"HH:mm"}
                  onChange={(time, timeString) => updateTime("lunchOut", timeString)}
                  className="w-1/2"
                  changeOnBlur={true}
                />
              </div>
              <div className="flex flex-row items-center justify-between w-full">
                <p className="text-sm font-medium uppercase">Lunch-In Time</p>
                <TimePicker
                  defaultValue={entryData.lunchIn ? dayjs(entryData.lunchIn) : null}
                  format={"HH:mm"}
                  onChange={(time, timeString) => updateTime("lunchIn", timeString)}
                  className="w-1/2"
                  changeOnBlur={true}
                />
              </div>
            </>
          )}
          <div className="flex flex-row items-center justify-between w-full">
            <p className="text-sm font-medium uppercase">Clock-Out Time</p>
            <TimePicker
              defaultValue={entryData.timeOut ? dayjs(entryData.timeOut) : null}
              format={"HH:mm"}
              onChange={(time, timeString) => updateTime("timeOut", timeString)}
              className="w-1/2"
              changeOnBlur={true}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={rnmModal}
        onCancel={() => setRnmModal(false)}
        onClose={() => setRnmModal(false)}
        centered
        title="R&M Entries"
        destroyOnClose
        width={750}
        footer={[
          <Button type="primary" onClick={() => setRnmDrawer(true)}>
            Add new R&M Entry
          </Button>,
          <Button key="close" onClick={() => setRnmModal(false)}>
            Close
          </Button>,
        ]}
      >
        <div className="w-full h-full overflow-y-auto max-h-96">
          <Table columns={rnmColumns} dataSource={timesheet.rnm} className="hidden w-full py-4 md:table" pagination={false} />
        </div>
      </Modal>
      <Drawer
        open={rnmDrawer}
        onClose={() => closeRNMDrawer()}
        destroyOnClose
        title="R&M Entry"
        maskClosable={false}
        footer={
          <div className="flex items-center justify-end w-full gap-2 py-2">
            <Button onClick={() => closeRNMDrawer()}>Cancel</Button>
            <Button type="primary" onClick={() => submitRNM()}>
              Save
            </Button>
          </div>
        }
      >
        <div className="flex flex-col items-start justify-start w-full gap-4">
          <div className="flex flex-col items-start justify-start w-full gap-1.5">
            <p className="text-sm text-slate-600">Date</p>
            <DatePicker
              format={"MM/DD/YYYY"}
              onChange={(date, dateString) => updateRNMDate(date)}
              className="w-full"
              changeOnBlur={true}
              defaultValue={rnmData.date ? dayjs(rnmData.date) : null}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1.5">
            <p className="text-sm text-slate-600">R&M Start Time</p>
            <TimePicker
              defaultValue={rnmData.start ? dayjs(rnmData.start) : null}
              format={"HH:mm"}
              onChange={(time, timeString) => updateRNMTime("start", time)}
              className="w-full"
              changeOnBlur={true}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1.5">
            <p className="text-sm text-slate-600">R&M End Time</p>
            <TimePicker
              defaultValue={rnmData.end ? dayjs(rnmData.end) : null}
              format={"HH:mm"}
              onChange={(time, timeString) => updateRNMTime("end", time)}
              className="w-full"
              changeOnBlur={true}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1.5">
            <p className="text-sm text-slate-600">R&M Description</p>
            <Input.TextArea
              rows={4}
              className="!resize-none"
              placeholder="Please describe what you worked on"
              defaultValue={rnmData.description}
              onChange={(e) => updateRNMDescription(e.target.value)}
            />
          </div>
          {rnmData.rnmId && (
            <div className="flex items-center justify-between w-full">
              <p className="text-sm text-slate-600">Remove R&M Entry</p>
              <Popconfirm
                title="Remove R&M Entry?"
                description="Are you sure you want to remove this R&M entry?"
                onConfirm={() => removeRNM(rnmData.rnmId)}
                cancelText="No"
                okText="Yes"
                placement="left"
                icon={<WarningCircle strokeWidth={2} className="w-5 h-5 mr-2 text-red-500 mt-0.5" />}
              >
                <Button danger>Remove R&M Entry</Button>
              </Popconfirm>
            </div>
          )}
        </div>
      </Drawer>
      <Modal
        open={reviewModal}
        onCancel={() => closeReviewModal()}
        onClose={() => closeReviewModal()}
        centered
        title="Review Timesheet"
        destroyOnClose
        footer={[
          <Button onClick={() => closeReviewModal()}>Cancel</Button>,
          <Button danger onClick={() => submitReview(false)} style={{ backgroundColor: "#FC4D4F !important" }}>
            Reject
          </Button>,
          <Button type="primary" onClick={() => submitReview(true)}>
            Approve
          </Button>,
        ]}
      >
        <p className="mb-2 font-medium text-slate-600">Add a comment to your review</p>
        <Input.TextArea placeholder="Enter your note here" onChange={(e) => setReviewComment(e.target.value)} rows={5} autoSize={{ maxRows: 5, minRows: 5 }} />
      </Modal>
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <p className="text-lg font-semibold uppercase text-slate-400 animate-pulse">Loading...</p>
    </div>
  );
};

export default OpenTimesheet;
